import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View, Text, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Calendar from 'react-native-calendars/src/calendar';
import LocaleConfig from 'xdate';
import slugify from 'slugify';
import moment from 'moment';

import { localize } from '../../../Services/CalendarLocales';
LocaleConfig.locales['it'] = localize();
LocaleConfig.defaultLocale = 'it';

import { getResponsive } from './FiltersStyle';
import SlideItemStyle from '../../Layout/SlideItem/SlideItemStyle';
import { ApplicationStyles, Colors } from '../../../Theme';
import { Button, PrimaryButton } from '../../Buttons';
import { SelectPicker } from '../../Forms';
import { SlideItem } from '../../Layout';

import HelperActions from '../../../Stores/Helper/Actions';
import { defaultFilters } from '../../../Containers/Races/Races';
import { isDesktop } from '../../../Services/ResponsiveHelper';
import { DownArrow, LeftArrow, RightArrow, UpArrow } from "../../../Theme/Icons";

const Filters = ({ resetFilters, applyFilters, filters }) => {
  const dispatch = useDispatch();
  const Style = getResponsive();

  const provinces = useSelector(state => state.helper.provinces);

  React.useEffect(() => {
    if (!provinces) {
      dispatch(HelperActions.getProvinces());
    }
  }, []);

  React.useEffect(() => {
    if (provinces) {
      let regions = provinces.map(province => (province.region)).filter((v, i, a) => a.indexOf(v) === i);
      regions.sort((a, b) => a > b ? 1 : b > a ? -1 : 0);
      setRegions(regions);
    }
  }, [provinces]);

  const [ open, setOpen ] = React.useState(null);
  const [ markedDates, setMarkedDates ] = React.useState(null);
  const [ regions, setRegions ] = React.useState(null);

  const [ from, setFrom ] = React.useState(filters.from);
  const [ to, setTo ] = React.useState(filters.to);
  const [ calendar, setCalendar ] = React.useState(filters.calendar);
  const [ region, setRegion ] = React.useState(filters.region);
  const [ province, setProvince ] = React.useState(filters.province);
  const [ raceLevel, setRaceLevel ] = React.useState(filters.race_level);
  const [ raceType, setRaceType ] = React.useState(filters.race_type);

  React.useEffect(() => {
    let dates = {};
    dates[moment(from).format('YYYY-MM-DD')] = { selected: true, color: Colors.blue1, startingDay: true };
    if (to) {
      dates[moment(to).format('YYYY-MM-DD')] = { selected: true, color: Colors.blue1, endingDay: true };
      let date = moment(from).add(1, 'day');
      while (date.isBefore(moment(to))) {
        dates[date.format('YYYY-MM-DD')] = { selected: true, color: Colors.grayUltraLight, textColor: Colors.gray };
        date = date.add(1, 'day');
      }
    }
    setMarkedDates(dates);
  }, [ from, to ]);

  React.useEffect(() => {
    if (region !== 'ALL' && province !== 'ALL' && !provinces.find(el => el.code === province && slugify(el.region) === region)) {
      setProvince('ALL');
    }
  },[ region ]);

  React.useEffect(() => {
    if (provinces && province !== 'ALL' && region === 'ALL') {
      setRegion(slugify(provinces.find(p => province === p.code).region));
    }
  },[ province, provinces ]);

  const handleCalendarPress = (day) => {
    if (to) {
      let dates = {};
      dates[moment(from).format('YYYY-MM-DD')] = { selected: true, color: Colors.blue1, startingDay: true };
      setMarkedDates(dates);
      setFrom(moment(day.dateString, 'YYYY-MM-DD'));
      setTo(null);
    } else {
      setTo(moment(day.dateString, 'YYYY-MM-DD'));
    }
  };

  const calendars = [{
    id: 'NAZ',
    value: 'Nazionale'
  }, {
    id: 'REG',
    value: 'Regionale'
  }];

  const race_levels = [{
    id: 'ALL',
    value: 'Tutte'
  }, {
    id: 'maratona',
    value: 'Maratona'
  }, {
    id: 'mezza maratona',
    value: 'Mezza maratona'
  }, {
    id: 'altro',
    value: 'Altro'
  }];

  const race_types = [{
    id: 'ALL',
    value: 'Tutte'
  }, {
    id: 'montagna',
    value: 'Montagna'
  }, {
    id: 'strada',
    value: 'Strada'
  }, {
    id: 'ULTRAMARATONA',
    value: 'Ultramaratona'
  }, {
    id: 'NORDIC WALKING',
    value: 'Nordic walking'
  }, {
    id: 'trail',
    value: 'Trail'
  }];

  const Wrapper = ({ children }) => (
    isDesktop() ? (
      <View style={Style.container}>
        {children}
      </View>
    ) : (
      <ScrollView bounces={false} contentContainerStyle={Style.container}>
        {children}
      </ScrollView>
    )
  );

  Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <Wrapper>
      <View style={Style.header}>
        <Text style={Style.title}>Affina la ricerca</Text>
        <Button style={Style.resetButton} onPress={resetFilters}><Text style={Style.resetButtonText}>Reset</Text></Button>
      </View>
      <View style={Style.filtersContainer}>
        <View style={[Style.slideItem, SlideItemStyle.itemBorder]}>
          <Button onPress={() => setOpen(open!=='calendar'?'calendar':null)}>
            <View style={SlideItemStyle.titleContainer}>
              <Text style={Style.filterTitle}>Periodo{from && to ? `: ${from.format('DD/MM')} - ${to.format('DD/MM')}` : ''}</Text>
              <View style={SlideItemStyle.icon}>{open ? <UpArrow /> : <DownArrow />}</View>
            </View>
          </Button>
          <View style={open!=='calendar' ? Style.slideItemCollapsed : {}}>
            <Calendar
              current={to && to !== filters.to ? to.format('YYYY-MM-DD') : null}
              minDate={to ? null : from.format('YYYY-MM-DD')}
              onDayPress={handleCalendarPress}
              monthFormat={'MMMM yyyy'}
              hideExtraDays={false}
              firstDay={1}
              markedDates={markedDates}
              markingType={to?'period':'simple'}
              renderArrow = {direction => direction === 'left' ? <LeftArrow size={15} stroke={2} color={Colors.blue1} /> : <RightArrow size={15} stroke={2} color={Colors.blue1} />}
              theme={{
                textSectionTitleColor: Colors.blue1,
                textDayHeaderFontSize: 15,
                textDayFontSize: 15,
                textMonthFontSize: 18,
                textDayHeaderFontFamily: ApplicationStyles.screen.fontsRegular.fontFamily,
                textDayFontFamily: ApplicationStyles.screen.fontsRegular.fontFamily,
                textMonthFontFamily: ApplicationStyles.screen.fontsRegular.fontFamily,
                dayTextColor: Colors.gray,
                textMonthFontWeight: 'bold',
                monthTextColor: Colors.blue1,
                textDayHeaderFontWeight: 'bold',
                'stylesheet.day.basic': {
                  base: {
                    height: 25,
                    width: 25,
                    alignItems: 'center',
                  },
                  text: {
                    marginTop: Platform.OS === 'android' ? 2 : 4,
                    fontSize: 15,
                    fontFamily: ApplicationStyles.screen.fontsRegular.fontFamily,
                    color: Colors.gray,
                  },
                },
                'stylesheet.calendar.main': {
                  week: {
                    marginTop: 3,
                    marginBottom: 3,
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                  },
                },
                'stylesheet.day.period': {
                  base: {
                    height: 25,
                    width: 25,
                    alignItems: 'center',
                  },
                  text: {
                    marginTop: Platform.OS === 'android' ? 2 : 4,
                    fontSize: 15,
                    fontFamily: ApplicationStyles.screen.fontsRegular.fontFamily,
                    color: Colors.gray,
                  },
                  leftFiller: {
                    height: 20,
                    flex: 1,
                  },
                  rightFiller: {
                    height: 20,
                    flex: 1,
                  },
                  fillers: {
                    position: 'absolute',
                    height: 20,
                    flexDirection: 'row',
                    left: 0,
                    right: 0,
                    top: 3,
                  },
                }
              }}
            />
          </View>
        </View>
        <SlideItem padding={10} titleStyle={Style.filterTitle} title={<Text>Tipologia calendario{calendar !== defaultFilters.calendar ? <>: <Text style={Style.filterSelectedText}>{calendars.find(cal => cal.id === calendar).value}</Text></> : null}</Text>} open={open==='calendar_type'} callback={(status) => setOpen(status?'calendar_type':null)}>
          <View style={Style.filterContent}>
            <SelectPicker pickerStyle={Style.filterSelected} pickerTextStyle={Style.filterSelectedText} onChange={(val) => { setCalendar(val); setOpen(null); }} value={calendar} values={calendars} hasPlaceholder={false} />
          </View>
        </SlideItem>
        <SlideItem padding={10} titleStyle={Style.filterTitle} title={<Text>Regione{region !== defaultFilters.region ? <>: <Text style={Style.filterSelectedText}>{provinces.find(p => region === slugify(p.region)).region}</Text></> : null}</Text>} open={open==='region'} callback={(status) => setOpen(status?'region':null)}>
          <View style={Style.filterContent}>
            {regions && (
              <SelectPicker pickerStyle={Style.filterSelected} pickerTextStyle={Style.filterSelectedText} onChange={(val) => { setRegion(val); setOpen(null); }} value={region} values={['Tutte', ...regions].map(val => ({ id: val==='Tutte'?'ALL':slugify(val), value: val }))} hasPlaceholder={false} />
            )}
          </View>
        </SlideItem>
        <SlideItem padding={10} titleStyle={Style.filterTitle} title={<Text>Provincia{province !== defaultFilters.province ? <>: <Text style={Style.filterSelectedText}>{provinces.find(p => province === p.code).name}</Text></> : null}</Text>} open={open==='province'} callback={(status) => setOpen(status?'province':null)}>
          <View style={Style.filterContent}>
            {provinces && (
              <SelectPicker pickerStyle={Style.filterSelected} pickerTextStyle={Style.filterSelectedText} onChange={(val) => { setProvince(val); setOpen(null); }} value={province} values={[{ code: 'ALL', name: 'Tutte', region: 'ALL' }, ...provinces].filter(val => region==='ALL' || val.region === 'ALL' || slugify(val.region) === region).map(province => ({ id: province.code, value: province.name }))} hasPlaceholder={false} />
            )}
          </View>
        </SlideItem>
        <SlideItem padding={10} titleStyle={Style.filterTitle} title={<Text>Livello gara{raceLevel !== defaultFilters.race_level ? <>: <Text style={Style.filterSelectedText}>{race_levels.find(r => raceLevel === r.id).value}</Text></> : null}</Text>} open={open==='race_level'} callback={(status) => setOpen(status?'race_level':null)}>
          <View style={Style.filterContent}>
            <SelectPicker pickerStyle={Style.filterSelected} pickerTextStyle={Style.filterSelectedText} onChange={(val) => { setRaceLevel(val); setOpen(null); }} value={raceLevel} values={race_levels} hasPlaceholder={false} />
          </View>
        </SlideItem>
        <SlideItem padding={10} titleStyle={Style.filterTitle} title={<Text>Tipologia gara{raceType !== defaultFilters.race_type ? <>: <Text style={Style.filterSelectedText}>{race_types.find(r => raceType === r.id).value}</Text></> : null}</Text>} open={open==='race_type'} callback={(status) => setOpen(status?'race_type':null)}>
          <View style={Style.filterContent}>
            <SelectPicker pickerStyle={Style.filterSelected} pickerTextStyle={Style.filterSelectedText} onChange={(val) => { setRaceType(val); setOpen(null); }} value={raceType} values={race_types} hasPlaceholder={false} />
          </View>
        </SlideItem>
        <View style={Style.button}>
          <View style={Style.filterContent}>
            <PrimaryButton disabled={!to} onPress={() => applyFilters({ from, to, calendar, race_level: raceLevel, race_type: raceType, province, region })}>APPLICA</PrimaryButton>
          </View>
        </View>
      </View>
    </Wrapper>
  );
};

Filters.propTypes = {
  resetFilters: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  filters: PropTypes.object,
};

export default Filters;
