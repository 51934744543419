import React from 'react';
import { Linking, Text, View } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import { Config } from '../../../Config';

import Style, { getResponsive } from './SuccessPopupStyle';
import { PopupSuccessIcon } from '../../../Theme/Icons';
import { Modal } from '../../Layout';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';
import { PrimaryButton, SecondaryButton } from '../../Buttons';

const SuccessPopup = ({ title, text, closeHandler, button='OK', help=false }) => {
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  const handleHelp = () => {
    try {
      Linking.openURL(`mailto:${Config.HELP_CONTACTS.address}?subject=${Config.HELP_CONTACTS.subject}`).catch(() => { });
    } catch (err) { }
  };

  return (
    <View onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}>
      <Modal animationType={'fade'} transparent={true} onRequestClose={closeHandler}>
        <LinearGradient colors={['rgba(0,42,85,0.95)', 'rgba(0,42,85,0.5)']} style={getResponsive().gradient}>
          <View style={getResponsive().wrapper}>
            <PopupSuccessIcon />
            <Text style={Style.h2}>{title}</Text>
            {text && <Text style={Style.text}>{text}</Text>}
            <PrimaryButton onPress={closeHandler} size={'small'} style={Style.button}>
              <Text>{button}</Text>
            </PrimaryButton>
            {help && (
              <SecondaryButton onPress={handleHelp} size={'xsmall'} style={Style.helpButton}>Richiesta assistenza</SecondaryButton>
            )}
          </View>
        </LinearGradient>
      </Modal>
    </View>
  )
};

SuccessPopup.propTypes = {
  help: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  button: PropTypes.string,
};

export default SuccessPopup;
