import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getEditorialChallenges: ['loader', 'from'],
  setEditorialChallenges: ['data'],
  mergeEditorialChallenges: ['data'],
  setEditorialChallengesError: ['error'],
  setLoadMoreEditorialChallenges: ['status'],
  getUserChallenges: ['loader', 'from'],
  setUserChallenges: ['data'],
  mergeUserChallenges: ['data'],
  setUserErrorChallenges: ['error'],
  setLoadMoreUserChallenges: ['status'],
  getChallenge: ['data'],
  setChallenge: ['data'],
  createChallenge: ['data'],
  createChallengeSuccess: ['status'],
  joinChallenge: ['data', 'join'],
  setChallengesResults: ['data'],
  fetchChallengeProgress: ['challenge', 'challenge_type'],
  addChallengeWon: ['challenge'],
  removeChallengeWon: ['challenge_id'],
});

export const ChallengesTypes = Types;
export default Creators;
