import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { S3Types } from './Actions'

export const uploadError = (state, { error }) => ({
  ...state,
  error,
});

export const uploadSuccess = (state, { data }) => ({
  ...state,
  data,
});

export const uploadReset = (state) => ({
  ...state,
  error: null,
  data: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [S3Types.S3_UPLOAD_ERROR]: uploadError,
  [S3Types.S3_UPLOAD_SUCCESS]: uploadSuccess,
  [S3Types.S3_UPLOAD_RESET]: uploadReset,
});
