import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';
import { useDispatch } from 'react-redux';

import Style, { getResponsive } from './GoalPopupStyle';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';
import { Trophy } from '../../../Theme/Icons';
import { Modal } from '../../Layout';
import { PrimaryButton } from '../../Buttons';

import ChallengesActions from '../../../Stores/Challenges/Actions';

const GoalPopup = ({ challenge }) => {

  const dispatch = useDispatch();
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  const closeHandler = () => {
    dispatch(ChallengesActions.removeChallengeWon(challenge.id));
  };

  return (
    <View onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}>
      <Modal animationType={'fade'} transparent={true} onRequestClose={closeHandler}>
        <LinearGradient colors={['rgba(0,42,85,0.95)', 'rgba(0,42,85,0.5)']} style={getResponsive().gradient}>
          <View style={getResponsive().wrapper}>
            <Trophy />
            <Text style={Style.h2}>{'Complimenti, obiettivo completato!'}</Text>
            <Text style={Style.text}>Hai raggiunto il traguardo di {challenge.title.toUpperCase()}, continua così!</Text>
            <PrimaryButton onPress={closeHandler} size={'small'} style={Style.button}>
              <Text>OK</Text>
            </PrimaryButton>
          </View>
        </LinearGradient>
      </Modal>
    </View>
  )
};

GoalPopup.propTypes = {
  challenge: PropTypes.object.isRequired,
};

export default GoalPopup;
