import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, Text, ImageBackground, ScrollView, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-native';

import { getResponsive } from './HomeStyle';
import { Colors } from '../../../Theme';
import { RightArrow } from '../../../Theme/Icons';
import { Button } from '../../Buttons';
import { Footer, PageHeader } from '../../Layout';

import ResponsiveHelper, { isDesktop } from '../../../Services/ResponsiveHelper';
import HelperActions from '../../../Stores/Helper/Actions';

const Home = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const Style = getResponsive();
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  const services = useSelector(state => state.helper.services);

  const PlayButton = ({ route, path, background, backgroundWeb, title, subTitle }) => (
    <Button style={Style.box} route={route} onPress={() => { if (path) history.push(`/servizi/${path}`); dispatch(HelperActions.sendCRMEvent(`event_open_play_${route||path}`)); }}>
      <ImageBackground source={{ uri: isDesktop() && backgroundWeb ? backgroundWeb : background }} style={Style.boxBackground}>
        <View style={Style.innerBox}>
          <Text style={Style.title}>{title}</Text>
          <Text style={Style.text}>{subTitle}</Text>
        </View>
        {isDesktop() && (
          <View style={Style.arrowContainer}>
            <RightArrow color={Colors.white} stroke={2} shadow={true} />
          </View>
        )}
      </ImageBackground>
    </Button>
  );

  PlayButton.propTypes = {
    route: PropTypes.string,
    path: PropTypes.string,
    background: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    backgroundWeb: PropTypes.string,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
  };

  return (
    <ScrollView onLayout={() => setBreakpoint(ResponsiveHelper.getSize())} bounces={false} contentContainerStyle={Style.wrapper}>
      <PageHeader back={false} mobile={false} title={'Gioca con Runcard'} desc={'Scopri i servizi Runcard pensati per te!'} />
      <View style={Style.container}>
        {services && services.map((service, key) => (
          ((service.enabled.mobile && Platform.OS !== 'web') || (service.enabled.web && Platform.OS === 'web')) && (
            <PlayButton key={key} route={service.type === 'custom' ? service.id : undefined} path={service.type !== 'custom' ? service.path : undefined} background={service.image.mobile} backgroundWeb={service.image.web} title={service.title.toUpperCase()} subTitle={service.text} />
          )
        ))}
      </View>
      <Footer />
    </ScrollView>
  );
};

export default Home;
