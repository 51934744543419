import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path } from 'react-native-svg';

const Logo = ({ width=255 }) => (
  <Svg width={width} height={Math.round((width*100)/255)} viewBox="0 0 255 100">
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <Path
        d="M73.066224,76.898448 C72.823344,76.153968 72.205584,75.647088 71.123184,75.361968 C70.589904,75.198288 70.146384,75.092688 69.782064,75.024048 C69.359664,74.929008 69.042864,74.881488 68.736624,74.844528 C67.516944,74.812848 66.471504,75.340848 65.642544,76.338768 L50.198544,95.367888 C49.649424,96.070128 49.406544,96.730128 49.485744,97.379568 C49.554384,97.796688 49.765584,98.345808 50.446704,98.805168 L50.642064,98.910768 C50.837424,99.011088 51.127824,99.116688 51.507984,99.248688 C51.856464,99.375408 52.305264,99.507408 52.833264,99.634128 L52.838544,99.644688 C54.448944,100.067088 55.885104,99.586608 56.967504,98.277168 L72.406224,79.232208 C73.082064,78.419088 73.282704,77.627088 73.066224,76.898448 L73.066224,76.898448 Z"
        id="Fill-1" fill="#00ADEE" />
      <Path
        d="M99.187968,78.625008 C99.114048,78.313488 99.040128,78.001968 98.918688,77.605968 C98.786688,77.246928 98.644128,76.808688 98.454048,76.285968 C98.242848,75.742128 97.186848,74.701968 97.128768,74.649168 L79.836768,57.109008 C79.203168,56.480688 78.548448,56.179728 77.872608,56.216688 C77.492448,56.216688 76.885248,56.375088 76.404768,56.992848 L76.256928,57.177648 C76.135488,57.383568 76.003488,57.647568 75.834528,58.022448 C75.686688,58.349808 75.501888,58.772208 75.306528,59.284368 L75.311808,59.294928 C74.699328,60.826128 75.042528,62.341488 76.246368,63.534768 L91.241568,78.593328 L77.107008,92.675088 C75.908448,93.863088 75.449088,95.241168 76.029888,96.793488 L76.029888,96.809328 C76.241088,97.305648 76.383648,97.738608 76.563168,98.081808 C76.726848,98.446128 76.848288,98.720688 76.953888,98.910768 L77.085888,99.121968 C77.587488,99.723888 78.194688,99.871728 78.585408,99.903408 C79.240128,99.935088 79.963488,99.702768 80.597088,99.079728 L97.989408,81.819408 C98.902848,80.921808 99.330528,79.818288 99.187968,78.625008"
        id="Fill-3" fill="#00ADEE" />
      <Path
        d="M106.303824,29.323536 L105.976464,29.318256 C103.700784,29.365776 102.280464,29.962416 101.726064,31.076496 L93.874704,48.083376 L79.660944,30.564336 C79.064304,29.840976 78.119184,29.476656 76.867824,29.466096 L76.693584,29.466096 C75.405264,29.503056 74.164464,29.825136 73.002864,30.432336 C71.978544,31.002576 71.318544,31.625616 70.954224,32.354256 L61.091184,54.139536 C60.779664,54.725616 60.922224,55.116336 61.091184,55.359216 C61.444944,55.881936 62.247504,56.130096 63.667824,56.135376 L63.995184,56.130096 C66.265584,56.087856 67.685904,55.507056 68.240304,54.387696 L76.065264,37.053456 L90.416304,55.016016 C91.012944,55.728816 91.958064,56.098416 93.219984,56.108976 L93.399504,56.108976 C94.682544,56.077296 95.928624,55.765776 97.063824,55.148016 C98.109264,54.561936 98.790384,53.954736 99.133584,53.241936 L108.875184,31.324656 C109.165584,30.749136 109.044144,30.342576 108.880464,30.094416 C108.516144,29.582256 107.724144,29.328816 106.303824,29.323536"
        id="Fill-5" fill="#00ADEE" />
      <Path
        d="M106.424736,10.66032 C106.408896,16.50528 101.630496,21.24144 95.780256,21.2151498 C89.908896,21.18864 85.193856,16.41024 85.2043983,10.56528 C85.246656,4.70448 90.025056,-0.0264 95.870016,-0.000109955525 C101.720256,0.0264 106.461696,4.79424 106.424736,10.66032"
        id="Fill-7" fill="#00ADEE" />
      <Path
        d="M26.18352,32.55912 C25.5552,33.97416 24.40416,34.66584 22.77792,34.66056 C22.52976,34.66056 22.22352,34.64472 21.78528,34.6236 C21.37344,34.58136 21.05136,34.57608 20.84016,34.5708 C16.58976,34.54968 13.98144,35.6268 12.9888,37.78632 L5.84496,53.37816 C5.3592,54.41832 4.6728,55.06776 3.80688,55.32648 C3.42144,55.4268 2.59776,55.50072 1.30416,55.4796 C0.01584,55.49016 -0.33264,54.75096 0.32736,53.33592 L7.4712,37.75992 C9.0024,34.4124 11.7216,32.13672 15.6288,30.954 C18.03648,30.22008 21.27312,29.85576 25.344,29.87688 C26.7168,29.88744 27.01248,30.77976 26.18352,32.55912"
        id="Fill-9" fill="#00ADEE" />
      <Path
        d="M58.440624,48.06384 C56.091024,53.23296 49.564944,55.77792 38.883504,55.72512 C38.577264,55.72512 38.202384,55.72512 37.748304,55.71456 C37.262544,55.71456 36.729264,55.69872 36.090384,55.67232 C36.085104,55.6512 36.011184,55.63536 35.889744,55.64592 L35.657424,55.64592 L35.250864,55.63536 L34.490544,55.63008 L33.302544,55.63536 C24.342384,55.59312 21.010704,53.0112 23.349744,47.91072 L30.625584,32.01792 C31.285584,30.60816 32.278224,29.90592 33.651024,29.9111704 C34.992144,29.92176 35.768304,29.9904 35.937264,30.1224 C36.560304,30.38112 36.613104,31.04112 36.143184,32.04432 L28.872624,47.9424 C27.975024,49.896 30.176784,50.86224 35.467344,50.88864 L43.725264,50.93088 C48.915504,50.94672 51.951504,50.028 52.812144,48.13248 L60.146064,32.14992 C60.615984,31.14672 61.275984,30.50256 62.141904,30.23856 C62.416464,30.11712 63.277104,30.05376 64.639344,30.05904 C66.006864,30.0696 66.371184,30.77712 65.742864,32.1816 L58.440624,48.06384 Z"
        id="Fill-11" fill="#00ADEE" />
      <Path
        d="M140.647056,54.00384 C140.018736,55.42416 138.841296,56.1264 137.120016,56.1159573 L116.660016,56.01552 C112.736976,55.98912 110.065296,55.61952 108.703056,54.82224 C106.300656,53.60256 105.820176,51.39552 107.277456,48.19584 L111.918576,38.08992 C114.294576,32.89968 119.416176,30.31248 127.262256,30.34944 L149.163696,30.45504 C150.599856,30.46032 150.958896,31.26288 150.235536,32.83632 C149.506896,34.43088 148.413936,35.22288 146.998896,35.21232 L125.472336,35.112 C121.047696,35.09616 118.391856,36.03072 117.510096,37.94208 L112.905936,47.9688 C111.923856,50.15472 113.940816,51.25296 118.983216,51.27936 L139.791696,51.3744 C141.164496,51.3744 141.449616,52.26144 140.647056,54.00384"
        id="Fill-13" fill="#FEFEFE" />
      <Path
        d="M140.647056,54.00384 C140.018736,55.42416 138.841296,56.1264 137.120016,56.1159573 L116.660016,56.01552 C112.736976,55.98912 110.065296,55.61952 108.703056,54.82224 C106.300656,53.60256 105.820176,51.39552 107.277456,48.19584 L111.918576,38.08992 C114.294576,32.89968 119.416176,30.31248 127.262256,30.34944 L149.163696,30.45504 C150.599856,30.46032 150.958896,31.26288 150.235536,32.83632 C149.506896,34.43088 148.413936,35.22288 146.998896,35.21232 L125.472336,35.112 C121.047696,35.09616 118.391856,36.03072 117.510096,37.94208 L112.905936,47.9688 C111.923856,50.15472 113.940816,51.25296 118.983216,51.27936 L139.791696,51.3744 C141.164496,51.3744 141.449616,52.26144 140.647056,54.00384 Z"
        id="Stroke-15" stroke="#FEFEFE" strokeWidth="0.5" />
      <Path
        d="M179.648832,47.249136 C179.759712,47.011536 179.896992,46.689456 180.060672,46.304016 C180.224352,45.929136 180.414432,45.501456 180.630912,44.999856 C180.857952,44.503536 181.042752,44.049456 181.206432,43.679856 C181.348992,43.310256 181.496832,42.998736 181.607712,42.750576 L161.992512,42.660816 C160.820352,42.655536 159.537312,42.866736 158.116992,43.283856 C156.279552,43.838256 155.154912,44.545776 154.743072,45.438096 L153.269952,48.680016 C152.847552,49.619856 153.333312,50.343216 154.732512,50.865936 C155.608992,51.240816 156.733632,51.457296 158.095872,51.467856 L171.850272,51.531216 C173.893632,51.531216 175.398432,51.330576 176.359392,50.929296 C177.679392,50.385456 178.804032,49.155216 179.648832,47.249136 L179.648832,47.249136 Z M184.453632,48.606096 C182.104032,53.743536 177.161952,56.309616 169.627392,56.262096 L156.105312,56.198736 C153.196032,56.182896 150.946752,55.628496 149.331072,54.535536 C147.102912,53.136336 146.596032,51.182736 147.710112,48.711696 L149.204352,45.443376 C150.445152,42.766416 152.720832,40.765296 156.031392,39.445296 C158.518272,38.426256 161.274432,37.903536 164.284032,37.924656 L183.825312,38.009136 C184.379712,36.224496 182.278272,35.295216 177.594912,35.268816 C177.388992,35.263536 177.140832,35.279376 176.887392,35.284656 C176.586432,35.295216 176.274912,35.300496 175.926432,35.326896 C175.915872,35.337456 175.810272,35.353296 175.657152,35.353296 L175.398432,35.342736 L174.997152,35.342736 L174.358272,35.337456 C172.974912,35.332176 172.710912,34.450416 173.534592,32.686896 C174.141792,31.287696 175.160832,30.574896 176.533632,30.5801469 C180.937152,30.606576 184.173792,30.944496 186.227712,31.556976 C189.733632,32.734416 190.673472,35.078736 189.073632,38.547696 L184.453632,48.606096 Z"
        id="Fill-17" fill="#FEFEFE" />
      <Path
        d="M179.648832,47.249136 C179.759712,47.011536 179.896992,46.689456 180.060672,46.304016 C180.224352,45.929136 180.414432,45.501456 180.630912,44.999856 C180.857952,44.503536 181.042752,44.049456 181.206432,43.679856 C181.348992,43.310256 181.496832,42.998736 181.607712,42.750576 L161.992512,42.660816 C160.820352,42.655536 159.537312,42.866736 158.116992,43.283856 C156.279552,43.838256 155.154912,44.545776 154.743072,45.438096 L153.269952,48.680016 C152.847552,49.619856 153.333312,50.343216 154.732512,50.865936 C155.608992,51.240816 156.733632,51.457296 158.095872,51.467856 L171.850272,51.531216 C173.893632,51.531216 175.398432,51.330576 176.359392,50.929296 C177.679392,50.385456 178.804032,49.155216 179.648832,47.249136 Z M184.453632,48.606096 C182.104032,53.743536 177.161952,56.309616 169.627392,56.262096 L156.105312,56.198736 C153.196032,56.182896 150.946752,55.628496 149.331072,54.535536 C147.102912,53.136336 146.596032,51.182736 147.710112,48.711696 L149.204352,45.443376 C150.445152,42.766416 152.720832,40.765296 156.031392,39.445296 C158.518272,38.426256 161.274432,37.903536 164.284032,37.924656 L183.825312,38.009136 C184.379712,36.224496 182.278272,35.295216 177.594912,35.268816 C177.388992,35.263536 177.140832,35.279376 176.887392,35.284656 C176.586432,35.295216 176.274912,35.300496 175.926432,35.326896 C175.915872,35.337456 175.810272,35.353296 175.657152,35.353296 L175.398432,35.342736 L174.997152,35.342736 L174.358272,35.337456 C172.974912,35.332176 172.710912,34.450416 173.534592,32.686896 C174.141792,31.287696 175.160832,30.574896 176.533632,30.5801469 C180.937152,30.606576 184.173792,30.944496 186.227712,31.556976 C189.733632,32.734416 190.673472,35.078736 189.073632,38.547696 L184.453632,48.606096 Z"
        id="Stroke-19" stroke="#FEFEFE" strokeWidth="0.5" />
      <Path
        d="M212.555904,33.415008 C211.906464,34.835328 210.750144,35.527008 209.113344,35.527008 C208.875744,35.527008 208.574784,35.505888 208.173504,35.490048 C207.751104,35.442528 207.418464,35.431968 207.191424,35.431968 C202.946304,35.421408 200.327424,36.477408 199.334784,38.652768 L192.190944,54.234048 C191.705184,55.279488 191.024064,55.934208 190.137024,56.198208 C189.767424,56.282688 188.938464,56.356608 187.650144,56.346048 C186.340704,56.346048 186.013344,55.617408 186.673344,54.202368 L193.817184,38.615808 C195.353664,35.273568 198.078144,32.997888 201.985344,31.831008 C204.387744,31.091808 207.645504,30.716928 211.700544,30.743328 C213.094464,30.748608 213.358464,31.640928 212.555904,33.415008"
        id="Fill-21" fill="#FEFEFE" />
      <Path
        d="M212.555904,33.415008 C211.906464,34.835328 210.750144,35.527008 209.113344,35.527008 C208.875744,35.527008 208.574784,35.505888 208.173504,35.490048 C207.751104,35.442528 207.418464,35.431968 207.191424,35.431968 C202.946304,35.421408 200.327424,36.477408 199.334784,38.652768 L192.190944,54.234048 C191.705184,55.279488 191.024064,55.934208 190.137024,56.198208 C189.767424,56.282688 188.938464,56.356608 187.650144,56.346048 C186.340704,56.346048 186.013344,55.617408 186.673344,54.202368 L193.817184,38.615808 C195.353664,35.273568 198.078144,32.997888 201.985344,31.831008 C204.387744,31.091808 207.645504,30.716928 211.700544,30.743328 C213.094464,30.748608 213.358464,31.640928 212.555904,33.415008 Z"
        id="Stroke-23" stroke="#FEFEFE" strokeWidth="0.5" />
      <Path
        d="M238.275312,48.559104 L244.104432,35.860704 L227.456592,35.776224 C222.519792,35.749824 219.547152,36.863904 218.528112,39.092064 L214.362192,48.178944 C213.596592,49.842144 214.040112,50.919264 215.692752,51.415584 C216.410832,51.610944 217.920912,51.737664 220.281072,51.748224 L228.253872,51.785184 C233.935152,51.816864 237.287952,50.745024 238.275312,48.559104 L238.275312,48.559104 Z M243.761232,48.680544 C242.515152,51.431424 239.896272,53.506464 235.894032,54.926784 C233.037552,56.014464 229.954032,56.553024 226.622352,56.526624 L218.184912,56.484384 C209.657712,56.447424 206.347152,54.002784 208.295472,49.166304 C208.617552,48.321504 209.124432,47.186304 209.752752,45.797664 C211.051632,42.993984 212.144592,40.728864 213.052752,38.954784 C215.803632,33.637824 221.384592,31.003104 229.790352,31.040064 L246.269232,31.119264 L248.566032,26.103264 C249.204912,24.709344 250.208112,24.007104 251.570352,24.0123544 C252.557712,24.017664 253.333872,24.096864 253.824912,24.239424 C254.453232,24.508704 254.558832,25.131744 254.094192,26.134944 L243.761232,48.680544 Z"
        id="Fill-25" fill="#FEFEFE" />
      <Path
        d="M238.275312,48.559104 L244.104432,35.860704 L227.456592,35.776224 C222.519792,35.749824 219.547152,36.863904 218.528112,39.092064 L214.362192,48.178944 C213.596592,49.842144 214.040112,50.919264 215.692752,51.415584 C216.410832,51.610944 217.920912,51.737664 220.281072,51.748224 L228.253872,51.785184 C233.935152,51.816864 237.287952,50.745024 238.275312,48.559104 Z M243.761232,48.680544 C242.515152,51.431424 239.896272,53.506464 235.894032,54.926784 C233.037552,56.014464 229.954032,56.553024 226.622352,56.526624 L218.184912,56.484384 C209.657712,56.447424 206.347152,54.002784 208.295472,49.166304 C208.617552,48.321504 209.124432,47.186304 209.752752,45.797664 C211.051632,42.993984 212.144592,40.728864 213.052752,38.954784 C215.803632,33.637824 221.384592,31.003104 229.790352,31.040064 L246.269232,31.119264 L248.566032,26.103264 C249.204912,24.709344 250.208112,24.007104 251.570352,24.0123544 C252.557712,24.017664 253.333872,24.096864 253.824912,24.239424 C254.453232,24.508704 254.558832,25.131744 254.094192,26.134944 L243.761232,48.680544 Z"
        id="Stroke-27" stroke="#FEFEFE" strokeWidth="0.5" />
    </G>
  </Svg>
);

Logo.propTypes = {
  width: PropTypes.number,
};

export default Logo;
