import { Platform } from 'react-native';
import { put, call, select } from 'redux-saga/effects';
import { generatePath } from 'react-router-native';
import moment from 'moment';
import { Config } from '../Config';

import ChallengesActions from '../Stores/Challenges/Actions';
import LayoutActions from '../Stores/Layout/Actions';
import NavigationActions from '../Stores/Navigation/Actions';
import HelperActions from '../Stores/Helper/Actions';
import WorkoutActions from '../Stores/Workout/Actions';
import { fetchGoogleFitData, fetchSaluteData, fetchStravaData } from './WorkoutSaga';
import { fetchEditorialChallenges, fetchUserChallenges, fetchChallenge, createChallenge as createChallengeApi, joinChallenge as joinChallengeApi, completeChallenge } from '../Services/ApiGatewayService';

import { getPath } from '../Router/AppRoutes';
import ServicesActions from "../Stores/Services/Actions";

export function* getEditorialChallenges({ loader=true, from=null }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  yield put(ChallengesActions.setEditorialChallengesError(null));
  try {
    const data = yield call(fetchEditorialChallenges, { from });

    if (data.length === 10) {
      yield put(ChallengesActions.setLoadMoreEditorialChallenges(true));
    } else {
      yield put(ChallengesActions.setLoadMoreEditorialChallenges(false));
    }

    yield put(ChallengesActions.setEditorialChallenges(data));
  } catch (err) {
    console.log('getEditorialChallenges ERROR', err);
    yield put(ChallengesActions.setEditorialChallengesError(err));
    yield put(ChallengesActions.setEditorialChallenges(null));
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}

export function* getUserChallenges({ loader=true, from=null }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  yield put(ChallengesActions.setUserErrorChallenges(null));
  try {
    const data = yield call(fetchUserChallenges, { from });

    if (data.length === 10) {
      yield put(ChallengesActions.setLoadMoreUserChallenges(true));
    } else {
      yield put(ChallengesActions.setLoadMoreUserChallenges(false));
    }

    yield put(ChallengesActions.setUserChallenges(data));
  } catch (err) {
    console.log('getUserChallenges ERROR', err);
    yield put(ChallengesActions.setUserErrorChallenges(err));
    yield put(ChallengesActions.setUserChallenges(null));
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}

export function* getChallenge({ data }) {
  const { uuid, type } = data;
  yield put(LayoutActions.setLoading(true));
  try {
    const data = yield call(fetchChallenge, { uuid, type });
    yield put(ChallengesActions.setChallenge(data));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      yield put(ChallengesActions.setChallenge(false));
    } else {
      console.log('getChallenge ERROR', err);
      yield put(LayoutActions.setError(err));
      yield put(ChallengesActions.setChallenge(null));
    }
  }
  yield put(LayoutActions.setLoading(false));
}

export function* createChallenge({ data }) {
  yield put(LayoutActions.setLoading(true));
  yield put(ChallengesActions.createChallengeSuccess(null));
  try {
    const challenge = yield call(createChallengeApi, data);
    const previousChallenges = yield select(state => state.challenges.user_challenges);
    previousChallenges.push(challenge);
    yield put(ChallengesActions.setUserChallenges(previousChallenges));
    yield put(ChallengesActions.createChallengeSuccess(true));
    yield put(NavigationActions.pushNavigation(generatePath(getPath('challengeUser'), { id: challenge.id })));
  } catch (err) {
    console.log('createChallenge ERROR', err);
    yield put(ChallengesActions.createChallengeSuccess(false));
  }
  yield put(LayoutActions.setLoading(false));
}

export function* joinChallenge({ data, join }) {
  const { uuid, type } = data;
  yield put(LayoutActions.setLoading(true));
  try {
    yield call(joinChallengeApi, { uuid, type, join });
    if (join) {
      let challenges = yield select(state => state.challenges.editorial_challenges);
      challenges = challenges.map(el => ({ ...el, joined: uuid===el.id?true:el.joined, users:el.id?el.users+1:el.users }));
      yield put(ChallengesActions.setEditorialChallenges(challenges));
      let challenge = yield select(state => state.challenges.challenge);
      if (challenge && challenge.id === uuid) {
        yield put(ChallengesActions.setChallenge({ ...challenge, joined: true, completed: 0, users: challenge.users+1 }));
      }
    } else {
      if (type === 'editorial') {
        let challenges = yield select(state => state.challenges.editorial_challenges);
        challenges = challenges.map(el => ({ ...el, joined: uuid===el.id?false:el.joined, users:el.id?el.users-1:el.users }));
        yield put(ChallengesActions.setEditorialChallenges(challenges));
        let challenge = yield select(state => state.challenges.challenge);
        if (challenge && challenge.id === uuid) {
          yield put(ChallengesActions.setChallenge({ ...challenge, joined: false, users: challenge.users-1 }));
        }
      } else {
        let challenges = yield select(state => state.challenges.user_challenges);
        challenges = challenges.filter(el => el.id !== uuid);
        yield put(ChallengesActions.setUserChallenges(challenges));
        yield put(ChallengesActions.setChallenge(null));
        yield put(NavigationActions.pushNavigation('challengesUser'));
      }
    }
  } catch (err) {
    console.log('joinChallenge ERROR', err);
    yield put(LayoutActions.setError({}));
  }
  yield put(LayoutActions.setLoading(false));
}

export function* checkCompletedChallenges() {
  if (Platform.OS === 'web') return;

  try {
    let userChallenge = yield select(state => state.challenges.user_challenges);
    userChallenge = userChallenge.filter(challenge => !challenge.completed && challenge.completed !== 0);
    for (let i=0; i<userChallenge.length; i++) {
      yield fetchChallengeProgress({ challenge: userChallenge[i], challenge_type: 'user' });
    }
    let editorialChallenge = yield select(state => state.challenges.editorial_challenges);
    editorialChallenge = editorialChallenge.filter(challenge => challenge.joined && !challenge.completed && challenge.completed !== 0);
    for (let i=0; i<editorialChallenge.length; i++) {
      yield fetchChallengeProgress({ challenge: editorialChallenge[i], challenge_type: 'editorial' });
    }
  } catch (err) {
    console.log('fetchChallengeProgress ERROR', err);
  }
}

export function* fetchChallengeProgress({ challenge, challenge_type }) {
  if (Platform.OS === 'web') return;
  try {
    const fetched = yield select(state => state.workout.workouts_fetched);

    yield put(ChallengesActions.setChallengesResults({ [challenge_type === 'editorial' ? challenge.user_challenge_id : challenge.id]: 'LOADING' }));

    const { google_fit, strava, salute } = yield select(state => state.services);

    const cacheKey = `${challenge.start_date}-${challenge.end_date}`;

    const from = moment(challenge.start_date, 'YYYYMMDD');
    const to = moment(challenge.end_date, 'YYYYMMDD');

    let totKm = 0;
    if (google_fit) {
      try {
        let res;
        if (typeof fetched[cacheKey] === 'undefined') {
          res = yield call(fetchGoogleFitData, from, to);
          yield put(WorkoutActions.setWorkoutsFetched({ ...fetched, [cacheKey]: res }));
        } else {
          res = fetched[cacheKey];
        }

        totKm = res.filter(data => Config.GOOGLE_FIT.workout_activities.indexOf(data.activityName)!==-1).reduce((acc, el) => { acc += Math.round(el.distance/1000); return acc; }, totKm);
      } catch (err) {
        console.log('ERROR: Fetching from GoogleFit', err);
      }
    }
    if (salute) {
      try {
        let res;
        if (typeof fetched[cacheKey] === 'undefined') {
          res = yield call(fetchSaluteData, from, to);
          yield put(WorkoutActions.setWorkoutsFetched({ ...fetched, [cacheKey]: res }));
        } else {
          res = fetched[cacheKey];
        }

        totKm = res.filter(data => data.activityName === 'Running').reduce((acc, el) => { acc += Math.round(el.distance/0.62137); return acc; }, totKm);
      } catch (err) {
        console.log('ERROR: Fetching from Salute', err);
      }
    }
    if (strava) {
      try {
        let res;
        if (typeof fetched[cacheKey] === 'undefined') {
          let strava_data = yield select(state => state.services.strava_data);
          const { data, stravaToken } = yield call(fetchStravaData, strava_data, from, to);
          yield put(WorkoutActions.setWorkoutsFetched({ ...fetched, [cacheKey]: data }));
          res = data;

          const { access_token, refresh_token, expires_at, athlete_id } = stravaToken;
          yield put(ServicesActions.setStravaData(access_token, refresh_token, expires_at, athlete_id));
        } else {
          res = fetched[cacheKey];
        }

        totKm = res.reduce((acc, el) => { acc += Math.round(el.distance/1000); return acc; }, totKm);
      } catch (err) {
        console.log('ERROR: Fetching from Strava', err);
      }
    }

    // se scaduto o se completato
    if (to.isBefore(moment().startOf('day')) || totKm >= challenge.km) {
      yield call(completeChallenge, { uuid: challenge_type === 'editorial' ? challenge.user_challenge_id : challenge.id, type: 'user', km: totKm });

      if (totKm >= challenge.km) {
        // Popup
        yield put(ChallengesActions.addChallengeWon(challenge));
        // Leaderboard
        if (challenge_type === 'editorial') {
          yield put(HelperActions.sendLeaderboardEvent('challenge-completed', challenge.score));
        } else {
          yield put(HelperActions.sendLeaderboardEvent('target-completed', challenge.km));
        }
      }

      let challenges = yield select(state => challenge_type === 'editorial' ? state.challenges.editorial_challenges : state.challenges.user_challenges);
      challenges = challenges.map(el => ({ ...el, completed: el.id===challenge.id?totKm:el.completed }));
      if (challenge_type === 'editorial') {
        yield put(ChallengesActions.setEditorialChallenges(challenges));
      } else {
        yield put(ChallengesActions.setUserChallenges(challenges));
      }

      let selectedChallenge = yield select(state => state.challenges.challenge);
      if (selectedChallenge && selectedChallenge.id === challenge.id) {
        yield put(ChallengesActions.setChallenge({ ...challenge, completed: totKm }));
      }
    } else {
      yield put(ChallengesActions.setChallengesResults({ [challenge_type === 'editorial' ? challenge.user_challenge_id : challenge.id]: totKm }));
    }
  } catch (err) {
    console.log('fetchChallengeProgress ERROR', err);
    yield put(ChallengesActions.setChallengesResults({ [challenge_type === 'editorial' ? challenge.user_challenge_id : challenge.id]: 'ERROR' }));
  }
}
