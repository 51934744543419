import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { View, Text } from "react-native";
import { useHistory } from "react-router-native";

import { getResponsive } from "./TabNavStyle";
import { Button } from "../../Buttons";
import {
  TabActivityIcon,
  TabNewsIcon,
  TabPlayIcon,
  TabRaceIcon,
  TabRuncardIcon,
  TabHomeIcon,
  TabRuncardButton,
} from "../../../Theme/Icons";

import LayoutActions from "../../../Stores/Layout/Actions";
import NavigationActions from "../../../Stores/Navigation/Actions";
import HelperActions from "../../../Stores/Helper/Actions";
import { getPath } from "../../../Router/Router";
import { isDesktop } from "../../../Services/ResponsiveHelper";

const TabNavButtons = ({ size = "regular", background = false }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const tab = useSelector((state) => state.layout.tab);

  const Style = getResponsive();
  let history = useHistory();

  const iconSizes = {
    regular: 28,
    big: 55,
  };

  const resetHistory = () => {
    history.entries = [];
    history.index = -1;
  };

  const TabButton = ({ onPress, route, text, icon, allowBack = false }) => {
    const action = onPress
      ? onPress
      : () => {
          if (!allowBack) {
            resetHistory();
          }
          dispatch(HelperActions.sendCRMEvent(`event_open_${route}`));
          history.push(getPath(route));
        };
    return (
      <View
        style={[
          Style.buttonContainer,
          size === "big" ? Style.buttonContainerBig : {},
        ]}
      >
        <Button onPress={action} style={Style.button}>
          <View
            style={[
              Style.iconContainer,
              size === "big" ? Style.iconContainerBig : {},
            ]}
          >
            {icon}
          </View>
          <Text
            style={[
              Style.buttonText,
              route && tab === route.toUpperCase()
                ? Style.buttonTextActive
                : {},
            ]}
          >
            {text}
          </Text>
        </Button>
      </View>
    );
  };

  TabButton.propTypes = {
    onPress: PropTypes.func,
    route: PropTypes.string,
    text: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    allowBack: PropTypes.bool,
  };

  const RuncardButton = () => (
    <Button
      onPress={() => {
        if (user && user.runcard) {
          history.entries = [];
          history.index = -1;
        }
        history.push(getPath(user && user.runcard ? "runcard" : "linkRuncard"));
      }}
      style={[Style.button, Style.runcardButton]}
    >
      <View
        style={[
          Style.iconContainerRuncard,
          tab === "RUNCARD" ? Style.iconContainerRuncardActive : {},
        ]}
      >
        <TabRuncardButton active={tab === "RUNCARD"} />
      </View>
    </Button>
  );

  const openLogin = (route) => {
    dispatch(LayoutActions.toggleLoginModal(true));
    dispatch(NavigationActions.setAfterLogin(route));
  };

  return (
    <View
      style={[
        Style.buttonWrapper,
        background ? Style.buttonWrapperAbsolute : {},
      ]}
    >
      {!isDesktop() && <View style={Style.whiteSpace} />}
      {isDesktop() && (
        <TabButton
          route={"homepage"}
          text={"HOME"}
          icon={
            <TabHomeIcon active={tab === "HOMEPAGE"} size={iconSizes[size]} />
          }
        />
      )}
      {!isDesktop() || user ? (
        <TabButton
          route={"races"}
          text={"Gare"}
          icon={<TabRaceIcon active={tab === "RACES"} size={iconSizes[size]} />}
        />
      ) : (
        <TabButton
          onPress={() => openLogin("play")}
          text={"Gare"}
          icon={<TabRaceIcon active={tab === "RACES"} size={iconSizes[size]} />}
        />
      )}
      {!isDesktop() || user ? (
        <TabButton
          route={"activities"}
          text={"Attività"}
          icon={
            <TabActivityIcon
              active={tab === "ACTIVITIES"}
              size={iconSizes[size]}
            />
          }
        />
      ) : (
        <TabButton
          onPress={() => openLogin("play")}
          text={"Attività"}
          icon={
            <TabActivityIcon
              active={tab === "ACTIVITIES"}
              size={iconSizes[size]}
            />
          }
        />
      )}
      {!isDesktop() ? (
        <RuncardButton />
      ) : user ? (
        <TabButton
          route={user.runcard ? "runcard" : "linkRuncard"}
          text={"Runcard"}
          icon={
            <TabRuncardIcon
              active={tab === "RUNCARD"}
              size={iconSizes[size]}
              allowBack={!user.runcard}
            />
          }
        />
      ) : (
        <TabButton
          onPress={() => openLogin("runcard")}
          text={"Runcard"}
          icon={
            <TabRuncardIcon active={tab === "RUNCARD"} size={iconSizes[size]} />
          }
        />
      )}
      {!isDesktop() || user ? (
        <TabButton
          route={"play"}
          text={"Servizi"}
          icon={<TabPlayIcon active={tab === "PLAY"} size={iconSizes[size]} />}
        />
      ) : (
        <TabButton
          onPress={() => openLogin("play")}
          text={"Servizi"}
          icon={<TabPlayIcon active={tab === "PLAY"} size={iconSizes[size]} />}
        />
      )}
      <TabButton
        route={"news"}
        text={"News"}
        icon={<TabNewsIcon active={tab === "NEWS"} size={iconSizes[size]} />}
      />
      {!isDesktop() && <View style={Style.whiteSpace} />}
    </View>
  );
};

TabNavButtons.propTypes = {
  size: PropTypes.oneOf(["big", "regular"]),
  background: PropTypes.bool,
  history: PropTypes.object,
};

export default TabNavButtons;
