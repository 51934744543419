import ResponsiveHelper from '../../../../Services/ResponsiveHelper';
import { ApplicationStyles, Colors, Fonts } from '../../../../Theme';

const BaseStyle = {
  accordionTitle: {
    ...ApplicationStyles.screen.fontsBold,
  },
  button: {
    marginTop: 30,
  },
  buttonContainer: {
    flexDirection:'row',
    paddingBottom: 30,
    marginTop: 20,
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
  },
  dip: {
    marginTop: 10,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dipA: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dipLink: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.cattolicaGreen,
    fontSize: Fonts.size.regular - 2,
  },
  title: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
    fontSize: Fonts.size.big,
    color: Colors.black,
  },
  titleBold: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.big,
    color: Colors.black,
  },
  readMoreText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular - 1,
    marginVertical: 20
  },
  readMoreBold: {
    ...ApplicationStyles.screen.fonts,
  },
  slideItemContent: {
    marginBottom: 20,
  },
  subtitle: {
    ...ApplicationStyles.screen.fonts,
    textAlign: 'center',
    fontSize: Fonts.size.big,
    color: Colors.cattolicaRed,
    marginBottom: 20,
  },
  switchContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 30,
  },
  switchInner: {
    flex: 1,
    marginLeft: 10,
  },
  switchMore: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.black,
    textDecorationLine: 'underline',
    fontSize: Fonts.size.small,
  },
  switchTitle: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.cattolicaRed,
    fontSize: Fonts.size.regular+1,
    marginBottom: 5,
  },
  switchTitleSelected: {
    color: Colors.blue1,
  },
  switchText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
    marginBottom: 10,
    flexWrap: 'wrap',
  },
  switchSubtitle: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.cattolicaRed,
    fontSize: Fonts.size.regular+1,
  },
  wrapper: {}
};

const StyleLG = {
  wrapper: {
    paddingHorizontal: 30,
  },
  subtitle: {
    marginBottom: 50,
  },
  switchContainer: {
    backgroundColor: Colors.white,
    padding: 30,
    paddingBottom: 0,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
