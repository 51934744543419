import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import Collapsible from './Collapsible';

import Style from './SlideItemStyle';
import { UpArrow, DownArrow } from '../../../Theme/Icons';
import { Button } from '../../Buttons';

const SlideItem = ({ open, title, text, callback, titleStyle, children=null, border=true, padding=15, customOpenArrow, customCloseArrow }) => {
  return (
    <View style={[{ paddingVertical: padding }, border ? Style.itemBorder : {}]}>
      <Button onPress={() => callback(!open)}>
        <View style={Style.titleContainer}>
          <Text style={titleStyle}>{title}</Text>
          {open ? !customCloseArrow ? <View style={Style.icon}><UpArrow /></View> : customCloseArrow : !customOpenArrow ? <View style={Style.icon}><DownArrow /></View> : customOpenArrow}
        </View>
      </Button>
      <Collapsible collapsed={!open}>
        {text && <Text style={Style.text}>{text}</Text>}
        {children}
      </Collapsible>
    </View>
  );
};

SlideItem.propTypes = {
  padding: PropTypes.number,
  open: PropTypes.bool,
  border: PropTypes.bool,
  title: PropTypes.oneOfType([ PropTypes.string, PropTypes.node ]),
  text: PropTypes.string,
  callback: PropTypes.func,
  children: PropTypes.node,
  titleStyle: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
  customOpenArrow: PropTypes.node,
  customCloseArrow: PropTypes.node,
};

export default SlideItem;
