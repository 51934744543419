import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path } from 'react-native-svg';

import { Colors } from '../../Theme';

const LogoutIcon = ({ size=20, color=Colors.white }) => (
  <Svg width={size} height={size} viewBox={'0 0 28 28'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <Path d={'M27.3,3.09258659 L27.3,23.2974134 C27.3,25.0026758 25.8656303,26.39 24.1025408,26.39 L3.21260215,26.39 C1.4495127,26.39 0.0151429688,25.0026758 0.0151429688,23.2974134 L0.0151429688,19.1739646 L2.14678242,19.1739646 L2.14678242,23.2974134 C2.14678242,23.8658342 2.62490566,24.3282756 3.21260215,24.3282756 L24.1025941,24.3282756 C24.6902906,24.3282756 25.1684139,23.8658342 25.1684139,23.2974134 L25.1684139,3.09258659 C25.1684139,2.5241658 24.6902906,2.06172439 24.1025941,2.06172439 L3.21260215,2.06172439 C2.62490566,2.06172439 2.14678242,2.5241658 2.14678242,3.09258659 L2.14678242,7.21603537 L0.0151429688,7.21603537 L0.0151429688,3.09258659 C0.0151429688,1.38732422 1.4495127,0 3.21260215,0 L24.1025941,0 C25.8656303,0 27.3,1.38732422 27.3,3.09258659 Z M6.62318262,6.78902356 L0,13.1949742 L6.62318262,19.6009249 L8.13044121,18.1431024 L4.08033691,14.2258364 L19.4129127,14.2258364 L19.4129127,12.164112 L4.08039023,12.164112 L8.13049453,8.24684599 L6.62318262,6.78902356 Z'} fill={color} />
    </G>
  </Svg>
);

LogoutIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default LogoutIcon;
