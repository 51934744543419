import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export const BaseStyle = {
  avatarContainer: {
    alignItems: 'center',
    marginBottom: 20,
  },
  backButton: {
    padding: 20,
    width: 60
  },
  consent: {
    marginTop: 10,
    marginBottom: 10,
  },
  closeContainer: {
    marginBottom: 10,
    marginLeft: 30,
    marginTop: 30,
  },
  conditionsButton: {
    flexDirection:'row',
    flexWrap: 'wrap',
    flex: 1,
    marginLeft: 10,
  },
  conditionsLink: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.small+1,
    textDecorationLine: 'underline',
  },
  conditionsText: {
    ...ApplicationStyles.screen.fontsRegular,
    fontSize: Fonts.size.small+1,
  },
  conditionsWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    marginVertical: 10,
  },
  container: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  containerWeb: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: 10,
    marginBottom: 30,
    marginTop: 30,
    maxWidth: 450,
    minWidth: 400,
  },
  error: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.red,
    fontSize: Fonts.size.regular-2,
    marginBottom: 10,
  },
  genderButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
    marginTop: 10,
  },
  h1: {
    ...ApplicationStyles.screen.h1,
    marginBottom: 20,
    textAlign: 'center',
  },
  lead: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
    marginBottom: 20,
  },
  login: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.blue1,
  },
  loginLink: {
    paddingBottom: 10,
    paddingTop: 10,
    textAlign: 'center',
  },
  loginText: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
  },
  marginTop: {
    marginTop: 10,
  },
  modal: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  modalContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  registerButton: {
    marginBottom: 30,
    marginTop: 20
  },
  sectionWrapper: {
    borderColor: Colors.grayUltraLight,
    borderTopWidth: 1,
    paddingBottom: 20,
    paddingTop: 20,
  },
  sectionWrapperStrike: {
    marginTop: 20,
  },
  socialText: {
    ...ApplicationStyles.screen.fontsLight,
    backgroundColor: Colors.white,
    fontSize: Fonts.size.regular-1,
    marginBottom: 10,
    marginTop: -30,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  switchContainerPrivacy: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
  },
  switchInner: {
    flexDirection:'row',
    flexWrap: 'wrap',
    flex: 1,
    marginLeft: 10,
    alignSelf: 'center',
  },
  switchTitle: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small+1,
    marginTop: 10,
  },
  switchText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small+1,
  },
  textInput: {
    ...ApplicationStyles.screen.fontsLight,
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
    color: Colors.gray,
    fontSize: Fonts.size.regular,
    marginBottom: 10,
    paddingBottom: 10,
    paddingTop: 10,
  },
  titleStrike: {
    flex: 1,
    flexDirection:'row',
    justifyContent: 'center',
  },
  wrapperBackground: {
    backgroundColor: Colors.darkBlue,
  },
  wrapper: {
    flex: 1,
  },
};

export default StyleSheet.create(BaseStyle);
