import { StyleSheet } from 'react-native';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

import { ApplicationStyles, Colors, Fonts } from '../../Theme';

export const BaseStyle = {
  backButton: {
    padding: 20,
    width: 60
  },
  backButtonContainer: {
    flex: 1,
  },
  background: {
    width: '100%',
  },
  button: {
    marginTop: 20,
    marginBottom: 40,
  },
  centerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
  },
  container: {
    flex: 1,
  },
  h1: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.h1,
    color: Colors.white,
    textAlign: 'center',
    marginVertical: 15,
    flex: 2,
  },
  innerContainer: {
    flex: 1,
    width: '100%',
  },
  paddingTop: {
    paddingTop: 10,
  },
  topContainer: {
    flexDirection: 'row',
  },
};

const StyleMedium = {
  innerContainer: {
    ...BaseStyle.innerContainer,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });
export default StyleSheet.create(BaseStyle);
