export const INITIAL_STATE = {
  logged: false,
  user: null,
  error: null,
  change_password: false,
  change_password_required: false,
  change_password_success: null,
  request_change_password_error: null,
  request_change_password_success: false,
  change_password_username: null,
  is_change: false,
};
