import { StyleSheet, Platform } from 'react-native';
import { Colors } from '../../../Theme';

export default StyleSheet.create({
  wrapper: {
    backgroundColor: Colors.transparentBlack,
    bottom: 0,
    flex: 1,
    justifyContent: 'center',
    left: 0,
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    right: 0,
    top: 0,
  },
});
