import { INITIAL_STATE } from './InitialState';
import { createReducer } from 'reduxsauce';
import { RacesTypes } from './Actions';

export const setRaces = (state, { data }) => ({
  ...state,
  races: data,
});

export const setRacesError = (state, { error }) => ({
  ...state,
  races_error: error,
});

export const setOpenRace = (state, { race }) => ({
  ...state,
  open_race: race,
});

export const setOpenRaceError = (state, { error }) => ({
  ...state,
  open_race_error: error,
});

export const setSavedRaces = (state, { data }) => ({
  ...state,
  saved_races: data,
});

export const setSavedRacesError = (state, { error }) => ({
  ...state,
  saved_races_error: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [RacesTypes.SET_RACES]: setRaces,
  [RacesTypes.SET_RACES_ERROR]: setRacesError,
  [RacesTypes.SET_OPEN_RACE]: setOpenRace,
  [RacesTypes.SET_OPEN_RACE_ERROR]: setOpenRaceError,
  [RacesTypes.SET_SAVED_RACES]: setSavedRaces,
  [RacesTypes.SET_SAVED_RACES_ERROR]: setSavedRacesError,
});
