import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Image, Text, View, Platform, Linking } from "react-native";
import moment from "moment";
import { PubSub } from "aws-amplify";
import LinearGradient from "react-native-linear-gradient";
import { Config } from "../../../Config";

import Style from "./CardStyle";
import { Colors, Images } from "../../../Theme";
import CardStatus from "./CardStatus";
import Barcode from "./Barcode";
import {
  AlertIcon,
  CheckIcon,
  InfoIcon,
  DownloadIcon,
} from "../../../Theme/Icons";
import { Button } from "../../Buttons";

import RuncardActions from "../../../Stores/Runcard/Actions";
import { isDesktop } from "../../../Services/ResponsiveHelper";

import LayoutActions from "../../../Stores/Layout/Actions";
import HelperActions from "../../../Stores/Helper/Actions";

const Card = ({ user, runcard, openTutorial }) => {
  const dispatch = useDispatch();
  const barcode = useSelector((state) => state.runcard.barcode);
  const barcodeError = useSelector((state) => state.runcard.barcode_error);

  React.useEffect(() => {
    if (!barcode) {
      dispatch(RuncardActions.getRuncardBarcode(runcard.runcard));
    }
  }, []);

  let subscribe;
  let timeout;
  const generatePdfCertificate = () => {
    if (timeout) clearTimeout(timeout);
    if (runcard) {
      // Receive messages from PDF generated
      subscribe = PubSub.subscribe(
        `runcard/${Config.STAGE}/card-pdf/${runcard.runcard}`
      ).subscribe({
        next: (data) => {
          console.log("data", data);
          if (timeout) clearTimeout(timeout);
          dispatch(LayoutActions.setLoading(false));
          if (data.value.status === "OK") {
            const url = `${Config.CDN}/${data.value.data}`;
            if (Platform.OS === "ios") {
              // setPDFOpen(url);
              let URI = `https://runcard-cdn.dshare.cloud/app/preprod/${
                data.value.data
              }`;
              Linking.openURL(URI);
            } else if (Platform.OS === "web") {
              window.open(url);
            } else {
              Linking.openURL(url);
            }
          } else {
            dispatch(
              LayoutActions.setError({
                text:
                  "Non è stato possibile generare il PDF.\nTi preghiamo di riprovare più tardi.",
              })
            );
          }
          if (subscribe) subscribe.unsubscribe();
        },
        error: (err) => console.log("PubSub subscribe error", err),
      });

      dispatch(LayoutActions.setLoading(true));
      dispatch(HelperActions.generatePdf("runcard-card"));

      timeout = setTimeout(() => {
        console.log("error");
        dispatch(LayoutActions.setLoading(false));
        dispatch(
          LayoutActions.setError({
            text:
              "La generazione del PDF sta impiegando troppo tempo.\nTi preghiamo di riprovare più tardi.",
          })
        );
      }, 60000); // 1 minute
    }
  };

  return !isDesktop() ? (
    <>
      <LinearGradient colors={[Colors.darkBlue, Colors.blue1]}>
        <View style={Style.box}>
          <View style={Style.name}>
            <Text style={Style.lead} numberOfLines={1}>
              {user.given_name} {user.family_name}
            </Text>
            <Button onPress={openTutorial}>
              <InfoIcon />
            </Button>
            <Button onPress={() => generatePdfCertificate()}>
              <DownloadIcon color={"#fff"} size={28} />
            </Button>
          </View>
          <Text style={Style.cardNumber}>
            {Config.RUNCARD_TYPES[runcard.type]
              ? Config.RUNCARD_TYPES[runcard.type].name.replace("Runcard ", "")
              : ""}{" "}
            n° {runcard.runcard}
          </Text>
        </View>
      </LinearGradient>
      <View style={Style.barcodeContainer}>
        <Barcode barcode={barcode} error={barcodeError} />
      </View>
      {Platform.OS !== "web" && (
        <View style={Style.cardImageContainer}>
          <Image
            source={Images.card}
            style={Style.card}
            resizeMode={"contain"}
          />
        </View>
      )}

      <View style={Style.recap}>
        <View style={Style.row}>
          <CardStatus runcard={runcard} />
        </View>
      </View>
    </>
  ) : (
    <View style={Style.columnContainer}>
      <View style={Style.column}>
        <LinearGradient
          colors={[Colors.darkBlue, Colors.blue1]}
          style={Style.flexContainer}
        >
          <View style={Style.cardsContainer}>
            <View style={[Style.tutorialButtonContainer, { marginBottom: 15 }]}>
              <Button onPress={openTutorial} style={Style.tutorialButton}>
                <InfoIcon />
              </Button>
              <Button
                onPress={() => {
                  generatePdfCertificate();
                }}
                style={Style.tutorialButton}
              >
                <DownloadIcon color={"#fff"} size={28} />
              </Button>
            </View>
            <View style={Style.flexContainer}>
              <View style={[Style.barcodeContainer, Style.barcodeContainerWeb]}>
                <Barcode barcode={barcode} />
              </View>
            </View>
          </View>
        </LinearGradient>
      </View>
      <View style={[Style.recap, Style.recapDesktop]}>
        <View style={Style.recapContainer}>
          <View>
            <Text style={[Style.lead, Style.gray]}>
              {user.given_name} {user.family_name}
            </Text>
            <Text style={[Style.cardNumber, Style.cardNumberBig, Style.gray]}>
              {Config.RUNCARD_TYPES[runcard.type]
                ? Config.RUNCARD_TYPES[runcard.type].name
                : ""}{" "}
              n° {runcard.runcard}
            </Text>
          </View>
          <Text style={Style.statusText}>Stato Runcard:</Text>
          <View style={[Style.row, Style.rowAlignLeft]}>
            {moment(runcard.expire_at).isAfter(moment()) ? (
              <>
                <View style={Style.icon}>
                  <CheckIcon />
                </View>
                <Text style={Style.bigText}>ATTIVA</Text>
              </>
            ) : (
              <>
                <View style={Style.icon}>
                  <AlertIcon color={Colors.red} />
                </View>
                <Text style={Style.bigText}>SCADUTA</Text>
              </>
            )}
          </View>
          <View style={[Style.row, Style.rowAlignLeft]}>
            <CardStatus runcard={runcard} />
          </View>
          <View style={[Style.row, Style.rowAlignLeft]}>
            <View style={Style.icon}>
              <InfoIcon color={Colors.blue1} />
            </View>
            <Button route={"certificate"}>
              <Text style={Style.note}>
                È necessario un certificato medico per attività sportiva valido
                per accedere alle competizioni
              </Text>
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

Card.propTypes = {
  user: PropTypes.object,
  runcard: PropTypes.object,
  openTutorial: PropTypes.func,
};

export default Card;
