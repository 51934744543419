import React from 'react';
import { View, Text } from 'react-native';
import { useDispatch } from 'react-redux';

import Style from './DeleteProfileStyle';
import { PageHeader } from '../../Layout';

import AuthActions from '../../../Stores/Auth/Actions';
import HelperActions from '../../../Stores/Helper/Actions';
import { SecondaryButton } from '../../Buttons';
import { ConfirmPopup } from '../../Popup';

const DeleteProfile = () => {
  const dispatch = useDispatch();
  const [confirmPopup, setConfirmPopup] = React.useState(false);
  const submitDeleteProfile = () => {
    setConfirmPopup(true);
  };
  const handleConfirm = (value) => {
    if (value) {
      dispatch(AuthActions.deleteProfile());
    }
    setConfirmPopup(false);
  }
  return (
    <>
      <PageHeader title={'Elimina Profilo'} />
      <View style={Style.container}>
        <Text style={Style.lead}>Tappando "Elimina" il tuo profilo verrà
          eliminato definitivamente e non potrà più essere ripristinato. Per
          annullare l'operazione torna indietro con il pulsante "&lt;"
        </Text>
        <SecondaryButton onPress={submitDeleteProfile} style={Style.button}>
          <Text style={Style.text}>ELIMINA</Text>
        </SecondaryButton>
      </View>
      {confirmPopup && (
        <ConfirmPopup closeHandler={handleConfirm} title={'Elimina Profilo'} text={`Vuoi eliminare il tuo profilo in modo definitivo?`} cancelButton={'ANNULLA'} confirmButton={'ELIMINA'} />
      )}
    </>
  );
};

export default DeleteProfile;
