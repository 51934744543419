import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export const BaseStyle = {
  barcode: {
    width: '100%',
    height: null,
    aspectRatio: 4/2,
  },
  barcodeContainer: {
    backgroundColor: Colors.white,
    marginRight: 30,
    marginBottom: -30,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    justifyContent: 'center',
    marginTop: -150,
    maxWidth: 500,
    paddingRight: 50,
    paddingLeft: 50,
    paddingTop: 50,
    paddingBottom: 20,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.3,
    shadowRadius: 5,
    minHeight: 160,
    zIndex: 1,
  },
  barcodeContainerWeb: {
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    marginBottom: 30,
    marginRight: -30,
    marginTop: 150,
    marginLeft: 150,
    paddingTop: 20,
    paddingRight: 50,
    paddingLeft: 50,
  },
  barcodeWeb: {
    width: '100%',
    height: 'auto',
  },
  bigText: {
    ...ApplicationStyles.screen.h1,
    fontSize: Fonts.size.h1-1,
    color: Colors.gray,
  },
  box: {
    paddingLeft: 50,
    paddingRight: 30,
    paddingBottom: 280,
    zIndex: 2,
  },
  buttonContainer: {
    marginLeft: 10
  },
  card: {
    width: null,
    height: null,
    resizeMode: 'cover',
    aspectRatio: 502/318,
    marginLeft: 50,
    marginRight: 30,
    zIndex: 2,
  },
  cardImageContainer: {
    zIndex: 5,
    position: 'absolute',
    top: 110,
    left: 0,
    right: 20,
  },
  cardNumber: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular,
    color: Colors.white,
    marginBottom: 20,
  },
  cardNumberBig: {
    fontSize: Fonts.size.regular+2,
  },
  cardsContainer: {
    flex:1,
    maxWidth: 500,
    alignSelf: 'flex-end'
  },
  cardWeb: {
    width: '100%',
    height: 'auto',
    marginTop: -190,
    marginLeft: 0,
  },
  cardDesktop: {
    marginLeft: -90,
  },
  column: {
    flex: 1,
    zIndex: 2,
  },
  columnContainer: {
    flexDirection: 'row',
    marginBottom: 50,
    elevation: 4,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
  },
  error: {
    ...ApplicationStyles.screen.fontsLight,
  },
  flexContainer: {
    flex: 1,
  },
  gray: {
    color: Colors.gray,
  },
  icon: {
    marginRight: 10,
    alignSelf: 'flex-start',
  },
  lead: {
    ...ApplicationStyles.screen.h1,
    fontSize: Fonts.size.h1-1,
    color: Colors.white,
  },
  name: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    marginTop: 30,
    marginRight: 30,
  },
  note: {
    color: Colors.blue1,
  },
  recap: {
    flex: 1,
    paddingBottom: 20,
    paddingTop: 30,
    marginTop: 30,
  },
  recapContainer: {
    maxWidth: 500,
    alignSelf: 'flex-start',
    paddingLeft: 50,
  },
  recapDesktop: {
    backgroundColor: Colors.grayUltraFantaLight,
    marginTop: 0,
    paddingTop: 50,
  },
  row: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  rowAlignLeft: {
    paddingLeft: 0,
  },
  statusText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular,
    marginBottom: 20,
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    flex: 1,
  },
  textBold: {
    ...ApplicationStyles.screen.fonts,
  },
  tutorialButton: {
    padding: 20,
  },
  tutorialButtonContainer: {
    flexDirection:'row',
    justifyContent: 'flex-end',
  },
};

export default StyleSheet.create(BaseStyle);
