import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { useHistory, useLocation, Redirect } from 'react-router-native';
import { BackHandler, Platform } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import NetInfo from '@react-native-community/netinfo';

import Styles from './RootScreenStyle';
import { ErrorPopup } from '../../Components/Popup';
import NoConnection from '../Errors/NoConnection';
import { Loader } from '../../Components/Layout';

import { getPath } from '../../Router/AppRoutes';
import LayoutActions from '../../Stores/Layout/Actions';
import NavigationActions from '../../Stores/Navigation/Actions';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const RootView = ({ children, signIn_failure, setSignIn_failure }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const error = useSelector(state => state.layout.error);
  const breakpoint = useSelector(state => state.layout.breakpoint);
  const redirect = useSelector(state => state.navigation.redirect);

  const [ connection, setConnection ] = React.useState(null);

  const handleBackButtonClick = () => {
    if (history.canGo(-1)) {
      history.goBack();
      return true;
    }
  };

  const prevBreakpointRef = React.useRef();
  React.useEffect(() => {
    prevBreakpointRef.current = breakpoint;
  });

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      if (prevBreakpointRef.current !== breakpoint) {
        window.location.reload();
      }
    }
  }, [ breakpoint ]);

  React.useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', handleBackButtonClick);

    let unsubscribe;
    if (Platform.OS === 'web') {
      setConnection(true);
    } else {
      NetInfo.fetch().then(state => {
        setConnection(state.isInternetReachable === null ? null : (state.isConnected && state.isInternetReachable !== false));
      });

      // Connection check
      unsubscribe = NetInfo.addEventListener(state => {
        setConnection(state.isInternetReachable === null ? null : (state.isConnected && state.isInternetReachable !== false));
      });
    }

    return () => {
      if (unsubscribe) unsubscribe();
      BackHandler.removeEventListener('hardwareBackPress', handleBackButtonClick);
    };
  }, []);

  if (redirect) {
    console.log(`DEBUG: Hard redirect: ${typeof redirect === 'string' ? getPath(redirect) : JSON.stringify({ pathname: getPath(redirect[0]), state: redirect[1] })}`);
    const redirectUrl = typeof redirect === 'string' ? getPath(redirect) : getPath(redirect[0]);
    const redirectState = typeof redirect === 'string' ? null : redirect[1];

    setTimeout(() => dispatch(NavigationActions.resetNavigation(), 100));
    history.entries = [];
    history.index = -1;

    return (
      <Redirect to={{ pathname: redirectUrl, state: redirectState }} />
    );
  }

  const LoginError = () => {
    const history = useHistory();
    const location = useLocation();
    const errorDescription = location.search.substring(1).split('&').filter(el => el.split('=')[0] === 'error_description');
    const message = errorDescription && errorDescription.length && errorDescription[0].indexOf('social-login-missing-email') !== -1 ? 'Il tuo indirizzo email è necessario per procedere con l\'autenticazione. Per favore, riprova.' : 'Qualcosa è andato storto';
    return <ErrorPopup title={'Ops!'} text={message} closeHandler={() => { setSignIn_failure(null); history.push(getPath('login')); }} button={'RIPROVA'} />;
  };

  return connection ? (
    <>
      <View style={Styles.container} onLayout={() => dispatch(LayoutActions.setBreakpoint(ResponsiveHelper.getSize()))}>
        {children}
      </View>
      {error && (
        <ErrorPopup title={error.title||'Ops!'} text={error.text||'Qualcosa è andato storto'} extra={error.extra||null} closeHandler={() => { dispatch(LayoutActions.setError(null)); if (error.closeHandler) error.closeHandler(); }} button={error.dismissButton||'OK'} />
      )}
      {signIn_failure && (
        <LoginError setSignIn_failure={setSignIn_failure} />
      )}
    </>
  ) : connection === null ? <Loader /> : <NoConnection />;
};

RootView.propTypes = {
  children: PropTypes.node,
  signIn_failure: PropTypes.bool,
  setSignIn_failure: PropTypes.func,
};

export default RootView;
