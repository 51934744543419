import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  button: {
    backgroundColor: Colors.red,
    borderColor: Colors.red,
    marginBottom: 20,
    marginTop: 20,
  },
  container: {
    ...ApplicationStyles.screen.paddingTabBar,
    paddingHorizontal: 30,
    paddingTop: 20,
  },
  divider: {
    marginBottom: 20,
  },
  lead: {
    ...ApplicationStyles.screen.fontsRegular,
    marginBottom: 30,
    marginTop: 20,
    textAlign: 'center',
  },
  text: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.white,
    fontSize: Fonts.size.regular,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },

});
