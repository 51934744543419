import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { AuthTypes } from './Actions'

export const setUserLogged = (state, { user }) => ({
  ...state,
  logged: true,
  user: user,
  error: null,
});

export const loginFailure = (state, { error }) => ({
  ...state,
  error,
});

export const loginReset = (state) => ({
  ...state,
  error: null,
});

export const userLogout = (state) => ({
  ...state,
  logged: false,
  user: null,
  error: null,
});

export const startChangePassword = (state, { change }) => ({
  ...state,
  change_password_success: null,
  change_password: true,
  change_password_required: false,
  request_change_password_success: false,
  request_change_password_error: null,
  change_password_error: null,
  change_password_username: null,
  is_change: !!change,
});

export const requestChangePasswordFailure = (state, { error, username }) => ({
  ...state,
  change_password: true,
  request_change_password_success: false,
  request_change_password_error: error,
  change_password_username: username,
});

export const requestChangePasswordSuccess = (state, { username }) => ({
  ...state,
  change_password: true,
  request_change_password_success: true,
  request_change_password_error: null,
  change_password_error: null,
  change_password_username: username,
});

export const changePasswordFailure = (state, { error }) => ({
  ...state,
  change_password_error: error,
  change_password_success: false,
  request_change_password_success: false,
  request_change_password_error: null,
});

export const changePasswordSuccess = (state) => ({
  ...state,
  change_password_error: null,
  change_password_success: true,
  request_change_password_success: false,
  request_change_password_error: null,
});

export const endChangePassword = (state) => ({
  ...state,
  change_password: false,
  change_password_username: null,
  change_password_error: null,
  change_password_success: null,
  request_change_password_success: null,
  request_change_password_error: null,
});

export const forceChangePassword = (state) => ({
  ...state,
  change_password_required: true,
});

export const reducer = createReducer(INITIAL_STATE, {
  [AuthTypes.SET_USER_LOGGED]: setUserLogged,
  [AuthTypes.LOGIN_FAILURE]: loginFailure,
  [AuthTypes.LOGIN_RESET]: loginReset,
  [AuthTypes.USER_LOGOUT]: userLogout,
  [AuthTypes.START_CHANGE_PASSWORD]: startChangePassword,
  [AuthTypes.REQUEST_CHANGE_PASSWORD_FAILURE]: requestChangePasswordFailure,
  [AuthTypes.REQUEST_CHANGE_PASSWORD_SUCCESS]: requestChangePasswordSuccess,
  [AuthTypes.CHANGE_PASSWORD_FAILURE]: changePasswordFailure,
  [AuthTypes.CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [AuthTypes.END_CHANGE_PASSWORD]: endChangePassword,
  [AuthTypes.FORCE_CHANGE_PASSWORD]: forceChangePassword,
});
