import { put, call, select } from "redux-saga/effects";
import { Auth } from "aws-amplify";

import NavigationActions from "../Stores/Navigation/Actions";
import AuthActions from "../Stores/Auth/Actions";
import LayoutActions from "../Stores/Layout/Actions";
import RegistrationActions from "../Stores/Registration/Actions";
import {
  fetchUser,
  saveUser,
  postCrmEvent,
  postLeaderboardEvent,
  deleteUser,
} from "../Services/ApiGatewayService";
import Analytics from "../Services/AnalyticsService";
import { getPath } from "../Router/Router";
import { Config } from "../Config";

export function* login({ credentials, redirect, signup }) {
  const { username, password } = credentials;
  yield put(LayoutActions.setLoading(true));
  let result = yield Auth.signIn(username.toLowerCase(), password)
    .then((data) => ({ status: "OK", data }))
    .catch((err) => ({ status: "KO", err }));

  if (result.status === "KO" && typeof result.err.code !== "undefined") {
    switch (result.err.code) {
      case "UserNotFoundException":
        if (result.err.message.indexOf("migrating") > -1) {
          yield put(
            AuthActions.loginFailure(
              "L'indirizzo email o la password sono errati"
            )
          );
        } else {
          yield put(AuthActions.loginFailure("Utente non trovato"));
        }
        break;
      case "NotAuthorizedException":
        yield put(
          AuthActions.loginFailure(
            "L'indirizzo email o la password sono errati"
          )
        );
        break;
      case "UserNotConfirmedException":
        yield put(
          RegistrationActions.registrationToBeConfirmed({
            email: username,
            password,
          })
        );
        yield put(NavigationActions.pushNavigation("registration"));
        break;
      case "PasswordResetRequiredException":
        yield put(AuthActions.requestChangePassword(username.toLowerCase()));
        yield put(AuthActions.forceChangePassword());
        break;
      default:
        console.log("DEBUG: Auth error", result.err);
        yield put(
          AuthActions.loginFailure(
            result.err.log ? result.err.log : "UNKNOWN ERROR"
          )
        );
        break;
    }
  } else if (result.status === "KO") {
    console.log("DEBUG: Ath error", result.err);
    yield put(
      AuthActions.loginFailure(
        result.err.log ? result.err.log : "UNKNOWN ERROR"
      )
    );
  } else {
    try {
      const credentials = yield Auth.currentCredentials().then(
        (credentials) => credentials
      );

      if (signup) {
        // CRM
        // yield call(postCrmEvent, { event: 'event_signup' });
        // yield call(postCrmEvent, { event: 'event_insert_privacy' });
        yield call(postLeaderboardEvent, { event: "subscribe" });
        Analytics.getInstance().trackRegistration();
      }

      const user = yield call(fetchUser, credentials.identityId);
      if (user.complete) {
        yield put(AuthActions.setUserLogged(user));
        if (redirect) yield put(NavigationActions.pushNavigation(redirect));
        const redirectAfterLogin = yield select(
          (state) => state.navigation.afterLogin
        );
        if (redirectAfterLogin) {
          yield put(NavigationActions.setAfterLogin(null));
          yield put(NavigationActions.pushNavigation(redirectAfterLogin));
        }

        yield put(LayoutActions.toggleLoginModal(false));

        if (!signup) {
          Analytics.getInstance().trackLogin();
          // CRM
          yield call(postCrmEvent, { event: "event_login" });
        }
      } else {
        yield put(RegistrationActions.registrationToBeCompleted(user));
        yield put(NavigationActions.pushNavigation("registration"));
      }
    } catch (err) {
      yield put(AuthActions.logout());
    }
  }
  yield put(LayoutActions.setLoading(false));
}

export function* logout({ redirect }) {
  yield Auth.signOut();
  yield put(AuthActions.userLogout());
  if (redirect) yield put(NavigationActions.pushNavigation(redirect));
  Analytics.getInstance().trackLogout();
}

export function* deleteProfile() {
  yield put(LayoutActions.setLoading(true));
  try {
    yield call(deleteUser);
    yield put(AuthActions.logout(getPath(Config.APP_HOMEPAGE)));
   
  } catch (err) {
    console.log("err", err);
    yield put(LayoutActions.setError({}));
  }
  yield put(LayoutActions.setLoading(false));
}

export function* requestChangePassword({ username }) {
  yield put(LayoutActions.setLoading(true));
  const result = yield Auth.forgotPassword(username.toLowerCase())
    .then((data) => ({ status: "OK", data }))
    .catch((err) => ({ status: "KO", err }));

  if (result.status === "KO" && typeof result.err.code !== "undefined") {
    switch (result.err.code) {
      case "LimitExceededException":
        yield put(
          AuthActions.requestChangePasswordFailure(
            "Hai raggiunto il numero massimo di richieste, riprova più tardi",
            username
          )
        );
        break;
      case "UserNotFoundException":
        yield put(
          AuthActions.requestChangePasswordFailure(
            "Indirizzo email non trovato",
            username
          )
        );
        break;
      default:
        yield put(
          AuthActions.requestChangePasswordFailure(
            result.err.log ? result.err.log : "UNKNOWN ERROR",
            username
          )
        );
        break;
    }
  } else if (result.status === "KO") {
    yield put(
      AuthActions.requestChangePasswordFailure(
        result.err.log ? result.err.log : "UNKNOWN ERROR",
        username
      )
    );
  } else {
    yield put(AuthActions.requestChangePasswordSuccess(username));
  }

  yield put(LayoutActions.setLoading(false));
}

export function* changePasswordSkipRequest({ username }) {
  yield put(LayoutActions.setLoading(true));
  yield put(AuthActions.requestChangePasswordSuccess(username));
  yield put(LayoutActions.setLoading(false));
}

export function* changePassword({ data }) {
  const { email: username, password, code } = data;
  yield put(LayoutActions.setLoading(true));
  const result = yield Auth.forgotPasswordSubmit(
    username.toLowerCase(),
    code,
    password
  )
    .then((data) => ({ status: "OK", data }))
    .catch((err) => ({ status: "KO", err }));
  if (result.status === "KO" && typeof result.err.code !== "undefined") {
    switch (result.err.code) {
      case "CodeMismatchException":
        yield put(
          AuthActions.changePasswordFailure("Il codice inserito non è corretto")
        );
        break;
      case "ExpiredCodeException":
        const newCode = yield Auth.forgotPassword(username.toLowerCase())
          .then(() => true)
          .catch(() => false);
        yield put(
          AuthActions.changePasswordFailure(
            newCode
              ? "Il codice inserito è scaduto, te ne abbiamo inviato un altro"
              : "Il codice inserito non è corretto"
          )
        );
        break;
      default:
        yield put(
          AuthActions.changePasswordFailure(
            result.err.log ? result.err.log : "UNKNOWN ERROR"
          )
        );
        break;
    }
  } else if (result.status === "KO") {
    yield put(
      AuthActions.changePasswordFailure(
        result.err.log ? result.err.log : "UNKNOWN ERROR"
      )
    );
  } else {
    yield put(AuthActions.changePasswordSuccess());
  }
  yield put(LayoutActions.setLoading(false));
}

export function* updateNotification({ notification, value }) {
  let notifications = yield select(
    (state) => state.auth.user.notifications || {}
  );
  notifications[notification] = value;
  try {
    const user = yield call(saveUser, { notifications });
    yield put(AuthActions.setUserLogged(user));
  } catch (err) {
    yield put(LayoutActions.setError(err));
  }
}
