import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Stop, Defs, LinearGradient, Mask, Use, Polygon } from 'react-native-svg';

const TabNewsIcon = ({ active=false, size=26 }) => {
  return (
    <Svg width={size} height={size} viewBox={'0 0 26 26'}>
      <Defs>
        <Path d={'M4.008114,0.1252152 L4.008114,9.1419372 L0.171864,9.1419372 L0.171864,9.1443924 L0,9.1443924 L0,22.7732076 C0,24.4304676 1.3491324,25.7796 3.0063924,25.7796 L3.0063924,25.7796 L22.6479924,25.7796 C24.30648,25.7796 25.6543848,24.4304676 25.6543848,22.7732076 L25.6543848,22.7732076 L25.6543848,0.1252152 L4.008114,0.1252152 Z M5.9698188,23.2887996 C5.9980536,23.1206184 6.0127848,22.9487544 6.0127848,22.7732076 L6.0127848,22.7732076 L6.0127848,2.129886 L23.649714,2.129886 L23.649714,22.7732076 C23.649714,23.3256276 23.2004124,23.7749292 22.6479924,23.7749292 L22.6479924,23.7749292 L5.8396932,23.7749292 C5.8961628,23.619024 5.9403564,23.4557532 5.9698188,23.2887996 L5.9698188,23.2887996 Z M2.0046708,22.7732076 L2.0046708,11.146608 L4.008114,11.146608 L4.008114,22.7732076 C4.008114,23.3256276 3.56004,23.7749292 3.0063924,23.7749292 L3.0063924,23.7749292 C2.4539724,23.7749292 2.0046708,23.3256276 2.0046708,22.7732076 L2.0046708,22.7732076 Z'} id={'pathNews1'} />
        <Path d={'M0.0620532,6.438762 L5.9238432,6.438762 L5.9238432,0.4763088 L0.0620532,0.4763088 L0.0620532,6.438762 Z M1.3448952,5.1939756 L4.6422288,5.1939756 L4.6422288,1.71864 L1.3448952,1.71864 L1.3448952,5.1939756 Z'} id={'pathNews2'} />
        <LinearGradient x1="50.0004627%" y1="-0.187799311%" x2="50.0004627%" y2="95.5561733%" id="gradientNews3">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50.0116482%" y1="-784.705836%" x2="50.0116482%" y2="440.559039%" id="gradientNews4">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50.0116482%" y1="-982.195119%" x2="50.0116482%" y2="243.069755%" id="gradientNews5">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50.0133042%" y1="-384.82831%" x2="50.0133042%" y2="840.436565%" id="gradientNews6">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50.0116482%" y1="-130.229489%" x2="50.0116482%" y2="281.723885%" id="gradientNews9">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50.0065403%" y1="-202.342088%" x2="50.0065403%" y2="1022.92279%" id="gradientNews12">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50.0133042%" y1="-784.705836%" x2="50.0133042%" y2="440.559039%" id="gradientNews13">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50.0133042%" y1="-584.767073%" x2="50.0133042%" y2="640.497802%" id="gradientNews14">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50.0133042%" y1="-982.195119%" x2="50.0133042%" y2="243.069755%" id="gradientNews15">
          <Stop stopColor={active ? '#02AFF0' : '#E2E4E2'} offset="0%" />
          <Stop stopColor={active ? '#0D85F0' : '#C3C3C3'} offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
        <G transform="translate(0.000000, 0.112753)">
          <Mask fill="white" id={'maskNews1'}>
            <Use href="#pathNews1" />
          </Mask>
          <Path d="M4.008114,0.1252152 L4.008114,9.1419372 L0.171864,9.1419372 L0.171864,9.1443924 L0,9.1443924 L0,22.7732076 C0,24.4304676 1.3491324,25.7796 3.0063924,25.7796 L3.0063924,25.7796 L22.6479924,25.7796 C24.30648,25.7796 25.6543848,24.4304676 25.6543848,22.7732076 L25.6543848,22.7732076 L25.6543848,0.1252152 L4.008114,0.1252152 Z M5.9698188,23.2887996 C5.9980536,23.1206184 6.0127848,22.9487544 6.0127848,22.7732076 L6.0127848,22.7732076 L6.0127848,2.129886 L23.649714,2.129886 L23.649714,22.7732076 C23.649714,23.3256276 23.2004124,23.7749292 22.6479924,23.7749292 L22.6479924,23.7749292 L5.8396932,23.7749292 C5.8961628,23.619024 5.9403564,23.4557532 5.9698188,23.2887996 L5.9698188,23.2887996 Z M2.0046708,22.7732076 L2.0046708,11.146608 L4.008114,11.146608 L4.008114,22.7732076 C4.008114,23.3256276 3.56004,23.7749292 3.0063924,23.7749292 L3.0063924,23.7749292 C2.4539724,23.7749292 2.0046708,23.3256276 2.0046708,22.7732076 L2.0046708,22.7732076 Z" fill="url(#gradientNews3)" mask="url(#maskNews1)" />
        </G>
        <Polygon id="Fill-4" fill="url(#gradientNews4)" points="15.7832532 17.9252291 21.6450432 17.9252291 21.6450432 15.9205583 15.7832532 15.9205583" />
        <Polygon id="Fill-6" fill="url(#gradientNews4)" points="15.7832532 17.9252291 21.6450432 17.9252291 21.6450432 15.9205583 15.7832532 15.9205583" />
        <Polygon id="Fill-8" fill="url(#gradientNews4)" points="15.7832532 17.9252291 21.6450432 17.9252291 21.6450432 15.9205583 15.7832532 15.9205583" />
        <Polygon id="Fill-10" fill="url(#gradientNews5)" points="15.7832532 21.8842391 21.6450432 21.8842391 21.6450432 19.8795683 15.7832532 19.8795683" />
        <Polygon id="Fill-12" fill="url(#gradientNews5)" points="15.7832532 21.8842391 21.6450432 21.8842391 21.6450432 19.8795683 15.7832532 19.8795683" />
        <Polygon id="Fill-14" fill="url(#gradientNews5)" points="15.7832532 21.8842391 21.6450432 21.8842391 21.6450432 19.8795683 15.7832532 19.8795683" />
        <Polygon id="Fill-16" fill="url(#gradientNews6)" points="8.016228 9.90900108 13.7785824 9.90900108 13.7785824 7.90433028 8.016228 7.90433028" />
        <Polygon id="Fill-18" fill="url(#gradientNews6)" points="8.016228 9.90900108 13.7785824 9.90900108 13.7785824 7.90433028 8.016228 7.90433028" />
        <Polygon id="Fill-20" fill="url(#gradientNews6)" points="8.016228 9.90900108 13.7785824 9.90900108 13.7785824 7.90433028 8.016228 7.90433028" />
        <G transform="translate(15.721200, 7.478353)">
          <Mask fill="white" id={'maskNews2'}>
            <Use href="#pathNews2" />
          </Mask>
          <Path d="M0.0620532,6.438762 L5.9238432,6.438762 L5.9238432,0.4763088 L0.0620532,0.4763088 L0.0620532,6.438762 Z M1.3448952,5.1939756 L4.6422288,5.1939756 L4.6422288,1.71864 L1.3448952,1.71864 L1.3448952,5.1939756 Z" fill="url(#gradientNews9)" mask="url(#maskNews2)" />
        </G>
        <Polygon fill={'url(#gradientNews12)'} points="8.016228 6.25075308 21.6450432 6.25075308 21.6450432 4.24608228 8.016228 4.24608228" />
        <Polygon fill={'url(#gradientNews12)'} points="8.016228 6.25075308 21.6450432 6.25075308 21.6450432 4.24608228 8.016228 4.24608228" />
        <Polygon fill={'url(#gradientNews12)'} points="8.016228 6.25075308 21.6450432 6.25075308 21.6450432 4.24608228 8.016228 4.24608228" />
        <Polygon fill={'url(#gradientNews13)'} points="8.016228 17.9252291 13.7785824 17.9252291 13.7785824 15.9205583 8.016228 15.9205583" />
        <Polygon fill={'url(#gradientNews13)'} points="8.016228 17.9252291 13.7785824 17.9252291 13.7785824 15.9205583 8.016228 15.9205583" />
        <Polygon fill={'url(#gradientNews13)'} points="8.016228 17.9252291 13.7785824 17.9252291 13.7785824 15.9205583 8.016228 15.9205583" />
        <Polygon fill={'url(#gradientNews14)'} points="8.016228 13.9171151 13.7785824 13.9171151 13.7785824 11.9124443 8.016228 11.9124443" />
        <Polygon fill={'url(#gradientNews14)'} points="8.016228 13.9171151 13.7785824 13.9171151 13.7785824 11.9124443 8.016228 11.9124443" />
        <Polygon fill={'url(#gradientNews14)'} points="8.016228 13.9171151 13.7785824 13.9171151 13.7785824 11.9124443 8.016228 11.9124443" />
        <Polygon fill={'url(#gradientNews15)'} points="8.016228 21.8842391 13.7785824 21.8842391 13.7785824 19.8795683 8.016228 19.8795683" />
        <Polygon fill={'url(#gradientNews15)'} points="8.016228 21.8842391 13.7785824 21.8842391 13.7785824 19.8795683 8.016228 19.8795683" />
        <Polygon fill={'url(#gradientNews15)'} points="8.016228 21.8842391 13.7785824 21.8842391 13.7785824 19.8795683 8.016228 19.8795683" />
      </G>
    </Svg>
  )
};

TabNewsIcon.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
};

export default TabNewsIcon;
