import React from 'react'
import { useHistory } from 'react-router-native';
import { useDispatch, useSelector } from 'react-redux';

import { RenewRuncard } from '../../Components/Runcard';

import { getPath } from '../../Router/Router';
import RuncardActions from '../../Stores/Runcard/Actions';
import LayoutActions from '../../Stores/Layout/Actions';

const RenewRuncardScreen = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const { logged, user } = useSelector(state => state.auth);
  const runcard = useSelector(state => state.runcard.runcard);

  React.useEffect(() => {
    if (!user || !user.runcard) {
      history.push(getPath('linkRuncard'));
    }
    if (!runcard) {
      dispatch(RuncardActions.getRuncard())
    }
    dispatch(LayoutActions.setTab('RUNCARD'));
  }, []);

  return logged && user.runcard && runcard && <RenewRuncard />;
};

export default RenewRuncardScreen;
