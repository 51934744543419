import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polygon } from 'react-native-svg';
import { Colors } from "../../Theme";

const DownloadIcon = ({ size=12, color=Colors.blue1 }) => (
  <Svg width={size} height={size} viewBox={'0 0 12 12'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <G fill={color} fillRule={'nonzero'}>
        <Polygon points={'0 10 12 10 12 11 0 11'} />
        <Polygon points={'10 5.31738286 9.37305669 4.7389061 6.44415475 7.43395126 6.44415475 0 5.55496757 0 5.55496757 7.43395126 2.62521377 4.73809851 2 5.31657526 5.99956116 9'} />
      </G>
    </G>
  </Svg>
);

DownloadIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default DownloadIcon;
