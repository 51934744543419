import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const TabHomeIcon = ({ active=false, size=28, color=null }) => {
  return (
    <Svg width={size} height={size} viewBox={'0 0 29 28'}>
      <Defs>
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={'gradientHome'}>
          <Stop stopColor={active ? '#02AFF1' : '#E3E4E3'} offset="0%" />
          <Stop stopColor={active ? '#0D85F1' : '#C3C4C3'} offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
        <G transform="translate(-0.500000, -1.500000)">
          <Path d="M1,14.14325 L2.42576,15.791375 L3.72832,14.655125 L3.72832,28.66025 L13.0736,28.66025 L13.0736,17.96375 L16.87824,17.96375 L16.87824,28.66025 L26.11488,28.66025 L26.11488,14.655125 L27.41856,15.791375 L28.8432,14.14325 L14.9216,2 L1,14.14325 Z M5.90224,12.758375 L14.9216,4.89125 L23.94096,12.758375 L23.94096,26.47775 L19.05104,26.47775 L19.05104,15.780125 L10.9008,15.780125 L10.9008,26.47775 L5.90224,26.47775 L5.90224,12.758375 Z" fill={color ? color : 'url(#gradientHome)'} fillRule={'evenodd'} />
        </G>
      </G>
    </Svg>
  )
};

TabHomeIcon.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
};

export default TabHomeIcon;
