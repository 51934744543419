import moment from 'moment';
import { Config } from '../Config';

const Strava = {};

Strava.config = {
  client_id: Config.STRAVA.client_id,
  client_secret: Config.STRAVA.client_secret,
  redirect_uri: Config.STRAVA.redirect_uri,
  approval_prompt: 'auto',
  scope: 'activity:read_all',
  api_base_url: 'https://www.strava.com',
  services: {
    authorize: '/oauth/mobile/authorize',
    deauthorize: '/oauth/deauthorize',
    token: '/oauth/token',
    athlete_list_activities: '/api/v3/athlete/activities',
    activity: '/api/v3/activities'
  }
};

Strava.disconnect = async (token) => {
  return await Strava.fetch(Strava.config.services.deauthorize, 'POST', null, token);
};

Strava.getAthleteActivities = async (token, from, to) => {
  return await Strava.fetch(`${Strava.config.services.athlete_list_activities}?before=${to.format('X')}&after=${from.format('X')}`, 'GET', null, token);
};

Strava.getActivity = async (token, activity) => {
  return await Strava.fetch(`${Strava.config.services.activity}/${activity}`, 'GET', null, token);
};

Strava.getRequestAccessURL = () => {
  const oauthArgs = {
    client_id: Strava.config.client_id,
    redirect_uri: Strava.config.redirect_uri,
    approval_prompt: Strava.config.approval_prompt,
    scope: Strava.config.scope,
    response_type: 'code'
  };
  return `${Strava.config.api_base_url}${Strava.config.services.authorize}?${Object.keys(oauthArgs).map(prop => `${prop}=${oauthArgs[prop]}`).join('&')}`;
};

Strava.parseAuthUrl = (url) => {
  let authCode = null;
  let error = null;
  let scope = null;
  let params = url.split('?');
  if (params.length) {
    params = params.pop().split('&').map(entry => entry.split('='));
    authCode = params.filter(entry => entry[0] === 'code');
    authCode = authCode && authCode.length ? authCode.pop().pop() : null;
    error = params.filter(entry => entry[0] === 'error');
    error = error && error.length ? error.pop().pop() : null;
    scope = params.filter(entry => entry[0] === 'scope');
    scope = scope && scope.length ? decodeURIComponent(scope.pop().pop()) : null;
  }
  return { authCode, error, scope, };
};

Strava.getToken = async (code) => {
  let body = new FormData();
  body.append('client_id', Strava.config.client_id);
  body.append('client_secret', Strava.config.client_secret);
  body.append('code', code);

  const response = await fetch(`${Strava.config.api_base_url}${Strava.config.services.token}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Origin: '*',
      'Content-Type': 'multipart/form-data',
    },
    mode: 'cors',
    body,
  });
  return await response.json();
};

Strava.refreshToken = async (token) => {
  if (Strava.tokenExpired(token)) {
    let body = new FormData();
    body.append('client_id', Strava.config.client_id);
    body.append('client_secret', Strava.config.client_secret);
    body.append('grant_type', 'refresh_token');
    body.append('refresh_token', token.refresh_token);

    const response = await fetch(`${Strava.config.api_base_url}${Strava.config.services.token}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: {
        client_id: Strava.config.client_id,
        client_secret: Strava.config.client_secret,
        grant_type: 'refresh_token',
        refresh_token: token.refresh_token,
      },
    });
    const res = await response.json();
    if (res && res.token) {
      return { expired: true, refreshed: true, token };
    }
    return { expired: true, refreshed: false, token };
  } else {
    return { expired: false, refreshed: false, token };
  }
};

Strava.fetch = async (url, method, params, token) => {
  if (token && token.access_token) {
    const response = await fetch(`${Strava.config.api_base_url}${url}`, {
      method,
      headers: {
        Accept: 'application/json',
        Origin: '*',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access_token}`,
      },
      body: params,
    });
    const data = await response.json();
    if (data && data.errors) {
      throw data.message;
    }
    return data;
  }
  throw 'Can\'t retrieve user token';
};

Strava.tokenExpired = (token) => {
  return moment.unix(token.expires_at).isBefore(moment());
};

export default Strava;
