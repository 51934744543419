import { Platform } from 'react-native';
import { ApplicationStyles, Colors } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  advHomepage: {
    marginTop: 10,
  },
  adv: {
    marginLeft: 10,
  },
  background: {
    backgroundColor: Colors.darkBlue,
  },
  claim: {

  },
  hamburger: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    justifyContent: 'center',
  },
  hamburgerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  hamburgerHome: {
    paddingRight: 20,
    paddingTop: 20,
    alignItems: 'flex-end',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerLogo: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerHome: {
    ...Platform.select({
      web: {
        backgroundImage: 'url(/static/images/bg_header_home.png)',
        backgroundSize: 'cover',
        backgroundPosition: '50% 99%',
        overflow: 'hidden',
        paddingBottom: 30,
      }
    })
  },
  logo: {
    width: 100,
    marginLeft: 30,
    paddingTop: 15,
    paddingBottom: 14,
  },
  logoAdvHomepage: {},
  logoHomepage: {
    marginLeft: 0,
    alignSelf: 'center'
  },
  wrapper: {
    marginBottom: Platform.OS === 'web' ? -170 : 0,
    zIndex: 2,
  },
};

const StyleLG = {
  advHomepage: {
    marginLeft: 10,
    marginTop: 0,
  },
  claim: {
    ...ApplicationStyles.screen.fontsRegularItalic,
    color: Colors.white,
    textAlign: 'center',
    marginBottom: 50,
    marginTop: 15,
  },
  hamburger: {
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 40,
    paddingTop: 0,
    alignItems: 'flex-end',
  },
  logo: {
    marginLeft: 40,
    width: 130,
  },
  logoAdvHomepage: {
    flexDirection:'row',
    alignItems:'center',
  },
  header: {
    alignItems: 'center',
    paddingLeft: 0,
  },
  headerHome: {
    paddingTop: 60,
    paddingBottom: 100,
  },
  headerLogo: {
    flex: 1,
  },
  hamburgerHome: {
    flex: 1,
    paddingRight: 60,
    alignItems: 'flex-end',
  },
  wrapper: {
    marginBottom: -150,
    zIndex: 2,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
