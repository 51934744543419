import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, Text, View } from 'react-native';

import { getResponsive } from '../LegalsStyle';

const PrivacyPolicyRuncard = ({ onlyContent = false }) => {
  const Style = getResponsive();

  const Content = () => (
    <View style={Style.container}>
      <Text style={Style.text}>
        Con queste righe vogliamo darti le informazioni necessarie affinché tu sappia come sono trattati i tuoi dati personali acquisiti nell’ambito del progetto Runcard di FIDAL.{'\n'}
        <Text style={Style.bold}>Il titolare del trattamento</Text> è la Federazione Italiana di Atletica Leggera, che si trova in Via Flaminia nuova 830 -00191 Roma e puoi contattare tramite la casella mail privacy@fidal.it.{'\n'}
        I tuoi dati saranno trattati in modo lecito e secondo correttezza, come stabilito dal Regolamento Europeo UE n. 679/2016 e dal D.Lgs 196/2003 cosi come adeguato dal D.Lgs. 101/2018 in tema di protezione dei dati personali.{'\n'}
        Il trattamento dei tuoi dati personali è mirato unicamente alla realizzazione delle <Text style={Style.bold}>finalità</Text> istituzionali promosse dalla Federazione, tra le quali il progetto Runcard.{'\n'}
        Il conferimento dei tuoi dati personali è obbligatorio ai fini della partecipazione alle attività del progetto Runcard.{'\n'}
        L’eventuale rifiuto, comporterebbe l’impossibilità di partecipare alle attività di Runcard.{'\n'}
        Una volta tesserato, sarai soggetto ad obblighi di legge e regolamentari, nazionali ai quali non potrai sottrarti.{'\n'}
        Il trattamento dei tuoi dati personali è svolto dal Titolare e dalle persone da Lui autorizzate.{'\n'}
        I tuoi dati sono comunicati <Text style={Style.underlined}>agli organi di giustizia sportiva nazionale ed internazionale</Text>{'\n'}
        Laddove si rendesse necessaria, la comunicazione dei tuoi dati potrà essere effettuata a soggetti terzi che forniscono a FIDAL servizi collaterali e strumentali.{'\n'}
        Per diritto di cronaca e documentazione delle attività sportive, i tuoi dati potranno essere pubblicati con i mezzi che la federazione riterrà più opportuni; la <Text style={Style.bold}>diffusione</Text> dei dati avverrà nei limiti dell’essenzialità nell’informazione (<Text style={Style.underlined}>principio di essenzialità</Text>) riguardo a fatti di interesse pubblico.{'\n'}
        Il Titolare tratterà i tuoi dati personali per tutta la durata del tesseramento, per il tempo necessario ad adempiere alle finalità di cui sopra e successivamente per archiviazione a fini storici e statistici.{'\n'}
        I tuoi dati personali non verranno trasferiti all’estero.{'\n'}
        <Text style={Style.underlined}>In ogni momento potrai <Text style={Style.bold}>esercitare i diritti</Text> contemplati dalle normative vigenti in tema di Protezione dei Dati, più specificamente il diritto all’accesso ai dati personali, la rettifica o cancellazione degli stessi, la limitazione del trattamento che ti riguarda o l’opposizione al trattamento stesso e il diritto al reclamo presso una autorità di controllo.</Text>{'\n'}
        Potrai, in qualsiasi momento, <Text style={Style.bold}>esercitare i tuoi diritti</Text> scrivendo all’indirizzo di posta elettronica: privacy@fidal.it.{'\n'}
      </Text>
    </View>
  );

  return onlyContent ? <Content/> : (
    <ScrollView bounces={false}>
      <Text style={Style.h1}>Trattamento dei Dati Personali</Text>
      <View style={Style.textContainer}>
        <Content />
      </View>
    </ScrollView>
  );
};

PrivacyPolicyRuncard.propTypes = {
  onlyContent: PropTypes.bool,
};

export default PrivacyPolicyRuncard;
