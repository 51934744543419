import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';

import Style, { getResponsive } from './ConfirmPopupStyle';
import { Modal } from '../../Layout';
import { PrimaryButton, Button, ButtonSizes } from '../../Buttons';
import { CloseIcon } from '../../../Theme/Icons';

import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const ConfirmPopup = ({ title, text, closeHandler, confirmButton='OK', cancelButton='ANNULLA' }) => {
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  return (
    <View onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}>
      <Modal animationType={'fade'} transparent={true} onRequestClose={closeHandler}>
        <LinearGradient colors={['rgba(0,42,85,0.95)', 'rgba(0,42,85,0.5)']} style={getResponsive().gradient}>
          <View style={getResponsive().wrapper}>
            <Button onPress={() => closeHandler(false)} style={Style.closeButton}><CloseIcon /></Button>
            <View style={Style.container}>
              <Text style={Style.h2}>{title}</Text>
              {text && <Text style={Style.text}>{text}</Text>}
              <Button onPress={() => closeHandler(true)} size={'small'} style={[Style.cancelButton, { height: ButtonSizes['small'] }]}>
                <Text style={Style.cancelButtonText}>{confirmButton}</Text>
              </Button>
              <PrimaryButton onPress={() => closeHandler(false)} size={'small'} style={Style.button}>
                <Text>{cancelButton}</Text>
              </PrimaryButton>
            </View>
          </View>
        </LinearGradient>
      </Modal>
    </View>
  )
};

ConfirmPopup.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  confirmButton: PropTypes.string,
  cancelButton: PropTypes.string,
};

export default ConfirmPopup;
