import { StyleSheet, Platform } from 'react-native';
import { ApplicationStyles, Colors } from '../../Theme';

export default StyleSheet.create({
  clickWrapper: {
    bottom: 0,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
    left: 0,
    position:'absolute',
    right: 0,
    top: 0,
  },
  container: {
    ...ApplicationStyles.screen.container,
    backgroundColor: Colors.grayUltraFantaLight,
  },
  innerButtons: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 30,
    marginRight: 30,
    marginTop: 30,
  },
  innerContent: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'space-around',
    paddingBottom: 30,
    paddingTop: 30,
  },
  modalInnerContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 450,
    width: '100%',
  },
  modalWrapper: {
    bottom: 0,
    left: 0,
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    right: 0,
    top: 0,
  },
  passwordModal: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  passwordModalContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  passwordModalWrapper: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  scrollView: {
    justifyContent: 'center',
  },
})
