import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  border: {
  },
  buttonsContainer: {
    backgroundColor: Colors.white,
    flexDirection:'row',
    justifyContent: 'space-between',
  },
  confirmButton: {
    paddingHorizontal: 30,
    paddingVertical: 20,
  },
  confirmButtonText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
  },
  confirmButtonTextOk: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-2,
  },
  modalBackground: {
    flex: 1,
  },
  picker: {
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
    marginBottom: 10,
    paddingLeft: 5,
    paddingVertical: 1,
  },
  pickerIOS: {
    backgroundColor: Colors.white,
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
  },
  pickerInput: {
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
    marginBottom: 10,
    paddingVertical: 10,
  },
  pickerInputText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.gray,
    fontSize: Fonts.size.regular,
  },
  placeholder: {
    opacity: 0.4,
  },
});
