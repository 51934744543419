import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polygon } from 'react-native-svg';

import { Colors } from '../../Theme';

const CloseIcon = ({ size=20, color=Colors.white }) => (
  <Svg width={size} height={size} viewBox={'0 0 20 20'}>
    <G fill={color} fillRule={'nonzero'}>
      <Polygon points={'20 1.10578125 18.8942188 0 10 8.89421875 1.10578125 0 0 1.10578125 8.89421875 10 0 18.8942188 1.10578125 20 10 11.1057812 18.8942188 20 20 18.8942188 11.1057812 10'} />
    </G>
  </Svg>
);

CloseIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default CloseIcon;
