import React from "react";
import { SafeAreaView, ScrollView, Text, TextInput, View } from "react-native";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";

import Style, { BaseStyle } from "./LoginFormStyle";
import { isDesktop } from "../../../Services/ResponsiveHelper";
import {
  BackOrCloseButton,
  Button,
  PrimaryButton,
  SocialButton,
  Link,
} from "../../Buttons";
import { ForgotPasswordForm } from "../";

import RegistrationActions from "../../../Stores/Registration/Actions";
import AuthActions from "../../../Stores/Auth/Actions";
import LayoutActions from "../../../Stores/Layout/Actions";

const LoginForm = ({ email, isModal = false }) => {
  const dispatch = useDispatch();
  let listView;

  const [username, setUsername] = React.useState(email || "");
  const [password, setPassword] = React.useState("");

  const error = useSelector((state) => state.auth.error);
  const changePassword = useSelector((state) => state.auth.change_password);

  const submitLogin = () => dispatch(AuthActions.login({ username, password }));

  const openRegistration = () => {
    dispatch(LayoutActions.toggleLoginModal(false));
    dispatch(LayoutActions.toggleRegistrationModal(true));
  };

  const startChangePassword = () => {
    dispatch(AuthActions.startChangePassword(false));
  };

  return !isModal || !changePassword ? (
    <>
      <SafeAreaView
        style={[
          Style.wrapper,
          isDesktop() && !isModal ? Style.wrapperBackground : {},
        ]}
      >
        <ScrollView ref={(ref) => (listView = ref)} bounces={false}>
          <View style={isDesktop() && !isModal ? Style.containerWeb : {}}>
            {changePassword ? (
              <ForgotPasswordForm />
            ) : (
              <>
                {!isModal && <BackOrCloseButton style={Style.backButton} />}
                <View style={Style.container}>
                  <Text style={Style.h1}>Accedi</Text>
                  {error && <Text style={Style.error}>{error}</Text>}
                  <TextInput
                    style={Style.textInput}
                    textContentType={"emailAddress"}
                    autoCorrect={false}
                    keyboardType={"email-address"}
                    placeholder={"Email"}
                    value={username}
                    autoCapitalize={"none"}
                    onChangeText={setUsername}
                  />
                  <TextInput
                    style={Style.textInput}
                    textContentType={"password"}
                    autoCorrect={false}
                    secureTextEntry={true}
                    placeholder={"Password"}
                    value={password}
                    onChangeText={setPassword}
                  />
                  <View style={Style.forgotPassword}>
                    <Button
                      onPress={startChangePassword}
                      style={Style.forgotPasswordButton}
                    >
                      <Text style={Style.forgotPasswordText}>
                        Password dimenticata?
                      </Text>
                    </Button>
                  </View>
                  <PrimaryButton
                    onPress={submitLogin}
                    disabled={!username.length || !password.length}
                    style={Style.loginButton}
                  >
                    <Text>ACCEDI</Text>
                  </PrimaryButton>
                  <View
                    style={[Style.sectionWrapper, Style.sectionWrapperStrike]}
                  >
                    <View style={Style.titleStrike}>
                      <Text style={Style.socialText}>Oppure accedi con</Text>
                    </View>
                    <SocialButton
                      social={"google"}
                      onPress={() =>
                        dispatch(
                          RegistrationActions.socialRegistration("Google")
                        )
                      }
                    />
                    <SocialButton
                      social={"facebook"}
                      onPress={() =>
                        dispatch(
                          RegistrationActions.socialRegistration("Facebook")
                        )
                      }
                    />
                    <SocialButton
                      social={"apple"}
                      onPress={() =>
                        dispatch(
                          RegistrationActions.socialRegistration(
                            "SignInWithApple"
                          )
                        )
                      }
                    />
                  </View>
                  <View style={[Style.sectionWrapper, Style.marginTop]}>
                    {isModal ? (
                      <Button
                        onPress={openRegistration}
                        style={BaseStyle.registerLink}
                      >
                        <Text style={Style.registerText}>
                          Non sei ancora iscritto?{" "}
                          <Text style={Style.register}>REGISTRATI</Text>
                        </Text>
                      </Button>
                    ) : (
                      <Link route={"registration"}>
                        <View style={BaseStyle.registerLink}>
                          <Text style={Style.registerText}>
                            Non sei ancora iscritto?{" "}
                            <Text style={Style.register}>REGISTRATI</Text>
                          </Text>
                        </View>
                      </Link>
                    )}
                  </View>
                </View>
              </>
            )}
          </View>
        </ScrollView>
      </SafeAreaView>
    </>
  ) : (
    <ForgotPasswordForm showBack={false} isModal={isModal} />
  );
};

LoginForm.propTypes = {
  email: PropTypes.string,
  isModal: PropTypes.bool,
};

export default LoginForm;
