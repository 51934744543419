import { ApplicationStyles, Colors } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  bold: {
    ...ApplicationStyles.screen.fonts,
  },
  columnA: {
    flex: 1,
  },
  columnB: {
    flex: 3,
  },
  container: {
    ...ApplicationStyles.screen.paddingTabBar,
    flexGrow: 1,
  },
  cta: {
    ...ApplicationStyles.screen.fonts,
    textAlign: 'center',
    color: Colors.blue1,
  },
  downloadButton: {
    width: 'auto',
    alignSelf: 'flex-start',
    borderWidth: 1,
    borderColor: Colors.blue1,
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 20,
    flexDirection: 'row',
  },
  downloadButtonContainer: {
    flexDirection:'row',
    justifyContent:'flex-start',
  },
  downloadButtonText: {
    color: Colors.blue1,
  },
  downloadIcon: {
    marginTop: 3,
    marginRight: 2,
  },
  icon: {
    position: 'absolute',
    top: 12,
    left: 15,
  },
  info: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  innerContainer: {
    padding: 30,
  },
  inputContainer: {
    flexDirection: 'row',
    marginTop: 20,
  },
  input: {
    ...ApplicationStyles.screen.fontsLightItalic,
    backgroundColor: Colors.grayUltraLight,
    borderRadius: 50,
    color: Colors.grayMedium2,
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 12,
    paddingLeft: 50,
    flex: 1,
  },
  modal: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    elevation: 8,
    shadowOpacity: 0.50,
    shadowColor: Colors.black,
    shadowOffset: { width: 2, height: 5 },
    shadowRadius: 5,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 30,
    paddingBottom: 30,
    marginHorizontal: 30,
  },
  modalText: {
    ...ApplicationStyles.screen.fontsLight,
  },
  modalBackground: {
    backgroundColor: Colors.transparentBlue,
    flex: 1,
    justifyContent: 'space-around',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
  },
  modalClose: {
    padding: 30,
    alignSelf: 'flex-end',
  },
  note: {
    ...ApplicationStyles.screen.fonts,
    marginLeft: 10,
    marginRight: 30,
    color: Colors.blue1,
  },
  notFound: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
  },
  pdfModalBackground: {
    marginTop: 89,
    backgroundColor: Colors.mediumGray,
    flex: 1,
    justifyContent: 'space-around',
  },
  pdfModalContainer: {
    flex: 1,
  },
  pdfModalDownload: {
    paddingHorizontal: 10,
    alignSelf: 'flex-end',
  },
  results: {
    marginTop: 20,
  },
  row: {
    flexDirection:'row',
    paddingVertical: 10,
    borderBottomWidth: 1,
    borderColor: Colors.grayALittleLight,
  },
  tableContent: {
    ...ApplicationStyles.screen.fontsLight,
    paddingBottom: 5,
  },
  tableHeader: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
    marginVertical: 10,
  },
  webContainer: {
  },
};

const StyleLG = {
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
  modalContainer: {
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
