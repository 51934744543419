import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from "../../../Services/ResponsiveHelper";

const BaseStyle = {
  advColumn: {

  },
  backButton: {
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButtonText: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-2,
    textDecorationLine: 'none',
  },
  backButtonWrapper: {
    borderWidth: 1,
    borderColor: Colors.blue1,
    borderRadius: 20,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
  columns: {

  },
  container: {
    ...ApplicationStyles.screen.container,
    ...ApplicationStyles.screen.paddingTabBar,
  },
  content: {
    marginTop: 30,
  },
  data: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.regular-2,
    paddingBottom: 5,
  },
  innerContainer: {
    flex: 1,
  },
  news: {
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
  },
  newsContent: {
    marginTop: 20,
  },
  newsSource: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-2,
    paddingTop: 5,
  },
  noNewsText: {
    ...ApplicationStyles.screen.fontsLight,
    marginBottom: 20,
    marginTop: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  summary: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    paddingTop: 5,
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.h1,
  },
  titleContainer: {
    flex: 1,
  },
  webViewContent: {
    flex: 1
  },
  wrapper: {
    flex: 1
  }
};

const StyleLG = {
  columns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    paddingTop: 20,
  },
  innerContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
  wrapper: {
    backgroundColor: Colors.grayUltraFantaLight,
    minHeight: '100%',
    flex: null,
  },
  news: {
    width: 600,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
