import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop, Polygon } from 'react-native-svg';

const SaveRaceIcon = ({ size=28, fill=false }) => (
  <Svg width={size} height={size} viewBox={'0 0 19 28'}>
    <Defs>
      <LinearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'saveRaceGradient'}>
        <Stop stopColor={'#0D85F1'} offset={'0%'} />
        <Stop stopColor={'#02AFF1'} offset={'100%'} />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} strokeWidth={'1'} fillRule={'evenodd'} fill={'url(#saveRaceGradient)'}>
      {!fill
        ? <Path d={'M0,0 L0,4.24 L0,6.527 L0,28 L9.5,21.765 L19,27.999 L19,0 L0,0 Z M1.825,6.844 L1.825,4.772 L1.825,1.825 L4.126,1.825 L7.375,1.825 L11.625,1.825 L17.176,1.825 L17.176,8.162 L17.176,8.667 L17.176,12.079 L17.176,24.622 L9.5,19.569 L1.825,24.622 L1.825,6.844 Z'} />
        : <Polygon points={'0 0 0 4.23978336 0 6.52732644 0 28 9.5 21.7641556 19 28 19 0'} />
      }
    </G>
  </Svg>
);

SaveRaceIcon.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.bool,
};

export default SaveRaceIcon;
