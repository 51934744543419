import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  Image,
  ImageBackground,
  SafeAreaView,
  ScrollView,
  Switch,
  Text,
  View,
  Platform,
} from "react-native";
import { Config } from "../../../Config";

import Style, { getResponsive } from "./BuyRuncardStyle";
import { PrimaryButton, Button } from "../../../Components/Buttons";
import { Registration, Payment } from "../";
import { BackIcon } from "../../../Theme/Icons";
import { Colors, Images } from "../../../Theme";
import { Footer, Modal } from "../../Layout";
import { PrivacyPolicyRuncard } from "../../Legals";

import ResponsiveHelper, {
  isDesktop,
} from "../../../Services/ResponsiveHelper";
import RegistrationActions from "../../../Stores/Registration/Actions";

const BuyRuncard = ({
  buyHandler,
  foreign: externalForeign,
  cardType: externalRuncardType,
}) => {
  const dispatch = useDispatch();
  let listView;

  const [{}, setBreakpoint] = React.useState(ResponsiveHelper.getSize());

  const [step, setStep] = React.useState(
    externalForeign !== null ? (externalRuncardType !== null ? 3 : 2) : 1
  );
  const [foreign, setForeign] = React.useState(externalForeign);
  const [runcardType, setRuncardType] = React.useState(externalRuncardType);
  const [privacyModal, setPrivacyModal] = React.useState(false);

  const scrollTop = () => {
    if (listView) listView.scrollTo({ y: 0 });
    if (Platform.OS === "web" && window) {
      window.scroll(0, 0);
    }
  };

  React.useEffect(() => {
    setStep(
      externalForeign !== null ? (externalRuncardType !== null ? 3 : 2) : 1
    );
  }, []);

  React.useEffect(() => {
    scrollTop();
  }, [step]);

  const backStep = () => {
    dispatch(RegistrationActions.registrationReset());
    if (step === 1) {
      // Close buy
      buyHandler(false);
    } else {
      setStep(step - 1);
    }
  };

  const SelectNationality = () => (
    <>
      <View style={[Style.switchContainer, Style.switchMarginBottom]}>
        <Switch
          value={foreign === false}
          onValueChange={(val) => setForeign(val ? !val : null)}
        />
        <Button onPress={() => setForeign(false)} style={Style.switchInner}>
          <Text
            style={[
              Style.cardTypeTitle,
              foreign === false ? Style.cardTypeTitleSelected : {},
            ]}
          >
            ITALIA
          </Text>
          <Text style={Style.cardTypeText}>
            Valida per tutti i cittadini con residenza italiana.
          </Text>
        </Button>
      </View>
      <View style={[Style.switchContainer, Style.switchMarginBottom]}>
        <Switch
          value={foreign === true}
          onValueChange={(val) => setForeign(val ? val : null)}
        />
        <Button onPress={() => setForeign(true)} style={Style.switchInner}>
          <Text
            style={[
              Style.cardTypeTitle,
              foreign === true ? Style.cardTypeTitleSelected : {},
            ]}
          >
            ESTERO
          </Text>
          <Text style={Style.cardTypeText}>
            Valida per tutti coloro che non risiedono in italia ma intendono
            partecipare alle manifestazioni FIDAL
          </Text>
        </Button>
      </View>
      <View>
        <PrimaryButton
          onPress={() => (foreign !== null ? setStep(2) : {})}
          style={Style.button}
          disabled={foreign === null}
        >
          AVANTI
        </PrimaryButton>
      </View>
    </>
  );

  const ChooseRuncard = () => (
    <>
      {Object.keys(Config.RUNCARD_TYPES)
        .filter((type) => Config.RUNCARD_TYPES[type].available)
        .map((type) => (
          <View style={Style.switchContainer} key={type}>
            <Switch
              value={runcardType === type}
              onValueChange={(val) => setRuncardType(val ? type : null)}
            />
            <Button
              onPress={() => setRuncardType(type)}
              style={Style.switchInner}
            >
              <Text
                style={[
                  Style.cardTypeTitle,
                  runcardType === type ? Style.cardTypeTitleSelected : {},
                ]}
              >
                {Config.RUNCARD_TYPES[type].short_name} -{" "}
                {!foreign
                  ? Config.RUNCARD_TYPES[type].price
                  : Config.RUNCARD_TYPES[type].price_foreign}
                €
              </Text>
              <Text style={Style.cardTypeText}>
                {Config.RUNCARD_TYPES[type].description}
              </Text>
            </Button>
          </View>
        ))}
      <View>
        <PrimaryButton
          onPress={() => setStep(3)}
          style={Style.button}
          disabled={runcardType === null}
        >
          AVANTI
        </PrimaryButton>
      </View>
    </>
  );

  const stepHeader = {
    1: null,
    2: null,
    3: null,
    4: <Text style={Style.headerTextx}>Pagamento</Text>,
  };

  const stepTitles = {
    1: (
      <Text style={Style.lead}>
        Acquista{" "}
        <Text style={Style.leadBold}>
          Runcard{"\n"}
          <Text style={Style.leadBlue}>indica la tua residenza</Text>
        </Text>
      </Text>
    ),
    2: (
      <Text style={Style.lead}>
        Scegli la{" "}
        <Text style={Style.leadBold}>
          Runcard{"\n"}
          <Text style={Style.leadBlue}>più adatta a te</Text>
        </Text>
        !
      </Text>
    ),
    3: (
      <Text style={Style.lead}>
        Registrazione dati{"\n"}
        <Text style={Style.leadBold}>
          <Text style={Style.leadBlue}>Runcard</Text>
        </Text>
      </Text>
    ),
    4: null,
  };

  const stepContent = {
    1: <SelectNationality />,
    2: <ChooseRuncard />,
    3: (
      <Registration
        foreign={foreign}
        runcardType={runcardType}
        scrollTop={scrollTop}
        setPrivacyModal={setPrivacyModal}
        successHandler={() => setStep(4)}
      />
    ),
    4: (
      <Payment
        runcardType={runcardType}
        foreign={foreign}
        scrollTop={scrollTop}
        back={() => {
          setStep(2);
        }}
      />
    ),
  };

  const StepHeader = () =>
    !isDesktop() ? (
      <View style={Style.topContainer}>
        <ImageBackground
          source={Images.bg_buy}
          style={Style.background}
          resizeMode="cover"
        >
          <SafeAreaView style={Style.container}>
            <View style={Style.innerTopContainer}>
              <Button onPress={() => backStep()} style={Style.backButton}>
                <BackIcon />
              </Button>
              {step === 4 && stepHeader[step]}
              {step === 4 && (
                <Button onPress={() => {}} style={Style.backButton} />
              )}
            </View>
            {stepHeader[step]}
          </SafeAreaView>
        </ImageBackground>
      </View>
    ) : (
      <>
        <ImageBackground source={Images.bg_runcard} resizeMode="cover">
          <View style={Style.headerContainer}>
            <View style={Style.headerColumnLeft}>
              <Text style={Style.h1}>Registra{"\n"}la tua Runcard!</Text>
              <Text style={Style.h2}>
                Entra nella Community e accedi a tutte le gare
              </Text>
            </View>
            <View style={Style.headerColumnRight}>
              <Image
                source={Images.card_bg_full}
                style={Style.headerCardImage}
                resizeMode={"contain"}
              />
            </View>
          </View>
        </ImageBackground>
        <View style={getResponsive().backButtonWebContainer}>
          <View style={Style.backButtonWebWrapper}>
            <Button
              onPress={() => (step === 3 ? buyHandler(false) : backStep())}
              style={Style.backButtonWeb}
            >
              <BackIcon color={Colors.blue1} size={10} stroke={2} />
              <Text style={Style.backButtonWebText}>Indietro</Text>
            </Button>
          </View>
        </View>
      </>
    );

  return (
    <View
      onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}
      style={Style.container}
    >
      {step === 4 && <StepHeader />}
      <ScrollView ref={(ref) => (listView = ref)} bounces={false}>
        {step !== 4 && <StepHeader />}
        <SafeAreaView style={Style.container}>
          <View
            style={[Style.centerContainer, step === 4 ? Style.fullWidth : {}]}
          >
            <View style={getResponsive().innerContainer}>
              {stepTitles[step]}
              {stepContent[step]}
            </View>
          </View>
        </SafeAreaView>
      </ScrollView>
      {privacyModal && (
        <Modal
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => setPrivacyModal(false)}
        >
          <SafeAreaView style={Style.modal}>
            <View style={Style.closeContainer}>
              <Button onPress={() => setPrivacyModal(false)}>
                <BackIcon />
              </Button>
            </View>
            <View style={Style.modalContainer}>
              <PrivacyPolicyRuncard />
            </View>
          </SafeAreaView>
        </Modal>
      )}
      <Footer />
    </View>
  );
};

BuyRuncard.propTypes = {
  buyHandler: PropTypes.func,
  foreign: PropTypes.bool,
  cardType: PropTypes.string,
};

export default BuyRuncard;
