import { ApplicationStyles } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const BaseStyle = {
  activities: {
  },
  advColumn: {
  },
  columns: {
  },
  container: {
    ...ApplicationStyles.screen.container,
  },
  innerContainer: {
    flexGrow: 1,
  },
  linkServices: {
    ...ApplicationStyles.screen.paddingTabBar,
    paddingLeft: 30,
    paddingRight: 30,
  },
  wrapper: {
    flexGrow: 1,
  },
};

const StyleLG = {
  activities: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    width: 600,
    flexGrow: 1,
  },
  advColumn: {
    width: 300,
    marginTop: 60,
  },
  columns: {
    paddingTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
  container: {
    paddingBottom: 0,
    paddingTop: 30,
  },
  innerContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
