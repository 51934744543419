import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop, Polygon, Mask, Use } from 'react-native-svg';

const ConnectionIcon = ({ size=112 }) => (
  <Svg width={size} height={size} viewBox={'0 0 112 100'}>
    <Defs>
      <Polygon id="Connection-path-1" points="0.3745 0.8553 19.5185 0.8553 19.5185 20 0.3745 20" />
      <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="Connection-Gradient">
        <Stop stopColor="#FFFFFF" offset="0%" />
        <Stop stopColor="#E3E4E3" offset="100%" />
      </LinearGradient>
      <Polygon id="Connection-path-2" points="0.8461 0.5634 44.793 0.5634 44.793 32.7854 0.8461 32.7854" />
    </Defs>
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <G transform="translate(46.000000, 79.387300)">
        <Mask id="Connection-mask-1" fill="white">
          <Use href="#Connection-path-1" />
        </Mask>
        <Path d="M9.9465,0.8553 C4.6595,0.8553 0.3745,5.1403 0.3745,10.4273 C0.3745,15.7143 4.6595,20.0003 9.9465,20.0003 C15.2315,20.0003 19.5185,15.7143 19.5185,10.4273 C19.5185,5.1403 15.2315,0.8553 9.9465,0.8553" fill="#FFFFFF" mask="url(#Connection-mask-1)" />
      </G>
      <Path d="M44.9348,34.8598 C34.3858,36.9798 24.6928,42.1578 16.9198,49.9538 L27.0878,60.0908 C32.4468,54.7158 39.0418,51.0308 46.2328,49.2868 C45.8028,44.4028 45.3638,39.5298 44.9348,34.8598" fill="#FFFFFF" />
      <Path d="M65.6115,49.2983 C72.7825,51.0473 79.3595,54.7293 84.7065,60.0913 L94.8755,49.9533 C87.1225,42.1773 77.4595,37.0073 66.9405,34.8763 C66.5005,39.5553 66.0485,44.4273 65.6115,49.2983" fill="url(#Connection-Gradient)" />
      <G transform="translate(67.000000, 9.387300)">
        <Mask id="Connection-mask-2" fill="white">
          <Use href="#Connection-path-2" />
        </Mask>
        <Path d="M2.1701,0.5634 C1.8511,4.2734 1.3851,9.2194 0.8461,14.9154 C13.5751,17.2824 25.2851,23.4204 34.6301,32.7854 L44.7931,22.6424 C33.0461,10.8724 18.2421,3.2674 2.1701,0.5634" fill="#FFFFFF" mask="url(#Connection-mask-2)" />
      </G>
      <Path d="M43.385,17.1694 C43.132,14.4664 42.905,12.0304 42.71,9.9364 C26.604,12.6274 11.769,20.2374 0,32.0294 L10.163,42.1724 C19.533,32.7824 31.28,26.6364 44.049,24.2834 C43.817,21.7824 43.593,19.3964 43.385,17.1694" fill="#FFFFFF" />
      <Path d="M63.6213,74.9467 C65.1353,75.7357 66.5363,76.7587 67.7743,78.0067 L77.9593,67.8837 C74.2253,64.1277 69.7063,61.4477 64.7693,59.9867 C64.3313,65.2207 63.9353,70.2937 63.6213,74.9467" fill="url(#Connection-Gradient)" />
      <Path d="M33.8342,67.8833 L44.0172,78.0063 C45.2772,76.7383 46.7062,75.7003 48.2502,74.9063 C47.9062,70.2363 47.5022,65.1783 47.0652,59.9753 C42.1132,61.4333 37.5772,64.1173 33.8342,67.8833" fill="#FFFFFF" />
      <Path d="M46.55,3.0434 C46.623,3.9084 46.695,4.7534 46.75,5.5114 L47.504,13.5954 C49.106,30.7144 51.498,56.2844 52.902,75.5464 L58.975,75.5464 C60.011,59.9354 61.911,39.8684 63.452,23.6104 C64.154,16.2164 64.758,9.8314 65.144,5.2934 C65.37,3.4714 65.529,1.7034 65.612,0.1564 L46.31,0.0004 C46.373,0.9494 46.463,2.0064 46.55,3.0434" fill="#FFFFFF" />
    </G>
  </Svg>
);

ConnectionIcon.propTypes = {
  size: PropTypes.number,
};

export default ConnectionIcon;
