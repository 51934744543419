import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { View, SafeAreaView, ScrollView, Platform, StyleSheet } from 'react-native';
import { Switch, Route } from 'react-router-native';
import LinearGradient from 'react-native-linear-gradient';

import Style from './AppScreenStyle';
import ResponsiveHelper, { isDesktop } from '../../Services/ResponsiveHelper';
import { Colors } from '../../Theme';
import { Sidebar, TabNav, Header, PageNotFound } from '../../Components/Layout';
import Tutorial from '../../Components/Tutorial/Tutorial';
import { CloseIcon, InfoIcon } from '../../Theme/Icons';
import { Button } from '../../Components/Buttons';
import {
  ForgotPasswordForm,
  LoginForm,
  RegistrationComplete,
  RegistrationConfirm,
  RegistrationForm
} from '../../Components/Auth';
import { GoalPopup } from '../../Components/Popup';

import { RouteWithSubRoutes } from '../../Router/Router';
import LayoutActions from '../../Stores/Layout/Actions';
import AuthActions from '../../Stores/Auth/Actions';

const AppScreen = ({ routes }) => {
  const dispatch = useDispatch();

  const [tutorial, setTutorial] = React.useState(false);
  const [{ }, setBreakpoint] = React.useState(0);

  const mapRegistrationStatus = {
    TO_BE_COMPLETED: <RegistrationComplete isModal={true} />,
    CONFIRM: <RegistrationConfirm isModal={true} />,
    COMPLETED: null,
  };

  const challengesWonPopup = useSelector(state => state.challenges.challenges_won);
  const loginModal = useSelector(state => state.layout.loginModal);
  const registrationModal = useSelector(state => state.layout.registrationModal);
  const registrationStatus = useSelector(state => state.registration.status);
  const changePassword = useSelector(state => state.auth.change_password);

  React.useEffect(() => {
    if (registrationStatus === 'COMPLETED') {
      dispatch(LayoutActions.toggleRegistrationModal(false));
    }
  }, [registrationStatus]);

  return (
    <View style={Style.container} onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}>
      <Header />
      <View style={Style.container}>
        <View style={Style.container}>
          <Switch>
            {routes.map(route => (<RouteWithSubRoutes key={route.name} {...route} openTutorial={() => setTutorial(true)} />))}
            <Route path='*'>
              <PageNotFound />
            </Route>
          </Switch>
        </View>
        {!isDesktop() && <TabNav />}
      </View>
      <Sidebar />
      {tutorial && (
        <Tutorial onClose={() => setTutorial(false)} onEnd={() => setTutorial(false)} button={'CHIUDI'} />
      )}
      {((loginModal || registrationModal) && !tutorial) && (
        <LinearGradient colors={['rgba(0,42,85,0.95)', 'rgba(0,42,85,0.5)']} style={Style.modalWrapper}>
          <ScrollView contentContainerStyle={Style.scrollView}>
            <View style={Style.modalContainer}>
              {Platform.OS === 'web' && <div style={StyleSheet.flatten(Style.clickWrapper)} onClick={() => { dispatch(LayoutActions.toggleLoginModal(false)); dispatch(LayoutActions.toggleRegistrationModal(false)); dispatch(AuthActions.endChangePassword()); }} />}
              <View style={Style.modalInnerContainer}>
                <View style={Style.innerButtons}>
                  {!changePassword ? (
                    <Button onPress={() => setTutorial(true)}>
                      <InfoIcon color={Colors.blue1} />
                    </Button>
                  ) : <View />}
                  <Button onPress={() => { dispatch(LayoutActions.toggleLoginModal(false)); dispatch(LayoutActions.toggleRegistrationModal(false)); dispatch(AuthActions.endChangePassword()); }}>
                    <CloseIcon color={Colors.blue1} />
                  </Button>
                </View>
                <View style={Style.innerContent}>
                  {loginModal && <LoginForm isModal={true} />}
                  {registrationModal && (mapRegistrationStatus[registrationStatus] || <RegistrationForm isModal={true} />)}
                </View>
              </View>
            </View>
          </ScrollView>
        </LinearGradient>
      )}
      {(challengesWonPopup.length > 0) && (
        <GoalPopup challenge={challengesWonPopup[0]} />
      )}
      {(changePassword && !isDesktop()) && (
        <View style={Style.passwordModalWrapper} onRequestClose={() => dispatch(AuthActions.endChangePassword())}>
          <SafeAreaView style={Style.passwordModal}>
            <View style={Style.passwordModalContainer}>
              <ForgotPasswordForm closeHandler={() => dispatch(AuthActions.endChangePassword())} />
            </View>
          </SafeAreaView>
        </View>
      )}
    </View>
  )
};

AppScreen.propTypes = {
  routes: PropTypes.array,
};

export default AppScreen;
