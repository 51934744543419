import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { OrderTypes } from './Actions'

export const setOrder = (state, { order_id }) => ({
  ...state,
  order_id,
});

export const setOrderError = (state, { error }) => ({
  ...state,
  order_error: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [OrderTypes.SET_ORDER]: setOrder,
  [OrderTypes.SET_ORDER_ERROR]: setOrderError,
});
