import { ApplicationStyles, Colors, Fonts } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const BaseStyle = {
  button: {
    width: 300,
  },
  buttonContainer: {
    marginBottom: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  column: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 50,
  },
  columns: {
  },
  imageContainer: {
    display: 'none',
  },
  spacer: {
    width: 60,
  },
  textContainer: {
    flex: 3,
  },
  title: {
    ...ApplicationStyles.screen.h1,
    color: Colors.gray,
    textAlign: 'left',
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 50,
  },
  titleImage: {
    marginLeft: 20,
    marginRight: 20,
    maxHeight: 100,
  },
  itemImg: {
    aspectRatio: 104/128,
    flex: 1,
    maxHeight: 100,
  },
  itemText: {
    ...ApplicationStyles.screen.fontsRegular,
    fontSize: Fonts.size.regular,
  },
  itemTitle: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+3,
    marginBottom: 30,
    textTransform: 'uppercase',
  },
};

const StyleLG = {
  columns: {
    flexDirection: 'row',
  },
  title: {
    textAlign: 'center',
  },
  titleImage: {
    display: 'none',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
