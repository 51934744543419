import { INITIAL_STATE } from './InitialState';
import { createReducer } from 'reduxsauce';
import { ChallengesTypes } from './Actions';

export const setEditorialChallenges = (state, { data }) => ({
  ...state,
  editorial_challenges: data,
});

export const mergeEditorialChallenges = (state, { data }) => ({
  ...state,
  editorial_challenges: state.editorial_challenges ? data.reduce((acc, challenge) => {
    const i = acc.findIndex(el => el.id === challenge.id);
    if (i === -1) {
      acc.push(challenge);
    } else {
      acc[i] = challenge;
    }
    return acc;
  }, state.editorial_challenges) : data,
});

export const setEditorialChallengesError = (state, { error }) => ({
  ...state,
  editorial_error_challenges: error,
});

export const setLoadMoreEditorialChallenges = (state, { status }) => ({
  ...state,
  editorial_loadmore_challenges: status,
});

export const setUserChallenges = (state, { data }) => ({
  ...state,
  user_challenges: data,
});

export const mergeUserChallenges = (state, { data }) => ({
  ...state,
  user_challenges: state.user_challenges ? data.reduce((acc, challenge) => {
    const i = acc.findIndex(el => el.id === challenge.id);
    if (i === -1) {
      acc.push(challenge);
    } else {
      acc[i] = challenge;
    }
    return acc;
  }, state.user_challenges) : data,
});

export const setUserErrorChallenges = (state, { error }) => ({
  ...state,
  user_error_challenges: error,
});

export const setLoadMoreUserChallenges = (state, { status }) => ({
  ...state,
  user_loadmore_challenges: status,
});

export const setChallenge = (state, { data }) => ({
  ...state,
  challenge: data,
});

export const createChallengeSuccess = (state, { status }) => ({
  ...state,
  create_challenge_success: status,
});

export const setChallengesResults = (state, { data }) => ({
  ...state,
  challenges_results: { ...state.challenges_results, ...data },
});

export const addChallengeWon = (state, { challenge }) => {
  return {
    ...state,
    challenges_won: [ ...state.challenges_won, challenge ],
  }
};

export const removeChallengeWon = (state, { challenge_id }) => {
  return {
    ...state,
    challenges_won: state.challenges_won.filter(el => el.id !== challenge_id),
  }
};

export const reducer = createReducer(INITIAL_STATE, {
  [ChallengesTypes.SET_EDITORIAL_CHALLENGES]: setEditorialChallenges,
  [ChallengesTypes.MERGE_EDITORIAL_CHALLENGES]: mergeEditorialChallenges,
  [ChallengesTypes.SET_EDITORIAL_CHALLENGES_ERROR]: setEditorialChallengesError,
  [ChallengesTypes.SET_LOAD_MORE_EDITORIAL_CHALLENGES]: setLoadMoreEditorialChallenges,
  [ChallengesTypes.SET_USER_CHALLENGES]: setUserChallenges,
  [ChallengesTypes.MERGE_USER_CHALLENGES]: mergeUserChallenges,
  [ChallengesTypes.SET_USER_ERROR_CHALLENGES]: setUserErrorChallenges,
  [ChallengesTypes.SET_LOAD_MORE_USER_CHALLENGES]: setLoadMoreUserChallenges,
  [ChallengesTypes.SET_CHALLENGE]: setChallenge,
  [ChallengesTypes.CREATE_CHALLENGE_SUCCESS]: createChallengeSuccess,
  [ChallengesTypes.SET_CHALLENGES_RESULTS]: setChallengesResults,
  [ChallengesTypes.ADD_CHALLENGE_WON]: addChallengeWon,
  [ChallengesTypes.REMOVE_CHALLENGE_WON]: removeChallengeWon,
});
