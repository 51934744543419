import React from 'react';
import PropTypes from 'prop-types';
import Svg, { Circle } from 'react-native-svg';

import { Colors } from '../../Theme';

const CameraIcon = ({ size=10, color=Colors.gray, empty=false }) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    {empty
      ? <Circle r={(size/2)} fill={'none'} stroke={color} strokeWidth={'1'} cx={(size/2)} cy={(size/2)} />
      : <Circle r={(size/2)} fill={color} cx={(size/2)} cy={(size/2)} />
    }
  </Svg>
);

CameraIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  empty: PropTypes.bool,
};

export default CameraIcon;
