import React from "react";
import { View, Text, TextInput, Keyboard, Switch, Image } from "react-native";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Config } from "../../../../Config";
import { ConfigCattolica } from "../../../../Config/cattolica";

import { getResponsive } from "./FormStyle";
import { Button, PrimaryButton, ToggleButton } from "../../../Buttons";
import { DatePicker, SelectPicker } from "../../../Forms";
import { Images } from "../../../../Theme";

import CattolicaActions from "../../../../Stores/Cattolica/Actions";
import HelperActions from "../../../../Stores/Helper/Actions";
import {
  validateCodiceFiscale,
  validateCap,
} from "../../../../Services/ValidationService";
import { isDesktop } from "../../../../Services/ResponsiveHelper";

const Form = ({ selected, scrollTop, clickHandler }) => {
  const Style = getResponsive();
  const dispatch = useDispatch();

  const MAX_AGE = 75;
  const DELTA_DAYS = selected !== "meteo" ? 1 : 7;

  const user = useSelector((state) => state.auth.user);
  const events = useSelector((state) => state.cattolica.events);
  const { provinces, countries } = useSelector((state) => state.helper);

  const [coveragePeriod, setCoveragePeriod] = React.useState(6);
  const [name, setName] = React.useState(user.given_name || "");
  const [myprovince, setMyprovince] = React.useState("");
  const [surname, setSurname] = React.useState(user.family_name || "");
  const [birthDate, setBirthDate] = React.useState(
    user.birthdate ? new Date(user.birthdate) : ""
  );
  const [taxCode, setTaxCode] = React.useState(user.tax_code || "");
  const [gender, setGender] = React.useState(user.gender || "");
  const [address, setAddress] = React.useState(user.address || "");
  const [city, setCity] = React.useState(user.city || "");
  const [cap, setCAP] = React.useState(user.cap || "");
  const [province, setProvince] = React.useState(user.province || "");
  const [consent1, setConsent1] = React.useState(true);
  const [consent2, setConsent2] = React.useState(false);
  const [consent3, setConsent3] = React.useState(false);
  const [consent4, setConsent4] = React.useState(false);
  const [consent5, setConsent5] = React.useState(false);
  const [consent6, setConsent6] = React.useState(false);
  const [formError, setFormError] = React.useState("");
  const [eventStart, setEventStart] = React.useState(null);
  const [eventEnd, setEventEnd] = React.useState(null);
  const [eventName, setEventName] = React.useState("");
  const [eventCity, setEventCity] = React.useState("");
  const [eventCountry, setEventCountry] = React.useState("");
  const [eventProvince, setEventProvince] = React.useState("");
  const [eventsCountries, setEventsCountries] = React.useState(null);

  React.useEffect(() => {
    dispatch(CattolicaActions.getEvents());
    if (!countries) {
      dispatch(HelperActions.getCountries());
    }
    if (!provinces) {
      dispatch(HelperActions.getProvinces());
    }
    if (!events) {
      dispatch(CattolicaActions.getEvents());
    } else {
      setEventsCountries(
        [...events, { country: "Italia" }]
          .reduce((acc, el) => {
            if (acc.indexOf(el.country) === -1) acc.push(el.country);
            return acc;
          }, [])
          .sort()
          .map((country) => ({ id: country, value: country }))
      );
    }
  }, []);

  React.useEffect(() => {
    if (events) {
      setEventsCountries(
        [...events, { country: "Italia" }]
          .reduce((acc, el) => {
            if (acc.indexOf(el.country) === -1) acc.push(el.country);
            return acc;
          }, [])
          .sort()
          .map((country) => ({ id: country, value: country }))
      );
    } else {
      setEventsCountries(null);
    }
  }, [events]);

  const handleSubmitData = () => {
    scrollTop();
    if (!name.length) {
      setFormError("Nome è un campo obbligatorio");
      return false;
    }
    if (!surname.length) {
      setFormError("Cognome è un campo obbligatorio");
      return false;
    }
    if (!birthDate || birthDate === "") {
      setFormError("Data di nascita è un campo obbligatorio");
      return false;
    }
    if (!moment(birthDate)) {
      setFormError("Formato della data di nascita non valido");
      return false;
    }
    if (
      moment(birthDate).isAfter(
        moment()
          .subtract(Config.MIN_AGE, "years")
          .toDate()
      )
    ) {
      setFormError(`Devi avere almeno ${Config.MIN_AGE} anni per procedere`);
      return false;
    }
    if (
      moment(birthDate).isBefore(
        moment()
          .subtract(MAX_AGE, "years")
          .toDate()
      )
    ) {
      setFormError(`Devi avere non più di ${MAX_AGE} anni per procedere`);
      return false;
    }
    if (!gender.length) {
      setFormError("Genere è un campo obbligatorio");
      return false;
    }
    if (!taxCode.length) {
      setFormError("Codice Fiscale è un campo obbligatorio");
      return false;
    }
    if (!validateCodiceFiscale(taxCode)) {
      setFormError("Formato del Codice Fiscale non valido");
      return false;
    }
    if (!address.length) {
      setFormError("Indirizzo è un campo obbligatorio");
      return false;
    }
    if (!city.length) {
      setFormError("Comune è un campo obbligatorio");
      return false;
    }
    if (!cap.length) {
      setFormError("CAP è un campo obbligatorio");
      return false;
    }
    if (!validateCap(cap)) {
      setFormError("Formato del CAP non valido");
      return false;
    }
    if (!province.length) {
      setFormError("Provincia è un campo obbligatorio");
      return false;
    }

    if (selected !== "allenamento-protetto") {
      if (!eventName.length) {
        setFormError("Nome dell'evento è un campo obbligatorio");
        return false;
      }
      if (!eventCountry.length) {
        setFormError("Nazione dell'evento è un campo obbligatorio");
        return false;
      }
      if (selected !== "meteo" || eventCountry === "IT")
        if (!eventProvince.length) {
          setFormError("Provincia dell'evento è un campo obbligatorio");
          return false;
        }
      if (!eventCity.length) {
        setFormError("Comune dell'evento è un campo obbligatorio");
        return false;
      }

      if (!eventStart || eventStart === "") {
        setFormError("Data di inizio dell'evento è un campo obbligatorio");
        return false;
      }
      if (!moment(eventStart)) {
        setFormError("Formato della data di inizio dell'evento non valido");
        return false;
      }
      // if (
      //   moment(eventStart)
      //     .startOf("day")
      //     .isBefore(
      //       moment()
      //         .add(DELTA_DAYS, "days")
      //         .startOf("day")
      //     )
      // ) {
      //   setFormError("La data di inizio dell'evento non deve essere passata");
      //   return false;
      // }
      if (!eventEnd || eventEnd === "") {
        setFormError("Data di fine dell'evento è un campo obbligatorio");
        return false;
      }
      if (!moment(eventEnd)) {
        setFormError("Formato della data di fine dell'evento non valido");
        return false;
      }
      // if (
      //   moment(eventEnd)
      //     .startOf("day")
      //     .isBefore(
      //       moment()
      //         .add(DELTA_DAYS, "days")
      //         .startOf("day")
      //     )
      // ) {
      //   setFormError("La data di fine dell'evento non deve essere passata");
      //   return false;
      // }
      if (
        moment(eventEnd)
          .startOf("day")
          .isBefore(moment(eventStart).startOf("day"))
      ) {
        setFormError(
          "La data di fine dell'evento non può essere precedente alla data di fine"
        );
        return false;
      }
    } else {
      if (!eventStart || eventStart === "") {
        setFormError("Data di inizio della copertura è un campo obbligatorio");
        return false;
      }
      if (!moment(eventStart)) {
        setFormError("Formato della data di inizio della copertura non valido");
        return false;
      }
      // if (
      //   moment(eventStart)
      //     .startOf("day")
      //     .isBefore(
      //       moment()
      //         .add(1, "day")
      //         .startOf("day")
      //     )
      // ) {
      //   setFormError(
      //     "La data di inizio della copertura non deve essere passata"
      //   );
      //   return false;
      // }
      // if (!eventEnd || eventEnd === "") {
      //   setFormError("Data di fine della copertura è un campo obbligatorio");
      //   return false;
      // }
      // if (!moment(eventEnd)) {
      //   setFormError("Formato della data di fine della copertura non valido");
      //   return false;
      // }
      // if (
      //   moment(eventEnd)
      //     .startOf("day")
      //     .isBefore(
      //       moment()
      //         .add(1, "day")
      //         .startOf("day")
      //     )
      // ) {
      //   setFormError("La data di fine della copertura non deve essere passata");
      //   return false;
      // }
      // if (
      //   moment(eventEnd)
      //     .startOf("day")
      //     .isBefore(moment(eventStart).startOf("day"))
      // ) {
      //   setFormError(
      //     "La data di fine della copertura non può essere precedente alla data di fine"
      //   );
      //   return false;
      // }
    }

    setFormError("");

    const data = {
      name,
      surname,
      email: user.email,
      birthDate,
      gender,
      taxCode,
      province: provinces.find((p) => p.code === province).name,
      address,
      city,
      cap,
      eventStart: moment(eventStart),
      eventEnd: moment(eventEnd),
      consent1,
      consent2,
      consent3,
      consent4,
      consent5,
      consent6,
      policy: ConfigCattolica[selected].policy,
    };

    if (selected === "allenamento-protetto") {
      data.coverage = coveragePeriod === 6 ? "semestrale" : "annuale";
      data.eventEnd = moment(eventStart)
        .add(coveragePeriod, "months")
        .endOf("day")
        .toDate();
    } else {
      data.eventName = eventName;
      data.eventCountry = eventCountry;
      data.eventProvince =
        eventCountry === "Italia"
          ? provinces.find((p) => p.code === eventProvince).name
          : eventProvince;
      data.eventCity = eventCity;
    }
    data.Prodotto = ConfigCattolica[selected].title;
    clickHandler();
    dispatch(CattolicaActions.getQuotation(data));
  };

  const autoCompleteEvent = (eventId) => {
    const event = events.find((ev) => ev.id.toString() === eventId);
    setEventName(event.name);
    setEventCity(event.city);
    setEventStart(moment.unix(event.startDate / 1000).toDate());
    setEventEnd(moment.unix(event.endtDate / 1000).toDate());
  };

  const ProvinciaSelector = () => {
    if (provinces) {
      let value = "";
      for (var x of provinces) {
        if (x.code === province) {
          value = x.name;
        }
      }
      return (
        <TextInput
          editable={false}
          style={Style.textInput}
          placeholder={"Provincia"}
          autoCorrect={false}
          onBlur={Keyboard.dismiss}
          value={value}
          onChangeText={setCAP}
        />
      );
    }
  };
  React.useEffect(() => {
    let my = undefined;
    let ProvinciaDAta = provinces.map((province) => ({
      id: province.code,
      value: province.name,
    }));
    for (var x of ProvinciaDAta) {
      if (x.id === province) {
        setMyprovince(x.value);
      }
    }
  }, [provinces]);

  return (
    <View style={Style.wrapper}>
      {isDesktop() && (
        <Image
          source={Images.cattolica_logo}
          style={Style.logo}
          resizeMode={"contain"}
        />
      )}
      <View style={Style.form}>
        <Text style={Style.title}>
          {ConfigCattolica[selected].title}{" "}
          <Text style={Style.subtitle}>
            {ConfigCattolica[selected].subtitle}
          </Text>
        </Text>
        <Text style={Style.description}>
          {ConfigCattolica[selected].description}
        </Text>
        <View style={Style.formTitleContainer}>
          <View style={Style.formTitleBorder} />
          <Text style={Style.formTitle}>FAST QUOTE</Text>
          <View style={Style.formTitleBorder} />
        </View>
        {!!formError.length && <Text style={Style.error}>{formError}</Text>}
        {selected === "allenamento-protetto" && (
          <>
            <View style={Style.switchContainer}>
              <Switch
                onValueChange={(val) =>
                  val ? setCoveragePeriod(6) : setCoveragePeriod(12)
                }
                value={coveragePeriod === 6}
              />
              <View style={Style.switchInner}>
                <Text style={Style.switchTitle}>Assicurazione semestrale</Text>
              </View>
            </View>
            <View style={Style.switchContainer}>
              <Switch
                onValueChange={(val) =>
                  val ? setCoveragePeriod(12) : setCoveragePeriod(6)
                }
                value={coveragePeriod === 12}
              />
              <View style={Style.switchInner}>
                <Text style={Style.switchTitle}>Assicurazione annuale</Text>
              </View>
            </View>
            <DatePicker
              onChange={(date) => {
                setEventStart(
                  moment(date)
                    .startOf("day")
                    .toDate()
                );
                setEventEnd(
                  moment()
                    .add(1, "day")
                    .toDate()
                );
              }}
              value={eventStart}
              placeholder={"Data di inizio copertura"}
              minDate={moment()
                .add(1, "day")
                .toDate()}
            />
            <TextInput
              editable={false}
              style={Style.textInput}
              value={
                eventStart &&
                moment(eventStart)
                  .add(coveragePeriod, "months")
                  .format("DD/MM/YYYY")
              }
              placeholder={"Data di fine copertura"}
              editable={false}
            />
          </>
        )}
        <TextInput
          editable={user.given_name.length > 0 ? false : true}
          style={Style.textInput}
          textContentType={"givenName"}
          autoCorrect={false}
          placeholder={"Nome"}
          onBlur={Keyboard.dismiss}
          value={name}
          onChangeText={setName}
        />
        <TextInput
          editable={user.family_name.length > 0 ? false : ture}
          style={Style.textInput}
          textContentType={"familyName"}
          autoCorrect={false}
          placeholder={"Cognome"}
          onBlur={Keyboard.dismiss}
          value={surname}
          onChangeText={setSurname}
        />
        <DatePicker
          disabled={true}
          onChange={setBirthDate}
          value={birthDate}
          placeholder={"Data di nascita"}
          maxDate={moment()
            .subtract(Config.MIN_AGE, "years")
            .toDate()}
          minDate={moment()
            .subtract(MAX_AGE, "years")
            .toDate()}
        />
        <TextInput
          editable={user.tax_code && user.tax_code.length > 0 ? false : true}
          style={Style.textInput}
          placeholder={"Codice fiscale"}
          autoCorrect={false}
          onBlur={Keyboard.dismiss}
          value={taxCode}
          onChangeText={setTaxCode}
        />
        {gender === "M" ? (
          <TextInput
            editable={false}
            style={Style.textInput}
            placeholder={"Codice fiscale"}
            autoCorrect={false}
            onBlur={Keyboard.dismiss}
            value={"uomo"}
            onChangeText={setTaxCode}
          />
        ) : (
          <TextInput
            editable={false}
            style={Style.textInput}
            placeholder={"Codice fiscale"}
            autoCorrect={false}
            // onBlur={Keyboard.dismiss}
            value={"donna"}
            onChangeText={setTaxCode}
          />
        )}
        {/* <View style={Style.genderButtons}>
          <ToggleButton value={'M'} selected={(gender === 'M')} text={'uomo'} checkHandler={setGender} />
          <ToggleButton value={'F'} selected={(gender === 'F')} text={'donna'} checkHandler={setGender} />
        </View> */}
        <Text style={Style.formSection}>Residenza</Text>
        <TextInput
          editable={user.address && user.address.length > 0 ? false : true}
          style={Style.textInput}
          placeholder={"Indirizzo"}
          autoCorrect={false}
          onBlur={Keyboard.dismiss}
          value={address}
          onChangeText={setAddress}
        />
        <View style={Style.fieldsOneRow}>
          <View style={Style.fieldsOneRowWide}>
            <TextInput
              editable={user.city && user.city.length > 0 ? false : true}
              style={Style.textInput}
              placeholder={"Comune"}
              autoCorrect={false}
              onBlur={Keyboard.dismiss}
              value={city}
              onChangeText={setCity}
            />
          </View>
          <View style={Style.fieldsOneRowSmall}>
            <TextInput
              editable={user.cap && user.cap.length > 0 ? false : true}
              style={Style.textInput}
              placeholder={"CAP"}
              autoCorrect={false}
              onBlur={Keyboard.dismiss}
              value={cap}
              onChangeText={setCAP}
            />
          </View>
        </View>
        {/* {ProvinciaSelector()} */}
        {provinces && province === "" ? (
          <SelectPicker
            placeholder={"Provincia"}
            onChange={setProvince}
            value={province}
            values={provinces.map((province) => ({
              id: province.code,
              value: province.name,
            }))}
          />
        ) : (
          <TextInput
            style={Style.textInput}
            placeholder={""}
            autoCorrect={false}
            disabled={true}
            onBlur={Keyboard.dismiss}
            value={myprovince.toUpperCase()}
          />
        )}
        {selected !== "allenamento-protetto" && (
          <>
            <Text style={Style.formSection}>Evento</Text>
            {selected === "meteo" ? (
              <>
                {eventsCountries && (
                  <SelectPicker
                    placeholder={"Nazione evento"}
                    onChange={(val) => {
                      setEventCountry(val);
                      setEventProvince("");
                    }}
                    value={eventCountry}
                    values={eventsCountries}
                  />
                )}
                {eventCountry === "Italia" || eventCountry === "" ? (
                  <>
                    {provinces && (
                      <SelectPicker
                        placeholder={"Provincia evento"}
                        onChange={setEventProvince}
                        value={eventProvince}
                        values={provinces.map((province) => ({
                          id: province.code,
                          value: province.name,
                        }))}
                      />
                    )}
                    <TextInput
                      style={Style.textInput}
                      placeholder={"Comune evento"}
                      autoCorrect={false}
                      onBlur={Keyboard.dismiss}
                      value={eventCity}
                      onChangeText={setEventCity}
                    />
                    <TextInput
                      style={Style.textInput}
                      autoCorrect={false}
                      placeholder={"Nome evento"}
                      onBlur={Keyboard.dismiss}
                      value={eventName}
                      onChangeText={setEventName}
                    />
                    <DatePicker
                      onChange={(date) => {
                        setEventStart(
                          moment(date)
                            .startOf("day")
                            .toDate()
                        );
                        if (moment(date).isAfter(moment(eventEnd)))
                          setEventEnd(
                            moment(date)
                              .endOf("day")
                              .toDate()
                          );
                      }}
                      value={eventStart}
                      placeholder={"Data di inizio"}
                      minDate={moment()
                        .add(DELTA_DAYS, "days")
                        .toDate()}
                    />
                    <DatePicker
                      onChange={(date) =>
                        setEventEnd(
                          moment(date)
                            .endOf("day")
                            .toDate()
                        )
                      }
                      value={eventEnd}
                      placeholder={"Data di fine"}
                      minDate={
                        eventStart
                          ? moment
                              .max(
                                moment().add(DELTA_DAYS, "days"),
                                moment(eventStart)
                              )
                              .toDate()
                          : moment()
                              .add(DELTA_DAYS, "days")
                              .toDate()
                      }
                    />
                  </>
                ) : (
                  <>
                    {events && (
                      <SelectPicker
                        placeholder={"Evento"}
                        onChange={autoCompleteEvent}
                        value={eventName}
                        values={
                          events &&
                          events
                            .filter((e) => e.country === eventCountry)
                            .map(({ id, name }) => ({ id, value: name }))
                        }
                      />
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <TextInput
                  style={Style.textInput}
                  autoCorrect={false}
                  placeholder={"Nome evento"}
                  onBlur={Keyboard.dismiss}
                  value={eventName}
                  onChangeText={setEventName}
                />
                {selected !== "gara-sicura" ? (
                  <>
                    <DatePicker
                      onChange={(date) => {
                        setEventStart(
                          moment(date)
                            .startOf("day")
                            .toDate()
                        );
                        if (moment(date).isAfter(moment(eventEnd)))
                          setEventEnd(
                            moment(date)
                              .endOf("day")
                              .toDate()
                          );
                      }}
                      value={eventStart}
                      placeholder={"Data di inizio"}
                      minDate={moment()
                        .add(DELTA_DAYS, "days")
                        .toDate()}
                    />
                    {eventStart && (
                      <DatePicker
                        onChange={(date) =>
                          setEventEnd(
                            moment(date)
                              .endOf("day")
                              .toDate()
                          )
                        }
                        value={eventEnd}
                        placeholder={"Data di fine"}
                        minDate={
                          eventStart
                            ? moment
                                .max(
                                  moment().add(DELTA_DAYS, "days"),
                                  moment(eventStart)
                                )
                                .toDate()
                            : moment()
                                .add(DELTA_DAYS, "days")
                                .toDate()
                        }
                      />
                    )}
                  </>
                ) : (
                  <>
                    <DatePicker
                      onChange={(date) => {
                        setEventStart(
                          moment(date)
                            .startOf("day")
                            .toDate()
                        );
                        setEventEnd(
                          moment(date)
                            .endOf("day")
                            .toDate()
                        );
                      }}
                      value={eventStart}
                      placeholder={"Data evento"}
                      minDate={moment()
                        .add(1, "day")
                        .toDate()}
                    />
                  </>
                )}
                {countries && (
                  <SelectPicker
                    placeholder={"Nazione evento"}
                    onChange={(val) => {
                      setEventCountry(val);
                      setEventProvince("");
                    }}
                    value={eventCountry}
                    values={
                      countries &&
                      countries.map((country) => ({
                        id: country.code,
                        value: country.name,
                      }))
                    }
                  />
                )}
                {eventCountry === "IT" ? (
                  provinces && (
                    <SelectPicker
                      placeholder={"Provincia evento"}
                      onChange={setEventProvince}
                      value={eventProvince}
                      values={provinces.map((province) => ({
                        id: province.code,
                        value: province.name,
                      }))}
                    />
                  )
                ) : (
                  <TextInput
                    style={Style.textInput}
                    placeholder={"Provincia evento"}
                    autoCorrect={false}
                    onBlur={Keyboard.dismiss}
                    value={eventProvince}
                    onChangeText={setEventProvince}
                  />
                )}
                <TextInput
                  style={Style.textInput}
                  placeholder={"Comune evento"}
                  autoCorrect={false}
                  onBlur={Keyboard.dismiss}
                  value={eventCity}
                  onChangeText={setEventCity}
                />
              </>
            )}
          </>
        )}
        <View style={Style.consent}>
          <Text style={Style.consentText}>
            Al trattamento dei dati personali anche relativi allo stato di
            salute per le finalità relative all&apos;attività
            assicurativa/liquidativa, ed anche alla comunicazione agli altri
            soggetti sopra indicati nell&apos;informativa, anche appartenenti
            alla catena assicurativa, al trattamento, nonché al trasferimento
            all&apos;estero fuori dal territorio nazionale, anche extra UE, da
            parte dei Contitolari e di tali altri soggetti degli stessi dati
            (anche sanitari) sempre per le medesime finalità, per i casi nei
            quali non può essere effettuato senza il consenso
          </Text>
          <View style={Style.switches}>
            <View style={Style.switchContainer}>
              <Switch
                value={consent1}
                onValueChange={(val) => (val ? setConsent1(true) : null)}
              />
              <Button
                onPress={() => setConsent1(true)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Consento</Text>
              </Button>
            </View>
            <View style={Style.switchContainer}>
              <Switch
                value={!consent1}
                onValueChange={(val) => (val ? setConsent1(false) : null)}
              />
              <Button
                onPress={() => setConsent1(false)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Non Consento</Text>
              </Button>
            </View>
          </View>
        </View>
        <View style={Style.consent}>
          <Text style={Style.consentText}>
            Al trattamento dei dati personali per finalità di profilazione
            effettuata dai Contitolari, sia con l&apos;intervento umano sia in
            modalità automatizzata, nonché per definire il profilo
            dell&apos;interessato per formulare proposte adeguate rispetto alle
            sue necessità e caratteristiche, effettuate con modalità
            tradizionali e automatizzate di contatto (18); il consenso è
            facoltativo
          </Text>
          <View style={Style.switches}>
            <View style={Style.switchContainer}>
              <Switch
                value={consent2}
                onValueChange={(val) => (val ? setConsent2(true) : null)}
              />
              <Button
                onPress={() => setConsent2(true)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Consento</Text>
              </Button>
            </View>
            <View style={Style.switchContainer}>
              <Switch
                value={!consent2}
                onValueChange={(val) => (val ? setConsent2(false) : null)}
              />
              <Button
                onPress={() => setConsent2(false)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Non Consento</Text>
              </Button>
            </View>
          </View>
        </View>
        <View style={Style.consent}>
          <Text style={Style.consentText}>
            Al trattamento per finalità di invio, effettuato dai Contitolari,
            con modalità tradizionali e automatizzate di contatto (19), di
            comunicazioni commerciali e di materiale pubblicitario di soggetti
            appartenenti a determinate categorie merceologiche(20), per finalità
            di marketing di questi; il consenso è facoltativo
          </Text>
          <View style={Style.switches}>
            <View style={Style.switchContainer}>
              <Switch
                value={consent3}
                onValueChange={(val) => (val ? setConsent3(true) : null)}
              />
              <Button
                onPress={() => setConsent3(true)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Consento</Text>
              </Button>
            </View>
            <View style={Style.switchContainer}>
              <Switch
                value={!consent3}
                onValueChange={(val) => (val ? setConsent3(false) : null)}
              />
              <Button
                onPress={() => setConsent3(false)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Non Consento</Text>
              </Button>
            </View>
          </View>
        </View>
        <View style={Style.consent}>
          <Text style={Style.consentText}>
            Alla comunicazione dei dati personali a soggetti appartenenti a
            determinate categorie merceologiche (21), per finalità di marketing
            di questi, attraverso modalità tradizionali e automatizzate di
            contatto (22); il consenso è facoltativo
          </Text>
          <View style={Style.switches}>
            <View style={Style.switchContainer}>
              <Switch
                value={consent4}
                onValueChange={(val) => (val ? setConsent4(true) : null)}
              />
              <Button
                onPress={() => setConsent4(true)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Consento</Text>
              </Button>
            </View>
            <View style={Style.switchContainer}>
              <Switch
                value={!consent4}
                onValueChange={(val) => (val ? setConsent4(false) : null)}
              />
              <Button
                onPress={() => setConsent4(false)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Non Consento</Text>
              </Button>
            </View>
          </View>
        </View>
        <View style={Style.consent}>
          <Text style={Style.consentText}>
            Dichiaro di aver esercitato il diritto di opposizione al trattamento
            per finalità di marketing dei Contitolari
          </Text>
          <View style={Style.switches}>
            <View style={Style.switchContainer}>
              <Switch
                value={consent5}
                onValueChange={(val) => (val ? setConsent5(true) : null)}
              />
              <Button
                onPress={() => setConsent5(true)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Sì</Text>
              </Button>
            </View>
            <View style={Style.switchContainer}>
              <Switch
                value={!consent5}
                onValueChange={(val) => (val ? setConsent5(false) : null)}
              />
              <Button
                onPress={() => setConsent5(false)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>No</Text>
              </Button>
            </View>
          </View>
        </View>
        <View style={Style.consent}>
          <Text style={Style.consentText}>
            Dichiaro di aver esercitato il diritto di opposizione al trattamento
            per finalità di marketing del Gruppo Imprenditoriale Cattolica
          </Text>
          <View style={Style.switches}>
            <View style={Style.switchContainer}>
              <Switch
                value={consent6}
                onValueChange={(val) => (val ? setConsent6(true) : null)}
              />
              <Button
                onPress={() => setConsent6(true)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>Sì</Text>
              </Button>
            </View>
            <View style={Style.switchContainer}>
              <Switch
                value={!consent6}
                onValueChange={(val) => (val ? setConsent6(false) : null)}
              />
              <Button
                onPress={() => setConsent6(false)}
                style={Style.switchInner}
              >
                <Text style={Style.switchText}>No</Text>
              </Button>
            </View>
          </View>
        </View>
        {/* <PrimaryButton onPress={handleSubmitData} style={Style.button}>RICHIEDI VALUTAZIONE</PrimaryButton> */}
        <PrimaryButton onPress={handleSubmitData} style={Style.button}>
          AVANTI
        </PrimaryButton>
      </View>
    </View>
  );
};

Form.propTypes = {
  selected: PropTypes.string,
  scrollTop: PropTypes.func,
};

export default Form;
