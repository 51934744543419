import React, { useState } from "react";
import {
  View,
  Text,
  Image,
  Modal,
  TextInput,
  TouchableOpacity,
  Platform,
  StyleSheet,
} from "react-native";
import PropTypes from "prop-types";
import moment from "moment";
import { ConfigCattolica } from "../../../../Config/cattolica";
import CattolicaActions from "../../../../Stores/Cattolica/Actions";
import { useDispatch, useSelector } from "react-redux";

import { getResponsive } from "./QuotationStyle";
import { PrimaryButton } from "../../../Buttons";
import { Images } from "../../../../Theme";

import { isDesktop } from "../../../../Services/ResponsiveHelper";
import { LeftArrow } from "../../../../Theme/Icons";
import { Auth } from "aws-amplify";

const Intro = ({ clickHandler, back }) => {
  const Style = getResponsive();
  const dispatch = useDispatch();
  const quotation = useSelector((state) => state.cattolica.quotation);
  const [quota, setQuota] = useState({});
  const [error, setError] = useState(false);
  const [comuneNew, setComuneNew] = useState("");
  const [provinciaNew, setProvinciaNew] = useState("");
  const [comuneNewEv, setComuneNewEv] = useState("");
  const [provinciaNewEv, setProvinciaNewEv] = useState("");

  const mappingData = {
    policy: {
      title: "ASSICURAZIONE",
      parser: (val) =>
        ConfigCattolica[
          Object.keys(ConfigCattolica).find(
            (el) => ConfigCattolica[el].policy === val
          )
        ].title,
    },
    surname: "COGNOME",
    name: "NOME",
    email: "E-MAIL",
    birthDate: {
      title: "DATA DI NASCITA",
      parser: (val) => moment(val).format("DD/MM/YYYY"),
    },
    gender: "SESSO",
    taxCode: "CODICE FISCALE",
    province: "RESIDENZA - PROVINCIA",
    address: "RESIDENZA - INDIRIZZO",
    city: "RESIDENZA - COMUNE",
    cap: "RESIDENZA - CAP",
  };

  if (quotation?.data?.policy === 2) {
    mappingData.coverage = {
      title: "COPERTURA POLIZZA",
      parser: (val) => `${val[0].toUpperCase()}${val.substring(1)}`,
    };
    mappingData.eventStart = {
      title: "DATA DI INIZIO COPERTURA",
      parser: (val) => moment(val).format("DD/MM/YYYY"),
    };
    mappingData.eventEnd = {
      title: "DATA DI FINE COPERTURA",
      parser: (val) => moment(val).format("DD/MM/YYYY"),
    };
  } else {
    mappingData.eventName = "EVENTO";
    if (quotation?.data?.policy !== 1) {
      mappingData.eventStart = {
        title: "DATA DI INIZIO EVENTO",
        parser: (val) => moment(val).format("DD/MM/YYYY"),
      };
      mappingData.eventEnd = {
        title: "DATA DI FINE EVENTO",
        parser: (val) => moment(val).format("DD/MM/YYYY"),
      };
    } else {
      mappingData.eventStart = {
        title: "DATA EVENTO",
        parser: (val) => moment(val).format("DD/MM/YYYY"),
      };
    }
    mappingData.eventCountry = "LOCALITÀ EVENTO - NAZIONE";
    mappingData.eventProvince = "LOCALITÀ EVENTO - PROVINCIA";
    mappingData.eventCity = "LOCALITÀ EVENTO - COMUNE";
  }

  React.useEffect(() => {
    LoadQuotation(quotation);
  }, []);

  const LoadNewQuotation = () => {
    let newQuotes = quotation;
    newQuotes.data.province = provinciaNew;
    newQuotes.data.city = comuneNew;
    newQuotes.data.eventCity = comuneNewEv;
    newQuotes.data.eventProvince = provinciaNewEv;
    LoadQuotation(newQuotes);
  };

  const LoadQuotation = async (quotations) => {
    let policy = 0;
    switch (quotations?.data?.Prodotto) {
      case "ALLENAMENTO PROTETTO":
        policy = 2;
        break;
      case "GARA SICURA":
        policy = 1;
        break;
      case "VIAGGIO SERENO":
        policy = 3;
        break;
      default:
        break;
    }
    let Token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + Token);
    myHeaders.append("Content-Type", "text/plain");
    var raw = {
      name: quotations?.data?.name,
      surname: quotations?.data?.surname,
      email: quotations?.data?.email,
      birthDate: quotations?.data?.birthDate,
      gender: quotations?.data?.gender,
      taxCode: quotations?.data?.taxCode,
      province: quotations?.data?.province,
      address: quotations?.data?.address,
      city: quotations?.data?.city,
      cap: quotations?.data?.cap,
      eventStart: quotations?.data?.eventStart,
      eventEnd: quotations?.data?.eventEnd,
      consent1: quotations?.data?.consent1,
      consent2: quotations?.data?.consent2,
      consent3: quotations?.data?.consent3,
      consent4: quotations?.data?.consent4,
      consent5: quotations?.data?.consent5,
      consent6: quotations?.data?.consent6,
      policy: policy,
      coverage: quotations?.data?.coverage,
      Prodotto: quotations?.data?.Prodotto,
      layaway: quotations?.data?.coverage,
      eventName: quotations?.data?.eventName,
      eventCity: quotation?.data?.eventCity,
      eventProvince: quotation?.data?.eventProvince,
      eventCountry: quotation?.data?.eventCountry,
    };

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    fetch(
      "https://wzh0bb4hi2.execute-api.eu-west-1.amazonaws.com/preprod/cattolica/quotation/",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        let resposnse = JSON.parse(result);
        if (
          resposnse.message &&
          resposnse.message[0] == "city or district invalid"
        ) {
          setError(true);
        } else {
          setError(false);
          setQuota(JSON.parse(result));
        }
      })
      .catch((error) => console.log("error", error));
  };

  const confirm = () => {
    let Output = quota;
    let data = quotation.data;
    if (quotation?.data?.Prodotto === "VIAGGIO SERENO") {
      Output.quotation.price = Number(Output.quotation.price).toFixed(2);
    }
    dispatch(CattolicaActions.setQuotation(null));
    dispatch(CattolicaActions.setQuotation({ ...Output, data }));
    clickHandler();
  };

  return (
    <View style={Style.wrapper}>
      {isDesktop() && (
        <Image
          source={Images.cattolica_logo}
          style={Style.logo}
          resizeMode={"contain"}
        />
      )}
      <>
        {Platform.OS !== "web" ? (
          <View style={Style.inner}>
            <Text style={Style.title}>PREVENTIVO</Text>
            <Text style={Style.price}>
              &euro;{" "}
              {quota?.quotation?.price?.toFixed(2).replace(".", ",") || "0,00"}
            </Text>
            {Object.keys(mappingData).map((field) => (
              <View key={field} style={Style.recapRow}>
                <Text style={Style.recapTitle}>
                  {typeof mappingData[field] === "string"
                    ? mappingData[field]
                    : mappingData[field].title}
                </Text>
                <Text style={Style.recapText}>
                  {typeof mappingData[field] === "string"
                    ? quotation?.data[field]
                    : mappingData[field].parser(quotation?.data[field])}
                </Text>
              </View>
            ))}
            <PrimaryButton
              onPress={() => {
                confirm();
              }}
            >
              ACQUISTA ORA!
            </PrimaryButton>
          </View>
        ) : !error && Platform.OS === "web" ? (
          <View style={Style.inner}>
            <Text style={Style.title}>PREVENTIVO</Text>
            <Text style={Style.price}>
              &euro;{" "}
              {quota?.quotation?.price?.toFixed(2).replace(".", ",") || "0,00"}
            </Text>
            {Object.keys(mappingData).map((field) => (
              <View key={field} style={Style.recapRow}>
                <Text style={Style.recapTitle}>
                  {typeof mappingData[field] === "string"
                    ? mappingData[field]
                    : mappingData[field].title}
                </Text>
                <Text style={Style.recapText}>
                  {typeof mappingData[field] === "string"
                    ? quotation?.data[field]
                    : mappingData[field].parser(quotation?.data[field])}
                </Text>
              </View>
            ))}
            <PrimaryButton
              onPress={() => {
                confirm();
              }}
            >
              ACQUISTA ORA!
            </PrimaryButton>
          </View>
        ) : (
          <View
            style={{
              flex: 1,
              backgroundColor: "#fff",
              padding: 30,
              justifyContent: "center",
            }}
          >
            <View>
              <View
                style={{
                  flex: 1,
                  padding: 15,
                }}
              >
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Sembra che i dati anagrafici o di indirizzo non siano corretti
                </Text>
                <TextInput
                  style={ConstStyle.inPutText}
                  value={comuneNew}
                  placeholder={"COMUNE RESIDENZA"}
                  onChangeText={setComuneNew}
                />
                <TextInput
                  style={ConstStyle.inPutText}
                  value={provinciaNew}
                  placeholder={"PROVINCIA RESIDENZA"}
                  onChangeText={setProvinciaNew}
                />
                {quotation?.data?.Prodotto !== "ALLENAMENTO PROTETTO" && (
                  <>
                    <TextInput
                      style={ConstStyle.inPutText}
                      value={comuneNewEv}
                      placeholder={"Comune evento"}
                      onChangeText={setComuneNewEv}
                    />
                    <TextInput
                      style={ConstStyle.inPutText}
                      value={provinciaNewEv}
                      placeholder={"Provincia evento"}
                      onChangeText={setProvinciaNewEv}
                    />
                  </>
                )}
              </View>
              <View
                style={{
                  flex: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 15,
                }}
              >
                <PrimaryButton
                  style={{
                    maxWidth: 300,
                  }}
                  onPress={() => {
                    LoadNewQuotation();
                  }}
                >
                  Riprova
                </PrimaryButton>
              </View>
            </View>
          </View>
        )}
      </>
      {Platform.OS !== "web" && error && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={error}
          onRequestClose={() => {
            back();
          }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: "rgba(0,42,85,0.5)",
              padding: 30,
              justifyContent: "center",
            }}
          >
            <View
              style={{
                backgroundColor: "#fff",
                borderRadius: 30,
              }}
            >
              <View
                style={{
                  flex: 0,
                  padding: 15,
                }}
              >
                <TouchableOpacity
                  style={{
                    paddingVertical: 15,
                  }}
                  onPress={back}
                >
                  <LeftArrow />
                </TouchableOpacity>
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Sembra che i dati anagrafici o di indirizzo non siano corretti
                </Text>
                <TextInput
                  style={ConstStyle.inPutText}
                  value={comuneNew}
                  placeholder={"COMUNE RESIDENZA"}
                  onChangeText={setComuneNew}
                />
                <TextInput
                  style={ConstStyle.inPutText}
                  value={provinciaNew}
                  placeholder={"PROVINCIA RESIDENZA"}
                  onChangeText={setProvinciaNew}
                />
                {quotation?.data?.Prodotto !== "ALLENAMENTO PROTETTO" && (
                  <>
                    <TextInput
                      style={ConstStyle.inPutText}
                      value={comuneNewEv}
                      placeholder={"Comune evento"}
                      onChangeText={setComuneNewEv}
                    />
                    <TextInput
                      style={ConstStyle.inPutText}
                      value={provinciaNewEv}
                      placeholder={"Provincia evento"}
                      onChangeText={setProvinciaNewEv}
                    />
                  </>
                )}
              </View>
              <View
                style={{
                  flex: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 15,
                }}
              >
                <TouchableOpacity
                  style={{
                    borderRadius: 100,
                    paddingHorizontal: 45,
                    padding: 15,
                    backgroundColor: "blue",
                    marginBottom: 15,
                  }}
                  onPress={() => {
                    LoadNewQuotation();
                  }}
                >
                  <Text
                    style={{
                      color: "#fff",
                    }}
                  >
                    Riprova
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
};

Intro.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
};

export default Intro;

const ConstStyle = StyleSheet.create({
  inPutText: {
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 5,
  },
});
