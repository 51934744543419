import { StyleSheet } from 'react-native';
import { Colors, ApplicationStyles } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  button: {
    marginTop: 20,
    marginBottom: 20,
  },
  wrapper: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 40,
    paddingBottom: 10,
    paddingTop: 10,
  },
  gradient: {
    width: '100%',
    height: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 100,
    justifyContent: 'space-around',
    flex: 1,
  },
  h2: {
    ...ApplicationStyles.screen.h2,
    marginBottom: 10,
    marginTop: 20,
    textAlign: 'center',
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
  },
  helpButton: {
    marginBottom: 20,
  },
};

const StyleMedium = {
  wrapper: {
    ...BaseStyle.wrapper,
    minWidth: 300,
    maxWidth: 500,
    maxHeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  gradient: {
    ...BaseStyle.gradient,
    paddingBottom: '10%',
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });
export default StyleSheet.create(BaseStyle);
