import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { pure } from 'recompose';
import { generatePath, useHistory } from 'react-router-native';
import { Text, View } from 'react-native';
import moment from 'moment';

import { getResponsive } from './ListStyle';
import { Button } from '../../Buttons';
import { SaveRaceIcon } from '../../../Theme/Icons';

import { getPath } from '../../../Router/AppRoutes';
import RacesActions from '../../../Stores/Races/Actions';

const Race = ({ race }) => {
  const dispatch = useDispatch();

  const { logged, user } = useSelector(state => state.auth);

  const Style = getResponsive();
  let history = useHistory();

  const openRace = () => {
    dispatch(RacesActions.setOpenRace(race));
    history.push(generatePath(getPath('raceItem'), { id: race.id }));
  };

  return (
    <Button onPress={openRace} style={Style.item}>
      <View style={Style.itemData}>
        <Text style={Style.itemDataDay}>{moment(race.date).format('ddd').substring(0,1).toUpperCase()}{moment(race.date).format('ddd').substring(1)}</Text>
        <Text style={Style.itemDataDayNumber}>{moment(race.date).format('D')}</Text>
        <Text style={Style.itemDataMonth}>{moment(race.date).format('MMM').substring(0,1).toUpperCase()}{moment(race.date).format('MMM').substring(1)}</Text>
        <Text style={Style.itemDataYear}>{moment(race.date).format('YYYY')}</Text>
      </View>
      <View style={Style.itemContent}>
        <View>
          <Text style={Style.itemTitle}>{race.title}</Text>
          <Text style={Style.itemWhere}>{race.city} ({race.province})</Text>
        </View>
        <View style={Style.itemFooter}>
          <View>
            <Text style={Style.itemDetails}>{race.id.substr(0, 3) === 'REG' ? 'Regionale' : 'Nazionale'}</Text>
            <Text style={Style.itemDetails}>{race.gender}</Text>
          </View>
          {logged && user.runcard && user.runcard !== '' && (
            <Button style={Style.button} onPress={() => dispatch(!!race.saved ? RacesActions.unSaveRace(true, race) : RacesActions.saveRace(true, race))}>
              <SaveRaceIcon size={25} fill={!!race.saved} />
            </Button>
          )}
        </View>
      </View>
    </Button>
  );
};

Race.propTypes = {
  race: PropTypes.object.isRequired,
};

export default pure(Race);
