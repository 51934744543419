import React from 'react';
import PropTypes from 'prop-types';
import Svg, {
  G,
  Rect,
} from 'react-native-svg';

const  HamburgerIcon = ({ size, color }) => (
  <Svg width={size} height={size} viewBox={`0 0 38 23`}>
    <G fill={color}>
      <Rect x="9" y="20" width="29" height="3" />
      <Rect x="0" y="10" width="38" height="3" />
      <Rect x="15" y="0" width="23" height="3" />
    </G>
  </Svg>
);

HamburgerIcon.propTypes = {
  size: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};

export default HamburgerIcon;
