import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import Svg, { G, Circle, Defs, Path, Polygon, LinearGradient, Stop } from 'react-native-svg';

import { Colors } from '../../Theme';

const PopupErrorIcon = ({ size=112 }) => {

  const Style = StyleSheet.create({
    iconWrapper: {
      borderRadius: size,
      marginLeft: 'auto',
      marginRight: 'auto',
      shadowColor: Colors.black,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.15,
      shadowRadius: 2,
      width: size,
    }
  });

  return (
    <View style={Style.iconWrapper}>
      <Svg width={size} height={size} viewBox={'0 0 112 106'}>
        <Defs>
          <Circle cx="52" cy="52" r="52" />
          <LinearGradient id={'PopupErrorIcon-gradient1'} x1="50%" y1="44.6910207%" x2="50%" y2="100%">
            <Stop stopColor="#FF0643" offset="0%" />
            <Stop stopColor="#009EFD" offset="100%" />
          </LinearGradient>
          <LinearGradient id={'PopupErrorIcon-gradient2'} x1="50%" y1="20.0016738%" x2="50%" y2="133.349185%">
            <Stop stopColor="#FF0643" offset="0%" />
            <Stop stopColor="#009EFD" offset="100%" />
          </LinearGradient>
        </Defs>
        <G transform="translate(-100.000000, -24.000000)">
          <G transform="translate(23.000000, 26.000000)">
            <G transform="translate(81.000000, 0.000000)">
              <G fill="#FFFFFF" fillRule="evenodd">
                <Path d="M52,7 C27.25,7 7,27.25 7,52 C7,76.75 27.25,97 52,97 C76.75,97 97,76.75 97,52 C97,27.25 76.75,7 52,7 Z" stroke="url(#PopupErrorIcon-gradient1)" strokeWidth="3" fillRule="nonzero" />
              </G>
              <G transform="translate(29.000000, 29.000000)" fill="url(#PopupErrorIcon-gradient2)" fillRule="nonzero">
                <Polygon points="46 3.34329688 43.4567031 0.8 23 21.2567031 2.54329687 0.8 0 3.34329688 20.4567031 23.8 0 44.2567031 2.54329687 46.8 23 26.3432969 43.4567031 46.8 46 44.2567031 25.5432969 23.8" />
              </G>
            </G>
          </G>
        </G>
      </Svg>
    </View>
  );
};

PopupErrorIcon.propTypes = {
  size: PropTypes.number,
};

export default PopupErrorIcon;
