import React from 'react';
import { Linking, Platform, StatusBar } from 'react-native';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import Amplify from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers';
import InAppBrowser from 'react-native-inappbrowser-reborn';
import { requestTrackingPermission, getTrackingStatus } from 'react-native-tracking-transparency';
import { Config } from './Config';

import createStore from './Stores';
import AmplifyAuthStorage from './Services/AsyncStorageService';

import Splash from './Containers/Splash/Splash';
import RootScreen from './Containers/Root/RootScreen';
import { Colors } from './Theme';
import Analytics from './Services/AnalyticsService'
const { store, persistor } = createStore();

const App = () => {
  if (Platform.OS !== 'web') {

    const [trackingStatus, setTrackingStatus] = React.useState();
    React.useEffect(() => {
      getTrackingStatus()
        .then((status) => {
          setTrackingStatus(status);
        })
        .catch((e) => Alert.alert('Error', e?.toString?.() ?? e));
    }, []);
    const request = React.useCallback(async () => {
      try {
        const status = await requestTrackingPermission();
        setTrackingStatus(status);
      } catch (e) {
        Alert.alert('Error', e?.toString?.() ?? e);
      }
    }, []);
    if(trackingStatus === 'not-determined') {
      try {
        request()
      }
      catch {
        console.log('test');
      }
    } 
    const urlOpener = async (url) => {
      try {
        if (await InAppBrowser.isAvailable()) {
          return InAppBrowser.open(url);
        } else {
          return Linking.openURL(url);
        }
      } catch (error) {
        return Linking.openURL(url);
      }
    };
    Config.AWS.Auth.oauth.urlOpener = Platform.OS === 'ios' ? urlOpener : null;

    Amplify.configure({ ...Config.AWS, storage: AmplifyAuthStorage });
    Analytics.getInstance();
    // TODO: Workaround: this is to avoid that Amplify OAuth try to parse EVERY url as a OAuth callback url
    // https://stackoverflow.com/questions/59883011/how-to-avoid-that-aws-amplify-oauth-tries-to-parse-every-oauth-process
    // console.log(Linking._subscriber.getSubscriptionsForType('url'));
    Linking.removeAllListeners('url');
    Linking.addEventListener('url', (url) => {
      if (url.url.indexOf(Config.AWS.Auth.oauth.redirectSignIn) !== -1 || url.url.indexOf(Config.AWS.Auth.oauth.redirectSignOut) !== -1) {
        Amplify.Auth._handleAuthResponse(url.url);
      }
    });
  } else {
    Amplify.configure(Config.AWS);
  }

  // AWS IoT PubSub subscription
  Amplify.addPluggable(new AWSIoTProvider(Config.AWS_IOT));

  // Enable AWS Amplify debug
  if (process && process.env && process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    //window.LOG_LEVEL = 'DEBUG';
  }

  React.useEffect(() => {
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(Colors.darkBlue);
    }
    StatusBar.setBarStyle('light-content', true);
  }, []);

  return (
    /**
     * @see https://github.com/reduxjs/react-redux/blob/master/docs/api/Provider.md
     */
    <Provider store={store}>
      {/**
       * PersistGate delays the rendering of the app's UI until the persisted state has been retrieved
       * and saved to redux.
       * The `loading` prop can be `null` or any react instance to show during loading (e.g. a splash screen),
       * for example `loading={<SplashScreen />}`.
       * @see https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
       */}
      <PersistGate loading={<Splash />} persistor={persistor}>
        <RootScreen uriPrefix={'runcard://'} />
      </PersistGate>
    </Provider>
  )
};

export default App;
