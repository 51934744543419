import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';
import { Platform } from 'react-native';

const BaseStyle = {
  calendar: {
    marginHorizontal: 10,
  },
  calendarFooter: {
    marginHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    flex: 1,
    flexGrow: 1,
    paddingTop: 20,
  },
  endReachedText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.small,
    paddingTop: 5,
    paddingBottom: 20,
  },
  errorText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.small,
    paddingBottom: 20,
  },
  item: {
    backgroundColor: Colors.white,
    elevation: 2,
    marginBottom: 10,
    flexDirection: 'row',
    paddingVertical: 20,
    paddingHorizontal: 30,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  itemContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  itemData: {
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  itemDataDay: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+1,
    color: Colors.blue1,
  },
  itemDataDayNumber: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.blue1,
    fontSize: Fonts.size.regular+23,
    marginBottom: -9,
    marginTop: -5,
  },
  itemDataMonth: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.regular+3,
  },
  itemDataYear: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    color: Colors.blue1,
  },
  itemPosition: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular-2,
  },
  itemRace: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    marginTop: 2,
    marginLeft: 10,
  },
  itemRaceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemTime: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-2,
    marginTop: 2,
  },
  itemTitle: {
    ...ApplicationStyles.screen.fonts,
  },
  legenda: {
    paddingVertical: 20,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  legendaText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular - 2,
    marginLeft: 5,
  },
  listFooter: {
    ...ApplicationStyles.screen.paddingTabBar,
    ...Platform.select({
      web: {
        paddingBottom: 20,
      }
    }),
    alignItems: 'center',
  },
  loader: {
    marginBottom: 20,
    flex: 1,
    justifyContent: 'center',
  },
  month: {
    paddingVertical: 20,
  },
  monthText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular - 2,
    color: Colors.blue1,
  },
};

const StyleLG = {
  item: {
    marginHorizontal: 30,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
