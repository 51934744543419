import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { View, Text } from "react-native";
import PropTypes from "prop-types";
import { Config } from "../../../Config";
import { generatePath } from "react-router-native";

import { getResponsive } from "./NewsStyle";
import { Footer, LoaderInline, PageHeader } from "../../Layout";
import { Share } from "../../Buttons";
import NewsContent from "./NewsContent";

import NewsActions from "../../../Stores/News/Actions";
import LayoutActions from "../../../Stores/Layout/Actions";
import HelperActions from "../../../Stores/Helper/Actions";
import { isDesktop } from "../../../Services/ResponsiveHelper";
import { getPath } from "../../../Router/AppRoutes";

const News = ({ match }) => {
  const Style = getResponsive();
  const dispatch = useDispatch();

  const { news: newsList, singleNews, singleNewsError } = useSelector(
    (state) => state.news
  );

  const [news, setNews] = React.useState(null);

  let newsId = match.params.id;

  React.useEffect(() => {
    newsId = match.params.id;
    if (newsId && (!singleNews || singleNews.id !== newsId)) {
      if (newsList) {
        let news =
          Object.keys(newsList)
            .reduce((acc, key) => {
              return [...acc, ...newsList[key]];
            }, [])
            .filter((el) => el.id === newsId)
            .shift() || false;
        setNews(news);
      } else {
        dispatch(NewsActions.getSingleNews(match.params.id));
      }
    } else if (newsId && singleNews && singleNews.id === newsId) {
      setNews(singleNews);
    }
  }, [match, singleNews]);

  React.useEffect(() => {
    if (singleNewsError) {
      setNews(false);
      dispatch(NewsActions.setSingleNewsError(null));
    }
  }, [singleNewsError]);

  React.useEffect(() => {
    dispatch(LayoutActions.setTab("NEWS"));
  }, []);

  return news ? (
    <View style={Style.wrapper}>
      {!isDesktop() ? (
        <PageHeader
          title={Config.NEWS.CATEGORIES[news.category]}
          right={
            <Share
              url={
                news.link.indexOf("cloudfront.net/runcard") !== -1
                  ? `${Config.WEBSITE_URL}${generatePath(getPath("newsItem"), {
                      id: news.id,
                    })}`
                  : news.link
              }
              callback={() => {
                dispatch(HelperActions.sendLeaderboardEvent("share-news"));
              }}
            />
          }
        />
      ) : (
        <PageHeader back={false} adv={true} section={"news"} />
      )}
      <View style={Style.innerContainer}>
        <View style={Style.webViewContent}>
          <NewsContent news={news} />
        </View>
      </View>
      <Footer adv={true} section={"news"} />
    </View>
  ) : news === false ? (
    <>
      <PageHeader title={"News"} desc={"Scopri le ultime novità"} />
      <View style={Style.innerContainer}>
        <Text style={Style.noNewsText}>News non trovata</Text>
      </View>
      <Footer />
    </>
  ) : (
    <LoaderInline />
  );
};

News.propTypes = {
  match: PropTypes.object.isRequired,
};

export default News;
