import React from 'react';
import PropTypes from 'prop-types'
import { View, Text, Image } from 'react-native';

import { getResponsive } from './AdvantagesStyle';
import { PrimaryButton } from "../Buttons";

const Advantages = ({ title = 'VANTAGGI' }) => {
  const Style = getResponsive();

  return (
    <>
      <View style={Style.titleContainer}>
        <img src={'/static/images/vantaggio.png'} style={Style.titleImage} alt={''} />
        <Text style={Style.title}>{title}</Text>
      </View>
      <View style={Style.columns}>
        <View style={Style.column}>
          <View style={Style.imageContainer}>
            <Image source={'/static/images/vantaggio.png'} style={Style.itemImg} resizeMode={'contain'} />
          </View>
          <View style={Style.textContainer}>
            <Text style={Style.itemTitle}>Assicurazione</Text>
            <Text style={Style.itemText}>
              Una sinergia attraverso la quale tutti i possessori di Runcard possono contare su una copertura assicurativa per infortuni, compresa nel prezzo del tesseramento annuale, derivanti dall’attività sportiva di running.
            </Text>
          </View>
        </View>
        <View style={Style.spacer} />
        <View style={Style.column}>
          <View style={Style.imageContainer}>
            <Image source={'/static/images/vantaggio.png'} style={Style.itemImg} resizeMode={'contain'} />
          </View>
          <View style={Style.textContainer}>
            <Text style={Style.itemTitle}>Bed & Runfast</Text>
            <Text style={Style.itemText}>
              10% di sconto su tutte le strutture aderenti a Bed&Runfast, il primo portale in Italia che mette in comunicazione, in maniera sistematica, le strutture ricettive di qualunque tipologia (alberghi, b&b, agriturismi, villaggi, appartamenti) e i runner che viaggiano.
            </Text>
          </View>
        </View>
      </View>
      <View style={Style.columns}>
        <View style={Style.column}>
          <View style={Style.imageContainer}>
            <Image source={'/static/images/vantaggio.png'} style={Style.itemImg} resizeMode={'contain'} />
          </View>
          <View style={Style.textContainer}>
            <Text style={Style.itemTitle}>Visita medica</Text>
            <Text style={Style.itemText}>
              Runcard è un filo diretto con medici qualificati e centri d’eccellenza, per consentirti di vivere la tua passione in serenità ed in piena salute. Sono stati attivati diversi rapporti di convenzione con professionisti e strutture di primo piano distribuiti sul territorio nazionale, in collaborazione con la Federazione Italiana Medico Sportiva.
            </Text>
          </View>
        </View>
        <View style={Style.spacer} />
        <View style={Style.column}>
        </View>
      </View>
      <View style={Style.buttonContainer}>
        <PrimaryButton style={Style.button} route={'advantages'}>SCOPRI TUTTI I VANTAGGI</PrimaryButton>
      </View>
    </>
  );
};

Advantages.propTypes = {
  title: PropTypes.string,
};

export default Advantages;
