import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { RegistrationTypes } from './Actions'

export const registrationReset = (state) => ({
  ...state,
  status: 'NONE',
  error: null,
  data: null,
});

export const registrationSuccess = (state) => ({
  ...state,
  status: 'COMPLETED',
  error: null,
  data: null,
});

export const registrationFailure = (state, { error }) => ({
  ...state,
  error,
});

export const registrationToBeConfirmed = (state, { data }) => ({
  ...state,
  status: 'CONFIRM',
  data,
});


export const registrationToBeCompleted = (state, { data }) => ({
  ...state,
  status: 'TO_BE_COMPLETED',
  data,
});

export const fullRegistrationSuccess = (state) => ({
  ...state,
  status: 'FULL_OK',
  error: null,
  data: null,
});

export const requestCodeSuccess = (state) => ({
  ...state,
  new_code_error: false,
  new_code_success: true,
});

export const requestCodeFailure = (state) => ({
  ...state,
  new_code_error: true,
  new_code_success: false,
});

export const validationErrors = (state, { errors }) => ({
  ...state,
  validation_errors: errors,
});

export const reducer = createReducer(INITIAL_STATE, {
  [RegistrationTypes.REGISTRATION_TO_BE_COMPLETED]: registrationToBeCompleted,
  [RegistrationTypes.REGISTRATION_TO_BE_CONFIRMED]: registrationToBeConfirmed,
  [RegistrationTypes.REGISTRATION_SUCCESS]: registrationSuccess,
  [RegistrationTypes.REGISTRATION_FAILURE]: registrationFailure,
  [RegistrationTypes.FULL_REGISTRATION_SUCCESS]: fullRegistrationSuccess,
  [RegistrationTypes.REGISTRATION_RESET]: registrationReset,
  [RegistrationTypes.REQUEST_CODE_SUCCESS]: requestCodeSuccess,
  [RegistrationTypes.REQUEST_CODE_FAILURE]: requestCodeFailure,
  [RegistrationTypes.VALIDATION_ERRORS]: validationErrors,
});
