import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { NewsTypes } from './Actions'

export const setNews = (state, { news }) => ({
  ...state,
  news: {
    ...state.news,
    ...news,
  },
});

export const setError = (state, { error }) => ({
  ...state,
  error,
});

export const setSingleNews = (state, { news }) => ({
  ...state,
  singleNews: news,
});

export const setSingleNewsError = (state, { error }) => ({
  ...state,
  singleNewsError: error,
});

export const setNewsLoadMore = (state, { category, loadMore }) => ({
  ...state,
  loadMore: {
    ...state.loadMore,
    [category]: loadMore
  },
});

export const setNewsLoaded = (state) => ({
  ...state,
  newsLoaded: true,
});

export const reducer = createReducer(INITIAL_STATE, {
  [NewsTypes.SET_NEWS]: setNews,
  [NewsTypes.SET_NEWS_ERROR]: setError,
  [NewsTypes.SET_SINGLE_NEWS]: setSingleNews,
  [NewsTypes.SET_SINGLE_NEWS_ERROR]: setSingleNewsError,
  [NewsTypes.SET_NEWS_LOAD_MORE]: setNewsLoadMore,
  [NewsTypes.SET_NEWS_LOADED]: setNewsLoaded,
});
