import React from 'react';
import { SafeAreaView, Text, View } from 'react-native';

import Style from './ErrorsStyle';
import { BackgroundImage } from '../../Components/Layout';

const LoadingError = () => {
  return (
    <View style={Style.container}>
      <BackgroundImage>
        <SafeAreaView style={Style.innerContainer}>
          <View style={Style.centerContainer}>
            <Text style={Style.title}>Ops!</Text>
            <Text style={Style.text}>Si è verificato un errore durante il caricamento dell&apos;app. Ti chiediamo di riprovare più tardi.</Text>
          </View>
        </SafeAreaView>
      </BackgroundImage>
    </View>
  );
};

export default LoadingError;
