import React from 'react';
import { useLocation } from 'react-router-native';

const ScrollToTop = () => {
  let location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
};

export default ScrollToTop;
