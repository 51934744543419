import React from 'react';
import { View } from 'react-native';

import { getResponsive } from '../LegalsStyle';
import { Footer, PageHeader } from '../../../Components/Layout';
import { CookiePolicy as CookiePolicyContent } from '../../../Components/Legals';

const CookiePolicy = () => {
  const Style = getResponsive();

  return (
    <View style={Style.wrapper}>
      <PageHeader title={'Privacy Policy'}/>
      <View style={Style.container}>
        <CookiePolicyContent padding={true} />
      </View>
      <Footer />
    </View>
  );
};

export default CookiePolicy;
