import React from 'react';
import { ScrollView, View } from 'react-native';
import { useHistory } from 'react-router-native';

import Style from './ServicesStyle';
import { Colors } from '../../../Theme';
import { PageHeader } from '../../Layout';
import { GoogleFit, Salute, Strava } from '../../Services';
import { Button } from '../../Buttons';
import { BackIcon } from '../../../Theme/Icons';

import { getPath } from '../../../Router/AppRoutes';

const Services = () => {
  let history = useHistory();
  return (
    <>
      <PageHeader title={'Collega le tue APP'} back={false} left={<Button onPress={() => history.push(getPath('play'))}><BackIcon /></Button>}  />
      <ScrollView contentContainerStyle={Style.container} bounces={false}>
        <View style={Style.innerContainer}>
          <GoogleFit borderColor={Colors.grayALittleLight} />
          <Salute borderColor={Colors.grayALittleLight} />
          <Strava />
        </View>
      </ScrollView>
    </>
  );
};

export default Services;
