import ResponsiveHelper from "../../../../Services/ResponsiveHelper";
import { ApplicationStyles, Colors, Fonts } from "../../../../Theme";
import { Dimensions } from "react-native";
const windowWidth = Dimensions.get("window").width;

const BaseStyle = {
  button: {
    marginTop: 20,
  },
  consent: {
    marginTop: 20,
  },
  consentText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small + 2,
    color: Colors.grayMedium2,
    marginBottom: 10,
  },
  description: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular - 1,
    marginBottom: 20,
  },
  error: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.red,
    fontSize: Fonts.size.regular - 2,
    marginBottom: 10,
  },
  fieldsOneRow: {
    flex: 1,
    flexDirection: "row",
  },
  fieldsOneRowSmall: {
    flex: 1,
    marginLeft: 10,
  },
  fieldsOneRowWide: {
    flex: 3,
  },
  form: {},
  formTitle: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.cattolicaRed,
    fontSize: Fonts.size.regular + 1,
    marginBottom: 20,
    textAlign: "center",
  },
  formTitleBorder: {},
  formTitleContainer: {},
  genderButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
  },
  logo: {
    width: 70,
    height: 65,
    marginHorizontal: "auto",
    marginTop: -65,
  },
  formSection: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular + 3,
    color: Colors.gray,
    marginTop: 20,
    marginBottom: 10,
  },
  switches: {
    flexDirection: "row",
  },
  switchContainer: {
    flex: 1,
    alignItems: "flex-start",
    flexDirection: "row",
    marginBottom: 20,
  },
  switchContainerPrivacy: {
    alignItems: "flex-start",
    flexDirection: "row",
    width: "100%",
    marginTop: 10,
  },
  switchInner: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    marginLeft: 10,
    alignSelf: "center",
  },
  switchText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small + 2,
    color: Colors.grayMedium2,
  },
  switchTitle: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular,
    marginBottom: 5,
  },
  title: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.cattolicaRed,
    fontSize: Fonts.size.regular + 1,
    marginBottom: 15,
  },
  textInput: {
    ...ApplicationStyles.screen.fontsLight,
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
    color: Colors.gray,
    fontSize: Fonts.size.regular,
    marginBottom: 10,
    paddingBottom: 10,
    paddingTop: 10,
  },
  subtitle: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.cattolicaRed,
    fontSize: Fonts.size.regular + 1,
  },
  wrapper: {},
  backModal: {
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    justifyContent: "center",
    alignItems: "center",
    padding: 30,
  },
  bodymodal: {
    backgroundColor: "#fff",
    padding: 15,
    paddingVertical: 30,
    borderRadius: 30,
  },
  ContainerError: {
    justifyContent: "center",
    alignItems: "center",
  },
  ErrorCheck: {
    borderWidth: 5,
    flex: 0,
    width: 80,
    height: 80,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
    borderTopColor: "red",
    borderRightColor: "red",
    borderLeftColor: "red",
    borderBottomColor: "transparent",
    marginTop: -60,
    backgroundColor: "#fff",
  },
  buttonModal: {
    flex: 1,
    width: windowWidth - 50,
    maxHeight: 50,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "blue",
    marginTop: 15,
  },
  close: {
    fontSize: 18,
    color: "#fff",
    fontWeight: "bold",
  },
};

const StyleLG = {
  wrapper: {
    backgroundColor: Colors.white,
    marginTop: 60,
    marginHorizontal: 30,
    padding: 30,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
  },
  form: {
    maxWidth: 500,
    marginHorizontal: "auto",
    marginVertical: 50,
  },
  formTitle: {
    marginHorizontal: 20,
  },
  formTitleBorder: {
    borderBottomWidth: 1,
    borderColor: Colors.cattolicaRed,
    flex: 1,
    marginBottom: 20,
    opacity: 0.37,
  },
  formTitleContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
};

export const getResponsive = () =>
  ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
