import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getResponsive } from './WorkoutStyle';

const Item = ({ item }) => {
  const Style = getResponsive();

  const { start_date, end_date, kilometers, mins_per_km: minsPerKm, kcalories: kCalories } = item;

  const Field = ({ size, label, children }) => (
    <View style={[Style.item, size ? { flex: size } : {}]}>
      {children}
      {label && <Text style={Style.labelStyle}>{label}</Text>}
    </View>
  );

  const elapsed = () => moment.utc(moment.unix(end_date/1000).diff(moment.unix(start_date/1000), true)).format('HH:mm:ss');

  Field.propTypes = {
    size: PropTypes.number,
    label: PropTypes.string,
    children: PropTypes.node,
  };

  return (
    <View style={Style.itemContainer}>
      <Field label={'giorno'}><Text style={Style.dataStyle}>{moment.unix(start_date/1000).format('D/MM')}</Text></Field>
      <Field label={'km'} size={1.3}><Text style={Style.dataStyle}>{(Math.round(kilometers*100)/100).toString().replace('.', ',')}</Text></Field>
      <Field label={'ore'} size={1.4}><Text style={Style.dataStyle}>{elapsed()}</Text></Field>
      <Field label={'min/km'} size={1.3}><Text style={Style.dataStyle}>{minsPerKm}</Text></Field>
      <Field label={'kcal'}><Text style={Style.dataStyle}>{kCalories ? Math.round(kCalories) : '--'}</Text></Field>
    </View>
  )
};

Item.propTypes = {
  item: PropTypes.shape({
    start_date: PropTypes.number,
    end_date: PropTypes.number,
    kilometers: PropTypes.number,
    hours: PropTypes.string,
    mins_per_km: PropTypes.string,
    kcalories: PropTypes.number,
    source: PropTypes.string,
  }).isRequired,
};

export default Item;
