
export const getAdv = (list, position, section='', random=true) => {
  if (list) {
    let adv = list.filter(el => el.position === position && (section === '' || el.section === section));
    if (adv.length && random) {
      const rand = Math.floor(Math.random() * adv.length);
      return adv[rand];
    } else if (adv.length) {
      adv.sort((a, b) => a.order - b.order);
      return adv;
    }
  }
  return null;
};
