import React from 'react';
import { useSelector } from 'react-redux';
import { View, ScrollView, SafeAreaView, Text, Platform } from 'react-native';

import { getResponsive } from './CertificateStyle';
import { ApplicationStyles, Colors } from '../../../Theme';
import { Adv, Footer, Modal, ModalDownload, PageHeader } from '../../Layout';
import { CloseIcon, InfoIcon } from '../../../Theme/Icons';

import { Button } from '../../Buttons';
import CertificateContent from './CertificateContent';

import { getAdv } from '../../../Services/ADVService';

const Certificate = () => {
  const Style = getResponsive();

  // ADV
  const options = useSelector(state => state.helper.options);
  const adv = Platform.OS !== 'web' ? getAdv(options.Advertising, 'footer_banner', 'certificato') : null;

  const [ PDFOpen, setPDFOpen ] = React.useState(null);
  const [ info, setInfo ] = React.useState(false);

  return (
    <>
      <PageHeader title={'Visita medica'}/>
      <ScrollView contentContainerStyle={[Style.container, adv ? ApplicationStyles.screen.paddingAdvFooter : {}]} bounces={false}>
        <View style={Style.webContainer}>
          <View style={Style.innerContainer}>
            <Button onPress={() => setInfo(true)} style={Style.info}>
              <InfoIcon color={Colors.blue1} />
              <Text style={Style.note}>Il certificato medico valido è necessario per accedere alle gare</Text>
            </Button>
            <Text style={Style.text}>Runcard ha attivato per te diverse convenzioni in collaborazione con la <Text style={Style.bold}>Federazione Italiana Medico Sportiva</Text>.</Text>
            <Text style={Style.cta}>SCOPRILE SUBITO!</Text>
            <CertificateContent openPdf={setPDFOpen} />
          </View>
        </View>
      </ScrollView>
      {PDFOpen && <ModalDownload title={'Informativa'} url={PDFOpen} closeHandler={() => setPDFOpen(null)} />}
      {info && (
        <Modal transparent={true} onRequestClose={() => setInfo(false)} animationType={'fade'}>
          <SafeAreaView style={Style.modalBackground}>
            <View style={Style.modalContainer}>
              <Button onPress={() => setInfo(false)} style={Style.modalClose}>
                <CloseIcon />
              </Button>
              <View style={Style.modal}>
                <Text style={Style.modalText}>
                  La partecipazione dei possessori di Runcard, Mountain and Trail Runcard, Runcard EPS e Runcard Nordic Walking è comunque subordinata al possesso di un certificato medico di idoneità agonistica specifica per l’atletica leggera, in corso di validità, che dovrà essere obbligatoriamente consegnato, anche digitalmente, alle società organizzatrici di ciascuna manifestazione.
                </Text>
              </View>
            </View>
          </SafeAreaView>
        </Modal>
      )}
      <Footer adv={true} section={'certificato'} />
      {adv && <View style={ApplicationStyles.screen.footerBanner}><Adv adv={adv} /></View>}
    </>
  );
};

export default Certificate;
