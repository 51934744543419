import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const TabRaceIcon = ({ active=false, size=30 }) => {
  return (
    <Svg width={size} height={size} viewBox={'0 0 30 31'}>
      <Defs>
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={'gradientRace'}>
          <Stop stopColor={active ? '#02AFF1' : '#E3E4E3'} offset="0%" />
          <Stop stopColor={active ? '#0D85F1' : '#C3C4C3'} offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
        <Path d="M25.8265,16.785508 C25.384,22.168348 21.09775,26.421548 15.6715,26.860508 L15.6715,25.120788 L13.84275,25.120788 L13.84275,26.860508 C8.41525,26.421548 4.129,22.168348 3.6865,16.785508 L5.439,16.785508 L5.439,14.971388 L3.6865,14.971388 C4.129,9.588548 8.41525,5.335348 13.84275,4.896388 L13.84275,6.636108 L15.6715,6.636108 L15.6715,4.896388 C21.09775,5.335348 25.384,9.588548 25.8265,14.971388 L24.074,14.971388 L24.074,16.785508 L25.8265,16.785508 Z M27.659,14.971388 C27.20775,8.568028 22.1265,3.526188 15.6715,3.078548 L15.6715,1.239628 L13.84275,1.239628 L13.84275,3.078548 C7.3865,3.526188 2.30525,8.568028 1.854,14.971388 L0.00025,14.971388 L0.00025,16.785508 L1.854,16.785508 C2.30525,23.190108 7.3865,28.229468 13.84275,28.677108 L13.84275,30.516028 L15.6715,30.516028 L15.6715,28.677108 C22.1265,28.229468 27.20775,23.190108 27.659,16.785508 L29.51275,16.785508 L29.51275,14.971388 L27.659,14.971388 Z" fill={'url(#gradientRace)'} />
        <Path d="M14.75725,15.281512 C14.0935,15.281512 13.5535,14.745832 13.5535,14.087392 C13.5535,13.428952 14.0935,12.893272 14.75725,12.893272 C15.41975,12.893272 15.961,13.428952 15.961,14.087392 C15.961,14.745832 15.41975,15.281512 14.75725,15.281512 M14.75725,11.699152 C13.42975,11.699152 12.34975,12.770512 12.34975,14.087392 C12.34975,15.404272 13.42975,16.475632 14.75725,16.475632 C16.08475,16.475632 17.16475,15.404272 17.16475,14.087392 C17.16475,12.770512 16.08475,11.699152 14.75725,11.699152" fill={'url(#gradientRace)'} />
        <Path d="M14.75725,21.607992 C13.62475,20.468432 10.5435,17.143992 10.5435,14.685072 C10.5435,11.871512 12.276,9.907352 14.75725,9.907352 C17.23725,9.907352 18.96975,11.871512 18.96975,14.685072 C18.96975,17.143992 15.8885,20.468432 14.75725,21.607992 M14.75725,8.714472 C11.6185,8.714472 9.33975,11.224232 9.33975,14.685072 C9.33975,18.199232 14.14225,22.691752 14.346,22.881472 L14.75725,23.262152 L15.16725,22.881472 C15.371,22.691752 20.1735,18.199232 20.1735,14.685072 C20.1735,11.224232 17.896,8.714472 14.75725,8.714472" fill={'url(#gradientRace)'} />
      </G>
    </Svg>
  )
};

TabRaceIcon.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
};

export default TabRaceIcon;
