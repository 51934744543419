import React from 'react';
import PropTypes from 'prop-types';
import { Text, ScrollView, View, Platform } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import { getResponsive } from './TabMenuStyle';
import { Colors } from '../../../Theme';
import { Button } from '../../Buttons';

const TabMenu = ({ tabs, currentTab }) => {
  const Style = getResponsive();
  const [ selected, setSelected ] = React.useState(null);

  let listView;
  let containerView;

  React.useEffect(() => {
    if (selected && listView && containerView && Platform.OS !== 'android') {
      containerView.measure(({}, {}, width) => {
        if (width>0) {
          selected.measure((fx) => {
            if (listView) listView.scrollTo({ x: fx - ((width)/2) + 50 });
          });
        }
      })
    }
  }, [selected]);

  return (
    <View ref={ref => containerView = ref}>
      <View style={Style.wrapper}>
        <View style={Style.tabBorder} />
        <ScrollView
          ref={ref => listView = ref}
          bounces={false}
          showsHorizontalScrollIndicator={false}
          horizontal={true}
          contentContainerStyle={Style.innerWrapper}
          scrollEventThrottle={16}>
          {Object.keys(tabs).map(tabId => (
            <View ref={view => tabId === currentTab ? setSelected(view) : {}} key={tabId}>
              <Button onPress={() => { tabs[tabId].callback(); }} style={[Style.tabButton, tabId === currentTab ? Style.activeTabButton : {} ]}>
                <Text style={[Style.tabLinkText, tabId === currentTab ? Style.activeTabLinkText : {}]}>{tabs[tabId].label}</Text>
              </Button>
            </View>
          ))}
        </ScrollView>
        <LinearGradient colors={[ Colors.transparent, Colors.grayUltraFantaLight, Colors.grayUltraFantaLight ]} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={[Style.gradientRight, /*Style.gradientHidden : */{} ]} />
        <LinearGradient colors={[ Colors.grayUltraFantaLight, Colors.grayUltraFantaLight, Colors.transparent ]} start={{ x: 0, y: 0 }} end={{ x: 1, y: 0 }} style={[Style.gradientLeft, /*Style.gradientHidden : */{} ]} />
      </View>
    </View>
  );
};

TabMenu.propTypes = {
  currentTab: PropTypes.string,
  tabs: PropTypes.shape({}).isRequired,
  tabId: PropTypes.string,
  elementPosition: PropTypes.string,
};

export default TabMenu;
