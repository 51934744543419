import { takeLatest, all } from "redux-saga/effects";

import { StartupTypes } from "../Stores/Startup/Actions";
import { startup, token } from "./StartupSaga";

import { RegistrationTypes } from "../Stores/Registration/Actions";
import {
  registration,
  socialRegistration,
  confirmRegistration,
  completeRegistration,
  fullRegistration,
  updateForRenew,
  requestConfirmationCode,
  updateUser,
} from "./RegistrationSaga";

import { AuthTypes } from "../Stores/Auth/Actions";
import {
  login,
  logout,
  requestChangePassword,
  changePasswordSkipRequest,
  changePassword,
  updateNotification,
  deleteProfile
} from "./AuthSaga";

import { HelperTypes } from "../Stores/Helper/Actions";
import {
  getProvinces,
  getCountries,
  getFaq,
  getConventions,
  getServiceTokenEncoded,
  sendCRMEvent,
  sendLeaderboardEvent,
  generatePdf,
  leaderboardLevel,
} from "./HelperSaga";

import { OrderTypes } from "../Stores/Order/Actions";
import { startOrder } from "./OrderSaga";

import { RuncardTypes } from "../Stores/Runcard/Actions";
import { getRuncard, linkRuncard, getRuncardBarcode } from "./RuncardSaga";

import { S3Types } from "../Stores/S3/Actions";
import { S3Upload, S3Delete } from "./S3Saga";

import { NewsTypes } from "../Stores/News/Actions";
import { getNews, getSingleNews } from "./NewsSaga";

import { ServicesTypes } from "../Stores/Services/Actions";
import { stravaAuthenticate, stravaDisconnect } from "./ServicesSaga";

import { WorkoutTypes } from "../Stores/Workout/Actions";
import {
  fetchGoogleFitActivities,
  fetchStravaActivities,
  fetchSaluteActivities,
} from "./WorkoutSaga";

import { SocialWallTypes } from "../Stores/SocialWall/Actions";
import { getSocialWallData } from "./SocialWallSaga";

import { ChallengesTypes } from "../Stores/Challenges/Actions";
import {
  getEditorialChallenges,
  getUserChallenges,
  getChallenge,
  createChallenge,
  joinChallenge,
  fetchChallengeProgress,
} from "./ChallengesSaga";

import { ResultsTypes } from "../Stores/Results/Actions";
import { getResults } from "./ResultsSaga";

import { RacesTypes } from "../Stores/Races/Actions";
import {
  getRaces,
  getRace,
  saveRace,
  unSaveRace,
  getSavedRaces,
} from "./RacesSaga";

import { CattolicaTypes } from "../Stores/Cattolica/Actions";
import {
  getQuotation,
  startOrder as startCattolicaOrder,
  getEvents,
} from "./CattolicaSaga";

export default function* root() {
  yield all([
    takeLatest(StartupTypes.STARTUP, startup),
    takeLatest(StartupTypes.TOKEN, token),
    takeLatest(AuthTypes.LOGOUT, logout),
    takeLatest(AuthTypes.DELETE_PROFILE, deleteProfile),
    takeLatest(AuthTypes.LOGIN, login),
    takeLatest(RegistrationTypes.REGISTRATION, registration),
    takeLatest(RegistrationTypes.SOCIAL_REGISTRATION, socialRegistration),
    takeLatest(RegistrationTypes.CONFIRM_REGISTRATION, confirmRegistration),
    takeLatest(RegistrationTypes.COMPLETE_REGISTRATION, completeRegistration),
    takeLatest(RegistrationTypes.FULL_REGISTRATION, fullRegistration),
    takeLatest(RegistrationTypes.UPDATE_FOR_RENEW, updateForRenew),
    takeLatest(RegistrationTypes.REQUEST_CODE, requestConfirmationCode),
    takeLatest(RegistrationTypes.UPDATE_USER, updateUser),
    takeLatest(AuthTypes.REQUEST_CHANGE_PASSWORD, requestChangePassword),
    takeLatest(
      AuthTypes.CHANGE_PASSWORD_SKIP_REQUEST,
      changePasswordSkipRequest
    ),
    takeLatest(AuthTypes.CHANGE_PASSWORD, changePassword),
    takeLatest(AuthTypes.UPDATE_NOTIFICATION, updateNotification),
    takeLatest(HelperTypes.GET_PROVINCES, getProvinces),
    takeLatest(HelperTypes.GET_COUNTRIES, getCountries),
    takeLatest(HelperTypes.GET_FAQ, getFaq),
    takeLatest(HelperTypes.GET_CONVENTIONS, getConventions),
    takeLatest(HelperTypes.GET_SERVICE_ENCODED_TOKEN, getServiceTokenEncoded),
    takeLatest(HelperTypes.SEND_CRM_EVENT, sendCRMEvent),
    takeLatest(HelperTypes.SEND_LEADERBOARD_EVENT, sendLeaderboardEvent),
    takeLatest(HelperTypes.GET_LEADERBOARD_LEVEL, leaderboardLevel),
    takeLatest(HelperTypes.GENERATE_PDF, generatePdf),
    takeLatest(OrderTypes.START_ORDER, startOrder),
    takeLatest(RuncardTypes.GET_RUNCARD, getRuncard),
    takeLatest(RuncardTypes.LINK_RUNCARD, linkRuncard),
    takeLatest(RuncardTypes.GET_RUNCARD_BARCODE, getRuncardBarcode),
    takeLatest(S3Types.S3_UPLOAD, S3Upload),
    takeLatest(S3Types.S3_DELETE, S3Delete),
    takeLatest(NewsTypes.GET_NEWS, getNews),
    takeLatest(NewsTypes.GET_SINGLE_NEWS, getSingleNews),
    takeLatest(ServicesTypes.STRAVA_AUTHENTICATE, stravaAuthenticate),
    takeLatest(ServicesTypes.STRAVA_DISCONNECT, stravaDisconnect),
    takeLatest(WorkoutTypes.GET_GOOGLE_FIT_DATA, fetchGoogleFitActivities),
    takeLatest(WorkoutTypes.GET_STRAVA_DATA, fetchStravaActivities),
    takeLatest(SocialWallTypes.GET_SOCIAL_WALL_DATA, getSocialWallData),
    takeLatest(WorkoutTypes.GET_SALUTE_DATA, fetchSaluteActivities),
    takeLatest(
      ChallengesTypes.GET_EDITORIAL_CHALLENGES,
      getEditorialChallenges
    ),
    takeLatest(ChallengesTypes.GET_USER_CHALLENGES, getUserChallenges),
    takeLatest(ChallengesTypes.GET_CHALLENGE, getChallenge),
    takeLatest(ChallengesTypes.CREATE_CHALLENGE, createChallenge),
    takeLatest(ChallengesTypes.JOIN_CHALLENGE, joinChallenge),
    takeLatest(
      ChallengesTypes.FETCH_CHALLENGE_PROGRESS,
      fetchChallengeProgress
    ),
    takeLatest(ResultsTypes.GET_RESULTS, getResults),
    takeLatest(RacesTypes.GET_RACES, getRaces),
    takeLatest(RacesTypes.OPEN_RACE, getRace),
    takeLatest(RacesTypes.SAVE_RACE, saveRace),
    takeLatest(RacesTypes.UN_SAVE_RACE, unSaveRace),
    takeLatest(RacesTypes.GET_SAVED_RACES, getSavedRaces),
    takeLatest(CattolicaTypes.GET_QUOTATION, getQuotation),
    takeLatest(CattolicaTypes.START_CATTOLICA_ORDER, startCattolicaOrder),
    takeLatest(CattolicaTypes.GET_EVENTS, getEvents),
  ]);
}
