import React from 'react';
import PropTypes from 'prop-types';
import { View, Image, TouchableWithoutFeedback, Platform, Dimensions, StyleSheet } from 'react-native';
import { FlingGestureHandler, Directions, State } from 'react-native-gesture-handler';

import { getResponsive } from './SocialWallStyle';
import { CloseIcon } from '../../../Theme/Icons';
import { LoaderInline } from '../../Layout';
import { Button } from '../../Buttons';

const FullImage = ({ fullImage, setFullImage, images }) => {
  const Style = getResponsive();

  const [ imageAspectRatio, setImageAspectRation ] = React.useState(null);

  const onSwipe = (gestureName) => {
    let i;
    switch (gestureName) {
      case 'SWIPE_UP':
      case 'SWIPE_DOWN':
        setFullImage(false);
        break;
      case 'SWIPE_LEFT':
        i = images.findIndex(el => el.id === fullImage.id);
        if (i === -1 || i===images.length-1) {
          setImageAspectRation(null);
          setFullImage(false);
        } else {
          setImageAspectRation(null);
          setFullImage(images[i+1]);
        }
        break;
      case 'SWIPE_RIGHT':
        i = images.findIndex(el => el.id === fullImage.id);
        if (i === -1 || i===0) {
          setImageAspectRation(null);
          setFullImage(false);
        } else {
          setImageAspectRation(null);
          setFullImage(images[i-1]);
        }
    }
  };

  const calcDimensions = () => {
    if (imageAspectRatio) {
      let { width, height } = Dimensions.get('window');

      width -= 50;
      height -= 100;

      let imgWidth = width;
      let imgHeight = imgWidth/imageAspectRatio;

      if (imgHeight > height) {
        imgHeight = height;
        imgWidth = imgHeight*imageAspectRatio;
      }

      return { width: imgWidth, height: imgHeight };
    }
    return { width: 0, height: 0 };
  };

  const GestureOnMobile = ({  children }) => (
    Platform.OS !== 'web' ? (
      <FlingGestureHandler direction={Directions.LEFT} onHandlerStateChange={({ nativeEvent }) => { if (nativeEvent.state === State.ACTIVE) onSwipe('SWIPE_LEFT'); }}>
        <FlingGestureHandler direction={Directions.RIGHT} onHandlerStateChange={({ nativeEvent }) => { if (nativeEvent.state === State.ACTIVE) onSwipe('SWIPE_RIGHT'); }}>
          <FlingGestureHandler direction={Directions.UP} onHandlerStateChange={({ nativeEvent }) => { if (nativeEvent.state === State.ACTIVE) onSwipe('SWIPE_UP'); }}>
            {children}
          </FlingGestureHandler>
        </FlingGestureHandler>
      </FlingGestureHandler>
    ) : children
  );

  GestureOnMobile.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const { width } = Dimensions.get('window');

  React.useEffect(() => {
    Image.getSize(fullImage.url, (width, height) => {
      setImageAspectRation(width / height);
    });
  }, [ fullImage ]);

  return (
    <View style={Style.modal}>
      {Platform.OS === 'web' && <div style={StyleSheet.flatten(Style.clickWrapper)} onClick={() => setFullImage(false)} />}
      <GestureOnMobile>
        <View style={[Style.fullScreenContainer, imageAspectRatio && Platform.OS === 'web' ? { width: calcDimensions().width } : {} ]}>
          <Button onPress={() => setFullImage(false)} style={Style.closeButton}>
            <CloseIcon />
          </Button>
          {imageAspectRatio ? (
            <TouchableWithoutFeedback onPress={() => setFullImage(false)}>
              {Platform.OS === 'web'
                ? <Image style={calcDimensions()} source={{ uri: fullImage.url }} />
                : <Image style={{ aspectRatio: imageAspectRatio, width: width-40 }} source={{ uri: fullImage.url }} />
              }
            </TouchableWithoutFeedback>
          ) : (
            <View style={[Style.loaderContainer, { width: width-40, height: width-40 }]}><LoaderInline style={{}} /></View>
          )}
        </View>
      </GestureOnMobile>
    </View>
  )
};

FullImage.propTypes = {
  setFullImage: PropTypes.func.isRequired,
  fullImage: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
};

export default FullImage;
