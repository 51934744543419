import React from 'react';
import { Text, Image, View } from 'react-native';
import PropTypes from 'prop-types';

import { getResponsive } from './IntroStyle';
import { PrimaryButton } from '../../../Buttons';
import { Images } from '../../../../Theme';
import { isDesktop } from "../../../../Services/ResponsiveHelper";

const Intro = ({ clickHandler }) => {
  const Style = getResponsive();

  return (
    <View style={Style.wrapper}>
      <View style={Style.imgContainer}>
        {isDesktop() && <View style={Style.backImgContainer}><img src={Images.cattolica_back} style={Style.backImg} alt={''} /></View>}
        <Image source={Images.cattolica_intro} style={Style.img} resizeMode={'contain'} />
      </View>
      <View style={Style.textContainer}>
        <Text style={Style.title}>TUA Assicurazioni,</Text>
        <Text style={Style.subtitle}>il Coach che hai sempre cercato!</Text>
        <Text style={Style.text}>TUA Assicurazioni si è focalizzata per creare una gamma di prodotti orientati al running. Scegli uno dei servizi che abbiamo pensato per proteggerti durante l&apos;allenamento, in caso di annullamento di una gara o tanto altro!</Text>
        {!isDesktop() && <Text style={Style.cta}>Scopri ora i nostri prodotti!</Text>}
      </View>
      {!isDesktop() && (
        <View style={Style.buttonContainer}>
          <PrimaryButton onPress={clickHandler}>INIZIA!</PrimaryButton>
        </View>
      )}
    </View>
  )
};

Intro.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

export default Intro;
