import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  startOrder: ['card_type', 'renew'],
  setOrder: ['order_id'],
  setOrderError: ['error'],
});

export const OrderTypes = Types;
export default Creators;
