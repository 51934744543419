import React from 'react';
import PropTypes from 'prop-types'
import { SafeAreaView, ScrollView, View, Text, TextInput } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Style from './RegistrationConfirmStyle';
import { Button, BackOrCloseButton, PrimaryButton } from '../../Buttons';
import { ErrorPopup, SuccessPopup } from '../../Popup';

import RegistrationActions from '../../../Stores/Registration/Actions';
import { isDesktop } from '../../../Services/ResponsiveHelper';

const RegistrationConfirm = ({ isModal }) => {
  const dispatch = useDispatch();

  const { error, data, new_code_error: newCodeError, new_code_success: newCodeSuccess } = useSelector(state => state.registration);

  const [ code, setCode ] = React.useState('');
  const [ newCodeRequested, setNewCodeRequested ] = React.useState(false);
  const [ inputWidth, setInputWidth ] = React.useState('99%');

  const submitConfirmation = () => {
    dispatch(RegistrationActions.confirmRegistration({ username: data.email, code, avatar: data.avatar, password: data.password }));
  };

  const requestNewCode = () => {
    dispatch(RegistrationActions.requestCode(data.email));
    setNewCodeRequested(true);
  };

  React.useEffect(() => {
    setTimeout(() => setInputWidth('100%'), 100);
  }, []);

  React.useEffect(() => {
    setCode('');
  }, [error]);

  return (
    <View style={[ Style.wrapper, isDesktop() && !isModal ? Style.wrapperBackground : {}]}>
      <SafeAreaView style={Style.wrapper}>
        <ScrollView bounces={false}>
          <View style={isDesktop() && !isModal ? Style.containerWeb : {}}>
            {!isModal && <BackOrCloseButton style={Style.backButton} />}
            <View style={Style.container}>
              <Text style={Style.h1}>Registrati</Text>
              {data ? (
                <View style={Style.wrapper}>
                  <Text style={Style.lead}>Abbiamo inviato un&apos;email all&apos;indirizzo {data.email} con il codice di conferma</Text>
                  {error && <Text style={Style.error}>{error}</Text>}
                  <TextInput style={[Style.textInput, { width: inputWidth }]} autoCorrect={false} placeholder={'Codice di conferma'} keyboardType={'number-pad'} value={code} onChangeText={setCode} />
                  <PrimaryButton onPress={submitConfirmation} disabled={!code.length} style={Style.button}>
                    <Text>CONFERMA</Text>
                  </PrimaryButton>
                  <View style={Style.sectionWrapper}>
                    <Button style={Style.newCodeButton} onPress={() => requestNewCode()}>
                      <Text style={Style.newCode}>Richiedi un altro codice</Text>
                    </Button>
                  </View>
                </View>
              ) : <Text style={Style.error}>Ops! Qualcosa è andato storto!</Text>
              }
            </View>
          </View>
        </ScrollView>
        {newCodeRequested && newCodeError && <ErrorPopup closeHandler={() => setNewCodeRequested(false)} title={'Si è verificato un errore'} />}
        {newCodeRequested && newCodeSuccess && <SuccessPopup closeHandler={() => setNewCodeRequested(false)} title={'Ti abbiamo inviato un nuovo codice'} text={`Controlla la tua casella email`} />}
      </SafeAreaView>
    </View>
  )
};

RegistrationConfirm.propTypes = {
  isModal: PropTypes.bool,
};

export default RegistrationConfirm;
