import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-native';
import { Platform } from 'react-native';

import { Loader } from '../../Components/Layout';
import LayoutActions from '../../Stores/Layout/Actions';
import { Config } from '../../Config';

import { getPath } from '../../Router/Router';
import NavigationActions from "../../Stores/Navigation/Actions";

const SignIn = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  let timeout;

  const logged = useSelector(state => state.auth.logged);
  const redirectAfterLogin = useSelector(state => state.navigation.afterLogin);

  React.useEffect(() => {
    if (Platform.OS === 'web' && location && location.search && location.search.indexOf('error') !== -1) {
      let errorMessage = null;
      location.search.substr(1).split('&').map(el => { const val = el.split('='); if (val[0] === 'error_description') errorMessage = decodeURIComponent(val[1].replace(/\+/g, '%20')); });
      if (errorMessage === 'PreSignUp failed with error social-login-missing-email. ') {
        errorMessage = 'Un indirizzo email valido è necessario per procedere con la registrazione';
      }
      dispatch(LayoutActions.setError({ text: errorMessage, closeHandler: () => history.push('/') }));
    } else {
      timeout = setTimeout(() => {
        dispatch(LayoutActions.setError({ text: 'L\'autenticazione sta impiegando troppo tempo. Ci scusiamo per il disagio, ti preghiamo di riprovare. Grazie.', closeHandler: () => history.replace(getPath(Config.APP_HOMEPAGE)) }));
      }, 60000) // 1 minute
    }

    return function cleanup() {
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  React.useEffect(() => {
    if (logged) {
      if (redirectAfterLogin) {
        dispatch(NavigationActions.setAfterLogin(null));
        history.replace(getPath(redirectAfterLogin));
      } else {
        history.replace(getPath(Config.APP_HOMEPAGE));
      }
    }
  }, [logged]);

  return (
    <Loader force={true} />
  );
};

export default SignIn;
