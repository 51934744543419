import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';

import Style  from './AdvPopupStyle';
import { Modal } from '../../Layout';
import { CloseIcon } from '../../../Theme/Icons';
import { Button } from '../../Buttons';

const AdvPopup = ({ closeHandler, children, width=null }) => {
  return (
    <Modal animationType={'fade'} transparent={true} onRequestClose={closeHandler}>
      <LinearGradient colors={['rgba(0,42,85,0.95)', 'rgba(0,42,85,0.5)']} style={Style.gradient}>
        <View style={[Style.wrapper, width ? { width } : {}]}>
          <Button onPress={closeHandler} style={Style.closeButton}>
            <CloseIcon />
          </Button>
          {children}
        </View>
      </LinearGradient>
    </Modal>
  )
};

AdvPopup.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

export default AdvPopup;
