import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-native';

import { getPath } from './Router';

const NoRuncardRoute = ({ route, logged, user }) => {
  const { path, exact, routes, ...rest } = route;

  return (
    <Route
      path={path}
      exact={exact||false}
      render={props => (!logged || !user || !user.runcard || user.runcard === '' ? <route.component {...props} {...rest} routes={routes} /> : <Redirect push={false} to={getPath('runcard')} />)}
    />
  );
};

NoRuncardRoute.propTypes = {
  route: PropTypes.object.isRequired,
  logged: PropTypes.bool,
  user: PropTypes.object,
};

const mapStateToProps = ({ auth }) => ({
  logged: auth.logged,
  user: auth.user,
});

export default connect(mapStateToProps)(NoRuncardRoute);
