import React from "react";
import PropTypes from "prop-types";
import { pure } from "recompose";
import { generatePath, useHistory } from "react-router-native";
import { Text, View } from "react-native";
import sanitizeHtml from "sanitize-html";
import moment from "moment";

import { getResponsive } from "./HomepageNewsStyle";
import { Colors } from "../../../Theme";
import { RightArrow } from "../../../Theme/Icons";
import { SecondaryButton } from "../../Buttons";

import { getPath } from "../../../Router/AppRoutes";
import { isDesktop } from "../../../Services/ResponsiveHelper";

const HomepageNews = ({ news, side }) => {
  const Style = getResponsive();
  let history = useHistory();

  const sanOptions = {
    allowedTags: [],
  };

  return (
    <View style={Style.newsContainer}>
      <View
        style={[
          Style.whiteSpace,
          side === "left" ? Style.whiteSpaceLeft : Style.whiteSpaceRight,
        ]}
      />
      <View
        style={[
          Style.news,
          side === "left" ? Style.cornerLeft : Style.cornerRight,
        ]}
      >
        {(side === "right" || !isDesktop()) && (
          <View style={Style.imageContainer}>
            <img
              src={
                news.image !== ""
                  ? news.image
                  : "/static/images/placeholder.jpg"
              }
              style={Style.image}
              alt={""}
            />
          </View>
        )}
        <View style={Style.newsContentContainer}>
          <Text style={Style.newsTitle}>{news.title}</Text>
          <Text style={Style.newsDate}>
            {moment.unix(news.date).format("D MMM YYYY [alle] HH:mm")}
          </Text>
          <Text style={Style.newsSummary}>
            {sanitizeHtml(news.summary, sanOptions)}
          </Text>
          <View style={Style.buttonContainer}>
            <SecondaryButton
              size={"xsmall"}
              style={Style.button}
              onPress={() =>
                history.push(generatePath(getPath("newsItem"), { id: news.id }))
              }
            >
              <View style={Style.buttonContent}>
                <Text style={Style.buttonText}>Leggi tutto</Text>
                <RightArrow color={Colors.blue1} size={16} />
              </View>
            </SecondaryButton>
          </View>
        </View>
        {side === "left" && isDesktop() && (
          <View style={Style.imageContainer}>
            <img
              src={
                news.image !== ""
                  ? news.image
                  : "/static/images/placeholder.jpg"
              }
              style={Style.image}
              alt={""}
            />
          </View>
        )}
      </View>
    </View>
  );
};

HomepageNews.propTypes = {
  news: PropTypes.object.isRequired,
  side: PropTypes.oneOf(["left", "right"]),
};

export default pure(HomepageNews);
