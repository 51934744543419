import React from "react";
import PropTypes from "prop-types";
import { Platform, View } from "react-native";
import { WebView } from "react-native-webview";

import Style from "./WebViewStyle";
import { ApplicationStyles } from "../../../Theme";

import { isDesktop } from "../../../Services/ResponsiveHelper";

const CustomWebView = ({
  url,
  style,
  onMessage = () => {},
  onLoad = () => {},
  customHTML,
  padding = false,
  headers = {},
  forwardedRef,
  ...props
}) => {
  const handleMessage = (message) => {
    let payload = message.nativeEvent.data;
    try {
      payload = JSON.parse(payload);
    } catch (e) {}
    onMessage(payload);
  };

  return Platform.OS === "web" ? (
    <View
      style={[
        style || Style.webview,
        !isDesktop() && padding
          ? {
              marginBottom:
                ApplicationStyles.screen.paddingTabBar.paddingBottom,
            }
          : {},
      ]}
    >
      <WebView
        ref={forwardedRef}
        useWebKit={true}
        source={url ? { uri: url } : { html: customHTML }}
        originWhitelist={["*"]}
        onMessage={handleMessage}
        onLoad={onLoad}
        {...props}
      />
    </View>
  ) : (
    <WebView
      ref={forwardedRef}
      useWebKit={false}
      source={url ? { uri: url, headers } : { html: customHTML }}
      originWhitelist={["*"]}
      style={[
        style || Style.webview,
        padding && Platform.OS !== "ios"
          ? {
              marginBottom:
                padding !== parseInt(padding)
                  ? ApplicationStyles.screen.paddingTabBar.paddingBottom
                  : padding,
            }
          : {},
      ]}
      onMessage={handleMessage}
      onLoadEnd={onLoad}
      {...props}
      contentInset={{
        top: 0,
        left: 0,
        right: 0,
        bottom: padding
          ? padding !== parseInt(padding)
            ? ApplicationStyles.screen.paddingTabBar.paddingBottom
            : padding
          : 0,
      }}
    />
  );
};

CustomWebView.propTypes = {
  url: PropTypes.string,
  customHTML: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  onMessage: PropTypes.func,
  onLoad: PropTypes.func,
  padding: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  headers: PropTypes.object,
  forwardedRef: PropTypes.object,
};

export default CustomWebView;
