import React from 'react';
import { Redirect } from 'react-router-native';
import { useSelector, useDispatch } from 'react-redux';
import { Config } from '../../Config';

import { getPath } from '../../Router/Router';
import AuthActions from '../../Stores/Auth/Actions';

const Logout = ({ }) => {
  const dispatch = useDispatch();

  const logged = useSelector(state => state.auth.logged);
  React.useEffect(() => {
    if (logged) {
      dispatch(AuthActions.logout());
    }
  }, [logged]);

  return <Redirect to={getPath(Config.APP_HOMEPAGE)} />;
};

export default Logout;
