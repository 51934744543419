import React from 'react';
import { Text, View, Image, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { FlingGestureHandler, Directions, State } from 'react-native-gesture-handler';
import LinearGradient from 'react-native-linear-gradient';

import Style, { getResponsive } from './TutorialStyle';
import { Images } from '../../Theme';
import { CloseIcon } from '../../Theme/Icons';

import { PrimaryButton, Button } from '../Buttons';
import { PaginationDots } from '../Layout';

import ResponsiveHelper from '../../Services/ResponsiveHelper';

const Tutorial = ({ onClose, onEnd, button = 'INIZIA SUBITO' }) => {
  const [page, setPage] = React.useState(0);
  const [breakpoint, setBreakpoint] = React.useState(ResponsiveHelper.getSize());
  const [showImage, setShowImage] = React.useState(true);

  const onSwipe = (gestureName) => {
    switch (gestureName) {
      case 'SWIPE_UP':
      case 'SWIPE_DOWN':
        onClose();
        break;
      case 'SWIPE_LEFT':
        if (page < pages.length - 1) {
          setPage(page + 1);
        } else {
          onEnd()
        }
        break;
      case 'SWIPE_RIGHT':
        if (page > 0) {
          setPage(page - 1);
        } else {
          onClose();
        }
    }
  };

  const pages = [
    { img: Images.tutorial1, title: 'Cos\'è Runcard?', text: 'Runcard è un tesseramento individuale che ti permette di accedere a tutte le manifestazioni “no Stadia”: maratone, mezze maratone, 10 km, campestri e trail in tutta Italia.' },
    { img: Images.tutorial3, title: 'Cosa ti serve per partecipare alle gare?', text: 'Per accedere alle competizioni devi essere in possesso di una Runcard, acquistabile attraverso l\'app, e di un certificato medico valido.' },
    { img: Images.tutorial2, title: 'Entra a far parte della Community Runcard!', text: 'Scopri tutte le gare e rimani aggiornato sulle novità e gli eventi vicini a te.' },
    { img: Images.tutorial4, title: 'Gioca e scala la classifica!', text: 'Accumula punti e vinci fantastici premi! Rispondi a quiz e sondaggi, scatta selfie personalizzati e metti alla prova le tue abilità da runner completando le sfide.' },
    { img: Images.tutorial5, title: 'Scopri i vantaggi!', text: 'Grazie a Runcard avrai accesso a sconti, convezioni e offerte esclusive dei nostri partner!\nChe aspetti? ' },
  ];

  const GestureOnMobile = ({ children }) => (
    Platform.OS !== 'web' ? (
      <FlingGestureHandler direction={Directions.LEFT} onHandlerStateChange={({ nativeEvent }) => { if (nativeEvent.state === State.ACTIVE) onSwipe('SWIPE_LEFT'); }}>
        <FlingGestureHandler direction={Directions.RIGHT} onHandlerStateChange={({ nativeEvent }) => { if (nativeEvent.state === State.ACTIVE) onSwipe('SWIPE_RIGHT'); }}>
          <FlingGestureHandler direction={Directions.UP} onHandlerStateChange={({ nativeEvent }) => { if (nativeEvent.state === State.ACTIVE) onSwipe('SWIPE_UP'); }}>
            {children}
          </FlingGestureHandler>
        </FlingGestureHandler>
      </FlingGestureHandler>
    ) : children
  );

  GestureOnMobile.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <View style={Style.modal} onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}>
      <LinearGradient colors={['rgba(0,42,85,0.95)', 'rgba(0,42,85,0.5)']} style={getResponsive().gradient}>
        <View style={getResponsive().wrapper}>
          <View style={Style.closeContainer}>
            <Button onPress={() => onClose()} style={Style.closeButton}>
              <CloseIcon />
            </Button>
          </View>
          <GestureOnMobile>
            <View style={getResponsive().container} onLayout={(event) => { const { height } = event.nativeEvent.layout; console.log(height); if (height < 460) setShowImage(false); }}>
              <View style={Style.content}>
                {breakpoint >= ResponsiveHelper.Breakpoints.XS && showImage && (
                  <View style={Style.imgWrapper}>
                    <Image source={pages[page].img} style={Style.img} resizeMode={'contain'} />
                  </View>
                )}
                <View style={Style.textWrapper}>
                  <Text style={Style.title}>{pages[page].title}</Text>
                  <Text style={getResponsive().text}>{pages[page].text}</Text>
                  {/*<Text style={Style.small}>{pages[page].small}</Text>*/}
                </View>
              </View>
              <PaginationDots current={page} size={pages.length} />
              {page < pages.length - 1
                ? <PrimaryButton size={'small'} onPress={() => setPage(page + 1)} style={Style.button}>AVANTI</PrimaryButton>
                : <PrimaryButton size={'small'} onPress={() => { onEnd(); onClose(); }} style={Style.button}>{button}</PrimaryButton>
              }
            </View>
          </GestureOnMobile>
        </View>
      </LinearGradient>
    </View>
  )
};

Tutorial.propTypes = {
  onClose: PropTypes.func,
  onEnd: PropTypes.func,
  button: PropTypes.string,
};

export default Tutorial;
