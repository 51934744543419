import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polyline } from 'react-native-svg';

import { Colors } from '../../Theme';

const RightArrow = ({ size=20, color=Colors.grayLight, stroke=1, shadow=false }) => (
  <Svg width={size} height={size} viewBox={'0 0 9 18'} className={shadow ? 'svg-shadow' : ''}>
    <G stroke={'none'} strokeWidth={stroke} fill={'none'} fillRule={'evenodd'} strokeLinecap={'round'}>
      <G transform={'translate(-335.000000, -533.000000)'} stroke={color}>
        <Polyline transform={'translate(339.500000, 542.000000) rotate(-180.000000) translate(-339.500000, -542.000000)'} points={'343 551 336 542 343 533'} />
      </G>
    </G>
  </Svg>
);

RightArrow.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  stroke: PropTypes.number,
  shadow: PropTypes.bool,
};

export default RightArrow;
