import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Animated, Platform, Dimensions, SafeAreaView } from 'react-native';
import { FlingGestureHandler, Directions, State } from 'react-native-gesture-handler';
import { useSelector, useDispatch } from 'react-redux';
import LinearGradient from 'react-native-linear-gradient';

import ResponsiveHelper, { isDesktop } from '../../../Services/ResponsiveHelper';
import { getResponsive } from './SidebarStyle';
import { Colors } from '../../../Theme';
import { Button, Link } from '../../Buttons';
import {
  AdvantagesIcon,
  CloseIcon,
  FaqIcon,
  LoginIcon,
  LogoutIcon,
  ProfileIcon,
  TabHomeIcon
} from '../../../Theme/Icons';
import LayoutActions from '../../../Stores/Layout/Actions';
import HelperActions from '../../../Stores/Helper/Actions';

const Sidebar = () => {
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const visible = useSelector(state => state.layout.sidebar);
  const Style = getResponsive();

  const toggleSidebar = () => dispatch(LayoutActions.toggleSidebar());
  const openLogin = () => dispatch(LayoutActions.toggleLoginModal(true));

  let rightBorder = 0-200;
  const [ wrapperVisible, setWrapperVisible ] = React.useState(visible);
  const [ fadeAnim ] = React.useState(new Animated.Value(rightBorder)); // Start from -200
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  React.useEffect(() => {
    const toValue = (visible && fadeAnim._value !== 0) ? 0 : (!visible && fadeAnim._value !== rightBorder) ? rightBorder : null;
    if (toValue !== null) Animated.timing(fadeAnim, { toValue, duration: 300 }).start(() => setWrapperVisible(visible));
  }, [visible]);

  const { height } = Dimensions.get('window');

  const IconButton = ({ text, children }) => (
    <View style={Style.buttonWrapper}>
      {height > 400 && children}
      <Text style={Style.buttonText}>{text}</Text>
    </View>
  );

  IconButton.propTypes = {
    children: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
  };

  const CloseButton = () => (
    <Button onPress={toggleSidebar} style={Style.button}>
      <IconButton text={'CHIUDI'}><CloseIcon size={25} /></IconButton>
    </Button>
  );

  const FaqButton = () => (
    <Link route={'faq'} replace={true} onPress={() => { toggleSidebar(); dispatch(HelperActions.sendCRMEvent('event_open_faq')); }} style={Style.link}>
      <View style={Style.button}>
        <IconButton text={'FAQ'}><FaqIcon size={30} /></IconButton>
      </View>
    </Link>
  );

  const AdvantagesButton = () => (
    <Link route={'advantages'} replace={true} onPress={() => { toggleSidebar(); dispatch(HelperActions.sendCRMEvent('event_open_benefits')); }} style={Style.link}>
      <View style={Style.button}>
        <IconButton text={'VANTAGGI'}><AdvantagesIcon size={30} /></IconButton>
      </View>
    </Link>
  );

  const LoginButton = () => {
    if (isDesktop()) {
      return (
        <Button onPress={() => { openLogin(); toggleSidebar(); }} style={Style.button}>
          <IconButton text={'LOGIN'}><LoginIcon size={30} /></IconButton>
        </Button>
      )
    }

    return (
      <Link route={Platform.OS === 'web' ? 'login' : 'welcome'} onPress={toggleSidebar} style={Style.link}>
        <View style={Style.button}>
          <IconButton text={'LOGIN'}><LoginIcon size={30} /></IconButton>
        </View>
      </Link>
    )
  };

  const ProfileButton = () => (
    <Link route={'profile'} replace={true} onPress={() => { toggleSidebar(); dispatch(HelperActions.sendCRMEvent('event_open_profile')); }} style={Style.link}>
      <View style={Style.button}>
        <IconButton text={'PROFILO'}><ProfileIcon size={30} /></IconButton>
      </View>
    </Link>
  );

  const LogoutButton = () => (
    <Link route={'logout'} replace={true} style={Style.link} onPress={() => toggleSidebar()}>
      <View style={Style.button}>
        <IconButton text={'LOGOUT'}><LogoutIcon size={30} /></IconButton>
      </View>
    </Link>
  );

  const HomeButton = () => (
    <Link route={'homepage'} replace={true} onPress={() => toggleSidebar()} style={Style.link}>
      <View style={Style.button}>
        <IconButton text={'HOME'}><TabHomeIcon size={30} color={Colors.white} /></IconButton>
      </View>
    </Link>
  );

  const Wrapper = ({ children }) => (
    Platform.OS === 'web'
      ? children
      : (<FlingGestureHandler direction={Directions.RIGHT} onHandlerStateChange={({ nativeEvent }) => { if (nativeEvent.state === State.ACTIVE) toggleSidebar(); }}>{children}</FlingGestureHandler>)
  );

  Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    (visible || wrapperVisible) && (
      <View style={Style.wrapper} onLayout={() => { setBreakpoint(ResponsiveHelper.getSize()); rightBorder = 0-getResponsive(false).container.width; }}>
        <Animated.View style={[Style.container, { right: fadeAnim }]}>
          <Wrapper>
            <LinearGradient colors={[Colors.blue4, Colors.darkBlue]} style={Style.gradient}>
              <SafeAreaView style={Style.content}>
                <View style={Style.innerContainer}>
                  {Platform.OS === 'web' && !isDesktop() && <HomeButton />}
                  {auth.logged ? <ProfileButton /> : <LoginButton />}
                  <FaqButton />
                  <AdvantagesButton />
                </View>
                <View style={Style.innerContainer}>
                  {auth.logged && <LogoutButton />}
                  <CloseButton />
                </View>
              </SafeAreaView>
            </LinearGradient>
          </Wrapper>
        </Animated.View>
      </View>
    )
  );
};

export default Sidebar;
