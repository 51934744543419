import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path } from 'react-native-svg';

import { Colors } from '../../Theme';

const BackIcon = ({ size=80, color=Colors.white }) => (
  <Svg width={size} height={size} viewBox={'0 0 64 64'}>
    <G stroke={'none'} fill={'none'} fillRule={'evenodd'} transform={'translate(5.000000, 0.000000)'}>
      <Path d={'M27,35.55 C36,35.55 54,40.5 54,49.5 C48.15,58.05 38.25,63.9 27,63.9 C15.75,63.9 5.85,58.0501765 3.55271368e-15,49.5 C3.55271368e-15,40.5 18,35.55 27,35.55 Z M27,-5.32907052e-15 C34.65,-5.32907052e-15 40.5,5.85 40.5,13.5 C40.5,21.15 34.65,27 27,27 C19.35,27 13.5,21.15 13.5,13.5 C13.5,5.85 19.35,-5.32907052e-15 27,-5.32907052e-15 Z'} fill={color} />
    </G>
  </Svg>
);

BackIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default BackIcon;
