import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-native";
import {
  ImageBackground,
  Image,
  SafeAreaView,
  ScrollView,
  Text,
  View,
} from "react-native";

import Style, { getResponsive } from "../BuyRuncard/BuyRuncardStyle";
import { BackOrCloseButton, Button } from "../../../Components/Buttons";
import { Registration, Payment } from "../";
import { BackIcon } from "../../../Theme/Icons";
import { Colors, Images } from "../../../Theme";
import { Footer, Modal } from "../../Layout";
import { PrivacyPolicyRuncard } from "../../Legals";

import ResponsiveHelper, {
  isDesktop,
} from "../../../Services/ResponsiveHelper";
import RegistrationActions from "../../../Stores/Registration/Actions";

const RenewRuncard = () => {
  let history = useHistory();
  const dispatch = useDispatch();
  let listView;

  const user = useSelector((state) => state.auth.user);
  const runcard = useSelector((state) => state.runcard.runcard);

  const [{}, setBreakpoint] = React.useState(ResponsiveHelper.getSize());

  const [step, setStep] = React.useState(1);
  const [privacyModal, setPrivacyModal] = React.useState(false);

  React.useEffect(() => {
    dispatch(RegistrationActions.registrationReset());
    setStep(1);
  }, []);

  const scrollTop = () => {
    if (listView) {
      listView.scrollTo({ y: 0 });
    }
  };

  React.useEffect(() => {
    if (listView) listView.scrollTo({ x: 0 });
  }, [step]);

  const backStep = () => {
    dispatch(RegistrationActions.registrationReset());
    setStep(step - 1);
  };

  const stepHeader = {
    1: null,
    2: <Text style={Style.headerText}>Pagamento</Text>,
  };

  const stepTitles = {
    1: (
      <Text style={Style.lead}>
        Aggiornamento dati{"\n"}
        <Text style={Style.leadBold}>
          <Text style={Style.leadBlue}>Runcard</Text>
        </Text>
      </Text>
    ),
    2: null,
  };

  const stepContent = {
    1: (
      <Registration
        foreign={user.country !== "IT"}
        runcardType={runcard.type}
        scrollTop={scrollTop}
        setPrivacyModal={setPrivacyModal}
        successHandler={() => setStep(2)}
        renew={true}
      />
    ),
    2: (
      <Payment
        runcardType={runcard.type}
        renew={true}
        foreign={user.country !== "IT"}
      />
    ),
  };

  const StepHeader = () =>
    !isDesktop() ? (
      <View style={Style.topContainer}>
        <ImageBackground
          source={Images.bg_buy}
          style={Style.background}
          resizeMode="cover"
        >
          <SafeAreaView style={Style.container}>
            <View style={Style.innerTopContainer}>
              {step === 1 && <BackOrCloseButton style={Style.backButton} />}
              {step === 2 && stepHeader[step]}
            </View>
            {step !== 2 && stepHeader[step]}
          </SafeAreaView>
        </ImageBackground>
      </View>
    ) : (
      <>
        <ImageBackground source={Images.bg_runcard} resizeMode="cover">
          <View style={Style.headerContainer}>
            <View style={Style.headerColumnLeft}>
              <Text style={Style.h1}>Rinnova{"\n"}la tua Runcard!</Text>
              <Text style={Style.h2}>
                Entra nella Community e accedi a tutte le gare
              </Text>
            </View>
            <View style={Style.headerColumnRight}>
              <Image
                source={Images.card_bg_full}
                style={Style.headerCardImage}
                resizeMode={"contain"}
              />
            </View>
          </View>
        </ImageBackground>
        <View style={getResponsive().backButtonWebContainer}>
          <View style={Style.backButtonWebWrapper}>
            <Button
              onPress={() => (step === 2 ? backStep() : history.goBack())}
              style={Style.backButtonWeb}
            >
              <BackIcon color={Colors.blue1} size={10} stroke={2} />
              <Text style={Style.backButtonWebText}>Indietro</Text>
            </Button>
          </View>
        </View>
      </>
    );

  return (
    <View
      onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}
      style={Style.container}
    >
      {step === 2 && <StepHeader />}
      <ScrollView ref={(ref) => (listView = ref)} bounces={false}>
        {step !== 2 && <StepHeader />}
        <SafeAreaView style={Style.container}>
          <View
            style={[Style.centerContainer, step === 2 ? Style.fullWidth : {}]}
          >
            <View style={getResponsive().innerContainer}>
              {stepTitles[step]}
              {stepContent[step]}
            </View>
          </View>
        </SafeAreaView>
      </ScrollView>
      {privacyModal && (
        <Modal
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => setPrivacyModal(false)}
        >
          <SafeAreaView style={Style.modal}>
            <View style={Style.closeContainer}>
              <Button onPress={() => setPrivacyModal(false)}>
                <BackIcon />
              </Button>
            </View>
            <View style={Style.modalContainer}>
              <PrivacyPolicyRuncard />
            </View>
          </SafeAreaView>
        </Modal>
      )}
      <Footer />
    </View>
  );
};

export default RenewRuncard;
