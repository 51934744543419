import { StyleSheet } from 'react-native';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

import { ApplicationStyles, Fonts, Colors } from '../../Theme';

export const BaseStyle = {
  avatarContainer: {
    marginBottom: 40,
  },
  container: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
    height:'100%',
  },
  centerContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 40,
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.big+8,
    marginBottom: 40,
    textAlign: 'center',
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    marginBottom: 80,
    textAlign: 'center',
  }
};

const StyleMedium = {
  innerContainer: {
    height: '100%',
    maxWidth:500,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });
export default StyleSheet.create(BaseStyle);
