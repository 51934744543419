import { ApplicationStyles } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const BaseStyle = {
  container: {
    flexGrow: 1,
  },
  wrapper: {
    flexGrow: 1,
  },
};

const StyleLG = {
  container: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
