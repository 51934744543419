import React from 'react';
import PropTypes from 'prop-types';
import { View, ImageBackground } from 'react-native';

import { getResponsive } from './TabNavStyle';

import { Images } from '../../../Theme';
import { isDesktop } from '../../../Services/ResponsiveHelper';
import TabNavButtons from './TabNavButtons';

const TabNav = ({ size = 'regular' }) => {
  const Style = getResponsive();

  return (
    <View style={Style.wrapper}>
      {!isDesktop() ? (
        <>
          <ImageBackground fadeDuration={0} source={Images.bg_tab_repeat} style={Style.background} resizeMode='stretch' />
          <View style={Style.buttonContainerRuncard}>
            <ImageBackground fadeDuration={0} source={Images.bg_tab} style={Style.backgroundRuncard} />
          </View>
          <ImageBackground fadeDuration={0} source={Images.bg_tab_repeat} style={Style.background} resizeMode='stretch' />
          <TabNavButtons size={size} background={true} />
        </>
      ) : <TabNavButtons size={size} background={false} />}
    </View>
  );
};

TabNav.propTypes = {
  size: PropTypes.oneOf(['big', 'regular']),
};

export default TabNav;
