import { put, call } from "redux-saga/effects";

import RuncardActions from "../Stores/Runcard/Actions";
import LayoutActions from "../Stores/Layout/Actions";
import {
  fetchRuncard,
  linkRuncard as linkRuncardApi,
  generateRuncardBarcode,
} from "../Services/ApiGatewayService";
import AuthActions from "../Stores/Auth/Actions";

export function* getRuncard({ loader = true, invalidate_cache = false }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  yield put(RuncardActions.getRuncardError(null));
  try {
    const runcard = yield call(fetchRuncard, { invalidate_cache });
    yield put(RuncardActions.setRuncard(runcard));
  } catch (err) {
    console.log("getRuncard ERROR", err);
    yield put(RuncardActions.getRuncardError(err));
    yield put(RuncardActions.setRuncard(null));
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}

const mapLinkRuncardErrors = {
  RuncardNotFound: "Runcard non trovata. Inserisci nuovamente il codice.",
  CannotLinkRuncard:
    "Non è stato possibile associare questa Runcard al tuo account",
  RuncardAlreadyLinked: "Questa runcard è già associata ad un account",
};

export function* linkRuncard(data) {
  data.code = data.code.toUpperCase();
  if (!data.code.match(/^RR[\d]{6}$/gi)) {
    yield put(
      RuncardActions.linkRuncardError(
        "Il codice runcard deve essere di tipo RR******"
      )
    );
    return;
  }
  yield put(LayoutActions.setLoading(true));
  try {
    const user = yield call(linkRuncardApi, data);
    yield put(AuthActions.setUserLogged(user));
    yield put(RuncardActions.linkRuncardSuccess());
  } catch (err) {
    console.log("Link runcard ERROR", err);
    let error = "";
    if (err.response && err.response.data && err.response.data.code) {
      error =
        typeof mapLinkRuncardErrors[err.response.data.code] !== "undefined"
          ? mapLinkRuncardErrors[err.response.data.code]
          : "";
    }
    yield put(RuncardActions.linkRuncardError(error));
  }
  yield put(LayoutActions.setLoading(false));
}

export function* getRuncardBarcode({ code }) {
  try {
    const barcode = yield call(generateRuncardBarcode, { code });
    yield put(RuncardActions.setRuncardBarcode(barcode.base64Data));
  } catch (error) {
    console.log("getRuncardBarcode ERROR", error);
    yield put(
      RuncardActions.setRuncardBarcodeError(error.message || error.toString())
    );
  }
}
