import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-native';
import { Config } from '../../../Config';

import { Button, Link } from '../';
import { BackIcon, CloseIcon } from '../../../Theme/Icons';
import { Colors } from '../../../Theme';

const BackOrCloseButton = ({ style, route=false }) => {
  let history = useHistory();

  return (
    ((history.hasOwnProperty('canGo') && history.canGo(-1)) || (!history.hasOwnProperty('canGo') && history.length>1)) ? (
      <Button onPress={() => history.goBack()} style={style}>
        <BackIcon color={Colors.grayLight} />
      </Button>
    ) : (
      <Link onPress={() => { history.entries = []; history.index = -1; }} route={route ? route : Config.APP_HOMEPAGE} style={style}>
        <CloseIcon color={Colors.grayLight} />
      </Link>
    )
  )
};

BackOrCloseButton.propTypes = {
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
  route: PropTypes.string,
};

export default BackOrCloseButton;
