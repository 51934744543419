import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setGoogleFit: ['status'],
  setStrava: ['status'],
  setSalute: ['status'],
  setStravaData: ['access_token', 'refresh_token', 'expires_at', 'athlete_id'],
  setStravaAuthError: ['error'],
  stravaAuthenticate: ['url'],
  stravaDisconnect: null,
});

export const ServicesTypes = Types;
export default Creators;
