import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Image, ScrollView, Text, View, StyleSheet } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import Slider from 'react-slick';
import { Config } from '../../Config';

import { getResponsive } from './HomepageStyle';
import { Colors, Images } from '../../Theme';
import { RightArrow, LeftArrow } from '../../Theme/Icons';
import { Footer, Header, LoaderInline } from '../../Components/Layout';
import { Link, PrimaryButton, SecondaryButton } from '../../Components/Buttons';
import Tutorial from '../../Components/Tutorial/Tutorial';
import Advantages from '../../Components/Advantages/Advantages';
import { HomepageNews } from '../../Components/News';

import LayoutActions from '../../Stores/Layout/Actions';
import Analytics from '../../Services/AnalyticsService';
import { isDesktop } from '../../Services/ResponsiveHelper';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Homepage = () => {
  const Style = getResponsive();
  const dispatch = useDispatch();

  const options = useSelector(state => state.helper.options);
  const { news, newsLoaded } = useSelector(state => state.news);

  const [ tutorial, setTutorial ] = React.useState(false);
  const [ latestNews, setLatestNews ] = React.useState([]);
  const [ partners, setPartners ] = React.useState({});

  React.useEffect(() => {
    if (options && options.Partners) {
      let partners = options.Partners.reduce((acc, partner) => {
        if (!acc[partner.category]) acc[partner.category] = [];
        acc[partner.category].push(partner);
        return acc;
      }, {});
      Object.keys(partners).forEach(category => {
        partners[category].sort((a, b) => a.order - b.order);
      });
      setPartners(partners);
    }
  }, [ options ]);

  React.useEffect(() => {
    Analytics.getInstance().trackHomepageView();
    dispatch(LayoutActions.setTab('HOMEPAGE'));
  }, []);

  const Arrow = ({ className, style, onClick, type }) => {
    return onClick ? (
      <a className={`${className ? className : ''}`} href={'#'} style={{ ...style }} onClick={onClick}>
        {type === 'prev' ? <LeftArrow color={Colors.blue1} /> : <RightArrow color={Colors.blue1} />}
      </a>
    ) : null;
  };

  Arrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
    type: PropTypes.string,
    onClick: PropTypes.func,
  };

  const sliderSettings = {
    className: 'slider variable-width slider-homepage',
    dots: true,
    arrow: true,
    infinite: true,
    centerMode: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: false,
    nextArrow: <Arrow type={'next'} />,
    prevArrow: <Arrow type={'prev'} />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
          infinite: false,
        }
      }
    ]
  };

  React.useEffect(() => {
    if (newsLoaded && news) {
      let latestNews = [];
      Object.keys(Config.NEWS.CATEGORIES).forEach(category => { if (category !== 'TopNews' && news[category] && news[category].length) latestNews = latestNews.concat(news[category]); });
      latestNews.sort((a, b) => b.date - a.date);
      latestNews = latestNews.slice(0, 2);
      setLatestNews(latestNews);
    }
  }, [ newsLoaded ]);

  return (
    <>
      <ScrollView bounces={false} contentContainerStyle={Style.background}>
        <Header isHome={true} />
        {(!newsLoaded || newsLoaded && latestNews.length)
          ? (
            <LinearGradient colors={[isDesktop() ? Colors.darkBlue : Colors.grayUltraFantaLight, isDesktop() ? Colors.blue4 : Colors.grayUltraFantaLight]} locations={[0,1]} style={Style.newsGradient}>
              <View style={Style.container}>
                <Text style={Style.newsTitle}>Articoli recenti</Text>
              </View>
              {latestNews.length
                ? latestNews.map((news, key) => <HomepageNews news={news} key={key} side={key%2===0 ? 'right' : 'left'} />)
                : <LoaderInline style={Style.newsLoader} />
              }
              <View style={Style.newsButtonContainer}>
                <View>
                  <PrimaryButton route={'news'} textStyle={Style.newsButton}>SCOPRI DI PIÙ</PrimaryButton>
                </View>
              </View>
            </LinearGradient>
          ) : (
            <LinearGradient colors={[isDesktop() ? Colors.darkBlue : Colors.grayUltraFantaLight, isDesktop() ? Colors.blue4 : Colors.grayUltraFantaLight]} locations={[0,1]} style={Style.newsGradient}>
              <View style={Style.newsButtonContainer}>
              </View>
            </LinearGradient>
          )}
        <View style={Style.whyContainer}>
          {!isDesktop()
            ? (
              <LinearGradient colors={[Colors.darkBlue, Colors.blue99999]} locations={[0,1]} style={Style.whyGradient}>
                <View style={Style.container}>
                  <Text style={Style.whyTitle}>Perché scegliere Runcard?</Text>
                </View>
              </LinearGradient>
            )
            : (
              <View style={Style.container}>
                <Text style={Style.whyTitle}>Perché scegliere Runcard?</Text>
              </View>
            )
          }
          <View style={[Style.container, Style.whyColumns]}>
            <View style={Style.whyLeftColumn}>
              <Text style={Style.whyText1}>Runcard è l’innovativo progetto di FIDAL per riunire tutte le persone che corrono e condividono il movimento come passione e vero e proprio stile di vita</Text>
              <Text style={Style.whyText2}>
                Siamo noi stessi sportivi praticanti a darti il miglior supporto possibile per farti conseguire il risultato che ti sei prefisso. In questa ottica abbiamo riunito i migliori talenti provenienti da tutto il mondo della corsa.
                Tutti gli esperti Runcard sono essi stessi sportivi convinti e sanno che un costante esercizio fisico, abbinato ad un corretto regime alimentare, sono la base per il miglioramento del proprio stile di vita.
              </Text>
            </View>
            <View style={Style.whyRightColumn}>
              <img src={'/static/images/infografica-home-runcard.png'} style={Style.whyInfografica} alt={'Infografica'} />
            </View>
          </View>
          <SecondaryButton style={Style.whyButton} onPress={() => setTutorial(true)}>GUARDA IL TUTORIAL</SecondaryButton>
        </View>
        <View style={Style.vantaggiContainer}>
          <View style={Style.whyShadow} />
          <View style={Style.container}>
            <Advantages />
          </View>
        </View>
        <View style={Style.appContainer}>
          <LinearGradient colors={[Colors.darkBlue, Colors.blue99999]} locations={[0,1]} style={Style.appGradient}>
            {!isDesktop() && <img src={'/static/images/mockup_iphone.png'} style={Style.appImage} alt={''} />}
            <View style={[Style.container, Style.appColumns]}>
              <View style={Style.appLeftColumn}>
                <Text style={Style.appTitle}>Scarica l&apos;app Runcard ed entra a far parte della community!</Text>
                <Text style={Style.appText}>Sei pronto a rivoluzionare il tuo modo di vivere il running? Scopri i servizi innovativi, cerca le gare più vicine a te, scala la classifica e vinci fantastici premi.{'\n'}Questo e molto altro nell&apos;app Runcard!</Text>
                <View style={Style.appButtons}>
                  <Link href={'//apps.apple.com/it/app/my-runcard/id1474102111'} style={isDesktop() ? Style.marginRight : Style.marginBottom5}><Image source={Images.button_appstore} style={Style.appButton} /></Link>
                  <Link href={'//play.google.com/store/apps/details?id=com.infront.runcard&hl=it'}><Image source={Images.button_googleplay} style={Style.appButton} /></Link>
                </View>
              </View>
              {isDesktop() && (
                <View style={Style.appRightColumn}>
                  <Image source={{ uri: '/static/images/mockup_iphone.png' }} style={Style.appImage} resizeMode={'contain'} />
                </View>
              )}
            </View>
          </LinearGradient>
        </View>
        {Config.PARTNERS.map((section, key) => (
          partners && partners[section.category] && partners[section.category].length && (
            <View style={Style.partnerContainer} key={key}>
              <View style={Style.container}>
                <Text style={Style.partnerTitle}>{section.label}</Text>
              </View>
              <View style={Style.partnerSliderContainer}>
                <Slider {...sliderSettings} infinite={partners[section.category].length>4}>
                  {partners[section.category].map((el, key) => <a key={key} style={StyleSheet.flatten(Style.partnerItem)} href={el.link} target={'_blank'} rel="noopener noreferrer"><img src={el.image} alt={'Partner'} style={StyleSheet.flatten(Style.partnerImage)} /></a>)}
                </Slider>
              </View>
            </View>
          )
        ))}
        <Footer />
      </ScrollView>
      {tutorial && (
        <Tutorial onClose={() => setTutorial(false)} onEnd={() => setTutorial(false)} button={'CHIUDI'} />
      )}
    </>
  );
};

export default Homepage;
