import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getProvinces: ["loader"],
  setProvinces: ["provinces"],
  getCountries: null,
  setCountries: ["countries"],
  getFaq: null,
  setFaq: ["faq"],
  setOptions: ["options"],
  setServices: ["services"],
  getConventions: ["cap"],
  setConventions: ["conventions"],
  getServiceEncodedToken: ["service_id"],
  setServicesEncodedTokens: ["data"],
  setServiceTokenEncodedError: ["error"],
  sendCRMEvent: ["event"],
  sendLeaderboardEvent: ["event", "count"],
  generatePdf: ["pdf"],
  getLeaderboardLevel: [],
  setLeaderboardLevel: ["level"],
});

export const HelperTypes = Types;
export default Creators;
