import { StyleSheet } from 'react-native';

import { ApplicationStyles, Colors, Fonts } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

export const BaseStyle = {
  container: {
    flexGrow: 1,
  },
  backButton: {
    padding: 20,
    width: 60
  },
  containerCenter: {
    justifyContent: 'center',
    paddingHorizontal: 40,
    paddingVertical: 30,
    flex: 1,
  },
  containerBottom: {
    paddingBottom: 30,
    justifyContent: 'flex-end',
  },
  containerWeb: {
    alignSelf: 'center',
    marginBottom: 30,
    marginTop: 30,
    maxWidth: 450,
    minWidth: 400,
    flex: 1,
  },
  flexContainer: {
    flexGrow: 1,
  },
  innerContainer: {
    flexGrow: 1,
  },
  login: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.blue1,
  },
  loginLink: {
    paddingBottom: 20,
    paddingTop: 10,
    textAlign: 'center',
  },
  loginText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    textAlign: 'center',
  },
  title: {
    ...ApplicationStyles.screen.h1,
    color: Colors.white,
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 0,
  },
  text: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.medium-2,
    textAlign: 'center',
    marginBottom: 30,
  },
};

const StyleMedium = {
  innerContainer: {
    height: '100%',
    maxWidth:500,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });
export default StyleSheet.create(BaseStyle);
