import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { View, Text, Platform } from 'react-native';
import { Switch, Route } from 'react-router-native';
import moment from 'moment';

import { getResponsive } from './RacesStyle';
import { ApplicationStyles } from '../../Theme';
import { BackIcon, FilterIcon } from '../../Theme/Icons';
import { Adv, Footer, PageHeader, PageSidebar } from '../../Components/Layout';
import { Button } from '../../Components/Buttons';
import { List, Filters } from '../../Components/Races';

import LayoutActions from '../../Stores/Layout/Actions';
import RacesActions from '../../Stores/Races/Actions';
import { isDesktop } from '../../Services/ResponsiveHelper';
import Analytics from '../../Services/AnalyticsService';
import { getAdv } from '../../Services/ADVService';
import { RouteWithSubRoutes } from "../../Router/Router";

const defaults = {
  from: moment().startOf('day'),
  to: moment().startOf('day').add(1, 'month'),
  calendar: 'NAZ',
  race_level: 'ALL',
  race_type: 'ALL',
  region: 'ALL',
  province: 'ALL',
};

const Races = ({ routes }) => {
  const Style = getResponsive();
  const dispatch = useDispatch();

  const { races, races_error: error } = useSelector(state => state.races);

  // ADV
  const options = useSelector(state => state.helper.options);
  const adv = Platform.OS !== 'web' ? getAdv(options.Advertising, 'footer_banner', 'gare') : null;

  const [ filter, toggleFilter ] = React.useState(false);
  const [ filtersEnabled, setFiltersEnabled ] = React.useState(false);

  // Filters
  const [ from, setFrom ] = React.useState(defaults.from);
  const [ to, setTo ] = React.useState(defaults.to);
  const [ calendar, setCalendar ] = React.useState(defaults.calendar);
  const [ region, setRegion ] = React.useState(defaults.region);
  const [ province, setProvince ] = React.useState(defaults.province);
  const [ raceLevel, setRaceLevel ] = React.useState(defaults.race_level);
  const [ raceType, setRaceType ] = React.useState(defaults.race_type);

  React.useEffect(() => {
    Analytics.getInstance().trackMatchesView();
    dispatch(LayoutActions.setTab('RACES'));
    if (!races && !error) {
      dispatch(RacesActions.getRaces(false, { from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD'), calendar, race_level: raceLevel, race_type: raceType, region, province }));
    }
  }, []);

  React.useEffect(() => {
    const values = { from, to, calendar, race_type: raceType, race_level: raceLevel, region, province };
    setFiltersEnabled(Object.keys(values).findIndex(key => defaults[key].toString() !== values[key].toString()) !== -1);
  }, [ from, to, calendar, region, province, raceLevel, raceType ]);

  const resetFilters = () => {
    setFrom(defaults.from);
    setTo(defaults.to);
    setCalendar(defaults.calendar);
    setRegion(defaults.region);
    setProvince(defaults.province);
    setRaceLevel(defaults.race_level);
    setRaceType(defaults.race_type);
    dispatch(RacesActions.setRaces(null));
    dispatch(RacesActions.getRaces(false, {
      from: defaults.from.format('YYYY-MM-DD'),
      to: defaults.to.format('YYYY-MM-DD'),
      calendar: defaults.calendar,
      race_level: defaults.race_level,
      race_type: defaults.race_type,
      region: defaults.region,
      province: defaults.province,
    }));
    toggleFilter(false);
  };

  const applyFilters = (filters) => {
    setFrom(filters.from);
    setTo(filters.to);
    setCalendar(filters.calendar);
    setRegion(filters.region);
    setProvince(filters.province);
    setRaceLevel(filters.race_level);
    setRaceType(filters.race_type);
    dispatch(RacesActions.setRaces(null));
    dispatch(RacesActions.getRaces(false, {
      from: filters.from.format('YYYY-MM-DD'),
      to: filters.to.format('YYYY-MM-DD'),
      calendar: filters.calendar,
      race_level: filters.race_level,
      race_type: filters.race_type,
      region: filters.region,
      province: filters.province,
    }));
    toggleFilter(false);
  };

  const Wrapper = ({ children }) => (
    <View style={Style.webContainer}>
      {isDesktop() ? (
        <View style={Style.columns}>
          <View style={Style.left}>
            {children}
          </View>
          <PageSidebar section={'gare'} />
        </View>
      ) : children}
    </View>
  );

  Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <View style={{ ...ApplicationStyles.screen.container }}>
      <Switch>
        {routes.map(route => (<RouteWithSubRoutes key={route.name} {...route} />))}
        <Route path='*'>
          <View style={Style.wrapper}>
            <>
              {!filter || Platform.OS === 'web'
                ? (
                  <PageHeader
                    title={'Gare'}
                    desc={'Cerca l\'evento che ti interessa'}
                    back={false}
                    left={
                      <Button onPress={() => toggleFilter(!filter)} style={Style.filterIcon}>
                        <FilterIcon enabled={filtersEnabled || (isDesktop() && filter)}/>
                      </Button>
                    }
                  />
                ) : (
                  <PageHeader
                    title={'Filtri'}
                    back={false}
                    left={
                      <Button onPress={() => toggleFilter(false)}>
                        <BackIcon/>
                      </Button>
                    }
                  />
              )}
              <Wrapper>
                {!filter || isDesktop()
                  ? error
                    ? <View style={Style.wrapper}><Text style={Style.error}>Non è stato possibile recuperare le gare.</Text></View>
                    : <List races={races} adv={(adv && !isDesktop())} />
                  : (
                    <View style={Style.wrapper}>
                      <Filters resetFilters={resetFilters} applyFilters={applyFilters} filters={{ from, to, calendar, race_level: raceLevel, race_type: raceType, region, province }} />
                    </View>
                )}
                {filter && isDesktop() && (
                  <View style={Style.floatingFilters}>
                    <Filters resetFilters={resetFilters} applyFilters={applyFilters} filters={{ from, to, calendar, race_level: raceLevel, race_type: raceType, region, province }} />
                  </View>
                )}
              </Wrapper>
            </>
            <Footer adv={!filter} section={'gare'} />
          </View>
          {adv && !isDesktop() && !filter && <View style={ApplicationStyles.screen.footerBanner}><Adv adv={adv} /></View>}
        </Route>
      </Switch>
    </View>
  );
};

Races.propTypes = {
  routes: PropTypes.array,
};

export default Races;
export const defaultFilters = defaults;
