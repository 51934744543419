import ResponsiveHelper from '../../../../Services/ResponsiveHelper';
import { ApplicationStyles, Colors, Fonts } from '../../../../Theme';

const BaseStyle = {
  inner: {},
  logo: {
    width: 70,
    height: 65,
    marginHorizontal: 'auto',
    marginTop: -65,
  },
  title: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.cattolicaRed,
    fontSize: Fonts.size.regular+1,
    marginBottom: 5,
    textAlign: 'center',
  },
  price: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.cattolicaRed,
    fontSize: Fonts.size.big+30,
    marginBottom: 15,
    textAlign: 'center',
  },
  recapRow: {
    marginBottom: 20,
  },
  recapTitle: {
    ...ApplicationStyles.screen.fontsRegular,
    fontSize: Fonts.size.regular-2,
  },
  recapText: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+3,
  },
  wrapper: {},
};

const StyleLG = {
  wrapper: {
    backgroundColor: Colors.white,
    marginTop: 60,
    marginHorizontal: 30,
    padding: 30,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
  },
  inner: {
    width: '100%',
    maxWidth: 500,
    marginHorizontal: 'auto',
    marginVertical: 50,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
