import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  button: {
    width: 'auto',
  },
  buttonContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  buttonContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonText: {
    ...Fonts.style.normal,
  },
  cornerLeft: { },
  cornerRight: { },
  imageContainer: {
    flex: 1,
  },
  image: {
    width: '100%',
  },
  news: {
    ...ApplicationStyles.screen.webContainer,
    backgroundColor: Colors.white,
    paddingLeft: 0,
    paddingRight: 0,
    paddingVertical: 0,
  },
  newsContainer: {
    width: '100%',
    marginBottom: 40,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 4,
  },
  newsContentContainer: {
    margin: 20,
  },
  newsDate: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.regular-2,
    paddingBottom: 5,
  },
  newsSummary: {
    ...ApplicationStyles.screen.fontsLight,
    marginBottom: 20,
  },
  newsTitle: {
    ...ApplicationStyles.screen.h3,
    textTransform: 'uppercase',
  },
  whiteSpace: { },
  whiteSpaceLeft: { },
  whiteSpaceRight: { },
};

const StyleLG = {
  cornerLeft: {
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
  cornerRight: {
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
  },
  imageContainer: {
    margin: 20,
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: 20,
  },
  newsContainer: {
    shadowOpacity: 0,
  },
  news: {
    flexDirection: 'row',
    flex: 1,
  },
  newsContentContainer: {
    flex: 1,
    marginHorizontal: 0,
  },
  whiteSpace: {
    backgroundColor: Colors.white,
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
  },
  whiteSpaceLeft: {
    left: 0,
    right: '50%',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
  },
  whiteSpaceRight: {
    left: '50%',
    right: 0,
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
