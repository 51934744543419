import React from 'react';
import PropTypes from 'prop-types';
import { Config } from '../../../Config';

import CustomWebView from '../../Layout/WebView/WebView';

const CookiePolicy = ({ padding=false }) => {
  const [ webViewHeight, setWebViewHeight ] = React.useState(100);

  const handleMessage = (event) => {
    if (event && event.action && event.action === 'resize') {
      setWebViewHeight(event.height);
    }
  };

  return (
    <CustomWebView style={{ height: webViewHeight }} onMessage={handleMessage} url={`${Config.WEBSITE_URL}/static/legals/cookie_policy.html`} padding={padding} />
  );
};

CookiePolicy.propTypes = {
  padding: PropTypes.bool,
};

export default CookiePolicy;
