import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList, Platform, ImageBackground } from 'react-native';
import { generatePath } from 'react-router-native';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-native';
import moment from 'moment';
import { Config } from '../../../../Config';

import { getResponsive } from './UserStyle';
import { Colors, Images } from '../../../../Theme';
import { PlusIcon, RightArrow, Circle, BackIcon } from '../../../../Theme/Icons';
import { Footer, LoaderInline, PageHeader } from '../../../Layout';
import { Button } from '../../../Buttons';

import ChallengesActions from '../../../../Stores/Challenges/Actions';
import { getPath } from '../../../../Router/AppRoutes';
import { isDesktop } from '../../../../Services/ResponsiveHelper';

const UserList = () => {
  let history = useHistory();
  const Style = getResponsive();
  const dispatch = useDispatch();

  const data = useSelector(state => state.challenges.user_challenges);
  const error = useSelector(state => state.challenges.user_error_challenges);
  const canLoadMore = useSelector(state => state.challenges.user_loadmore_challenges);

  const [ loading, setLoading ] = React.useState(false);
  const [ refreshing, setRefreshing ] = React.useState(false);
  const [ onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum ] = React.useState(true);

  React.useEffect(() => {
    if (!data && !error) {
      setLoading(true);
      dispatch(ChallengesActions.getUserChallenges(false));
    }
  });

  React.useEffect(() => {
    setRefreshing(false);
    if (data !== null && loading) {
      setLoading(false);
    }
  }, [data]);

  const handleRefresh = () => {
    setRefreshing(true);
    dispatch(ChallengesActions.getUserChallenges(false));
  };

  const loadMore = () => {
    if ((!onEndReachedCalledDuringMomentum || Platform.OS === 'web') && !loading) {
      setLoading(true);
      dispatch(ChallengesActions.getUserChallenges(false, data[data.length-1].id));
      setOnEndReachedCalledDuringMomentum(true);
    }
  };

  const Challenge = ({ item }) => {
    const past = !moment(item.end_date, 'YYYYMMDD').isAfter(moment());
    return (
      <Button style={Style.listItem} route={generatePath(getPath('challengeUser'), { id: item.id })}>
        <ImageBackground source={!past ? Images.goal : Images.goal_past} style={Style.kmImage}>
          <Text style={Style.topKmTextList}>{item.km}km</Text>
        </ImageBackground>
        <View style={Style.listItemTextContainer}>
          <View>
            <Text style={Style.listItemTextTitle}>{item.title}</Text>
            <Text style={Style.listItemTextDesc}>{past ? 'Terminato' : `Termina tra ${moment(item.end_date, 'YYYYMMDD').diff(moment(), 'days')} giorni`}</Text>
          </View>
          <RightArrow size={isDesktop() ? 20 : 10} />
        </View>
      </Button>
    );
  };

  Challenge.propTypes = {
    item: PropTypes.object,
  };

  const count = data ? data.filter(el => moment(el.end_date, 'YYYYMMDD').isSameOrAfter(moment())).length : 0;

  return (
    <View style={Style.container}>
      {isDesktop() ? (
        <PageHeader title={'Sfide'} desc={'Supera i tuoi limiti'} right={Config.MAX_GOALS > count ? <Button route={'challengeNew'}><PlusIcon size={35} /></Button> : null} />
      ) : (
        <PageHeader
          title={'I tuoi obiettivi'}
          right={Config.MAX_GOALS > count ? <Button route={'challengeNew'}><PlusIcon size={35} /></Button> : null}
          left={(!history.hasOwnProperty('canGo') || !history.canGo(-1)) && history.length <= 1 ? <Button onPress={() => history.push(getPath('challenges'))}><BackIcon /></Button> : null}
        />
      )}
      <View style={Style.wrapper}>
        <View style={Style.webContainer}>
          {isDesktop() && <Text style={Style.pageTitle}>I TUOI OBIETTIVI</Text>}
          {!error
            ? data !== null
            ? <FlatList
                style={Style.wrapper}
                data={data}
                refreshing={refreshing}
                onRefresh={handleRefresh}
                renderItem={({ item }) => <Challenge item={item} />}
                keyExtractor={item => item.id}
                initialNumToRender={5}
                numColumns={isDesktop() ? 2 : 1}
                onEndReached={() => canLoadMore ? loadMore() : () => {}}
                onEndReachedThreshold={0.5}
                onMomentumScrollBegin={() => setOnEndReachedCalledDuringMomentum(false) }
                onMomentumScrollEnd={() => setOnEndReachedCalledDuringMomentum(true) }
                ListEmptyComponent={data !== null ? <Text style={Style.error}>Non hai creato nessun obiettivo</Text> : null}
                ListFooterComponent={
                  <View style={Style.listFooter}>
                    {loading
                      ? <LoaderInline style={{}} />
                      : !canLoadMore && data.length
                        ? <><Circle size={6} color={Colors.grayLight} /><Text style={Style.endReachedText}>Non ci sono altri obiettivi</Text></>
                        : null
                    }
                  </View>}
              />
              : <LoaderInline style={{}} />
            : <Text style={Style.error}>Non è stato possibile recuperare gli obiettivi</Text>
          }
        </View>
      </View>
      <Footer />
    </View>
  );
};

export default UserList;
