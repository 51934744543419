import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  icon: {
    marginLeft: 10,
    marginTop: 10,
  },
  itemBorder: {
    borderBottomWidth: 1,
    borderColor: Colors.grayALittleLight,
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    flex: 1,
    fontSize: Fonts.size.regular-1,
    paddingBottom: 10,
    paddingTop: 20,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  wrapper: {
    flex: 1,
  },
});
