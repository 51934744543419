import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polyline } from 'react-native-svg';

import { Colors } from '../../Theme';

const BackIcon = ({ size=20, color=Colors.grayLight, stroke=0.9 }) => (
  <Svg width={size} height={size} viewBox={'0 0 19 19'}>
    <G stroke={'none'} fill={'none'} fillRule={'evenodd'}>
      <Polyline points={'9 18 0 9.5 9 1'} stroke={color} strokeWidth={stroke} />
    </G>
  </Svg>
);

BackIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  stroke: PropTypes.number,
};

export default BackIcon;
