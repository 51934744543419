import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList, Image, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { generatePath } from 'react-router-native';
import { useHistory } from 'react-router-native';
import moment from 'moment';

import { getResponsive } from './EditorialStyle';
import { Colors } from '../../../../Theme';
import { Circle, CheckIconRound, RightArrow, BackIcon } from '../../../../Theme/Icons';
import { Footer, LoaderInline, PageHeader } from '../../../Layout'
import { Button } from '../../../Buttons';

import ChallengesActions from '../../../../Stores/Challenges/Actions';
import { getPath } from '../../../../Router/AppRoutes';
import { isDesktop } from '../../../../Services/ResponsiveHelper';

const EditorialList = () => {
  let history = useHistory();
  const Style = getResponsive();

  const dispatch = useDispatch();

  const data = useSelector(state => state.challenges.editorial_challenges);
  const error = useSelector(state => state.challenges.editorial_error_challenges);
  const canLoadMore = useSelector(state => state.challenges.editorial_loadmore_challenges);

  const [ loading, setLoading ] = React.useState(false);
  const [ refreshing, setRefreshing ] = React.useState(false);
  const [ onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum ] = React.useState(true);

  React.useEffect(() => {
    if (!data && !error) {
      setLoading(true);
      dispatch(ChallengesActions.getEditorialChallenges(false));
    }
  }, []);

  React.useEffect(() => {
    setRefreshing(false);
    if (data !== null && loading) {
      setLoading(false);
    }
  }, [data]);

  const handleRefresh = () => {
    setRefreshing(true);
    dispatch(ChallengesActions.getEditorialChallenges(false));
  };

  const loadMore = () => {
    if ((!onEndReachedCalledDuringMomentum || Platform.OS === 'web') && !loading) {
      setLoading(true);
      dispatch(ChallengesActions.getEditorialChallenges(false, data[data.length-1].id));
      setOnEndReachedCalledDuringMomentum(true);
    }
  };

  const Challenge = ({ item }) => {
    const past = !moment(item.end_date, 'YYYYMMDD').isAfter(moment());
    return (
      <Button style={Style.listItem} route={generatePath(getPath('challengeEditorial'), { id: item.id })}>
        <Image source={{ uri: item.image }} style={Style.listItemImage} resizeMode={'cover'}/>
        <View style={Style.listItemTextContainer}>
          <View>
            <Text style={Style.listItemTextTitle}>{item.title}</Text>
            <Text style={Style.listItemTextDesc}>{past ? 'Terminata' : `Termina tra ${moment(item.end_date, 'YYYYMMDD').diff(moment(), 'days')} giorni`}</Text>
          </View>
          <RightArrow size={isDesktop() ? 20 : 10} />
        </View>
        {item.joined && <View style={Style.joinedIcon}><CheckIconRound/></View>}
      </Button>
    );
  };

  Challenge.propTypes = {
    item: PropTypes.object,
  };

  return (
    <View style={Style.container}>
      {isDesktop() ? (
        <PageHeader title={'Sfide'} desc={'Supera i tuoi limiti'} />
      ) : (
        <PageHeader
          title={'Top sfide'}
          left={(!history.hasOwnProperty('canGo') || !history.canGo(-1)) && history.length <= 1 ? <Button onPress={() => history.push(getPath('challenges'))}><BackIcon /></Button> : null}
        />
      )}
      <View style={Style.wrapper}>
        <View style={Style.webContainer}>
          {isDesktop() && <Text style={Style.pageTitle}>TOP SFIDE</Text>}
          {!error
            ? data !== null
              ? <FlatList
                  style={Style.wrapper}
                  data={data}
                  refreshing={refreshing}
                  onRefresh={handleRefresh}
                  renderItem={({ item }) => <Challenge item={item} />}
                  keyExtractor={item => item.id}
                  initialNumToRender={5}
                  numColumns={isDesktop() ? 2 : 1}
                  onEndReached={() => canLoadMore ? loadMore() : () => {}}
                  onEndReachedThreshold={0.5}
                  onMomentumScrollBegin={() => setOnEndReachedCalledDuringMomentum(false) }
                  onMomentumScrollEnd={() => setOnEndReachedCalledDuringMomentum(true) }
                  ListEmptyComponent={data !== null ? <Text style={Style.error}>Non c&apos;è nessuna sfida attiva al momento</Text> : null}
                  ListFooterComponent={
                    <View style={Style.listFooter}>
                      {loading
                        ? <LoaderInline style={{}} />
                        : !canLoadMore && data.length
                          ? <><Circle size={6} color={Colors.grayLight} /><Text style={Style.endReachedText}>Non ci sono altre sfide attive</Text></>
                          : null
                      }
                    </View>}
                />
              : <LoaderInline style={{}} />
            : <Text style={Style.error}>Non è stato possibile recuperare le sfide</Text>
          }
        </View>
      </View>
      <Footer />
    </View>
  );
};

export default EditorialList;
