import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Circle, Polygon, Stop, LinearGradient, Defs } from 'react-native-svg';

const CheckIconRound = ({ size=20 }) => (
  <Svg width={size} height={size} viewBox={'0 0 23 23'}>
    <Defs>
      <LinearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'RoundCheckIcon-Gradient'}>
        <Stop stopColor={'#02AFF1'} offset={'0%'} />
        <Stop stopColor={'#0D85F1'} offset={'100%'} />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <G fill={'url(#RoundCheckIcon-Gradient)'}>
        <Circle cx={'11.34'} cy={'11.34'} r={'11.34'} />
      </G>
      <G transform={'translate(4.860000, 6.885000)'} fill={'#FFFFFF'} fillRule={'nonzero'}>
        <Polygon points={'12.6681267 0 4.80367208 7.91269289 0.696873282 3.78073579 0 4.48188934 4.80367208 9.315 13.365 0.701153555'} />
      </G>
      <Circle stroke={'#FFFFFF'} strokeWidth={'0.5'} cx={'11.34'} cy={'11.34'} r={'11.34'} />
    </G>
  </Svg>
);

CheckIconRound.propTypes = {
  size: PropTypes.number,
};

export default CheckIconRound;
