import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getQuotation: ['data'],
  setQuotation: ['quotation'],
  getEvents: [],
  setEvents: ['events'],
  startCattolicaOrder: [],
});

export const CattolicaTypes = Types;
export default Creators;
