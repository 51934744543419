import { INITIAL_STATE } from './InitialState';
import { createReducer } from 'reduxsauce';
import { WorkoutTypes } from './Actions';
import moment from 'moment';

export const setWorkoutData = (state, { workout_data }) => {
  const previousData = state.workout_data ? state.workout_data : [];
  // Combine
  let res = workout_data.reduce((acc, item) => {
    if (!acc.find(el => el.workout_id === item.workout_id && el.source === item.source))
      acc.push(item);
    return acc;
  }, previousData);

  // Sort descending date
  res.sort((a, b) => {
    return (moment.unix(a.start_date).isAfter(moment.unix(b.end_date))) ? -1 : (moment.unix(a.start_date).isBefore(moment.unix(b.end_date))) ? 1 : 0;
  });

  return {
    ...state,
    workout_data: res,
  };
};

export const setGoogleFitDataSuccess = (state, { status }) => ({
  ...state,
  google_fit_data_success: status,
});

export const setGoogleFitDataError = (state, { error }) => ({
  ...state,
  google_fit_data_error: error,
});

export const resetGoogleFitData = (state) => {
  let data = state.workout_data ? state.workout_data : [];
  data = data.filter(el => el.source !== 'google-fit');
  return {
    ...state,
    workout_data: data,
  };
};

export const setStravaDataSuccess = (state, { status }) => ({
  ...state,
  strava_data_success: status,
});

export const setStravaDataError = (state, { error }) => ({
  ...state,
  strava_data_error: error,
});

export const resetStravaData = (state) => {
  let data = state.workout_data ? state.workout_data : [];
  data = data.filter(el => el.source !== 'strava');
  return {
    ...state,
    workout_data: data,
  };
};

export const setSaluteDataSuccess = (state, { status }) => ({
  ...state,
  salute_data_success: status,
});

export const setSaluteDataError = (state, { error }) => ({
  ...state,
  salute_data_error: error,
});

export const resetSaluteData = (state) => {
  let data = state.workout_data ? state.workout_data : [];
  data = data.filter(el => el.source !== 'salute');
  return {
    ...state,
    workout_data: data,
  };
};

export const setWorkoutsFetched = (state, { fetched }) => {
  return {
    ...state,
    workouts_fetched: fetched,
  };
};

export const reducer = createReducer(INITIAL_STATE, {
  [WorkoutTypes.SET_WORKOUT_DATA]: setWorkoutData,
  [WorkoutTypes.SET_GOOGLE_FIT_DATA_SUCCESS]: setGoogleFitDataSuccess,
  [WorkoutTypes.SET_GOOGLE_FIT_DATA_ERROR]: setGoogleFitDataError,
  [WorkoutTypes.RESET_GOOGLE_FIT_DATA]: resetGoogleFitData,
  [WorkoutTypes.SET_STRAVA_DATA_SUCCESS]: setStravaDataSuccess,
  [WorkoutTypes.SET_STRAVA_DATA_ERROR]: setStravaDataError,
  [WorkoutTypes.RESET_STRAVA_DATA]: resetStravaData,
  [WorkoutTypes.SET_SALUTE_DATA_SUCCESS]: setSaluteDataSuccess,
  [WorkoutTypes.SET_SALUTE_DATA_ERROR]: setSaluteDataError,
  [WorkoutTypes.RESET_SALUTE_DATA]: resetSaluteData,
  [WorkoutTypes.SET_WORKOUTS_FETCHED]: setWorkoutsFetched,
});
