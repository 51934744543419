import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import Svg, { G, Circle, Defs, Path, Polygon, LinearGradient, Stop } from 'react-native-svg';

import { Colors } from '../../Theme';

const PopupSuccessIcon = ({ size=112 }) => {

  const Style = StyleSheet.create({
    iconWrapper: {
      borderRadius: size,
      marginLeft: 'auto',
      marginRight: 'auto',
      shadowColor: Colors.black,
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.15,
      shadowRadius: 2,
      width: size,
    }
  });

  return (
    <View style={Style.iconWrapper}>
      <Svg width={size} height={size} viewBox={'0 0 112 106'}>
        <Defs>
          <Circle cx="52" cy="52" r="52" />
          <LinearGradient id={'PopupSuccessIcon-gradient1'} x1="50%" y1="0%" x2="50%" y2="100%">
            <Stop stopColor="#2AF598" offset="0%" />
            <Stop stopColor="#009EFD" offset="100%" />
          </LinearGradient>
          <LinearGradient id={'PopupSuccessIcon-gradient2'} x1="50%" y1="0%" x2="50%" y2="100%">
            <Stop stopColor="#2AF598" offset="0%" />
            <Stop stopColor="#009EFD" offset="100%"> /</Stop>
          </LinearGradient>
        </Defs>
        <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <G transform="translate(-132.000000, -269.000000)">
            <G transform="translate(32.000000, 244.000000)">
              <G transform="translate(104.000000, 27.000000)">
                <Path d="M52,7 C27.25,7 7,27.25 7,52 C7,76.75 27.25,97 52,97 C76.75,97 97,76.75 97,52 C97,27.25 76.75,7 52,7 Z" stroke="url(#PopupSuccessIcon-gradient1)" strokeWidth="3" fillRule="nonzero" />
                <G transform="translate(24.000000, 29.000000)" fill="url(#PopupSuccessIcon-gradient2)" fillRule="nonzero">
                  <Polygon points="22.1608652 46 0 22.8607232 3.06998438 19.3135045 21.9035859 38.9785638 53.680418 0 57 3.26487429" />
                </G>
              </G>
            </G>
          </G>
        </G>
      </Svg>
    </View>
  );
};

PopupSuccessIcon.propTypes = {
  size: PropTypes.number,
};

export default PopupSuccessIcon;
