import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';

import { Button, ButtonSizes } from '../';
import Style from './TransparentButtonStyle';

const TransparentButton = ({ size='medium', onPress, children, style }) => {
  return (
    <Button onPress={onPress} style={[Style.button, { height: ButtonSizes[size] }, style]}>
      <View style={Style.container}>
        <Text style={Style.text}>{children}</Text>
      </View>
    </Button>
  )
};

TransparentButton.propTypes = {
  size: PropTypes.oneOf(['medium', 'small']),
  onPress: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
};

export default TransparentButton;
