import Link from './Link/Link';
import Button from './Button/Button';
import PrimaryButton from './PrimaryButton/PrimaryButton';
import SecondaryButton from './SecondaryButton/SecondaryButton';
import TransparentButton from './TransparentButton/TransparentButton';
import BackButton from './BackButton/BackButton';
import BackOrCloseButton from './BackOrCloseButton/BackOrCloseButton';
import ToggleButton from './ToggleButton/ToggleButton';
import SocialButton from './SocialButton/SocialButton';
import Share from './Share/Share';
import Download from './Download/Download';

export const ButtonSizes = {
  xsmall: 30,
  small: 45,
  medium: 55,
};

export {
  Link,
  Button,
  PrimaryButton,
  SecondaryButton,
  TransparentButton,
  BackButton,
  BackOrCloseButton,
  ToggleButton,
  SocialButton,
  Share,
  Download,
}
