import { put, call } from 'redux-saga/effects';

import LayoutActions from '../Stores/Layout/Actions';
import SocialWallActions from '../Stores/SocialWall/Actions';
import { fetchSocialWall } from '../Services/ApiGatewayService';

export function* getSocialWallData({ loader = true }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  try {
    const data = yield call(fetchSocialWall);
    yield put(SocialWallActions.setSocialWallData(data));
    if (loader) yield put(LayoutActions.setLoading(false));
  } catch (error) {
    console.log(`DEBUG: error while fetching photos from Instagram API`, error);
    yield put(SocialWallActions.setSocialWallDataError(error));
    if (loader) yield put(LayoutActions.setLoading(false));
  }
}
