import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-native';
import { Config } from '../Config';

import { getPath } from './Router';

const NoAuthRoute = ({ route }) => {

  const { path, exact, routes, ...rest } = route;

  const { logged, user } = useSelector(state => state.auth);
  const next = useSelector(state => state.navigation.afterLogin);

  if (logged && user && user.complete) console.log(`DEBUG: ${route.path} is a NoAuthRoute: redirect to ${next ? next : Config.APP_HOMEPAGE}`);
  return (
    <Route
      path={path}
      exact={exact||false}
      render={props => (logged && user && user.complete ? <Redirect push={false} to={getPath(next ? next : Config.APP_HOMEPAGE)} /> : <route.component {...props} {...rest} routes={routes} />)}
    />
  );
};

NoAuthRoute.propTypes = {
  route: PropTypes.object.isRequired,
};

export default NoAuthRoute;
