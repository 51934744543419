import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  closeButton: {
    marginRight: -30,
    paddingBottom: 10,
    paddingHorizontal: 30,
    paddingTop: 30,
  },
  gradient: {
    alignItems: 'center',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    paddingBottom: 90,
    width: '100%',
  },
  wrapper: {
    alignItems: 'flex-end',
  },
});

