import { ApplicationStyles, Colors, Fonts } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';
import { Platform } from "react-native";

const BaseStyle = {
  content: {
    paddingVertical: 20,
  },
  contentTitle: {
    ...ApplicationStyles.screen.fontsRegular,
    fontSize: Fonts.size.regular-1,
    marginBottom: 10,
  },
  contentText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
    marginBottom: 20,
  },
  container: {
    flex: 1,
    marginBottom: ApplicationStyles.screen.paddingTabBar.paddingBottom,
    paddingTop: 10,
    paddingHorizontal: 30,
  },
  flex: {
    flex: 1,
  },
  modalBackground: {
    marginTop: Platform.OS === 'ios' ? 96 : 76,
    backgroundColor: Colors.mediumGray,
    flex: 1,
    justifyContent: 'space-around',
  },
  modalContainer: {
    flex: 1,
  },
  modalDownload: {
    paddingHorizontal: 10,
    alignSelf: 'flex-end',
  },
  paddingBottom5: {
    marginBottom: 10,
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    textTransform: 'uppercase',
  },
  wrapper: {
    flexGrow: 1,
  },
};

const StyleLG = {
  container: {
    ...ApplicationStyles.screen.webContainer,
    marginBottom: 30,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
