import { ApplicationStyles, Colors, Fonts } from '../../../../Theme';
import { Platform } from 'react-native';
import ResponsiveHelper from '../../../../Services/ResponsiveHelper';

const BaseStyle = {
  container: {
    flexGrow: 1,
  },
  endReachedText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.small,
    paddingTop: 10,
  },
  error: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular,
    paddingVertical: 30,
    textAlign: 'center',
  },
  kmImage: {
    width: '100%',
  },
  listFooter: {
    ...ApplicationStyles.screen.paddingTabBar,
    alignItems:'center',
    marginBottom: 20,
    paddingTop: 20,
  },
  listItem: {
    backgroundColor: Colors.white,
    elevation: 2,
    flex: 1,
    marginBottom: 10,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  listItemImage : {
    flex: 1,
    minHeight: 100,
  },
  listItemTextContainer: {
    alignItems:'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  listItemTextDesc: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small,
    textTransform: 'uppercase',
  },
  listItemTextTitle: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.small+2,
    textTransform: 'uppercase',
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
  pageTitle: {
    ...ApplicationStyles.screen.fonts,
    ...Fonts.style.h1,
    color: Colors.gray,
    textAlign: 'center',
    marginVertical: 30,
  },
  topKm: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: 'hidden',
  },
  topKmImage: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    flex: 1,
    overflow: 'hidden',
  },
  topKmText: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.white,
    fontSize: Fonts.size.h1 * 2,
    paddingVertical: 15,
    textAlign: 'center',
  },
  topKmTextList: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.white,
    fontSize: Fonts.size.h1*2,
    paddingHorizontal: 20,
    paddingVertical: 30,
    textAlign: 'center',
  },
  topListItem: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    elevation: 7,
    marginHorizontal: 10,
    marginVertical: 10,
    minHeight: 150,
    minWidth: Platform.OS !== 'web' ? 250 : 50,
    shadowColor: Colors.black,
    shadowOffset: { width: 4.5, height: 4.5 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  topListItemButton: {
    flex: 1,
  },
  topListItemImage : {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    flex: 1,
  },
  topListItemNew: {
    flex: 1,
    marginVertical: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topListItemNewContent: {
    flex: 1,
    justifyContent:'center',
  },
  topListItemNewTitle: {
    ...ApplicationStyles.screen.fontsLight,
    marginHorizontal: 20,
    textAlign: 'center',
  },
  topListItemText: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  topListItemTextDesc: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.darkBlueText,
    fontSize: Fonts.size.small,
    textTransform: 'uppercase',
  },
  topListItemTextTitle: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.darkBlueText,
    fontSize: Fonts.size.small+2,
    textTransform: 'uppercase',
  },
  topListWrapper: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  topTitle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
    paddingVertical: 10,
  },
  topTitleLink: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    fontSize: Fonts.size.regular+4,
    paddingVertical: 10,
  },
  topTitleText: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.grayMedium3,
    fontSize: Fonts.size.regular+2,
  },
  topWrapper: {
    flexGrow: 1,
  },
  webContainer: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
  }
};

const StyleLG = {
  listItem: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
  },
  listItemTextTitle: {
    fontSize: Fonts.size.regular+2,
  },
  listItemTextDesc: {
    fontSize: Fonts.size.regular+2,
  },
  topKmTextList: {
    color: Colors.white,
    fontSize: Fonts.size.h1*3,
    paddingVertical: 40,
  },
  topKmText : {
    paddingVertical: 30,
  },
  topListItemNew: {
    minHeight: 155,
  },
  topTitle: {
    marginTop: 30,
    marginBottom: 20,
  },
  topTitleText: {
    color: Colors.gray,
    textAlign: 'center',
    fontSize: Fonts.size.h1,
    flex: 1,
    textTransform: 'uppercase',
  },
  topTitleLink: {
    position: 'absolute',
    right: 30,
    top: 0,
    bottom: 0,
  },
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
