import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from "../../../Services/ResponsiveHelper";

export const BaseStyle = {
  fullWidth: {
    width: '100%',
  },
  loader: {
    alignSelf: 'center',
    marginVertical: 20,
  },
  retry: {
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 20,
  },
  retryText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-1,
    textDecorationLine: 'underline',
  },
  modalButton: {
    marginTop: 20,
    marginBottom: 20,
  },
  modalGradient: {
    width: '100%',
    height: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 100,
    justifyContent: 'space-around',
    flex: 1,
  },
  modalHelpButton: {
    marginBottom: 20,
  },
  modalTitle: {
    ...ApplicationStyles.screen.h2,
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  modalText: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
  },
  modalWrapper: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 40,
    paddingBottom: 10,
    paddingTop: 10,
  },
};

const StyleMedium = {
  modalWrapper: {
    ...BaseStyle.wrapper,
    minWidth: 300,
    maxWidth: 500,
    maxHeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  modalGradient: {
    ...BaseStyle.gradient,
    paddingBottom: '10%',
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });

export default StyleSheet.create(BaseStyle);
