import React from 'react';
import PropTypes from 'prop-types'
import { View, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import Style, { AvatarUploadStyle } from './AvatarUploadStyle';
import { Colors } from '../../Theme';
import { BlankAvatar, PlusFillIcon, MinusFillIcon } from '../../Theme/Icons';
import { Button } from '../Buttons';

const AvatarUpload = ({ avatar, onUpload, onReset, size=95 }) => {

  const loadAvatar = (event) => {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      const reader = new FileReader();
      reader.onload = event => {
        let img = document.createElement('img');
        img.src = event.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas'), ctx = canvas.getContext('2d');
          canvas.width = 500;
          canvas.height = 500;
          let sx = 0;
          let sy = 0;
          let sw = img.width;
          let sh = img.height;
          if (sw !== sh) {
            // Not square, crop it
            if (sw < sh) {
              // Portrait
              sy = (sh-sw)/2;
              sh = sw;
            } else {
              // Landscape
              sx = (sw-sh)/2;
              sw = sh;
            }
          }
          ctx.drawImage(img, sx, sy, sw, sh, 0, 0, 500, 500);
          onUpload(canvas.toDataURL());
        };
      };
      reader.readAsDataURL(files[0]);
    }
  };

  return (
    <>
      <input id={'avatarInput'} type={'file'} accept={'image/*'} capture={'camera'} onChange={loadAvatar} style={AvatarUploadStyle.hiddenInput} />
      {!avatar
        ? (
          <View style={[Style.wrapper, { borderRadius: ((size+10)/2), height: size+10, width: size+10 }]}>
            <label htmlFor={'avatarInput'} style={AvatarUploadStyle.pointer}>
              <LinearGradient colors={[Colors.blue1, Colors.blue2]} style={[Style.avatarGradient, { borderRadius: (size/2), height: size, width: size }]}>
                <BlankAvatar size={size-30} />
              </LinearGradient>
              <View style={Style.avatarIcon}>
                <PlusFillIcon/>
              </View>
            </label>
          </View>
        )
        : (
          <View style={[Style.wrapper, { borderRadius: ((size+10)/2), height: size+10, width: size+10 }]}>
            <label htmlFor={'avatarInput'} style={AvatarUploadStyle.pointer}>
              <Image source={{ uri: avatar }} style={[Style.uploadedAvatar, { borderRadius: (size/2), height: size, width: size }]} />
            </label>
            <Button style={Style.avatarIcon} onPress={onReset}>
              <MinusFillIcon/>
            </Button>
          </View>
        )
      }
    </>
  )
};

AvatarUpload.propTypes = {
  size: PropTypes.number,
  avatar: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
};

export default AvatarUpload;
