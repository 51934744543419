import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polyline } from 'react-native-svg';

import { Colors } from '../../Theme';

const UpArrow = ({ size=19, color=Colors.grayLight }) => (
  <Svg width={size} height={size} viewBox={'0 0 19 19'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'} strokeLinecap={'round'}>
      <G transform={'translate(-324.000000, -188.000000)'} stroke={color}>
        <Polyline transform={'translate(333.500000, 192.500000) rotate(-270.000000) translate(-333.500000, -192.500000)'} points={'337 201.5 330 192.5 337 183.5'} />
      </G>
    </G>
  </Svg>
);

UpArrow.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default UpArrow;
