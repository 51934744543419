import { put, call, select } from "redux-saga/effects";
import { Platform } from "react-native";
import { Config } from "../Config";
import { Auth, Storage } from "aws-amplify";
import uuidv1 from "uuid/v1";

import {
  fetchUser,
  saveUser,
  /*postCrmEvent,*/ postLeaderboardEvent,
  validateUser,
} from "../Services/ApiGatewayService";
import RegistrationAction from "../Stores/Registration/Actions";
import NavigationAction from "../Stores/Navigation/Actions";
import AuthActions from "../Stores/Auth/Actions";
import LayoutAction from "../Stores/Layout/Actions";
import NavigationActions from "../Stores/Navigation/Actions";
import Analytics from "../Services/AnalyticsService";

global.Buffer = global.Buffer || require("buffer").Buffer;

export function* registration({ data }) {
  const {
    name,
    surname,
    nickname,
    location,
    birthDate,
    gender,
    email,
    password,
    avatar,
    consent1,
    consent2,
    consent3,
  } = data;
  yield put(LayoutAction.setLoading(true));
  yield put(RegistrationAction.registrationFailure(null));

  let picture = yield uploadPicture(avatar);
  const user = {
    username: email.toLowerCase(),
    password,
    attributes: {
      given_name: name,
      family_name: surname,
      gender,
      nickname,
      birthdate: birthDate,
      // address: location,
      "custom:city": location,
      "custom:terms": "1",
      "custom:consent1": consent1 ? "1" : "0",
      "custom:consent2": consent2 ? "1" : "0",
      "custom:consent3": consent3 ? "1" : "0",
    },
  };
  if (picture) user.attributes.picture = picture;
  const result = yield Auth.signUp(user)
    .then((data) => ({ status: "OK", data }))
    .catch((err) => ({ status: "KO", err }));

  if (result.status === "KO" && typeof result.err.code !== "undefined") {
    if (result.err.code === "UsernameExistsException") {
      yield put(
        AuthActions.loginFailure(
          "Questa email è già registrata, inserisci la tua password per accedere"
        )
      );
      yield put(NavigationActions.pushNavigation(["login", { email }]));
    } else {
      yield put(
        RegistrationAction.registrationFailure(
          result.err.log ? result.err.log : "UNKNOWN ERROR"
        )
      );
    }
  } else if (result.status === "KO") {
    yield put(
      RegistrationAction.registrationFailure(
        result.err.log ? result.err.log : "UNKNOWN ERROR"
      )
    );
  } else {
    yield put(
      RegistrationAction.registrationToBeConfirmed({ email, password })
    );
  }
  yield put(LayoutAction.setLoading(false));
}

export function* confirmRegistration({ data }) {
  yield put(LayoutAction.setLoading(true));
  const { username, code, password } = data;
  const result = yield Auth.confirmSignUp(username.toLowerCase(), code)
    .then((data) => ({ status: "OK", data }))
    .catch((err) => ({ status: "KO", err }));
  if (result.status === "KO" && typeof result.err.code !== "undefined") {
    switch (result.err.code) {
      case "ExpiredCodeException":
        const newCode = yield Auth.resendSignUp(username.toLowerCase())
          .then(() => true)
          .catch(() => false);
        yield put(
          RegistrationAction.registrationFailure(
            newCode
              ? "Il codice inserito è scaduto, te ne abbiamo inviato un altro"
              : "Il codice inserito non è corretto"
          )
        );
        break;
      case "CodeMismatchException":
        yield put(
          RegistrationAction.registrationFailure(
            "Il codice inserito non è corretto"
          )
        );
        break;
      default:
        yield put(
          RegistrationAction.registrationFailure(
            result.err.log ? result.err.log : "UNKNOWN ERROR"
          )
        );
        break;
    }
  } else if (result.status === "KO") {
    yield put(
      RegistrationAction.registrationFailure(
        result.err.log ? result.err.log : "UNKNOWN ERROR"
      )
    );
  } else {
    yield put(RegistrationAction.registrationSuccess());
    if (Platform.OS !== "web") {
      yield put(NavigationAction.setAfterLogin("linkRuncard"));
      yield put(NavigationAction.setAfterRuncardLink("enableServices"));
      yield put(AuthActions.login({ username, password }, "linkRuncard", true));
    } else {
      yield put(AuthActions.login({ username, password }, null, true));
    }
  }
  yield put(LayoutAction.setLoading(false));
}

export function* socialRegistration({ social }) {
  console.log("SOCIAL", social);
  // if (Platform.OS === "web") {
  const redirectAfterLogin = yield select(
    (state) => state.navigation.afterLogin
  );
  const customState = redirectAfterLogin ? redirectAfterLogin : null;
  yield Auth.federatedSignIn({ provider: social, customState })
    .then((data) => ({ status: "OK", data }))
    .catch((err) => ({ status: "KO", err }));
  // } else {
  //   Auth.federatedSignIn({ provider: "facebook" });
  // }
}

export function* completeRegistration({ data }) {
  yield put(LayoutAction.setLoading(true));
  try {
    let picture = yield uploadPicture(data.avatar);
    const user = yield call(saveUser, {
      given_name: data.name,
      family_name: data.surname,
      gender: data.gender,
      nickname: data.nickname,
      birthdate: data.birthDate,
      city: data.location,
      picture,
      terms: true,
      consent1: data.consent1,
      consent2: data.consent2,
      consent3: data.consent3,
    });
    yield put(RegistrationAction.registrationSuccess());

    // CRM & Tracking
    // yield call(postCrmEvent, { event: 'event_signup' });
    // yield call(postCrmEvent, { event: 'event_insert_privacy' });
    yield call(postLeaderboardEvent, { event: "subscribe" });
    Analytics.getInstance().trackRegistration();

    yield put(AuthActions.setUserLogged(user));
    if (Platform.OS !== "web") {
      yield put(NavigationAction.setAfterRuncardLink("enableServices"));
      yield put(
        NavigationActions.pushNavigation(["linkRuncard", { back: false }])
      );
    } else {
      yield put(NavigationActions.pushNavigation(Config.APP_HOMEPAGE));
    }
  } catch (err) {
    console.log("err", err);
    yield put(LayoutAction.setError({}));
  }
  yield put(LayoutAction.setLoading(false));
}

// Used during the runcard buy process, before the payment
export function* fullRegistration({ data, runcard_data }) {
  yield put(LayoutAction.setLoading(true));
  try {
    yield call(saveUser, data);
    const user = yield call(fetchUser);
    yield put(AuthActions.setUserLogged(user));
    try {
      yield call(validateUser, runcard_data);
      yield put(RegistrationAction.fullRegistrationSuccess());
    } catch (error) {
      if (error.response.status === 400) {
        // Validation error
        const errors = error.response.data.message;
        yield put(RegistrationAction.validationErrors(errors));
      } else {
        console.log("fullRegistration validation error", error);
        yield put(LayoutAction.setError({}));
      }
    }
  } catch (err) {
    console.log("fullRegistration error", err);
    yield put(LayoutAction.setError({}));
  }
  yield put(LayoutAction.setLoading(false));
}

export function* updateForRenew({ data }) {
  yield put(LayoutAction.setLoading(true));
  try {
    yield call(saveUser, data);
    const user = yield call(fetchUser);
    yield put(AuthActions.setUserLogged(user));
    yield put(RegistrationAction.fullRegistrationSuccess());
  } catch (err) {
    console.log("err", err);
    yield put(LayoutAction.setError({}));
  }
  yield put(LayoutAction.setLoading(false));
}

export function* requestConfirmationCode({ username }) {
  yield put(LayoutAction.setLoading(true));
  const newCode = yield Auth.resendSignUp(username.toLowerCase())
    .then(() => true)
    .catch((err) => err);
  if (newCode === true) {
    yield put(RegistrationAction.requestCodeSuccess());
  } else {
    yield put(RegistrationAction.requestCodeFailure(err));
  }
  yield put(LayoutAction.setLoading(false));
}

export function* updateUser({ data }) {
  yield put(LayoutAction.setLoading(true));
  if (data.picture) data.picture = yield uploadPicture(data.picture);
  try {
    const user = yield call(saveUser, data);
    yield put(AuthActions.setUserLogged(user));
  } catch (err) {
    console.log("err", err);
    yield put(LayoutAction.setError({}));
  }
  yield put(LayoutAction.setLoading(false));
}

function* uploadPicture(avatar) {
  if (avatar && avatar.indexOf("base64") !== -1) {
    // Uploaded avatar
    try {
      let contentType = avatar.match(/^data:(image\/\w+);base64,/);
      if (contentType && contentType.length > 1) {
        contentType = contentType[1];
        const data = new Buffer(
          avatar.replace(/^data:image\/\w+;base64,/, ""),
          "base64"
        );
        const customPrefix = {
          public: `app/${Config.STAGE}/`,
        };
        const result = yield Storage.put(`avatar/${uuidv1()}.png`, data, {
          customPrefix,
          contentType,
        })
          .then((result) => result)
          .catch(() => null);
        if (result) {
          return `${Config.CDN}/${result.key}`;
        }
      }
    } catch (error) {
      console.log("uploadPicture error", error);
    }
  } else if (avatar) {
    // Remote avatar
    return avatar;
  }
  return null;
}
