import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop, Circle, Use } from 'react-native-svg';

const DipIcon = ({ size=59 }) => (
  <Svg width={size} height={size} viewBox={'0 0 59 59'}>
    <Defs>
      <LinearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'gradientDip1'}>
        <Stop stopColor={'#77CC69'} offset={'0%'} />
        <Stop stopColor={'#56B64A'} offset={'100%'} />
      </LinearGradient>
      <Circle id={'dipCircle'} cx={'20.26134'} cy={'20.26134'} r={'20.26134'} />
    </Defs>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <G transform={'translate(-874.000000, -523.000000)'}>
        <G transform={'translate(883.000000, 528.000000)'}>
          <G>
              <Use fill={'url(#gradientDip1)'} fillRule={'evenodd'} href={'#dipCircle'} />
          </G>
          <G transform={'translate(6.930000, 6.930000)'} fill={'#FFFFFF'} fillRule={'nonzero'}>
            <Path d={'M12.54528,22.495968 C12.54528,23.2820438 11.9057558,23.921568 11.11968,23.921568 C10.3336042,23.921568 9.69408,23.2820438 9.69408,22.495968 L9.69408,20.6712 L7.98336,20.6712 L7.98336,22.495968 C7.98336,24.2253348 9.39031315,25.632288 11.11968,25.632288 C12.8490468,25.632288 14.256,24.2253348 14.256,22.495968 L14.256,13.3872394 L12.54528,13.3872394 L12.54528,22.495968 Z'} />
            <G transform={'translate(0.028227, 0.000000)'}>
              <Path d={'M13.9996771,11.176704 L13.9996771,11.8190794 C15.3310928,11.5950808 16.3314268,11.4830815 17.0006792,11.4830815 C17.6652055,11.4830815 18.6655396,11.5950808 20.0016812,11.8190794 C19.9009768,8.65943654 19.2437182,5.72811782 18.1246222,3.48998285 C17.0156765,1.27203437 15.551015,2.78561174e-14 14.106198,2.78561174e-14 L13.9996771,2.78561174e-14 L13.9996771,11.176704 Z'} />
              <Path d={'M6.00092064,11.8190794 C6.14667398,6.90817248 7.54073971,2.72899757 9.61008365,0.364497408 C7.38557741,0.908905536 5.34485952,2.0514384 3.68403552,3.7122624 C1.49277427,5.90346662 0.20272032,8.75546496 2.78561174e-14,11.8190794 C1.3947887,11.5776785 2.43280748,11.456978 3.11405632,11.456978 C3.7663454,11.456978 4.7286335,11.5776785 6.00092064,11.8190794 Z'} />
              <Path d={'M21.0005136,11.8190794 C22.4762475,11.5771951 23.592015,11.4562529 24.3478161,11.4562529 C24.9255656,11.4562529 25.810124,11.5771951 27.0014913,11.8190794 C26.7987139,8.75546496 25.50866,5.90346662 23.3173987,3.7122624 C21.6565747,2.0514384 19.6158568,0.908905536 17.3913506,0.364497408 C19.4606945,2.72899757 20.8547603,6.90817248 21.0005136,11.8190794 Z'} />
              <Path d={'M8.87681203,3.48998285 C7.75771603,5.72817485 7.10045741,8.65943654 6.99975302,11.8190794 C8.35908988,11.5900535 9.35942389,11.4755405 10.0007551,11.4755405 C10.6932026,11.4755405 11.6935366,11.5900535 13.0017571,11.8190794 L13.0017571,11.176704 L13.0017571,2.78561174e-14 L12.8952363,2.78561174e-14 C11.4504192,2.78561174e-14 9.98575776,1.27203437 8.87681203,3.48998285 Z'} />
            </G>
          </G>
        </G>
      </G>
    </G>
  </Svg>

);

DipIcon.propTypes = {
  size: PropTypes.number,
};

export default DipIcon;
