import firebase from "firebase/app";
import { Config } from "../Config";
require("firebase/analytics");

let instance = null;

export default class Analytics {
  analytics = null;

  constructor() {
    if (!instance) {
      firebase.initializeApp({
        apiKey: Config.FIREBASE_ANALYTICS.api_key,
        authDomain: Config.FIREBASE_ANALYTICS.auth_domain,
        databaseURL: Config.FIREBASE_ANALYTICS.db_url,
        projectId: Config.FIREBASE_ANALYTICS.project_id,
        storageBucket: Config.FIREBASE_ANALYTICS.storage_bucket,
        messagingSenderId: Config.FIREBASE_ANALYTICS.messaging_sender_id,
        appId: Config.FIREBASE_ANALYTICS.app_id,
        measurementId: Config.FIREBASE_ANALYTICS.measurement_it,
      });

      this.analytics = firebase.analytics();

      instance = this;
    }

    return instance;
  }

  static getInstance() {
    return new Analytics();
  }

  async trackRegistration() {
    this.analytics.logEvent("user", {
      action: "register",
    });
  }

  async trackLogin() {
    this.analytics.logEvent("user", {
      action: "login",
    });
  }

  async trackLogout() {
    this.analytics.logEvent("user", {
      view: "logout",
    });
  }

  async trackSkipRegistration() {
    this.analytics.logEvent("user", {
      action: "skip-register",
    });
  }

  async trackMatchesView() {
    this.analytics.logEvent("navigation", {
      view: "gare",
    });
  }

  async trackActivitiesView() {
    this.analytics.logEvent("navigation", {
      view: "activities",
    });
  }

  async trackRuncardView() {
    this.analytics.logEvent("navigation", {
      view: "runcard",
    });
  }

  async trackPlayView() {
    this.analytics.logEvent("navigation", {
      view: "gioca",
    });
  }

  async trackTakeASelfieView() {
    this.analytics.logEvent("navigation", {
      view: "scattaunselfie",
    });
  }

  async trackSocialWallView() {
    this.analytics.logEvent("navigation", {
      view: "socialwall",
    });
  }

  async trackChallengesView() {
    this.analytics.logEvent("navigation", {
      view: "sfide",
    });
  }

  async trackMyBadgesView() {
    this.analytics.logEvent("navigation", {
      view: "mybadges",
    });
  }

  async trackServiceView(service) {
    this.analytics.logEvent("navigation", {
      view: service,
    });
  }

  async trackNewsView() {
    this.analytics.logEvent("navigation", {
      view: "news",
    });
  }

  async trackProfileView() {
    this.analytics.logEvent("navigation", {
      view: "profile",
    });
  }

  async trackFAQView() {
    this.analytics.logEvent("navigation", {
      view: "faq",
    });
  }

  async trackAdvantagesView() {
    this.analytics.logEvent("navigation", {
      view: "vantaggi",
    });
  }

  async trackHomepageView() {
    this.analytics.logEvent("navigation", {
      view: "homepage",
    });
  }
}
