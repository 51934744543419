import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList, ImageBackground, Platform } from 'react-native';
import { generatePath } from 'react-router-native';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Slider from 'react-slick';
import { Config } from '../../../../Config';

import { getResponsive } from './UserStyle';
import { LeftArrow, PlusIcon, RightArrow } from '../../../../Theme/Icons';
import { LoaderInline } from '../../../Layout';
import { Button } from '../../../Buttons';

import ChallengesActions from '../../../../Stores/Challenges/Actions';
import { Colors, Images } from '../../../../Theme';
import { getPath } from '../../../../Router/AppRoutes';
import { isDesktop } from "../../../../Services/ResponsiveHelper";

const UserSlider = () => {
  const Style = getResponsive();
  const dispatch = useDispatch();

  const data = useSelector(state => state.challenges.user_challenges);
  const error = useSelector(state => state.challenges.user_error_challenges);

  const [ loading, setLoading ] = React.useState(false);

  const Arrow = ({ className, style, onClick, type }) => {
    return className.indexOf('disabled') === -1 ? (
      <a className={`${className ? className : ''} rounded-arrows`} href={'#'} style={{ ...style }} onClick={onClick}>
        {type === 'prev' ? <LeftArrow color={Colors.white} stroke={2} size={isDesktop() ? 20 : 10} /> : <RightArrow color={Colors.white} stroke={2} size={isDesktop() ? 20 : 10} />}
      </a>
    ) : null;
  };

  Arrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
    type: PropTypes.string,
    onClick: PropTypes.func,
  };

  const sliderSettings = {
    className: 'slider slider-challenge',
    dots: true,
    arrow: true,
    infinite: false,
    slidesToShow: 3,
    nextArrow: <Arrow type={'next'} />,
    prevArrow: <Arrow type={'prev'} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  React.useEffect(() => {
    if (!data && !error) {
      setLoading(true);
      dispatch(ChallengesActions.getUserChallenges(false));
    }
  }, []);

  React.useEffect(() => {
    if (data !== null && loading) {
      setLoading(false);
    }
  }, [data]);

  const Challenge = ({ item }) => {
    return (
      item.id === 'new'
        ? <New />
        : (
          <View style={Style.topListItem}>
            <Button route={generatePath(getPath('challengeUser'), { id: item.id })} style={Style.topListItemButton}>
              <ImageBackground source={Images.goal} style={Style.topKmImage}>
                <Text style={Style.topKmText}>{item.km}km</Text>
              </ImageBackground>
              <View style={Style.topListItemText}>
                <Text style={Style.topListItemTextTitle}>{item.title}</Text>
                <Text style={Style.topListItemTextDesc}>Termina tra {moment(item.end_date, 'YYYYMMDD').diff(moment(), 'days')} giorni</Text>
              </View>
            </Button>
          </View>
        )
    );
  };

  Challenge.propTypes = {
    item: PropTypes.object,
  };

  const New = () => (
    <View style={Style.topListItem}>
      <Button route={'challengeNew'} style={Style.topListItemButton}>
        <View style={Style.topListItemNew}>
          <View style={Style.topListItemNewContent}><Text style={Style.topListItemNewTitle}>Crea un nuovo obiettivo{'\n'}e mettiti alla prova!{'\n'}Cosa aspetti!</Text></View>
          <View style={Style.topListItemNewContent}><PlusIcon size={60} /></View>
        </View>
      </Button>
    </View>
  );

  let listData = data ? data.filter(el => moment(el.end_date, 'YYYYMMDD').isSameOrAfter(moment())) : null;
  if (listData && Config.MAX_GOALS > listData.length) {
    listData = [ { id: 'new' }, ...listData.slice(0, 5) ];
  } else if (listData) {
    listData = [ ...listData.slice(0, 5) ];
  }

  return (
    <View style={Style.topWrapper}>
      <View style={Style.topTitle}>
        <Text style={Style.topTitleText}>I tuoi obiettivi</Text>
        <Button route={'challengesUser'}><Text style={Style.topTitleLink}>Vedi tutti</Text></Button>
      </View>
      {!error
        ? Platform.OS !== 'web'
          ? (
            <FlatList
              horizontal={true}
              contentContainerStyle={Style.topListWrapper}
              data={listData}
              renderItem={({ item }) => <Challenge item={item} />}
              keyExtractor={item => item.id}
              ListEmptyComponent={data !== null ? <New /> : null}
              initialNumToRender={5}
              ListFooterComponent={loading ? <LoaderInline style={{}} /> : null}
            />
          ) : (
            <Slider {...sliderSettings}>
              {listData && listData.map((item, key) => <Challenge key={key} item={item} />)}
            </Slider>
          )
        : <Text style={Style.error}>Non è stato possibile recuperare gli obiettivi</Text>
      }
    </View>
  );
};

export default UserSlider;
