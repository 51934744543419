export const INITIAL_STATE = {
  countries: null,
  provinces: null,
  options: null,
  optionsFetched: false,
  services: null,
  faq: null,
  conventions: null,
  servicesTokens: null,
  serviceTokenError: null,
  leaderboardLevel: null,
};
