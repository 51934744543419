import React from "react";
import PropTypes from "prop-types";
import { Text, View } from "react-native";
import moment from "moment";
import { Config } from "../../../Config";

import Style from "./CardStyle";
import { Colors } from "../../../Theme";
import { CheckIcon, AlertIcon } from "../../../Theme/Icons";
import { PrimaryButton } from "../../Buttons";

const CardStatus = ({ runcard }) => {
  // return (
  //   <PrimaryButton size={"xsmall"} route={"renewRuncard"}>
  //     Rinnova
  //   </PrimaryButton>
  // );
  return moment(runcard.expire_at).isAfter(moment()) ? (
    <>
      <View style={Style.icon}>
        <CheckIcon />
      </View>
      <Text style={Style.text}>
        Scade il{" "}
        <Text style={Style.textBold}>
          {moment(runcard.expire_at).format(Config.DATE_FORMAT.screen)}
        </Text>
      </Text>
      {moment(runcard.expire_at).diff(moment(), "days") <=
        Config.DAYS_BEFORE_RENEW && (
        <View style={Style.buttonContainer}>
          <PrimaryButton size={"xsmall"} route={"renewRuncard"}>
            Rinnova
          </PrimaryButton>
        </View>
      )}
    </>
  ) : (
    <>
      <View style={Style.icon}>
        <AlertIcon color={Colors.red} />
      </View>
      <Text style={Style.text}>
        Scaduta il{" "}
        <Text style={Style.textBold}>
          {moment(runcard.expire_at).format(Config.DATE_FORMAT.screen)}
        </Text>
      </Text>
      <View style={Style.buttonContainer}>
        <PrimaryButton size={"xsmall"} route={"renewRuncard"}>
          Rinnova
        </PrimaryButton>
      </View>
    </>
  );
};

CardStatus.propTypes = {
  runcard: PropTypes.object,
};

export default CardStatus;
