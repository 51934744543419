import React from 'react'
import { useHistory } from 'react-router-native';
import { useSelector, useDispatch } from 'react-redux';

import { LinkRuncard, BuyRuncard, LinkRuncardDesktop } from '../../Components/Runcard';

import { getPath } from '../../Router/Router';
import { isDesktop } from '../../Services/ResponsiveHelper';
import NavigationAction from '../../Stores/Navigation/Actions';
import LayoutActions from '../../Stores/Layout/Actions';
import Analytics from '../../Services/AnalyticsService';

const LinkRuncardScreen = () => {
  const dispatch = useDispatch();
  let history = useHistory();

  const { logged, user } = useSelector(state => state.auth);
  const nextRoute = useSelector(state => state.navigation.afterRuncardLink);

  const [ foreign, setForeign ] = React.useState(null);
  const [ cardType, setCardType ] = React.useState(null);
  const [ buy, setBuy ] = React.useState(false);

  React.useEffect(() => {
    Analytics.getInstance().trackRuncardView();
    if (user && user.runcard) {
      history.push(getPath(nextRoute ? nextRoute : 'runcard'));
      if (nextRoute) dispatch(NavigationAction.setAfterRuncardLink(null));
    }
    dispatch(LayoutActions.setTab('RUNCARD'));
  }, []);

  return logged &&
    (!buy
      ? !isDesktop() ? <LinkRuncard buyHandler={(val) => setBuy(val)} /> : <LinkRuncardDesktop buyHandler={(val) => setBuy(val)} setForeign={setForeign} setCardType={setCardType} />
      : <BuyRuncard buyHandler={(val) => setBuy(val)} foreign={foreign} cardType={cardType} />
    );
};

export default LinkRuncardScreen;
