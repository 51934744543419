import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getResults: ['loader'],
  setResults: ['data'],
  setResultsError: ['error']
});

export const ResultsTypes = Types;
export default Creators;
