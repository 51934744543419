import React from 'react';
import { View, Text } from 'react-native';

import Style from './PageNotFoundStyle';
import { PrimaryButton } from '../../Buttons';
import { Footer } from '../';

const PageNotFound = () => (
  <>
    <View style={Style.wrapper}>
      <View style={Style.container}>
        <Text style={Style.title}>OPS!</Text>
        <Text style={Style.text}>Pagina non trovata</Text>
        <PrimaryButton route={'homepage'} size={'small'}>VAI ALL&apos;HOMEPAGE</PrimaryButton>
      </View>
    </View>
    <Footer />
  </>
);

export default PageNotFound;
