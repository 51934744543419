import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, Image, Linking, Platform } from 'react-native';

import { Button } from '../../Buttons';

const slots = {
  splash: { width: 320, height: 50 },
  main_sponsor: { width: 142, height: 68 },
  footer_banner: { width: 320, height: 50 },
  popup: { width: 250, height: 250 },
  mpu: { width: 300, height: 250 },
  masthead: { width: 930, height: 180 },
};

const Adv = ({ adv }) => {
  return adv.dfp === false ? (
    <View style={{alignItems:'center'}}>
      {Platform.OS === 'web'
        ? (
          <a href={adv.settings.link} target={'_blank'} rel={'noopener noreferrer'}>
            <Image source={{ uri: adv.settings.image }} style={{ width: slots[adv.position].width, height: slots[adv.position].height }}/>
          </a>
        ) : (
          <Button onPress={() => Linking.openURL(adv.settings.link)}>
            <Image source={{ uri: adv.settings.image }} style={{ width: slots[adv.position].width, height: slots[adv.position].height }}/>
          </Button>
        )
      }
    </View>
  ) : <View style={{ width: slots[adv.position].width, height: slots[adv.position].height, justifyContent: 'center', alignItems: 'center', backgroundColor: 'red' }}><Text>DFP SLOT: TODO</Text></View>;
};

Adv.propTypes = {
  adv: PropTypes.object.isRequired,
};

export default Adv;
