import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { View, Text, Platform, Linking } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import sha1 from "crypto-js/sha1";
import { useHistory } from "react-router-native";
import moment from "moment";
import { PubSub } from "aws-amplify";
import { Config } from "../../../Config";

import Style, { getResponsive } from "./PaymentStyle";
import { LoaderInline, Modal, WebView } from "../../Layout";
import { ErrorPopup } from "../../Popup";
import { Button, PrimaryButton, SecondaryButton } from "../../Buttons";

import LayoutActions from "../../../Stores/Layout/Actions";
import OrderActions from "../../../Stores/Order/Actions";
import NavigationAction from "../../../Stores/Navigation/Actions";
import AuthActions from "../../../Stores/Auth/Actions";
import RuncardActions from "../../../Stores/Runcard/Actions";
import { getPath } from "../../../Router/Router";
import HelperActions from "../../../Stores/Helper/Actions";
import { PopupErrorIcon, PopupSuccessIcon } from "../../../Theme/Icons";

const Payment = ({ runcardType, foreign, renew = false, scrollTop, back }) => {
  let history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const { order_id, order_error } = useSelector((state) => state.order);
  const nextRoute = useSelector((state) => state.navigation.afterRuncardLink);
  const runcard = useSelector((state) => state.runcard.runcard);

  const [frameUrl, setFrameUrl] = React.useState(null);
  const [frameHeight, setFrameHeight] = React.useState(1071);
  const [cancel, setCancel] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [operationSuccess, setOperationSuccess] = React.useState(null);
  const [operationData, setOperationData] = React.useState(null);

  React.useEffect(() => {
    if (renew === false && runcard === null && success === false) {
      null;
    } else if (!renew && runcard && (!success || operationSuccess !== "OK")) {
      dispatch(
        AuthActions.setUserLogged({ ...user, runcard: runcard.runcard })
      );
      setSuccess(false);
      setCancel(false);
      setError(false);
      setOperationSuccess(null);
      history.push(getPath(nextRoute ? nextRoute : "runcard"));
      if (nextRoute) dispatch(NavigationAction.setAfterRuncardLink(null));
    } else if (
      renew &&
      moment(runcard.expire_at).diff(moment(), "days") >
        Config.DAYS_BEFORE_RENEW &&
      (!success || operationSuccess !== "OK")
    ) {
      dispatch(
        AuthActions.setUserLogged({ ...user, runcard: runcard.runcard })
      );
      setSuccess(false);
      setCancel(false);
      setError(false);
      setOperationSuccess(null);
      history.push(getPath(nextRoute ? nextRoute : "runcard"));
      if (nextRoute) dispatch(NavigationAction.setAfterRuncardLink(null));
    }
  }, [runcard, success]);

  React.useEffect(() => {
    dispatch(OrderActions.startOrder(runcardType, renew));
  }, []);

  React.useEffect(() => {
    if (order_id) {
      loadIFrame();
      // Receive messages from Payment server-to-server callback (NEXI to Lambda)
      // PubSub.configure();
      PubSub.subscribe(
        `runcard/${Config.STAGE}/order-processed/${order_id}`
      ).subscribe({
        // next: (data) => console.log("Message received", data),
        next: handleIotMessage,
        error: (err) => console.log("PubSub subscribe error", err),
        close: () => console.log("Done"),
      });
    }
  }, [order_id]);

  const loadIFrame = () => {
    if (order_id) {
      dispatch(LayoutActions.setLoading(true));
      const codTrans = order_id;
      const importo =
        (foreign
          ? Config.RUNCARD_TYPES[runcardType].price_foreign
          : Config.RUNCARD_TYPES[runcardType].price) * 100;

      const mac = sha1(
        `codTrans=${codTrans}divisa=${
          Config.PAYMENTS.NEXI.divisa
        }importo=${importo}${Config.PAYMENTS.NEXI.secret}`
      );

      const params = {
        alias: Config.PAYMENTS.NEXI.alias,
        importo,
        divisa: Config.PAYMENTS.NEXI.divisa,
        codTrans,
        url: Config.PAYMENTS.NEXI.success_callback,
        url_back: Config.PAYMENTS.NEXI.cancel_callback,
        mac,
        urlpost: Config.PAYMENTS.NEXI.success_url,
        mail: user.email,
        OPTION_CF: user.tax_code,
        descrizione: `${renew ? "Rinnovo" : "Acquisto"} Runcard ${runcardType}`,
        languageId: "ITA",
      };
      setFrameUrl(
        `${Config.PAYMENTS.NEXI.url}?${Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join("&")}`
      );
    }
  };

  const handleMessage = (message) => {
    switch (message) {
      case "success_payment":
        setSuccess(true);
        break;
      case "canceled_payment":
        setCancel(true);
        setFrameHeight(100);
        break;
      case "error_payment":
        setError(true);
        setFrameHeight(100);
        break;
      case "try_reload":
        dispatch(RuncardActions.getRuncard(false, true));
        break;
    }
  };

  const handleIotMessage = (data) => {
    if (
      data.value &&
      (!data.value.code || data.value.code !== "NexiWrongESITO")
    ) {
      setSuccess(true);
      setOperationData({ status: data.value.status, data: data.value.data });
      setOperationSuccess(data.value.status);
    } else {
      setError(true);
      setFrameHeight(100);
    }
    if (data.value.status === "OK") {
      //dispatch(HelperActions.sendCRMEvent('event_purchased_runcard'));
      dispatch(
        HelperActions.sendLeaderboardEvent(
          "buy",
          Config.RUNCARD_TYPES[runcardType].price
        )
      );
      if (!runcard) {
        dispatch(RuncardActions.getRuncard(false, true));
      } else if (renew) {
        runcard.expire_at = data.value.data.expire_at;
        dispatch(RuncardActions.setRuncard(runcard));
        dispatch(RuncardActions.getRuncard(false, true));
      }
    } else if (data.value.code !== "NexiWrongESITO") {
      dispatch(
        RuncardActions.setRuncardBuyError({ code: data.value.code, order_id })
      );
    }
  };

  const nextAfterSuccess = () => {
    dispatch(
      AuthActions.setUserLogged({
        ...user,
        runcard: operationData.data.runcard,
      })
    );
    setSuccess(false);
    setCancel(false);
    setError(false);
    setOperationSuccess(null);
    history.push(getPath(nextRoute ? nextRoute : "runcard"));
    if (nextRoute) dispatch(NavigationAction.setAfterRuncardLink(null));
  };

  const handleLater = () => {
    setCancel(false);
    setError(false);
    setSuccess(false);
    setOperationSuccess(null);
    history.push(getPath(nextRoute ? nextRoute : Config.APP_HOMEPAGE));
    if (nextRoute) dispatch(NavigationAction.setAfterRuncardLink(null));
  };

  const handleRetry = () => {
    setCancel(false);
    setError(false);
    setSuccess(false);
    setOperationSuccess(null);
    dispatch(OrderActions.startOrder(runcardType));
    setFrameUrl("about:blank");
    setFrameHeight(1071);
    loadIFrame();
  };

  const Retry = () => (
    <Button onPress={handleLater} style={Style.retry}>
      <Text style={Style.retryText}>
        {renew ? "Rinnova" : "Acquista"} Runcard in seguito
      </Text>
    </Button>
  );

  const handleHelp = () => {
    try {
      Linking.openURL(
        `mailto:${Config.HELP_CONTACTS.address}?subject=${
          Config.HELP_CONTACTS.subject
        }`
      ).catch(() => {});
    } catch (err) {}
  };

  return (
    <View>
      <View style={[Style.fullWidth, { height: frameHeight }]}>
        {frameUrl && (
          <WebView
            onNavigationStateChange={scrollTop}
            scrollEnabled={false}
            style={[
              Style.fullWidth,
              Platform.OS === "web" ? { height: frameHeight } : {},
            ]}
            url={frameUrl}
            onMessage={handleMessage}
            onLoad={() => dispatch(LayoutActions.setLoading(false))}
          />
        )}
      </View>
      {success === true && !order_error && !error && (
        <Modal
          animationType={"fade"}
          transparent={true}
          onRequestClose={() =>
            operationSuccess === "OK"
              ? nextAfterSuccess()
              : operationSuccess !== null
              ? history.push(getPath("runcard"))
              : {}
          }
        >
          <LinearGradient
            colors={["rgba(0,42,85,0.95)", "rgba(0,42,85,0.5)"]}
            style={getResponsive().modalGradient}
          >
            <View style={getResponsive().modalWrapper}>
              {operationSuccess === "OK" ? (
                <PopupSuccessIcon />
              ) : operationSuccess !== null ? (
                <PopupErrorIcon />
              ) : null}
              <Text style={Style.modalTitle}>
                {operationSuccess === "OK"
                  ? "Acquisto avvenuto con successo"
                  : operationSuccess !== null
                  ? "Si è verificato un errore"
                  : "Pagamento avvenuto con successo"}
              </Text>
              <Text style={Style.modalText}>
                {operationSuccess === "OK"
                  ? !renew
                    ? `Hai acquistato la ${
                        Config.RUNCARD_TYPES[operationData.data.runcard_type]
                          .name
                      } ${operationData.data.runcard}`
                    : `La scadenza della tua Runcard è ${moment(
                        operationData.data.expire_at
                      ).format(Config.DATE_FORMAT.screen)}`
                  : operationSuccess !== null
                  ? `${
                      !renew
                        ? "Non è stato possibile creare una nuova runcard"
                        : "Non è stato possibile rinnovare la tua runcard"
                    }\n\nCodice ordine: ${order_id}\nCodice di errore: ${
                      operationData.code
                    }`
                  : renew
                  ? "Rinnovo in corso..."
                  : "Creazione runcard in corso..."}
              </Text>
              {operationSuccess === null ? (
                <LoaderInline style={Style.loader} />
              ) : (
                <>
                  <PrimaryButton
                    onPress={() =>
                      operationSuccess === "OK"
                        ? nextAfterSuccess()
                        : operationSuccess !== null
                        ? history.push(getPath("runcard"))
                        : {}
                    }
                    size={"small"}
                    style={Style.modalButton}
                  >
                    <Text>OK</Text>
                  </PrimaryButton>
                  <SecondaryButton
                    onPress={handleHelp}
                    size={"xsmall"}
                    style={Style.modalHelpButton}
                  >
                    Richiesta assistenza
                  </SecondaryButton>
                </>
              )}
            </View>
          </LinearGradient>
        </Modal>
      )}
      {cancel && (
        <ErrorPopup
          closeHandler={handleRetry}
          title={"Il pagamento è stato annullato"}
          text={"Se si intende procedere con l'acquisto, ripetere l'operazione"}
          button={"RIPROVA"}
          extra={<Retry />}
        />
      )}
      {error && (
        <ErrorPopup
          help={true}
          closeHandler={handleRetry}
          title={"Il pagamento non è andato a buon fine"}
          text={
            "Ci scusiamo per il disagio, ti preghiamo di riprovare. Grazie."
          }
          button={"RIPROVA"}
          extra={<Retry />}
        />
      )}
      {order_error !== null && (
        <ErrorPopup
          help={true}
          closeHandler={() => history.push(getPath("runcard"))}
          title={"Si è verificato un errore"}
          text={`Non è stato possibile procedere con l\'acquisto\n\nCodice di errore: ${
            order_error.data && order_error.data.code
              ? order_error.data.code
              : "UNKNOWN"
          }`}
        />
      )}
    </View>
  );
};

Payment.propTypes = {
  runcardType: PropTypes.string,
  renew: PropTypes.bool,
  scrollTop: PropTypes.func,
  foreign: PropTypes.bool,
};

export default Payment;
