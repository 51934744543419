import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path } from 'react-native-svg';

import { Colors } from '../../Theme';

const LensIcon = ({ size=20, color=Colors.gray }) => (
  <Svg width={size} height={size} viewBox={'0 0 20 20'}>
    <G fill={color}>
      <Path d={'M8.90122789,15.5488309 C5.23316036,15.5488309 2.25015349,12.5660756 2.25015349,8.89937646 C2.25015349,5.23267732 5.23316036,2.24992206 8.90122789,2.24992206 C12.5688203,2.24992206 15.5523023,5.23267732 15.5523023,8.89937646 C15.5523023,12.5660756 12.5688203,15.5488309 8.90122789,15.5488309 L8.90122789,15.5488309 Z M14.5931224,13.9187646 C15.7774887,12.5784246 16.5024558,10.8239186 16.5024558,8.89937646 C16.5024558,4.70927027 13.0918798,1.3 8.90122789,1.3 C4.71010086,1.3 1.3,4.70927027 1.3,8.89937646 C1.3,13.0894827 4.71010086,16.4987529 8.90122789,16.4987529 C10.8262389,16.4987529 12.5811723,15.7739624 13.9213638,14.5903595 L20.1329923,20.8 L20.8,20.1236555 L14.5931224,13.9187646 Z'} />
    </G>
  </Svg>
);

LensIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default LensIcon;
