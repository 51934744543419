import { StyleSheet } from 'react-native';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export const BaseStyle = {
  container: {
    flex: 1,
  },
  backButton: {
    padding: 20,
    width: 60
  },
  button: {
    marginBottom: 10,
  },
  buttonContainer: {
    width: '100%',
  },
  innerContainer: {
    flex: 1,
    justifyContent: 'space-between',
  },
  topContainer: {
    height: 40,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 30,
    paddingBottom: 30,
  },
  innerTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  centerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 40,
  },
  bottomContainer: {
    height: 250,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 30,
  },
  lead: {
    ...ApplicationStyles.screen.h1,
    color: Colors.white,
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 0,
  },
  data: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.medium - 2,
    textAlign: 'center',
    marginBottom: 10,
  },
  later: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.regular - 1,
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  laterButton: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
  },
  buyError: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.regular - 2,
  },
  buyNow: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.medium - 2,
    textAlign: 'center',
    marginBottom: 20,
  },
  switchText: {
    alignSelf: 'center',
    flex: 1,
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.regular - 1,
    marginLeft: 10,
  },
  switchWrapper: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 0,
  },
  textInput: {
    ...ApplicationStyles.screen.fontsLight,
    backgroundColor: Colors.white,
    color: Colors.gray,
    width: '100%',
    fontSize: Fonts.size.regular,
    marginBottom: 10,
    paddingBottom: 15,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 30,
  },
  textInput80: {
    ...ApplicationStyles.screen.fontsLight,
    backgroundColor: Colors.white,
    color: Colors.gray,
    width: '80%',
    fontSize: Fonts.size.regular,
    marginBottom: 10,
    paddingBottom: 15,
    paddingTop: 15,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 30,
  },
  upgradeText: {
    textAlign: 'right',
    flex: 1,
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.regular - 1,
  },
};

const StyleMedium = {
  innerContainer: {
    ...BaseStyle.innerContainer,
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });
export default StyleSheet.create(BaseStyle);
