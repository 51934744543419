export default {
  bg_splash: require('../Assets/Images/bg_splash.jpg'),
  tutorial1: require('../Assets/Images/tutorial1.png'),
  tutorial2: require('../Assets/Images/tutorial2.png'),
  tutorial3: require('../Assets/Images/tutorial3.png'),
  tutorial4: require('../Assets/Images/tutorial4.png'),
  tutorial5: require('../Assets/Images/tutorial5.png'),
  bg_header: require('../Assets/Images/bg_header.jpg'),
  bg_tab: require('../Assets/Images/bg_tab.png'),
  bg_tab_repeat: require('../Assets/Images/bg_tab_repeat.png'),
  card_bg: require('../Assets/Images/card_bg.png'),
  card_bg_full: require('../Assets/Images/card_bg_full.png'),
  card: require('../Assets/Images/card.png'),
  bg_buy: require('../Assets/Images/bg_buy.jpg'),
  service_googlefit: require('../Assets/Images/service_googlefit.png'),
  service_salute: require('../Assets/Images/service_salute.png'),
  service_strava: require('../Assets/Images/service_strava.png'),
  button_appstore: require('../Assets/Images/button_appstore.png'),
  button_googleplay: require('../Assets/Images/button_googleplay.png'),
  fidal_logo_footer: require('../Assets/Images/fidal_logo_footer.png'),
  goal: require('../Assets/Images/goal.png'),
  goal_past: require('../Assets/Images/goal_past.png'),
  selfie: [
    require('../Assets/Images/selfie/selfie1_preview.png'),
    require('../Assets/Images/selfie/selfie2_preview.png'),
    require('../Assets/Images/selfie/selfie3_preview.png'),
  ],
  bg_runcard: require('../Assets/Images/bg_runcard.jpg'),
  cards: {
    eps: require('../Assets/Images/cards/eps.png'),
    fitw: require('../Assets/Images/cards/fitw.png'),
    nw: require('../Assets/Images/cards/nw.png'),
    standard: require('../Assets/Images/cards/standard.png'),
    trail: require('../Assets/Images/cards/trail.png'),
  },
  cattolica_intro: require('../Assets/Images/cattolica/intro.png'),
  cattolica_logo: require('../Assets/Images/cattolica/logo.png'),
  cattolica_back: require('../Assets/Images/cattolica/back.jpg'),
}
