import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator, View } from 'react-native';

import Style from './LoaderInlineStyle';
import { Colors } from '../../../Theme';

const LoaderInline = ({ style }) => (
  <View style={style? style : Style.wrapper}>
    <ActivityIndicator size={'large'} color={Colors.blue1} />
  </View>
);

LoaderInline.propTypes = {
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
};

export default LoaderInline;
