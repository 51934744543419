import React from 'react';
import PropTypes from 'prop-types';
import { SafeAreaView, ScrollView, Text, TextInput, View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Style from './ForgotPasswordFormStyle';
import { Button, PrimaryButton } from '../../Buttons';
import { SuccessPopup, ErrorPopup } from '../../Popup';
import { BackIcon } from '../../../Theme/Icons';

import AuthActions from '../../../Stores/Auth/Actions';
import { validateEmail, validatePassword } from '../../../Services/ValidationService';

const ForgotPasswordForm = ({ showBack = true, userEmail, isModal = false }) => {
  const dispatch = useDispatch();

  const errorRequestChangePassword = useSelector(state => state.auth.request_change_password_error);
  const errorChangePassword = useSelector(state => state.auth.change_password_error);
  const step2 = useSelector(state => state.auth.request_change_password_success);
  const changePasswordRequired = useSelector(state => state.auth.change_password_required);
  const successChangePassword = useSelector(state => state.auth.change_password_success);
  const username = useSelector(state => state.auth.change_password_username);
  const user = useSelector(state => state.auth.user);
  const isChange = useSelector(state => state.auth.is_change);
  //const isChange = false;

  const [email, setEmail] = React.useState(username || userEmail || (user ? user.email : ''));
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [code, setCode] = React.useState('');
  const [formError, setFormError] = React.useState('');

  const submitForgotPassword = () => {
    if (!email.length) { setFormError('Email è un campo obbligatorio'); return false; }
    if (!validateEmail(email)) { setFormError('Formato dell\'email non valido'); return false; }
    setFormError('');
    dispatch(AuthActions.requestChangePassword(email));
  };

  const submitNewPassword = () => {
    if (!password.length) { setFormError('Password è un campo obbligatorio'); return false; }
    if (!confirmPassword.length) { setFormError('Conferma password è un campo obbligatorio'); return false; }
    if (password !== confirmPassword) { setFormError('La password non è stata confermata'); return false; }
    if (!validatePassword(password)) { setFormError('La password deve essere di almeno 8 caratteri e contenere almeno un carattere minuscolo, un carattere maiuscolo, un numero ed un carattere speciale'); return false; }
    setFormError('');
    dispatch(AuthActions.changePassword({ email, password, code }));
  };

  return (
    <SafeAreaView style={Style.wrapper}>
      <ScrollView bounces={false}>
        {!step2
          ? (
            <View style={[Style.container, isModal ? Style.containerModal : {}]}>
              {showBack &&
                <View style={Style.closeContainer}>
                  <Button onPress={() => dispatch(AuthActions.endChangePassword())} style={Style.closeButton}>
                    <BackIcon />
                  </Button>
                </View>
              }
              {isChange ? (
                <>
                  <Text style={Style.h1}>Cambia la tua password</Text>
                  {errorRequestChangePassword && <Text style={Style.error}>{errorRequestChangePassword}</Text>}
                  <Text style={Style.lead}>Proseguendo, riceverai una mail con il codice per impostare la tua nuova password</Text>
                  <PrimaryButton onPress={submitForgotPassword} style={Style.button}>
                    <Text>MODIFICA PASSWORD</Text>
                  </PrimaryButton>
                  <View style={Style.skip}>
                    <Button onPress={() => { setFormError(''); dispatch(AuthActions.changePasswordSkipRequest(username)); }} style={Style.skipButton}>
                      <Text style={Style.skipText}>Ho già il codice</Text>
                    </Button>
                  </View>
                </>
              ) : (
                <>
                  <Text style={Style.h1}>{changePasswordRequired ? 'Cambia la tua password' : 'Hai dimenticato la password?'}</Text>
                  <View style={Style.textWrapper}><Text style={Style.lead}>Inserisci il tuo indirizzo email per reimpostare la tua password</Text></View>
                  {errorRequestChangePassword && <Text style={Style.error}>{errorRequestChangePassword}</Text>}
                  {!!formError.length && <Text style={Style.error}>{formError}</Text>}
                  <TextInput style={Style.textInput} textContentType={'emailAddress'} autoCorrect={false} autoCapitalize={'none'} keyboardType={'email-address'} placeholder={'Email'} value={email} onChangeText={setEmail} />
                  <View style={Style.textWrapper}><Text style={Style.lead}>A breve riceverai una mail con il codice per impostare la tua nuova password</Text></View>
                  <PrimaryButton onPress={submitForgotPassword} disabled={!email.length} style={Style.button}>
                    <Text>RECUPERA PASSWORD</Text>
                  </PrimaryButton>
                  <View style={Style.skip}>
                    <Button onPress={() => { if (!email.length) { setFormError('Email è un campo obbligatorio'); return false; } else { setFormError(''); dispatch(AuthActions.changePasswordSkipRequest(email)); } }} style={Style.skipButton}>
                      <Text style={Style.skipText}>Ho già il codice</Text>
                    </Button>
                  </View>
                  <View style={Style.sectionWrapper}>
                    <Button onPress={() => dispatch(AuthActions.endChangePassword())}>
                      <Text style={Style.backText}>Torna al <Text style={Style.back}>LOGIN</Text></Text>
                    </Button>
                  </View>
                </>
              )
              }

              {successChangePassword && (
                <SuccessPopup title={'Password modificata con successo!'} text={'Adesso puoi effettuare il login con la nuova password'} closeHandler={() => dispatch(AuthActions.endChangePassword())} />
              )}
              {errorChangePassword && (
                <ErrorPopup title={'Errore nella modifica password!'} text={errorChangePassword} closeHandler={() => { if (!isChange) { dispatch(AuthActions.endChangePassword()) } else { dispatch(AuthActions.changePasswordFailure(null)); dispatch(AuthActions.requestChangePasswordSuccess(username)); } }} />
              )}
            </View>
          )
          : (
            <View style={[Style.container, isModal ? Style.containerModal : {}]}>
              {showBack &&
                <View style={Style.closeContainer}>
                  <Button onPress={() => dispatch(AuthActions.endChangePassword())} style={Style.closeButton}>
                    <BackIcon />
                  </Button>
                </View>
              }
              <Text style={Style.h1}>{changePasswordRequired || isChange ? 'Cambia la tua password' : 'Inserisci password'}</Text>
              <Text style={Style.lead}>
                {changePasswordRequired || isChange
                  ? 'Scegli e conferma la tua nuova password ed inserisci il codice di verifica'
                  : 'Inserisci e conferma la tua nuova password ed il codice di verifica'
                }
                {username && `che hai ricevuto all'indirizzo ${username}`}
              </Text>
              {errorChangePassword && <Text style={Style.error}>{errorChangePassword}</Text>}
              {!!formError.length && <Text style={Style.error}>{formError}</Text>}
              <TextInput style={Style.textInput} textContentType={'password'} autoCorrect={false} secureTextEntry={true} placeholder={'Nuova password'} value={password} onChangeText={setPassword} />
              <TextInput style={Style.textInput} textContentType={'password'} autoCorrect={false} secureTextEntry={true} placeholder={'Conferma password'} value={confirmPassword} onChangeText={setConfirmPassword} />
              <TextInput style={Style.textInput} autoCorrect={false} placeholder={'Codice di conferma'} value={code} onChangeText={setCode} />
              <PrimaryButton onPress={submitNewPassword} disabled={(!password.length || !confirmPassword.length || !code.length)} style={Style.button}>
                <Text>SALVA PASSWORD</Text>
              </PrimaryButton>
              {!isChange && (
                <View style={Style.sectionWrapper}>
                  <Button onPress={() => dispatch(AuthActions.endChangePassword())}>
                    <Text style={Style.backText}>Torna al <Text style={Style.back}>LOGIN</Text></Text>
                  </Button>
                </View>
              )}
            </View>
          )
        }
      </ScrollView>
    </SafeAreaView>
  )
};

ForgotPasswordForm.propTypes = {
  userEmail: PropTypes.string,
  showBack: PropTypes.bool,
  isModal: PropTypes.bool,
};

export default ForgotPasswordForm;
