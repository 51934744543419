import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getSocialWallData: ['loader'],
  setSocialWallData: ['socialwall_data'],
  setSocialWallDataError: ['error']
});

export const SocialWallTypes = Types;
export default Creators;
