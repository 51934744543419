import React from 'react';
import PropTypes from 'prop-types';
import { ImageBackground } from 'react-native';

import Style from './BackgroundImageStyle';
import { Images } from '../../../Theme';

const BackgroundImage = ({ children }) => {
  return (
    <ImageBackground source={Images.bg_splash} style={Style.background}>
      {children}
    </ImageBackground>
  )
};

BackgroundImage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BackgroundImage;
