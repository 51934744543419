import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text } from 'react-native';

import { getResponsive } from './SocialWallStyle';
import { Footer, PageHeader } from '../../Layout';
import List from './List';
import FullImage from './FullImage';

import Analytics from '../../../Services/AnalyticsService';
import SocialWallActions from '../../../Stores/SocialWall/Actions';

const Style = getResponsive();

const SocialWall = () => {
  const dispatch = useDispatch();

  const [fullImage, setFullImage] = React.useState(false);

  const { socialwall_data: data, socialwall_data_error: error } = useSelector(state => state.social_wall);

  React.useEffect(() => {
    if (!data && !error) {
      console.log('dispatch')
      dispatch(SocialWallActions.getSocialWallData());
    }
    Analytics.getInstance().trackSocialWallView();
  }, []);

  return (
    <>
      <View style={Style.wrapper}>
        <PageHeader title={'Social Wall'} />
        {data && <List setFullImage={setFullImage} images={data} />}
        {error && <Text style={Style.error}>Non è stato possibile recuperare le immagini</Text>}
        <Footer />
      </View>
      {fullImage && <FullImage setFullImage={setFullImage} fullImage={fullImage} images={data} />}
    </>
  )
};

export default SocialWall;
