import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop, Mask, Use } from 'react-native-svg';

const TabActivityIcon = ({ active=false, size=26 }) => {
  return (
    <Svg width={size} height={size} viewBox={'0 0 26 26'}>
      <Defs>
        <Path d={'M13,0 C9.52714409,0 6.2631753,1.3521788 3.80810686,3.80810686 L3.80810686,3.80810686 C1.3521788,6.26231568 0,9.52714409 0,12.9991404 L0,12.9991404 C0,16.4728559 1.3521788,19.7368247 3.80810686,22.1927528 L3.80810686,22.1927528 C6.2631753,24.6486808 9.52714409,26 13,26 L13,26 C16.4728559,26 19.7376843,24.6486808 22.1927528,22.1927528 L22.1927528,22.1927528 C24.6160153,19.7703498 25.9647557,16.5596773 26,13.1383985 L26,13.1383985 L26,12.8607419 C25.9647557,9.44032269 24.6160153,6.2296502 22.1927528,3.80810686 L22.1927528,3.80810686 C19.7376843,1.3521788 16.4728559,0 13,0 Z M2.03127686,12.9991404 C2.03127686,6.95086954 6.95172915,2.03127686 13,2.03127686 L13,2.03127686 C19.0482708,2.03127686 23.9695828,6.95086954 23.9695828,12.9991404 L23.9695828,12.9991404 C23.9695828,19.0482708 19.0482708,23.9687231 13,23.9687231 L13,23.9687231 C6.95172915,23.9687231 2.03127686,19.0482708 2.03127686,12.9991404 L2.03127686,12.9991404 Z M11.3658666,18.5548502 L14.6375719,18.5548502 L14.6375719,5.90901276 L11.3658666,5.90901276 L11.3658666,18.5548502 Z M15.9631026,18.5548502 L19.2356675,18.5548502 L19.2356675,8.71136679 L15.9631026,8.71136679 L15.9631026,18.5548502 Z M6.76519209,18.5548502 L10.0368974,18.5548502 L10.0368974,12.9166171 L6.76519209,12.9166171 L6.76519209,18.5548502 Z'} id={'pathActivity'} />
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={'gradientActivity'}>
          <Stop stopColor={active ? '#02AFF1' : '#E3E4E3'} offset="0%" />
          <Stop stopColor={active ? '#0D85F1' : '#C3C4C3'} offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
        <Mask id={'maskActivity'} fill={'white'}>
          <Use href={'#pathActivity'} />
        </Mask>
        <Path d={'M13,0 C9.52714409,0 6.2631753,1.3521788 3.80810686,3.80810686 L3.80810686,3.80810686 C1.3521788,6.26231568 0,9.52714409 0,12.9991404 L0,12.9991404 C0,16.4728559 1.3521788,19.7368247 3.80810686,22.1927528 L3.80810686,22.1927528 C6.2631753,24.6486808 9.52714409,26 13,26 L13,26 C16.4728559,26 19.7376843,24.6486808 22.1927528,22.1927528 L22.1927528,22.1927528 C24.6160153,19.7703498 25.9647557,16.5596773 26,13.1383985 L26,13.1383985 L26,12.8607419 C25.9647557,9.44032269 24.6160153,6.2296502 22.1927528,3.80810686 L22.1927528,3.80810686 C19.7376843,1.3521788 16.4728559,0 13,0 Z M2.03127686,12.9991404 C2.03127686,6.95086954 6.95172915,2.03127686 13,2.03127686 L13,2.03127686 C19.0482708,2.03127686 23.9695828,6.95086954 23.9695828,12.9991404 L23.9695828,12.9991404 C23.9695828,19.0482708 19.0482708,23.9687231 13,23.9687231 L13,23.9687231 C6.95172915,23.9687231 2.03127686,19.0482708 2.03127686,12.9991404 L2.03127686,12.9991404 Z M11.3658666,18.5548502 L14.6375719,18.5548502 L14.6375719,5.90901276 L11.3658666,5.90901276 L11.3658666,18.5548502 Z M15.9631026,18.5548502 L19.2356675,18.5548502 L19.2356675,8.71136679 L15.9631026,8.71136679 L15.9631026,18.5548502 Z M6.76519209,18.5548502 L10.0368974,18.5548502 L10.0368974,12.9166171 L6.76519209,12.9166171 L6.76519209,18.5548502 Z'} fill={'url(#gradientActivity)'} mask={'url(#maskActivity)'} />
      </G>
    </Svg>
  )
};

TabActivityIcon.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
};

export default TabActivityIcon;
