import moment from 'moment';
import 'moment/locale/it.js';
moment.locale('it');

export const localize = () => {
  let localized = { monthNames:[], monthNamesShort: [], dayNames: [], dayNamesShort: [], today: 'Oggi' };
  for (let i=0; i<12; i++) {
    localized.monthNames.push(moment().month(i).format('MMMM')[0].toUpperCase()+moment().month(i).format('MMMM').substring(1));
    localized.monthNamesShort.push(moment().month(i).format('MMM'));
  }
  for (let i=0; i<7; i++) {
    localized.dayNames.push(moment().day(i).format('dddd'));
    localized.dayNamesShort.push(moment().day(i).format('dddd')[0].toUpperCase());
  }
  return localized;
};
