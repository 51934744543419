import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polyline } from 'react-native-svg';

import { Colors } from '../../Theme';

const DownArrow = ({ size=19, color=Colors.grayLight }) => (
  <Svg width={size} height={size} viewBox={'0 0 19 19'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'} strokeLinecap={'round'}>
      <G transform={'translate(-324.000000, -532.000000)'} stroke={color}>
        <Polyline transform={'translate(333.500000, 536.500000) rotate(-90.000000) translate(-333.500000, -536.500000)'} points={'337 545.5 330 536.5 337 527.5'} />
      </G>
    </G>
  </Svg>
);

DownArrow.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default DownArrow;
