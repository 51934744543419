import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { AppRegistry } from 'react-native';

import './styles/datepicker.css';
import App from 'components/src/App/App';

AppRegistry.registerComponent('runcard', () => App);
AppRegistry.runApplication('runcard', {
  rootTag: document.getElementById('root'),
});
