import { put, call } from "redux-saga/effects";

import OrderActions from "../Stores/Order/Actions";
import LayoutActions from "../Stores/Layout/Actions";
import { startOrder as startOrderAPI } from "../Services/ApiGatewayService";

export function* startOrder({ card_type, renew }) {
  yield put(LayoutActions.setLoading(true));
  try {
    const order = yield call(startOrderAPI, {
      card_type,
      renew,
    });
    yield put(OrderActions.setOrder(order.order_id));
  } catch (err) {
    yield put(OrderActions.setOrderError(err.response || err));
  }
  yield put(LayoutActions.setLoading(false));
}
