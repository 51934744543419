import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getRaces: ['loader', 'filters'],
  setRaces: ['data'],
  setRacesError: ['error'],
  openRace: ['id'],
  setOpenRace: ['race'],
  setOpenRaceError: ['error'],
  saveRace: ['loader', 'race'],
  unSaveRace: ['loader', 'race'],
  getSavedRaces: ['loader', 'date'],
  setSavedRaces: ['data'],
  setSavedRacesError: ['error'],
});

export const RacesTypes = Types;
export default Creators;
