import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setWorkoutData: ['workout_data'],
  getGoogleFitData: ['loader'],
  setGoogleFitDataSuccess: ['status'],
  setGoogleFitDataError: ['error'],
  resetGoogleFitData: [],
  getStravaData: ['loader'],
  setStravaDataSuccess: ['status'],
  setStravaDataError: ['error'],
  resetStravaData: [],
  getSaluteData: ['loader'],
  setSaluteDataSuccess: ['status'],
  setSaluteDataError: ['error'],
  resetSaluteData: [],
  setWorkoutsFetched: ['fetched'],
});

export const WorkoutTypes = Types;
export default Creators;
