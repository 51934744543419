import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-native';

import { RouteWithSubRoutes } from '../../Router/Router';
import LayoutActions from '../../Stores/Layout/Actions';
import { PageNotFound } from '../../Components/Layout';
import Analytics from '../../Services/AnalyticsService';

const Profile = ({ routes }) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.auth.user);

  React.useEffect(() => {
    dispatch(LayoutActions.setTab(null));
  }, []);

  React.useEffect(() => {
    Analytics.getInstance().trackProfileView();
  }, []);

  return user && (
    <>
      <Switch>
        {routes.map(route => (<RouteWithSubRoutes key={route.name} {...route} />))}
        <Route path='*'>
          <PageNotFound />
        </Route>
      </Switch>
    </>
  );
};

Profile.propTypes = {
  routes: PropTypes.array,
};

export default Profile;
