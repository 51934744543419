import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';

import { Button, ButtonSizes } from '../';
import Style from './SecondaryButtonStyle';

const SecondaryButton = ({ size='medium', onPress, children, disabled=false, style={}, route }) => {
  return (
    <Button route={route} onPress={onPress} style={[Style.button, { height: ButtonSizes[size] }, disabled ? Style.buttonDisabled : {}, size==='xsmall' ? Style.xSmallButton : {}, style]} disabled={disabled}>
      <Text style={[Style.text, size==='xsmall' ? Style.xSmallText : {}]}>{children}</Text>
    </Button>
  )
};

SecondaryButton.propTypes = {
  size: PropTypes.oneOf(['medium', 'small', 'xsmall']),
  onPress: PropTypes.func,
  route: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
};

export default SecondaryButton;
