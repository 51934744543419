import React from "react";
import {
  View,
  Text,
  Switch,
  Platform,
  Linking,
  FlatList,
  TouchableOpacity,
  Image,
} from "react-native";
import PropTypes from "prop-types";
import { Link as NativeLink } from "react-router-native";

import { ConfigCattolica } from "../../../../Config/cattolica";
import { Config } from "../../../../Config";

import { getResponsive } from "./ChooseStyle";
import { Button, Link, PrimaryButton } from "../../../Buttons";
import { DipIcon } from "../../../../Theme/Icons";
import { ModalDownload, SlideItem } from "../../../Layout";
import { isDesktop } from "../../../../Services/ResponsiveHelper";

const Choose = ({
  clickHandler,
  setSelected,
  selected,
  readMore,
  setReadMore,
  scrollTop,
}) => {
  const Style = getResponsive();

  const [PDFOpen, setPDFOpen] = React.useState(null);

  const handleDownload = (url) => {
    if (Platform.OS === "ios") {
      setPDFOpen(url);
    } else {
      Linking.openURL(url);
    }
  };

  // const [listUrl, setListUrl] = React.useState([]);

  React.useEffect(() => {}, []);
  let list3 = [
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/Allegato_3.pdf",
      name: "Visualizza le informazioni sul distributore",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/Allegato_4_Satec_TUA_Assicurazioni.pdf",
      name: "Visualizza le informazioni sulla distribuzione",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/Allegato_4_Satec_IVASS.pdf",
      name:
        "Visualizza l' elenco delle regole di comportamento del distributore",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/PrivacyTUA_Individuale.pdf",
      name: "Visualizza le informativa privacy TUA Assicurazioni",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/Viaggio_Sereno_CGA.pdf",
      name: "Visualizza il CGA",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/Viaggio_Sereno_DIP.pdf",
      name: "Visualizza il DIP",
      icon: 2,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/Viaggio_Sereno_DIP%2B.pdf",
      name: "Visualizza il DIP aggiuntivo",
      icon: 2,
    },
  ];

  const list2 = [
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/allenamento_protetto/Allegato_3.pdf",
      name: "Visualizza le informazioni sul distributore",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/allenamento_protetto/Allegato_4_Satec_TUA_Assicurazioni.pdf",
      name: "Visualizza le informazioni sulla distribuzione",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/Allegato_4_Satec_IVASS.pdf",
      name:
        "Visualizza l' elenco delle regole di comportamento del distributore",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/PrivacyTUA_Individuale.pdf",
      name: "Visualizza le informativa privacy TUA Assicurazioni",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/allenamento_protetto/Allenamento_Protetto_CGA.pdf",
      name: "Visualizza il CGA",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/allenamento_protetto/Allenamento_Protetto_DIP.pdf",
      name: "Visualizza il DIP",
      icon: 2,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/allenamento_protetto/Allenamento_Protetto_DIP%2B.pdf",
      name: "Visualizza il DIP aggiuntivo",
      icon: 2,
    },
  ];

  const ListUrl = [
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/gara_sicura/Allegato_3.pdf",
      name: "Visualizza le informazioni sul distributore",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/gara_sicura/Allegato_4_Satec_TUA_Assicurazioni.pdf",
      name: "Visualizza le informazioni sulla distribuzione",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/Allegato_4_Satec_IVASS.pdf",
      name:
        "Visualizza l' elenco delle regole di comportamento del distributore",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/viaggio_sereno/PrivacyTUA_Individuale.pdf",
      name: "Visualizza le informativa privacy TUA Assicurazioni",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/gara_sicura/Gara_Sicura_CGA.pdf",
      name: "Visualizza il CGA",
      icon: 1,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/gara_sicura/Gara_Sicura_DIP.pdf",
      name: "Visualizza il DIP",
      icon: 2,
    },
    {
      url:
        "https://runcard-cdn.dshare.cloud/app/preprod/cattolica/gara_sicura/Gara_Sicura_DIP%2B.pdf",
      name: "Visualizza il DIP aggiuntivo",
      icon: 2,
    },
  ];

  const DipLink = ({ dip, text = "" }) =>
    Platform.OS === "web" ? (
      <View style={Style.dip}>
        <Link href={`${Config.CDN}${dip}`} style={Style.dipA}>
          <Text style={Style.dipLink}>Visualizza il DIP {text}</Text>
          <DipIcon />
        </Link>
      </View>
    ) : (
      <Button
        style={Style.dip}
        onPress={() => handleDownload(`${Config.CDN}${dip}`)}
      >
        <Text style={Style.dipLink}>Visualizza il DIP {text}</Text>
        <DipIcon />
      </Button>
    );

  DipLink.propTypes = {
    dip: PropTypes.string.isRequired,
    text: PropTypes.string,
  };

  return readMore === null || isDesktop() ? (
    <View style={Style.wrapper}>
      <Text style={Style.title}>
        Scegli la <Text style={Style.titleBold}>Polizza</Text>
      </Text>
      <Text style={Style.subtitle}>più adatta alle tue esigenze</Text>
      {Object.keys(ConfigCattolica).map((type) => (
        <View key={type} style={Style.switchContainer}>
          {!isDesktop() && (
            <Switch
              onValueChange={(val) =>
                val ? setSelected(type) : setSelected(null)
              }
              value={selected === type}
            />
          )}
          <View style={Style.switchInner}>
            <Text
              style={[
                Style.switchTitle,
                selected === type ? Style.switchTitleSelected : {},
              ]}
            >
              {ConfigCattolica[type].title}{" "}
              <Text
                style={[
                  Style.switchSubtitle,
                  selected === type ? Style.switchTitleSelected : {},
                ]}
              >
                {ConfigCattolica[type].subtitle}
              </Text>
            </Text>
            <Text style={Style.switchText}>
              {ConfigCattolica[type].description}
            </Text>
            {!isDesktop() ? (
              <Button
                onPress={() => {
                  setReadMore(type);
                  scrollTop();
                }}
              >
                <Text style={Style.switchMore}>Scopri tutti i dettagli</Text>
              </Button>
            ) : (
              <>
                <View style={Style.buttonContainer}>
                  <PrimaryButton
                    onPress={() => {
                      setSelected(type);
                      clickHandler();
                    }}
                    size={"xsmall"}
                  >
                    ACQUISTA
                  </PrimaryButton>
                </View>
                <SlideItem
                  title={"DETTAGLI"}
                  titleStyle={Style.accordionTitle}
                  open={readMore === type}
                  callback={(open) => setReadMore(open ? type : null)}
                  customOpenArrow={<Text style={Style.accordionTitle}>+</Text>}
                  customCloseArrow={<Text style={Style.accordionTitle}>-</Text>}
                  border={false}
                >
                  <View style={Style.slideItemContent}>
                    {/* {ConfigCattolica[type].dip && (
                      // <DipLink dip={ConfigCattolica[type].dip} />
                    )} */}
                    <View
                      style={{
                        flex: 1,
                        // alignItems: "flex-end",
                      }}
                    >
                      {ConfigCattolica[type].title === "GARA SICURA" && (
                        <FlatList
                          data={ListUrl}
                          renderItem={({ item }) => {
                            return (
                              <TouchableOpacity
                                onPress={() => {
                                  window.open(item.url);
                                }}
                                style={{
                                  // alignItems: "flex-end",
                                  paddingVertical: 15,
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                {item.icon === 5 ? <DipIcon /> : <DipIcon />}
                                <Text
                                  numberOfLines={2}
                                  style={{
                                    color: "#56B64A",
                                  }}
                                >
                                  {item.name}
                                </Text>
                              </TouchableOpacity>
                            );
                          }}
                        />
                      )}
                      {ConfigCattolica[type].title ===
                        "ALLENAMENTO PROTETTO" && (
                        <FlatList
                          data={list2}
                          renderItem={({ item }) => {
                            return (
                              <TouchableOpacity
                                onPress={() => {
                                  window.open(item.url);
                                }}
                                style={{
                                  // alignItems: "flex-end",
                                  paddingVertical: 15,
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <DipIcon />
                                <Text
                                  numberOfLines={2}
                                  style={{
                                    color: "#56B64A",
                                  }}
                                >
                                  {item.name}
                                </Text>
                              </TouchableOpacity>
                            );
                          }}
                        />
                      )}
                      {ConfigCattolica[type].title === "VIAGGIO SERENO" && (
                        <FlatList
                          data={list3}
                          renderItem={({ item }) => {
                            return (
                              <TouchableOpacity
                                onPress={() => {
                                  window.open(item.url);
                                }}
                                style={{
                                  // alignItems: "flex-end",
                                  paddingVertical: 15,
                                  flexDirection: "row",
                                  alignItems: "center",
                                }}
                              >
                                <DipIcon />
                                <Text
                                  numberOfLines={2}
                                  style={{
                                    color: "#56B64A",
                                  }}
                                >
                                  {item.name}
                                </Text>
                              </TouchableOpacity>
                            );
                          }}
                        />
                      )}
                    </View>
                    {/* {ConfigCattolica[type].dip_2 && (
                      <DipLink
                        dip={ConfigCattolica[type].dip_2}
                        text={"aggiuntivo"}
                      />
                    )} */}
                    <Text style={Style.readMoreText}>
                      {ConfigCattolica[type].readMore}
                    </Text>
                    <Text style={Style.readMoreBold}>
                      {ConfigCattolica[type].readMoreData}
                    </Text>
                  </View>
                </SlideItem>
              </>
            )}
          </View>
        </View>
      ))}
      {!isDesktop() && (
        <PrimaryButton
          onPress={() => clickHandler()}
          disabled={selected === null}
        >
          AVANTI
        </PrimaryButton>
      )}
    </View>
  ) : (
    <View>
      <Text style={Style.switchTitle}>
        {ConfigCattolica[readMore].title}{" "}
        <Text style={Style.switchSubtitle}>
          {ConfigCattolica[readMore].subtitle}
        </Text>
      </Text>
      {/* {ConfigCattolica[readMore].dip && (
        <DipLink dip={ConfigCattolica[readMore].dip} />
      )}
      {ConfigCattolica[readMore].dip_2 && (
        <DipLink dip={ConfigCattolica[readMore].dip_2} text={"aggiuntivo"} />
      )} */}
      {ConfigCattolica[readMore].title === "GARA SICURA" && (
        <FlatList
          data={ListUrl}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL(item.url);
                }}
                style={{
                  // alignItems: "flex-end",
                  paddingVertical: 15,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <DipIcon />
                <Text
                  numberOfLines={2}
                  style={{
                    color: "#56B64A",
                    maxWidth: "80%",
                    fontSize: 20,
                  }}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            );
          }}
        />
      )}
      {ConfigCattolica[readMore].title === "ALLENAMENTO PROTETTO" && (
        <FlatList
          data={list2}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL(item.url);
                }}
                style={{
                  // alignItems: "flex-end",
                  paddingVertical: 15,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <DipIcon />
                <Text
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  style={{
                    color: "#56B64A",
                    maxWidth: "80%",
                    fontSize: 20,
                  }}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            );
          }}
        />
      )}
      {ConfigCattolica[readMore].title === "VIAGGIO SERENO" && (
        <FlatList
          data={list3}
          renderItem={({ item }) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  Linking.openURL(item.url);
                }}
                style={{
                  // alignItems: "flex-end",
                  paddingVertical: 15,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <DipIcon />
                <Text
                  numberOfLines={2}
                  style={{
                    color: "#56B64A",
                    maxWidth: "80%",
                    fontSize: 20,
                  }}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            );
          }}
        />
      )}
      <Text style={Style.readMoreText}>
        {ConfigCattolica[readMore].description}
        {"\n"}
        {"\n"}
        {ConfigCattolica[readMore].readMore}
      </Text>
      <Text style={Style.readMoreBold}>
        {ConfigCattolica[readMore].readMoreData}
      </Text>
      <PrimaryButton
        onPress={() => {
          setSelected(readMore);
          clickHandler();
        }}
        style={Style.button}
      >
        AVANTI
      </PrimaryButton>
      {PDFOpen && (
        <ModalDownload
          title={"DIP"}
          url={PDFOpen}
          closeHandler={() => setPDFOpen(null)}
        />
      )}
    </View>
  );
};

Choose.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.string,
  readMore: PropTypes.string,
  setReadMore: PropTypes.func.isRequired,
  scrollTop: PropTypes.func.isRequired,
};

export default Choose;
