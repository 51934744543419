import React from 'react';
import PropTypes from 'prop-types';
import ModalWeb from 'modal-react-native-web';

import { Loader } from '../index';

const Modal = ({ children, animationType, transparent, onRequestClose }) => {
  return (
    <ModalWeb ariaHideApp={false} animationType={animationType} transparent={transparent} onRequestClose={onRequestClose}>
      {children}
      <Loader />
    </ModalWeb>
  )
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  animationType: PropTypes.string.isRequired,
  transparent: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default Modal;
