import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Image, View, ScrollView, Platform } from "react-native";
import { useHistory } from "react-router-native";

import { getResponsive } from "./CattolicaStyle";
import { Intro, Choose, Form, Quotation, Payment, FormVerify } from "./";
import { Footer, PageHeader } from "../../Layout";
import { Button } from "../../Buttons";
import { BackIcon } from "../../../Theme/Icons";
import { Images } from "../../../Theme";

import CattolicaActions from "../../../Stores/Cattolica/Actions";
import { getPath } from "../../../Router/AppRoutes";
import { isDesktop } from "../../../Services/ResponsiveHelper";

const Cattolica = () => {
  const Style = getResponsive();
  const dispatch = useDispatch();
  let history = useHistory();
  let listView;

  const quotation = useSelector((state) => state.cattolica.quotation);

  const [step, setStep] = React.useState(isDesktop() ? 1 : 0);
  const [readMore, setReadMore] = React.useState(null);
  const [selected, setSelected] = React.useState(null);

  React.useEffect(() => {
    dispatch(CattolicaActions.setQuotation(null));
  }, []);

  React.useEffect(() => {
    if (quotation !== null && step === 2) {
      setStep(3);
    }
    if (quotation === null && step === 3) {
      setStep(2);
    }
  }, [quotation]);

  React.useEffect(() => {
    scrollTop();
  }, [step]);

  const backHandler = () => {
    if (step === 0 || (step === 1 && isDesktop())) {
      history.push(getPath("play"));
    }
    if (step === 1 && readMore) {
      setReadMore(null);
    } else {
      setStep(step - 1);
    }
  };

  const scrollTop = () => {
    if (listView) listView.scrollTo({ y: 0, animated: false });
    if (Platform.OS === "web" && window) {
      window.scroll(0, 0);
    }
  };

  const Logo = () => (
    <View style={Style.logoContainer}>
      <View style={Style.logoRow} />
      <Image
        source={Images.cattolica_logo}
        style={Style.img}
        resizeMode={"contain"}
      />
      <View style={Style.logoRow} />
    </View>
  );

  const steps = {
    0: (
      <View style={Style.wrapper}>
        <Intro clickHandler={() => setStep(1)} />
      </View>
    ),
    1: (
      <ScrollView
        style={Style.wrapperScroll}
        ref={(ref) => (listView = ref)}
        bounces={false}
      >
        <View style={Style.wrapper}>
          {isDesktop() ? <Intro clickHandler={() => {}} /> : null}
          <Logo />
          <Choose
            scrollTop={scrollTop}
            readMore={readMore}
            setReadMore={setReadMore}
            clickHandler={() => setStep(2)}
            setSelected={setSelected}
            selected={selected}
          />
        </View>
      </ScrollView>
    ),
    2: (
      <ScrollView
        style={Style.wrapperScroll}
        ref={(ref) => (listView = ref)}
        bounces={false}
      >
        <View style={Style.wrapper}>
          {!isDesktop() && <Logo />}
          <Form
            selected={selected}
            scrollTop={scrollTop}
            clickHandler={() => setStep(3)}
          />
        </View>
      </ScrollView>
    ),
    3: (
      <ScrollView
        style={Style.wrapperScroll}
        ref={(ref) => (listView = ref)}
        bounces={false}
      >
        <View style={Style.wrapper}>
          {!isDesktop() && <Logo />}
          <FormVerify
            selected={selected}
            scrollTop={scrollTop}
            clickHandler={() => setStep(4)}
            abort={() => {
              setStep(0);
            }}
          />
        </View>
      </ScrollView>
    ),
    4: (
      <ScrollView
        style={Style.wrapperScroll}
        ref={(ref) => (listView = ref)}
        bounces={false}
      >
        <View style={Style.wrapper}>
          {!isDesktop() && <Logo />}
          <Quotation clickHandler={() => setStep(5)} back={() => setStep(3)} />
        </View>
      </ScrollView>
    ),
    5: (
      <ScrollView
        style={!isDesktop() ? Style.wrapperScrollPayment : Style.wrapperScroll}
        ref={(ref) => (listView = ref)}
        bounces={false}
      >
        <View style={isDesktop() ? Style.wrapper : {}}>
          <Payment scrollTop={scrollTop} clickHandler={() => setStep(0)} />
        </View>
      </ScrollView>
    ),
  };

  return (
    <View style={Style.container}>
      <PageHeader
        title={"TUA Assicurazioni"}
        desc={"Sempre con te"}
        back={false}
        left={
          <Button onPress={backHandler}>
            <BackIcon />
          </Button>
        }
      />
      {steps[step] ? steps[step] : null}
      <Footer />
    </View>
  );
};

export default Cattolica;
