const packageJson = require('../../../../../packages/web/package.json');

export default {
  STAGE: process.env.REACT_APP_STAGE,
  AWS_IDENTITY_POOL: process.env.REACT_APP_AWS_IDENTITY_POOL,
  AWS_REGION: process.env.REACT_APP_AWS_REGION,
  AWS_USER_POOL_ID: process.env.REACT_APP_AWS_USER_POOL_ID,
  AWS_USER_POOL_WEBCLIENT_ID: process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID,
  AWS_OAUTH_DOMAIN: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
  AWS_OAUTH_REDIRECT_SIGNIN: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNIN,
  AWS_OAUTH_REDIRECT_SIGNOUT: process.env.REACT_APP_AWS_OAUTH_REDIRECT_SIGNOUT,
  AWS_IOT_ENDPOINT: process.env.REACT_APP_AWS_IOT_ENDPOINT,
  AWS_S3_BUCKET: process.env.REACT_APP_AWS_S3_BUCKET,
  AWS_API: process.env.REACT_APP_AWS_API,
  AWS_API_KEY: process.env.REACT_APP_AWS_API_KEY_WEB,
  NEXI_SUCCESS: process.env.REACT_APP_NEXI_SUCCESS,
  NEXI_CANCEL: process.env.REACT_APP_NEXI_CANCEL,
  NEXI_RUNCARD_ENV: process.env.REACT_APP_NEXI_RUNCARD_ENV,
  NEXI_RUNCARD_ALIAS: process.env.REACT_APP_NEXI_RUNCARD_ALIAS,
  NEXI_RUNCARD_SECRET: process.env.REACT_APP_NEXI_RUNCARD_SECRET,
  NEXI_CATTOLICA_ENV: process.env.REACT_APP_NEXI_CATTOLICA_ENV,
  NEXI_CATTOLICA_ALIAS: process.env.REACT_APP_NEXI_CATTOLICA_ALIAS,
  NEXI_CATTOLICA_SECRET: process.env.REACT_APP_NEXI_CATTOLICA_SECRET,
  STRAVA_CLIENT_ID: process.env.REACT_APP_STRAVA_CLIENT_ID,
  STRAVA_CLIENT_SECRET: process.env.REACT_APP_STRAVA_CLIENT_SECRET,
  STRAVA_REDIRECT_URI: process.env.REACT_APP_STRAVA_REDIRECT_URI,
  FIREBASE_SENDER_ID: process.env.REACT_APP_FIREBASE_SENDER_ID,
  FIREBASE_WEB_API_KEY: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
  FIREBASE_WEB_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_WEB_AUTH_DOMAIN,
  FIREBASE_WEB_DB_URL: process.env.REACT_APP_FIREBASE_WEB_DB_URL,
  FIREBASE_WEB_PROJECT_ID: process.env.REACT_APP_FIREBASE_WEB_PROJECT_ID,
  FIREBASE_WEB_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_WEB_STORAGE_BUCKET,
  FIREBASE_WEB_MESSAGING_SENDER_ID: process.env.REACT_APP_FIREBASE_WEB_MESSAGING_SENDER_ID,
  FIREBASE_WEB_APP_ID: process.env.REACT_APP_FIREBASE_WEB_APP_ID,
  FIREBASE_WEB_MEASUREMENT_IT: process.env.REACT_APP_FIREBASE_WEB_MEASUREMENT_IT,
  WEBSITE_URL: process.env.REACT_APP_WEBSITE_URL,
  CDN: process.env.REACT_APP_CDN,
  LEADERBOARD_TEAM_ID: process.env.REACT_APP_LEADERBOARD_TEAM_ID,
  VERSION: packageJson.version,
}
