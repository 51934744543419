import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export const BaseStyle = {
  backButton: {
    padding: 20,
    width: 60
  },
  closeContainer: {
    marginLeft: 30,
    marginTop: 30,
  },
  container: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  containerWeb: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: 10,
    marginBottom: 30,
    marginTop: 30,
    maxWidth: 450,
    minWidth: 400,
  },
  error: {...ApplicationStyles.screen.fontsRegular,
    color: Colors.red,
    fontSize: Fonts.size.regular-2,
    marginBottom: 10,
  },
  forgotPassword: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  forgotPasswordButton: {
    paddingBottom: 15,
  },
  forgotPasswordText: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
  },
  genderButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
    marginTop: 10,
  },
  h1: {
    ...ApplicationStyles.screen.h1,
    marginBottom: 20,
    textAlign: 'center',
  },
  loginButton: {
    marginBottom: 30,
    marginTop: 20,
  },
  marginTop: {
    marginTop: 10,
  },
  modal: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  modalContainer: {
    flex: 1,
  },
  register: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-1,
  },
  registerLink: {
    paddingBottom: 10,
    paddingTop: 10,
    alignItems: 'center',
  },
  registerText: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
  },
  sectionWrapper: {
    borderColor: Colors.grayUltraLight,
    borderTopWidth: 1,
    paddingBottom: 20,
    paddingTop: 20,
  },
  sectionWrapperStrike: {
    marginTop: 20,
  },
  socialText: {
    ...ApplicationStyles.screen.fontsLight,
    backgroundColor: Colors.white,
    fontSize: Fonts.size.regular-1,
    marginBottom: 10,
    marginTop: -30,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  textInput: {
    ...ApplicationStyles.screen.fontsLight,
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
    color: Colors.gray,
    fontSize: Fonts.size.regular,
    marginBottom: 20,
    paddingBottom: 10,
    paddingTop: 10,
  },
  titleStrike: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  wrapper: {
    flex: 1,
  },
  wrapperBackground: {
    backgroundColor: Colors.darkBlue,
  },
};

export default StyleSheet.create(BaseStyle);
