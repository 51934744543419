import React from 'react';
import { Platform } from 'react-native';
import { Switch } from 'react-router-native';
import { useDispatch, useSelector } from 'react-redux';
import InAppBrowser from 'react-native-inappbrowser-reborn';
import { Hub } from 'aws-amplify';
import { Config } from '../../Config';

import RootView from './RootView';
import { Loader, ScrollToTop } from '../../Components/Layout';
import Splash from '../Splash/Splash';
import LoadingError from '../Errors/LoadingError';

import StartupActions from '../../Stores/Startup/Actions';
import AuthActions from '../../Stores/Auth/Actions';
import NavigationActions from '../../Stores/Navigation/Actions';

import { Router, RouteWithSubRoutes, DeepLinking } from '../../Router/Router';
import AppRoutes from '../../Router/AppRoutes';
import NotificationService from '../../Services/NotificationService';

const RootScreen = () => {
  const dispatch = useDispatch();

  const { loaded, loadingError } = useSelector(state => state.startup);

  const [signIn_failure, setSignIn_failure] = React.useState(null);
  let googleLogout = false;

  if (Platform.OS !== 'web') {
    new NotificationService((data) => dispatch(StartupActions.token(data.token)), () => { }, Config.PUSH.firebase_sender_id);
  }
  const hubListner = async ({ payload }) => {
    if (Platform.OS === 'ios') {
      InAppBrowser.close();
    }
    if (payload.event === 'signIn') {
      let loginWith = null;
      if (payload.data && payload.data.username) {
        if (payload.data.username.indexOf('Google_') === 0) {
          loginWith = 'google';
        } else if (payload.data.username.indexOf('Facebook_') === 0) {
          loginWith = 'facebook';
        } else if (payload.data.username.indexOf('SignInWithApple_') === 0) {
          loginWith = 'apple';
        }
      }
      dispatch(StartupActions.startup(loginWith));
    } else if (payload.event === 'signOut') {
      if (Platform.OS === 'ios' && payload && payload.data && payload.data.username && payload.data.username.indexOf('Google_') === 0) {
        googleLogout = true;
      } else if (Platform.OS === 'ios' && googleLogout) {
        try {
          if (await InAppBrowser.isAvailable()) {
            setTimeout(() => {
              InAppBrowser.close();
            }, 500);
            await InAppBrowser.open('https://accounts.google.com/logout', {
              forceCloseOnRedirection: true
            });
          }
        } catch (error) {
          console.log('logout error', error);
        }
      }
      dispatch(AuthActions.userLogout());
    } else if (payload.event === 'cognitoHostedUI_failure') {
      setSignIn_failure(true);
    } else if (payload.event === 'customOAuthState') {
      dispatch(NavigationActions.pushNavigation(payload.data));
    }
  };

  React.useEffect(() => {
    dispatch(StartupActions.startup());
    Hub.listen('auth', hubListner);
    return () => {
      Hub.remove('auth', hubListner);
    }
  }, []);

  return loaded ? (
    <Router>
      <DeepLinking />
      <ScrollToTop />
      <RootView signIn_failure={signIn_failure} setSignIn_failure={setSignIn_failure}>
        <Switch>
          {AppRoutes.map(route => (<RouteWithSubRoutes key={route.name} {...route} />))}
        </Switch>
        <Loader />
      </RootView>
    </Router>
  ) : loadingError ? <LoadingError /> : <Splash />;
};

export default RootScreen;
