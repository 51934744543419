import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  backButton: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-2,
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
  },
  backButtonWrapper: {
    borderWidth: 1,
    borderColor: Colors.blue1,
    borderRadius: 20,
    alignSelf: 'flex-start',
    marginBottom: 20,
  },
  button: {
    alignItems: 'center',
    flex: 1,
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    paddingVertical: 20,
    marginVertical: 30,
    paddingHorizontal: 30,
    borderColor: Colors.grayALittleLight,
  },
  buttonText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-3,
    color: Colors.blue1,
    marginTop: 5,
  },
  columns: {

  },
  container: {
    flexGrow: 1,
    paddingHorizontal: 20,
  },
  detail: {
    ...ApplicationStyles.screen.fontsLight,
  },
  detailLink: {
    color: Colors.blue1,
  },
  detailTitle: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+1,
  },
  details: {
    ...ApplicationStyles.screen.paddingTabBar,
  },
  detailRow: {
    marginBottom: 15,
  },
  itemContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  itemData: {
    width: 70,
    alignItems: 'flex-end',
    paddingRight: 20,
  },
  itemDataDay: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+1,
    color: Colors.blue1,
  },
  itemDataDayNumber: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.blue1,
    fontSize: Fonts.size.regular+23,
    marginBottom: -9,
    marginTop: -5,
  },
  itemDataMonth: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.regular+3,
  },
  itemDataYear: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    color: Colors.blue1,
  },
  itemDetails: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    color: Colors.blue1,
  },
  itemTitle: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+1,
  },
  itemWhere: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
  },
  modalBackground: {
    marginTop: 89,
    backgroundColor: Colors.mediumGray,
    flex: 1,
    justifyContent: 'space-around',
  },
  modalContainer: {
    flex: 1,
  },
  modalDownload: {
    paddingHorizontal: 10,
    alignSelf: 'flex-end',
  },
  noRace: {
    flex: 1,
  },
  noRaceText: {
    ...ApplicationStyles.screen.fontsLight,
    marginBottom: 20,
    marginTop: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  raceHeader: {
    flexDirection: 'row',
    marginBottom: 5,
    paddingVertical: 20,
  },
  warning: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  warningIcon: {
    alignItems: 'flex-end',
    paddingRight: 20,
    width: 70,
  },
  warningText: {
    flex: 1,
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-2,
  },
  webContainer: {
    flex: 1,
  },
  webLink: {
    textDecorationLine: 'none',
  },
};

const StyleLG = {
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
    paddingTop: 20,
  },
  columns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  container: {
    width: 600,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  details: {
    paddingBottom: 20,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
