import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Line, Circle, Defs, LinearGradient, Stop } from 'react-native-svg';

const PlusIcon = ({ size=70 }) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
    <Defs>
      <LinearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'PlusIconGradient'}>
        <Stop stopColor={'#02AFF1'} offset={'0%'} />
        <Stop stopColor={'#0D85F1'} offset={'100%'} />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} strokeWidth={1} fill={'none'} fillRule={'evenodd'}>
      <G fill={'url(#PlusIconGradient)'}>
        <Circle cx={(size/2)} cy={(size/2)} r={(size/2)} />
      </G>
      <Line x1={size/2} y1={size/4} x2={size/2} y2={size-(size/4)} stroke={'#FFFFFF'} strokeWidth={size>50?2.5:1.5} />
      <Line x1={size/4} y1={size/2} x2={size-(size/4)} y2={size/2} stroke={'#FFFFFF'} strokeWidth={size>50?2.5:1.5} />
    </G>
  </Svg> //70:15=35:x
);

PlusIcon.propTypes = {
  size: PropTypes.number,
};

export default PlusIcon;
