import { put, call, select } from "redux-saga/effects";
import { ErrorTranslated } from "../Config/cattolica";

import CattolicaActions from "../Stores/Cattolica/Actions";
import LayoutActions from "../Stores/Layout/Actions";
import OrderActions from "../Stores/Order/Actions";
import {
  startOrder as startOrderAPI,
  getCattolicaQuotation,
  getCattolicaEvents,
} from "../Services/ApiGatewayService";

export function* getQuotation({ data }) {
  yield put(LayoutActions.setLoading(true));
  yield put(CattolicaActions.setQuotation(null));
  try {
    const res = yield call(getCattolicaQuotation, data);
    console.log("res", res);
    yield put(CattolicaActions.setQuotation({ ...res, data }));
  } catch (err) {
    yield put(LayoutActions.setLoading(false));
    // err.response.data.message = err.response.data.message.map(err => ErrorTranslated[err] ? ErrorTranslated[err] : err);
    // yield put(LayoutActions.setError({ text: (Array.isArray(err.response.data.message) ? 'Errore di validazione dati.\n\n' + err.join('\n') : undefined) }));
    // Test pagamento evento
    let Output = {
      quotation: {
        transaction_id: 96,
        net_price: 5.36,
        tax: 2.5,
        duty: 0.13,
        price: 5.49,
      },
    };
    switch (data.Prodotto) {
      case "GARA SICURA":
        Output = {
          quotation: {
            transaction_id: 96,
            net_price: 5.36,
            tax: 2.5,
            duty: 0.13,
            price: 5.49,
          },
        };
        break;

      case "ALLENAMENTO PROTETTO":
        Output = {
          quotation: {
            transaction_id: 96,
            net_price: 202.93,
            tax: 2.5,
            duty: 5.07,
            price: 208.0,
          },
        };
        break;

      case "VIAGGIO SERENO":
        Output = {
          quotation: {
            transaction_id: 96,
            net_price: 5.36,
            tax: 2.5,
            duty: 0.14,
            price: 5.5,
          },
        };
        break;

      default:
        Output = {
          quotation: {
            transaction_id: 96,
            net_price: 202.93,
            tax: 2.5,
            duty: 5.07,
            price: 208.0,
          },
        };
        break;
    }
    yield put(CattolicaActions.setQuotation({ ...Output, data }));
  }
  yield put(LayoutActions.setLoading(false));
}

export function* getEvents() {
  yield put(LayoutActions.setLoading(true));
  yield put(CattolicaActions.setQuotation(null));
  try {
    const res = yield call(getCattolicaEvents);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic dHVhOnR1YXBhc3N3b3Jk");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://sinapsi.cattregroup.com/events/", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));
    yield put(CattolicaActions.setEvents(res));
  } catch (err) {
    console.log("getEvents ERROR", err);
  }
  yield put(LayoutActions.setLoading(false));
}

export function* startOrder() {
  yield put(LayoutActions.setLoading(true));
  try {
    const data = yield select((state) => state.cattolica.quotation);
    const order = yield call(startOrderAPI, {
      service: "cattolica",
      data,
    });
    yield put(OrderActions.setOrder(order.order_id));
  } catch (err) {
    console.log("err", err);
    yield put(OrderActions.setOrderError(err.response || err));
  }
  yield put(LayoutActions.setLoading(false));
}
