import React from 'react';
import { SafeAreaView, Text, View } from 'react-native';

import Style from './ErrorsStyle';
import { BackgroundImage } from '../../Components/Layout';
import { ConnectionIcon } from '../../Theme/Icons';

const NoConnection = () => {
  return (
    <View style={Style.container}>
      <BackgroundImage>
        <SafeAreaView style={Style.innerContainer}>
          <View style={Style.centerContainer}>
            <ConnectionIcon />
            <Text style={Style.title}>Connessione assente!</Text>
            <Text style={Style.text}>Le funzioni dell’app saranno nuovamente disponibili quando tornerai online</Text>
          </View>
        </SafeAreaView>
      </BackgroundImage>
    </View>
  );
};

export default NoConnection;
