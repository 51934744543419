import React from 'react';
import { View, Text, Image, TextInput, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { Config } from '../../../../Config';

import { getResponsive } from './CreateStyle';
import { Footer, PageHeader, PageSidebar, Slider } from '../../../Layout';
import { PrimaryButton, ToggleButton } from '../../../Buttons';
import { KeyboardAwareScrollView } from '../../../Forms';
import { Images, Colors } from '../../../../Theme';

import ChallengesActions from '../../../../Stores/Challenges/Actions';
import { ErrorPopup } from '../../../Popup';

const Create = () => {
  const Style = getResponsive();
  const dispatch = useDispatch();
  let listView;

  const userChallenges = useSelector(state => state.challenges.user_challenges);
  const errorUserChallenges = useSelector(state => state.challenges.user_error_challenges);
  const challengeCreationFeedback = useSelector(state => state.challenges.create_challenge_success);

  React.useEffect(() => {
    if (!userChallenges && !errorUserChallenges) {
      dispatch(ChallengesActions.getUserChallenges());
    }
  });

  const [ distanceToRun, setDistanceToRun ] = React.useState(0);
  const [ title, setTitle ] = React.useState('');
  const [ deadline, setDeadline ] = React.useState(moment().add(7, 'd').format('YYYYMMDD'));
  const [ formError, setFormError ] = React.useState('');

  const scrollTop = () => {
    if (listView && listView.scrollTo) {
      listView.scrollTo({ y: 0 });
    }
  };

  const createChallenge = () => {
    if (!title.length) { scrollTop(); setFormError('Dai un nome al tuo obiettivo'); return false; }
    if (distanceToRun === 0) { scrollTop(); setFormError('Imposta i kilometri da percorrere'); return false; }

    setFormError('');

    const data = {
      title,
      start_date: moment().format('YYYYMMDD') * 1,
      end_date: deadline * 1,
      km: distanceToRun,
    };

    dispatch(ChallengesActions.createChallenge(data));
  };

  return (
    <>
      <PageHeader title={'I tuoi obiettivi'} desc={'Supera i tuoi limiti'} />
      <KeyboardAwareScrollView ref={ref => listView = ref} bounces={false}>
        <View style={Style.webContainer}>
          <View style={Style.wrapper}>
            {userChallenges && userChallenges.filter(el => moment(el.end_date, 'YYYYMMDD').isSameOrAfter(moment())).length >= Config.MAX_GOALS
              ? <Text style={Style.noChallengeText}>Hai già {userChallenges.length} obiettivi attivi</Text>
              : (
                <>
                  {Platform.OS === 'web'
                    ? (
                      <img src={Images.goal} alt={''} style={Style.image} />
                    ) : (
                      <Image source={Images.goal} style={Style.image} resizeMode={'cover'} />
                    )}
                  <View style={Style.columns}>
                    <View style={Style.innerContainer}>
                      {!!formError.length && <Text style={Style.error}>{formError}</Text>}
                      <View style={Style.challengeBox}>
                        <View style={Style.challengeTextTop}>
                          <Text style={Style.challengeTitle}>IMPOSTA IL TUO OBIETTIVO</Text>
                          <Text style={Style.endsIn}>{`Termina tra ${moment(deadline, 'YYYYMMDD').diff(moment(), 'days')} giorni`}</Text>
                        </View>
                      </View>
                      <View style={Style.inputContainer}>
                        <TextInput style={Style.input} onChangeText={setTitle} placeholder={'Dai un nome al tuo obiettivo'} />
                      </View>
                      <View style={Style.ranDistanceWrapper}>
                        <Text style={Style.ranDistance}>{`${distanceToRun} km`}</Text>
                      </View>
                      <Slider
                        maximumValue={100}
                        minimumValue={0}
                        max={100}
                        min={0}
                        step={1}
                        onValueChange={setDistanceToRun}
                        onChange={setDistanceToRun}
                        minimumTrackTintColor={Colors.blue2}
                        maximumTrackTintColor={Colors.grayLight}
                        trackStyle={Style.sliderTrackStyle}
                        railStyle={Style.sliderRailStyle}
                        handleStyle={Style.sliderHandle}
                      />
                      <Text style={Style.challengeLength}>{`100 km`}</Text>
                      <View style={Style.deadlineContainer}>
                        <ToggleButton buttonsNumber={3} value={moment().add(7, 'd').format('YYYYMMDD')} selected={(deadline === moment().add(7, 'd').format('YYYYMMDD'))} text={'settimana'} checkHandler={() => setDeadline(moment().add(7, 'd').format('YYYYMMDD'))} />
                        <ToggleButton buttonsNumber={3} value={moment().add(1, 'M').format('YYYYMMDD')} selected={(deadline === moment().add(1, 'M').format('YYYYMMDD'))} text={'mese'} checkHandler={() => setDeadline(moment().add(1, 'M').format('YYYYMMDD'))} />
                        <ToggleButton buttonsNumber={3} value={moment().add(1, 'y').format('YYYYMMDD')} selected={(deadline === moment().add(1, 'y').format('YYYYMMDD'))} text={'anno'} checkHandler={() => setDeadline(moment().add(1, 'y').format('YYYYMMDD'))} />
                      </View>
                      <View style={Style.detailsContainer}>
                        <View style={Style.detailsRow}>
                          <Text style={Style.detailType}>Durata</Text>
                          <Text style={Style.detailText}>{`${moment().format('DD MMM')} - ${moment(deadline).format('DD MMM')}`}</Text>
                        </View>
                        <View style={Style.detailsRow}>
                          <Text style={Style.detailType}>Punti</Text>
                          <Text style={Style.detailTextBold}>{`+ ${distanceToRun} XP`}</Text>
                        </View>
                      </View>
                      <View style={Style.buttonContainer}>
                        <PrimaryButton onPress={() => createChallenge()}><Text>CONFERMA</Text></PrimaryButton>
                      </View>
                      {challengeCreationFeedback === false && <ErrorPopup closeHandler={() => dispatch(ChallengesActions.createChallengeSuccess(null))} title={'Qualcosa è andato storto'} />}
                    </View>
                    <PageSidebar section={'sfide'} />
                  </View>
                </>
              )
            }
          </View>
        </View>
        <Footer />
      </KeyboardAwareScrollView>
    </>
  );
};

export default Create;
