import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  border: {
    borderBottomWidth: 1,
    borderColor: Colors.grayALittleLight,
  },
  container: {
    ...ApplicationStyles.screen.paddingTabBar,
    paddingHorizontal: 30,
    paddingTop: 20,
  },
  description: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
    marginBottom: 10,
  },
  descriptionText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
    paddingLeft: 5,
    paddingRight: 5,
  },
  head: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  loader: {
    bottom: 0,
    position: 'absolute',
    right: 10,
    top: 0,
  },
  loaderContainer: {
    height: 31,
  },
  switchContainer: {
    alignItems: 'flex-end',
  },
  title: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.h2,
  },
  titleContainer: {
    flex: 1,
    paddingRight: 20,
  },
  wrapper: {
    flex: 1,
    paddingBottom: 20,
    paddingTop: 20,
  },
});
