import { StyleSheet } from 'react-native';
import { Colors } from '../../../Theme';

export default StyleSheet.create({
  background: {
    backgroundColor: Colors.mediumGray,
    flex: 1,
    justifyContent: 'space-around',
    marginTop: 89,
  },
  container: {
    flex: 1,
  },
  download: {
    alignSelf: 'flex-end',
    paddingHorizontal: 10,
  },
});
