/**
 * This file contains the application's colors.
 *
 * Define color here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

export default {
  transparent: '#ffffff00',
  transparentButton: 'rgba(255,255,255,0.2)',
  white: '#FFF',
  black: '#000',
  transparentBlack: 'rgba(0,0,0,0.75)',
  transparentBlue: 'rgba(0,42,85,0.9)',
  transparentLightBlue2: 'rgba(0,42,85,0.2)',
  transparentLightBlue: 'rgba(0,74,154,0.8)',
  darkBlue: '#002a55',
  darkBlueText: '#5b6c94',
  lightBlue: '#32c5ff',
  blue1: '#02aff1',
  blue2: '#0d85f1',
  blue3: '#4acdff',
  blue4: '#00548e',
  blue5: '#76afcf',
  blue99990: '#2b88c2',
  blue99999: '#40b4f6',
  grayUltraFantaLight: '#f9f9f9',
  grayUltraLight: '#f0f0f0',
  grayALittleLight: '#e6e6e6',
  grayLight: '#c3c4c3',
  grayMedium: '#a9acb2',
  grayMedium2: '#8f929a',
  grayMedium3: '#858891',
  gray: '#5f6469',
  mediumGray: '#333333',
  darkGray: '#161616',
  green: '#6DD400',
  green2: '#55a600',
  yellow: 'yellow',
  orange: '#fa6400',
  red: '#ff0643',
  facebookGradient1: '#6c92c9',
  facebookGradient2: '#314d87',
  googleGradient1: '#4285F4',
  googleGradient2: '#4285F4',
  footerRowGray: '#242424',
  cattolicaRed: '#9E2E43',
  cattolicaGreen: '#56B64A',
}
