import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View, ScrollView } from 'react-native';

import { getResponsive } from './FrequentQuestionsStyle';
import { Footer, PageHeader } from '../../Components/Layout';
import FAQ from '../../Components/FAQ/FAQ';

import HelperActions from '../../Stores/Helper/Actions';
import LayoutActions from '../../Stores/Layout/Actions';
import Analytics from '../../Services/AnalyticsService';

const FrequentQuestions = () => {
  const dispatch = useDispatch();

  const faq = useSelector(state => state.helper.faq);

  const Style = getResponsive();

  React.useEffect(() => {
    if (!faq) {
      dispatch(HelperActions.getFaq());
    }
    dispatch(LayoutActions.setTab(''));
    dispatch(HelperActions.sendLeaderboardEvent('read-FAQ'));
  }, []);

  React.useEffect(() => {
    Analytics.getInstance().trackFAQView();
  }, []);

  return (
    <View style={Style.wrapper}>
      <PageHeader title={'Domande Frequenti'} back={false} />
      <ScrollView bounces={false} style={Style.flex} contentContainerStyle={Style.wrapper}>
        <View style={Style.container}>
          <FAQ />
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
};

export default FrequentQuestions;
