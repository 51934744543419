import { StyleSheet } from 'react-native';
import { Colors } from '../../../Theme';

export default StyleSheet.create({
  background: {
    backgroundColor: Colors.darkBlue,
    flex: 1,
    height: '100%',
    width: '100%',
  },
});
