/**
 * This file defines the base application styles.
 *
 * Use it to define generic component styles (e.g. the default text styles, default button styles...).
 */

import { Fonts, Colors } from './';

export default {
  screen: {
    container: {
      flexGrow: 1,
    },
    webContainer: {
      width: '100%',
      maxWidth: 1000,
      paddingLeft: 30,
      paddingRight: 30,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    blankPage: {
      padding: 30,
    },
    paddingTabBar: {
      paddingBottom: 0,
    },
    paddingFooter: {
      paddingBottom: 90,
    },
    paddingAdvFooter: {
      paddingBottom: 0,
    },
    h1: {
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: Fonts.size.h1,
      color: Colors.blue1,
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: Fonts.size.h2,
      color: Colors.blue1,
    },
    h3: {
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: Fonts.size.h3,
      color: Colors.gray,
    },
    fonts: {
      fontFamily: 'Roboto',
      fontWeight: '500',
      fontSize: Fonts.size.regular,
      color: Colors.gray,
    },
    fontsRegular: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontSize: Fonts.size.regular,
      color: Colors.gray,
    },
    fontsRegularItalic: {
      fontFamily: 'Roboto',
      fontWeight: '400',
      fontStyle: 'italic',
      fontSize: Fonts.size.regular,
      color: Colors.gray,
    },
    fontsBold: {
      fontFamily: 'Roboto',
      fontWeight: '700',
      fontSize: Fonts.size.regular,
      color: Colors.gray,
    },
    fontsLight: {
      fontFamily: 'Roboto',
      fontWeight: '300',
      fontSize: Fonts.size.regular,
      color: Colors.gray,
    },
    fontsLightItalic: {
      fontFamily: 'Roboto',
      fontWeight: '300',
      fontStyle: 'italic',
      fontSize: Fonts.size.regular,
      color: Colors.gray,
    },
    footerBanner: {
      marginBottom: 20,
    },
  },
}
