import { ApplicationStyles, Colors } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const BaseStyle = {
  columns: {

  },
  error: {
    ...ApplicationStyles.screen.fontsLight,
    marginHorizontal: 30,
    marginVertical: 20,
    textAlign: 'center',
  },
  filterIcon: {
    paddingTop: 18,
    paddingBottom: 17,
  },
  floatingFilters: {},
  left: { },
  webContainer: {
    flex: 1,
  },
  wrapper: {
    flexGrow: 1,
  },
};

const StyleLG = {
  columns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 20,
  },
  left: {
    width: 600,
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
    flexGrow: 1,
    minHeight: 770,
  },
  floatingFilters: {
    left: 0,
    position: 'absolute',
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    top: -20,
    width: 500,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
