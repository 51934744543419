import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path } from 'react-native-svg';

import { Colors } from '../../Theme';

const InfoIcon = ({ size=31, color=Colors.white }) => (
  <Svg width={size} height={size} viewBox={'0 0 31 31'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <G fill={color}>
        <Path d={'M15.5,2.421875 C8.28868555,2.421875 2.421875,8.28868555 2.421875,15.5 C2.421875,22.7113145 8.28868555,28.578125 15.5,28.578125 C22.7113145,28.578125 28.578125,22.7113145 28.578125,15.5 C28.578125,8.28868555 22.7113145,2.421875 15.5,2.421875 M15.5,31 C11.3598047,31 7.46742773,29.3876973 4.53980469,26.4601953 C1.61230273,23.5325723 0,19.6401953 0,15.5 C0,11.3598047 1.61230273,7.46742773 4.53980469,4.53980469 C7.46742773,1.61230273 11.3598047,0 15.5,0 C19.6401953,0 23.5325723,1.61230273 26.4601953,4.53980469 C29.3876973,7.46742773 31,11.3598047 31,15.5 C31,19.6401953 29.3876973,23.5325723 26.4601953,26.4601953 C23.5325723,29.3876973 19.6401953,31 15.5,31'} />
        <Path d={'M17.4029897,21.6612263 L16.3925755,22.2233786 L17.4906951,13.8902769 C17.4953275,13.8554819 17.4975922,13.8202824 17.4975922,13.7850828 C17.4975922,13.1462313 17.219395,12.5492841 16.7534855,12.1882142 C16.292826,11.8311903 15.7058068,11.7509654 15.183177,11.9736068 C15.164133,11.9816987 15.1454493,11.9908309 15.1271773,12.0010035 L11.9709648,13.7486695 L12.5368297,15.0372387 L13.5485821,14.4769937 L12.4507198,22.8061074 C12.4460875,22.8408445 12.4437713,22.8760441 12.4437713,22.9112436 C12.4437713,23.5480721 12.7230494,24.144557 13.1907603,24.5070719 C13.4825457,24.7331812 13.823794,24.8495305 14.1672041,24.8495305 C14.3707178,24.8495305 14.575055,24.8086088 14.7702305,24.725494 C14.7896349,24.7172287 14.8086789,24.7079231 14.8273111,24.6975193 L17.9709648,22.9486395 L17.4029897,21.6612263 Z M16.590873,10.2893723 C17.8149492,10.2893723 18.8108242,9.29355781 18.8108242,8.06948164 C18.8108242,6.84534492 17.8148887,5.84953047 16.590873,5.84953047 C15.3667969,5.84953047 14.3709219,6.84534492 14.3709219,8.06948164 C14.3709219,9.29355781 15.3667969,10.2893723 16.590873,10.2893723 L16.590873,10.2893723 Z'} />
      </G>
    </G>
  </Svg>
);

InfoIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default InfoIcon;
