import React from "react";
import PropTypes from "prop-types";
import { Picker, Platform, Text, View } from "react-native";

import Style from "./SelectPickerStyle";
import { Button } from "../../Buttons";
import { Modal } from "../../Layout";

const SelectPicker = ({
  value,
  values,
  onChange,
  placeholder,
  pickerStyle,
  pickerTextStyle,
  hasPlaceholder = true,
}) => {
  const [picker, togglePicker] = React.useState(Platform.OS === "web");
  const [selectedValue, setSelectedValue] = React.useState(value || null);
  return (
    <View>
      {Platform.OS !== "ios" ? (
        <View style={pickerStyle ? pickerStyle : Style.picker}>
          {hasPlaceholder ? (
            <Picker
              mode={"dialog"}
              selectedValue={selectedValue}
              onValueChange={(value) => {
                setSelectedValue(value);
                onChange(value);
              }}
              placeholder={placeholder}
            >
              {[{ value: placeholder, id: "" }, ...values].map((el) => (
                <Picker.Item label={el.value} value={el.id} key={el.id} />
              ))}
            </Picker>
          ) : (
            <Picker
              mode={"dialog"}
              selectedValue={selectedValue}
              onValueChange={(value) => {
                setSelectedValue(value);
                onChange(value);
              }}
            >
              {values.map((el) => (
                <Picker.Item label={el.value} value={el.id} key={el.id} />
              ))}
            </Picker>
          )}
        </View>
      ) : (
        <>
          <Button
            style={pickerStyle ? pickerStyle : Style.pickerInput}
            onPress={() => togglePicker(!picker)}
          >
            <Text
              style={
                pickerTextStyle
                  ? pickerTextStyle
                  : [
                      Style.pickerInputText,
                      value && value !== "" ? {} : Style.placeholder,
                    ]
              }
            >
              {values && value && values.find((el) => el.id === value)
                ? values.find((el) => el.id === value).value
                : placeholder}
            </Text>
          </Button>
          {picker && (
            <Modal
              transparent={true}
              onRequestClose={() => togglePicker(false)}
              animationType={"fade"}
            >
              <Button
                onPress={() => togglePicker(false)}
                style={Style.modalBackground}
              />
              <View style={Style.pickerIOS}>
                <Picker
                  mode={"dialog"}
                  selectedValue={selectedValue}
                  onValueChange={(value) => {
                    setSelectedValue(value);
                  }}
                  placeholder={placeholder}
                >
                  {placeholder && (
                    <Picker.Item label={placeholder} value={""} />
                  )}
                  {values.map((el) => (
                    <Picker.Item label={el.value} value={el.id} key={el.id} />
                  ))}
                </Picker>
                <View style={Style.buttonsContainer}>
                  <Button
                    onPress={() => {
                      togglePicker(false);
                      setSelectedValue(value || null);
                    }}
                    style={Style.confirmButton}
                  >
                    <Text style={Style.confirmButtonText}>Annulla</Text>
                  </Button>
                  <Button
                    onPress={() => {
                      onChange(selectedValue);
                      togglePicker(false);
                    }}
                    style={Style.confirmButton}
                  >
                    <Text style={Style.confirmButtonTextOk}>Conferma</Text>
                  </Button>
                </View>
              </View>
            </Modal>
          )}
        </>
      )}
    </View>
  );
};

SelectPicker.propTypes = {
  value: PropTypes.string,
  border: PropTypes.bool,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  hasPlaceholder: PropTypes.bool,
  pickerStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
  pickerTextStyle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
  ]),
};

export default SelectPicker;
