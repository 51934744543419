import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Style from './LoaderStyle';
import { Colors } from '../../../Theme';

const Loader = ({ force=false }) => {
  const loading = useSelector(state => state.layout.loading);

  return loading || force ? (
    <View style={Style.wrapper}>
      <ActivityIndicator size={'large'} color={Colors.white} />
    </View>
  ) : null;
};

Loader.propTypes = {
  force: PropTypes.bool,
};

export default Loader;
