import React from "react";
import {
  View,
  Text,
  TextInput,
  Keyboard,
  Switch,
  Image,
  FlatList,
  Modal,
  TouchableOpacity,
  Platform,
  Dimensions,
} from "react-native";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Config } from "../../../../Config";
import { ConfigCattolica } from "../../../../Config/cattolica";

import { getResponsive } from "./FormStyle";
import { Button, PrimaryButton, ToggleButton } from "../../../Buttons";
import { DatePicker, SelectPicker } from "../../../Forms";
import { Images } from "../../../../Theme";

import CattolicaActions from "../../../../Stores/Cattolica/Actions";
import HelperActions from "../../../../Stores/Helper/Actions";
import {
  validateCodiceFiscale,
  validateCap,
} from "../../../../Services/ValidationService";
import { isDesktop } from "../../../../Services/ResponsiveHelper";
import { AlertIcon } from "../../../../Theme/Icons";

const windowWidth = Dimensions.get("window").width;

const Form = ({ selected, scrollTop, clickHandler, abort }) => {
  const Domande = {
    Prod1: [
      {
        domanda: "Sei cittadino Italiano residente in Italia?",
        value: false,
        need: true,
      },
      {
        domanda: "Hai un’età compresa tra i 18 e i 75 anni?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Ti sei iscritto ad una gara podistica su un percorso inferiore a 100 km?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Desideri una protezione verso gli infortuni che possano occorre durante la gara a cui ti si iscritto?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Sei un corridore professionista/percepisci una remunerazione per l’attività sportiva?",
        value: false,
        need: false,
      },
    ],
    Prod2: [
      {
        domanda: "Sei cittadino Italiano residente in Italia?",
        value: false,
        need: true,
      },
      {
        domanda: "Hai un’età compresa tra i 18 e i 75 anni?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Pratichi attività podistica per allenarti e parteciperai a gare con un percorso inferiore a 100 km?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Desideri una protezione verso gli infortuni che possano occorrere durante gli allenamenti e le gare a cui ti iscriverà?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Si un corridore professionista/percepisce una remunerazione per l’attività sportiva? ",
        value: false,
        need: false,
      },
    ],
    Prod3: [
      {
        domanda: "Sei cittadino Italiano residente in Italia?",
        value: false,
        need: true,
      },
      {
        domanda: "Hai un’età compresa tra i 18 e i 75 anni?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Pratichi attività podistica e ti sei iscritto ad una gara con un percorso inferiore a 100 km?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Desideri una protezione verso gli infortuni o gravi malattie che possano impedirle la partecipazione alla gara a cui ti sei iscritto?",
        value: false,
        need: true,
      },
      {
        domanda:
          "Sei un corridore professionista/percepisce una remunerazione per l’attività sportiva?",
        value: false,
        need: false,
      },
    ],
  };

  const Style = getResponsive();
  const dispatch = useDispatch();

  const MAX_AGE = 75;
  const DELTA_DAYS = selected !== "meteo" ? 1 : 7;

  const user = useSelector((state) => state.auth.user);
  const events = useSelector((state) => state.cattolica.events);
  const { provinces, countries } = useSelector((state) => state.helper);
  const [domande, setDomande] = React.useState(Domande.Prod1);
  const [coveragePeriod, setCoveragePeriod] = React.useState(6);
  const [name, setName] = React.useState(user.given_name || "");
  const [surname, setSurname] = React.useState(user.family_name || "");
  const [birthDate, setBirthDate] = React.useState(
    user.birthdate ? new Date(user.birthdate) : ""
  );
  const [taxCode, setTaxCode] = React.useState(user.tax_code || "");
  const [gender, setGender] = React.useState(user.gender || "");
  const [address, setAddress] = React.useState(user.address || "");
  const [city, setCity] = React.useState(user.city || "");
  const [cap, setCAP] = React.useState(user.cap || "");
  const [province, setProvince] = React.useState(user.province || "");
  const [modalVisible, setModalVisible] = React.useState(false);
  const [consent1, setConsent1] = React.useState(false);
  const [consent2, setConsent2] = React.useState(false);
  const [consent3, setConsent3] = React.useState(false);
  const [consent4, setConsent4] = React.useState(false);
  const [consent5, setConsent5] = React.useState(false);
  const [consent6, setConsent6] = React.useState(false);
  const [formError, setFormError] = React.useState("");
  const [eventStart, setEventStart] = React.useState(null);
  const [eventEnd, setEventEnd] = React.useState(null);
  const [eventName, setEventName] = React.useState("");
  const [eventCity, setEventCity] = React.useState("");
  const [eventCountry, setEventCountry] = React.useState("");
  const [eventProvince, setEventProvince] = React.useState("");
  const [eventsCountries, setEventsCountries] = React.useState(null);

  React.useEffect(() => {
    dispatch(CattolicaActions.getEvents());
    if (!countries) {
      dispatch(HelperActions.getCountries());
    }
    if (!provinces) {
      dispatch(HelperActions.getProvinces());
    }
    if (!events) {
      dispatch(CattolicaActions.getEvents());
    } else {
      setEventsCountries(
        [...events, { country: "Italia" }]
          .reduce((acc, el) => {
            if (acc.indexOf(el.country) === -1) acc.push(el.country);
            return acc;
          }, [])
          .sort()
          .map((country) => ({ id: country, value: country }))
      );
    }
  }, []);

  React.useEffect(() => {
    if (events) {
      setEventsCountries(
        [...events, { country: "Italia" }]
          .reduce((acc, el) => {
            if (acc.indexOf(el.country) === -1) acc.push(el.country);
            return acc;
          }, [])
          .sort()
          .map((country) => ({ id: country, value: country }))
      );
    } else {
      setEventsCountries(null);
    }
  }, [events]);

  React.useEffect(() => {
    switch (ConfigCattolica[selected].title) {
      case "GARA SICURA":
        setDomande(Domande.Prod1);
        break;

      case "ALLENAMENTO PROTETTO":
        setDomande(Domande.Prod2);
        break;

      case "VIAGGIO SERENO":
        setDomande(Domande.Prod3);
        break;

      default:
        setDomande(Domande.Prod1);
        break;
    }
  }, []);

  const handleSubmitData = () => {
    if (!consent1) {
      setModalVisible(true);
      return false;
    }
    if (!consent2) {
      setModalVisible(true);
      return false;
    }
    if (!consent3) {
      setModalVisible(true);
      return false;
    }
    if (!consent4) {
      setModalVisible(true);
      return false;
    }
    if (consent5) {
      setModalVisible(true);
      return false;
    }
    clickHandler();
    scrollTop();
  };

  const autoCompleteEvent = (eventId) => {
    const event = events.find((ev) => ev.id.toString() === eventId);
    setEventName(event.name);
    setEventCity(event.city);
    setEventStart(moment.unix(event.startDate / 1000).toDate());
    setEventEnd(moment.unix(event.endtDate / 1000).toDate());
  };

  const setCange = (item) => {
    let newArray = domande;
    for (var x of newArray) {
      if (x.domanda === item.domanda) {
        x.value = !x.value;
      }
    }
    setDomande(newArray);
  };

  const ListaDomande = React.useCallback(
    (item) => {
      return (
        <View
          style={{
            flexDirection: "row",
            flex: 1,
            justifyContent: "space-between",
            alignItems: "center",
            marginVertical: 10,
          }}
        >
          <Text
            style={{
              flex: 1,
            }}
          >
            {item.domanda}
          </Text>
          <Switch
            value={item.value}
            onValueChange={() => {
              setCange(item);
            }}
          />
        </View>
      );
    },
    [domande]
  );

  const renderRow = ({ item }) => {
    ListaDomande(item);
  };

  return (
    <View style={Style.wrapper}>
      {isDesktop() && (
        <Image
          source={Images.cattolica_logo}
          style={Style.logo}
          resizeMode={"contain"}
        />
      )}
      <View style={Style.form}>
        <Text style={Style.title}>
          {ConfigCattolica[selected].title}{" "}
          <Text style={Style.subtitle}>
            {ConfigCattolica[selected].subtitle}
          </Text>
        </Text>
        <Text style={Style.description}>
          {ConfigCattolica[selected].description}
        </Text>
        <View style={Style.formTitleContainer}>
          <View style={Style.formTitleBorder} />
          <Text style={Style.formTitle}>FAST QUOTE</Text>
          <View style={Style.formTitleBorder} />
        </View>
        <View style={Style.switches}>
          <View style={Style.switchContainer}>
            <Button
              onPress={() => setConsent1(!consent1)}
              style={Style.switchInner}
            >
              <Text style={Style.switchText}>{domande[0].domanda}</Text>
            </Button>
            <Switch
              value={consent1}
              onValueChange={(val) =>
                val ? setConsent1(true) : setConsent1(false)
              }
            />
          </View>
        </View>
        <View style={Style.switchContainer}>
          <Button
            onPress={() => setConsent2(!consent2)}
            style={Style.switchInner}
          >
            <Text style={Style.switchText}>{domande[1].domanda}</Text>
          </Button>
          <Switch
            value={consent2}
            onValueChange={(val) =>
              val ? setConsent2(true) : setConsent2(false)
            }
          />
        </View>
        <View style={Style.switchContainer}>
          <Button
            onPress={() => setConsent3(!consent3)}
            style={Style.switchInner}
          >
            <Text style={Style.switchText}>{domande[2].domanda}</Text>
          </Button>
          <Switch
            value={consent3}
            onValueChange={(val) =>
              val ? setConsent3(true) : setConsent3(false)
            }
          />
        </View>
        <View style={Style.switchContainer}>
          <Button
            onPress={() => setConsent4(!consent4)}
            style={Style.switchInner}
          >
            <Text style={Style.switchText}>{domande[3].domanda}</Text>
          </Button>
          <Switch
            value={consent4}
            onValueChange={(val) =>
              val ? setConsent4(true) : setConsent4(false)
            }
          />
        </View>
        <View style={Style.switchContainer}>
          <Button
            onPress={() => setConsent5(!consent5)}
            style={Style.switchInner}
          >
            <Text style={Style.switchText}>{domande[4].domanda}</Text>
          </Button>
          <Switch
            value={consent5}
            onValueChange={(val) =>
              val ? setConsent5(true) : setConsent5(false)
            }
          />
        </View>
        <PrimaryButton onPress={handleSubmitData} style={Style.button}>
          RICHIEDI VALUTAZIONE
        </PrimaryButton>
      </View>

      {modalVisible && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={modalVisible}
          onRequestClose={() => {
            Alert.alert("Modal has been closed.");
            setModalVisible(!modalVisible);
          }}
        >
          <View
            style={
              Platform.OS === "web"
                ? { maxWidth: windowWidth - 200 }
                : Style.backModal
            }
          >
            <View style={Style.bodymodal}>
              {Platform.OS !== "web" && (
                <View style={Style.ContainerError}>
                  <View style={Style.ErrorCheck}>
                    <AlertIcon color={"red"} size={40} />
                  </View>
                </View>
              )}
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                Gentile{" "}
                <Text
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {name} {surname}
                </Text>
                , siamo spiacenti di informarti che la polizza{" "}
                <Text
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  {ConfigCattolica[selected].title}
                </Text>{" "}
                non è adeguata alle tue caratteristiche personali ed alle tue
                necessità. Non sarà possibile pertanto procedere all’acquisto.
                Ti ringraziamo comunque per l’interesse dimostrato
              </Text>
              <PrimaryButton
                onPress={() => {
                  setModalVisible(false), abort();
                }}
                style={
                  Platform.OS === "web" ? { marginTop: 15 } : Style.buttonModal
                }
              >
                CHIUDI
              </PrimaryButton>
            </View>
          </View>
        </Modal>
      )}
    </View>
  );
};

Form.propTypes = {
  selected: PropTypes.string,
  scrollTop: PropTypes.func,
};

export default Form;
