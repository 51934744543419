import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { Switch, Text, View, TextInput, Keyboard } from "react-native";
import { Config } from "../../../Config";

import { getResponsive } from "./RegistrationStyle";
import {
  PrimaryButton,
  Button,
  ToggleButton,
} from "../../../Components/Buttons";
import { DatePicker, SelectPicker } from "../../Forms";
import { ErrorPopup } from "../../Popup";

import {
  validateCodiceFiscale,
  validateCap,
} from "../../../Services/ValidationService";
import RegistrationActions from "../../../Stores/Registration/Actions";
import HelperActions from "../../../Stores/Helper/Actions";

const Registration = ({
  scrollTop,
  foreign,
  runcardType,
  setPrivacyModal,
  successHandler,
  renew = false,
}) => {
  const Style = getResponsive();

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const { provinces, countries } = useSelector((state) => state.helper);
  const {
    error,
    status: registrationStatus,
    validation_errors: validationErrors,
  } = useSelector((state) => state.registration);

  const [name, setName] = React.useState(user.given_name || "");
  const [surname, setSurname] = React.useState(user.family_name || "");
  const [birthDate, setBirthDate] = React.useState(
    user.birthdate ? new Date(user.birthdate) : ""
  );
  const [birthPlace, setBirthPlace] = React.useState(user.birthplace || "");
  const [taxCode, setTaxCode] = React.useState(user.tax_code || "");
  const [gender, setGender] = React.useState(user.gender || "");
  const [country, setCountry] = React.useState(user.country || "");
  const [address, setAddress] = React.useState(user.address || "");
  const [city, setCity] = React.useState(user.city || "");
  const [cap, setCAP] = React.useState(user.cap || "");
  const [province, setProvince] = React.useState(user.province || "");
  const [consent1, setConsent1] = React.useState(false);
  const [consent2, setConsent2] = React.useState(false);
  const [privacy, setPrivacy] = React.useState(user.privacy || null);
  const [formError, setFormError] = React.useState("");
  const [epsEnte, setEpsEnte] = React.useState("");
  const [epsSocieta, setEpsSocieta] = React.useState("");
  const [epsTessera, setEpsTessera] = React.useState("");
  const [epsData, setEpsData] = React.useState("");

  React.useEffect(() => {
    if (foreign && country === "IT") {
      setCountry("");
    }
    if (foreign && !countries) {
      dispatch(HelperActions.getCountries());
    } else if (!foreign) {
      if (!provinces) {
        dispatch(HelperActions.getProvinces());
      }
      setCountry("IT");
    }
  }, []);

  React.useEffect(() => {
    if (registrationStatus === "FULL_OK") {
      successHandler();
    }
  }, [registrationStatus]);

  const Consent2 = () => {
    switch (runcardType) {
      case "eps":
      case "fitw":
        return (
          <Text style={Style.consentText}>
            Con la richiesta di iscrizione al circuito &quot;Runcard FIDAL&quot;
            mi impegno a provvedere al pagamento alla FIDAL della quota di €
            15,00. Il premio relativo al contratto di assicurazione
            &quot;Infortuni dei Titolari Runcard FIDAL&quot; è pari a € 5,00
            inclusivo di € 0,50 di provvigioni.
          </Text>
        );
      case "trail":
        return (
          <Text style={Style.consentText}>
            Con la richiesta di iscrizione al circuito &quot;Runcard FIDAL&quot;
            mi impegno a provvedere al pagamento alla FIDAL della quota di €
            10,00. Il premio relativo al contratto di assicurazione
            &quot;Infortuni dei Titolari Runcard FIDAL&quot; è pari a € 5,00
            inclusivo di € 0,50 di provvigioni.
          </Text>
        );
      case "default":
      case "nw":
      default:
        return (
          <Text style={Style.consentText}>
            Con la richiesta di iscrizione al circuito &quot;Runcard FIDAL&quot;
            mi impegno a provvedere al pagamento alla FIDAL della quota di €
            30,00 (€ 15,00 per i non residenti in Italia). Il premio relativo al
            contratto di assicurazione &quot;Infortuni dei Titolari Runcard
            FIDAL&quot; è pari a € 5,00 inclusivo di € 0,50 di provvigioni.
          </Text>
        );
    }
  };

  const handleSubmitData = () => {
    scrollTop();
    if (!renew) {
      if (!name.length) {
        setFormError("Nome è un campo obbligatorio");
        return false;
      }
      if (!surname.length) {
        setFormError("Cognome è un campo obbligatorio");
        return false;
      }
      if (!birthDate || birthDate === "") {
        setFormError("Data di nascita è un campo obbligatorio");
        return false;
      }
      if (!moment(birthDate)) {
        setFormError("Formato della data di nascita non valido");
        return false;
      }
      if (
        moment(birthDate).isAfter(
          moment()
            .subtract(Config.MIN_AGE, "years")
            .toDate()
        )
      ) {
        setFormError(
          `Devi avere almeno ${Config.MIN_AGE} anni per registrarti`
        );
        return false;
      }
      if (!birthPlace.length) {
        setFormError("Luogo di nascita è un campo obbligatorio");
        return false;
      }
      if (!gender.length) {
        setFormError("Genere è un campo obbligatorio");
        return false;
      }
      if (!foreign) {
        if (!taxCode.length) {
          setFormError("Codice Fiscale è un campo obbligatorio");
          return false;
        }
        if (!validateCodiceFiscale(taxCode)) {
          setFormError("Formato del Codice Fiscale non valido");
          return false;
        }
      }
    }
    if (!foreign) {
      if (!province.length) {
        setFormError("Provincia è un campo obbligatorio");
        return false;
      }
      if (!cap.length) {
        setFormError("CAP è un campo obbligatorio");
        return false;
      }
      if (!validateCap(cap)) {
        setFormError("Formato del CAP non valido");
        return false;
      }
    } else {
      if (!country.length) {
        setFormError("Nazione è un campo obbligatorio");
        return false;
      }
    }

    if (!address.length) {
      setFormError("Indirizzo è un campo obbligatorio");
      return false;
    }
    if (!city.length) {
      setFormError("Comune è un campo obbligatorio");
      return false;
    }

    if (runcardType === "eps") {
      if (!epsEnte.length) {
        setFormError("Ente è un campo obbligatorio");
        return false;
      }
      if (!epsSocieta.length) {
        setFormError("Società è un campo obbligatorio");
        return false;
      }
      if (!epsTessera.length) {
        setFormError("Tessera un campo obbligatorio");
        return false;
      }
      if (!epsData || epsData === "") {
        setFormError("Data tesseramento è un campo obbligatorio");
        return false;
      }
      if (!moment(epsData)) {
        setFormError("Formato della data tesseramento non valido");
        return false;
      }
    }
    if (!consent1 || !consent2) {
      setFormError("Per proseguire è necessario accettare i consensi");
      return false;
    }
    if (privacy === null) {
      setFormError(
        "Per proseguire è necessario selezionare il consenso al trattamento dei dati"
      );
      return false;
    }

    setFormError("");
    const data = {
      country,
      address,
      city,
      cap,
      province,
      privacy,
    };
    if (runcardType === "eps") {
      data.eps_ente = epsEnte;
      data.eps_societa = epsSocieta;
      data.eps_tessera = epsTessera;
      data.eps_data = moment(epsData).format(Config.DATE_FORMAT.storage);
    }
    if (!renew) {
      data.given_name = name;
      data.family_name = surname;
      data.birthdate = moment(birthDate).format(Config.DATE_FORMAT.storage);
      data.birthplace = birthPlace;
      data.gender = gender;
      data.tax_code = taxCode;
      dispatch(
        RegistrationActions.fullRegistration(data, {
          runcard_type: runcardType,
          runcard_price: !foreign
            ? Config.RUNCARD_TYPES[runcardType].price
            : Config.RUNCARD_TYPES[runcardType].price_foreign,
        }),
        true
      );
    } else {
      dispatch(RegistrationActions.updateForRenew(data));
    }
  };

  return (
    <View style={Style.container}>
      {error && <Text style={Style.error}>{error}</Text>}
      {!!formError.length && <Text style={Style.error}>{formError}</Text>}
      {!renew && (
        <>
          <TextInput
            style={Style.textInput}
            textContentType={"givenName"}
            autoCorrect={false}
            placeholder={"Nome"}
            onBlur={Keyboard.dismiss}
            value={name}
            onChangeText={setName}
          />
          <TextInput
            style={Style.textInput}
            textContentType={"familyName"}
            autoCorrect={false}
            placeholder={"Cognome"}
            onBlur={Keyboard.dismiss}
            value={surname}
            onChangeText={setSurname}
          />
          <DatePicker
            onChange={setBirthDate}
            value={birthDate}
            placeholder={"Data di nascita"}
            maxDate={moment()
              .subtract(Config.MIN_AGE, "years")
              .toDate()}
          />
          <TextInput
            style={Style.textInput}
            placeholder={"Comune di nascita"}
            autoCorrect={false}
            onBlur={Keyboard.dismiss}
            value={birthPlace}
            onChangeText={setBirthPlace}
          />
          {!foreign && (
            <TextInput
              style={Style.textInput}
              placeholder={"Codice fiscale"}
              autoCorrect={false}
              onBlur={Keyboard.dismiss}
              value={taxCode}
              onChangeText={setTaxCode}
            />
          )}
          <View style={Style.genderButtons}>
            <ToggleButton
              value={"M"}
              selected={gender === "M"}
              text={"uomo"}
              checkHandler={setGender}
            />
            <ToggleButton
              value={"F"}
              selected={gender === "F"}
              text={"donna"}
              checkHandler={setGender}
            />
          </View>
        </>
      )}
      <Text style={Style.residenza}>Residenza</Text>
      {foreign && countries && (
        <SelectPicker
          placeholder={"Nazione"}
          onChange={(val) => setCountry(val)}
          value={country}
          values={
            countries &&
            countries
              .filter((country) => country.code !== "IT")
              .map((country) => ({ id: country.code, value: country.name }))
          }
        />
      )}
      <TextInput
        style={Style.textInput}
        placeholder={"Indirizzo"}
        autoCorrect={false}
        onBlur={Keyboard.dismiss}
        value={address}
        onChangeText={setAddress}
      />
      <View style={Style.fieldsOneRow}>
        <View style={Style.fieldsOneRowWide}>
          <TextInput
            style={Style.textInput}
            placeholder={"Comune"}
            autoCorrect={false}
            onBlur={Keyboard.dismiss}
            value={city}
            onChangeText={setCity}
          />
        </View>
        <View style={Style.fieldsOneRowSmall}>
          <TextInput
            style={Style.textInput}
            placeholder={"CAP"}
            autoCorrect={false}
            onBlur={Keyboard.dismiss}
            value={cap}
            onChangeText={setCAP}
          />
        </View>
      </View>
      {!foreign && provinces && (
        <SelectPicker
          placeholder={"Provincia"}
          onChange={setProvince}
          value={province}
          values={provinces.map((province) => ({
            id: province.code,
            value: province.name,
          }))}
        />
      )}
      {runcardType === "eps" && (
        <>
          <View style={Style.spacer} />
          <Text style={Style.residenza}>EPS</Text>
          <SelectPicker
            placeholder={"Ente"}
            onChange={setEpsEnte}
            value={epsEnte}
            values={Object.keys(Config.ENTI_EPS).map((ente) => ({
              id: ente,
              value: Config.ENTI_EPS[ente],
            }))}
          />
          <TextInput
            style={Style.textInput}
            placeholder={"Società"}
            autoCorrect={false}
            onBlur={Keyboard.dismiss}
            value={epsSocieta}
            onChangeText={setEpsSocieta}
          />
          <TextInput
            style={Style.textInput}
            placeholder={"Tessera"}
            autoCorrect={false}
            onBlur={Keyboard.dismiss}
            value={epsTessera}
            onChangeText={setEpsTessera}
          />
          {/*to do*/}
          <DatePicker
            onChange={setEpsData}
            value={epsData}
            placeholder={"Data tesseramento"}
            maxDate={moment().toDate()}
          />
        </>
      )}
      <View style={Style.spacer} />
      <View>
        <Text style={Style.consentText}>
          Con la presente si chiede di essere inserito nel circuito
          &quot;Runcard&quot; della Federazione Italiana di Atletica Leggera,
          accettando integralmente i regolamenti emanati dalla FIDAL in merito e
          d in particolare tutti le norme dalle stessa emanate relative alla
          pratica dell&apos;Atletica Leggera e all&apos;etica sportiva.
        </Text>
        <View style={Style.switchContainer}>
          <Switch value={consent1} onValueChange={setConsent1} />
          <Button onPress={() => setConsent1(true)} style={Style.switchInner}>
            <Text style={Style.switchText}>Accetto</Text>
          </Button>
        </View>
      </View>
      <View>
        <Consent2 />
        <View style={Style.switchContainer}>
          <Switch value={consent2} onValueChange={setConsent2} />
          <Button onPress={() => setConsent2(true)} style={Style.switchInner}>
            <Text style={Style.switchText}>Accetto</Text>
          </Button>
        </View>
      </View>
      <View style={Style.consent}>
        <Button onPress={() => setPrivacyModal(true)}>
          <Text style={Style.switchText}>
            Visualizza e accetta il{" "}
            <Text style={Style.switchLink}>Trattamento dei Dati Personali</Text>
          </Text>
        </Button>
        <View style={Style.switchContainerPrivacy}>
          <Switch value={privacy === true} onValueChange={setPrivacy} />
          <Button onPress={() => setPrivacy(true)} style={Style.switchInner}>
            <Text style={Style.switchText}>Presto il consenso</Text>
          </Button>
        </View>
        <View style={Style.switchContainerPrivacy}>
          <Switch
            value={privacy === false}
            onValueChange={(data) => setPrivacy(!data)}
          />
          <Button onPress={() => setPrivacy(false)} style={Style.switchInner}>
            <Text style={Style.switchText}>NON presto il consenso</Text>
          </Button>
        </View>
      </View>
      <View>
        <PrimaryButton onPress={() => handleSubmitData()} style={Style.button}>
          AVANTI
        </PrimaryButton>
      </View>
      {validationErrors && (
        <ErrorPopup
          closeHandler={() =>
            dispatch(RegistrationActions.validationErrors(null))
          }
          title={"Dati non validi"}
          text={validationErrors.map((el) => el[0]).join("\n")}
        />
      )}
    </View>
  );
};

Registration.propTypes = {
  scrollTop: PropTypes.func,
  runcardType: PropTypes.string,
  foreign: PropTypes.bool,
  setPrivacyModal: PropTypes.func,
  successHandler: PropTypes.func,
  renew: PropTypes.bool,
};

export default Registration;
