import { Platform } from "react-native";
import env from "./env";

export const Config = {
  STAGE: env.STAGE,
  APP_VERSION: `${env.VERSION}${env.STAGE !== "prod" ? `-${env.STAGE}` : ""}`,
  BREAKPOINTS: {
    // Same as bootstrap
    extra_small: 350, // Extra small device
    small: 576, // landscape phones
    medium: 768, // tablets
    large: 992, // desktops
    extra_large: 1200, // large desktops
  },
  AWS: {
    Auth: {
      identityPoolId: env.AWS_IDENTITY_POOL,
      region: env.AWS_REGION,
      mandatorySignIn: false,
      userPoolId: env.AWS_USER_POOL_ID,
      userPoolWebClientId: env.AWS_USER_POOL_WEBCLIENT_ID,
      oauth: {
        domain: env.AWS_OAUTH_DOMAIN,
        scope: [
          "email",
          "profile",
          "openid",
          "aws.cognito.signin.user.admin",
          "given_name",
          "family_name",
          "user_gender",
          "user_birthday",
          "user_location",
        ],
        redirectSignIn: env.AWS_OAUTH_REDIRECT_SIGNIN,
        redirectSignOut: env.AWS_OAUTH_REDIRECT_SIGNOUT,
        responseType: "code",
      },
    },
    Storage: {
      AWSS3: {
        bucket: env.AWS_S3_BUCKET,
        region: env.AWS_REGION,
      },
    },
    API: {
      endpoints: [
        {
          name: "RuncardApp",
          endpoint: env.AWS_API,
        },
      ],
    },
  },
  AWS_IOT: {
    aws_pubsub_region: env.AWS_REGION,
    aws_pubsub_endpoint: env.AWS_IOT_ENDPOINT,
  },
  API: {
    name: "RuncardApp",
    apiKey: env.AWS_API_KEY,
  },
  APP_HOMEPAGE: Platform.OS === "web" ? "homepage" : "news",
  DATE_FORMAT: {
    storage: "YYYY-MM-DD",
    screen: "DD/MM/YYYY",
    locale: "it-IT",
  },
  PAYMENTS: {
    NEXI: {
      url: `https://${
        env.NEXI_RUNCARD_ENV !== "prod" ? "int-" : ""
      }ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet`,
      alias: env.NEXI_RUNCARD_ALIAS,
      secret: env.NEXI_RUNCARD_SECRET,
      divisa: "EUR",
      success_callback: env.NEXI_SUCCESS,
      cancel_callback: env.NEXI_CANCEL,
      success_url: `${env.AWS_API}/payments/save`,
    },
    NEXI_CATTOLICA: {
      url: `https://${
        env.NEXI_CATTOLICA_ENV !== "prod" ? "int-" : ""
      }ecommerce.nexi.it/ecomm/ecomm/DispatcherServlet`,
      alias: env.NEXI_CATTOLICA_ALIAS,
      secret: env.NEXI_CATTOLICA_SECRET,
      divisa: "EUR",
      success_callback: env.NEXI_SUCCESS,
      cancel_callback: env.NEXI_CANCEL,
      success_url: `${env.AWS_API}/payments/save`,
    },
  },
  DAYS_BEFORE_RENEW: 30,
  MAX_GOALS: 10,
  RUNCARD_TYPES: {
    standard: {
      name: "Runcard Standard",
      short_name: "standard",
      available: true,
      description:
        "Valida per tutte le manifestazioni agonistiche non-stadia: strada, campestre, montagna e trail, marcia su strada e nordic walking",
      price: 30,
      price_foreign: 15,
    },
    eps: {
      name: "Runcard EPS",
      short_name: "eps",
      available: true,
      description:
        "Riservata ai tesserati degli enti di promozione sportiva per la disciplina Atletica Leggera e valida per tutte le manifestazioni agonistiche non-stadia: strada, campestre, montagna e trail, marcia su strada e nordic walking",
      price: 15,
      price_foreign: 15,
    },
    trail: {
      name: "Runcard Mountain and Trail",
      short_name: "trail",
      available: true,
      description:
        "Valida per la partecipazione alle sole manifestazioni agonistiche di Corsa in Montagna e Trail",
      price: 10,
      price_foreign: 10,
    },
    nw: {
      name: "Runcard Nordic Walking",
      short_name: "nw",
      available: true,
      description:
        "Valida per la partecipazione a tutte le manifestazioni di Nordic Walking",
      price: 15,
      price_foreign: 15,
    },
    fitw: {
      name: "Runcard Fitwalking",
      short_name: "fitw",
      available: true,
      description:
        "Valida per poter svolgere attività non agonistica di Fitwalking",
      price: 15,
      price_foreign: 15,
    },
  },
  ENTI_EPS: {
    ACSI: "ACSI",
    AICS: "AICS",
    ASC: "ASC",
    ASI: "ASI",
    LIBERTAS: "CNS LIBERTAS",
    CSAIN: "CSAIN",
    CSEN: "CSEN",
    CSI: "CSI",
    CUSI: "CUSI",
    ENDAS: "ENDAS",
    MSP: "MSP",
    OPES: "OPES",
    PGS: "PGS",
    UISP: "UISP",
    USACLI: "USACLI",
  },
  CONTACTS: {
    address: "support@runcard.com",
    subject: "Runcard - Richiesta di assistenza",
    telephone: "+390633484739",
  },
  HELP_CONTACTS: {
    address: "support@runcard.com",
    subject: "Runcard - Richiesta di assistenza",
  },
  PLAYBUZZ_ID: "9a65fe67-813f-4b10-9c94-0408391520ac",
  NEWS: {
    CATEGORIES: {
      TopNews: "News",
      Allenamento: "Allenamento",
      Alimentazione: "Wellness",
      "Medico Sportivo": "Help",
      Regolamenti: "Primo Piano",
    },
  },
  STRAVA: {
    client_id: env.STRAVA_CLIENT_ID,
    client_secret: env.STRAVA_CLIENT_SECRET,
    redirect_uri: env.STRAVA_REDIRECT_URI,
  },
  GOOGLE_FIT: {
    workout_activities: [
      "running",
      "jogging",
      "running on sand",
      "running.treadmill",
      "treadmill",
      "walking.fitness",
      "walking.nordic",
      "walking.treadmill",
      "walking.stroller",
    ],
  },
  PUSH: {
    firebase_sender_id: env.FIREBASE_SENDER_ID,
  },
  FIREBASE_ANALYTICS: {
    api_key: env.FIREBASE_WEB_API_KEY,
    auth_domain: env.FIREBASE_WEB_AUTH_DOMAIN,
    db_url: env.FIREBASE_WEB_DB_URL,
    project_id: env.FIREBASE_WEB_PROJECT_ID,
    storage_bucket: env.FIREBASE_WEB_STORAGE_BUCKET,
    messaging_sender_id: env.FIREBASE_WEB_MESSAGING_SENDER_ID,
    app_id: env.FIREBASE_WEB_APP_ID,
    measurement_it: env.FIREBASE_WEB_MEASUREMENT_IT,
  },
  MIN_AGE: 18,
  WEBSITE_URL: env.WEBSITE_URL,
  LEADERBOARD_TEAM_ID: env.LEADERBOARD_TEAM_ID,
  CDN: env.CDN,
  PARTNERS: [
    { label: "SPONSOR UFFICIALI", category: "main" },
    { label: "MEDIA PARTNER", category: "media" },
    { label: "PARTNER COMMERCIALI", category: "commercial" },
  ],
};
