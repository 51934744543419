import { ApplicationStyles, Colors, Fonts } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const BaseStyle = {
  appButton: {
    height: 49,
    width: 182,
  },
  appButtons: {
    marginTop: 30,
    alignItems: 'center',
  },
  appColumns: {
    flexDirection: 'row',
  },
  appContainer: {
    backgroundColor: Colors.white,
    paddingTop: 50,
    zIndex: 1,
  },
  appGradient: {
    paddingBottom: 40,
  },
  appImage: {
    width: '150%',
    maxWidth: 842,
    height: 'auto',
    overflow: 'hidden',
    marginTop: -50,
    marginLeft: -60,
  },
  appLeftColumn: {
    flex: 1,
  },
  appRightColumn: {
    flex: 2,
    justifyContent: 'center',
    marginRight: -200,
    marginVertical: -100,
  },
  appText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.regular+2,
  },
  appTitle: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.regular+5,
    marginBottom: 30,
  },
  background: {
    backgroundColor: Colors.white,
  },
  container: {
    ...ApplicationStyles.screen.webContainer,
  },
  marginBottom5: {
    marginBottom: 5,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginRight: {
    marginRight: 20,
  },
  newsButton: {
    marginHorizontal: 20,
  },
  newsButtonContainer: {
    flexDirection: 'row',
    paddingBottom: 40,
  },
  newsGradient: {
    alignItems: 'center',
    paddingTop: 200,
  },
  newsLoader: {
    marginBottom: 20,
  },
  newsTitle: {
    ...ApplicationStyles.screen.h1,
    color: Colors.gray,
    paddingBottom: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  partnerContainer: {
    backgroundColor: Colors.white,
    paddingVertical: 40,
  },
  partnerImage: {
    height: 40,
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  partnerItem: {
    display: 'block',
    paddingLeft: 20,
    paddingRight: 20,
  },
  partnerSliderContainer: {
    marginHorizontal: 60,
  },
  partnerTitle: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.regular+2,
    paddingBottom: 20,
    textAlign: 'center',
  },
  recentContainer: {
    backgroundColor: Colors.grayUltraLight,
    paddingTop: 50,
  },
  recentTitle: {
    ...ApplicationStyles.screen.h1,
    color: Colors.gray,
    paddingBottom: 200,
  },
  vantaggiContainer: {
    backgroundColor: Colors.grayUltraLight,
    paddingBottom: 30,
    paddingTop: 50,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    zIndex: 1,
  },
  whyBox: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    paddingVertical: 40,
  },
  whyButton: {
    marginTop: 40,
    width: 280,
    alignSelf: 'center',
  },
  whyColumns: {

  },
  whyShadow: {
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    height: 10,
    marginTop: -60,
    marginBottom: 60,
  },
  whyContainer: {
    marginBottom: 0,
    paddingBottom: 40,
    zIndex: 3,
    borderTopWidth: 1,
    borderTopColor: 'white',
    marginTop: -1,
    backgroundColor: 'white',
  },
  whyGradient: {
    marginBottom: 40,
  },
  whyInfografica: {
    maxWidth: 400,
    width: '100%',
    marginTop: 40,
  },
  whyLeftColumn: {
    flex: 1,
  },
  whyRightColumn: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  whyText1: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+5,
    marginBottom: 30,
  },
  whyText2: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular+2,
  },
  whyTitle: {
    ...ApplicationStyles.screen.h1,
    color: Colors.white,
    fontSize: Fonts.size.big+10,
    paddingBottom: 40,
    paddingTop: 40,
    textAlign: 'center',
  },
  wrapper: {
    flex: 1,
  },
};

const StyleLG = {
  appButtons: {
    flexDirection: 'row',
    marginTop: 30,
  },
  appContainer: {
    paddingVertical: 50,
  },
  appGradient: {
    borderBottomRightRadius: 50,
    borderTopRightRadius: 50,
    marginRight: 100,
    paddingVertical: 40,
  },
  appImage: {
    aspectRatio: 842/504,
    flex: 1,
    maxHeight: 800,
  },
  newsButtonContainer: {
    width: '100%',
    justifyContent:'center',
    backgroundImage: 'url(/static/images/bg_homepage_why.png)',
    backgroundSize: '100%',
    backgroundPosition: 'center bottom',
    backgroundRepeat: 'no-repeat',
    paddingBottom: 150,
  },
  newsTitle: {
    fontSize: Fonts.size.big+20,
    color: Colors.white,
    paddingBottom: 50,
    textTransform: 'none',
  },
  partnerContainer: {
    paddingVertical: 50,
  },
  whyInfografica: {
    marginTop: 0,
  },
  whyContainer: {
    paddingBottom: 50,
  },
  whyTitle: {
    fontSize: Fonts.size.big+20,
    color: Colors.gray,
    paddingBottom: 80,
    paddingTop: 0,
  },
  whyColumns: {
    flexDirection: 'row',
  },
  whyText1: {
    marginTop: 15,
  },
  whyRightColumn: {
    flex: 1.2,
    flexDirection: 'row',
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
