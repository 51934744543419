import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View } from 'react-native';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, WhatsappShareButton, WhatsappIcon } from 'react-share';

import { Button } from '../';
import { ShareIcon } from '../../../Theme/Icons';
import { Colors } from '../../../Theme';

const Share = ({ url, callback, position = 'left' }) => {

  const Style = StyleSheet.create({
    shareButton: {
      margin: 5,
    },
    sharePopover: {
      backgroundColor: Colors.transparentLightBlue,
      borderRadius: 10,
      flexDirection: 'row',
      left: position === 'left' ? 'auto' : position === 'right'? 33 : -61,
      paddingLeft: 5,
      paddingRight: 5,
      paddingTop: 5,
      position: 'absolute',
      right: position === 'left' ? 33 : 'auto',
      top: position === 'center' ? -41 : -11,
      zIndex: 9999,
    },
  });

  const [ open, toggleOpen ] = React.useState(false);

  const OpenShare = () => (
    <View className={`share-balloon share-balloon-${position}`} style={Style.sharePopover}>
      <View style={Style.shareButton}>
        <FacebookShareButton url={url}>
          <FacebookIcon size={25} round={true} />
        </FacebookShareButton>
      </View>
      <View style={Style.shareButton}>
        <TwitterShareButton url={url}>
          <TwitterIcon size={25} round={true} />
        </TwitterShareButton>
      </View>
      <View style={Style.shareButton}>
        <LinkedinShareButton url={url}>
          <LinkedinIcon size={25} round={true} />
        </LinkedinShareButton>
      </View>
      <View style={Style.shareButton}>
        <WhatsappShareButton url={url}>
          <WhatsappIcon size={25} round={true} />
        </WhatsappShareButton>
      </View>
    </View>
  );

  return (
    <>
      <View>
        <Button onPress={() => { toggleOpen(!open); if (!open && callback) callback(); }}>
          <ShareIcon />
        </Button>
        {open && <OpenShare />}
      </View>
    </>
  )
};

Share.propTypes = {
  url: PropTypes.string,
  callback: PropTypes.func,
  position: PropTypes.oneOf(['left', 'right', 'center']),
};

export default Share;
