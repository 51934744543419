import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text, Platform } from 'react-native';

import { getResponsive } from './GenericServiceStyle';
import { Footer, LoaderInline, PageHeader, PageNotFound, WebView } from '../../Layout';

import Analytics from '../../../Services/AnalyticsService';
import HelperActions from '../../../Stores/Helper/Actions';
import { getUniqueId } from '../../../Services/NotificationService';

const GenericService = ({ match }) => {
  const dispatch = useDispatch();
  const Style = getResponsive();

  const thisWebView = React.createRef();

  const { user } = useSelector(state => state.auth);
  const { services, servicesTokens, serviceTokenError } = useSelector(state => state.helper);

  const [ webViewHeight, setWebViewHeight ] = React.useState(100);
  const [ service, setService ] = React.useState(null);
  const [ serviceToken, setServiceToken ] = React.useState(null);

  React.useEffect(() => {
    if (service && service.encryption_key) {
      dispatch(HelperActions.getServiceEncodedToken(service.id));
    }

    if (service && service.path === 'quiz') {
      dispatch(HelperActions.sendLeaderboardEvent('quiz-polls'));
    }

  }, [ service ]);

  React.useEffect(() => {
    if (service && service.encryption_key && servicesTokens && servicesTokens[service.id]) {
      setServiceToken(servicesTokens[service.id]);
    }
  }, [ servicesTokens ]);

  React.useEffect(() => {
    const servicePath = match.params.service;
    const found = services.find(service => service.path === servicePath);
    if (found) {
      setService(found);
      Analytics.getInstance().trackServiceView(servicePath);
    } else {
      setService(false);
    }
  }, [ match ]);

  const handleMessage = (event) => {
    if (event && event.action && event.action === 'resize') {
      setWebViewHeight(event.height);
    }

    if (event && event.action && event.action === 'iframe_partner_ready' && service.encryption_key && serviceToken && Platform.OS === 'web') {
      // Send auth
      console.log('sending auth...');
      const jsonAuth = {
        action: 'auth-info',
        body: {
          token: serviceToken,
          deviceid: getUniqueId(),
        }
      };
      thisWebView.current.postMessage(jsonAuth, service.payload.url);
      console.log('sent auth.');
    }
  };

  return service === false ? (
    <PageNotFound />
  ) : service ? (
    <>
      <PageHeader title={service.title} desc={service.text} />
      <View style={Style.webContainer}>
        {!service.encryption_key || serviceToken ? (
          <WebView
            padding={true}
            style={{ height: webViewHeight }}
            onMessage={handleMessage}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            url={`${service.payload.url}${service.payload.url.indexOf('?') === -1 ? '?' : '&'}platform=${Platform.OS}&email=${user && user.email ? user.email : ''}`}
            headers={service.encryption_key ? { 'X-Token': serviceToken, 'x-deviceid': getUniqueId(), 'accept-language': 'IT' } : {}}
            forwardedRef={thisWebView}
          />) : !serviceTokenError ? <LoaderInline style={{}} /> : <Text style={Style.error}>Non è stato possibile caricare il servizio</Text>
        }
      </View>
      <Footer />
    </>
  ) : null;
};

GenericService.propTypes = {
  match: PropTypes.object,
};

export default GenericService;
