import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Text, View, FlatList, Platform } from 'react-native';

import { getResponsive } from './ListStyle';
import { LoaderInline } from '../../Layout';
import { SecondaryButton } from '../../Buttons';
import { Colors } from '../../../Theme';
import { Circle } from '../../../Theme/Icons';
import NewsItem from './Item';

import NewsActions from '../../../Stores/News/Actions';

const List = ({ category }) => {
  const Style = getResponsive();

  const [ refreshing, setRefreshing ] = React.useState(false);
  const [ loading, setLoading ] = React.useState(false);
  const [ onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum ] = React.useState(true);

  const dispatch = useDispatch();
  const news = useSelector(state => state.news.news);
  const canLoadMore = useSelector(state => state.news.loadMore[category]);

  React.useEffect(() => {
    dispatch(NewsActions.getNews(category));
  }, [category]);

  React.useEffect(() => {
    setRefreshing(false);
    if (loading) {
      setLoading(false);
    }
  }, [news]);

  const handleRefresh = () => {
    setRefreshing(true);
    dispatch(NewsActions.getNews(category));
  };

  const loadMore = () => {
    if ((!onEndReachedCalledDuringMomentum || Platform.OS === 'web') && !loading) {
      setLoading(true);
      dispatch(NewsActions.getNews(category, news[category][news[category].length-1].date));
      setOnEndReachedCalledDuringMomentum(true);
    }
  };

  return (
    (news && news[category]) ? (
      <FlatList
        style={Style.listContainer}
        data={news[category].map(el => ({ ...el, key: el.id }))}
        renderItem={({ item }) => <NewsItem news={item} />}
        keyExtractor={item => item.id}
        refreshing={refreshing}
        onRefresh={handleRefresh}
        ListEmptyComponent={<Text style={Style.noNewsText}>Nessuna news</Text>}
        initialNumToRender={5}
        onEndReached={() => canLoadMore && Platform.OS !== 'web' ? loadMore() : () => {}}
        onEndReachedThreshold={1}
        onMomentumScrollBegin={() => setOnEndReachedCalledDuringMomentum(false) }
        onMomentumScrollEnd={() => setOnEndReachedCalledDuringMomentum(true) }
        ListFooterComponent={
          <View style={Style.listFooter}>
            {loading
              ? <LoaderInline style={{}} />
              : !canLoadMore && news[category].length
                ? <><Circle size={6} color={Colors.grayLight} /><Text style={Style.endReachedText}>Non ci sono altre notizie da caricare</Text></>
                : canLoadMore && Platform.OS === 'web' ? (<SecondaryButton size={'xsmall'} onPress={loadMore}>Carica altro</SecondaryButton>) : null
            }
          </View>
        }
      />
    ) : <LoaderInline style={Style.loader} />
  );
};

List.propTypes = {
  category: PropTypes.string.isRequired,
  news: PropTypes.object,
};

export default List;
