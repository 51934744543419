import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Style  from './FAQStyle';
import { SlideItem } from '../Layout';

import HelperActions from '../../Stores/Helper/Actions';

const FAQ = () => {
  const dispatch = useDispatch();

  const faq = useSelector(state => state.helper.faq);

  const [ open, setOpen ] = React.useState(null);

  React.useEffect(() => {
    if (!faq) {
      dispatch(HelperActions.getFaq());
    }
  }, []);

  return (
    <>
      {faq && faq.map((el, key) => <SlideItem titleStyle={Style.title} key={key} open={open===key} title={el.question} text={el.answer} callback={(status) => setOpen(status?key:null)} />)}
    </>
  );
};

export default FAQ;
