import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  container: {
    alignItems: 'center',
    marginBottom: 50,
    marginTop: 50,
    maxWidth: 500,
    padding: 30,
  },
  text: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.grayLight,
    fontSize: Fonts.size.big+5,
    paddingBottom: 50,
  },
  title: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.grayLight,
    fontSize: Fonts.size.big+20,
    paddingBottom: 20,
  },
  wrapper: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  },
});
