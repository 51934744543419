import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setUserLogged: ['user'],
  login: ['credentials', 'redirect', 'signup'],
  loginFailure: ['error'],
  loginReset: [],
  logout: ['redirect'],
  userLogout: null,
  startChangePassword: ['change', 'username'],
  forceChangePassword: null,
  requestChangePassword: ['username'],
  requestChangePasswordFailure: ['error', 'username'],
  requestChangePasswordSuccess: ['username'],
  changePasswordSkipRequest: ['username'],
  changePassword: ['data'],
  changePasswordFailure: ['error'],
  changePasswordSuccess: [null],
  endChangePassword: null,
  updateNotification: ['notification', 'value'],
  deleteProfile: ['redirect'],
});

export const AuthTypes = Types;
export default Creators
