import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from "../../../Services/ResponsiveHelper";

const BaseStyle = {
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginRight: 30,
  },
  firstPopupButton: {
    marginTop: 10,
    marginBottom: 10,
  },
  divider: {
    marginBottom: 20
  },
  cancelIcon: {
    backgroundColor: Colors.grayLight,
    borderRadius: 10,
    padding: 3,
  },
  closeContainer: {
    marginBottom: 10,
    marginLeft: 30,
    marginTop: 30,
  },
  container: {
    paddingBottom: ApplicationStyles.screen.paddingTabBar.paddingBottom - 30,
  },
  editIcon: {
    paddingTop: 6,
  },
  error: {
    color: Colors.red,
  },
  leftColumn: {
  },
  loader: {
    margin: 20,
    marginLeft: 30,
    marginRight: 30,
  },
  info: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  infoNote: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    marginLeft: 10,
  },
  infoModal: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    elevation: 8,
    shadowOpacity: 0.50,
    shadowColor: Colors.black,
    shadowOffset: { width: 2, height: 5 },
    shadowRadius: 5,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 30,
    paddingBottom: 30,
    marginHorizontal: 30,
  },
  infoModalText: {
    ...ApplicationStyles.screen.fontsLight,
  },
  infoModalBackground: {
    backgroundColor: Colors.transparentBlue,
    flex: 1,
    justifyContent: 'space-around',
  },
  infoModalContainer: {
    flex: 1,
    justifyContent: 'center',
    maxWidth: 600,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  infoModalClose: {
    padding: 30,
    alignSelf: 'flex-end',
  },
  menuButton: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingRight: 30,
  },
  menuButtonLink: {
    flex: 1,
  },
  menuContainer: {
    alignContent: 'flex-start',
    marginBottom: 30,
  },
  menuItem: {
    backgroundColor: Colors.white,
    marginBottom: 3,
  },
  menuItemText: {
    ...ApplicationStyles.screen.fontsLight,
    padding: 20,
    paddingLeft: 30,
    paddingRight: 30,
  },
  menuItemTextSelected: {
    color: Colors.blue1,
  },
  menuItemWithButton: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  menuItemWithIcon: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  },
  modal: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  modalContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  nicknameContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  pageHeader: {
    flex: 1,
    flexDirection: 'column',
    padding: 20,
  },
  pageHeaderSubtitle: {
    ...ApplicationStyles.screen.pageHeaderSubtitle,
  },
  pageHeaderTitle: {
    ...ApplicationStyles.screen.pageHeaderTitle,
  },
  rightColumn: {
    backgroundColor: Colors.white,
    flex: 1,
    marginBottom: 30,
    marginLeft: 10,
    marginTop: 20,
    padding: 30,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  rightColumnCTA: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    fontSize: Fonts.size.regular + 2,
    marginBottom: 10,
  },
  rightColumnText: {
    ...ApplicationStyles.screen.fontsRegular,
    marginBottom: 20,
  },
  rightColumnTitle: {
    ...ApplicationStyles.screen.h3,
    textTransform: 'uppercase',
    marginBottom: 20,
  },
  saveIcon: {
    backgroundColor: Colors.grayLight,
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    padding: 3,
  },
  textInput: {
    ...ApplicationStyles.screen.fonts,
    borderBottomWidth: 1,
    borderColor: Colors.grayALittleLight,
    color: Colors.orange,
    fontSize: Fonts.size.h2,
    paddingBottom: 2,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 2,
    width: '60%',
  },
  topContainer: {
    alignItems: 'center',
    paddingBottom: 20,
    paddingTop: 20,
  },
  userName: {
    ...ApplicationStyles.screen.h2,
    color: Colors.gray,
    marginTop: 15,
  },
  userBadge: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userBadgeImage: {
    height: 30,
    width: 30,
  },
  userLevel: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.h2 - 2,
    paddingLeft: 5,
  },
  userNickname: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.orange,
    fontSize: Fonts.size.h2,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
  },
  versionItem: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  versionText: {
    color: Colors.grayMedium2,
  },
  webContainer: {

  },
};

const StyleLG = {
  container: {
    paddingBottom: 0,
  },
  leftColumn: {
    width: 300
  },
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
    flexDirection: 'row',
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
