import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  listContainer: {
  },
  listFooter: {
    ...ApplicationStyles.screen.paddingTabBar,
    alignItems:'center',
    marginTop: 20,
  },
  item: {
    backgroundColor: Colors.white,
    elevation: 3,
    flexDirection: 'row',
    marginBottom: 5,
    paddingVertical: 20,
    paddingHorizontal: 20,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 1.5,
  },
  itemContent: {
    flex: 1,
    justifyContent: 'space-between',
  },
  itemData: {
    alignItems: 'flex-end',
    paddingRight: 20,
    width: 70,
  },
  itemDataDay: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+1,
    color: Colors.blue1,
  },
  itemDataDayNumber: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.blue1,
    fontSize: Fonts.size.regular+23,
    marginBottom: -9,
    marginTop: -5,
  },
  itemDataMonth: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.regular+3,
  },
  itemDataYear: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    color: Colors.blue1,
  },
  itemDetails: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    color: Colors.blue1,
  },
  itemFooter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  itemTitle: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+1,
  },
  itemWhere: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
  },
  endReachedText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.small,
    paddingTop: 5,
  },
};

const StyleLG = {
  listContainer: {
    marginLeft: -10,
    marginRight: -10,
  },
  item: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
