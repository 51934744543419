const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const validatePassword = (password) => {
  // 8 chars, lowercase, uppercase, digit and symbol
  if (password.length < 8) return false;
  if (!/[a-z]/.test(password)) return false; // Lowercase
  if (!/[A-Z]/.test(password)) return false; // Uppercase
  if (!/[0-9]/.test(password)) return false; // Digit
  if (!/[^a-zA-Z0-9]/.test(password)) return false; // Symbol
  return true;
};

const validateCodiceFiscale = (taxCode) => {
  if (taxCode.length >= 15 && taxCode.length <= 17) {
    return true;
  } else {
    return false;
  }
  // if (taxCode.length === 17) {
  //   const regEx = new RegExp(
  //     "^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$"
  //   );
  //   return regEx.test(taxCode.substring(0, 16));
  // } else {
  //   const regEx = new RegExp(
  //     "^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$"
  //   );
  //   return regEx.test(taxCode);
  // }
};

const validateCap = (CAP) => {
  const regEx = new RegExp("^[0-9]{5}$");
  return regEx.test(CAP);
};

export { validateEmail, validatePassword, validateCodiceFiscale, validateCap };
