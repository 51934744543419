import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  toggleSidebar: null,
  toggleLoginModal: ['status'],
  toggleRegistrationModal: ['status'],
  setLoading: ['loading'],
  setTab: ['tab'],
  setError: ['error'],
  setBreakpoint: ['breakpoint'],
});

export const LayoutTypes = Types;
export default Creators;
