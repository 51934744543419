import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const ChronometerIcon = ({ size=25 }) => (
  <Svg width={size} height={size} viewBox={'0 0 20 23'}>
    <Defs>
      <LinearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'ChronometerIconLinearGradient'}>
        <Stop stopColor={'#02AFF1'} offset={'0%'} />
        <Stop stopColor={'#0D85F1'} offset={'100%'} />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <Path d={'M18.4008163,13.5033814 C18.2567347,17.9053372 14.674898,21.4776646 10.2612245,21.6213637 C5.39673469,21.7797177 1.40244898,17.7960363 1.56122449,12.9444611 C1.70530612,8.54250531 5.28734694,4.97017788 9.70081633,4.82647876 C14.5653061,4.66812478 18.5595918,8.65180619 18.4008163,13.5033814 L18.4008163,13.5033814 Z M17.9818367,6.83195664 L18.0546939,6.90462035 C18.0673469,6.91723982 18.0806122,6.92904513 18.0942857,6.94023982 L18.4414286,7.28646106 L19.9638776,5.76805398 L17.4853061,3.29626637 L17.165102,3.61541681 C17.1404082,3.63312478 17.1167347,3.6526646 17.094898,3.67525752 L16.3355102,4.4326292 C16.314898,4.45298319 16.2969388,4.47516903 16.2802041,4.49776195 L15.9628571,4.81446991 L16.2953061,5.14603628 C16.3077551,5.16130177 16.3210204,5.17595664 16.3355102,5.18979735 L16.4083673,5.2626646 L15.8738776,5.7957354 C14.525102,4.72959381 12.8910204,4.02046106 11.0987755,3.81447876 L11.0987755,2.88246991 L12.2242857,2.88246991 L12.2242857,0.203478761 L11.6881633,0.203478761 L8.27387755,0.203478761 L7.68163265,0.203478761 L7.68163265,2.88246991 L8.86326531,2.88246991 L8.86326531,3.81447876 C7.07122449,4.02656726 5.43081633,4.73549646 4.08836735,5.7957354 L3.55387755,5.2626646 L3.62653061,5.18979735 C3.64755102,5.16903628 3.66591837,5.14644336 3.68285714,5.12323982 L4.00102041,4.80612478 L3.62673469,4.4326292 L2.86734694,3.67525752 L2.47857143,3.2877177 L0,5.75950531 L0.38877551,6.14704513 L1.14795918,6.90462035 L1.52244898,7.27791239 L1.83571429,6.96547876 C1.86102041,6.94756726 1.88510204,6.92741681 1.90755102,6.90462035 L1.98040816,6.83195664 L2.51489796,7.36502743 C0.833469388,9.48896549 0.0491836735,12.3497177 0.717959184,15.3647531 C1.4077551,18.4746381 4.53183673,21.6647177 7.63612245,22.4119124 C13.8818367,23.9148504 19.4822449,19.2029035 19.4822449,13.2180186 C19.4822449,11.0124611 18.7167347,8.97665575 17.4471429,7.36502743 L17.9818367,6.83195664 Z'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M10.6512857,13.3970319 C10.5506735,13.7713416 10.1686327,13.9919788 9.80006122,13.8983504 C9.4247551,13.7982088 9.20353061,13.4171823 9.29740816,13.0493858 C9.39802041,12.6750761 9.77985714,12.4544389 10.1486327,12.5480673 C10.5239388,12.6416956 10.7449592,13.0294389 10.6512857,13.3970319 L10.6512857,13.3970319 Z M10.5776122,11.7593504 C10.1284286,11.5788106 9.60577551,11.5989611 9.15659184,11.8596956 C8.3992449,12.3074832 8.14455102,13.2834566 8.59373469,14.0387929 C9.04271429,14.7941292 10.0212857,15.0481469 10.7786327,14.6003593 C11.2276122,14.3331115 11.4957755,13.8849168 11.5492449,13.4104655 L15.0412857,10.2354478 L10.5776122,11.7593504 Z'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M12.2463673,9.23938496 C12.5612653,9.41992478 12.9635102,9.30614602 13.1377959,8.99208407 C13.3186122,8.67781858 13.2047347,8.27684513 12.8898367,8.10302212 C12.5747347,7.9224823 12.1792245,8.03626106 11.9984082,8.35032301 C11.8777959,8.56424336 11.8910612,8.81154425 12.0051429,9.0055177 C12.0653469,9.09222566 12.1457551,9.17934071 12.2463673,9.23938496'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M9.94079592,8.63770088 C10.3026327,8.63770088 10.591,8.34378938 10.591,7.98270973 C10.591,7.62183363 10.296102,7.3344354 9.93406122,7.3344354 C9.57222449,7.3344354 9.28406122,7.6283469 9.28406122,7.98922301 C9.28406122,8.10971858 9.31753061,8.22329381 9.37120408,8.31692212 C9.48508163,8.51089558 9.69957143,8.6444177 9.94079592,8.63770088'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M10.0279796,17.8222319 C9.66614286,17.8287451 9.37777551,18.1230637 9.37777551,18.4839398 C9.37777551,18.6042319 9.41144898,18.7110903 9.46491837,18.8047186 C9.57879592,18.9984885 9.7934898,19.1324177 10.0347143,19.1254973 C10.396551,19.1189841 10.6847143,18.824869 10.6781837,18.4637894 C10.6847143,18.1161434 10.3898163,17.8222319 10.0279796,17.8222319'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M12.320102,17.1871673 C12.0117347,17.3677071 11.9045918,17.7686805 12.0921429,18.0762292 C12.2731633,18.3902912 12.6754082,18.4906363 12.9903061,18.3033796 C13.2986735,18.1230434 13.4058163,17.7218664 13.2180612,17.4143177 C13.0305102,17.106769 12.6284694,17.0066274 12.320102,17.1871673'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M13.9888776,15.496077 C13.8682653,15.7099973 13.8817347,15.9505814 13.9956122,16.1512717 C14.0492857,16.2446965 14.1364286,16.331608 14.2370408,16.3851389 C14.5519388,16.5656788 14.9539796,16.4519 15.1282653,16.1376345 C15.302551,15.8235726 15.1952041,15.4225991 14.8803061,15.2487761 C14.5654082,15.0749531 14.1631633,15.1818115 13.9888776,15.496077 Z'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M5.36997959,13.2835381 C5.36997959,12.9226619 5.06834694,12.6350602 4.71304082,12.6350602 C4.35120408,12.641777 4.06304082,12.935892 4.06304082,13.2967681 C4.06304082,13.4170602 4.0965102,13.5241221 4.15018367,13.6177504 C4.26406122,13.8115204 4.47855102,13.945246 4.71977551,13.9385292 C5.08855102,13.9385292 5.37671429,13.6444142 5.36997959,13.2835381'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M5.12193878,15.3422009 C4.81357143,15.5227407 4.70642857,15.9237142 4.89397959,16.2312628 C5.075,16.5388115 5.4772449,16.6456699 5.79214286,16.4584133 C6.1005102,16.278077 6.20112245,15.8769 6.02010204,15.5628381 C5.83234694,15.2620062 5.43030612,15.1551478 5.12193878,15.3422009'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M14.5854286,13.1899097 C14.5854286,13.3102018 14.618898,13.4170602 14.6793061,13.5172018 C14.7931837,13.7113788 15.0076735,13.8383876 15.248898,13.8383876 C15.6107347,13.8383876 15.899102,13.5373522 15.899102,13.1831929 C15.899102,12.8221133 15.6042041,12.534715 15.2421633,12.534715 C14.8735918,12.534715 14.5786939,12.8355469 14.5854286,13.1899097'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M5.07502041,11.231246 C5.38991837,11.4117858 5.79216327,11.2982106 5.96644898,10.9839451 C6.14073469,10.6698832 6.03338776,10.275423 5.7117551,10.0950867 C5.39685714,9.92106018 5.00134694,10.0281221 4.82032653,10.3423876 C4.70644898,10.5493876 4.71318367,10.796892 4.82706122,10.9973788 C4.88746939,11.0910071 4.96787755,11.1712018 5.07502041,11.231246'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M7.62863265,9.28609735 C7.62863265,9.28609735 7.63536735,9.28609735 7.63536735,9.27958407 C7.64189796,9.27958407 7.64189796,9.27958407 7.64189796,9.27286726 L7.64863265,9.27286726 C7.957,9.09232743 8.05761224,8.69115044 7.87659184,8.38380531 C7.69557143,8.06953982 7.29353061,7.96939823 6.97842857,8.14993805 C6.97842857,8.14993805 6.97169388,8.14993805 6.97169388,8.15645133 C6.96516327,8.15645133 6.96516327,8.16316814 6.95842857,8.16316814 C6.65006122,8.34370796 6.54291837,8.74468142 6.73046939,9.05223009 C6.91822449,9.36649558 7.32026531,9.46684071 7.62863265,9.28609735'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
      <Path d={'M7.71571429,17.2338796 C7.40081633,17.0533398 6.99857143,17.1671186 6.82428571,17.4811805 C6.70367347,17.6953044 6.71693878,17.9424018 6.83102041,18.1363752 C6.88469388,18.2300035 6.97163265,18.3167115 7.0722449,18.3769593 C7.38734694,18.5507823 7.78938776,18.4370035 7.96367347,18.1294549 C8.14469388,17.8086761 8.03061224,17.4144195 7.71571429,17.2338796'} fill={'url(#ChronometerIconLinearGradient)'} fillRule={'evenodd'} />
  </G>
</Svg>
);

export default ChronometerIcon;

ChronometerIcon.propTypes = {
  size: PropTypes.number,
};
