import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import { getResponsive } from './ResultsStyle';
import { LoaderInline } from '../../Layout';
import { ShoeIcon } from '../../../Theme/Icons';

import ResultsActions from '../../../Stores/Results/Actions';

const Results = () => {
  const dispatch = useDispatch();
  const Style = getResponsive();

  const [ refreshing, setRefreshing ] = React.useState(false);
  const { results, error } = useSelector(state => state.results);

  React.useEffect(() => {
    if (!results && !error) {
      dispatch(ResultsActions.getResults(false));
    }
  }, []);

  React.useEffect(() => {
    if (refreshing) {
      setRefreshing(false);
    }
  }, [results, error]);

  const handleRefresh = () => {
    setRefreshing(true);
    dispatch(ResultsActions.getResults(false));
  };

  const Result = ({ item }) => {
    const date = moment(item.data_prestazione, 'YYYY-MM-DD');
    return (
      <View style={Style.item}>
        <View style={Style.itemData}>
          <Text style={Style.itemDataDay}>{date.format('ddd').substring(0,1).toUpperCase()}{date.format('ddd').substring(1)}</Text>
          <Text style={Style.itemDataDayNumber}>{date.format('D')}</Text>
          <Text style={Style.itemDataMonth}>{date.format('MMM').substring(0,1).toUpperCase()}{date.format('MMM').substring(1)}</Text>
          <Text style={Style.itemDataYear}>{date.format('YYYY')}</Text>
        </View>
        <View style={Style.itemContent}>
          <View>
            <Text style={Style.itemTitle}>{item.manifestazione}</Text>
            <View style={Style.itemRaceContainer}>
              <ShoeIcon size={20} />
              <Text style={Style.itemRace}>{item.gara}</Text>
            </View>
          </View>
          <View>
            <Text style={Style.itemPosition}>{item.piazzamento}&deg; CLASSIFICATO</Text>
            <Text style={Style.itemTime}>{item.prestazione}</Text>
          </View>
        </View>
      </View>
    );
  };

  Result.propTypes = {
    item: PropTypes.shape({
      gara: PropTypes.string,
      manifestazione: PropTypes.string,
      prestazione: PropTypes.string,
      piazzamento: PropTypes.string,
      data_prestazione: PropTypes.string,
    }).isRequired,
  };

  return (
    <View style={Style.container}>
      <View style={Style.innerContainer}>
        {!error && results
          ? (
            <FlatList
              data={results}
              renderItem={({ item }) => <Result item={item} />}
              keyExtractor={item => item.gara.toLowerCase().replace('/ /', '-')+item.data_prestazione}
              onRefresh={handleRefresh}
              refreshing={refreshing}
              ListEmptyComponent={<Text style={Style.errorText}>Nessun risultato</Text>}
              ListFooterComponent={<View style={Style.listFooter} />}
            />
          ) : error ? (
            <Text style={Style.errorText}>Non è stato possibile recuperare i tuoi risultati</Text>
          ) : <LoaderInline style={{}} />
        }
      </View>
    </View>
  );
};

export default Results;
