import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';

import Style, { BaseStyle } from './CardStyle';
import { Images } from '../../../Theme';
import { LoaderInline } from '../../Layout';

import { isDesktop } from '../../../Services/ResponsiveHelper';

const Barcode = ({ barcode, error }) => {
  return (
    <>
      <img src={Images.card} style={isDesktop() ? { ...BaseStyle.cardWeb, ...BaseStyle.cardDesktop } : BaseStyle.cardWeb} alt={''} />
      {error
        ? <Text style={Style.error}>Impossibile generare il barcode</Text>
        : barcode
          ? <img src={barcode} style={BaseStyle.barcodeWeb} alt={''} />
          : <LoaderInline style={{}} />
      }
    </>
  );
};

Barcode.propTypes = {
  barcode: PropTypes.string,
  error: PropTypes.object,
};

export default Barcode;
