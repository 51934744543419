import { ApplicationStyles, Colors } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  container: {
    flexGrow: 1,
  },
  img: {
    width: 70,
    height: 65,
    marginHorizontal: 10,
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 20,
    alignItems: 'center',
  },
  logoRow: {
    borderBottomWidth: 1,
    borderColor: Colors.cattolicaRed,
    flex: 1,
    opacity: .37,
  },
  wrapper: {
    flexGrow: 1,
    paddingTop: 30,
    marginBottom: ApplicationStyles.screen.paddingTabBar.paddingBottom+30,
  },
  wrapperScroll: {
    flex: 1,
    paddingHorizontal: 30,
  },
  wrapperScrollPayment: {
    flex: 1,
  },
};

const StyleLG = {
  logoContainer: {
    paddingHorizontal: 30,
  },
  wrapper: {
    ...ApplicationStyles.screen.webContainer,
    paddingTop: 0,
  },
  wrapperScroll: {
    paddingHorizontal: 0,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
