import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { RuncardTypes } from './Actions'

export const setRuncard = (state, { runcard }) => ({
  ...state,
  runcard,
});

export const getRuncardError = (state, { error }) => ({
  ...state,
  error,
});

export const linkRuncardSuccess = (state) => ({
  ...state,
  link_error: null,
  link_success: true,
});

export const linkRuncardError = (state, { error }) => ({
  ...state,
  link_error: error,
  link_success: false,
});

export const clearLinkStatus = (state) => ({
  ...state,
  link_error: null,
  link_success: false,
});

export const setRuncardBarcodeError = (state, { error }) => ({
  ...state,
  barcode_error: error,
});

export const setRuncardBarcode = (state, { barcode }) => ({
  ...state,
  barcode,
});

export const setRuncardBuyError = (state, { error }) => ({
  ...state,
  runcard_buy_error: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [RuncardTypes.SET_RUNCARD]: setRuncard,
  [RuncardTypes.GET_RUNCARD_ERROR]: getRuncardError,
  [RuncardTypes.LINK_RUNCARD_SUCCESS]: linkRuncardSuccess,
  [RuncardTypes.LINK_RUNCARD_ERROR]: linkRuncardError,
  [RuncardTypes.CLEAR_LINK_STATUS]: clearLinkStatus,
  [RuncardTypes.SET_RUNCARD_BARCODE_ERROR]: setRuncardBarcodeError,
  [RuncardTypes.SET_RUNCARD_BARCODE]: setRuncardBarcode,
  [RuncardTypes.SET_RUNCARD_BUY_ERROR]: setRuncardBuyError,
});
