import { put, call, select } from 'redux-saga/effects';

import RacesActions from '../Stores/Races/Actions';
import LayoutActions from '../Stores/Layout/Actions';
import {
  fetchRaces,
  fetchRace,
  saveRace as saveRaceApi,
  fetchSavedRaces,
  postCrmEvent, postLeaderboardEvent
} from '../Services/ApiGatewayService';

export function* getRaces({ loader=true, filters={} }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  yield put(RacesActions.setRacesError(null));
  try {
    const data = yield call(fetchRaces, filters);
    yield put(RacesActions.setRaces(data));
  } catch (err) {
    console.log('fetchRaces ERROR', err);
    yield put(RacesActions.setRacesError(err));
    yield put(RacesActions.setRaces(null));
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}

export function* getRace({ id }) {
  yield put(LayoutActions.setLoading(true));
  yield put(RacesActions.setOpenRaceError(null));
  try {
    const data = yield call(fetchRace, id);
    yield put(RacesActions.setOpenRace(data));
  } catch (err) {
    console.log('fetchRace ERROR', err);
    yield put(RacesActions.setOpenRaceError(err));
    yield put(RacesActions.setOpenRace(null));
  }
  yield put(LayoutActions.setLoading(false));
}

export function* saveRace({ loader=true, race }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  try {
    yield call(saveRaceApi, race);
    let races = yield select(state => state.races.races);
    races = races.map(el => el.id === race.id ? { ...race, saved: true } : el);
    yield put(RacesActions.setRaces(races));
    let open_race = yield select(state => state.races.open_race);
    if (open_race && open_race.id === race.id) {
      yield put(RacesActions.setOpenRace({ ...open_race, saved: true }));
    }

    // CRM & Leaderboard
    yield call(postCrmEvent, { event: 'event_saved_race' });
    yield call(postLeaderboardEvent, { event: 'save-match' });

  } catch (err) {
    console.log('saveRaceApi ERROR', err);
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}

export function* unSaveRace({ loader=true, race }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  try {
    yield call(saveRaceApi, { id: race.id, date: race.date, remove: true });

    let races = yield select(state => state.races.races);
    races = races.map(el => el.id === race.id ? { ...race, saved: false } : el);
    yield put(RacesActions.setRaces(races));

    let open_race = yield select(state => state.races.open_race);
    if (open_race && open_race.id === race.id) {
      yield put(RacesActions.setOpenRace({ ...open_race, saved: false }));
    }

    let saved_races = yield select(state => state.races.saved_races);
    if (saved_races && saved_races.find(el => el.id === race.id)) {
      saved_races = saved_races.filter(el => el.id !== race.id);
      yield put(RacesActions.setSavedRaces(saved_races));
    }
  } catch (err) {
    console.log('saveRaceApi ERROR', err);
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}

export function* getSavedRaces({ loader=true, date }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  yield put(RacesActions.setSavedRacesError(null));
  try {
    const data = yield call(fetchSavedRaces, date);
    yield put(RacesActions.setSavedRaces(data));
  } catch (err) {
    console.log('fetchSavedRaces ERROR', err);
    yield put(RacesActions.setSavedRacesError(err));
    yield put(RacesActions.setSavedRaces(null));
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}
