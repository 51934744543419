import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import { useHistory } from 'react-router-native';
import { useSelector } from 'react-redux';

import { getResponsive } from './PageHeaderStyle';
import { isDesktop } from '../../../Services/ResponsiveHelper';
import { BackButton } from '../../Buttons';
import { Adv } from '../index';

import { getAdv } from '../../../Services/ADVService';

const PageHeader = ({ adv=false, back=true, mobile=true, title, desc, children, right, left, section = '' }) => {
  const Style = getResponsive();
  let history = useHistory();

  // ADV
  const options = adv ? useSelector(state => state.helper.options) : null;
  const masthead = adv ? getAdv(options ? options.Advertising : null, 'masthead', section) : [];

  //if (Platform.OS === 'web') back = false;

  return (isDesktop() || mobile) && (
    <View style={Style.wrapper}>
      <View style={Style.webContainer}>
        <View style={[Style.container, ((back && ((history.hasOwnProperty('canGo') && history.canGo(-1)) || (history.length > 1))) || left) && !right ? Style.withBack : {}]}>
          {back && <BackButton style={Style.backButton} />}
          {left && <View style={Style.leftButton}>{left}</View>}
          {(adv && masthead) && <Adv adv={masthead} />}
          <View style={[Style.textContainer, isDesktop() ? Style.textContainerDesktop : {} ]}>
            {title && <Text style={[Style.title, isDesktop() ? Style.titleDesktop : {} ]}>{title}</Text>}
            {desc && isDesktop() && <Text style={Style.desc}>{desc}</Text>}
          </View>
          {right && <View style={Style.rightButton}>{right}</View>}
          {children}
        </View>
      </View>
    </View>
  );
};

PageHeader.propTypes = {
  adv: PropTypes.bool,
  back: PropTypes.bool,
  mobile: PropTypes.bool,
  title: PropTypes.string,
  desc: PropTypes.string,
  children: PropTypes.node,
  right: PropTypes.node,
  left: PropTypes.node,
  section: PropTypes.string,
};

export default PageHeader;
