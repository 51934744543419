import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  button: {
    borderRadius: 30,
    shadowColor: Colors.black,
    shadowOffset: { width: 2, height: 5 },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    width: '100%',
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.transparentButton,
    borderRadius: 30,
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  text: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.regular,
    textAlign: 'center',
  }
});
