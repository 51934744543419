import React from 'react';
import PropTypes from 'prop-types';
import { View, Image, FlatList, Platform } from 'react-native';
import { pure } from 'recompose';

import { getResponsive } from './SocialWallStyle';
import { Button } from '../../Buttons';

import { isDesktop } from '../../../Services/ResponsiveHelper';

const List = ({ setFullImage, images }) => {
  const Style = getResponsive();

  const ImgBox = ({ item }) => (
    <View style={Style.container}>
      <Button onPress={() => setFullImage(item)}>
        {Platform.OS === 'web'
          ? <div style={{ backgroundImage: `url(${item.url})` }} className={'imgBox'} />
          : <Image style={Style.imgContainer} source={{ uri: item.url }} />
        }
      </Button>
    </View>
  );

  ImgBox.propTypes = {
    item: PropTypes.object,
  };

  return (
    <FlatList
      key={isDesktop() ? 'col5' : 'col3'}
      data={images}
      renderItem={({ item }) => <ImgBox item={item} />}
      numColumns={isDesktop() ? 5 : 3}
      keyExtractor={item => item.id}
      ListFooterComponent={<View style={Style.listFooter} />}
    />
  )
};

List.propTypes = {
  setFullImage: PropTypes.func.isRequired,
  images: PropTypes.array.isRequired,
};

export default pure(List);
