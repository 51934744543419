import { Dimensions, StyleSheet, Platform } from 'react-native';
import { Config } from '../Config';

const Breakpoints = {
  XXS: 0,
  XS: 1,
  SM: 2,
  MD: 3,
  LG: 4,
  XL: 5,
};

const isExtraSmall = () => {
  const { width } = Dimensions.get('window');
  return width >= Config.BREAKPOINTS.extra_small;
};

const isSmall = () => {
  const { width } = Dimensions.get('window');
  return width >= Config.BREAKPOINTS.small;
};

const isMedium = () => {
  const { width } = Dimensions.get('window');
  return width >= Config.BREAKPOINTS.medium;
};

const isLarge = () => {
  const { width } = Dimensions.get('window');
  return width >= Config.BREAKPOINTS.large;
};

const isExtraLarge = () => {
  const { width } = Dimensions.get('window');
  return width >= Config.BREAKPOINTS.extra_large;
};

export const getSize = () => {
  if (isExtraLarge()) return Breakpoints.XL;
  if (isLarge()) return Breakpoints.LG;
  if (isMedium()) return Breakpoints.MD;
  if (isSmall()) return Breakpoints.SM;
  if (isExtraSmall()) return Breakpoints.XS;
  return Breakpoints.XXS;
};

const getResponsive = ({ base={}, XXS={}, SM={}, MD={}, LG={}, XL={}, stylesheet }) => {
  const breakpoint = getSize();
  let style = base;

  if (breakpoint === Breakpoints.XXS) style = Object.keys(style).reduce((acc, key) => { acc[key] = { ...style[key], ...XXS[key]||{} }; return acc; }, {});
  if (breakpoint >= Breakpoints.SM && Platform.OS === 'web') style = Object.keys(style).reduce((acc, key) => { acc[key] = { ...style[key], ...SM[key]||{} }; return acc; }, {});
  if (breakpoint >= Breakpoints.MD && Platform.OS === 'web') style = Object.keys(style).reduce((acc, key) => { acc[key] = { ...style[key], ...MD[key]||{} }; return acc; }, {});
  if (breakpoint >= Breakpoints.LG && Platform.OS === 'web') style = Object.keys(style).reduce((acc, key) => { acc[key] = { ...style[key], ...LG[key]||{} }; return acc; }, {});
  if (breakpoint >= Breakpoints.XL && Platform.OS === 'web') style = Object.keys(style).reduce((acc, key) => { acc[key] = { ...style[key], ...XL[key]||{} }; return acc; }, {});

  return stylesheet ? StyleSheet.create(style) : style;
};

const isDesktop = () => {
  const breakpoint = getSize();
  return Platform.OS === 'web' && breakpoint >= Breakpoints.LG;
};

export default {
  getSize,
  Breakpoints,
  getResponsive,
}

export {
  isDesktop,
  Breakpoints,
}
