import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  startup: ['loginWith'],
  setLoaded: ['status'],
  setLoadingError: ['error'],
  token: ['token', 'device_id'],
});

export const StartupTypes = Types;
export default Creators;
