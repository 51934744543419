import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path } from 'react-native-svg';

import { Colors } from '../../Theme';

const EditIcon = ({ size=46, color=Colors.grayMedium }) => (
  <Svg width={size} height={size} viewBox={'0 0 46 46'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <G fill={color} fillRule={'nonzero'}>
        <Path d={'M43.929,13.391 C43.883,13.49 43.827,13.585 43.746,13.667 L15.84,41.572 C15.731,41.76 15.58,41.924 15.365,42.006 L1.513,45.886 C1.484,45.9 1.451,45.902 1.419,45.912 L1.372,45.926 C1.364,45.929 1.355,45.927 1.348,45.93 C1.254,45.955 1.161,45.976 1.062,45.9753263 C0.964,45.978 0.873,45.96 0.78,45.934 C0.759,45.928 0.74,45.932 0.719,45.925 C0.711,45.922 0.706,45.915 0.698,45.912 C0.61,45.879 0.534,45.829 0.458,45.771 C0.419,45.743 0.378,45.718 0.345,45.685 C0.312,45.652 0.287,45.611 0.259,45.572 C0.201,45.497 0.152,45.42 0.118,45.332 C0.114,45.324 0.108,45.319 0.105,45.311 C0.098,45.291 0.102,45.271 0.096,45.25 C0.071,45.158 0.053,45.066 0.0548483341,44.969 C0.0548483341,44.869 0.075,44.776 0.1,44.682 C0.104,44.674 0.101,44.666 0.104,44.659 L0.118,44.61 C0.129,44.58 0.131,44.547 0.144,44.517 L4.024,30.665 C4.106,30.449 4.27,30.301 4.458,30.19 L31.937,2.71 C31.977,2.665 32.024,2.627 32.065,2.583 L32.364,2.284 C32.379,2.269 32.398,2.264 32.414,2.25 C33.858,0.87 35.796,0 37.953,0 C42.397,0 46,3.603 46,8.047 C46,10.108 45.205,11.969 43.929,13.391 Z M40.15,14.5 L36.531,10.881 L12.891,34.522 C12.895,34.53 12.905,34.533 12.909,34.541 L15.282,39.368 L40.15,14.5 Z M2.559,43.473 L5.344,42.694 L3.338,40.689 L2.559,43.473 Z M3.943,38.53 L7.501,42.089 L13.621,40.374 C13.621,40.374 11.035,35.002 11.031,35 L5.657,32.41 L3.943,38.53 Z M11.49,33.124 C11.498,33.128 11.501,33.137 11.509,33.142 L35.15,9.5 L31.531,5.881 L6.663,30.749 L11.49,33.124 Z M37.922,2 C36.14,2 34.55,2.776 33.433,3.994 L33.426,3.987 L32.912,4.5 L41.531,13.119 L42.058,12.591 L42.052,12.585 C43.261,11.469 44.031,9.884 44.031,8.109 C44.031,4.735 41.296,2 37.922,2 Z'} />
      </G>
    </G>
  </Svg>
);

EditIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default EditIcon;
