import { ApplicationStyles } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const BaseStyle = {
  container: {
    flex: 1,
    marginBottom: ApplicationStyles.screen.paddingTabBar.paddingBottom,
    paddingTop: 10,
    paddingHorizontal: 30,
  },
  flex: {
    flex: 1,
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    textTransform: 'uppercase',
  },
  wrapper: {
    flexGrow: 1,
  },
};

const StyleLG = {
  container: {
    ...ApplicationStyles.screen.webContainer,
    marginBottom: 30,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
