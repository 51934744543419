import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Circle, Defs, LinearGradient, Stop } from 'react-native-svg';

const MinusFillIcon = ({ size=24 }) => (
  <Svg width={size} height={size} viewBox={'0 0 24 24'}>
    <Defs>
      <LinearGradient x1={'100%'} y1={'17.7664044%'} x2={'50%'} y2={'100%'} id={'gradient'}>
        <Stop stopColor={'#0694E3'} offset={'0%'} />
        <Stop stopColor={'#0061BD'} offset={'100%'} />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} fill={'none'}>
      <Circle fill={'#FFFFFF'} cx={'12'} cy={'12'} r={'12'} />
      <Path d={'M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M13,13 L11,13 L7,13 L7,11 L11,11 L13,11 L17,11 L17,13 L13,13 Z'} fill={'url(#gradient)'}/>
    </G>
  </Svg>
);

MinusFillIcon.propTypes = {
  size: PropTypes.number,
};

export default MinusFillIcon;
