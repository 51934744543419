import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polygon, Circle } from 'react-native-svg';

const AdvantagesIcon = ({ size=24 }) => (
  <Svg width={size} height={size} viewBox={'0 0 28 28'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <G transform={'translate(-299.000000, -243.000000)'}>
        <G transform={'translate(300.000000, 244.000000)'}>
          <Polygon fill={'#FFFFFF'} fillRule={'nonzero'} points={'14.3712121 11.7857143 20.1666667 11.7857143 20.1666667 14.3571429 14.3712121 14.3571429 14.3712121 20.1428571 11.7954545 20.1428571 11.7954545 14.3571429 6 14.3571429 6 11.7857143 11.7954545 11.7857143 11.7954545 6 14.3712121 6'} />
          <Circle stroke={'#FFFFFF'} strokeWidth={'2'} cx={'13'} cy={'13'} r={'13'} />
        </G>
      </G>
    </G>
  </Svg>

);

AdvantagesIcon.propTypes = {
  size: PropTypes.number,
};

export default AdvantagesIcon;
