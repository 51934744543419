import { Platform, StyleSheet } from 'react-native';
import { Colors, Fonts, ApplicationStyles } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const BaseStyle = {
  wrapper: {
    flex: 1,
  },
  button: {
    marginTop: 20,
  },
  gradient: {
    width: '100%',
    height: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 100,
    justifyContent: 'center',
    flex: 1,
  },
  closeButton: {
    paddingBottom: 30,
    paddingLeft: 30,
  },
  closeContainer: {
    alignItems: 'flex-end',
  },
  container: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    elevation: 8,
    flex: 1,
    shadowOpacity: 0.50,
    shadowColor: Colors.black,
    shadowOffset: { width: 2, height: 5 },
    shadowRadius: 5,
    paddingLeft: 40,
    paddingRight: 40,
    paddingTop: 30,
    paddingBottom: 30,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'stretch',
  },
  imgWrapper: {
    flexShrink: 0.2,
    flex: 0.8,
    flexGrow: 2,
    width: '100%',
    minHeight: 50,
  },
  img: {
    flex:1,
    height: undefined,
    width: undefined,
  },
  modal: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  textWrapper: {
    flex: 2.2,
    flexShrink: 1,
    flexGrow: 3,
    minHeight: 180,
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    textAlign: 'center',
    color: Colors.blue1,
    fontSize: Fonts.size.big-3,
    marginTop: 15,
    marginBottom: 15,
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
    color: Colors.gray,
    fontSize: Fonts.size.regular,
    marginBottom: 15,
  },
  small: {
    ...ApplicationStyles.screen.fontsLightItalic,
    textAlign: 'center',
    color: Colors.gray,
    fontSize: Fonts.size.small,
    marginBottom: 15,
  }
};

const StyleMedium = {
  wrapper: {
    ...BaseStyle.wrapper,
    maxWidth: 500,
    maxHeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  gradient: {
    ...BaseStyle.gradient,
    paddingBottom: '10%',
  },
};

const StyleExtraSmall = {
  container: {
    ...BaseStyle.container,
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    ...BaseStyle.text,
    fontSize: Fonts.size.regular-2,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium, XXS: StyleExtraSmall });
export default StyleSheet.create(BaseStyle);
