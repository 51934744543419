import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Image, ImageBackground, ScrollView, Text, View } from "react-native";
import { Config } from "../../../Config";

import Style from "./LinkRuncardDesktopStyle";
import { Footer, PageSidebar } from "../../../Components/Layout";
import { Images } from "../../../Theme";
import { PrimaryButton, ToggleButton } from "../../../Components/Buttons";
import Advantages from "../../Advantages/Advantages";
import FAQ from "../../FAQ/FAQ";

import ResponsiveHelper from "../../../Services/ResponsiveHelper";

import LayoutActions from "../../../Stores/Layout/Actions";

const LinkRuncardDesktop = ({
  buyHandler,
  setForeign: setBuyForeign,
  setCardType: setBuyCardType,
}) => {
  const dispatch = useDispatch();

  const [foreign, setForeign] = React.useState(false);
  const [{}, setBreakpoint] = React.useState(ResponsiveHelper.getSize());

  React.useEffect(() => {
    dispatch(LayoutActions.setTab("RUNCARD"));
  }, []);

  let scrollView;
  const scrollToBuy = () => {
    if (scrollView) {
      scrollView.scrollTo({ y: 200 });
    }
    if (window && document) {
      document.getElementById("buyRuncard").scrollIntoView();
    }
  };

  return (
    <ScrollView
      onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}
      ref={(ref) => (scrollView = ref)}
    >
      <ImageBackground source={Images.bg_runcard} resizeMode="cover">
        <View style={[Style.columns, Style.webContainer]}>
          <View style={Style.headerColumnLeft}>
            <Text style={Style.h1}>Scopri le{"\n"}nostre Runcard!</Text>
            <Text style={Style.h2}>
              Entra nella Community e accedi a tutte le gare
            </Text>
          </View>
          <View style={Style.headerColumnRight}>
            <Image
              source={Images.card_bg_full}
              style={Style.headerCardImage}
              resizeMode={"contain"}
            />
          </View>
        </View>
      </ImageBackground>
      <View style={Style.grayWrapper}>
        <View style={[Style.columns, Style.webContainer]}>
          <View style={Style.leftColumn}>
            <Text style={Style.leftColumnText1}>
              Runcard è l’innovativo progetto di FIDAL per riunire tutte le
              persone che corrono e condividono il movimento come passione e
              vero e proprio stile di vita.
            </Text>
            <Text style={Style.leftColumnText2}>
              Siamo noi stessi sportivi praticanti a darti il miglior supporto
              possibile per farti conseguire il risultato che ti sei prefisso.
              In questa ottica abbiamo riunito i migliori talenti provenienti da
              tutto il mondo della corsa, e non ci riferiamo solo agli esperti
              che lavorano nella Federazione ma anche a prestigiosi esterni.
            </Text>
          </View>
          <PageSidebar adv={false} scrollToBuy={scrollToBuy} />
        </View>
      </View>
      <View style={Style.webContainer} nativeID={"buyRuncard"}>
        <Text style={Style.runcardH1}>Runcard</Text>
        <Text style={Style.runcardH2}>Scegli la Runcard più adatta a te!</Text>
        <Text style={Style.runcardResidenza}>La tua residenza:</Text>
        <View style={Style.runcardResidenzaChoose}>
          <ToggleButton
            checkHandler={() => setForeign(false)}
            text={"Italia"}
            selected={!foreign}
            value={"IT"}
          />
          <ToggleButton
            checkHandler={() => setForeign(true)}
            text={"Estero"}
            selected={foreign}
            value={"ES"}
          />
        </View>

        <View style={Style.runcardList}>
          {Object.keys(Config.RUNCARD_TYPES)
            .filter((type) => Config.RUNCARD_TYPES[type].available)
            .map((type) => (
              <View style={Style.cardContainer} key={type}>
                <Image
                  source={Images.cards[type]}
                  style={Style.cardImage}
                  resizeMode={"contain"}
                />
                <View style={Style.card}>
                  <Text style={Style.cardTypeTitle}>
                    {Config.RUNCARD_TYPES[type].short_name} -{" "}
                    {!foreign
                      ? Config.RUNCARD_TYPES[type].price
                      : Config.RUNCARD_TYPES[type].price_foreign}
                    €
                  </Text>
                  <Text style={Style.cardTypeText}>
                    {Config.RUNCARD_TYPES[type].description}
                  </Text>
                  <PrimaryButton
                    onPress={() => {
                      setBuyForeign(foreign);
                      setBuyCardType(type);
                      buyHandler(true);
                    }}
                    size={"xsmall"}
                    height={40}
                    style={Style.buyButton}
                    textStyle={Style.buyButtonText}
                  >
                    ACQUISTA
                  </PrimaryButton>
                </View>
              </View>
            ))}
        </View>
      </View>
      <View style={Style.grayWrapper}>
        <View style={Style.webContainer}>
          <Advantages title={"Perché Runcard?"} />
        </View>
      </View>
      <View style={[Style.webContainer, Style.faqContainer]}>
        <Text style={Style.faqTitle}>Domande frequenti</Text>
        <FAQ />
      </View>
      <Footer />
    </ScrollView>
  );
};

LinkRuncardDesktop.propTypes = {
  buyHandler: PropTypes.func,
  setForeign: PropTypes.func,
  setCardType: PropTypes.func,
};

export default LinkRuncardDesktop;
