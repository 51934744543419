import React from "react";
import PropTypes from "prop-types";
import DatePickerWeb, { registerLocale } from "react-datepicker";
import it from "date-fns/locale/it";
registerLocale("it", it);

import "react-datepicker/dist/react-datepicker.css";
import { isDesktop } from "../../../Services/ResponsiveHelper";

const DatePicker = ({
  value,
  onChange,
  placeholder,
  maxDate,
  minDate,
  elementId = null,
  disabled = false,
}) => {
  if (!elementId) {
    elementId = `date-picker-${Math.round(Math.random() * 50)}`;
  }
  return (
    <DatePickerWeb
      id={elementId}
      selected={value}
      onChange={(date) => onChange(date)}
      locale={"it"}
      placeholderText={placeholder}
      showYearDropdown={true}
      showMonthDropdown={true}
      dateFormat={"dd/MM/yyyy"}
      maxDate={maxDate}
      minDate={minDate}
      onFocus={(e) => (e.target.readOnly = true)}
      onBlue={(e) => (e.target.readOnly = false)}
      onCalendarClose={() => document.body.classList.remove("calendar-open")}
      onCalendarOpen={() => document.body.classList.add("calendar-open")}
      // withPortal={!isDesktop()}
      disabled={disabled}
    />
  );
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  elementId: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DatePicker;
