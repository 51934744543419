import { ApplicationStyles, Fonts } from '../../Theme';
import ResponsiveHelper from "../../Services/ResponsiveHelper";

const BaseStyle = {
  container: {

  },
  h1: {
    ...ApplicationStyles.screen.h3,
    marginBottom: 30,
    marginLeft: 30,
    marginRight: 30,
    marginTop: 10,
    textAlign: 'center',
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    marginBottom: 15,
    marginTop: 15,
  },
  bold: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular-2,
  },
  underlined: {
    textDecorationLine: 'underline',
  },
  textContainer: {
    marginLeft: 30,
    marginRight: 30,
  },
};

const StyleLG = {
  container: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
