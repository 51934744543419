import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const AlertIcon = ({ size=24, color=false }) => (
  <Svg width={size} height={size} viewBox={'0 0 24 20'}>
    <Defs>
      <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={'alertIconGradient'}>
        <Stop stopColor="#02AFF1" offset="0%" />
        <Stop stopColor="#0D85F1" offset="100%" />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <Path d={'M23.6907971,16.8316886 L13.8463863,1.01048185 C13.4585424,0.387203743 12.7510577,0 11.9999793,0 C11.2489008,0 10.5414161,0.387203743 10.1535253,1.01052727 L0.309161445,16.8316886 C-0.0916668624,17.4759077 -0.103526243,18.2830212 0.278177056,18.9380515 C0.659974104,19.5930818 1.37931818,20 2.15556853,20 L21.84439,20 C22.6206403,20 23.3400313,19.5930818 23.7218283,18.9380061 C24.1035316,18.2829304 24.0916723,17.4758168 23.6907971,16.8316886 Z M22.361234,18.1934429 C22.2561402,18.3737352 22.0580932,18.485799 21.84439,18.485799 L2.15556853,18.485799 C1.94186531,18.485799 1.74381835,18.3737806 1.63877142,18.1934883 C1.53367763,18.0131506 1.53695888,17.7909309 1.6472558,17.6136366 L11.4917134,1.7924299 C11.5984947,1.62085922 11.7932604,1.51424645 12.0000261,1.51424645 C12.206745,1.51424645 12.4015107,1.62085922 12.508292,1.7924299 L22.3526558,17.6136366 C22.4630465,17.7909763 22.4663278,18.0131506 22.361234,18.1934429 Z'} fill={color ? color : 'url(#alertIconGradient)'} />
      <Path d={'M12.0068907,6 C11.4413621,6 11,6.29961878 11,6.83077717 C11,8.45137832 11.1930736,10.7801613 11.1930736,12.4008065 C11.1931182,12.8229766 11.5655732,13 12.0069353,13 C12.3379569,13 12.8069264,12.8229766 12.8069264,12.4008065 C12.8069264,10.7802053 13,8.45142235 13,6.83077717 C13,6.29966281 12.5448565,6 12.0068907,6 Z'} fill={color ? color : 'url(#alertIconGradient)'} />
      <Path d={'M12.0065301,14 C11.4314123,14 11,14.4545225 11,15 C11,15.5324599 11.4313701,16 12.0065301,16 C12.5424671,16 13,15.5324599 13,15 C13,14.4545225 12.5424248,14 12.0065301,14 Z'} fill={color ? color : 'url(#alertIconGradient)'} />
    </G>
  </Svg>
);

AlertIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default AlertIcon;
