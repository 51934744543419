import React from 'react';
import { Route } from 'react-router-native';

import AuthRoute from './AuthRoute';
import NoAuthRoute from './NoAuthRoute';
import RuncardRoute from './RuncardRoute';
import NoRuncardRoute from './NoRuncardRoute';

const RouteWithSubRoutes = (route) => {
  if (route.logged === true) {
    // Route needs authentication
    return <AuthRoute route={route} />;
  }
  if (route.logged === false) {
    // Route needs no authentication
    return <NoAuthRoute route={route} />;
  }
  if (route.runcard === true) {
    // Route needs runcard
    return <RuncardRoute route={route} />;
  }
  if (route.runcard === false) {
    // Route needs no runcard
    return <NoRuncardRoute route={route} />;
  }

  return (
    <Route
      path={route.path}
      exact={route.exact||false}
      render={props => (<route.component {...props} routes={route.routes} />)}
    />
  );
};

export default RouteWithSubRoutes;
