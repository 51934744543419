import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  button: {
    alignItems: 'center',
    borderColor: Colors.blue1,
    borderRadius: 30,
    borderWidth: 1,
    justifyContent: 'center',
    width: '100%',
  },
  buttonDisabled: {
    opacity: 0.8
  },
  text: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    fontSize: Fonts.size.regular,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  xSmallButton: {
    width: 'auto',
  },
  xSmallText: {
    fontSize: Fonts.size.regular-3,
    paddingLeft: 25,
    paddingRight: 25,
  },
});
