import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Polygon, Rect } from 'react-native-svg';

const Trophy = ({ size=98 }) => (
  <Svg width={size} height={size} viewBox={'0 0 90 90'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <Polygon fill="#FF7058" points="54 74 37 74 39.8976411 67.8064337 42.4090657 62 48.5909343 62 51.1023589 67.8064337" />
      <Path d="M64.4094349,74 L26.7644941,74 C24.6940043,74 23,75.6941092 23,77.7647271 L23,90 L67.9856863,90 L67.9856863,77.7647271 C68.173929,75.6941092 66.4799247,74 64.4094349,74 Z" fill="#FFD15C" />
      <Path d="M50,67.7674212 C48.4828149,68.5116735 46.9656299,69.2557477 45.4482633,69.8139369 L44.5000908,70 L43.5517367,69.6278739 C42.0345516,69.0696846 40.5173666,68.3256104 39,67.5813581 L41.4655391,62 L47.34479,62 L50,67.7674212 Z" fill="#F1543F" />
      <G fill="#F8B64C">
        <Path d="M0,10.2856449 L0,17.9532102 C0,36.6545455 11.6146614,52.7376222 27.9125498,59.4702102 C33.1578287,61.7144063 38.9651594,62.8364148 44.9598406,62.8364148 C50.9545219,62.8364148 56.7618526,61.7144063 62.0071315,59.4702102 C78.3050199,52.7378011 89.9196813,36.6545455 89.9196813,17.9533892 L89.9196813,10.2856449 L0,10.2856449 Z M82.4261952,17.9532102 C82.4261952,26.9298153 79.2415936,35.1584744 73.8089641,41.7038523 C66.8777092,49.9323324 56.5743227,55.3558807 44.9598406,55.3558807 C33.3453586,55.3558807 23.0419721,50.1195426 16.1107171,41.7038523 C10.8654382,35.3453267 7.49348606,26.9298153 7.49348606,17.9532102 L7.49348606,17.766179 L82.2388446,17.766179 L82.2388446,17.9532102 L82.4261952,17.9532102 Z" />
        <Path d="M74.1836653,0 L15.7358367,0 C14.4245618,0 13.4878088,1.12200852 13.4878088,2.24419602 C13.4878088,3.5532358 14.4243825,4.6754233 15.7358367,4.6754233 L74.1836653,4.6754233 C75.4949402,4.6754233 76.4316932,3.55341477 76.4316932,2.43122727 C76.4316932,1.12200852 75.4949402,0 74.1836653,0 Z" />
      </G>
      <Path d="M74,5 L74,25.3104408 C74,44.1164378 62.4742951,61.2299131 45,68 C27.5257049,61.0418351 16,44.1164378 16,25.1223628 L16,5 L74,5 Z" fill="#FFD15C" />
      <Polygon fill="#FFFFFF" points="45.5000897 13 49.4374154 25.1903807 62 25.1903807 51.8749613 32.809437 55.624949 45 45.5000897 37.3809437 35.375051 45 39.1250387 32.809437 29 25.1903807 41.5625846 25.1903807" />
      <Rect fill="#FFC952" x="16" y="5" width="58" height="3" />
    </G>
  </Svg>
);

Trophy.propTypes = {
  size: PropTypes.number,
};

export default Trophy;
