import React from 'react';
import { useSelector } from 'react-redux';
import { SafeAreaView, Text, View } from 'react-native';
import { useHistory } from 'react-router-native';
import { Config } from '../../Config';

import Style, { getResponsive } from './WelcomeUserStyle';
import { BackgroundImage, UserAvatar } from '../../Components/Layout';
import { PrimaryButton } from '../../Components/Buttons';

import ResponsiveHelper from '../../Services/ResponsiveHelper';
import { getPath } from '../../Router/AppRoutes';

const WelcomeUser = () => {
  let history = useHistory();

  const { user, logged } = useSelector(state => state.auth);

  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  return logged && (
    <View onLayout={() => setBreakpoint(ResponsiveHelper.getSize())} style={Style.container}>
      <BackgroundImage>
        <SafeAreaView style={getResponsive().innerContainer}>
          <View style={Style.centerContainer}>
            <View style={Style.avatarContainer}>
              <UserAvatar size={120} />
            </View>
            <Text style={Style.title}>Ciao {user.given_name}, benvenut{user.gender === 'F' ? 'a' : 'o'}!</Text>
            <Text style={Style.text}>Inizia subito per usufruire dei servizi esclusivi, ottenere convenzioni con i nostri partner e ricevere codici sconti irresistibili.</Text>
            <PrimaryButton onPress={() => { history.entries = []; history.index = -1; history.push(getPath(Config.APP_HOMEPAGE)); }}>INIZIA!</PrimaryButton>
          </View>
        </SafeAreaView>
      </BackgroundImage>
    </View>
  );
};

export default WelcomeUser;
