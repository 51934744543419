import React from 'react'
import { Text, View, SafeAreaView } from 'react-native'

import Style, { getResponsive, BaseStyle } from './PaywallStyle';
import { BackgroundImage } from '../../Components/Layout';
import { BackOrCloseButton, Link, PrimaryButton } from '../../Components/Buttons';

import ResponsiveHelper, { isDesktop } from '../../Services/ResponsiveHelper';

const Paywall = () => {
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  return(
    <View onLayout={() => setBreakpoint(ResponsiveHelper.getSize())} style={Style.container}>
      <BackgroundImage>
        <SafeAreaView style={getResponsive().innerContainer}>
          <View style={isDesktop() ? Style.containerWeb : Style.flexContainer}>
            <BackOrCloseButton style={Style.backButton} />
            <View style={Style.containerCenter}>
              <Text style={Style.title}>Entra in Runcard!</Text>
              <Text style={Style.text}>Devi essere loggato per poter accedere a questo servizio!</Text>
              <PrimaryButton route={'registration'}><Text>REGISTRATI!</Text></PrimaryButton>
            </View>
            <View style={Style.containerBottom}>
              <Link route={'login'} style={BaseStyle.loginLink}>
                <Text style={Style.loginText}>
                  Hai già un account?&nbsp;
                  <Text style={Style.login}>LOGIN</Text>
                </Text>
              </Link>
            </View>
          </View>
        </SafeAreaView>
      </BackgroundImage>
    </View>
  );
};

export default Paywall;
