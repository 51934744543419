import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getRuncard: ['loader', 'invalidate_cache'],
  setRuncard: ['runcard'],
  getRuncardError: ['error'],
  linkRuncard: ['code', 'tax_code'],
  linkRuncardSuccess: null,
  linkRuncardError: ['error'],
  clearLinkStatus: null,
  getRuncardBarcode: ['code'],
  setRuncardBarcode: ['barcode'],
  setRuncardBarcodeError: ['error'],
  setRuncardBuyError: ['error'],
});

export const RuncardTypes = Types;
export default Creators;
