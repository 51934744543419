import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-native';
import { Text, View, ScrollView, Linking, SafeAreaView, Platform } from 'react-native';
import moment from 'moment';
import { Config } from '../../../Config';

import { getResponsive } from './RaceStyle';
import { ApplicationStyles, Colors } from '../../../Theme';
import { InfoIcon, BackIcon, SaveRaceIcon } from '../../../Theme/Icons';
import { Adv, Footer, Modal, PageHeader, PageSidebar, WebView } from '../../Layout';
import { Share, Button, Download, Link } from '../../Buttons';

import { getPath } from '../../../Router/AppRoutes';
import { isDesktop } from '../../../Services/ResponsiveHelper';
import { getAdv } from '../../../Services/ADVService';
import RacesActions from '../../../Stores/Races/Actions';
import HelperActions from '../../../Stores/Helper/Actions';

const Race = ({ match }) => {
  const dispatch = useDispatch();
  const Style = getResponsive();

  const { logged } = useSelector(state => state.auth);
  const { open_race, open_race_error } = useSelector(state => state.races);

  const [ attachment, setAttachment ] = React.useState(null);

  const options = useSelector(state => state.helper.options);
  const adv = Platform.OS !== 'web' ? getAdv(options.Advertising, 'footer_banner', 'gare') : null;

  let raceId = match.params.id;

  React.useEffect(() => {
    raceId = match.params.id;
    if (raceId && (!open_race || open_race.id !== raceId) && !open_race_error) {
      // Fetch single race
      dispatch(RacesActions.openRace(raceId));
    } else if (raceId && open_race && open_race.id === raceId) {
      if (match.params.id) dispatch(HelperActions.sendCRMEvent('event_open_single_race'));
    }
  }, []);

  const handleDownload = (url) => {
    if (Platform.OS === 'ios') {
      setAttachment(url);
    } else {
      Linking.openURL(url);
    }
  };

  const Row = ({ title, value, email=false, link=false, inAppLink=false }) => (
    value
      ? (
        <View style={Style.detailRow}>
          {inAppLink
            ? Platform.OS !== 'web'
                ? (
                  <Button onPress={() => handleDownload(value)}>
                    <Text style={[Style.detailTitle, Style.detailLink]}>{title}</Text>
                  </Button>
                ) : (
                <Link href={value} target={'_blank'}>
                  <Text style={[Style.detailTitle, Style.detailLink]}>{title}</Text>
                </Link>
                )
            : (
              <>
                <Text style={Style.detailTitle}>{title}</Text>
                {email
                  ? <EmailLink mailto={value} />
                  : link
                    ? <BrowserLink address={value} />
                    : <Text style={Style.detail} selectable={true}>{value}</Text>
                }
              </>
            )
          }
        </View>
      ) : null
  );

  Row.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string,
    email: PropTypes.bool,
    link: PropTypes.bool,
    inAppLink: PropTypes.bool,
  };

  const EmailLink = ({ mailto }) => (
    <Button onPress={() => { try { Linking.openURL(`mailto:${mailto}`).catch(() => {}) } catch (err) {}}}>
      <Text style={[Style.detail, Style.detailLink]} selectable={true}>{mailto}</Text>
    </Button>
  );

  EmailLink.propTypes = {
    mailto: PropTypes.string.isRequired,
  };

  const BrowserLink = ({ address }) => (
    Platform.OS === 'web'
      ? (
        <a href={address} target={'_blank'} rel={'noreferrer noopener'} style={Style.webLink}>
          <Text style={[Style.detail, Style.detailLink]} selectable={true}>{address}</Text>
        </a>
      )
      : (
        <Button onPress={() => Linking.openURL(address)}>
          <Text style={[Style.detail, Style.detailLink]} selectable={true}>{address}</Text>
        </Button>
      )
  );

  BrowserLink.propTypes = {
    address: PropTypes.string.isRequired,
  };

  return (
    open_race && open_race.id === raceId
    ? (
      <>
        <View style={{ ...ApplicationStyles.screen.container }}>
          {isDesktop()
            ? <PageHeader back={false} adv={true} section={'gare'} />
            : <PageHeader title={'Gara'} />
          }
          <View style={Style.webContainer}>
            {isDesktop() && (
              <View style={Style.backButtonWrapper}>
                <Button route={'races'} style={Style.backButton}>{/* TODO */}
                  <BackIcon color={Colors.blue1} size={10} stroke={2} />
                  Torna ai risultati
                </Button>
              </View>
            )}
            <View style={Style.columns}>
              <ScrollView bounces={false} contentContainerStyle={Style.container}>
                <View style={Style.raceHeader}>
                  <View style={Style.itemData}>
                    <Text style={Style.itemDataDay}>{moment(open_race.date).format('ddd').substring(0,1).toUpperCase()}{moment(open_race.date).format('ddd').substring(1)}</Text>
                    <Text style={Style.itemDataDayNumber}>{moment(open_race.date).format('D')}</Text>
                    <Text style={Style.itemDataMonth}>{moment(open_race.date).format('MMM').substring(0,1).toUpperCase()}{moment(open_race.date).format('MMM').substring(1)}</Text>
                    <Text style={Style.itemDataYear}>{moment(open_race.date).format('YYYY')}</Text>
                  </View>
                  <View style={Style.itemContent}>
                    <View>
                      <Text style={Style.itemTitle}>{open_race.title}</Text>
                      <Text style={Style.itemWhere}>{open_race.city} ({open_race.province})</Text>
                    </View>
                    <View>
                      <Text style={Style.itemDetails}>{open_race.id.substr(0, 3) === 'REG' ? 'Regionale' : 'Nazionale'}</Text>
                      <Text style={Style.itemDetails}>{open_race.gender}</Text>
                    </View>
                  </View>
                </View>
                <View style={Style.warning}>
                  <View style={Style.warningIcon}><InfoIcon color={Colors.blue1} /></View>
                  <Text style={Style.warningText}>Per partecipare alla gara è necessario essere in possesso della Runcard e del certificato medico non scaduti</Text>
                </View>
                <View style={Style.buttons}>
                  <View style={Style.button}>
                    <Share position={'center'} url={`${Config.WEBSITE_URL}${generatePath(getPath('raceItem'), { id: open_race.id })}`} message={'Runcard | La gara sta per partire! Scopri tutte le informazioni'} callback={() => { dispatch(HelperActions.sendCRMEvent('event_share_race')); dispatch(HelperActions.sendLeaderboardEvent('share')); } } />
                    <Text style={Style.buttonText}>Condividi</Text>
                  </View>
                  {logged && (
                    <Button style={Style.button} onPress={() => dispatch(!!open_race.saved ? RacesActions.unSaveRace(true, open_race) : RacesActions.saveRace(true, open_race))}>
                      <SaveRaceIcon size={25} fill={!!open_race.saved} />
                      <Text style={Style.buttonText}>{open_race.saved ? 'Evento salvato' : 'Salva evento'}</Text>
                    </Button>
                  )}
                </View>
                <View style={[ Style.details, adv ? ApplicationStyles.screen.paddingAdvFooter : {} ]}>
                  <Row title={'Tipo'} value={open_race.type} />
                  <Row title={'Livello'} value={open_race.level} />
                  <Row title={'Categoria'} value={open_race.category} />
                  <Row title={'Email'} value={open_race.email} email={true} />
                  <Row title={'Info'} value={open_race.info} />
                  <Row title={'Sito'} value={open_race.website} link={true} />
                  <Row title={'Organizzazione'} value={open_race.organization} />
                  <Row title={'Sottotitolo'} value={open_race.subtitle} />
                  <Row title={'Codice società'} value={open_race.soc} />
                  <Row title={'Ente'} value={open_race.ente} />
                  {open_race.attachments.map((attachment, index) => (
                    <Row title={`Allegato ${index+1}`} value={attachment} inAppLink={true} key={index} />
                  ))}
                </View>
              </ScrollView>
              <PageSidebar section={'gare'} />
            </View>
          </View>
          <Footer />
          {adv && !isDesktop() && <View style={ApplicationStyles.screen.footerBanner}><Adv adv={adv} /></View>}
        </View>
        {attachment && (
          <Modal transparent={true} onRequestClose={() => setAttachment(null)} animationType={'fade'}>
            <SafeAreaView style={Style.modalBackground}>
              <View style={Style.modalContainer}>
                <PageHeader title={'Allegato'} back={false} left={<Button onPress={() => setAttachment(null)}><BackIcon /></Button> } />
                <View style={Style.modalDownload}>
                  <Download url={attachment} />
                </View>
                <WebView url={attachment} />
              </View>
            </SafeAreaView>
          </Modal>
        )}
      </>
    ) : open_race_error
      ? (
        <View style={Style.noRace}>
          <Text style={Style.noRaceText}>Evento non trovato</Text>
        </View>
      ) : null
  );
};

Race.propTypes = {
  match: PropTypes.object.isRequired,
};

export default Race;
