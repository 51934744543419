import { ApplicationStyles, Colors } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  button: {
    marginTop: 0,
    marginBottom: 20,
  },
  container: {
    ...ApplicationStyles.screen.paddingTabBar,
    backgroundColor: Colors.white,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
  },
  filtersContainer: {
    flexGrow: 1,
    paddingHorizontal: 30,
  },
  filterContent: {
    paddingTop: 20,
  },
  filterSelected: {
    margin: 0
  },
  filterSelectedText: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.blue1,
  },

  filterTitle: {
    ...ApplicationStyles.screen.fontsLight,
  },
  resetButton: {
    paddingVertical: 20,
  },
  resetButtonText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.blue1,
  },
  slideItem : {
    paddingVertical: 10,
  },
  slideItemCollapsed: {
    overflow: 'hidden',
    height: 0,
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    paddingVertical: 20,
  },
  toggle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  }
};

const StyleLG = {
  filtersContainer: {
    flexGrow: 0,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
