import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { View, Text, Linking, Image, Platform } from "react-native";
import { Config } from "../../../Config";

import { getResponsive } from "./FooterStyle";
import { ApplicationStyles, Images } from "../../../Theme";
import { Logo } from "../../../Theme/Icons";
import { Link, Button } from "../../Buttons";
import { Adv, SlideItem } from "../index";
import { Popup } from "../../Popup";

import { isDesktop } from "../../../Services/ResponsiveHelper";
import { getAdv } from "../../../Services/ADVService";

const Footer = ({ adv = false, section = "" }) => {
  const Style = getResponsive();
  const [contactPopUp, setContactPopUp] = React.useState(false);

  const [open, setOpen] = React.useState(null);

  // ADV
  const options = useSelector((state) => state.helper.options);
  const advItem = adv
    ? getAdv(options.Advertising, "footer_banner", section)
    : null;

  const contactUs = () => {
    try {
      Linking.openURL(
        `mailto:${Config.CONTACTS.address}?subject=${Config.CONTACTS.subject}`
      ).catch(() => {
        setContactPopUp(true);
      });
    } catch (err) {
      setContactPopUp(true);
    }
  };

  return Platform.OS !== "web" ? null : isDesktop() ? (
    <View style={Style.wrapper}>
      <View style={Style.container}>
        <View style={Style.topContainer}>
          <View>
            <Logo width={150} />
          </View>
          <View style={Style.menuContainer}>
            <View>
              <Text style={Style.menuBlockTitle}>AIUTO:</Text>
              <Link route={"faq"} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>FAQ</Text>
              </Link>
              <Button onPress={contactUs} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>Contattaci</Text>
              </Button>
            </View>
            <View>
              <Text style={Style.menuBlockTitle}>NOTE LEGALI:</Text>
              <Link route={"conditions"} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>
                  Termini e Condizioni
                </Text>
              </Link>
              <Link route={"privacy"} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>
                  Informativa sulla privacy
                </Text>
              </Link>
              <Link route={"cookie"} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>Cookie Policy</Text>
              </Link>
            </View>
            <View>
              <Text style={Style.menuBlockTitle}>SEGUICI:</Text>
              <Link
                href={"//www.facebook.com/runcarditalia"}
                style={Style.menuBlockLink}
              >
                <Text style={Style.menuBlockLinkText}>Facebook</Text>
              </Link>
              <Link
                href={"//twitter.com/runcarditalia"}
                style={Style.menuBlockLink}
              >
                <Text style={Style.menuBlockLinkText}>Twitter</Text>
              </Link>
              <Link
                href={"//www.instagram.com/runcarditalia/"}
                style={Style.menuBlockLink}
              >
                <Text style={Style.menuBlockLinkText}>Instagram</Text>
              </Link>
            </View>
          </View>
          <View>
            <Link
              href={"//apps.apple.com/it/app/my-runcard/id1474102111"}
              style={Style.marginBottom}
            >
              <Image source={Images.button_appstore} style={Style.appButton} />
            </Link>
            <Link
              href={
                "//play.google.com/store/apps/details?id=com.infront.runcard&hl=it"
              }
            >
              <Image
                source={Images.button_googleplay}
                style={Style.appButton}
              />
            </Link>
          </View>
        </View>
        <View style={Style.copyContainer}>
          <Text style={Style.copyText}>
            TUTTI DIRITTI RISERVATI (C) {new Date().getFullYear()} - POWERED BY
            FIDAL - P.IVA 01384571004
          </Text>
          <Image source={Images.fidal_logo_footer} style={Style.fidalLogo} />
        </View>
      </View>
      {contactPopUp && (
        <Popup
          title={"Contattaci"}
          closeHandler={() => setContactPopUp(false)}
          text={`Se desideri contattarci, scrivici all'indirizzo\n${
            Config.CONTACTS.address
          }`}
          button={"Chiudi"}
        />
      )}
    </View>
  ) : (
    <>
      {advItem && (
        <View style={ApplicationStyles.screen.footerBanner}>
          <Adv adv={advItem} />
        </View>
      )}
      <View style={Style.wrapper}>
        <View style={Style.container}>
          <View style={Style.accordionItem}>
            <SlideItem
              padding={5}
              border={false}
              title={"AIUTO"}
              titleStyle={Style.accordionTitle}
              open={open === "help"}
              callback={(status) => setOpen(status ? "help" : null)}
            >
              <Link route={"faq"} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>FAQ</Text>
              </Link>
              <Button onPress={contactUs} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>Contattaci</Text>
              </Button>
            </SlideItem>
          </View>
          <View style={Style.accordionItem}>
            <SlideItem
              padding={5}
              border={false}
              title={"NOTE LEGALI"}
              titleStyle={Style.accordionTitle}
              open={open === "legals"}
              callback={(status) => setOpen(status ? "legals" : null)}
            >
              <Link route={"conditions"} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>
                  Termini e Condizioni
                </Text>
              </Link>
              <Link route={"privacy"} style={Style.menuBlockLink}>
                <Text style={Style.menuBlockLinkText}>
                  Informativa sulla privacy
                </Text>
              </Link>
            </SlideItem>
          </View>
          <View style={Style.accordionItem}>
            <SlideItem
              padding={5}
              border={false}
              title={"SEGUICI"}
              titleStyle={Style.accordionTitle}
              open={open === "social"}
              callback={(status) => setOpen(status ? "social" : null)}
            >
              <Link
                href={"//www.facebook.com/runcarditalia"}
                style={Style.menuBlockLink}
              >
                <Text style={Style.menuBlockLinkText}>Facebook</Text>
              </Link>
              <Link
                href={"//twitter.com/runcarditalia"}
                style={Style.menuBlockLink}
              >
                <Text style={Style.menuBlockLinkText}>Twitter</Text>
              </Link>
              <Link
                href={"//www.instagram.com/runcarditalia/"}
                style={Style.menuBlockLink}
              >
                <Text style={Style.menuBlockLinkText}>Instagram</Text>
              </Link>
            </SlideItem>
          </View>
          <View style={Style.buttons}>
            <Link
              href={"//www.apple.com/it/osx/apps/app-store/?id=39"}
              style={Style.marginBottom}
            >
              <Image source={Images.button_appstore} style={Style.appButton} />
            </Link>
            <Link href={"//play.google.com/store?hl=it"}>
              <Image
                source={Images.button_googleplay}
                style={Style.appButton}
              />
            </Link>
          </View>
          <Text style={Style.copyText}>
            TUTTI DIRITTI RISERVATI (C) {new Date().getFullYear()} - POWERED BY
            FIDAL - P.IVA 01384571004
          </Text>
        </View>
      </View>
    </>
  );
};

Footer.propTypes = {
  adv: PropTypes.bool,
  section: PropTypes.string,
};

export default Footer;
