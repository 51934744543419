import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { getPath } from './AppRoutes';
import RouteWithSubRoutes from './RouteWithSubRoutes';

const DeepLinking = () => (<></>);

export {
  Router,
  DeepLinking,
  getPath,
  RouteWithSubRoutes,
};
