import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { View, Text, Image, Platform } from "react-native";
import sha1 from "crypto-js/sha1";
import { useHistory } from "react-router-native";
import { PubSub } from "aws-amplify";
import { Config } from "../../../../Config";

import { getResponsive } from "./PaymentStyle";
import { LoaderInline, WebView } from "../../../Layout";
import { SuccessPopup, ErrorPopup, Popup } from "../../../Popup";
import { Button } from "../../../Buttons";
import { Images } from "../../../../Theme";

import CattolicaActions from "../../../../Stores/Cattolica/Actions";
import LayoutActions from "../../../../Stores/Layout/Actions";
import { getPath } from "../../../../Router/Router";
import { isDesktop } from "../../../../Services/ResponsiveHelper";

const Payment = ({ scrollTop, clickHandler }) => {
  const Style = getResponsive();
  let history = useHistory();
  const dispatch = useDispatch();

  const quotation = useSelector((state) => state.cattolica.quotation);
  const user = useSelector((state) => state.auth.user);
  const { order_id, order_error } = useSelector((state) => state.order);

  const [frameUrl, setFrameUrl] = React.useState(null);
  const [frameHeight, setFrameHeight] = React.useState(1071);
  const [cancel, setCancel] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [success, setSuccess] = React.useState(false);
  const [operationSuccess, setOperationSuccess] = React.useState(null);

  React.useEffect(() => {
    dispatch(CattolicaActions.startCattolicaOrder());
  }, []);

  React.useEffect(() => {
    if (order_id) {
      loadIFrame();
      // Receive messages from Payment server-to-server callback (NEXI to Lambda)
      PubSub.subscribe(
        `runcard/${Config.STAGE}/cattolica-order-processed/${order_id}`
      ).subscribe({
        next: (data) => handleIotMessage(data),
        error: (err) => console.log("PubSub subscribe error", err),
      });
    }
  }, [order_id]);

  React.useEffect(() => {
    if (success === true) {
      setTimeout(() => {
        setCancel(false);
        setSuccess(false);
        setError(false);
        setOperationSuccess(null);
        dispatch(CattolicaActions.setQuotation(null));
        clickHandler();
      }, 1500);
    }
  }, [success]);

  const loadIFrame = () => {
    if (order_id) {
      dispatch(LayoutActions.setLoading(true));
      const codTrans = order_id;
      const importo = quotation?.quotation?.price
        ? quotation?.quotation?.price * 100
        : 0;

      const mac = sha1(
        `codTrans=${codTrans}divisa=${
          Config.PAYMENTS.NEXI_CATTOLICA.divisa
        }importo=${importo}${Config.PAYMENTS.NEXI_CATTOLICA.secret}`
      );

      const params = {
        alias: Config.PAYMENTS.NEXI_CATTOLICA.alias,
        importo,
        divisa: Config.PAYMENTS.NEXI_CATTOLICA.divisa,
        codTrans,
        url: Config.PAYMENTS.NEXI_CATTOLICA.success_callback,
        url_back: Config.PAYMENTS.NEXI_CATTOLICA.cancel_callback,
        mac,
        urlpost: Config.PAYMENTS.NEXI_CATTOLICA.success_url,
        mail: user.email,
        OPTION_CF: quotation?.data.tax_code,
        descrizione: "Acquisto assicurazione",
        languageId: "ITA",
      };
      setFrameUrl(
        `${Config.PAYMENTS.NEXI_CATTOLICA.url}?${Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join("&")}`
      );
    }
  };

  const handleMessage = (message) => {
    switch (message) {
      case "success_payment":
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 300);
        break;
      case "canceled_payment":
        setCancel(true);
        setFrameHeight(100);
        break;
      case "error_payment":
        setError(true);
        setFrameHeight(100);
        break;
    }
  };

  const handleIotMessage = (data) => {
    if (data.value.code !== "NexiWrongESITO") {
      setSuccess(true);
      setOperationSuccess(
        JSON.stringify({
          code: data.value.code,
          status: data.value.status,
          data: data.value.data,
        })
      );
    } else {
      setError(true);
      setFrameHeight(100);
    }
  };

  const nextAfterSuccess = () => {
    setCancel(false);
    setSuccess(false);
    setError(false);
    setOperationSuccess(null);
    dispatch(CattolicaActions.setQuotation(null));
    history.push(getPath("play"));
  };

  const handleLater = () => {
    setCancel(false);
    setError(false);
    setSuccess(false);
    setOperationSuccess(null);
    dispatch(CattolicaActions.setQuotation(null));
    history.push(getPath("play"));
  };

  const handleRetry = () => {
    setCancel(false);
    setError(false);
    setSuccess(false);
    setOperationSuccess(null);
    dispatch(CattolicaActions.startCattolicaOrder());
    setFrameUrl("about:blank");
    setFrameHeight(1071);
    loadIFrame();
  };

  const Retry = () => (
    <Button onPress={handleLater} style={Style.retry}>
      <Text style={Style.retryText}>Annulla</Text>
    </Button>
  );

  return (
    <View style={Style.wrapper}>
      {isDesktop() && (
        <Image
          source={Images.cattolica_logo}
          style={Style.logo}
          resizeMode={"contain"}
        />
      )}
      <View style={Style.inner}>
        <View style={[Style.fullWidth, { height: frameHeight }]}>
          {success === true &&
            !order_error &&
            !error &&
            (operationSuccess !== null ? (
              JSON.parse(operationSuccess).status === "OK" ? (
                <SuccessPopup
                  help={true}
                  closeHandler={() => nextAfterSuccess()}
                  title={"Acquisto avvenuto con successo"}
                  text={"TBD"}
                />
              ) : (
                <ErrorPopup
                  help={true}
                  closeHandler={() => history.push(getPath("play"))}
                  title={"Si è verificato un errore"}
                  text={`Non è stato possibile procedere con l'acquisto\n\nCodice ordine: ${order_id}\nCodice di errore: ${
                    JSON.parse(operationSuccess).code
                  }`}
                />
              )
            ) : (
              <Popup
                closeHandler={() => {}}
                title={"Pagamento avvenuto con successo"}
                button={false}
                text={"Acquisto in corso..."}
              >
                <LoaderInline style={Style.loader} />
              </Popup>
            ))}
          {cancel && (
            <ErrorPopup
              closeHandler={handleRetry}
              title={"Il pagamento è stato annullato"}
              text={
                "Se si intende procedere con l'acquisto, ripetere l'operazione"
              }
              button={"RIPROVA"}
              extra={<Retry />}
            />
          )}
          {error && (
            <ErrorPopup
              help={true}
              closeHandler={handleRetry}
              title={"Il pagamento non è andato a buon fine"}
              text={
                "Ci scusiamo per il disagio, ti preghiamo di riprovare. Grazie."
              }
              button={"RIPROVA"}
              extra={<Retry />}
            />
          )}
          {order_error && (
            <ErrorPopup
              help={true}
              closeHandler={() => history.push(getPath("play"))}
              title={"Si è verificato un errore"}
              text={`Non è stato possibile procedere con l\'acquisto\n\nCodice di errore: ${
                order_error.data && order_error.data.code
                  ? order_error.data.code
                  : "UNKNOWN"
              }`}
            />
          )}
          {frameUrl && (
            <WebView
              onNavigationStateChange={scrollTop}
              scrollEnabled={false}
              style={[
                Style.fullWidth,
                Platform.OS === "web" ? { height: frameHeight } : {},
              ]}
              url={frameUrl}
              onMessage={handleMessage}
              onLoad={() => dispatch(LayoutActions.setLoading(false))}
            />
          )}
        </View>
      </View>
    </View>
  );
};

Payment.propTypes = {
  scrollTop: PropTypes.func,
};

export default Payment;

// 4539970000000006 - scadenza 12/2030x
