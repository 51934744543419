import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const FilterIcon = ({ size=25, enabled=false }) => (
  <Svg width={size} height={size} viewBox={'0 0 25 25'}>
    <Defs>
      <LinearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'FilterIconGradient'}>
        <Stop stopColor={enabled? '#02AFF1': '#E3E4E3'} offset={'0%'} />
        <Stop stopColor={enabled? '#0D85F1' : '#C3C4C3'} offset={'100%'} />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <Path d="M17.3656522,6.0296 C16.4069565,6.0296 15.6286957,5.21131429 15.6286957,4.20331429 C15.6286957,3.19531429 16.4069565,2.37702857 17.3656522,2.37702857 C18.3254348,2.37702857 19.1026087,3.19531429 19.1026087,4.20331429 C19.1026087,5.21131429 18.3254348,6.0296 17.3656522,6.0296 L17.3656522,6.0296 Z M23.445,3.29017143 L21.6265217,3.29017143 L20.7156522,3.29017143 C20.245,1.34502857 18.3634783,0.170171429 16.5134783,0.665028571 C15.2863043,0.993028571 14.3286957,2.00102857 14.0167391,3.29017143 L2.68630435,3.29017143 L0.867826087,3.29017143 L-0.000652173913,3.29017143 L-0.000652173913,5.11645714 L0.867826087,5.11645714 L2.68630435,5.11645714 L14.0167391,5.11645714 C14.4873913,7.0616 16.368913,8.2376 18.218913,7.74274286 C19.446087,7.4136 20.4036957,6.40674286 20.7156522,5.11645714 L21.6265217,5.11645714 L23.445,5.11645714 L24.3134783,5.11645714 L24.3134783,3.29017143 L23.445,3.29017143 Z" fill="url(#FilterIconGradient)" fillRule={'evenodd'} />
      <Path d="M15.6291304,22.1741714 C14.6704348,22.1741714 13.8921739,21.3570286 13.8921739,20.3478857 C13.8921739,19.3398857 14.6704348,18.5227429 15.6291304,18.5227429 C16.588913,18.5227429 17.366087,19.3398857 17.366087,20.3478857 C17.366087,21.3570286 16.588913,22.1741714 15.6291304,22.1741714 L15.6291304,22.1741714 Z M23.4443478,19.4358857 L21.6269565,19.4358857 L18.9791304,19.4358857 C18.5084783,17.4896 16.6269565,16.3147429 14.7769565,16.8096 C13.5497826,17.1376 12.5921739,18.1456 12.2802174,19.4358857 L2.68673913,19.4358857 L0.86826087,19.4358857 L-0.000217391304,19.4358857 L-0.000217391304,21.2610286 L0.86826087,21.2610286 L2.68673913,21.2610286 L12.2802174,21.2610286 C12.7508696,23.2061714 14.6323913,24.3821714 16.4823913,23.8873143 C17.7095652,23.5581714 18.6671739,22.5513143 18.9791304,21.2610286 L21.6269565,21.2610286 L23.4443478,21.2610286 L24.3128261,21.2610286 L24.3128261,19.4358857 L23.4443478,19.4358857 Z" fill="url(#FilterIconGradient)" fillRule={'evenodd'} />
      <Path d="M6.07793478,10.4498286 C7.03771739,10.4498286 7.8148913,11.2669714 7.8148913,12.2761143 C7.8148913,13.2841143 7.03771739,14.1012571 6.07793478,14.1012571 C5.11923913,14.1012571 4.34097826,13.2841143 4.34097826,12.2761143 C4.34097826,11.2669714 5.11923913,10.4498286 6.07793478,10.4498286 L6.07793478,10.4498286 Z M0.868152174,13.1881143 L2.68663043,13.1881143 L2.72793478,13.1881143 C3.19967391,15.1344 5.0801087,16.3092571 6.93119565,15.8144 C8.15728261,15.4864 9.11597826,14.4784 9.42793478,13.1881143 L21.6268478,13.1881143 L23.4453261,13.1881143 L24.3127174,13.1881143 L24.3127174,11.3629714 L23.4453261,11.3629714 L21.6268478,11.3629714 L9.42793478,11.3629714 C8.95728261,9.41782857 7.07576087,8.24182857 5.22576087,8.73668571 C3.99858696,9.06582857 3.04097826,10.0726857 2.72793478,11.3629714 L2.68663043,11.3629714 L0.868152174,11.3629714 L-0.000326086957,11.3629714 L-0.000326086957,13.1881143 L0.868152174,13.1881143 Z" fill="url(#FilterIconGradient)" fillRule={'evenodd'} />
    </G>
  </Svg>
);

FilterIcon.propTypes = {
  size: PropTypes.number,
  enabled: PropTypes.bool,
};

export default FilterIcon;
