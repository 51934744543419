import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from "../../../Services/ResponsiveHelper";

const BaseStyle = {
  arrowIcon: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  container: {
    ...ApplicationStyles.screen.container,
    ...ApplicationStyles.screen.paddingTabBar,
  },
  endReachedText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.small,
    paddingTop: 10,
  },
  gradient: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  image: {
    height: 170,
    width: '100%',
  },
  listContainer: {
  },
  listFooter: {
    ...ApplicationStyles.screen.paddingTabBar,
    alignItems:'center',
    marginBottom: 20,
    paddingTop: 20,
  },
  loader: {
    margin: 20,
  },
  news: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
    paddingLeft: 30,
    paddingTop: 20,
  },
  newsContainer: {
    backgroundColor: Colors.white,
    elevation: 3,
    marginBottom: 5,
    marginTop: 15,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 1.5,
  },
  newsContentContainer: { },
  newsContentWrapper: { },
  newsDate: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayMedium,
    fontSize: Fonts.size.regular-2,
    marginBottom: 5,
  },
  newsSource: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-2,
    paddingTop: 5,
  },
  newsSummary: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
    paddingTop: 5,
  },
  newsTitle: {
    ...ApplicationStyles.screen.fonts,
    textTransform: 'uppercase',
  },
  noNewsText: {
    ...ApplicationStyles.screen.fontsLight,
    marginVertical: 20,
    textAlign: 'center',
  },
  noUnderline: {
    textDecorationLine: 'none',
  },
  titleContainer: {
    flex: 1,
  }
};

const StyleLG = {
  listContainer: {
    marginLeft: -10,
    marginRight: -10,
  },
  newsContainer: {
    marginLeft: 10,
    marginRight: 10,
  },
  newsContentContainer: {
    flex: 3,
  },
  newsContentWrapper: {
    flexDirection: 'row'
  },
  image: {
    flex: 2,
    height: 'auto',
    width: 'auto',
    marginRight: 20,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
