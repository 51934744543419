import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';

import { Button } from '../';
import Style from './ToggleButtonStyle';
import { Colors } from '../../../Theme';

const ToggleButton = ({ value, selected = false, text, checkHandler, buttonsNumber = 2 }) => (
  <Button onPress={() => checkHandler(value)} style={[selected ? [Style.button, Style.buttonSelected] : Style.button, { flex: 0.96 / buttonsNumber }]}>
    {selected ? (
      <LinearGradient colors={[Colors.blue1, Colors.blue2]} style={[Style.textWrapper, Style.gradient]}>
        <Text style={selected ? [Style.buttonText, Style.buttonTextSelected] : Style.buttonText}>{text}</Text>
      </LinearGradient>
    ) : (
      <View style={Style.textWrapper}>
        <Text style={selected ? [Style.buttonText, Style.buttonTextSelected] : Style.buttonText}>{text}</Text>
      </View>
    )}

  </Button>
);

ToggleButton.propTypes = {
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  checkHandler: PropTypes.func.isRequired,
  buttonsNumber: PropTypes.number,
};

export default ToggleButton;
