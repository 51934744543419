import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { LayoutTypes } from './Actions'

export const toggleSidebar = (state) => ({
  ...state,
  sidebar: !state.sidebar,
});

export const setTab = (state, { tab }) => ({
  ...state,
  tab,
});

export const setLoading = (state, { loading }) => {
  return ({
    ...state,
    loading,
  });
};

export const setError = (state, { error }) => ({
  ...state,
  error,
});

export const setBreakpoint = (state, { breakpoint }) => ({
  ...state,
  breakpoint,
});

export const toggleLoginModal = (state, { status }) => ({
  ...state,
  loginModal: status,
});

export const toggleRegistrationModal = (state, { status }) => ({
  ...state,
  registrationModal: status,
});

export const reducer = createReducer(INITIAL_STATE, {
  [LayoutTypes.TOGGLE_SIDEBAR]: toggleSidebar,
  [LayoutTypes.TOGGLE_LOGIN_MODAL]: toggleLoginModal,
  [LayoutTypes.TOGGLE_REGISTRATION_MODAL]: toggleRegistrationModal,
  [LayoutTypes.SET_LOADING]: setLoading,
  [LayoutTypes.SET_ERROR]: setError,
  [LayoutTypes.SET_TAB]: setTab,
  [LayoutTypes.SET_BREAKPOINT]: setBreakpoint,
});
