import React from 'react';
import { Text, View, ActivityIndicator, Animated } from 'react-native';
import { useSelector } from 'react-redux';

import Style from './SplashStyle';
import { Colors } from '../../Theme';
import { Logo } from '../../Theme/Icons';
import { Adv } from '../../Components/Layout';

import { getAdv } from '../../Services/ADVService';

const Splash = () => {

  const { optionsFetched, options } = useSelector(state => state.helper);

  const [ adv, setAdv ] = React.useState(null);
  const [ fadeAnim ] = React.useState(new Animated.Value(0));
  const [ heightAnim ] = React.useState(new Animated.Value(0));

  React.useEffect(() => {
    if (options && options.Advertising) {
      const adv = getAdv(options.Advertising, 'splash');
      if (adv) {
        setAdv(adv);
        Animated.timing(fadeAnim, { toValue: 1, duration: 300 }).start();
        Animated.timing(heightAnim, { toValue: 50, duration: 300 }).start();
      }
    }
  }, [ options, optionsFetched ]);

  return (
    <View style={Style.container}>
      <View style={Style.containerLogo}>
        <View style={Style.logo}>
          <Logo />
        </View>
        <View style={Style.loader}>
          <ActivityIndicator size={'large'} color={Colors.white} />
        </View>
      </View>
      <View style={Style.powered}>
        <Text style={Style.poweredText}>Powered by <Text style={Style.poweredTextHighlight}>FIDAL</Text></Text>
        {adv && (
          <Animated.View style={[Style.adv, { opacity: fadeAnim, height: heightAnim }]}>
            <Adv adv={adv} />
          </Animated.View>
        )}
      </View>
    </View>
  );
};

export default Splash;
