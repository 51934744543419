import { INITIAL_STATE } from './InitialState';
import { createReducer } from 'reduxsauce';
import { SocialWallTypes } from './Actions';

export const setSocialWallData = (state, { socialwall_data }) => ({
  ...state,
  socialwall_data,
});

export const setSocialWallDataError = (state, { error }) => ({
  ...state,
  socialwall_data_error: error,
});


export const reducer = createReducer(INITIAL_STATE, {
  [SocialWallTypes.SET_SOCIAL_WALL_DATA]: setSocialWallData,
  [SocialWallTypes.SET_SOCIAL_WALL_DATA_ERROR]: setSocialWallDataError,
});
