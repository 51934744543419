import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';

import Style, { getResponsive } from './PopupStyle';
import { Modal } from '../../Layout';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';
import { PrimaryButton } from '../../Buttons';
import { Button } from '../../Buttons';
import { CloseIcon } from '../../../Theme/Icons';
import { Colors } from '../../../Theme';

const Popup = ({ title, text, closeHandler, button='OK', children=null, showClose=null }) => {
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  return (
    <View onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}>
      <Modal animationType={'fade'} transparent={true} onRequestClose={closeHandler}>
        <LinearGradient colors={['rgba(0,42,85,0.95)', 'rgba(0,42,85,0.5)']} style={getResponsive().gradient}>
          <View style={getResponsive().wrapper}>
            {showClose && <Button style={Style.closeContainer} onPress={closeHandler}><CloseIcon color={Colors.blue1} /></Button>}
            <Text style={Style.h2}>{title}</Text>
            {text && <Text style={Style.text}>{text}</Text>}
            {children}
            {button !== false && (
              <PrimaryButton onPress={closeHandler} size={'small'} style={Style.button}>
                <Text>{button}</Text>
              </PrimaryButton>
            )}
          </View>
        </LinearGradient>
      </Modal>
    </View>
  )
};

Popup.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  button: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
};

export default Popup;
