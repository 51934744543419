import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from "../../../Services/ResponsiveHelper";

export const BaseStyle = {
  container: {
    width: '100%',
  },
  consent: {
    marginTop: 20,
    marginBottom: 40,
  },
  consentText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small+2,
    color: Colors.grayMedium2,
    marginBottom: 10,
  },
  error: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.red,
    fontSize: Fonts.size.regular-2,
    marginBottom: 10,
  },
  fieldsOneRow: {
    flex: 1,
    flexDirection: 'row',
  },
  fieldsOneRowSmall: {
    flex: 1,
    marginLeft: 10,
  },
  fieldsOneRowWide: {
    flex: 3,
  },
  genderButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
    marginTop: 20,
  },
  residenza: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+3,
    color: Colors.gray,
    marginBottom: 10,
  },
  spacer: {
    marginBottom: 20,
  },
  switchContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    marginBottom: 20,
  },
  switchContainerPrivacy: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    marginTop: 10,
  },
  switchInner: {
    flexDirection:'row',
    flexWrap: 'wrap',
    flex: 1,
    marginLeft: 10,
    alignSelf: 'center',
  },
  switchLink: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.small+1,
    textDecorationLine: 'underline',
  },
  switchText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small+2,
    color: Colors.grayMedium2,
  },
  textInput: {
    ...ApplicationStyles.screen.fontsLight,
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
    color: Colors.gray,
    fontSize: Fonts.size.regular,
    marginBottom: 10,
    paddingBottom: 10,
    paddingTop: 10,
  },
};

const StyleLG = {
  container: {
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
export default StyleSheet.create(BaseStyle);
