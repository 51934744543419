import { ApplicationStyles, Colors } from '../../Theme';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

const BaseStyle = {
  container: {

  },
  title: {
    ...ApplicationStyles.screen.fonts,
    flex: 1,
    paddingBottom: 5,
    paddingTop: 5,
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    marginTop: 10,
  },
  menu: {
    borderColor: Colors.grayALittleLight,
    borderTopWidth: 1,
    marginLeft: 30,
    marginRight: 30,
    paddingBottom: ApplicationStyles.screen.paddingTabBar.paddingBottom + 30,
  },
  webContainer: {

  },
};

const StyleLG = {
  container: {
    backgroundColor: Colors.white,
  },
  menu: {
    paddingBottom: 50,
  },
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
