import { StyleSheet } from 'react-native';

import { ApplicationStyles, Colors, Fonts } from '../../Theme';

export default StyleSheet.create({
  adv: {
    marginTop: 10,
  },
  container: {
    alignItems: 'center',
    backgroundColor: Colors.darkBlue,
    flex: 1,
    justifyContent: 'space-between',
  },
  containerLogo: {
    flex: 1,
    justifyContent: 'flex-start',
    paddingTop: 100,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
  logo: {
    height: 102,
    width: 257,
  },
  powered: {
    justifyContent: 'center',
    paddingBottom: 30,
  },
  poweredText: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.medium,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  poweredTextHighlight: {
    color: Colors.lightBlue,
  },
})
