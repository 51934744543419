import React from 'react';

export const ConfigCattolica = {
  'gara-sicura': {
    title: 'GARA SICURA',
    subtitle: '(Infortuni)',
    description: 'Ti garantisce una copertura in caso di infortunio nel giorno della gara podistica (su strada asfaltata e per un percorso inferiore a 100km) a cui ti iscrivi. Pensata per chi vuole competere in tutta sicurezza e tranquillità.',
    readMore: `Vincoli per l’acquisto:
• Età 18-75 anni 
• Residenza italiana (codice fiscale e indirizzo di residenza)
• Competizione su strada asfaltata e per un percorso inferiore a 100km 
        
I dati necessari sono:`,
    readMoreData: `• Nome
• Cognome
• Data di nascita
• Codice fiscale
• Sesso
• Indirizzo di residenza
• Nome evento
• Data evento
• Località evento - nazione
• Località evento - provincia
• Località evento - comune
`,
    policy: 1,
    dip: '/dip-test.pdf',//TODO '/dip.pdf'
    dip_2: '/dip-test.pdf',//TODO '/dip-avanzato.pdf'
  },
  'allenamento-protetto': {
    title: 'ALLENAMENTO PROTETTO',
    subtitle: '(Infortuni)',
    description: 'Ti garantisce una copertura in caso di infortunio durante la tua “marcia” di avvicinamento alle gare podistiche a cui ti iscrivi. Pensata per chi vuole allenarsi e gareggiare al massimo, senza avere altri pensieri e brutti imprevisti (a questi ci pensiamo noi).',
    readMore: `Vincoli per l’acquisto:
• Età 18-75 anni
• Residenza italiana (codice fiscale e indirizzo di residenza)
        
I dati necessari sono:`,
    readMoreData: `• Nome
• Cognome
• Data di nascita
• Codice fiscale
• Sesso
• Indirizzo di residenza
• Durata copertura
• Data di inizio copertura
`,
    policy: 2,
  },
  'viaggio-sereno': {
    title: 'VIAGGIO SERENO',
    subtitle: '(Annullamento)',
    description: 'Ti garantisce una copertura nel caso in cui tu non possa più partecipare, a causa di infortunio o malattia grave, all’evento sportivo al quale ti eri iscritto. Pensata per chi voglia tutelare il proprio viaggio e soggiorno e mettersi al riparo da eventi che impediscano di vivere l’esperienza di gara.',
    readMore: `Vincoli per l’acquisto:
• Età 18-75 anni
• Residenza italiana (codice fiscale e indirizzo di residenza)
• Competizione su strada asfaltata e per un percorso inferiore a 100km 

I dati necessari sono:`,
    readMoreData: `• Nome
• Cognome
• Data di nascita
• Codice fiscale
• Sesso
• Indirizzo di residenza
• Nome evento
• Data inizio evento
• Data fine evento
• Località evento - nazione
• Località evento - provincia
• Località evento - comune
`,
    policy: 3,
  },
  /* 'meteo': {
    title: 'METEO',
    subtitle: null,
    description: 'Ti garantisce una copertura dall’annullamento di una gara per maltempo. Allenati al massimo, al resto ci pensiamo noi.',
    readMore: `Vincoli per l’acquisto:
• Età 18-75 anni 
• Residenza italiana (codice fiscale e indirizzo di residenza)
• Località/competizioni:
    - Italiane tutte se su strada asfaltata e per un percorso inferiore a 100km 
    - Estere solo da lista prestabilita

I dati necessari sono:`,
    readMoreData: `• Nome
• Cognome
• Data di nascita
• Codice fiscale
• Sesso
• Indirizzo di residenza
• Nome evento
• Data inizio evento
• Data fine evento
• Località evento - nazione
• Località evento - provincia
• Località evento - comune
`,
    policy: 4,
  }, */
};

export const ErrorTranslated = {
  'name invalid': 'Nome non valido',
  'surname invalid': 'Cognome non valido',
  'fiscalCode invalid': 'Codice fiscale non valido',
  'gender invalid': 'Genere non valido',
  'dateOfBirth invalid': 'Data di nascita non valida',
  'strartDate invalid': 'Data di inizio non valida',
  'elapsed start date': 'Data di inizio passata',
  'endDate invalid': 'Data di fine non valida',
  'elapsed end date': 'Data di fine passata',
  'italian city not existing': 'Città italiana non esistente',
  'city or district invalid': 'Città o provincia non valida',
  'zipCode invalid': 'CAP non valido',
  'the age of the insured will be too high on the date indicated': 'L\'età dell\'assicurato sarò troppo alta alla data indicata',
  'invalid event startDate, too early': 'Data di inizio non valida, troppo presto',
};
