import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  button: {
    borderBottomWidth: 1,
    borderColor: Colors.grayLight,
    borderLeftWidth: 1,
    borderRadius: 30,
    borderRightWidth: 1,
  },
  buttonSelected: {
  },
  buttonText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.gray,
    fontSize: Fonts.size.regular,
    textAlign: 'center',
  },
  buttonTextSelected: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.regular,
  },
  gradient: {
    backgroundColor: Colors.blue1,
    borderRadius: 30,
  },
  textWrapper: {
    paddingBottom: 5,
    paddingTop: 5,
  },
});

