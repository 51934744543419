import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  S3_upload: ['file'],
  S3_delete: ['file'],
  S3_uploadError: ['error'],
  S3_uploadSuccess: ['data'],
  S3_uploadReset: null,
});

export const S3Types = Types;
export default Creators;
