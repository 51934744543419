import React from 'react';
import PropTypes from 'prop-types';
import { ScrollView, View, Text, Switch } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import Style from './NotificationsStyle';
import { LoaderInline, PageHeader } from '../../Layout';

import AuthActions from '../../../Stores/Auth/Actions';
import HelperActions from '../../../Stores/Helper/Actions';

const Notifications = () => {
  const dispatch = useDispatch();
  const userNotifications = useSelector(state => state.auth.user.notifications || {});

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(false)
  }, [userNotifications]);

  const Notification = ({ title, description, id, border = true }) => (
    <View style={[Style.wrapper, border ? Style.border : {}]}>
      <View style={Style.head}>
        <View style={Style.titleContainer}>
          <Text style={Style.title}>{title}</Text>
        </View>
        <View style={Style.switchContainer}>
          {loading !== id
            ? <Switch onValueChange={val => handleNotification(id, val)} value={userNotifications[id] || false} />
            : <View style={Style.loaderContainer}><LoaderInline style={Style.loader} /></View>
          }
        </View>
      </View>
      <Text style={Style.description}>{description}</Text>
    </View>
  );

  Notification.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string,
    border: PropTypes.bool,
  };

  const handleNotification = (id, value) => {
    setLoading(id);
    dispatch(AuthActions.updateNotification(id, value));
    dispatch(HelperActions.sendCRMEvent('event_changed_notifications'));
    if (value) dispatch(HelperActions.sendLeaderboardEvent('enable-notifications'));
  };

  const notifications = {
    race_reminder: {
      title: 'Promemoria gara',
      description: 'Inviami notifiche prima di uno dei miei eventi',
    },
    expiring_runcard: {
      title: 'Scadenza Runcard',
      description: 'Inviami notifiche prima della scadenza della mia Runcard',
    },
  };
  return (
    <>
      <PageHeader title={'Notifiche'} />
      <ScrollView contentContainerStyle={Style.container} bounces={false}>
        <View style={Style.innerContainer}>
          {Object.keys(notifications).map((id, index) => <Notification key={id} title={notifications[id].title} description={notifications[id].description} id={id} border={index < Object.keys(notifications).length - 1} />)}
        </View>
      </ScrollView>
    </>
  );
};

export default Notifications;
