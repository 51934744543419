export const INITIAL_STATE = {
  editorial_challenges: null,
  editorial_error_challenges: null,
  editorial_loadmore_challenges: false,
  user_challenges: null,
  user_error_challenges: null,
  user_loadmore_challenges: false,
  challenge: null,
  create_challenge_success: null,
  challenges_results: {},
  challenges_won: [],
};
