import { Colors, ApplicationStyles, Fonts } from '../../../../Theme';
import { BaseStyle as BaseStyleChallenge , StyleLG as StyleLGChallenge } from '../Challenge/ChallengeStyle';
import ResponsiveHelper from "../../../../Services/ResponsiveHelper";

export const BaseStyle = {
  ...BaseStyleChallenge,
  deadlineContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  error: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.red,
    fontSize: Fonts.size.regular-2,
    marginBottom: 20,
  },
  input: {
    ...ApplicationStyles.screen.fontsLightItalic,
    backgroundColor: Colors.grayUltraLight,
    borderRadius: 50,
    color: Colors.grayMedium2,
    padding: 12,
    textAlign: 'center',
  },
  inputContainer: {
    paddingTop: 42,
  },
  webContainer: {
    ...ApplicationStyles.screen.paddingTabBar,
  },
  wrapper: {
    ...ApplicationStyles.screen.container,
  },
};

export const StyleLG = {
  ...StyleLGChallenge,
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  }
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
