import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  buttonsContainer: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    marginBottom: 20,
    marginLeft: 20,
    marginRight: 20,
    paddingBottom: 5,
    paddingTop: 20,
  },
  cancel: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    margin: 20,
    marginTop: 0,
    padding: 10,
  },
  cancelText: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.medium,
    textAlign: 'center',
  },
  firstOption: {
    borderTopWidth: 0,
    marginTop: -5,
    paddingTop: 0,
  },
  modalBackground: {
    backgroundColor: Colors.transparentBlue,
    flex: 1,
    justifyContent: 'space-around',
  },
  option: {
    borderColor: Colors.grayUltraLight,
    borderTopWidth: 1,
    padding: 10,
  },
  optionText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.medium,
    textAlign: 'center',
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.medium,
    paddingBottom: 15,
    textAlign: 'center',
  },
});
