import { ApplicationStyles, Colors } from '../../../../Theme';
import ResponsiveHelper from '../../../../Services/ResponsiveHelper';

const BaseStyle = {
  container: {
    flexGrow: 1,
  },
  editorialGradient: {
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.5,
    shadowRadius: 2,
    marginBottom: 30,
  },
  section: {
    flex: 1,
    justifyContent: 'center',
  },
  sectionEditorial: {
    flex: 1,
  },
  sectionUser: {
    flex: 1,
  },
  separator: {
    borderBottomWidth:1,
    borderColor: Colors.grayALittleLight,
    marginHorizontal: 30,
  },
  webContainer: {},
  wrapper: {
    flexGrow: 1,
    ...ApplicationStyles.screen.paddingTabBar,
  },
};

const StyleLG = {
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
