import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { View, Text, Image, ScrollView, ImageBackground, RefreshControl, Platform } from 'react-native';
import moment from 'moment';
import { useHistory } from 'react-router-native';
import { Config } from '../../../../Config';

import { getResponsive } from './ChallengeStyle';
import { Images, Colors } from '../../../../Theme';
import { BackIcon, ShareIcon, Trophy } from '../../../../Theme/Icons';
import { Footer, LoaderInline, PageHeader, PageSidebar, Slider } from '../../../Layout';
import { Button, PrimaryButton, Share } from '../../../Buttons';
import { ConfirmPopup } from '../../../Popup';

import { getPath } from '../../../../Router/AppRoutes';
import ChallengesActions from '../../../../Stores/Challenges/Actions';
import Analytics from '../../../../Services/AnalyticsService';

const Challenge = ({ type, match }) => {
  const Style = getResponsive();
  let history = useHistory();
  const dispatch = useDispatch();

  const challenge = useSelector(state => state.challenges.challenge);
  const list = useSelector(state => state.challenges[`${type}_challenges`]);
  const results = useSelector(state => state.challenges.challenges_results);

  const [ confirmPopup, setConfirmPopup ] = React.useState(false);
  const [ kmDone, setKmDone ] = React.useState(null);
  const [ refreshing, setRefreshing ] = React.useState(false);

  React.useEffect(() => {
    Analytics.getInstance().trackChallengesView();
  }, []);

  React.useEffect(() => {
    const challengeId = match.params.id;
    if (challengeId && (!challenge || challenge.id !== challengeId)) {
      let challenge = null;
      if (list) {
        challenge = list.filter(el => el.id === challengeId);
        if (challenge.length) dispatch(ChallengesActions.setChallenge(challenge.shift()));
      }
      if (!challenge) {
        dispatch(ChallengesActions.getChallenge({ uuid: challengeId, type }));
      }
    }
  }, [ match, type, challenge ]);

  React.useEffect(() => {
    if (challenge && (challenge.joined || type !== 'editorial') && Platform.OS === 'web') {
      setKmDone(challenge.completed);
      if (challenge.completed < challenge.km) {
        challenge.completed = 0;
      }
      return;
    }
    if (challenge && (challenge.joined || type !== 'editorial') && (!challenge.completed || challenge.completed === 0)) {
      if (typeof results[type === 'editorial' ? challenge.user_challenge_id : challenge.id] !== 'undefined') {
        setKmDone(results[type === 'editorial' ? challenge.user_challenge_id : challenge.id]);
      } else {
        dispatch(ChallengesActions.fetchChallengeProgress(challenge, type));
      }
    } else if (challenge && !challenge.joined && type === 'editorial') {
      setKmDone(0);
    }
  }, [ challenge ]);

  const inProgress = () => {
    // Check if the challenge has been joined (if editorial) and if it's not yet completed
    return (challenge && (challenge.joined || type !== 'editorial') && (!challenge.completed || challenge.completed === 0));
  };

  const past = challenge ? !moment(challenge.end_date, 'YYYYMMDD').isAfter(moment()) : null;

  const handleJoin = () => {
    if (type !== 'editorial' ||  challenge.joined) {
      setConfirmPopup(true);
    } else {
      handleConfirm(true);
    }
  };

  const handleConfirm = (status) => {
    setConfirmPopup(false);
    if (status) {
      dispatch(ChallengesActions.joinChallenge({ uuid: challenge.id, type }, !(type !== 'editorial' ||  challenge.joined)))
    }
  };

  const onRefresh = () => {
    dispatch(ChallengesActions.fetchChallengeProgress(challenge, type));
    setRefreshing(true);
  };

  React.useEffect(() => {
    if (challenge && (challenge.joined || type !== 'editorial') && (!challenge.completed || challenge.completed === 0)) {
      if (typeof results[type === 'editorial' ? challenge.user_challenge_id : challenge.id] !== 'undefined') {
        setKmDone(results[type === 'editorial' ? challenge.user_challenge_id : challenge.id]);
        if (results[challenge.id] === 'LOADING') {
          setRefreshing(true);
        } else {
          setRefreshing(false);
        }
      }
    }
  }, [ results ]);

  return (
    <>
      <PageHeader
        title={type === 'editorial' ? 'Top sfide' : 'I tuoi obiettivi'}
        desc={'Supera i tuoi limiti'}
        back={(history.hasOwnProperty('canGo') && !history.canGo(-1)) || history.length > 1}
        left={(!history.hasOwnProperty('canGo') || !history.canGo(-1)) && history.length <= 1 ? <Button onPress={() => history.push(getPath('challenges'))}><BackIcon /></Button> : null}
      />
      <ScrollView bounces={inProgress()} refreshControl={inProgress() ? <RefreshControl refreshing={refreshing} onRefresh={onRefresh} /> : null}>
        <View style={Style.webContainer}>
          {challenge ? (
            <View style={Style.wrapper}>
              {type === 'editorial' ?
                Platform.OS === 'web'
                  ? (
                    <img src={challenge.image} alt={challenge.title} style={Style.image} />
                  )
                  : (
                    <View style={Style.imageContainer}>
                      <Image style={Style.image} resizeMode={'cover'} source={{ uri: challenge.image }} />
                    </View>
                  )
                : (
                <ImageBackground source={!past ? Images.goal : Images.goal_past} style={Style.kmContainer}>
                  <Text style={Style.imgKmText}>{challenge.km}km</Text>
                </ImageBackground>
              )}
              <View style={Style.columns}>
                <View style={Style.innerContainer}>
                  <View style={Style.challengeBox}>
                    <View style={Style.challengeTextTop}>
                      <Text style={Style.challengeTitle}>{challenge.title}</Text>
                      <Text style={Style.endsIn}>{past ? type === 'editorial' ? 'Terminata' : 'Terminato' : `Termina tra ${moment(challenge.end_date, 'YYYYMMDD').diff(moment(), 'days')} giorni`}</Text>
                    </View>
                    {(challenge && challenge.completed > 0) ? (
                      <Share url={`${Config.CDN}/challenge-badges/${type === 'editorial' ? challenge.user_challenge_id : challenge.id}_badge.png`} fetchBefore={true} />
                    ) : (
                      <ShareIcon color={Colors.grayALittleLight} />
                    )}
                  </View>
                  {(kmDone !== null && kmDone !== 'LOADING' && kmDone !== 'ERROR') || challenge.completed > 0
                    ? (
                      <>
                        <View style={Style.ranDistanceWrapper}>
                          <Text style={Style.ranDistance}>{challenge.completed > 0 ? challenge.km : kmDone} km</Text>
                        </View>
                        <Slider
                          maximumValue={parseInt(challenge.km)}
                          minimumValue={0}
                          max={parseInt(challenge.km)}
                          min={0}
                          value={challenge.completed > 0 ? parseInt(challenge.km) : kmDone}
                          disabled={!(Platform.OS === 'web')}
                          minimumTrackTintColor={Colors.blue2}
                          maximumTrackTintColor={Colors.grayLight}
                          trackStyle={Style.sliderTrackStyle}
                          railStyle={Style.sliderRailStyle}
                          handleStyle={Style.sliderHandleHidden}
                        />
                        <Text style={Style.challengeLength}>{`${challenge.km} km`}</Text>
                      </>
                      )
                    : kmDone !== 'ERROR' ? <LoaderInline style={Style.loader} /> : <Text style={Style.kmError}>Non è stato possibile recuperare i tuoi allenamenti</Text>
                  }
                  <View style={Style.detailsContainer}>
                    <View style={Style.detailsRow}>
                      <Text style={Style.detailType}>Durata</Text>
                      <Text style={Style.detailText}>{`${moment(challenge.start_date, 'YYYYMDD').format('DD MMM')} - ${moment(challenge.end_date, 'YYYYMDD').format('DD MMM')}`}</Text>
                    </View>
                    {type === 'editorial' && (
                      <View style={Style.detailsRow}>
                        <Text style={Style.detailType}>Partecipanti</Text>
                        <Text style={Style.detailText}>{`${challenge.users} runner`}</Text>
                      </View>
                    )}
                    <View style={Style.detailsRow}>
                      <Text style={Style.detailType}>Punti</Text>
                      <Text style={Style.detailTextBold}>{`+ ${challenge.score} XP`}</Text>
                    </View>
                  </View>
                  {type === 'editorial' && challenge.description && (
                    <View style={Style.descriptionContainer}>
                      <Text style={Style.descriptionText}>{challenge.description}</Text>
                    </View>
                  )}
                  {!past && (!challenge.completed || challenge.completed === 0) ? (
                    <View style={Style.buttonContainer} >
                      <PrimaryButton onPress={() => handleJoin()}>
                        <Text>{type !== 'editorial' ||  challenge.joined ? 'ABBANDONA' : 'PARTECIPA'}</Text>
                      </PrimaryButton>
                    </View>
                    ) : (challenge.joined || type !== 'editorial') && (
                    <View style={Style.endedChallenge}>
                      {challenge.completed > 0
                        ? (
                          <View style={Style.completedContainer}>
                            <Trophy size={50} />
                            <Text style={Style.completedText}>Complimenti, obiettivo completato!</Text>
                          </View>
                        ) : <Text style={Style.endedChallengeText}>{type !== 'editorial' ? 'Non hai completato l\'obiettivo' : 'Hai partecipato alla sfida'}</Text>
                      }
                    </View>
                    )
                  }
                </View>
                <PageSidebar section={'sfide'} />
              </View>
            </View>
          ) : challenge === false && (
            <Text style={Style.noChallengeText}>{type === 'editorial' ? 'Sfida non trovata' : 'Obiettivo non trovato'}</Text>
          )}
        </View>
        <Footer />
        {confirmPopup && (
          <ConfirmPopup closeHandler={handleConfirm} title={`${type === 'editorial' ? 'Sei sicuro di voler abbandonare la sfida?' : 'Abbandonando il tuo obiettivo lo eliminerai!'}`} cancelButton={'ANNULLA'} confirmButton={type === 'editorial' ? 'ABBANDONA' : 'ELIMINA'} />
        )}
      </ScrollView>
    </>
  );
};

Challenge.propTypes = {
  type: PropTypes.oneOf(['editorial', 'user']),
  match: PropTypes.object,
};

export default Challenge;
