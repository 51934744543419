import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  container: {
    flex: 1,
  },
  dataStyle: {
    color: Colors.gray,
    ...ApplicationStyles.screen.fontsRegular,
    textAlign: 'center',
  },
  endReachedText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.small,
    paddingVertical: 10,
  },
  errorText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayMedium,
    fontSize: Fonts.size.small,
    backgroundColor: Colors.white,
    alignItems: 'center',
    marginTop: 10,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 20,
    elevation: 2,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  errorTextSalute: {
    ...ApplicationStyles.screen.fontsLight,
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 20,
    textAlign: 'center',
  },
  headerContainer: {
    backgroundColor: Colors.grayUltraFantaLight,
    overflow: 'hidden',
    paddingBottom: 5,
    marginBottom: -5,
  },
  headerItem: {
    flex: 1,
    alignItems: 'center',
  },
  headerItemText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.blue1,
    fontSize: Fonts.size.small-1,
    paddingTop: 3,
  },
  headerTable: {
    backgroundColor: Colors.grayUltraFantaLight,
    flexDirection: 'row',
    justifyContent: 'space-between',
    elevation: 2,
    paddingHorizontal: 30,
    paddingBottom: 10,
    paddingTop: 20,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  item: {
    flex: 1,
  },
  itemContainer: {
    backgroundColor: Colors.white,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    paddingVertical: 20,
    paddingHorizontal: 30,
    elevation: 2,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  listFooter: {
    ...ApplicationStyles.screen.paddingTabBar,
    alignItems: 'center',
    marginTop: 20,
  },
  labelStyle: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.blue1,
    fontSize: Fonts.size.small-1,
    textAlign: 'center',
  },
  noWorkOutText: {
    ...ApplicationStyles.screen.fontsLight,
    marginBottom: 20,
    marginTop: 20,
    textAlign: 'center',
  },
  sourceIcon: {
    height: 30,
    width: 30,
  },
  sourceIconContainer: {
    marginBottom: -10,
  },
};

const StyleLG = {
  headerContainer: {
    paddingLeft: 0,
  },
  headerTable: {
    paddingRight: 70,
    marginLeft: 30,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
