import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ScrollView, Text, View } from "react-native";
import { Redirect, useHistory } from "react-router-native";
import { Config } from "../../Config";

import { getResponsive } from "./RuncardStyle";
import { Card as RuncardCard } from "../../Components/Runcard";
import { ErrorPopup } from "../../Components/Popup";
import { Footer, PageHeader, SlideItem } from "../../Components/Layout";

import LayoutActions from "../../Stores/Layout/Actions";
import RuncardActions from "../../Stores/Runcard/Actions";
import { getPath } from "../../Router/Router";
import Analytics from "../../Services/AnalyticsService";
import HelperActions from "../../Stores/Helper/Actions";
import { Button } from "../../Components/Buttons/index";

const Runcard = ({ openTutorial }) => {
  const dispatch = useDispatch();

  const Style = getResponsive();
  const history = useHistory();

  const user = useSelector((state) => state.auth.user);
  const { runcard, error: runcardError } = useSelector(
    (state) => state.runcard
  );

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    Analytics.getInstance().trackRuncardView();
    dispatch(LayoutActions.setTab("RUNCARD"));
    if (user.runcard && user.runcard !== "" && !runcard) {
      dispatch(RuncardActions.getRuncard());
    }
  }, []);

  return user && typeof user.runcard !== "undefined" ? (
    runcardError ? (
      <ErrorPopup
        closeHandler={() => history.push(getPath(Config.APP_HOMEPAGE))}
        title={"Ops"}
        text={"Si è verificato un errore, riprova più tardi"}
      />
    ) : (
      runcard && (
        <ScrollView bounces={false} contentContainerStyle={Style.container}>
          <PageHeader
            back={false}
            mobile={false}
            title={"La tua Runcard"}
            desc={"Controlla lo stato della tua card"}
          />
          <RuncardCard
            user={user}
            runcard={runcard}
            openTutorial={openTutorial}
          />

          <View style={Style.webContainer}>
            <View style={Style.menu}>
              <SlideItem
                titleStyle={Style.title}
                open={open}
                title={"Vuoi fare un upgrade?"}
                callback={(status) => {
                  setOpen(status);
                  if (status)
                    dispatch(
                      HelperActions.sendCRMEvent("event_open_upgrade_runcard")
                    );
                }}
              >
                <Text style={Style.text}>
                  Si. Il cambiamento però può essere eseguito solo dai nostri
                  operatori.{"\n"}
                  Nel caso in cui si voglia passare da una Runcard Standard ad
                  una Runcard EPS, bisogna inviare copia del proprio tesserino
                  EPS ed assicurarsi che l’Atletica Leggera sia compresa nel
                  tesseramento.{"\n"}
                  Qualora invece si voglia passare da una Runcard Trail o EPS in
                  corso di validità ad una Runcard Standard, bisognerà
                  effettuare alla FIDAL un bonifico di integrazione del costo.
                  {"\n"}
                  Se la Runcard invece è scaduta, non si dovrà pagare nessun
                  sovrapprezzo.
                </Text>
              </SlideItem>
              {/* <Button route={"certificate"}>
                <Text style={{ color: "#02aff1", fontSize: 16, marginTop: 15 }}>
                  È necessario un certificato medico per attività sportiva
                  valido per accedere alle competizioni
                </Text>
              </Button> */}
            </View>
          </View>

          <Footer />
        </ScrollView>
      )
    )
  ) : (
    <Redirect to={getPath("linkRuncard")} />
  );
};

Runcard.propTypes = {
  openTutorial: PropTypes.func.isRequired,
};

export default Runcard;
