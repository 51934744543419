const size = {
  h1: 26,
  h2: 20,
  h3: 20,
  input: 18,
  regular: 17,
  medium: 20,
  big: 24,
  small: 13,
};

const style = {
  h1: {
    fontSize: size.h1,
  },
  h2: {
    fontSize: size.h2,
  },
  h3: {
    fontSize: size.h3,
  },
  normal: {
    fontSize: size.regular,
  },
};

export default {
  size,
  style,
};
