import React from 'react';
import { Text } from 'react-native';
import PropTypes from 'prop-types';
import LinearGradient from 'react-native-linear-gradient';

import { Button, ButtonSizes } from '../';
import Style from './PrimaryButtonStyle';
import { Colors } from '../../../Theme';

const PrimaryButton = ({ size='medium', onPress, height, children, disabled=false, style={}, textStyle={}, route }) => {
  return (
    <Button route={route} onPress={onPress} style={[Style.button, { height: height ? height : ButtonSizes[size] }, disabled ? Style.buttonDisabled : {}, size==='xsmall' ? Style.xSmallButton : {}, style]} disabled={disabled}>
      <LinearGradient colors={[Colors.blue1, Colors.blue2]} style={Style.gradient}>
        <Text style={[Style.text, size==='xsmall' ? Style.xSmallText : {}, textStyle]}>{children}</Text>
      </LinearGradient>
    </Button>
  )
};

PrimaryButton.propTypes = {
  height: PropTypes.number,
  size: PropTypes.oneOf(['medium', 'small', 'xsmall']),
  onPress: PropTypes.func,
  route: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
  textStyle: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
};

export default PrimaryButton;
