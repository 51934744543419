import { Colors, Fonts, ApplicationStyles } from '../../../../Theme';
import ResponsiveHelper from '../../../../Services/ResponsiveHelper';
import { Platform } from 'react-native';

export const BaseStyle = {
  noChallengeText: {
    ...ApplicationStyles.screen.fontsLight,
    marginBottom: 20,
    marginTop: 20,
    textAlign: 'center',
  },
  buttonContainer: {
    paddingBottom: 30,
  },
  challengeTextTop: {
    flexDirection: 'column',
  },
  challengeBox: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  columns: { },
  completedContainer: {
    flex:1,
    alignItems:'center',
  },
  completedText: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    marginTop: 10,
  },
  descriptionContainer: {
    paddingBottom: 30,
  },
  descriptionText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular - 2,
  },
  detailsContainer: {
    flex: 1,
    flexDirection: 'column',
    paddingVertical: 34,
  },
  detailText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular + 1,
  },
  detailTextBold: {
    ...ApplicationStyles.screen.fontsBold,
  },
  detailType: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.regular + 1,
    color: Colors.gray,
  },
  detailsRow: {
    flex: 1,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  endedChallenge: {
    flex: 1,
    paddingBottom: 30,
  },
  endedChallengeText: {
    ...ApplicationStyles.screen.fontsRegular,
    textAlign: 'center',
  },
  endsIn: {
    ...ApplicationStyles.screen.fontsLight,
    textTransform: 'uppercase',
    fontSize: Fonts.size.small + 2,
  },
  imageContainer: {
    flex: 1,
    height: 162,
  },
  image: {
    flex: 1,
    ...Platform.select({
      web: {
        width: '100%',
        height: 'auto',
      }
    }),
  },
  innerContainer: {
    marginTop: 30,
    marginLeft: 30,
    marginRight: 30,
  },
  kmContainer: {
    flex: 1,
  },
  kmError: {
    ...ApplicationStyles.screen.fontsLight,
    marginTop: 20,
    textAlign: 'center',
  },
  challengeLength: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.regular - 1,
    textAlign: 'right',
  },
  challengeTitle: {
    ...ApplicationStyles.screen.fontsBold,
    textTransform: 'uppercase',
  },
  loader: {
    marginTop: 40,
  },
  ranDistance: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.big + 1,
  },
  ranDistanceWrapper: {
    marginTop: 30,
    flex: 1,
    alignItems: 'center',
  },
  sliderHandle: {
    width: 25,
    height: 25,
    marginTop: -10,
    borderColor: Colors.grayUltraLight,
    backgroundColor: Colors.grayUltraLight,
  },
  sliderHandleHidden: {
    display: 'none',
  },
  sliderTrackStyle: {
    backgroundColor: Colors.blue2,
    height: 20,
    marginTop: -8,
    borderRadius: 20,
  },
  sliderRailStyle: {
    backgroundColor: Colors.grayLight,
    height: 5,
    marginTop: 0,
  },
  imgKmText: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.white,
    fontSize: Fonts.size.h1*3,
    paddingVertical: 30,
    textAlign: 'center',
  },
  webContainer: { },
  wrapper: {
    ...ApplicationStyles.screen.paddingTabBar,
    ...ApplicationStyles.screen.container,
  },
};

export const StyleLG = {
  buttonContainer: {
    minWidth: 300,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 50,
  },
  detailsRow: {
    marginTop: 20,
  },
  endedChallenge: {
    paddingBottom: 50,
  },
  challengeLength: {
    fontSize: Fonts.size.regular+5,
    marginTop: 10,
  },
  challengeTitle: {
    fontSize: Fonts.size.big,
  },
  columns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  descriptionText: {
    fontSize: Fonts.size.regular,
  },
  endsIn: {
    fontSize: Fonts.size.big-2,
  },
  image: {
    marginBottom: 30,
  },
  imgKmText: {
    fontSize: Fonts.size.h1*5,
    paddingVertical: 80,
  },
  kmContainer: {
    marginBottom: 30,
  },
  innerContainer: {
    width: 600,
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
  },
  ranDistance: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.big+10,
  },
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
