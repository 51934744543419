import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import Style from './PaginationDotsStyle';
import { Colors } from '../../../Theme';
import { Circle } from '../../../Theme/Icons';

const PaginationDots = ({ current, size }) => {
  return (
    <View style={Style.container}>
      {[...Array(size).keys()].map(key => (
        <View style={Style.dot} key={key}>
          <Circle size={key===current ? 8 : 6} color={Colors.grayLight} empty={key===current}  />
        </View>
      ))}
    </View>
  )
};

PaginationDots.propTypes = {
  current: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
};

export default PaginationDots;
