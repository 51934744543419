import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polyline } from 'react-native-svg';

import { Colors } from '../../Theme';

const CheckIcon = ({ size=20, color=Colors.green }) => (
  <Svg width={size} height={size} viewBox={'0 0 20 14'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'} strokeLinecap={'round'} strokeLinejoin={'round'}>
      <G transform={'translate(-214.000000, -420.000000)'} stroke={color} strokeWidth={'1.96'}>
        <Polyline points={'233 421 221.923077 433 215 427.666667'} />
      </G>
    </G>
  </Svg>
);

CheckIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default CheckIcon;
