import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { View, ScrollView, Text, Platform, Linking, SafeAreaView } from 'react-native';
import { Config } from '../../Config';

import { getResponsive } from './AdvantagesStyle';
import { Footer, Modal, PageHeader, SlideItem, WebView } from '../../Components/Layout';
import { Button, Download, Link } from '../../Components/Buttons';
import { BackIcon } from '../../Theme/Icons';

import LayoutActions from '../../Stores/Layout/Actions';
import HelperActions from '../../Stores/Helper/Actions';
import Analytics from '../../Services/AnalyticsService';

const Advantages = () => {
  const dispatch = useDispatch();
  const Style = getResponsive();

  const [ open, setOpen ] = React.useState(null);
  const [ attachment, setAttachment ] = React.useState(null);

  React.useEffect(() => {
    Analytics.getInstance().trackAdvantagesView();
    dispatch(LayoutActions.setTab(''));
    dispatch(HelperActions.sendLeaderboardEvent('read-benefits'));
  }, []);

  const handleDownload = (url) => {
    if (Platform.OS === 'ios') {
      setAttachment(url);
    } else {
      Linking.openURL(url);
    }
  };

  const InAppPdf = ({ pdf, text }) => (
    Platform.OS !== 'web'
      ? (
        <Button onPress={() => handleDownload(pdf)}>
          <Text style={Style.contentTitle}>{text}</Text>
        </Button>
      ) : (
        <Link href={pdf} target={'_blank'}>
          <Text style={Style.contentTitle}>{text}</Text>
        </Link>
      )
  );

  InAppPdf.propTypes = {
    pdf: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  };

  const items = [
    {
      title: 'PRINCIPALI VANTAGGI',
      content: (
        <View style={Style.content}>
          <Text style={Style.contentTitle}>ASSICURAZIONE</Text>
          <Text style={Style.contentText}>Una sinergia attraverso la quale tutti i possessori di Runcard possono contare su una copertura assicurativa per infortuni, compresa nel prezzo del tesseramento annuale, derivanti dall’attività sportiva di running.</Text>
          <Text style={Style.contentTitle}>BED & RUNFAST</Text>
          <Text style={Style.contentText}>10% di sconto su tutte le strutture aderenti a Bed&Runfast, è il primo portale in Italia che mette in comunicazione in maniera sistematica le strutture ricettive di qualunque tipologia (alberghi, b&b, agriturismi, villaggi, appartamenti...) e i runner che viaggiano.</Text>
        </View>),
    },
    {
      title: 'SERVIZI',
      content: (
        <View style={Style.content}>
          <Text style={Style.contentText}>
            Vivere l&apos;atletica, tutti i giorni, attraverso la corsa; vivere l&apos;atletica, in particolari momenti dell&apos;anno, dall&apos;interno, a contatto con i suoi protagonisti. In questa sezione i sottoscrittori della Runcard troveranno una serie di iniziative a loro dedicate che verranno organizzate direttamente dalla Federazione Italiana di Atletica Leggera. In particolare, saranno attivate le seguenti opportunità:
          </Text>
          <Text style={Style.contentText}>
            Biglietti &quot;dedicati&quot; ai grandi eventi d&apos;atletica leggera: con Runcard si potrà accedere a condizioni di favore per assistere alle più importanti manifestazioni di atletica leggera organizzate nel nostro Paese. A cominciare dal Golden Gala Pietro Mennea (www.goldengala.it), il meeting internazionale parte del circuito IAAF Diamond League, che ogni anno porta a Roma, sulle tribune dello Stadio Olimpico, i più grandi campioni della &quot;regina&quot;.
          </Text>
          <Text style={Style.contentText}>
            Accesso alle strutture FIDAL: Il vostro programma di allenamento prevede le famose &quot;ripetute&quot; su distanze ben precise? Volete provare cosa significa correre sul tartan? Con Runcard potete recarvi presso uno dei tanti impianti in Italia dove si allenano semplici amanti dell&apos;atletica e grandi campioni, e accedere alla struttura tutte le volte che volete alle stesse condizioni dei tesserati FIDAL.
          </Text>
          <Text style={Style.contentText}>
            Contatto diretto con i professionisti della federazione: Runcard certifica un&apos;appartenenza. Un passaporto che permette di avere condizioni di favore per accedere a servizi legati al mondo del running, consigli realizzati da professionisti certificati dalla Federazione riguardo alimentazione, allenamento e traumatologia e sconti su acquisti di materiali del mondo running.
          </Text>
          <Text style={Style.contentText}>
            Stage esclusivi con tecnici federali: stage di allenamento con i campioni azzurri - I possessori della Runcard potranno accedere a speciali stage di preparazione, organizzati presso le strutture di riferimento dell&apos;atletica italiana (a cominciare dalla &quot;Scuola&quot; di Formia, il Centro di Preparazione Olimpica del CONI, celebre per aver fatto da scenario alla preparazione di molte delle leggende azzurre). Tecnici professionisti, assistenza sanitaria e fisioterapica, ed il contatto con campioni del presente e del passato. Per sentire viva l&apos;esperienza dello sport. Da campioni.
          </Text>
          <Text style={Style.contentText}>
            Accesso diretto alle manifestazioni agonistiche non-stadia: Ti piace correre, fare delle competizioni su strada, ma non sei interessato a tesserarti per una società? Runcard risolve il tuo problema: Con Runcard è possibile partecipare alle manifestazioni agonistiche non-stadia: strada, corsa campestre, montagna e trail, organizzate sotto l&apos;egida della Federazione Italiana di Atletica Leggera (FIDAL). In ogni caso - come previsto dalla vigente normativa in materia - è obbligatoria la presentazione (sia da parte degli italiani che degli stranieri residenti e non residenti) di un certificato medico d&apos;idoneità agonistica specifico per l&apos;atletica leggera in corso di validità in Italia, che dovrà essere esibito agli organizzatori in originale.
          </Text>
        </View>),
    },
    {
      title: 'RUNCARES',
      content: (
        <View style={Style.content}>
          <Text style={Style.contentText}>
            Il mondo Runcard non finisce qui. Se aderisci a Runcard avrai tanti straordinari vantaggi per te e darai una mano anche a tre progetti sociali di grandissimo impatto: Action Aid, Sport senza frontiere e l’attività sportiva nelle scuole. Perché correre dev’essere una gioia. Per tutti.
          </Text>
          <Text style={Style.contentText}>
            <Text style={Style.contentTitle}>ACTION AID: </Text>
            Corri con Runcard e sostieni ActionAid! ActionAid in qualità di social partner della FIDAL partecipa al progetto Runcard beneficiando di 1 euro per ogni tessera sottoscritta. I fondi raccolti saranno destinati al Progetto di ActionAid &quot;Operazione Fame&quot;.
          </Text>
          <Text style={Style.contentTitle}>SPORT SENZA FRONTIERE</Text>
          <Text style={Style.contentText}>
            <Text style={Style.contentTitle}>ATLETICA NELLE SCUOLE: </Text>
            L&apos;atletica è uno sport meraviglioso, completo e adatto a tutti. Ma in Italia ha bisogno di essere ulteriormente sostenuto. Ben 10€ per ogni Runcard sottoscritta saranno destinati al sostegno della pratica dell&apos;atletica leggera nella scuola. I proventi dell&apos;iniziativa saranno investiti per promuovere la disciplina a livello giovanile e per l&apos;organizzazione dei Campionati Studenteschi. Un&apos;iniziativa senza precedenti in Italia.
          </Text>
        </View>),
    },
    {
      title: 'VISITA MEDICA',
      content: (
        <View style={Style.content}>
          <Text style={Style.contentText}>Runcard è un filo diretto con medici qualificati e centri d’eccellenza, per consentirti di vivere la tua passione in serenità ed in piena salute. Sono stati attivati diversi rapporti di convenzione con professionisti e strutture di primo piano distribuiti in tutto il territorio nazionale, in collaborazione con la Federazione Italiana Medico Sportiva.</Text>
          <Button route={'certificate'}><Text style={Style.contentText}>Vai a pagina Visita medica</Text></Button>
        </View>)
    }
  ];

  return (
    <View style={Style.wrapper}>
      <PageHeader title={'Vantaggi'} back={false} />
      <ScrollView bounces={false} contentContainerStyle={Style.wrapper} style={Style.flex}>
        <View style={Style.container}>
          {items && items.map((el, key) => <SlideItem titleStyle={Style.title} key={key} open={open===key} title={el.title} callback={(status) => setOpen(status?key:null)}>{el.content}</SlideItem>)}
        </View>
        <Footer />
      </ScrollView>
      {attachment && (
        <Modal transparent={true} onRequestClose={() => setAttachment(null)} animationType={'fade'}>
          <SafeAreaView style={Style.modalBackground}>
            <View style={Style.modalContainer}>
              <PageHeader title={'PDF'} back={false} left={<Button onPress={() => setAttachment(null)}><BackIcon /></Button> } />
              <View style={Style.modalDownload}>
                <Download url={attachment} />
              </View>
              <WebView url={attachment} />
            </View>
          </SafeAreaView>
        </Modal>
      )}
    </View>
  );
};

export default Advantages;
