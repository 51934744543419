import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, FlatList, Text } from 'react-native';

import { getResponsive } from './WorkoutStyle';
import { Colors } from '../../../Theme';
import { Circle } from '../../../Theme/Icons';
import { LoaderInline } from '../../Layout';
import Item from './Item';
import Header from './Header';

import WorkoutActions from '../../../Stores/Workout/Actions';

const Workout = () => {
  const Style = getResponsive();
  const canLoadMore = false;

  const [ loading, setLoading ] = React.useState(false);
  const [ onEndReachedCalledDuringMomentum, setOnEndReachedCalledDuringMomentum ] = React.useState(true);

  const dispatch = useDispatch();
  const { workout_data: data, google_fit_data_success: googleFitStatus, strava_data_success: stravaStatus, salute_data_success: saluteStatus } = useSelector(state => state.workout);
  const saluteEnabled = useSelector(state => state.services.salute);
  //const canLoadMore = useSelector(state => state.workout.loadMore);

  React.useEffect(() => {
    if (!googleFitStatus) {
      dispatch(WorkoutActions.getGoogleFitData());
    }
    if (!stravaStatus) {
      dispatch(WorkoutActions.getStravaData());
    }
    if (!saluteStatus) {
      dispatch(WorkoutActions.getSaluteData());
    }
  });

  const handleRefresh = () => {
    dispatch(WorkoutActions.getGoogleFitData(false));
    dispatch(WorkoutActions.getStravaData(false));
    dispatch(WorkoutActions.getSaluteData(false));
  };

  const loadMore = () => {
    if ((!onEndReachedCalledDuringMomentum || Platform.OS === 'web') && !loading) {
      setLoading(true);
      setOnEndReachedCalledDuringMomentum(true);
      // TODO
    }
  };

  const EmptyList = () => (
    !saluteEnabled ? (
      <View style={Style.listFooter}>
        <Circle size={6} color={Colors.grayLight} />
        <Text style={Style.endReachedText}>Nessun allenamento registrato</Text>
      </View>) : (
      <View style={Style.listFooter}>
        <Text style={Style.errorTextSalute}>Non risultano allenamenti registrati su Salute.{'\n\n'}Assicurati di aver concesso i permessi necessari, da Impostazioni &gt; Privacy &gt; Salute</Text>
      </View>
    )
  );

  return (
    <View style={Style.container}>
      <Header />
      {googleFitStatus === 'ERROR' && <Text style={Style.errorText}>Non è stato possibile recuperare i dati da Google Fit</Text>}
      {stravaStatus === 'ERROR' && <Text style={Style.errorText}>Non è stato possibile recuperare i dati da Strava</Text>}
      {saluteStatus === 'ERROR' && <Text style={Style.errorText}>Non è stato possibile recuperare i dati da Salute</Text>}
      <FlatList
        data={data ? data.map(workout => ({ ...workout, key: workout.workout_id  })) : []}
        renderItem={({item}) => <Item item={item} />}
        refreshing={googleFitStatus === 'LOADING' || stravaStatus === 'LOADING' || saluteStatus === 'LOADING'}
        keyExtractor={item => item.workout_id}
        onRefresh={handleRefresh}
        onEndReached={() => canLoadMore ? loadMore() : () => {}}
        ListEmptyComponent={<EmptyList />}
        initialNumToRender={7}
        onMomentumScrollBegin={() => setOnEndReachedCalledDuringMomentum(false) }
        onMomentumScrollEnd={() => setOnEndReachedCalledDuringMomentum(true) }
        ListFooterComponent={
          <View style={Style.listFooter}>
            {loading
              ? <LoaderInline style={{}} />
              : !canLoadMore && data && data.length
                ? <><Circle size={6} color={Colors.grayLight} /><Text style={Style.endReachedText}>Non ci sono altri allenamenti da mostrare!</Text></>
                : null
            }
          </View>
        }
      />
    </View>
  );
};

export default Workout;
