import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import { Platform } from 'react-native';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  arrowContainer: {
    bottom: 0,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 80
  },
  box: {
    elevation: 2,
    flex: 1,
    marginBottom: 5,
    marginTop: 5,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  boxBackground: {
    backgroundColor: Colors.darkBlue,
    height: '100%',
    width: '100%',
  },
  container: {
    paddingTop: 5,
    paddingBottom: Platform.OS === 'web' ? 5 : 0,
  },
  innerBox: {
    paddingHorizontal: 40,
    paddingVertical: 60,
  },
  pageHeader: {
    flex: 1,
    flexDirection: 'column',
    padding: 20,
  },
  pageHeaderSubtitle: {
    ...ApplicationStyles.screen.pageHeaderSubtitle,
  },
  pageHeaderTitle: {
    ...ApplicationStyles.screen.pageHeaderTitle,
  },
  text: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.regular,
    textAlign: 'center',
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.h1,
    textAlign: 'center',
  },
  wrapper: {
    paddingBottom: ApplicationStyles.screen.paddingTabBar.paddingBottom,
  },
};

const StyleLG = {
  title: {
    textShadowColor: Colors.black,
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 1,
  },
  text: {
    textShadowColor: Colors.black,
    textShadowOffset: { width: 0, height: 1 },
    textShadowRadius: 1,
  },
  container: {
    ...ApplicationStyles.screen.webContainer,
    paddingBottom: 50,
    paddingTop: 50,
  },
  wrapper: {
    paddingBottom: 0,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
