import { Config } from "../Config";
import { Platform } from "react-native";
import { Auth, API } from "aws-amplify";

import { getUniqueId } from "./NotificationService";

import sha1 from "crypto-js/sha1";

const fetchUser = async (identityId = false) => {
  const device_id = getUniqueId();

  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
  };

  if (identityId) {
    options.headers["x-identityid"] = identityId;
  }

  let url = "/user";
  if (Platform.OS !== "web") {
    url = `${url}?device_type=mobile&device_id=${device_id}`;
  }

  return await API.get(Config.API.name, url, options);
};

const saveUser = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };
  return await API.put(Config.API.name, "/user", options);
};
const deleteUser = async () => {

  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: {},
  };
  return await API.post(Config.API.name, "/user/delete", options);
};

const validateUser = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };
  return await API.post(Config.API.name, "/user/validate", options);
};

const linkRuncard = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };
  return await API.post(Config.API.name, "/runcard/link", options);
};

const fetchRuncard = async ({ invalidate_cache }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
  };
  if (invalidate_cache) {
    options.headers["Cache-Control"] = "max-age=0";
  }
  return await API.get(Config.API.name, "/runcard", options);
};

const startOrder = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };
  return await API.post(Config.API.name, "/payments/start", options);
};

const fetchCountries = async () => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(Config.API.name, "/countries?object=countries", options);
};

const fetchProvinces = async () => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(Config.API.name, "/countries?object=provinces", options);
};

const fetchFAQ = async () => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(Config.API.name, "/faq", options);
};

const fetchNews = async ({ category, from }) => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(
    Config.API.name,
    `/news?category=${category}${from ? `&from=${from}` : ""}`,
    options
  );
};

const fetchSingleNews = async ({ uuid }) => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(Config.API.name, `/news/${uuid}`, options);
};

const generateRuncardBarcode = async ({ code }) => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(Config.API.name, `/runcard/barcode/${code}`, options);
};

const fetchOptions = async () => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(Config.API.name, `/options`, options);
};

const fetchServices = async () => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(Config.API.name, `/services`, options);
};

const encodeServiceToken = async (service_id) => {
  const session = await Auth.currentSession();

  const now = Math.floor(Date.now() / 1000);

  let options = {
    headers: { "x-api-key": Config.API.apiKey },
    body: {
      token: JSON.stringify({
        access_token: session.getAccessToken().getJwtToken(),
        refresh_token: session.getRefreshToken().token,
        access_token_expires_in: session.getAccessToken().payload.exp - now,
      }),
    },
  };
  return await API.post(
    Config.API.name,
    `/services/${service_id}/enc-jwt`,
    options
  );
};

// TODO paginazione
const fetchEditorialChallenges = async () => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
  };
  return await API.get(Config.API.name, "/challenges/editorial", options);
};

// TODO paginazione
const fetchUserChallenges = async ({ from }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
  };
  return await API.get(Config.API.name, "/challenges/user", options);
};

const fetchChallenge = async ({ uuid, type }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
  };
  return await API.get(Config.API.name, `/challenges/${type}/${uuid}`, options);
};

const createChallenge = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };
  return await API.post(Config.API.name, "/challenges", options);
};

const joinChallenge = async ({ join, type, uuid }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: { join },
  };
  return await API.post(
    Config.API.name,
    `/challenges/${type}/${uuid}`,
    options
  );
};

const completeChallenge = async ({ km, type, uuid }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: { km },
  };
  return await API.put(Config.API.name, `/challenges/${type}/${uuid}`, options);
};

const fetchConventions = async ({ cap }) => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  return await API.get(Config.API.name, `/conventions?cap=${cap}`, options);
};

const savePushToken = async (data) => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
    body: data,
  };
  return await API.post(Config.API.name, `/push`, options);
};

const fetchResults = async () => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
  };
  return await API.get(Config.API.name, `/user/results`, options);
};

const fetchRaces = async (filters) => {
  filters = Object.keys(filters)
    .map((key) => `${key}=${filters[key]}`)
    .join("&");
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  const session = await Auth.currentSession().catch(() => null);
  if (session) {
    options.headers.Authorization = `Bearer ${session
      .getAccessToken()
      .getJwtToken()}`;
  }

  return await API.get(Config.API.name, `/races?${filters}`, options);
};

const fetchRace = async (id) => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };
  const session = await Auth.currentSession().catch(() => null);
  if (session) {
    options.headers.Authorization = `Bearer ${session
      .getAccessToken()
      .getJwtToken()}`;
  }

  return await API.get(Config.API.name, `/races/${id}`, options);
};

const saveRace = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };

  return await API.post(Config.API.name, "/races", options);
};

const fetchSavedRaces = async (date) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
  };
  return await API.get(Config.API.name, `/races/saved?d=${date}`, options);
};

const fetchSocialWall = async () => {
  let options = {
    headers: { "x-api-key": Config.API.apiKey },
  };

  return await API.get(Config.API.name, "/socialwall", options);
};

const postCrmEvent = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: {
      type: "crm",
      data,
    },
  };

  return await API.post(Config.API.name, "/crm-event", options);
};

const postLeaderboardEvent = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };

  return await API.post(
    Config.API.name,
    `/leaderboard/team/${Config.LEADERBOARD_TEAM_ID}/user/events`,
    options
  );
};

const postUserPdf = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };
  switch (data.type) {
    case "runcard-card":
      return await API.post(Config.API.name, "/user/pdf", options);
      break;

    case "certificate":
      return await API.post(Config.API.name, "/user/pdf", options);
      break;

    default:
      return await API.post(Config.API.name, "/user/pdf", options);
      break;
  }
};

const getLeaderboardLevel = async (email) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
  };

  return await API.get(
    Config.API.name,
    `/leaderboard/team/${Config.LEADERBOARD_TEAM_ID}/user/level?email=${email}`,
    options
  );
};

const getCattolicaQuotation = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };
  return await API.post(Config.API.name, `/cattolica/quotation`, options);
};

const getCattolicaEvents = async (data) => {
  let options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
      "x-api-key": Config.API.apiKey,
    },
    body: data,
  };

  return await API.get(Config.API.name, `/cattolica/events`, options);
};

export {
  fetchUser,
  deleteUser,
  saveUser,
  validateUser,
  fetchRuncard,
  linkRuncard,
  startOrder,
  fetchCountries,
  fetchProvinces,
  fetchFAQ,
  fetchNews,
  fetchSingleNews,
  generateRuncardBarcode,
  fetchOptions,
  fetchServices,
  fetchEditorialChallenges,
  fetchUserChallenges,
  fetchChallenge,
  createChallenge,
  joinChallenge,
  fetchConventions,
  completeChallenge,
  savePushToken,
  fetchResults,
  fetchRaces,
  fetchRace,
  saveRace,
  fetchSavedRaces,
  fetchSocialWall,
  encodeServiceToken,
  postCrmEvent,
  postLeaderboardEvent,
  postUserPdf,
  getLeaderboardLevel,
  getCattolicaQuotation,
  getCattolicaEvents,
};
