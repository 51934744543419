import { put, call, select } from 'redux-saga/effects'

import Strava from '../Services/StravaService';
import ServicesActions from '../Stores/Services/Actions';
import LayoutActions from '../Stores/Layout/Actions';
import NavigationActions from '../Stores/Navigation/Actions';
import WorkoutActions from '../Stores/Workout/Actions';
import { postCrmEvent, postLeaderboardEvent } from '../Services/ApiGatewayService';

export function* stravaAuthenticate({ url }) {
  yield put(LayoutActions.setLoading(true));

  try {
    const { authCode, error, scope } = yield call(Strava.parseAuthUrl, url);
    if (error) {
      yield put(ServicesActions.setStrava(false));
      yield put(ServicesActions.setStravaAuthError('Non è stato possibile collegare Strava al tuo account'));
      yield put(LayoutActions.setLoading(false));
      return;
    }

    const redirect = yield select((state) => state.navigation.afterStrava);
    if (redirect) {
      yield put(NavigationActions.pushNavigation(redirect));
    }

    const res = yield call(Strava.getToken, authCode);
    if (!res || !res.hasOwnProperty('access_token')) {
      yield put(ServicesActions.setStrava(false));
      yield put(ServicesActions.setStravaAuthError('Non è stato possibile collegare Strava al tuo account. Ritenta più tardi.'));
      yield put(LayoutActions.setLoading(false));
      return;
    }

    if (scope.indexOf(Strava.config.scope) === -1) {
      yield put(ServicesActions.setStrava(false));
      yield put(ServicesActions.setStravaAuthError('Non hai concesso le autorizzazioni necessarie per collegare Strava al tuo account'));
      yield put(LayoutActions.setLoading(false));
      return;
    }
    const { access_token, refresh_token, expires_at, athlete } = res;
    yield put(ServicesActions.setStrava(true));
    yield put(ServicesActions.setStravaData(access_token, refresh_token, expires_at, athlete.id));
    yield put(WorkoutActions.getStravaData(false));

    yield call(postCrmEvent, { event: 'event_enabled_strava' });
    yield call(postLeaderboardEvent, { event: 'connect-strava' });

    // Disable other services
    yield put(ServicesActions.setSalute(false));
    yield put(WorkoutActions.resetSaluteData());
    yield put(ServicesActions.setGoogleFit(false));
    yield put(WorkoutActions.resetGoogleFitData());

    yield put(LayoutActions.setLoading(false));
  } catch (error) {
    yield put(ServicesActions.setStrava(false));
    yield put(ServicesActions.setStravaAuthError('Non è stato possibile collegare Strava al tuo account. Ritenta più tardi.'));
    yield put(LayoutActions.setLoading(false));
  }
}

export function* stravaDisconnect() {
  yield put(LayoutActions.setLoading(true));
  const token = yield getOrRefreshStravaToken();
  if (token) {
    try {
      const res = yield call(Strava.disconnect, token);
      if (res && res.access_token) {
        yield put(ServicesActions.setStrava(false));
      } else {
        yield put(LayoutActions.setError({}));
      }
    } catch (err) {
      yield put(LayoutActions.setError({}));
    }
  }
  yield put(LayoutActions.setLoading(false));
}

function* getOrRefreshStravaToken() {
  let token = yield select((state) => state.services.strava_data);
  const refreshToken = yield call(Strava.refreshToken, token);
  if (refreshToken.expired) {
    if (refreshToken.refreshed) {
      token = refreshToken.token;
      const { access_token, refresh_token, expires_at, athlete } = token;
      yield put(ServicesActions.setStravaData(access_token, refresh_token, expires_at, athlete.id));
    } else {
      // Token is not valid anymore, delete.
      yield put(ServicesActions.setStravaData(null));
      yield put(ServicesActions.setStrava(false));
      yield put(ServicesActions.setStravaAuthError('Si è verificato un errore, per favore collega nuovamente il servizio'));
      return false;
    }
  }
  return token;
}
