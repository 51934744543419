import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Circle } from 'react-native-svg';

const FaqIcon = ({ size=32 }) => (
  <Svg width={size} height={size} viewBox={'0 0 32 32'}>
    <G transform="translate(-1318.000000, -133.000000)" fill="#FFFFFF" fillRule="nonzero">
      <G transform="translate(1318.000000, 133.000000)">
        <Circle cx="15.8936727" cy="23.6539636" r="1.56698182" />
        <Path d="M15.9682909,0 C7.14306563,0 0,7.14188049 0,15.9682909 C0,24.7935162 7.14188049,31.9365818 15.9682909,31.9365818 C24.7935162,31.9365818 31.9365818,24.7947013 31.9365818,15.9682909 C31.9365818,7.14306563 24.7947013,0 15.9682909,0 Z M15.9682909,29.4415364 C8.52201488,29.4415364 2.49504545,23.415565 2.49504545,15.9682909 C2.49504545,8.52201488 8.52101686,2.49504545 15.9682909,2.49504545 C23.4145669,2.49504545 29.4415364,8.52101686 29.4415364,15.9682909 C29.4415364,23.4145669 23.415565,29.4415364 15.9682909,29.4415364 Z" id="Shape" />
        <Path d="M15.8936727,8.05876364 C13.1369935,8.05876364 10.8942545,10.3030966 10.8942545,13.0617351 C10.8942545,13.7525203 11.4538144,14.3124779 12.1441091,14.3124779 C12.8344038,14.3124779 13.3939636,13.7525203 13.3939636,13.0617351 C13.3939636,11.6824158 14.5153331,10.5602494 15.8936727,10.5602494 C17.2720123,10.5602494 18.3933818,11.6824158 18.3933818,13.0617351 C18.3933818,14.4410543 17.2720123,15.5632208 15.8936727,15.5632208 C15.2033781,15.5632208 14.6438182,16.1231784 14.6438182,16.8139636 L14.6438182,19.9408208 C14.6438182,20.6316061 15.2033781,21.1915636 15.8936727,21.1915636 C16.5839674,21.1915636 17.1435273,20.6316061 17.1435273,19.9408208 L17.1435273,17.906425 C19.2974016,17.3496568 20.8930909,15.3886796 20.8930909,13.0617351 C20.8930909,10.3030966 18.6503519,8.05876364 15.8936727,8.05876364 Z" id="Path" />
      </G>
    </G>
  </Svg>

);

FaqIcon.propTypes = {
  size: PropTypes.number,
};

export default FaqIcon;
