import React from 'react';
import { View, Text, FlatList, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import Calendar from 'react-native-calendars/src/calendar';
import LocaleConfig from 'xdate';
import moment from 'moment';

import { localize } from '../../../Services/CalendarLocales';
LocaleConfig.locales['it'] = localize();
LocaleConfig.defaultLocale = 'it';

import { getResponsive } from './SavedRacesStyle';
import { LoaderInline } from '../../Layout';
import { ApplicationStyles, Colors } from '../../../Theme';
import { Circle, LeftArrow, RightArrow } from '../../../Theme/Icons';
import { Button } from '../../Buttons';
import RaceItem from '../../Races/List/Item';

import RacesActions from '../../../Stores/Races/Actions';

const SavedRaces = () => {
  const dispatch = useDispatch();
  const Style = getResponsive();

  const [ refreshing, setRefreshing ] = React.useState(false);
  const [ day, setDay ] = React.useState(null);
  const [ month, setMonth ] = React.useState(moment().startOf('month'));
  const [ marked, setMarked ] = React.useState({});
  const { saved_races: races, saved_races_error: error } = useSelector(state => state.races);

  React.useEffect(() => {
    dispatch(RacesActions.setSavedRaces(null));
    dispatch(RacesActions.getSavedRaces(false, month.format('YYYYMMDD')));
  }, [ month ]);

  React.useEffect(() => {
    if (refreshing) {
      setRefreshing(false);
    }
  }, [ races, error ]);

  React.useEffect(() => {
    if (races) {
      let dates = {};
      races.forEach(el => {
        dates[moment(el.date, 'YYYYMMDD').format('YYYY-MM-DD')] = {
          ...dates[moment(el.date, 'YYYYMMDD').format('YYYY-MM-DD')],
          marked: true,
          dotColor: Colors.blue1,
        };
      });

      if (day) {
        dates[day.format('YYYY-MM-DD')] = {
          ...dates[day.format('YYYY-MM-DD')],
          selected: true,
          selectedColor: Colors.grayLight,
          selectedTextColor: Colors.gray,
        };
      }
      setMarked(dates);
    }
  }, [ races, day ]);

  const handleRefresh = () => {
    setRefreshing(true);
    dispatch(RacesActions.getSavedRaces(false, month.format('YYYYMMDD')));
  };

  const displayRaces = races ? day ? races.filter(r => moment(r.date).startOf('day').isSame(day.startOf('day'))) : races.filter(r => moment(r.date).format('MM') === month.format('MM')) : [];

  return (
    <View style={Style.container}>
      <FlatList
        data={displayRaces}
        renderItem={({ item }) => <RaceItem race={item} />}
        keyExtractor={item => item.id}
        onRefresh={handleRefresh}
        refreshing={refreshing}
        ListFooterComponent={
          <View style={Style.listFooter}>
            {races === null && !error
              ? <LoaderInline style={Style.loader} />
              : (
                <>
                  {displayRaces.length===0 && <><Circle size={6} color={Colors.grayLight} /><Text style={Style.endReachedText}>Nessun evento salvato in questo {day ? 'giorno' : 'mese'}.</Text></>}
                  {error && <Text style={Style.errorText}>Non è stato possibile recuperare i tuoi eventi salvati.</Text>}
                </>
              )
            }
          </View>
        }
        ListHeaderComponent={<View style={Style.calendar}>
          <Calendar
            onDayPress={day => setDay(moment(day.dateString, 'YYYY-MM-DD'))}
            current={month.format('YYYY-MM-DD')}
            onMonthChange={date => setMonth(moment(date.dateString, 'YYYY-MM-DD').startOf('month'))}
            monthFormat={'MMMM yyyy'}
            hideExtraDays={false}
            firstDay={1}
            markedDates={marked}
            renderArrow = {direction => direction === 'left' ? <LeftArrow size={15} stroke={2} color={Colors.blue1} /> : <RightArrow size={15} stroke={2} color={Colors.blue1} />}
            theme={{
              selectedDayTextColor: Colors.gray,
              todayTextColor: Colors.white,
              todayBackgroundColor: Colors.blue1,
              calendarBackground: Colors.grayUltraFantaLight,
              textSectionTitleColor: Colors.blue1,
              textDayHeaderFontSize: 15,
              textDayFontSize: 15,
              textMonthFontSize: 18,
              textDayHeaderFontFamily: ApplicationStyles.screen.fonts.fontFamily,
              textDayFontFamily: ApplicationStyles.screen.fonts.fontFamily,
              textMonthFontFamily: ApplicationStyles.screen.fonts.fontFamily,
              dayTextColor: Colors.gray,
              textMonthFontWeight: 'bold',
              monthTextColor: Colors.blue1,
              textDayHeaderFontWeight: 'bold',
              'stylesheet.day.single': {
                base: {
                  height: 25,
                  width: 25,
                  alignItems: 'center',
                },
                text: {
                  marginTop: Platform.OS === 'android' ? 2 : 4,
                  fontSize: 15,
                  fontFamily: ApplicationStyles.screen.fontsRegular.fontFamily,
                  color: Colors.gray,
                }
              },
              'stylesheet.calendar.main': {
                week: {
                  marginTop: 2,
                  marginBottom: 2,
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                },
              }
            }}
          />
          <View style={Style.calendarFooter}>
            {day ? <Button onPress={() => setDay(null)} style={Style.month}><Text style={Style.monthText}>Tutto il mese</Text></Button> : <View />}
            <View style={Style.legenda}>
              <Circle size={4} color={Colors.blue1} />
              <Text style={Style.legendaText}>Eventi salvati</Text>
            </View>
          </View>
        </View>}
      />
    </View>
  );
};

export default SavedRaces;
