import { StyleSheet } from 'react-native';
import { Colors, ApplicationStyles, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  button: {
    marginBottom: 20,
  },
  cancelButton: {
    marginTop: 20,
    borderRadius: 30,
    borderWidth: 1,
    borderColor: Colors.blue1,
    width: '100%',
    justifyContent: 'center',
    marginBottom: 10,
  },
  cancelButtonText: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.regular,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  wrapper: {
  },
  closeButton: {
    alignSelf: 'flex-end',
    marginBottom: 10,
  },
  container: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    padding: 40,
    paddingBottom: 10,
    paddingTop: 10,
  },
  gradient: {
    width: '100%',
    height: '100%',
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 30,
    paddingBottom: 100,
    justifyContent: 'space-around',
    flex: 1,
  },
  h2: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular + 2,
    marginBottom: 10,
    marginTop: 20,
    textAlign: 'center',
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
  }
};

const StyleMedium = {
  wrapper: {
    ...BaseStyle.wrapper,
    minWidth: 300,
    maxWidth: 500,
    maxHeight: 700,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  gradient: {
    ...BaseStyle.gradient,
    paddingBottom: '10%',
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });
export default StyleSheet.create(BaseStyle);
