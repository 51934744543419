import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFacebookF, faGoogle, faApple } from '@fortawesome/free-brands-svg-icons';
import LinearGradient from 'react-native-linear-gradient';

import { Button } from '../';
import Style from './SocialButtonStyle';
import { Colors } from '../../../Theme';
import { GoogleIcon } from "../../../Theme/Icons";

const SocialButton = ({ social, onPress, disabled=false, type='login' }) => {
  const socialMap = {
    facebook: {
      icon: faFacebookF,
      color1: Colors.facebookGradient1,
      color2: Colors.facebookGradient2,
      textColor: Colors.white,
    },
    google: {
      icon: faGoogle,
      color1: Colors.googleGradient1,
      color2: Colors.googleGradient2,
      textColor: Colors.white,
    },
    apple: {
      icon: faApple,
      color1: Colors.black,
      color2: Colors.black,
      textColor: Colors.white,
    },
  };

  return (
    <Button onPress={onPress} style={[Style.button, disabled? Style.buttonDisabled : {}]} disabled={disabled}>
      <LinearGradient colors={[socialMap[social].color1, socialMap[social].color2]} style={Style.gradient}>
        <View style={[Style.icon, social === 'google' ? Style.googleIcon : {}]}>
          {social !== 'google'
            ? <FontAwesomeIcon icon={socialMap[social].icon} size={22} color={socialMap[social].textColor}/>
            : <GoogleIcon size={22}/>
          }
        </View>
        <Text style={[Style.text, { color: socialMap[social].textColor }] }>{type==='login'?'ACCEDI CON':'ISCRIVITI CON'} {social.toUpperCase()}</Text>
      </LinearGradient>
    </Button>
  )
};

SocialButton.propTypes = {
  social: PropTypes.oneOf(['facebook', 'google', 'apple']).isRequired,
  onPress: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['login', 'registration']),
};

export default SocialButton;
