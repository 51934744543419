import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-native';

import { Button } from '../';
import { BackIcon } from '../../../Theme/Icons';

const BackButton = ({ style }) => {
  let history = useHistory();

  return (
    (history.hasOwnProperty('canGo') && history.canGo(-1)) || (history.length > 1) ? (
      <Button onPress={() => history.goBack()} style={style}>
        <BackIcon />
      </Button>
    ) : null
  )
};

BackButton.propTypes = {
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
};

export default BackButton;
