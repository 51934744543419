import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { NavigationTypes } from './Actions'

export const setAfterLogin = (state, { route }) => ({
  ...state,
  afterLogin: route,
});

export const setAfterRuncardLink = (state, { route }) => ({
  ...state,
  afterRuncardLink: route,
});

export const setAfterStrava = (state, { route }) => ({
  ...state,
  afterStrava: route,
});

export const pushNavigation = (state, { route }) => ({
  ...state,
  redirect: route,
});

export const resetNavigation = (state) => ({
  ...state,
  redirect: null,
});

export const reducer = createReducer(INITIAL_STATE, {
  [NavigationTypes.SET_AFTER_LOGIN]: setAfterLogin,
  [NavigationTypes.SET_AFTER_RUNCARD_LINK]: setAfterRuncardLink,
  [NavigationTypes.SET_AFTER_STRAVA]: setAfterStrava,
  [NavigationTypes.PUSH_NAVIGATION]: pushNavigation,
  [NavigationTypes.RESET_NAVIGATION]: resetNavigation,
});
