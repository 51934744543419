import { ApplicationStyles, Colors, Fonts } from '../../../../Theme';
import ResponsiveHelper from "../../../../Services/ResponsiveHelper";

export const BaseStyle = {
  inner: {},
  logo: {
    width: 70,
    height: 65,
    marginHorizontal: 'auto',
    marginTop: -65,
  },
  fullWidth: {
    width: '100%',
  },
  loader: {
    alignSelf: 'center',
    marginVertical: 20,
  },
  retry: {
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 20,
  },
  retryText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-1,
    textDecorationLine: 'underline',
  },
  wrapper: {},
};

const StyleLG = {
  wrapper: {
    backgroundColor: Colors.white,
    marginTop: 60,
    marginHorizontal: 30,
    padding: 30,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
  },
  inner: {
    width: '100%',
    marginHorizontal: 'auto',
    marginVertical: 50,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
