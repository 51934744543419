import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path } from 'react-native-svg';

import { Colors } from '../../Theme';

const LoginIcon = ({ size=20, color=Colors.white }) => (
  <Svg width={size} height={size} viewBox={'0 0 27 27'}>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <Path d={'M3.16420679,0 C1.4192982,0 0,1.4192982 0,3.16335589 L0,3.16335589 L0,7.38239281 L2.10937665,7.38239281 L2.10937665,3.16335589 C2.10937665,2.58219242 2.58219242,2.10909301 3.16420679,2.10909301 L3.16420679,2.10909301 L23.8353605,2.10909301 C24.4168076,2.10909301 24.8901906,2.58219242 24.8901906,3.16335589 L24.8901906,3.16335589 L24.8901906,23.8347932 C24.8901906,24.4168076 24.4168076,24.8896234 23.8353605,24.8896234 L23.8353605,24.8896234 L3.16420679,24.8896234 C2.58219242,24.8896234 2.10937665,24.4168076 2.10937665,23.8347932 L2.10937665,23.8347932 L2.10937665,19.6166072 L0,19.6166072 L0,23.8347932 C0,25.5797018 1.4192982,26.999 3.16420679,26.999 L3.16420679,26.999 L23.8353605,26.999 C25.5799854,26.999 26.999,25.5797018 26.999,23.8347932 L26.999,23.8347932 L26.999,3.16335589 C26.999,1.4192982 25.5799854,0 23.8353605,0 L23.8353605,0 L3.16420679,0 Z M11.1643517,8.43722295 L15.1720822,12.4446699 L0,12.4446699 L0,14.5540465 L15.1720822,14.5540465 L11.1643517,18.561777 L12.6556926,20.0534016 L19.2098778,13.4992164 L12.6556926,6.9455984 L11.1643517,8.43722295 Z'} fill={color} />
    </G>
  </Svg>
);

LoginIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default LoginIcon;
