import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { View, ScrollView, Platform } from 'react-native';
import { useHistory } from 'react-router-native';

import { getResponsive } from './ActivitiesStyle';
import { isDesktop } from '../../Services/ResponsiveHelper';
import { ApplicationStyles } from '../../Theme';
import { Adv, Footer, PageHeader, PageSidebar, TabMenu } from '../../Components/Layout';
import { Workout, Results, SavedRaces } from '../../Components/Activities';
import { GoogleFit, Salute, Strava } from '../../Components/Services';
import { AdvPopup } from '../../Components/Popup';

import LayoutActions from '../../Stores/Layout/Actions';
import HelperActions from '../../Stores/Helper/Actions';
import { getPath } from '../../Router/AppRoutes';
import Analytics from '../../Services/AnalyticsService';
import { getAdv } from '../../Services/ADVService';

const Activities = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const Style = getResponsive();

  const googleFitEnabled = useSelector(state => state.services.google_fit);
  const saluteEnabled = useSelector(state => state.services.salute);
  const stravaEnabled = useSelector(state => state.services.strava);
  const user = useSelector(state => state.auth.user);

  const [ currentTab, setCurrentTab ] = React.useState('saved_races');
  const [ adv, setAdv ] = React.useState(false);

  // ADV
  const tab = useSelector(state => state.layout.tab);
  const options = useSelector(state => state.helper.options);

  React.useEffect(() => {
    if (tab !== 'ACTIVITIES') {
      if (Platform.OS !== 'web') setAdv(getAdv(options ? options.Advertising : null, 'popup', 'attivita'));
      dispatch(LayoutActions.setTab('ACTIVITIES'));
    }
    Analytics.getInstance().trackActivitiesView();
  }, []);

  React.useEffect(() => {
    if (currentTab === 'your_results' && (!user.runcard || user.runcard ==='')) {
      history.push(getPath('linkRuncard'));
    }
  }, [ currentTab ]);

  const tabs = {
    saved_races: {
      label: 'Gare salvate',
      callback: () => { setCurrentTab('saved_races'); dispatch(HelperActions.sendCRMEvent('event_activities_saved_races')); },
    },
    your_results: {
      label: 'I tuoi risultati',
      callback: () => { setCurrentTab('your_results'); dispatch(HelperActions.sendCRMEvent('event_activities_your_results')); },
    },
    workout: {
      label: 'Allenamento',
      callback: () => { setCurrentTab('workout'); dispatch(HelperActions.sendCRMEvent('event_activities_workout')); },
    },
  };

  if (Platform.OS === 'web') {
    delete(tabs.workout);
  }

  const Wrapper = ({ children }) => (
    !isDesktop() ? (
      <View style={Style.container}>
        {children}
      </View>
    ) : (
      <View style={Style.columns}>
        <View style={Style.activities}>
          {children}
        </View>
        <PageSidebar section={'attivita'}/>
      </View>
    )
  );

  Wrapper.propTypes = {
    children: PropTypes.node,
  };

  const EnableServices = () => (
    <ScrollView bounces={false} contentContainerStyle={Style.linkServices}>
      <GoogleFit />
      <Salute />
      <Strava />
    </ScrollView>
  );

  return (
    <View style={{ ...ApplicationStyles.screen.container }}>
      <PageHeader back={false} title={'Attività'} desc={'Tieni sott\'occhio i tuoi risultati'} />
      <View style={Style.wrapper}>
        <View style={Style.innerContainer}>
          <Wrapper>
            <TabMenu tabs={tabs} currentTab={currentTab} />
            {currentTab === 'saved_races' && <SavedRaces />}
            {currentTab === 'workout' && (
              !googleFitEnabled && !stravaEnabled && !saluteEnabled
                ? <EnableServices />
                : <Workout />
            )}
            {currentTab === 'your_results' && <Results />}
          </Wrapper>
        </View>
      </View>
      <Footer />
      {adv && (
        <AdvPopup closeHandler={() => setAdv(false)} width={250}><Adv adv={adv} /></AdvPopup>
      )}
    </View>
  )
};

export default Activities;
