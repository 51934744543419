import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { HelperTypes } from './Actions'

export const setProvinces = (state, { provinces }) => ({
  ...state,
  provinces,
});

export const setCountries = (state, { countries }) => ({
  ...state,
  countries,
});

export const setFaq = (state, { faq }) => ({
  ...state,
  faq,
});

export const setOptions = (state, { options }) => {
  return ({
    ...state,
    options,
    optionsFetched: true,
  });
};

export const setServices = (state, { services }) => ({
  ...state,
  services,
});

export const setConventions = (state, { conventions }) => ({
  ...state,
  conventions,
});

export const setServicesEncodedTokens = (state, { data }) => ({
  ...state,
  servicesTokens: {
    ...state.servicesTokens,
    ...data,
  },
});

export const setServiceTokenEncodedError = (state, { error }) => ({
  ...state,
  serviceTokenError: error,
});


export const setLeaderboardLevel = (state, { level }) => ({
  ...state,
  leaderboardLevel: level,
});

export const reducer = createReducer(INITIAL_STATE, {
  [HelperTypes.SET_PROVINCES]: setProvinces,
  [HelperTypes.SET_COUNTRIES]: setCountries,
  [HelperTypes.SET_FAQ]: setFaq,
  [HelperTypes.SET_OPTIONS]: setOptions,
  [HelperTypes.SET_SERVICES]: setServices,
  [HelperTypes.SET_CONVENTIONS]: setConventions,
  [HelperTypes.SET_SERVICES_ENCODED_TOKENS]: setServicesEncodedTokens,
  [HelperTypes.SET_SERVICE_TOKEN_ENCODED_ERROR]: setServiceTokenEncodedError,
  [HelperTypes.SET_LEADERBOARD_LEVEL]: setLeaderboardLevel,
});
