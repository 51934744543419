import React from 'react';
import PropTypes from 'prop-types';
import { View, Keyboard } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { Colors } from '../../../Theme';
import { HamburgerIcon } from '../../../Theme/Icons';
import { Button } from '../../Buttons';
import LayoutActions from '../../../Stores/Layout/Actions';

const Hamburger = ({ style }) => {
  const dispatch = useDispatch();

  const sidebarVisible = useSelector(state => state.layout.sidebar);

  return !sidebarVisible ? (
    <Button onPress={() => { Keyboard.dismiss(); dispatch(LayoutActions.toggleSidebar()); }} style={style}>
      <HamburgerIcon size={38} color={Colors.blue5} />
    </Button>
  ) : (
    <View style={style}>
      <HamburgerIcon size={38} color={Colors.blue5} />
    </View>
  )
};

Hamburger.propTypes = {
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
};

export default Hamburger;
