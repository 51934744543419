import { StyleSheet } from 'react-native';
import { Colors } from '../../../Theme';

export default StyleSheet.create({
  avatarGradient: {
    alignItems: 'center',
    backgroundColor: Colors.blue1,
    borderColor: Colors.white,
    borderWidth: 6,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  avatarShadow: {
    elevation: 8,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  uploadedAvatar: {
    alignItems: 'center',
    borderColor: Colors.white,
    borderWidth: 6,
    justifyContent: 'center',
    shadowColor: Colors.black,
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
});
