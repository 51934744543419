import React from "react";
import PropTypes from "prop-types";
import { pure } from "recompose";
import { generatePath, useHistory } from "react-router-native";
import { Text, View, Image, Platform, StyleSheet } from "react-native";
import LinearGradient from "react-native-linear-gradient";
import sanitizeHtml from "sanitize-html";
import moment from "moment";
import he from "he";

import { getResponsive } from "./ListStyle";
import { Button } from "../../Buttons";
import { Colors } from "../../../Theme";
import { RightArrow } from "../../../Theme/Icons";

import { getPath } from "../../../Router/AppRoutes";
import { isDesktop } from "../../../Services/ResponsiveHelper";

const News = ({ news }) => {
  const Style = getResponsive();
  let history = useHistory();

  const sanOptions = {
    allowedTags: [],
  };

  const Link = ({ children }) =>
    Platform.OS === "web" &&
    !news.remote &&
    news.link.indexOf("cloudfront.net/runcard") === -1 ? (
      <a
        href={news.link}
        target={"_blank"}
        rel="noopener noreferrer"
        style={StyleSheet.flatten([Style.newsContainer, Style.noUnderline])}
      >
        {children}
      </a>
    ) : (
      <Button
        onPress={() =>
          history.push(generatePath(getPath("newsItem"), { id: news.id }))
        }
        style={Style.newsContainer}
      >
        {children}
      </Button>
    );

  Link.propTypes = {
    children: PropTypes.node.isRequired,
  };

  return (
    <Link>
      {news.image !== "" && !isDesktop() && (
        <View>
          <Image source={{ uri: news.image }} style={Style.image} />
          <LinearGradient
            colors={[Colors.transparent, Colors.transparentBlue]}
            locations={[0.2, 1]}
            style={Style.gradient}
          />
        </View>
      )}
      <View style={Style.news}>
        <View style={Style.titleContainer}>
          <Text style={Style.newsDate}>
            {moment.unix(news.date).format("D MMM YYYY [alle] HH:mm")}
          </Text>
          <View style={Style.newsContentWrapper}>
            {news.image !== "" && isDesktop() && (
              <Image source={{ uri: news.image }} style={Style.image} />
            )}
            <View style={Style.newsContentContainer}>
              <Text style={Style.newsTitle}>{he.decode(news.title)}</Text>
              {news.category === "TopNews" && (
                <Text style={Style.newsSource}>da {news.sourceName}</Text>
              )}
              {isDesktop() && (
                <div
                  style={Style.newsSummary}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(news.summary, sanOptions),
                  }}
                />
              )}
            </View>
          </View>
        </View>
        <View style={Style.arrowIcon}>
          <RightArrow size={15} color={Colors.grayMedium} />
        </View>
      </View>
    </Link>
  );
};

News.propTypes = {
  news: PropTypes.object.isRequired,
};

export default pure(News);
