import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, TextInput, Linking, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { getResponsive } from './CertificateStyle';
import { LoaderInline } from '../../Layout';
import {  DownloadIcon, LensIcon } from '../../../Theme/Icons';

import HelperActions from '../../../Stores/Helper/Actions';
import { Button, Link } from '../../Buttons';

const CertificateContent = ({ openPdf }) => {
  const dispatch = useDispatch();
  const Style = getResponsive();

  const user = useSelector(state => state.auth.user);
  const conventions = useSelector(state => state.helper.conventions);
  const error = useSelector(state => state.layout.error);

  const [ cap, setCap ] = React.useState(user.cap||'');
  const [ loading, setLoading ] = React.useState(false);

  React.useEffect(() => {
    if (cap.length > 2) {
      setLoading(true);
      dispatch(HelperActions.getConventions(cap));
    }
  }, [ cap ]);

  React.useEffect(() => {
    if (loading) setLoading(false);
  }, [ conventions ]);

  React.useEffect(() => {
    if (loading && error) setLoading(false);
  }, [ error ]);

  const handleDownload = (url) => {
    if (Platform.OS === 'ios') {
      openPdf(url);
    } else {
      Linking.openURL(url);
    }
  };

  return (
    <>
      <View style={Style.inputContainer}>
        <TextInput style={Style.input} autoCorrect={false} keyboardType={'numeric'} placeholder={'cerca per CAP'} value={cap} onChangeText={setCap} />
        <View style={Style.icon}><LensIcon /></View>
      </View>
      <View style={Style.results}>
        {loading || !conventions
          ? loading && <LoaderInline style={{}}/>
          : conventions.length
            ? (
              <>
                <View style={Style.row}>
                  <View style={Style.columnA}>
                    <Text style={Style.tableHeader}>CAP</Text>
                  </View>
                  <View style={Style.columnB}>
                    <Text style={Style.tableHeader}>Azienda</Text>
                  </View>
                </View>
                {conventions.map(el => (
                  <View style={Style.row} key={el.id}>
                    <View style={Style.columnA}>
                      <Text style={Style.tableContent}>{el.cap}</Text>
                    </View>
                    <View style={Style.columnB}>
                      <Text style={[Style.tableContent, Style.bold ]}>{el.name}</Text>
                      <Text style={Style.tableContent}>{el.address}</Text>
                      {el.phone !== '' && <Text onPress={()=>{Linking.openURL(`tel:${Platform.OS === 'ios' && '//'}${el.phone.replace(/\D/, '')}`);} } style={Style.tableContent}>Tel. {el.phone}</Text>}
                      {Platform.OS === 'web'
                        ? (
                          <View style={Style.downloadButtonContainer}>
                            <Link href={el.attachment}>
                              <View style={Style.downloadButton}>
                                <View style={Style.downloadIcon}><DownloadIcon /></View>
                                <Text style={Style.downloadButtonText}>Informativa</Text>
                              </View>
                            </Link>
                          </View>
                        )
                        : (
                          <Button style={Style.downloadButton} onPress={() => handleDownload(el.attachment)}>
                            <View style={Style.downloadIcon}><DownloadIcon /></View>
                            <Text style={Style.downloadButtonText}>Informativa</Text>
                          </Button>
                        )
                      }
                    </View>
                  </View>
                ))}
              </>
          ) : <Text style={Style.notFound}>Nessuna azienda trovata per questo cap</Text>
        }
      </View>
    </>
  );
};

CertificateContent.propTypes = {
  openPdf: PropTypes.func.isRequired,
};

export default CertificateContent;
