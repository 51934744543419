import React from "react";
import PropTypes from "prop-types";
import { Keyboard, Switch, Text, TextInput, View } from "react-native";
import { useLocation } from "react-router-native";
import { useSelector, useDispatch } from "react-redux";

import { getResponsive } from "./PageSidebarStyle";
import { isDesktop } from "../../../Services/ResponsiveHelper";
import { PrimaryButton, SecondaryButton } from "../../Buttons";
import { ErrorPopup, SuccessPopup } from "../../Popup";
import { Adv } from "../index";

import LayoutActions from "../../../Stores/Layout/Actions";
import RuncardActions from "../../../Stores/Runcard/Actions";
import NavigationAction from "../../../Stores/Navigation/Actions";
import { getPath } from "../../../Router/AppRoutes";
import { getAdv } from "../../../Services/ADVService";

import { AlertOldUser } from "../../ModalOldUser/index";

const PageSidebar = ({ adv = true, scrollToBuy = () => {}, section = "" }) => {
  const Style = getResponsive();
  const dispatch = useDispatch();
  const location = useLocation();

  const { link_error: error, link_success: success } = useSelector(
    (state) => state.runcard
  );
  const { logged, user } = useSelector((state) => state.auth);

  // ADV
  const options = adv ? useSelector((state) => state.helper.options) : null;
  const MPUs = adv
    ? getAdv(options ? options.Advertising : null, "mpu", section, false)
    : [];

  const openLogin = () => dispatch(LayoutActions.toggleLoginModal(true));
  const openRegistration = () =>
    dispatch(LayoutActions.toggleRegistrationModal(true));

  const [code, setCode] = React.useState("");
  const [taxCode, setTaxCode] = React.useState("");
  const [foreign, setForeign] = React.useState(false);
  const [oldUser, setOldUser] = React.useState(false);

  const successHandler = () => {
    dispatch(RuncardActions.clearLinkStatus());
    dispatch(NavigationAction.pushNavigation("runcard"));
    dispatch(NavigationAction.setAfterRuncardLink(null));
  };

  return isDesktop() ? (
    <View style={Style.advColumn}>
      <AlertOldUser StateVisible={oldUser} Close={() => {}} />
      {!logged || !user ? (
        <View style={Style.boxContainer}>
          <View style={Style.box}>
            <Text style={Style.boxText}>Non sei ancora registrato?</Text>
            <Text style={[Style.boxH1, Style.marginBottom20]}>
              Registrati ora!
            </Text>
            <PrimaryButton
              size={"small"}
              style={Style.marginBottom20}
              onPress={openRegistration}
            >
              REGISTRATI
            </PrimaryButton>
            <Text style={[Style.boxText, Style.marginBottom10]}>
              Hai già un account?
            </Text>
            <SecondaryButton size={"xsmall"} onPress={openLogin}>
              Login
            </SecondaryButton>
          </View>
        </View>
      ) : (
        (!user.runcard || user.runcard === "") && (
          <View style={Style.boxContainer}>
            <View style={Style.box}>
              <Text style={[Style.boxH2, Style.marginBottom10]}>
                Non hai una Runcard?
              </Text>
              {location.pathname === getPath("linkRuncard") ? (
                <PrimaryButton
                  size={"small"}
                  style={Style.marginBottom20}
                  onPress={scrollToBuy}
                >
                  ACQUISTALA ORA!
                </PrimaryButton>
              ) : (
                <PrimaryButton
                  size={"small"}
                  style={Style.marginBottom20}
                  route={"linkRuncard"}
                >
                  ACQUISTALA ORA!
                </PrimaryButton>
              )}
              <Text
                style={[Style.boxH2, Style.marginBottom10, Style.marginTop20]}
              >
                Hai già una Runcard?
              </Text>
              <TextInput
                style={Style.textInput}
                autoCorrect={false}
                placeholder={"Numero Runcard"}
                onBlur={Keyboard.dismiss}
                value={code}
                onChangeText={setCode}
              />
              {!foreign && (
                <TextInput
                  style={Style.textInput}
                  autoCorrect={false}
                  placeholder={"Codice Fiscale"}
                  onBlur={Keyboard.dismiss}
                  value={taxCode}
                  onChangeText={setTaxCode}
                />
              )}
              <View style={Style.switchWrapper}>
                <Switch value={foreign} onValueChange={setForeign} />
                <Text style={Style.switchText}>Non risiedo in Italia</Text>
              </View>
              <PrimaryButton
                height={40}
                size={"small"}
                onPress={() =>
                  dispatch(RuncardActions.linkRuncard(code, taxCode))
                }
                style={Style.button}
                disabled={!code.length || (!taxCode.length && !foreign)}
              >
                ASSOCIA
              </PrimaryButton>
            </View>
          </View>
        )
      )}
      {adv &&
        MPUs &&
        MPUs.map((banner, key) => (
          <View key={key} style={Style.adv}>
            <Adv adv={banner} />
          </View>
        ))}
      {error !== null && (
        <ErrorPopup
          closeHandler={() => dispatch(RuncardActions.clearLinkStatus())}
          title={"Runcard non associata"}
          text={error}
          help={true}
        />
      )}
      {success && (
        <SuccessPopup
          closeHandler={() => successHandler()}
          title={"Runcard associata con successo!"}
        />
      )}
    </View>
  ) : null;
};

PageSidebar.propTypes = {
  adv: PropTypes.bool,
  scrollToBuy: PropTypes.func,
  section: PropTypes.string,
};

export default PageSidebar;
