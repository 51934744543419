import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, ScrollView } from 'react-native';
import { useHistory } from 'react-router-native';
import sanitizeHtml from 'sanitize-html';
import moment from 'moment';
import 'moment/locale/it.js';
moment.locale('it');

import { getResponsive } from './NewsStyle';
import { PageSidebar, WebView } from '../../Layout';
import { Button } from '../../Buttons';
import { BackIcon } from '../../../Theme/Icons';
import { Colors } from '../../../Theme';

import { isDesktop } from '../../../Services/ResponsiveHelper';

const News = ({ news }) => {
  let history = useHistory();
  const Style = getResponsive();

  const sanOptions =  {
    allowedTags: [ 'b', 'i', 'em', 'strong', 'a', 'p', 'br', 'div', 'img' ],
    transformTags: {
      'div': 'p',
    }
  };

  const [ frameHeight, setFrameHeight ] = React.useState(500);

  const handleMessage = (message) => {
    if (message && typeof message === 'string' && message.indexOf('loaded') !== -1) {
      setFrameHeight(parseInt(message.split('|')[1]));
    }
    if (message && message.action && message.action === 'resize') {
      setFrameHeight(message.height);
    }
  };

  const canGoBack = () => ((history.hasOwnProperty('canGo') && history.canGo(-1)) || (history.length > 1));

  return !news.remote && news.link.indexOf('cloudfront.net/runcard') === -1
    ? <Text style={Style.noNewsText}>News non trovata</Text>
    : (
      <ScrollView style={Style.container}>
        {isDesktop() && (
          <View style={Style.backButtonWrapper}>
            <Button onPress={() => { if (canGoBack()) { history.goBack() } }} route={canGoBack() ? null : 'news'} style={Style.backButton}>
              <BackIcon color={Colors.blue1} size={10} stroke={2} />
              <Text style={Style.backButtonText}>Torna ai risultati</Text>
            </Button>
          </View>
        )}
        <View style={Style.columns}>
          {news.link.indexOf('cloudfront.net/runcard') === -1
            ? (
              <View style={Style.news}>
                <Text style={Style.data}>{moment.unix(news.date).format('D MMM YYYY [alle] HH:mm')}</Text>
                <Text style={Style.title}>{news.title}</Text>
                <Text style={Style.newsSource}>da {news.sourceName}</Text>
                <div style={Style.newsContent} className={'news-content'} dangerouslySetInnerHTML={{ __html: sanitizeHtml(news.content, sanOptions) }} />
              </View>
            ) : (
              <View style={Style.news}>
                <WebView url={news.link} style={{ height: frameHeight }} automaticallyAdjustContentInsets={false} scalesPageToFit={false} onMessage={handleMessage}/>
              </View>
            )
          }
          <PageSidebar section={'news'} />
        </View>
      </ScrollView>
  );
};

News.propTypes = {
  news: PropTypes.object.isRequired,
};

export default News;
