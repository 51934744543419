import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  backButton: {
    padding: 20,
    width: 60
  },
  button: {
    marginBottom: 20,
    marginTop: 20,
  },
  container: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  containerWeb: {
    alignSelf: 'center',
    backgroundColor: Colors.white,
    borderRadius: 10,
    marginBottom: 30,
    marginTop: 30,
    maxWidth: 450,
    minWidth: 400,
  },
  error: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.red,
    fontSize: Fonts.size.regular-2,
    marginBottom: 10,
  },
  h1: {
    ...ApplicationStyles.screen.h1,
    marginBottom: 20,
    textAlign: 'center',
  },
  lead: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.gray,
    marginBottom: 20,
    textAlign: 'center',
  },
  newCode: {
    ...ApplicationStyles.screen.fontsLight,
    textAlign: 'center',
  },
  newCodeButton: {
    paddingBottom: 20,
    paddingTop: 10,
    width: '100%',
  },
  sectionWrapper: {
    marginBottom: 20,
    paddingBottom: 20,
    paddingTop: 20,
  },
  textInput: {
    ...ApplicationStyles.screen.fontsLight,
    alignSelf: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
    color: Colors.gray,
    fontSize: Fonts.size.big,
    marginBottom: 20,
    paddingBottom: 10,
    paddingTop: 10,
    textAlign: 'center',
  },
  wrapper: {
    flex: 1,
  },
  wrapperBackground: {
    backgroundColor: Colors.darkBlue,
  },
});
