import React from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '../../Components/Layout';
import { RegistrationForm, RegistrationConfirm, RegistrationComplete } from '../../Components/Auth';

const Registration = () => {
  const mapStatus = {
    TO_BE_COMPLETED: <RegistrationComplete />,
    CONFIRM: <RegistrationConfirm />,
    COMPLETED: <Loader force={true} />,
  };

  const registrationStatus = useSelector(state => state.registration.status);

  return mapStatus[registrationStatus]||<RegistrationForm />;
};

export default Registration;
