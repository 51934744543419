import React from 'react';
import PropTypes from 'prop-types';
import { View, ImageBackground, Text, Platform, SafeAreaView } from 'react-native';
import { useLocation } from 'react-router-native';
import { useSelector } from 'react-redux';

import { getResponsive } from './HeaderStyle';
import { Adv, Hamburger, TabNav } from '../';
import { Link } from '../../Buttons';
import { Images } from '../../../Theme';
import { Logo } from '../../../Theme/Icons';

import { isDesktop } from '../../../Services/ResponsiveHelper';

import { getAdv } from '../../../Services/ADVService';

const Header = ({ isHome = false }) => {

  // ADV
  const options = useSelector(state => state.helper.options);
  const adv = getAdv(options.Advertising, 'main_sponsor');

  const location = useLocation();
  const Style = getResponsive();

  const isHomepage = () => location.pathname === '/';

  const image = !isDesktop() && !isHome ? Images.bg_header : { uri: '/static/images/bg_header.png' };

  const HeaderHomepageWeb = () => (
    <View style={Style.wrapper}>
      <View style={Style.headerHome}>
        <Hamburger style={Style.hamburgerHome} />
        <View style={Style.logoHomepage}>
          <View style={Style.logoAdvHomepage}>
            <Logo width={isDesktop() ? 340 : 200} />
            {adv && <View style={Style.advHomepage}><Adv adv={adv} /></View>}
          </View>
          {isDesktop() && <Text style={Style.claim}>Community powered By FIDAL</Text>}
        </View>
        {isDesktop() && <TabNav size={'big'} />}
      </View>
    </View>
  );

  return isHomepage() ? isHome ? <HeaderHomepageWeb /> : null : (
    <ImageBackground source={image} style={Style.background}>
        <SafeAreaView style={Style.header}>
          <View style={Style.headerLogo}>
            <View style={Style.logo}>
              {Platform.OS === 'web' ? (
                <Link route={'homepage'}>
                  <Logo width={isDesktop() ? 130 : 100} />
                </Link>
              ) : (
                <Logo width={100} />
              )}
            </View>
            {adv && <View style={Style.adv}><Adv adv={adv} /></View>}
          </View>
          {isDesktop() && <TabNav />}
          <View style={Style.hamburgerContainer}>
            <Hamburger style={Style.hamburger} />
          </View>
        </SafeAreaView>
    </ImageBackground>
  );
};

Header.propTypes = {
  isHome: PropTypes.bool,
};

export default Header;
