import { Platform, StyleSheet } from 'react-native';
import { Colors } from '../../Theme';

export const AvatarUploadStyle = {
  avatarGradient: {
    alignItems: 'center',
    backgroundColor: Colors.blue1,
    borderRadius: 50,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  avatarIcon: {
    position: 'absolute',
    bottom: 5,
    right: 5,
  },
  hiddenInput: {
    width: 1,
    height: 1,
    opacity: 0,
    position: 'absolute',
    overflow: 'hidden',
    zIndex: -1,
  },
  uploadedAvatar: {
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    flex: 1,
    elevation: 8,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
    backgroundColor: Colors.white,
    padding: 5,
  },
};

if (Platform.OS === 'web') {
  AvatarUploadStyle.pointer = { cursor: 'pointer' };
}

export default StyleSheet.create(AvatarUploadStyle);
