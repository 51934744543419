import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  button: {
    borderRadius: 30,
    elevation: 8,
    height: 55,
    marginBottom: 0,
    marginTop: 10,
    shadowColor: Colors.black,
    shadowOffset: { width: 2, height: 5 },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    width: '100%',
  },
  buttonDisabled: {
    opacity: 0.8
  },
  googleIcon: {
    backgroundColor: Colors.white,
    borderRadius: 30,
    left: 15,
    padding: 5,
    top: 11,
  },
  gradient: {
    alignItems: 'center',
    borderRadius: 30,
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30,
    width: '100%',
  },
  icon: {
    left: 20,
    position: 'absolute',
    top: 16,
  },
  text: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    flex: 1,
    fontSize: Fonts.size.regular,
    textAlign: 'center',
  }
});

export const ButtonSizes = {
  small: 45,
  medium: 60,
};
