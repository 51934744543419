import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Stop, Defs, LinearGradient, Mask, Use } from 'react-native-svg';

const TabPlayIcon = ({ active=false, size=26 }) => {
  return (
    <Svg width={size} height={size} viewBox={'0 0 26 26'}>
      <Defs>
        <Path d="M13,0 C9.52714286,0 6.26352381,1.352 3.80714286,3.80838095 L3.80714286,3.80838095 C1.352,6.26228571 0,9.52714286 0,13.0012381 L0,13.0012381 C0,16.4728571 1.352,19.7377143 3.80714286,22.1928571 L3.80714286,22.1928571 C6.26352381,24.648 9.52714286,26 13,26 L13,26 C16.4728571,26 19.7377143,24.648 22.191619,22.1928571 L22.191619,22.1928571 C24.648,19.7377143 26,16.4728571 26,13.0012381 L26,13.0012381 C26,9.52714286 24.648,6.26228571 22.191619,3.80838095 L22.191619,3.80838095 C19.7377143,1.352 16.4728571,0 13,0 Z M2.03171429,13.0012381 C2.03171429,6.95190476 6.95190476,2.03171429 13,2.03171429 L13,2.03171429 C19.0480952,2.03171429 23.9682857,6.95190476 23.9682857,13.0012381 L23.9682857,13.0012381 C23.9682857,19.0480952 19.0480952,23.9682857 13,23.9682857 L13,23.9682857 C6.95190476,23.9682857 2.03171429,19.0480952 2.03171429,13.0012381 L2.03171429,13.0012381 Z M7.41495238,9.95304762 L18.5862857,9.95304762 L18.5862857,7.92133333 L7.41495238,7.92133333 L7.41495238,9.95304762 Z M7.41495238,14.0152381 L18.5862857,14.0152381 L18.5862857,11.9835238 L7.41495238,11.9835238 L7.41495238,14.0152381 Z M7.41495238,18.0786667 L18.5862857,18.0786667 L18.5862857,16.0469524 L7.41495238,16.0469524 L7.41495238,18.0786667 Z" id="pathPlay" />
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={'gradientPlayDisabled'}>
          <Stop stopColor="#E3E4E3" offset="0%" />
          <Stop stopColor="#C3C4C3" offset="100%" />
        </LinearGradient>
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={'gradientPlayEnabled'}>
          <Stop stopColor="#02AFF1" offset="0%" />
          <Stop stopColor="#0D85F1" offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
        <Mask id={'maskPlay'} fill={'white'}>
          <Use href={'#pathPlay'} />
        </Mask>
        <Path d={'M13,0 C9.52714286,0 6.26352381,1.352 3.80714286,3.80838095 L3.80714286,3.80838095 C1.352,6.26228571 0,9.52714286 0,13.0012381 L0,13.0012381 C0,16.4728571 1.352,19.7377143 3.80714286,22.1928571 L3.80714286,22.1928571 C6.26352381,24.648 9.52714286,26 13,26 L13,26 C16.4728571,26 19.7377143,24.648 22.191619,22.1928571 L22.191619,22.1928571 C24.648,19.7377143 26,16.4728571 26,13.0012381 L26,13.0012381 C26,9.52714286 24.648,6.26228571 22.191619,3.80838095 L22.191619,3.80838095 C19.7377143,1.352 16.4728571,0 13,0 Z M2.03171429,13.0012381 C2.03171429,6.95190476 6.95190476,2.03171429 13,2.03171429 L13,2.03171429 C19.0480952,2.03171429 23.9682857,6.95190476 23.9682857,13.0012381 L23.9682857,13.0012381 C23.9682857,19.0480952 19.0480952,23.9682857 13,23.9682857 L13,23.9682857 C6.95190476,23.9682857 2.03171429,19.0480952 2.03171429,13.0012381 L2.03171429,13.0012381 Z M7.41495238,9.95304762 L18.5862857,9.95304762 L18.5862857,7.92133333 L7.41495238,7.92133333 L7.41495238,9.95304762 Z M7.41495238,14.0152381 L18.5862857,14.0152381 L18.5862857,11.9835238 L7.41495238,11.9835238 L7.41495238,14.0152381 Z M7.41495238,18.0786667 L18.5862857,18.0786667 L18.5862857,16.0469524 L7.41495238,16.0469524 L7.41495238,18.0786667 Z'} fill={active ? 'url(#gradientPlayEnabled)' : 'url(#gradientPlayDisabled)'} mask={'url(#maskPlay)'} />
      </G>
    </Svg>
  )
};

TabPlayIcon.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
};

export default TabPlayIcon;
