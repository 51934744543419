import { put, call, select } from "redux-saga/effects";

import HelperActions from "../Stores/Helper/Actions";
import LayoutActions from "../Stores/Layout/Actions";
import {
  fetchCountries,
  fetchProvinces,
  fetchFAQ,
  fetchConventions,
  encodeServiceToken,
  postCrmEvent,
  postLeaderboardEvent,
  postUserPdf,
  getLeaderboardLevel,
} from "../Services/ApiGatewayService";

export function* getProvinces({ loader = true }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  try {
    let provinces = yield call(fetchProvinces);
    provinces.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
    yield put(HelperActions.setProvinces(provinces));
  } catch (err) {
    console.log("getProvinces error", err);
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}

export function* getCountries() {
  yield put(LayoutActions.setLoading(true));
  try {
    let countries = yield call(fetchCountries);
    countries.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
    yield put(HelperActions.setCountries(countries));
  } catch (err) {
    console.log("getCountries error", err);
  }
  yield put(LayoutActions.setLoading(false));
}

export function* getFaq() {
  yield put(LayoutActions.setLoading(true));
  try {
    const faq = yield call(fetchFAQ);
    faq.sort((a, b) => parseInt(a.order) - parseInt(b.order));
    yield put(HelperActions.setFaq(faq));
  } catch (err) {
    console.log("getFaq error", err);
  }
  yield put(LayoutActions.setLoading(false));
}

export function* getConventions({ cap }) {
  try {
    let conventions = yield call(fetchConventions, { cap });
    yield put(HelperActions.setConventions(conventions));
  } catch (err) {
    console.log("getConventions error", err);
    yield put(LayoutActions.setError({}));
  }
}

export function* getServiceTokenEncoded({ service_id }) {
  try {
    yield put(HelperActions.setServiceTokenEncodedError(null));
    const { token } = yield call(encodeServiceToken, service_id);
    yield put(HelperActions.setServicesEncodedTokens({ [service_id]: token }));
  } catch (err) {
    console.log("getServiceTokenEncoded error", err);
    yield put(HelperActions.setServiceTokenEncodedError(err));
  }
}

export function* sendCRMEvent({ event }) {
  try {
    const logged = yield select((state) => state.auth.logged);
    if (!logged) {
      console.log("Skip sendCRMEvent for unauth users");
      return;
    }
    yield call(postCrmEvent, { event });
  } catch (err) {
    console.log("sendCRMEvent error", err);
  }
}

export function* sendLeaderboardEvent({ event, count }) {
  try {
    const logged = yield select((state) => state.auth.logged);
    if (!logged) {
      console.log("Skip sendLeaderboardEvent for unauth users");
      return;
    }
    yield call(postLeaderboardEvent, { event, count });
  } catch (err) {
    console.log("sendLeaderboardEvent error", err);
  }
}

export function* leaderboardLevel() {
  try {
    const user = yield select((state) => state.auth.user);
    const res = yield call(getLeaderboardLevel, user.email);
    const level = { badge: res.image, level: res["name_it-IT"] };
    yield put(HelperActions.setLeaderboardLevel(level));
  } catch (err) {
    console.log("leaderboardLevel error", err);
  }
}

export function* generatePdf({ pdf }) {
  try {
    yield call(postUserPdf, { type: pdf });
  } catch (err) {
    console.log("sendLeaderboardEvent error", err);
  }
}
