import { put } from 'redux-saga/effects';
import { Storage } from 'aws-amplify';

import S3Actions from '../Stores/S3/Actions';
import LayoutActions from '../Stores/Layout/Actions';
import { Config } from '../Config';

export function* S3Upload({ file }) {
  yield put(LayoutActions.setLoading(true));
  yield put(S3Actions.S3_uploadReset());
  try {
    const uploaded = yield Storage.put(`app/${Config.STAGE}/${file.filename}`, file.data).then(data => ({ success: true, data })).catch(error => ({ success: false, error: error.toString() }));
    if (uploaded.success) {
      yield put(S3Actions.S3_uploadSuccess(uploaded.data.key));
    } else {
      yield put(S3Actions.S3_uploadError(uploaded.error));
    }
  } catch (err) {
    console.log('err', err);
    yield put(S3Actions.S3_uploadError(err.toString()));
  }
  yield put(LayoutActions.setLoading(false));
}

export function* S3Delete({ cdnFile }) {
  yield put(S3Actions.S3_uploadReset());
  try {
    yield Storage.remove(cdnFile.replace(`${Config.CDN}`, ''));
  } catch (err) {
    console.log('err', err);
  }
}
