import { Platform } from 'react-native';
import { Colors, ApplicationStyles, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  clickWrapper: {
    bottom: 0,
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
    left: 0,
    position:'absolute',
    right: 0,
    top: 0,
  },
  closeButton: {
    alignSelf:'flex-end',
    padding: 20,
    marginRight: -20,
  },
  container: {
    flex: 1,
  },
  error: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular,
    paddingVertical: 30,
    textAlign: 'center',
  },
  fullScreenContainer: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...Platform.select({
      web: {
        marginBottom: 90,
      },
    })
  },
  imgContainer: {
    width: '100%',
    height: undefined,
    aspectRatio: 1,
  },
  innerContainer: {
    flex: 1,
  },
  loaderContainer: {
    justifyContent: 'center',
  },
  modal: {
    ...ApplicationStyles.screen.paddingTabBar,
    backgroundColor: Colors.transparentBlue,
    flex: 1,
    justifyContent: 'space-around',
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    elevation: 10,
  },
  rowContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  wrapper: {
    ...ApplicationStyles.screen.container,
  },
  listFooter: {
    ...ApplicationStyles.screen.paddingTabBar,
  },
};

const StyleLG = {
  closeButton: {
    marginRight: -50,
  },
  fullScreenContainer: {
    ...ApplicationStyles.screen.webContainer,
    marginBottom: 20,
  },
  innerContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
