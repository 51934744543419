import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { StartupTypes } from './Actions'

export const setLoaded = (state, { status }) => ({
  ...state,
  loaded: status,
});

export const setLoadingError = (state, { error }) => ({
  ...state,
  loadingError: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [StartupTypes.SET_LOADED]: setLoaded,
  [StartupTypes.SET_LOADING_ERROR]: setLoadingError,
});
