import React from 'react';
import PropTypes from 'prop-types';
import { Easing, Animated, View, StyleSheet } from 'react-native';

const Collapsible = ({ collapsed, children }) => {
  const [ measuring, setMeasuring ] = React.useState(false);
  const [ measured, setMeasured ] = React.useState(false);
  const [ heightAnim ] = React.useState(new Animated.Value(0));
  const [ contentHeight, setContentHeight ] = React.useState(0);
  const [ animating, setAnimating ] = React.useState(false);

  let contentHandle = null;
  let animation = null;

  React.useEffect(() => {
    setMeasured(false);
    toggleCollapsed(collapsed);
  }, [collapsed]);

  const measureContent = (callback) => {
    setMeasuring(true);
    requestAnimationFrame(() => {
      if (!contentHandle) {
        setMeasuring(false);
        callback(0);
      } else {
        contentHandle.measure((x, y, width, height) => {
          setMeasuring(false);
          setMeasured(true);
          setContentHeight(height);
          callback(height);
        });
      }
    });
  };

  const toggleCollapsed = collapsed => {
    if (collapsed) {
      transitionToHeight(0);
    } else if (!contentHandle) {
      if (measured) {
        transitionToHeight(contentHeight);
      }
    } else {
      measureContent(contentHeight => {
        transitionToHeight(contentHeight);
      });
    }
  };

  const transitionToHeight = height => {
    if (animation) {
      animation.stop();
    }
    setAnimating(true);
    animation = Animated.timing(heightAnim, {
      toValue: height,
      duration: 300,
      easing: Easing.out(Easing['cubic']),
    }).start(() => {
      setAnimating(false);
    });
  };

  const handleLayoutChange = event => {
    const calcHeight = event.nativeEvent.layout.height;
    if (!animating && !collapsed && !measuring && calcHeight !== contentHeight) {
      setContentHeight(calcHeight);
    }
  };

  const Style = StyleSheet.create({
    container: {
      overflow: 'hidden'
    },
    measuring: {
      opacity: 0,
      position: 'absolute',
    }
  });

  return (
    <Animated.View style={[Style.container, (!measuring && (measured || collapsed)) ? { height: heightAnim } : {}]} pointerEvents={collapsed ? 'none' : 'auto'}>
      <View ref={ref => { if (ref) contentHandle = ref; }} style={measuring ? Style.measuring : {}} onLayout={animating ? undefined : handleLayoutChange}>
        {children}
      </View>
    </Animated.View>
  );
};

Collapsible.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.node,
};

export default Collapsible;
