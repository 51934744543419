import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SafeAreaView, ScrollView, Text, View } from 'react-native';
import { useHistory } from 'react-router-native';
import { Config } from '../../Config';


import Style, { getResponsive } from './WelcomeStyle';
import { BackgroundImage, Modal } from '../../Components/Layout';
import { Button, PrimaryButton, TransparentButton, Link } from '../../Components/Buttons';
import Tutorial from '../../Components/Tutorial/Tutorial';
import { BackIcon, Logo } from '../../Theme/Icons';
import { Conditions, PrivacyPolicy } from '../../Components/Legals';

import { getPath } from '../../Router/Router';
import ResponsiveHelper from '../../Services/ResponsiveHelper';
import Analytics from '../../Services/AnalyticsService';

const Welcome = ({ logged }) => {
  let history = useHistory();
  const [ tutorial, setTutorial ] = React.useState(false);
  const [ conditions, setConditions ] = React.useState(false);
  const [ privacy, setPrivacy ] = React.useState(false);
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());

  return !logged && (
    <View onLayout={() => setBreakpoint(ResponsiveHelper.getSize())} style={Style.container}>
      <BackgroundImage>
        <SafeAreaView style={getResponsive().innerContainer}>
          <ScrollView contentContainerStyle={Style.container} bounces={false}>
            <View style={Style.topContainer}>
              <Text style={Style.title}>Benvenuto in</Text>
              <View style={Style.logo}>
                <Logo />
              </View>
              <Text style={Style.lead}>Registrati subito per scoprire una nuova esperienza by FIDAL</Text>
              <View style={Style.buttonContainer}>
                <PrimaryButton route='registration'>INIZIA SUBITO</PrimaryButton>
              </View>
              <Link replace={true} route={getPath(Config.APP_HOMEPAGE)} style={Style.laterLink} onPress={() => Analytics.getInstance().trackSkipRegistration()}><Text style={Style.later}>No, voglio registrarmi in seguito</Text></Link>
            </View>
            <View style={Style.bottomContainer}>
              <View style={Style.buttonContainer}>
                <TransparentButton onPress={() => setTutorial(true)}>SCOPRI RUNCARD</TransparentButton>
              </View>
              <Link route='login'>
                <Text style={Style.loginText}>Hai già un account? <Text style={Style.login}>LOGIN</Text></Text>
              </Link>
              <View style={Style.conditionsContainer}>
                <Text style={Style.conditions}>
                  Utilizzando Runcard accetti le nostre <Text style={Style.underlined} onPress={() => setConditions(true)}>Condizioni Generali</Text> e la <Text style={Style.underlined} onPress={() => setPrivacy(true)}>Privacy Policy</Text>
                </Text>
              </View>
            </View>
          </ScrollView>
        </SafeAreaView>
        {tutorial && (
          <Tutorial onClose={() => setTutorial(false)} onEnd={() => history.replace(getPath('registration'))} />
        )}
      </BackgroundImage>
      {conditions && (
        <Modal animationType={'slide'} transparent={true} onRequestClose={() => setConditions(false)}>
          <SafeAreaView style={Style.modal}>
            <View style={Style.closeContainer}>
              <Button onPress={() => setConditions(false)}>
                <BackIcon />
              </Button>
            </View>
            <View style={Style.modalContainer}>
              <Conditions />
            </View>
          </SafeAreaView>
        </Modal>
      )}
      {privacy && (
        <Modal animationType={'slide'} transparent={true} onRequestClose={() => setPrivacy(false)}>
          <SafeAreaView style={Style.modal}>
            <View style={Style.closeContainer}>
              <Button onPress={() => setPrivacy(false)}>
                <BackIcon />
              </Button>
            </View>
            <View style={Style.modalContainer}>
              <PrivacyPolicy />
            </View>
          </SafeAreaView>
        </Modal>
      )}
    </View>
  );
};

Welcome.propTypes = {
  logged: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  logged: state.auth.logged,
});

const mapDispatchToProps = () => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Welcome);
