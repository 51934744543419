import React from 'react';
import PropTypes from 'prop-types';
import { Text, View, FlatList } from 'react-native';

import { getResponsive } from './ListStyle';
import { ApplicationStyles, Colors } from '../../../Theme';
import { Circle } from '../../../Theme/Icons';
import { LoaderInline } from '../../Layout';
import RaceItem from './Item';

const List = ({ races, adv=false }) => {
  const Style = getResponsive();

  return (
    <FlatList
      style={Style.listContainer}
      data={races}
      renderItem={({ item }) => <RaceItem race={item} />}
      keyExtractor={item => item.id}
      initialNumToRender={5}
      ListFooterComponent={
        <View style={[Style.listFooter, adv ? ApplicationStyles.screen.paddingAdvFooter : {}]}>
          {races === null
            ? <LoaderInline style={{}} />
            : races.length===0 && <><Circle size={6} color={Colors.grayLight} /><Text style={Style.endReachedText}>Nessun evento trovato.</Text></>
          }
        </View>
      }
    />
  );
};

List.propTypes = {
  races: PropTypes.array,
  adv: PropTypes.bool,
};

export default List;
