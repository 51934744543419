import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-native';

import { RouteWithSubRoutes } from '../../Router/Router';
import LayoutActions from '../../Stores/Layout/Actions';
import { PageNotFound } from "../../Components/Layout";
import Analytics from '../../Services/AnalyticsService';

const Play = ({ user, routes, setActiveTab }) => {
  
  React.useEffect(() => {
    Analytics.getInstance().trackPlayView();
  }, []);

  React.useEffect(() => {
    setActiveTab();
  });

  return user && (
    <Switch>
      {routes.map(route => (<RouteWithSubRoutes key={route.name} {...route} />))}
      <Route path='*'>
        <PageNotFound />
      </Route>
    </Switch>
  )
};

Play.propTypes = {
  user: PropTypes.object,
  routes: PropTypes.array,
  setActiveTab: PropTypes.func,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveTab: () => dispatch(LayoutActions.setTab('PLAY')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Play);
