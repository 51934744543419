import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  backButton: {
    padding: 20,
    paddingLeft: 30,
    paddingRight: 10,
  },
  container: {
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
  },
  desc: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayMedium3,
    fontSize: Fonts.size.regular+3,
    textAlign: 'center',
  },
  leftButton: {
    paddingLeft: 30,
    width: 60,
  },
  rightButton: {
    alignItems: 'flex-end',
    paddingRight: 30,
    width: 60,
  },
  textContainer: {
    flex: 1,
    paddingBottom: 14,
    paddingTop: 14,
  },
  textContainerDesktop: {
    paddingBottom: 30,
    paddingTop: 30,
  },
  title: {
    ...ApplicationStyles.screen.h1,
    fontSize: Fonts.size.h1-2,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  titleDesktop: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.grayMedium3,
    fontSize: Fonts.size.h1+8,
  },
  webContainer: {

  },
  wrapper: {
    backgroundColor: Colors.white,
    elevation: 4,
    paddingRight: 0,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    zIndex: 2,
  },
  withBack: {
    paddingRight: 60,
  },
};

const StyleLG = {
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
