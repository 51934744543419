import { StyleSheet } from "react-native";
import ResponsiveHelper from "../../../Services/ResponsiveHelper";
import { ApplicationStyles, Colors, Fonts } from "../../../Theme";

export const BaseStyle = {
  backButton: {
    paddingLeft: 20,
    paddingRight: 40,
    paddingTop: 20,
    paddingBottom: 30,
  },
  backButtonWeb: {
    paddingBottom: 5,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    flexDirection: "row",
    alignItems: "center",
  },
  backButtonWebText: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    fontSize: Fonts.size.regular - 2,
  },
  backButtonWebContainer: {},
  backButtonWebWrapper: {
    borderWidth: 1,
    borderColor: Colors.blue1,
    borderRadius: 20,
    alignSelf: "flex-start",
    marginBottom: 30,
    marginTop: 50,
  },
  background: {
    width: "100%",
    height: 100,
  },
  card: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 292,
    height: 208,
  },
  cardTypeText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular - 1,
  },
  cardTypeTitle: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.regular + 1,
    textTransform: "uppercase",
  },
  cardTypeTitleSelected: {
    color: Colors.blue1,
  },
  centerContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 40,
  },
  closeContainer: {
    marginBottom: 10,
    marginLeft: 30,
    marginTop: 30,
  },
  container: {
    flex: 1,
  },
  fullWidth: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  h1: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.h1 + 10,
    marginBottom: 30,
    marginTop: 50,
  },
  h2: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.h2,
    marginBottom: 50,
  },
  headerContainer: {
    ...ApplicationStyles.screen.webContainer,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerCardImage: {
    aspectRatio: 478 / 343,
    flex: 1,
    marginBottom: -20,
  },
  headerColumnLeft: {
    flex: 1,
    paddingHorizontal: 50,
  },
  headerColumnRight: {
    flex: 1.7,
  },
  headerText: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.h1,
    color: Colors.white,
    textAlign: "center",
    flex: 1,
    marginTop: 15,
  },
  innerContainer: {
    flex: 1,
    width: "100%",
  },
  innerTopContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  lead: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.h1,
    color: Colors.gray,
    textAlign: "center",
    marginBottom: 40,
    marginTop: 20,
  },
  leadBold: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.h1,
  },
  leadBlue: {
    color: Colors.blue1,
  },
  modal: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  modalContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  switchContainer: {
    alignItems: "flex-start",
    flexDirection: "row",
    width: "100%",
    marginBottom: 20,
  },
  switchInner: {
    flexDirection: "row",
    flexWrap: "wrap",
    flex: 1,
    marginLeft: 10,
    alignSelf: "center",
  },
  switchMarginBottom: {
    marginBottom: 40,
  },
  topContainer: {
    height: 100,
    justifyContent: "flex-start",
    alignItems: "center",
  },
};

const StyleMedium = {
  backButtonWebContainer: {
    width: "100%",
    maxWidth: 700,
    marginLeft: "auto",
    marginRight: "auto",
  },
  innerContainer: {
    backgroundColor: Colors.white,
    maxWidth: 700,
    marginLeft: "auto",
    marginRight: "auto",
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    padding: 30,
    marginBottom: 30,
  },
};

export const getResponsive = () =>
  ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });
export default StyleSheet.create(BaseStyle);
