import React from "react";
import {
  View,
  Platform,
  Text,
  Modal,
  TouchableOpacity,
  StyleSheet,
} from "react-native";

const AlertOldUser = ({ StateVisible, Close = () => {} }) => {
  return (
    <>
      {Platform.OS === "web" && StateVisible === true && (
        <View style={{}}>
          <Text style={styles.TextError}>
            Dati non validi – Codice fiscale xxxxxxxxxxxxxxx è già presente.
          </Text>
        </View>
      )}
      {Platform.OS !== "web" && (
        <Modal
          animationType="slide"
          transparent={true}
          visible={StateVisible}
          onRequestClose={() => {
            null;
          }}
        >
          <View style={styles.BodyModal}>
            <View style={styles.ContainerModal}>
              <Text style={styles.TextError}>
                Dati non validi – Codice fiscale xxxxxxxxxxxxxxx è già presente.
              </Text>
              <TouchableOpacity
                onPress={() => {
                  Close();
                }}
                style={styles.buttonClose}
              >
                <Text style={styles.textClose}>Chiudi</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  TextError: {
    fontSize: 20,
    color: "#d24311",
    textAlign: "center",
  },
  BodyModal: {
    backgroundColor: "rgba(0,42,85,0.5)",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 15,
  },
  ContainerModal: {
    backgroundColor: "#fff",
    borderRadius: 30,
    padding: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonClose: {
    padding: 15,
    borderWidth: 1,
    marginVertical: 15,
    borderRadius: 15,
    minWidth: "50%",
    backgroundColor: "rgba(0,42,85,0.5)",
  },
  textClose: {
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
  },
});

export default AlertOldUser;
