import { StyleSheet, Platform } from 'react-native';
import { Colors, ApplicationStyles, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

export const BaseStyle = {
  back: {
    backgroundColor: 'rgba(0,0,0,1)',
    height: '100%',
    width: '100%',
  },
  button: {
    alignItems: 'center',
    paddingBottom: 15,
    paddingTop: 15,
    width: '100%',
  },
  buttonText: {
    ...ApplicationStyles.screen.fontsRegular,
    fontSize: Fonts.size.small,
    color: Colors.white,
    marginTop: 5,
    textAlign: 'center',
  },
  buttonWrapper: {
    alignItems: 'center',
  },
  container: {
    bottom: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: 125,
  },
  content: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
  },
  closeWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  gradient: {
    alignItems: 'center',
    height: '100%',
    opacity: 0.89,
    width: '100%',
  },
  innerContainer: {
    width: '100%',
    alignItems: 'center',
    marginVertical: 20,
  },
  link: {
    width: '100%',
  },
  wrapper: {
    bottom: 0,
    flex: 1,
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    left: 0,
    right: 0,
    top: 0,
    overflow: 'hidden',
    zIndex: 3,
  },
};

const StyleMedium = {
  buttonText: {
    marginTop: 10,
  },
  container: {
    width: 200
  },
  gradient: {
    paddingBottom: 80,
    paddingTop: 80,
  },
};

export const getResponsive = (stylesheet=true) => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium, stylesheet });
export default StyleSheet.create(BaseStyle);
