import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  pushNavigation: ['route'],
  resetNavigation: null,
  setAfterLogin: ['route'],
  setAfterRuncardLink: ['route'],
  setAfterStrava: ['route'],
});

export const NavigationTypes = Types;
export default Creators;
