import React from 'react';
import PropTypes from 'prop-types';
import { View, Text, FlatList, Image, Platform } from 'react-native';
import { generatePath } from 'react-router-native';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Slider from 'react-slick';

import { getResponsive } from './EditorialStyle';
import { Colors } from '../../../../Theme';
import { LoaderInline } from '../../../Layout';
import { Button } from '../../../Buttons';

import ChallengesActions from '../../../../Stores/Challenges/Actions';
import { getPath } from '../../../../Router/AppRoutes';
import { CheckIconRound, LeftArrow, RightArrow } from '../../../../Theme/Icons';
import { isDesktop } from "../../../../Services/ResponsiveHelper";

const EditorialSlider = () => {
  const Style = getResponsive();

  const dispatch = useDispatch();

  const data = useSelector(state => state.challenges.editorial_challenges);
  const error = useSelector(state => state.challenges.editorial_error_challenges);

  const [ loading, setLoading ] = React.useState(false);

  const Arrow = ({ className, style, onClick, type }) => {
    return className.indexOf('disabled') === -1 ? (
      <a className={`${className ? className : ''} rounded-arrows`} href={'#'} style={{ ...style }} onClick={onClick}>
        {type === 'prev' ? <LeftArrow color={Colors.white} stroke={2} size={isDesktop() ? 20 : 10} /> : <RightArrow color={Colors.white} stroke={2} size={isDesktop() ? 20 : 10} />}
      </a>
    ) : null;
  };

  Arrow.propTypes = {
    className: PropTypes.string,
    style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
    type: PropTypes.string,
    onClick: PropTypes.func,
  };

  const sliderSettings = {
    className: `slider slider-challenge ${isDesktop() ? 'slider-challenge-big' : ''}`,
    dots: true,
    arrow: true,
    infinite: false,
    slidesToShow: 3,
    nextArrow: <Arrow type={'next'} />,
    prevArrow: <Arrow type={'prev'} />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  React.useEffect(() => {
    if (!data && !error) {
      setLoading(true);
      dispatch(ChallengesActions.getEditorialChallenges(false));
    }
  }, []);

  React.useEffect(() => {
    if (data !== null && loading) {
      setLoading(false);
    }
  }, [data]);

  const Challenge = ({ item }) => (
    <View style={Style.topListItem}>
      <Button route={generatePath(getPath('challengeEditorial'), { id: item.id })} style={Style.topListItemButton}>
        <Image source={{ uri: item.image }} style={Style.topListItemImage} resizeMode={'cover'} />
        <View style={Style.topListItemText}>
          <Text style={Style.topListItemTextTitle}>{item.title}</Text>
          <Text style={Style.topListItemTextDesc}>Termina tra {moment(item.end_date, 'YYYYMMDD').diff(moment(), 'days')} giorni</Text>
        </View>
        {item.joined && <View  style={Style.joinedIcon}><CheckIconRound /></View>}
      </Button>
    </View>
  );

  Challenge.propTypes = {
    item: PropTypes.object,
  };

  return (
    <View style={Style.topWrapper}>
      <View style={Style.topTitle}>
        <Text style={Style.topTitleText}>TOP SFIDE</Text>
        <Button route={'challengesEditorial'}><Text style={Style.topTitleLink}>Vedi tutte</Text></Button>
      </View>
        {!error
          ? Platform.OS !== 'web'
              ? (
                <FlatList
                  horizontal={true}
                  contentContainerStyle={Style.topListWrapper}
                  data={data ? data.filter(el => moment(el.end_date, 'YYYYMMDD').isSameOrAfter(moment())).slice(0, 5) : data}
                  renderItem={({ item }) => <Challenge item={item} />}
                  keyExtractor={item => item.id}
                  ListEmptyComponent={data !== null ? <Text style={Style.error}>Non c&apos;è nessuna sfida attiva al momento</Text> : null}
                  initialNumToRender={5}
                  ListFooterComponent={loading ? <LoaderInline style={{}} /> : null}
                />
              ) : (
                <Slider {...sliderSettings}>
                  {data && data.filter(el => moment(el.end_date, 'YYYYMMDD').isSameOrAfter(moment())).slice(0, 5).map((item, key) => <Challenge key={key} item={item} />)}
                </Slider>
              )
          : <Text style={Style.error}>Non è stato possibile recuperare le sfide</Text>
        }
    </View>
  );
};

export default EditorialSlider;
