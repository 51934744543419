import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Polyline } from 'react-native-svg';

import { Colors } from '../../Theme';

const LeftArrow = ({ size=20, color=Colors.grayLight, stroke=1, shadow=false }) => (
  <Svg width={size} height={size} viewBox={'0 0 9 18'} className={shadow ? 'svg-shadow' : ''}>
    <G stroke={'none'} strokeWidth={stroke} fill={'none'} fillRule={'evenodd'} strokeLinecap={'round'}>
      <G transform={'translate(-329.000000, -233.000000)'} stroke={color}>
        <Polyline points={'337 251 330 242 337 233'} />
      </G>
    </G>
  </Svg>
);

LeftArrow.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  stroke: PropTypes.number,
  shadow: PropTypes.bool,
};

export default LeftArrow;
