import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop, Mask, Use, Polygon } from 'react-native-svg';

const TabRuncardButton = ({ active=false, size=55 }) => {
  return (
    <Svg width={size} height={size} viewBox={'0 0 55 55'}>
      <Defs>
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradientBgButtonRuncard">
          <Stop stopColor={'#FFFFFF'} offset="20.1198269%" />
          <Stop stopColor={'#E3E4E3'} offset="100%" />
        </LinearGradient>
        <Polygon id="pathIconRuncard" points="0 0.45894322 8.13662542 0.45894322 8.13662542 9.03290329 0 9.03290329" />
      </Defs>
      <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
        <G fill={'url(#gradientBgButtonRuncard)'}>
          <Path d={'M27.5,55 C42.6878306,55 55,42.6878306 55,27.5 C55,12.3121694 42.6878306,0 27.5,0 C12.3121694,0 0,12.3121694 0,27.5 C0,42.6878306 12.3121694,55 27.5,55 Z'} />
        </G>
        <G transform="translate(17.000000, 10.000000)">
          <G transform="translate(0.000000, 25.259895)">
            <Mask id={'mask'} fill={'white'}>
              <Use href="#pathIconRuncard" />
            </Mask>
            <Path d={'M8.10800928,1.16520768 C8.02454688,0.90937728 7.81226208,0.73519488 7.44031008,0.63721728 C7.25705568,0.58097088 7.10464608,0.54468288 6.97945248,0.52109568 C6.83430048,0.48843648 6.72543648,0.47210688 6.62020128,0.45940608 C6.20107488,0.44851968 5.84182368,0.62995968 5.55696288,0.97288128 L0.24984288,7.51197888 C0.06114528,7.75329408 -0.02231712,7.98009408 0.00489888,8.20326528 C0.02848608,8.34660288 0.10106208,8.53530048 0.33511968,8.69315328 L0.40225248,8.72944128 C0.46938528,8.76391488 0.56917728,8.80020288 0.69981408,8.84556288 C0.81956448,8.88910848 0.97378848,8.93446848 1.15522848,8.97801408 L1.15704288,8.98164288 C1.71043488,9.12679488 2.20395168,8.96168448 2.57590368,8.51171328 L7.88120928,1.96717248 C8.11345248,1.68775488 8.18239968,1.41559488 8.10800928,1.16520768 L8.10800928,1.16520768 Z'} fill={active ? '#00ADEE' : '#C3C4C3'} mask={'url(#mask)'} />
          </G>
          <Path d={'M17.0843904,27.0184118 C17.0589888,26.9113622 17.0335872,26.8043126 16.991856,26.6682326 C16.946496,26.5448534 16.8975072,26.3942582 16.8321888,26.2146326 C16.7596128,26.0277494 16.3967328,25.6703126 16.3767744,25.6521686 L10.4346144,19.6247318 C10.2168864,19.4088182 9.9919008,19.3053974 9.7596576,19.3180982 C9.6290208,19.3180982 9.4203648,19.3725302 9.2552544,19.584815 L9.2044512,19.648319 C9.16272,19.7190806 9.11736,19.8098006 9.0592992,19.938623 C9.008496,20.0511158 8.944992,20.1962678 8.8778592,20.3722646 L8.8796736,20.3758934 C8.6692032,20.9020694 8.7871392,21.4228022 9.2008224,21.8328566 L14.3537184,27.0075254 L9.4965696,31.8465302 C9.0847008,32.2547702 8.926848,32.7283286 9.126432,33.2617622 L9.126432,33.2672054 C9.199008,33.437759 9.2479968,33.5865398 9.3096864,33.7044758 C9.3659328,33.8296694 9.407664,33.9240182 9.443952,33.9893366 L9.489312,34.0619126 C9.66168,34.2687542 9.870336,34.3195574 10.0046016,34.3304438 C10.2295872,34.3413302 10.47816,34.2614966 10.695888,34.0473974 L16.6725216,28.1161238 C16.9864128,27.8076758 17.1333792,27.4284662 17.0843904,27.0184118'} fill={active ? '#00ADEE' : '#C3C4C3'} />
          <Path d={'M19.5296573,10.0766333 L19.4171645,10.0748189 C18.6351581,10.0911485 18.1470845,10.2961757 17.9565725,10.6790141 L15.2585597,16.5231965 L10.3741949,10.5030173 C10.1691677,10.2544445 9.84439008,10.1292509 9.41437728,10.1256221 L9.35450208,10.1256221 C8.91178848,10.1383229 8.48540448,10.2490013 8.08623648,10.4576573 C7.73424288,10.6536125 7.50744288,10.8677117 7.38224928,11.1180989 L3.99295008,18.6043133 C3.88590048,18.8057117 3.93488928,18.9399773 3.99295008,19.0234397 C4.11451488,19.2030653 4.39030368,19.2883421 4.87837728,19.2901565 L4.99087008,19.2883421 C5.77106208,19.2738269 6.25913568,19.0742429 6.44964768,18.6895901 L9.13858848,12.7329149 L14.0701277,18.9055037 C14.2751549,19.1504477 14.5999325,19.2774557 15.0335741,19.2810845 L15.0952637,19.2810845 C15.5361629,19.2701981 15.9643613,19.1631485 16.3544573,18.9508637 C16.7137085,18.7494653 16.9477661,18.5408093 17.0657021,18.2958653 L20.4132701,10.7642909 C20.5130621,10.5665213 20.4713309,10.4268125 20.4150845,10.3415357 C20.2898909,10.1655389 20.0177309,10.0784477 19.5296573,10.0766333'} fill={active ? '#00ADEE' : '#C3C4C3'} />
          <Path d={'M19.571207,3.6632736 C19.5657638,5.6718144 17.9237318,7.2993312 15.9133766,7.29029694 C13.8957638,7.2811872 12.2755046,5.6391552 12.2791274,3.6306144 C12.2936486,1.6166304 13.9356806,-0.009072 15.9442214,-3.77847168e-05 C17.9545766,0.009072 19.5839078,1.6474752 19.571207,3.6632736'} fill={active ? '#00ADEE' : '#C3C4C3'} />
        </G>
      </G>
    </Svg>
  )
};

TabRuncardButton.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
};

export default TabRuncardButton;
