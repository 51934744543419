import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { View, Image } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

import Style from './UserAvatarStyle';
import { Colors } from '../../../Theme';
import { BlankAvatar } from '../../../Theme/Icons';

const UserAvatar = ({ user, size=100 }) => {
  return user && (
    <View>
      {!user.picture
        ? (
          <LinearGradient colors={[Colors.blue1, Colors.blue2]} style={[Style.avatarGradient, Style.avatarShadow, { borderRadius: (size/2), height: size, width: size }]}>
            <BlankAvatar size={(size-30)} />
          </LinearGradient>
        )
        : (
          <View style={[Style.avatarShadow, { borderRadius: (size/2) }]}>
            <Image source={{ uri: user.picture }} style={[Style.uploadedAvatar, { borderRadius: (size/2), height: size, width: size }]} />
          </View>
        )
      }
    </View>
  );
};

UserAvatar.propTypes = {
  user: PropTypes.object,
  size: PropTypes.number,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(UserAvatar);
