import { put, call, spawn, select, all } from "redux-saga/effects";
import { Auth } from "aws-amplify";
import { Config } from "../Config";

import { getUniqueId } from "../Services/NotificationService";
import StartupActions from "../Stores/Startup/Actions";
import AuthActions from "../Stores/Auth/Actions";
import RegistrationActions from "../Stores/Registration/Actions";
import NavigationActions from "../Stores/Navigation/Actions";
import HelperActions from "../Stores/Helper/Actions";
import NewsActions from "../Stores/News/Actions";
import {
  fetchUser,
  fetchOptions,
  fetchServices,
  savePushToken,
} from "../Services/ApiGatewayService";
import { getRuncard } from "./RuncardSaga";
import { getProvinces } from "./HelperSaga";
import { getNews } from "./NewsSaga";
import { fetchStravaActivities, fetchSaluteActivities } from "./WorkoutSaga";
import {
  getEditorialChallenges,
  getUserChallenges as getUserChallenge,
  checkCompletedChallenges,
} from "./ChallengesSaga";
import { getSocialWallData } from "./SocialWallSaga";

export function* startup({ loginWith }) {
  yield put(StartupActions.setLoaded(false));

  try {
    // Advertising at first
    const options = yield call(fetchOptions);
    yield put(HelperActions.setOptions(options));
  } catch (err) {
    yield put(StartupActions.setLoadingError(err));
    return;
  }

  // Check if user is logged...
  let user;
  try {
    const result = yield Auth.currentAuthenticatedUser()
      .then((user) => ({ status: "LOGGED", user }))
      .catch(() => null);
    if (result && result.status === "LOGGED") {
      try {
        const credentials = yield Auth.currentCredentials().then(
          (credentials) => credentials
        );
        user = yield call(fetchUser, credentials.identityId);
        if (user.complete) {
          yield put(AuthActions.setUserLogged(user));
          yield put(HelperActions.sendLeaderboardEvent("login"));
          if (loginWith) {
            yield put(HelperActions.sendCRMEvent(`event_login_${loginWith}`));
          }
          const afterLogin = yield select(
            (state) => state.navigation.afterLogin
          );
          if (afterLogin) {
            yield put(NavigationActions.pushNavigation(afterLogin));
          }
        } else {
          yield put(RegistrationActions.registrationToBeCompleted(user));
          yield put(NavigationActions.pushNavigation("registration"));
        }
      } catch (err) {
        yield put(AuthActions.logout());
      }
    }

    const services = yield call(fetchServices);
    services.sort((a, b) => a.order - b.order);
    yield put(HelperActions.setServices(services));
  } catch (err) {
    console.log("ERROR on startup", err);
  }

  try {
    // User's workout
    if (user && user.complete) {
      yield all([
        call(getEditorialChallenges, { loader: false }),
        call(getUserChallenge, { loader: false }),
      ]);
      yield call(checkCompletedChallenges);
    }
  } catch (err) {
    console.log("ERROR on startup", err);
  }

  yield put(StartupActions.setLoaded(true));

  try {
    // User's workout
    if (user && user.complete) {
      yield all([
        call(fetchStravaActivities, { loader: false }),
        call(fetchSaluteActivities, { loader: false }),
      ]);
    }
  } catch (err) {}

  try {
    // Preload other stuff
    yield spawn(getProvinces, { loader: false });
  } catch (err) {
    console.log("ERROR on startup", err);
  }

  try {
    // User runcard
    if (user && user.complete && user.runcard) {
      yield spawn(getRuncard, { loader: false });
    }
  } catch (err) {
    console.log("ERROR on startup", err);
  }

  try {
    // News
    yield all(
      Object.keys(Config.NEWS.CATEGORIES).map((category) =>
        call(getNews, { category })
      )
    );
    yield put(NewsActions.setNewsLoaded(true));
  } catch (err) {
    console.log("ERROR on startup", err);
  }

  try {
    // SocialWallData Preload
    yield spawn(getSocialWallData, { loader: false });
  } catch (err) {
    console.log("ERROR on startup", err);
  }
}

export function* token({ token }) {
  const device_id = getUniqueId();
  yield call(savePushToken, {
    device_id,
    push_token: token,
    platform: Platform.OS,
  });
}
