import { combineReducers } from 'redux';
import configureStore from './CreateStore';
import rootSaga from '../Sagas';
import { reducer as StartupReducer } from './Startup/Reducers';
import { reducer as AuthReducer } from './Auth/Reducers';
import { reducer as LayoutReducer } from './Layout/Reducers';
import { reducer as RegistrationReducer } from './Registration/Reducers';
import { reducer as NavigationReducer } from './Navigation/Reducers';
import { reducer as HelperReducer } from './Helper/Reducers';
import { reducer as OrderReducer } from './Order/Reducers';
import { reducer as RuncardReducer } from './Runcard/Reducers';
import { reducer as S3Reducer } from './S3/Reducers';
import { reducer as NewsReducer } from './News/Reducers';
import { reducer as ServicesReducer } from './Services/Reducers';
import { reducer as WorkoutReducer } from './Workout/Reducers';
import { reducer as SocialWallReducer } from './SocialWall/Reducers';
import { reducer as ChallengesReducer } from './Challenges/Reducers';
import { reducer as ResultsReducer } from './Results/Reducers';
import { reducer as RacesReducer } from './Races/Reducers';
import { reducer as CattolicaReducer } from './Cattolica/Reducers';

export default () => {
  const rootReducer = combineReducers({
    startup: StartupReducer,
    auth: AuthReducer,
    registration: RegistrationReducer,
    layout: LayoutReducer,
    navigation: NavigationReducer,
    helper: HelperReducer,
    order: OrderReducer,
    runcard: RuncardReducer,
    S3: S3Reducer,
    news: NewsReducer,
    services: ServicesReducer,
    workout: WorkoutReducer,
    social_wall: SocialWallReducer,
    challenges: ChallengesReducer,
    results: ResultsReducer,
    races: RacesReducer,
    cattolica: CattolicaReducer,
  });

  return configureStore(rootReducer, rootSaga);
};
