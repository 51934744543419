import { INITIAL_STATE } from './InitialState';
import { createReducer } from 'reduxsauce';
import { ResultsTypes } from './Actions';

export const setResults = (state, { data }) => ({
  ...state,
  results: data,
});

export const setResultsError = (state, { error }) => ({
  ...state,
  results_error: error,
});

export const reducer = createReducer(INITIAL_STATE, {
  [ResultsTypes.SET_RESULTS]: setResults,
  [ResultsTypes.SET_RESULTS_ERROR]: setResultsError,
});
