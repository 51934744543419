import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-native';

import { getPath } from './Router';

const RuncardRoute = ({ route }) => {
  const location = useLocation();

  const { path, exact, routes, paywall, ...rest } = route;
  const { logged, user } = useSelector(state => state.auth);

  return (
    <Route
      path={path}
      exact={exact||false}
      render={props => (logged && user && user.runcard && user.runcard !== '' ? <route.component {...props} routes={routes} {...rest} /> : <Redirect push={false} to={{ pathname: getPath(paywall && (!logged && !user) ? 'paywall' : 'linkRuncard'), state: { redirectAfterLogin: location } }} />)}
    />
  );
};

RuncardRoute.propTypes = {
  route: PropTypes.object.isRequired,
};

export default RuncardRoute;
