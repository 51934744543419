import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getNews: ['category', 'from'],
  setNews: ['news'],
  setNewsError: ['error'],
  getSingleNews: ['uuid'],
  setSingleNews: ['news'],
  setSingleNewsError: ['error'],
  setNewsLoadMore: ['category', 'loadMore'],
  setNewsLoaded: null,
});

export const NewsTypes = Types;
export default Creators;
