import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-native';

import { LoginForm } from '../../Components/Auth';
import NavigationAction from '../../Stores/Navigation/Actions';

const Login = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const logged = useSelector(state => state.auth.logged);

  React.useEffect(() => {
    if (location.state && location.state.redirectAfterLogin) {
      dispatch(NavigationAction.setAfterLogin(location.state.redirectAfterLogin));
    }
  }, []);

  return !logged && (
    <LoginForm email={location.state ? location.state.email||'' : ''} />
  )
};

export default Login;
