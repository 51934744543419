import { ApplicationStyles } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  container: {
    paddingBottom: 0,
    paddingTop: 5,
  },
  webContainer: {
    flexGrow: 1,
  },
  wrapper: {
    ...ApplicationStyles.screen.paddingTabBar,
    flex: 1,
  },
  error: {
    ...ApplicationStyles.screen.fontsLight,
    marginVertical: 20,
    textAlign: 'center',
  },
};

const StyleLG = {
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
    paddingBottom: 30,
  },
  wrapper: {
    paddingBottom: 0,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
