import React from 'react';
import PropTypes from 'prop-types';
import { SafeAreaView, View } from 'react-native';

import Style from './ModalDownloadStyle';
import { PageHeader, WebView, Modal } from '../';
import { Button, Download } from '../../Buttons';
import { BackIcon } from '../../../Theme/Icons';

const ModalDownload = ({ closeHandler, url, title }) => (
  <Modal transparent={true} onRequestClose={closeHandler} animationType={'fade'}>
    <SafeAreaView style={Style.background}>
      <View style={Style.container}>
        <PageHeader title={title} back={false} left={<Button onPress={closeHandler}><BackIcon /></Button> } />
        <View style={Style.download}>
          <Download url={url} />
        </View>
        <WebView url={url} />
      </View>
    </SafeAreaView>
  </Modal>
);

ModalDownload.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default ModalDownload;
