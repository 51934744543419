import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  buyButton: {
    width: 150,
  },
  buyButtonText: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
  },
  card: {
    flex: 3,
    marginBottom: 30,
    paddingLeft: 100,
    paddingRight: 30,
    paddingVertical: 30,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
  },
  cardContainer: {
    flexDirection: 'row',
  },
  cardImage: {
    aspectRatio: 478/343,
    flex: 1,
    marginRight: -80,
    transform: [{ rotate: '-10deg' }]
  },
  cardTypeText: {
    ...ApplicationStyles.screen.fontsLight,
    marginBottom: 20,
  },
  cardTypeTitle: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.regular+2,
    textTransform: 'uppercase',
  },
  columns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  faqContainer: {
    paddingBottom: 20,
  },
  faqTitle: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.h1,
    paddingBottom: 20,
    paddingTop: 50,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  grayWrapper: {
    backgroundColor: Colors.grayUltraLight,
    paddingBottom: 30,
    paddingTop: 50,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 3 },
    shadowOpacity: 0.2,
    shadowRadius: 5,
    zIndex: 2,
  },
  h1: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.h1+10,
    marginBottom: 30,
    marginTop: 50,
  },
  h2: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.h2,
    marginBottom: 50,
  },
  headerCardImage: {
    aspectRatio: 478/343,
    flex: 1,
    marginBottom: -20,
  },
  headerColumnLeft: {
    flex: 1,
    paddingHorizontal: 50,
  },
  headerColumnRight: {
    flex: 1.7,
  },
  leftColumn: {
    width: 600,
  },
  leftColumnText1: {
    ...ApplicationStyles.screen.fonts,
    fontSize: Fonts.size.regular+3,
    lineHeight: Fonts.size.regular+10,
    marginVertical: 30,
  },
  leftColumnText2: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular+3,
    lineHeight: Fonts.size.regular+10,
  },
  runcardH1: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.h1+10,
    paddingTop: 50,
    textAlign: 'center',
  },
  runcardH2: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.h2,
    paddingBottom: 50,
    textAlign: 'center',
  },
  runcardList: {
    marginBottom: 20,
  },
  runcardResidenza: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular+2,
    paddingBottom: 10,
    textAlign: 'center',
  },
  runcardResidenzaChoose: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 300,
    minWidth: 200,
  },
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
});
