import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  registration: ['data'],
  socialRegistration: ['social'],
  registrationToBeConfirmed: ['data'],
  registrationToBeCompleted: ['data'],
  confirmRegistration: ['data'],
  registrationSuccess: null,
  registrationFailure: ['error'],
  completeRegistration: ['data'],
  fullRegistration: ['data', 'runcard_data'],
  updateForRenew: ['data'],
  fullRegistrationSuccess: null,
  validationErrors: ['errors'],
  registrationReset: null,
  requestCode: ['username'],
  requestCodeSuccess: null,
  requestCodeFailure: ['error'],
  updateUser: ['data'],
});

export const RegistrationTypes = Types;
export default Creators
