export const INITIAL_STATE = {
  workout_data: null,
  google_fit_data_error: null,
  google_fit_data_success: null,
  strava_data_error: null,
  strava_data_success: null,
  salute_data_error: null,
  salute_data_success: null,
  workouts_fetched: [],
};
