import { StyleSheet } from 'react-native';
import { ApplicationStyles } from '../../../Theme';

export default StyleSheet.create({
  container: {
    paddingBottom: ApplicationStyles.screen.paddingTabBar.paddingBottom-20,
  },
  innerContainer: {
    paddingLeft: 30,
    paddingRight: 40,
    paddingTop: 10,
  },
});
