import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  back: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-1,
  },
  backText: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
    marginBottom: 10,
    marginTop: 10,
    textAlign: 'center',
  },
  button: {
    marginBottom: 20,
    marginTop: 20,
  },
  closeButton: {
    padding:20,
  },
  closeContainer: {
    margin: -20,
    marginBottom: 0,
  },
  container: {
    flex: 1,
    padding: 20,
  },
  containerModal: {
    paddingBottom: 10,
    paddingTop: 0,
  },
  error: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.red,
    fontSize: Fonts.size.regular-2,
    marginBottom: 10,
  },
  h1: {
    ...ApplicationStyles.screen.h1,
    marginBottom: 20,
    marginTop: 10,
    textAlign: 'center',
  },
  lead: {
    ...ApplicationStyles.screen.fontsLight,
    marginBottom: 30,
    marginTop: 20,
    textAlign: 'center',
  },
  sectionWrapper: {
    borderColor: Colors.grayUltraLight,
    borderTopWidth: 1,
    marginTop: 20,
    padding: 20,
    paddingBottom: 0,
  },
  skip: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  skipButton: {
    paddingBottom: 15,
  },
  skipText: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
  },
  textInput: {
    ...ApplicationStyles.screen.fontsLight,
    borderBottomWidth: 1,
    borderColor: Colors.grayUltraLight,
    color: Colors.gray,
    fontSize: Fonts.size.regular,
    marginBottom: 20,
    paddingBottom: 10,
    paddingTop: 10,
  },
  textWrapper: {
    flex: 1,
    flexDirection: 'row',
  },
  wrapper: {
    flex: 1,
  },
  wrapperBackground: {
    backgroundColor: Colors.darkBlue,
  },
});
