import React from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Keyboard,
  Linking,
  SafeAreaView,
  ScrollView,
  Switch,
  Text,
  TextInput,
  View,
} from "react-native";
import { useHistory, useLocation } from "react-router-native";
import { Config } from "../../../Config";

import Style, { getResponsive } from "./LinkRuncardStyle";
import { BackgroundImage } from "../../../Components/Layout";
import {
  BackOrCloseButton,
  PrimaryButton,
  TransparentButton,
  Button,
} from "../../../Components/Buttons";
import Tutorial from "../../../Components/Tutorial/Tutorial";
import { ErrorPopup, SuccessPopup } from "../../../Components/Popup";

import ResponsiveHelper from "../../../Services/ResponsiveHelper";
import RuncardActions from "../../../Stores/Runcard/Actions";
import NavigationAction from "../../../Stores/Navigation/Actions";
import HelperActions from "../../../Stores/Helper/Actions";
import { getPath } from "../../../Router/Router";

import { AlertOldUser } from "../../ModalOldUser/index";

const LinkRuncard = ({ buyHandler }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  let history = useHistory();

  const {
    link_error: error,
    link_success: success,
    runcard_buy_error: buyError,
  } = useSelector((state) => state.runcard);
  const nextRoute = useSelector((state) => state.navigation.afterRuncardLink);

  const [code, setCode] = React.useState("");
  const [taxCode, setTaxCode] = React.useState("");
  const [foreign, setForeign] = React.useState(false);
  const [tutorial, setTutorial] = React.useState(false);
  const [{}, setBreakpoint] = React.useState(ResponsiveHelper.getSize());
  const [oldUser, setOldUser] = React.useState(false);

  const handleHelp = () => {
    try {
      Linking.openURL(
        `mailto:${Config.HELP_CONTACTS.address}?subject=${
          Config.HELP_CONTACTS.subject
        }`
      ).catch(() => {});
    } catch (err) {}
  };

  const successHandler = () => {
    dispatch(RuncardActions.clearLinkStatus());
    dispatch(HelperActions.sendCRMEvent("event_linked_runcard"));
    dispatch(HelperActions.sendLeaderboardEvent("connect-card"));
    history.push(getPath(nextRoute ? nextRoute : "runcard"));
    if (nextRoute) dispatch(NavigationAction.setAfterRuncardLink(null));
  };

  const skipHandler = () => {
    dispatch(HelperActions.sendCRMEvent("event_skip_runcard"));
    dispatch(RuncardActions.clearLinkStatus());

    if (
      !location.state ||
      !location.state.hasOwnProperty("back") ||
      location.state.back !== false
    ) {
      if (
        (history.hasOwnProperty("canGo") && history.canGo(-1)) ||
        (!history.hasOwnProperty("canGo") && history.length > 1)
      ) {
        history.goBack();
        return;
      }
    }

    history.push(getPath(Config.APP_HOMEPAGE));
  };

  const controlCode = (x) => {
    let a = x.replace(/[^\d.-]/g, "");
    setCode(a);
  };

  return (
    <View
      onLayout={() => setBreakpoint(ResponsiveHelper.getSize())}
      style={Style.container}
    >
      <BackgroundImage>
        <SafeAreaView style={Style.container}>
          <ScrollView contentContainerStyle={Style.container} bounces={false}>
            <View style={getResponsive().innerContainer}>
              {!location.state ||
              !location.state.hasOwnProperty("back") ||
              location.state.back !== false ? (
                <BackOrCloseButton
                  style={Style.backButton}
                  route={nextRoute ? nextRoute : null}
                />
              ) : (
                <View style={Style.backButton} />
              )}
              <View style={Style.centerContainer}>
                <Text style={Style.lead}>HAI GI&Agrave; UNA RUNCARD?</Text>
                <Text style={Style.data}>Inserisci i tuoi dati</Text>
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      paddingHorizontal: 15,
                      color: "#fff",
                      fontSize: 22,
                    }}
                  >
                    RR
                  </Text>
                  <TextInput
                    style={Style.textInput}
                    autoCorrect={false}
                    placeholder={"Numero Runcard"}
                    onBlur={Keyboard.dismiss}
                    value={code}
                    onChangeText={(x) => {
                      controlCode(x);
                    }}
                  />
                </View>
                {!foreign && (
                  <TextInput
                    style={[Style.textInput, { marginLeft: 60 }]}
                    autoCorrect={false}
                    placeholder={"Codice Fiscale"}
                    onBlur={Keyboard.dismiss}
                    value={taxCode}
                    onChangeText={setTaxCode}
                  />
                )}
                <View style={Style.switchWrapper}>
                  <Switch value={foreign} onValueChange={setForeign} />
                  <Text style={Style.switchText}>Non risiedo in Italia</Text>
                </View>
                <PrimaryButton
                  onPress={() =>
                    dispatch(RuncardActions.linkRuncard("RR" + code, taxCode))
                  }
                  style={Style.button}
                  disabled={!code.length || (!taxCode.length && !foreign)}
                >
                  ASSOCIA
                </PrimaryButton>
              </View>
              {buyError ? (
                <View style={Style.bottomContainer}>
                  <Text style={Style.buyNow}>
                    Siamo spiacenti, il tuo acquisto non è andato a buon fine.
                    {"\n"}
                    {"\n"}
                    <Text style={Style.buyError}>
                      Codice ordine: {buyError.order_id}
                      {"\n"}
                      Codice di errore: {buyError.code}
                    </Text>
                  </Text>
                  <View style={Style.buttonContainer}>
                    <PrimaryButton
                      size={"small"}
                      onPress={handleHelp}
                      style={Style.button}
                    >
                      ASSISTENZA
                    </PrimaryButton>
                  </View>
                  <Button
                    onPress={() => skipHandler()}
                    style={Style.laterButton}
                  >
                    <Text style={Style.later}>
                      Inserisci i dati Runcard in seguito
                    </Text>
                  </Button>
                </View>
              ) : (
                <View style={Style.bottomContainer}>
                  <Text style={Style.buyNow}>Non hai ancora una Runcard?</Text>
                  <View style={Style.buttonContainer}>
                    <TransparentButton
                      onPress={() => setTutorial(true)}
                      style={Style.button}
                    >
                      SCOPRI DI PIÙ
                    </TransparentButton>
                    <PrimaryButton
                      onPress={() => buyHandler(true)}
                      style={Style.button}
                    >
                      ACQUISTALA ORA!
                    </PrimaryButton>
                  </View>
                  <Button
                    onPress={() => skipHandler()}
                    style={Style.laterButton}
                  >
                    <Text style={Style.later}>Acquista runcard in seguito</Text>
                  </Button>
                </View>
              )}
            </View>
          </ScrollView>
          {error !== null && (
            <ErrorPopup
              closeHandler={() => dispatch(RuncardActions.clearLinkStatus())}
              title={"Runcard non associata"}
              text={error}
              help={true}
            />
          )}
          {success && (
            <SuccessPopup
              closeHandler={() => successHandler()}
              title={"Runcard associata con successo!"}
            />
          )}
        </SafeAreaView>
        {tutorial && (
          <Tutorial
            onClose={() => setTutorial(false)}
            onEnd={() => buyHandler(true)}
            button={"ACQUISTALA ORA!"}
          />
        )}
      </BackgroundImage>
      <AlertOldUser StateVisible={oldUser} Close={() => setOldUser(false)} />
    </View>
  );
};

LinkRuncard.propTypes = {
  buyHandler: PropTypes.func,
};

export default LinkRuncard;
