import { ApplicationStyles, Fonts, Colors } from '../../../Theme';
import ResponsiveHelper from "../../../Services/ResponsiveHelper";

const BaseStyle = {
  activeTabButton: {
    borderBottomWidth: 2,
    borderColor: Colors.blue1,
  },
  activeTabLinkText: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue1,
    fontSize: Fonts.size.regular-2,
  },
  tabButton: {
    paddingBottom: 5,
    marginHorizontal: 10,
  },
  tabLink: {
    borderBottomWidth: 1,
    borderColor: Colors.grayALittleLight,
  },
  tabLinkText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-2,
  },
  gradientHidden: {
    height: 0,
    width: 0,
  },
  gradientLeft: {
    bottom: 0,
    left: -15,
    position: 'absolute',
    top: 0,
    width: 30,
  },
  gradientRight: {
    bottom: 0,
    position: 'absolute',
    right: -15,
    top: 0,
    width: 30,
  },
  tabBorder: {
    position: 'absolute',
    bottom: 0,
    left: 10,
    right: 10,
    borderBottomWidth: 1,
    borderColor: Colors.grayALittleLight,
  },
  wrapper: {
    marginTop: 20,
    marginHorizontal: 20,
  },
  innerWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexGrow: 1,
  },
};

const StyleLG = {
  tabLink: {
    borderBottomWidth: 2,
  },
  tabLinkText: {
    fontSize: Fonts.size.regular+2,
    textAlign: 'center',
  },
  activeTabLinkText: {
    fontSize: Fonts.size.regular+2,
  },
  tabBorder: {
    borderBottomWidth: 2,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
