import { StyleSheet } from 'react-native';
import { ApplicationStyles } from '../../Theme';

export default StyleSheet.create({
  title: {
    ...ApplicationStyles.screen.fonts,
    flex: 1,
    textTransform: 'uppercase',
  },
});
