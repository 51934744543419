import { Platform } from 'react-native';
import { Colors, ApplicationStyles, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  background: {
    flex: 1,
  },
  backgroundRuncard: {
    height: 93,
    width: 93,
  },
  button: {
    alignItems: 'center',
    paddingTop: 15,
  },
  runcardButton: {
    marginLeft: 20,
    marginRight: 20,
  },
  buttonContainer: {
    alignItems: 'center',
    flex: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 10,
    width: 40,
  },
  buttonContainerBig: {

  },
  buttonContainerRuncard: {
    alignItems: 'center',
    width: 93,
  },
  buttonText: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.grayMedium,
    fontSize: Fonts.size.small,
    paddingTop: 2,
    textAlign: 'center',
  },
  buttonTextActive: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.blue2,
    fontSize: Fonts.size.small,
  },
  buttonWrapperAbsolute: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
  },
  buttonWrapper: {
    flexDirection: 'row',
  },
  iconContainer: {
    alignItems: 'center',
    height: 31,
    justifyContent: 'space-around',
    width: 31,
  },
  iconContainerBig: {

  },
  iconContainerRuncard: {
    borderRadius: 55,
    elevation: 8,
    marginTop: Platform.OS === 'ios' ? -7 : -5,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.40,
    shadowRadius: 2,
  },
  iconContainerRuncardActive: {
    shadowColor: Colors.blue2,
    shadowOpacity: 0.90,
    shadowRadius: 5,
  },
  whiteSpace: {
    width: 15,
  },
  wrapper: {
    bottom: 0,
    flex: 1,
    flexDirection: 'row',
    left: 0,
    justifyContent: 'space-between',
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    right: 0,
  },
};

const StyleLG = {
  button: {
    height: '100%',
    paddingTop: 15,
    paddingBottom: 15,
  },
  buttonContainer: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 0,
    width: 60,
  },
  buttonContainerBig: {
    paddingLeft: 10,
    paddingRight: 10,
    width: null,
  },
  buttonContainerRuncard: {
    flex: 1,
    paddingLeft: 5,
    paddingRight: 5,
    width: null,
  },
  buttonText: {
    color: Colors.white,
    fontSize: Fonts.size.small-2,
    paddingTop: 5,
    textTransform: 'uppercase',
  },
  buttonTextActive: {
    color: Colors.white,
    fontSize: Fonts.size.small-2,
  },
  iconContainer: {
    backgroundColor: Colors.white,
    borderRadius: 46,
    height: 46,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 0.5 },
    shadowOpacity: 0.20,
    shadowRadius: 4,
    width: 46,
  },
  iconContainerBig: {
    borderRadius: 45,
    height: 90,
    width: 90,
  },
  iconContainerRuncard: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderRadius: 45,
    height: 45,
    justifyContent: 'space-around',
    marginLeft: 5,
    marginRight: 5,
    marginTop: 0,
    shadowOffset: { width: 0, height: 0.5 },
    shadowRadius: 4,
    width: 45,
  },
  iconContainerRuncardActive: {
    shadowColor: Colors.blue2,
    shadowOpacity: 0.90,
    shadowRadius: 5,
  },
  wrapper: {
    justifyContent: 'center',
    position: 'static',
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
