import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, Platform } from 'react-native';
import { Link } from '../';

const Button = ({ style, onPress, children, disabled=false, route }) => {
  return (
    <>
      {(route && !disabled)
        ? (
          <Link route={route} style={style} onPress={onPress}>
            {children}
          </Link>
        ) : (
          Platform.OS === 'web'
            ? (
              <TouchableOpacity onClick={() => !disabled && onPress ? onPress() : null} style={style} disabled={disabled}>
                {children}
              </TouchableOpacity>
            )
            : (
              <TouchableOpacity onPress={() => !disabled && onPress ? onPress() : null} style={style} disabled={disabled}>
                {children}
              </TouchableOpacity>
            )
        )
      }
    </>
  )
};

Button.propTypes = {
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
  onPress: PropTypes.func,
  route: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default Button;
