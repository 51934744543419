import { ApplicationStyles, Colors, Fonts } from '../../../Theme';
import ResponsiveHelper from '../../../Services/ResponsiveHelper';

const BaseStyle = {
  accordionItem: {
    borderBottomWidth: 1,
    borderColor: Colors.footerRowGray,
    width: '100%',
  },
  accordionTitle: {
    ...ApplicationStyles.screen.fonts,
    textAlign: 'left',
  },
  appButton: {
    height: 49*0.8,
    width: 182*0.8,
    marginHorizontal: 10,
  },
  buttons: {
    marginVertical: 10,
    flexDirection: 'row',
    flex: 1,
  },
  container: {
    ...ApplicationStyles.screen.webContainer,
    alignItems: 'center',
    paddingBottom: 20,
    paddingTop: 30,
    width: '100%',
  },
  copyContainer: {
    alignItems: 'flex-end',
    flexDirection: 'row',
    marginTop: 20,
  },
  copyText: {
    ...ApplicationStyles.screen.fontsRegular,
    textAlign: 'center',
  },
  fidalLogo: {
    height: 44,
    marginBottom: 3,
    marginLeft: 10,
    width: 88,
  },
  marginBottom: {
    marginBottom: 10,
  },
  menuBlockLink: {
    paddingBottom: 5,
  },
  menuBlockLinkText: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.white,
    fontSize: Fonts.size.regular-2,
  },
  menuBlockTitle: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.regular-2,
    paddingBottom: 5,
  },
  menuContainer: {
    alignContent: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  topContainer: {
    flexDirection:'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  wrapper: {
    ...ApplicationStyles.screen.paddingFooter,
    backgroundColor: Colors.darkGray,
  },
};

const StyleLG = {
  appButton: {
    height: 49,
    width: 182,
    marginHorizontal: 0,
  },
  container: {
    paddingBottom: 40,
    paddingTop: 40,
  },
  wrapper: {
    paddingBottom: 0,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
