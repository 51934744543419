import React from 'react';
import { View } from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import { useHistory } from 'react-router-native';

import { getResponsive } from './HomeStyle';
import { Footer, PageHeader } from '../../../Layout';
import { Colors } from '../../../../Theme';
import { BackIcon } from '../../../../Theme/Icons';
import { Button } from '../../../Buttons';
import EditorialSlider from '../Editorial/Slider';
import UserSlider from '../User/Slider';

import { isDesktop } from '../../../../Services/ResponsiveHelper';
import { getPath } from '../../../../Router/AppRoutes';

const Home = () => {
  let history = useHistory();
  const Style = getResponsive();

  return (
    <View style={Style.container}>
      <PageHeader title={'Sfide'} desc={'Supera i tuoi limiti'} back={false} left={<Button onPress={() => history.push(getPath('play'))}><BackIcon /></Button>} />
      <View style={Style.wrapper}>
        <View style={[Style.section, Style.sectionEditorial ]}>
          {isDesktop()
            ? (
              <LinearGradient colors={[Colors.darkBlue, Colors.blue99999]} locations={[0,1]} style={Style.editorialGradient}>
                <View style={Style.webContainer}>
                  <EditorialSlider />
                </View>
              </LinearGradient>
            ) : <EditorialSlider />}
        </View>
        <View style={Style.webContainer}>
          <View style={Style.separator} />
        </View>
        <View style={[Style.section, Style.sectionUser ]}>
          <View style={Style.webContainer}>
            <UserSlider />
          </View>
        </View>
      </View>
      <Footer />
    </View>
  );
};

export default Home;
