import React from 'react';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';

import { getResponsive } from './WorkoutStyle';
import { CalendarIcon, ShoeIcon, ChronometerIcon, SpeedMeterIcon, CaloriesIcon } from '../../../Theme/Icons';

export default function Header() {
  const Style = getResponsive();

  const HeaderItem = ({ icon, text, size }) => (
    <View style={[Style.headerItem, size ? { flex: size } : {}]}>
      {icon}
      <Text style={Style.headerItemText}>{text}</Text>
    </View>
  );

  HeaderItem.propTypes = {
    icon: PropTypes.node.isRequired,
    text: PropTypes.string.isRequired,
    size: PropTypes.number,
  };

  return (
    <View style={Style.headerContainer}>
      <View style={Style.headerTable}>
        <HeaderItem icon={<CalendarIcon />} text={'Data'} />
        <HeaderItem icon={<ShoeIcon />} text={'Distanza'} size={1.3} />
        <HeaderItem icon={<ChronometerIcon />} text={'Tempo'} size={1.4} />
        <HeaderItem icon={<SpeedMeterIcon />} text={'Passo'} size={1.3} />
        <HeaderItem icon={<CaloriesIcon />} text={'Calorie'} />
      </View>
    </View>
  )
};
