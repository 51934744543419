import React from 'react';
import PropTypes from 'prop-types';
import { Link as BrowserLink } from 'react-router-dom';
import { StyleSheet } from 'react-native';

import { getPath } from '../../../Router/AppRoutes';

const Link = (props) => {
  const { onPress, style, route, replace, href, ...restProps } = props;

  const styles = {
    textDecorationLine: 'none',
    ...StyleSheet.flatten(style),
  };

  return route
    ? <BrowserLink replace={replace} className={`button ${styles.hasOwnProperty('shadowOffset') ? 'shadow-button' : ''}`} to={route ? getPath(route) : null} onClick={onPress} {...restProps} style={styles} />
    : <a rel="noopener noreferrer" target={'_blank'} href={href} onClick={onPress} {...restProps} style={styles}>{props.children}</a>;
};

Link.propTypes = {
  onPress: PropTypes.func,
  route: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.oneOfType([ PropTypes.object, PropTypes.number, PropTypes.array ]),
  replace: PropTypes.bool,
};

export default Link;
