import { put, call, select } from "redux-saga/effects";

import NewsActions from "../Stores/News/Actions";
import LayoutActions from "../Stores/Layout/Actions";
import { fetchNews, fetchSingleNews } from "../Services/ApiGatewayService";

export function* getNews({ category, from }) {
  yield put(NewsActions.setNewsError(false));
  try {
    let news = yield call(fetchNews, { category, from });

    if (news.length === 10) {
      yield put(NewsActions.setNewsLoadMore(category, true));
    } else {
      yield put(NewsActions.setNewsLoadMore(category, false));
    }

    const previousNews = yield select((state) => state.news.news);
    if (previousNews && previousNews[category]) {
      // Merge and sort
      news = [...previousNews[category], ...news]
        .reduce((acc, el) => {
          if (acc.findIndex((ac) => ac.id === el.id) === -1)
            return [...acc, el];
          else return acc;
        }, [])
        .sort((a, b) => parseInt(b.date) - parseInt(a.date));
    }
    yield put(NewsActions.setNews({ [category]: news }));
  } catch (err) {
    console.log("getNews error", err);
    yield put(NewsActions.setNewsError(true));
  }
}

export function* getSingleNews({ uuid }) {
  yield put(LayoutActions.setLoading(true));
  yield put(NewsActions.setSingleNewsError(false));
  try {
    let news = yield call(fetchSingleNews, { uuid });
    yield put(NewsActions.setSingleNews(news));
  } catch (err) {
    console.log("getSingleNews error", err);
    yield put(NewsActions.setSingleNewsError(true));
  }
  yield put(LayoutActions.setLoading(false));
}
