import { StyleSheet } from 'react-native';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

export default StyleSheet.create({
  button: {
    borderRadius: 30,
    elevation: 8,
    shadowColor: Colors.black,
    shadowOffset: { width: 2, height: 5 },
    shadowOpacity: 0.15,
    shadowRadius: 5,
    width: '100%',
  },
  buttonDisabled: {
    opacity: 0.8
  },
  gradient: {
    alignItems: 'center',
    backgroundColor: Colors.blue1,
    borderRadius: 30,
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  text: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.regular,
    paddingLeft: 20,
    paddingRight: 20,
    textAlign: 'center',
  },
  xSmallButton: {
    width: 'auto',
  },
  xSmallText: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.white,
    fontSize: Fonts.size.regular-1,
    paddingLeft: 25,
    paddingRight: 25,
  },
});
