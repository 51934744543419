import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { CattolicaTypes } from './Actions'

export const setQuotation = (state, { quotation }) => ({
  ...state,
  quotation,
});

export const setEvents = (state, { events }) => ({
  ...state,
  events,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CattolicaTypes.SET_QUOTATION]: setQuotation,
  [CattolicaTypes.SET_EVENTS]: setEvents,
});
