import React from "react";
import {
  SafeAreaView,
  ScrollView,
  Switch,
  Text,
  TextInput,
  View,
  Platform,
} from "react-native";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Config } from "../../../Config";

import Style from "../RegistrationForm/RegistrationFormStyle";
import { PrimaryButton, ToggleButton, Button, Link } from "../../Buttons";
import { Conditions, CookiePolicy, PrivacyPolicy } from "../../Legals";
import { Modal } from "../../Layout";
import { DatePicker } from "../../Forms";
import { BackIcon, CloseIcon } from "../../../Theme/Icons";
import AvatarUpload from "../../AvatarUpload/AvatarUpload";
import { Colors } from "../../../Theme";

import RegistrationActions from "../../../Stores/Registration/Actions";
import AuthActions from "../../../Stores/Auth/Actions";
import { isDesktop } from "../../../Services/ResponsiveHelper";

const RegistrationComplete = ({
  completeRegistration,
  error,
  data,
  logout,
}) => {
  let listView;

  const [popup, setPopup] = React.useState(false);

  const [avatar, setAvatar] = React.useState(data.picture || "");
  const [name, setName] = React.useState(data.given_name || "");
  const [surname, setSurname] = React.useState(data.family_name || "");
  const [nickname, setNickname] = React.useState(data.nickname || "");
  const [location, setLocation] = React.useState(data.city || "");
  const [birthDate, setBirthDate] = React.useState(
    data.birthdate && data.birthdate !== "" ? new Date(data.birthdate) : ""
  );
  const [gender, setGender] = React.useState(data.gender || "");
  const [email, setEmail] = React.useState(data.email);
  const [acceptConditions, setAcceptConditions] = React.useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = React.useState(false);
  const [consent1, setConsent1] = React.useState(false);
  const [consent2, setConsent2] = React.useState(null);
  const [consent3, setConsent3] = React.useState(null);
  const [formError, setFormError] = React.useState("");

  const submitRegistration = () => {
    if (listView) {
      listView.scrollTo({ y: 0 });
    }

    if (!name.length) {
      setFormError("Nome è un campo obbligatorio");
      return false;
    }
    if (!surname.length) {
      setFormError("Cognome è un campo obbligatorio");
      return false;
    }
    if (!nickname.length) {
      setFormError("Nickname è un campo obbligatorio");
      return false;
    }
    if (!location.length) {
      setFormError("Località è un campo obbligatorio");
      return false;
    }
    if (birthDate === "") {
      setFormError("Data di nascita è un campo obbligatorio");
      return false;
    }
    if (!moment(birthDate)) {
      setFormError("Formato della data di nascita non valido");
      return false;
    }
    if (
      moment(birthDate).isAfter(
        moment()
          .subtract(Config.MIN_AGE, "years")
          .toDate()
      )
    ) {
      setFormError(`Devi avere almeno ${Config.MIN_AGE} anni per registrarti`);
      return false;
    }
    if (!gender.length) {
      setFormError("Genere è un campo obbligatorio");
      return false;
    }
    if (!email.length) {
      setFormError("Email è un campo obbligatorio");
      return false;
    }
    if (!acceptConditions) {
      setFormError("Accetta i Termini e Condizioni d'uso");
      return false;
    }
    if (!acceptPrivacy) {
      setFormError("Accetta la Privacy Policy");
      return false;
    }

    if (consent1 === null || consent2 === null || consent3 === null) {
      setFormError(
        "Per proseguire è necessario selezionare il consenso al trattamento dei dati"
      );
      return false;
    }

    setFormError("");

    completeRegistration({
      name,
      surname,
      nickname,
      location,
      birthDate: moment(birthDate).format(Config.DATE_FORMAT.storage),
      gender,
      email,
      avatar,
      consent1,
      consent2,
      consent3,
    });
  };

  return (
    <View style={[Style.wrapper, isDesktop() ? Style.wrapperBackground : {}]}>
      <SafeAreaView style={Style.wrapper}>
        <ScrollView
          ref={(ref) => (listView = ref)}
          bounces={false}
          className={"scroll-view"}
        >
          <View style={isDesktop() ? Style.containerWeb : {}}>
            <Link route={"logout"} style={Style.backButton}>
              <CloseIcon color={Colors.grayLight} />
            </Link>
            <View style={Style.container}>
              <Text style={Style.h1}>Completa il tuo account!</Text>
              <View style={Style.avatarContainer}>
                <AvatarUpload
                  avatar={avatar}
                  onUpload={setAvatar}
                  onReset={() => setAvatar(null)}
                />
              </View>
              {error && <Text style={Style.error}>{error}</Text>}
              {!!formError.length && (
                <Text style={Style.error}>{formError}</Text>
              )}
              <TextInput
                style={Style.textInput}
                textContentType={"givenName"}
                autoCorrect={false}
                placeholder={"Nome"}
                value={name}
                onChangeText={setName}
              />
              <TextInput
                style={Style.textInput}
                textContentType={"familyName"}
                autoCorrect={false}
                placeholder={"Cognome"}
                value={surname}
                onChangeText={setSurname}
              />
              <TextInput
                style={Style.textInput}
                textContentType={"nickname"}
                autoCorrect={false}
                placeholder={"Nickname"}
                value={nickname}
                onChangeText={setNickname}
              />
              <TextInput
                style={Style.textInput}
                textContentType={"addressCity"}
                placeholder={"Luogo di residenza"}
                value={location}
                onChangeText={setLocation}
              />
              <DatePicker
                onChange={setBirthDate}
                value={birthDate}
                maxDate={moment()
                  .subtract(Config.MIN_AGE, "years")
                  .toDate()}
                placeholder={"Data di nascita"}
              />
              <View style={Style.genderButtons}>
                <ToggleButton
                  value={"M"}
                  selected={gender === "M"}
                  text={"uomo"}
                  checkHandler={setGender}
                />
                <ToggleButton
                  value={"F"}
                  selected={gender === "F"}
                  text={"donna"}
                  checkHandler={setGender}
                />
              </View>
              <TextInput
                style={Style.textInput}
                textContentType={"emailAddress"}
                autoCorrect={false}
                keyboardType={"email-address"}
                autoCapitalize={"none"}
                placeholder={"Email"}
                value={email}
                onChangeText={setEmail}
                editable={false}
              />
              <View style={Style.conditionsWrapper}>
                <Switch
                  value={acceptConditions}
                  onValueChange={setAcceptConditions}
                />
                {Platform.OS === "web" ? (
                  <Link
                    route={"conditions"}
                    target={"_blank"}
                    style={Style.conditionsButton}
                  >
                    <Text style={Style.conditionsText}>
                      Visualizza e accetta i{" "}
                      <Text style={Style.conditionsLink}>
                        Termini e le Condizioni d&apos;uso
                      </Text>
                    </Text>
                  </Link>
                ) : (
                  <Button
                    onPress={() => setPopup(<Conditions />)}
                    style={Style.conditionsButton}
                  >
                    <Text style={Style.conditionsText}>
                      Visualizza e accetta i{" "}
                      <Text style={Style.conditionsLink}>
                        Termini e le Condizioni d&apos;uso
                      </Text>
                    </Text>
                  </Button>
                )}
              </View>
              <View style={Style.conditionsWrapper}>
                <Switch
                  value={acceptPrivacy}
                  onValueChange={setAcceptPrivacy}
                />
                <View style={Style.conditionsButton}>
                  {Platform.OS === "web" ? (
                    <Text style={Style.conditionsText}>
                      Visualizza e accetta la{" "}
                      <Link
                        route={"privacy"}
                        target={"_blank"}
                        style={Style.conditionsLink}
                      >
                        Privacy Policy
                      </Link>{" "}
                      e la{" "}
                      <Link
                        route={"cookie"}
                        target={"_blank"}
                        style={Style.conditionsLink}
                      >
                        Cookie Policy
                      </Link>
                    </Text>
                  ) : (
                    <Text style={Style.conditionsText}>
                      Visualizza e accetta la{" "}
                      <Text
                        onPress={() => setPopup(<PrivacyPolicy />)}
                        style={Style.conditionsLink}
                      >
                        Privacy Policy
                      </Text>{" "}
                      e la{" "}
                      <Text
                        onPress={() => setPopup(<CookiePolicy />)}
                        style={Style.conditionsLink}
                      >
                        Cookie Policy
                      </Text>
                    </Text>
                  )}
                </View>
              </View>
              <Text style={Style.switchTitle}>
                Dopo aver preso visione dell&apos;Informativa Privacy dichiaro:
              </Text>
              {/* <View style={Style.consent}>
                <Text style={Style.switchText}>Riguardo all’utilizzo dei miei dati per finalità di profilazione al fine di personalizzare l&apos;esperienza di utilizzo della piattaforma, ricevere materiale informativo e comunicazioni commerciali dedicate da parte di Infront Italy in relazione a prodotti e servizi di Infront Italy o dei suoi partner</Text>
                <View style={Style.switchContainerPrivacy}>
                  <Switch value={consent1 === true} onValueChange={setConsent1} />
                  <Button onPress={() => setConsent1(true)} style={Style.switchInner}>
                    <Text style={Style.switchText}>Presto il consenso</Text>
                  </Button>
                </View>
                <View style={Style.switchContainerPrivacy}>
                  <Switch value={consent1 === false} onValueChange={(data) => setConsent1(!data)} />
                  <Button onPress={() => setConsent1(false)} style={Style.switchInner}>
                    <Text style={Style.switchText}>NON presto il consenso</Text>
                  </Button>
                </View>
              </View> */}
              <View style={Style.consent}>
                <Text style={Style.switchText}>
                  Riguardo all’utilizzo dei miei dati per finalità di
                  profilazione al fine di personalizzare l&apos;esperienza di
                  utilizzo della piattaforma, ricevere materiale informativo e
                  comunicazioni commerciali dedicate da parte di FIDAL in
                  relazione a prodotti e servizi di Fidal o dei suoi partner
                </Text>
                <View style={Style.switchContainerPrivacy}>
                  <Switch
                    value={consent2 === true}
                    onValueChange={setConsent2}
                  />
                  <Button
                    onPress={() => setConsent2(true)}
                    style={Style.switchInner}
                  >
                    <Text style={Style.switchText}>Presto il consenso</Text>
                  </Button>
                </View>
                <View style={Style.switchContainerPrivacy}>
                  <Switch
                    value={consent2 === false}
                    onValueChange={(data) => setConsent2(!data)}
                  />
                  <Button
                    onPress={() => setConsent2(false)}
                    style={Style.switchInner}
                  >
                    <Text style={Style.switchText}>NON presto il consenso</Text>
                  </Button>
                </View>
              </View>
              <View style={Style.consent}>
                <Text style={Style.switchText}>
                  Riguardo alla comunicazione dei miei dati a società terze e al
                  relativo trattamento dei miei dati per la ricezione o la
                  visualizzazione di materiali informativi e offerte commerciali
                  relative ai prodotti e servizi inerenti alle mie aree di
                  interesse
                </Text>
                <View style={Style.switchContainerPrivacy}>
                  <Switch
                    value={consent3 === true}
                    onValueChange={setConsent3}
                  />
                  <Button
                    onPress={() => setConsent3(true)}
                    style={Style.switchInner}
                  >
                    <Text style={Style.switchText}>Presto il consenso</Text>
                  </Button>
                </View>
                <View style={Style.switchContainerPrivacy}>
                  <Switch
                    value={consent3 === false}
                    onValueChange={(data) => setConsent3(!data)}
                  />
                  <Button
                    onPress={() => setConsent3(false)}
                    style={Style.switchInner}
                  >
                    <Text style={Style.switchText}>NON presto il consenso</Text>
                  </Button>
                </View>
              </View>
              <PrimaryButton
                onPress={submitRegistration}
                style={Style.registerButton}
              >
                <Text>COMPLETA REGISTRAZIONE</Text>
              </PrimaryButton>
              <View style={Style.sectionWrapper}>
                <Button
                  onPress={() => {
                    logout("Login");
                  }}
                  style={Style.loginLink}
                >
                  <Text style={Style.loginText}>
                    Hai già un account?&nbsp;
                    <Text style={Style.login}>LOGIN</Text>
                  </Text>
                </Button>
              </View>
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
      {popup && (
        <Modal
          animationType={"slide"}
          transparent={true}
          onRequestClose={() => setPopup(false)}
        >
          <SafeAreaView style={Style.modal}>
            <View style={Style.closeContainer}>
              <Button onPress={() => setPopup(false)}>
                <BackIcon />
              </Button>
            </View>
            <View style={Style.modalContainer}>{popup}</View>
          </SafeAreaView>
        </Modal>
      )}
    </View>
  );
};

RegistrationComplete.propTypes = {
  logout: PropTypes.func,
  completeRegistration: PropTypes.func,
  error: PropTypes.string,
  data: PropTypes.object,
};

const mapStateToProps = (state) => ({
  error: state.registration.error,
  data: state.registration.data,
});

const mapDispatchToProps = (dispatch) => ({
  completeRegistration: (data) =>
    dispatch(RegistrationActions.completeRegistration(data)),
  logout: (data) => dispatch(AuthActions.logout(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationComplete);
