import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { View, Platform } from "react-native";
import { Switch, Route } from "react-router-native";
import { Config } from "../../Config";

import { ApplicationStyles } from "../../Theme";
import { getResponsive } from "../../Components/News/News/NewsStyle";
import { Adv, Footer, PageHeader, TabMenu } from "../../Components/Layout";
import { List } from "../../Components/News";
import { AdvPopup } from "../../Components/Popup";

import LayoutActions from "../../Stores/Layout/Actions";
import HelperActions from "../../Stores/Helper/Actions";
import Analytics from "../../Services/AnalyticsService";
import { getAdv } from "../../Services/ADVService";
import { RouteWithSubRoutes } from "../../Router/Router";

const News = ({ routes }) => {
  const dispatch = useDispatch();
  const Style = getResponsive();

  // ADV
  const tab = useSelector((state) => state.layout.tab);
  const options = useSelector((state) => state.helper.options);

  const [category, selectCategory] = React.useState("TopNews");
  const [adv, setAdv] = React.useState(false);

  React.useEffect(() => {
    if (tab !== "NEWS") {
      if (Platform.OS !== "web")
        setAdv(getAdv(options ? options.Advertising : null, "popup", "news"));
    }
    dispatch(LayoutActions.setTab("NEWS"));
  }, []);

  React.useEffect(() => {
    Analytics.getInstance().trackNewsView();
  }, []);

  const tabs = Object.keys(Config.NEWS.CATEGORIES).reduce((acc, id) => {
    acc[id] = {
      label: Config.NEWS.CATEGORIES[id],
      callback: () => {
        selectCategory(id);
        dispatch(
          HelperActions.sendCRMEvent(
            `event_open_news_${id.toLowerCase().replace(/ /gi, "_")}`
          )
        );
      },
    };
    return acc;
  }, {});

  return (
    <View style={{ ...ApplicationStyles.screen.container }}>
      <Switch>
        {routes.map((route) => (
          <RouteWithSubRoutes key={route.name} {...route} />
        ))}
        <Route path="*">
          <PageHeader
            back={false}
            title={"News"}
            desc={"Scopri le ultime novità"}
          />
          <View style={Style.innerContainer}>
            <TabMenu tabs={tabs} currentTab={category} />
            <List category={category} />
          </View>
          <Footer />
          {adv && (
            <AdvPopup closeHandler={() => setAdv(false)} width={250}>
              <Adv adv={adv} />
            </AdvPopup>
          )}
        </Route>
      </Switch>
    </View>
  );
};

News.propTypes = {
  routes: PropTypes.array,
};

export default News;
