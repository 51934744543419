import ResponsiveHelper from '../../../Services/ResponsiveHelper';
import { ApplicationStyles, Colors, Fonts } from '../../../Theme';

const BaseStyle = {
  adv: {
    marginBottom: 20,
  },
  advColumn: { },
  box: {
    alignItems: 'center',
    backgroundColor: Colors.white,
    marginBottom: 20,
    marginHorizontal: 10,
    padding: 40,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
  },
  boxContainer: {
    marginHorizontal: -10,
    paddingHorizontal: 15,
  },
  boxH1: {
    ...ApplicationStyles.screen.h1,
    color: Colors.gray,
  },
  boxH2: {
    ...ApplicationStyles.screen.h2,
    color: Colors.gray,
  },
  boxText: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small+2,
    textAlign: 'center',
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginBottom10: {
    marginBottom: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  textInput: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.gray,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 3,
    width: '100%',
    fontSize: Fonts.size.regular-2,
    marginBottom: 10,
    paddingBottom: 5,
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 30,
  },
  switchWrapper: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    marginBottom: 10,
    marginTop: 0,
  },
  switchText: {
    alignSelf: 'center',
    flex: 1,
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular-1,
    marginLeft: 10,
  },
};

const StyleLG = {
  advColumn: {
    width: 300,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
