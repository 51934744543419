import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const ShareIcon = ({ size=25, color=false }) => (
  <Svg width={size} height={size} viewBox={'0 0 25 27'}>
    <Defs>
      <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={'gradientShare'}>
        <Stop stopColor={'#02AFF1'} offset="0%" />
        <Stop stopColor={'#0D85F1'} offset="100%" />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} fill={'none'} fillRule={'evenodd'}>
      <G fill={color ? color : 'url(#gradientShare)'} fillRule={'nonzero'}>
        <Path d={'M19.6497555,17.066468 C18.16988,17.066468 16.8502797,17.7945785 16.006163,18.9198401 L8.93802093,14.8435247 C9.10363877,14.3526017 9.19446145,13.8230669 9.19446145,13.276984 C9.19446145,12.7253852 9.10363877,12.2013663 8.93267841,11.7049273 L15.995478,7.63412791 C16.8342522,8.76490552 18.1591949,9.49853198 19.644413,9.49853198 C22.1767632,9.49853198 24.244315,7.36936047 24.244315,4.74926599 C24.244315,2.12917151 22.1821057,0 19.644413,0 C17.1067203,0 15.044511,2.12917151 15.044511,4.74926599 C15.044511,5.30086483 15.1353337,5.83039971 15.3062941,6.32132267 L8.248837,10.3921221 C7.41006278,9.25582849 6.08512004,8.52771802 4.59990198,8.52771802 C2.06755176,8.52771802 0,10.6568895 0,13.276984 C0,15.8970785 2.06755176,18.02625 4.60524449,18.02625 C6.09046256,18.02625 7.41540529,17.2926235 8.25952203,16.1563299 L15.3223216,20.2326453 C15.1513612,20.7290843 15.055196,21.2641352 15.055196,21.815734 C15.055196,24.4303125 17.1174053,26.565 19.655098,26.565 C22.1927907,26.565 24.255,24.4358285 24.255,21.815734 C24.255,19.1956395 22.1874482,17.066468 19.6497555,17.066468 Z M19.6497555,1.49483285 C21.3914141,1.49483285 22.8071795,2.95656977 22.8071795,4.75478198 C22.8071795,6.55299419 21.3914141,8.0147311 19.6497555,8.0147311 C17.9080969,8.0147311 16.4923315,6.55299419 16.4923315,4.75478198 C16.4923315,2.95656977 17.9134394,1.49483285 19.6497555,1.49483285 Z M4.60524449,16.5369331 C2.8635859,16.5369331 1.44782048,15.0751962 1.44782048,13.276984 C1.44782048,11.4787718 2.8635859,10.0170349 4.60524449,10.0170349 C6.34690308,10.0170349 7.7626685,11.4787718 7.7626685,13.276984 C7.7626685,15.0751962 6.34156057,16.5369331 4.60524449,16.5369331 Z M19.6497555,25.0701672 C17.9080969,25.0701672 16.4923315,23.6084302 16.4923315,21.810218 C16.4923315,20.0120058 17.9080969,18.5502689 19.6497555,18.5502689 C21.3914141,18.5502689 22.8071795,20.0120058 22.8071795,21.810218 C22.8071795,23.6084302 21.3914141,25.0701672 19.6497555,25.0701672 Z'} />
      </G>
    </G>
  </Svg>
);

ShareIcon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default ShareIcon;
