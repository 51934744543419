import { ApplicationStyles, Colors, Fonts } from '../../../../Theme';
import ResponsiveHelper from '../../../../Services/ResponsiveHelper';
import { Platform } from 'react-native';

const BaseStyle = {
  container: {
    flexGrow: 1,
  },
  endReachedText: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.grayLight,
    fontSize: Fonts.size.small,
    paddingTop: 10,
  },
  error: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular,
    paddingVertical: 30,
    textAlign: 'center',
  },
  joinedIcon: {
    position:'absolute',
    right: 10,
    top: 10,
  },
  listFooter: {
    ...ApplicationStyles.screen.paddingTabBar,
    alignItems:'center',
    marginBottom: 20,
    paddingTop: 20,
  },
  listItem: {
    backgroundColor: Colors.white,
    elevation: 2,
    flex: 1,
    marginBottom: 10,
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.15,
    shadowRadius: 2,
  },
  listItemImage : {
    flex: 1,
    minHeight: 100,
  },
  listItemTextContainer: {
    alignItems:'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingVertical: 20,
  },
  listItemTextDesc: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.small,
    textTransform: 'uppercase',
  },
  listItemTextTitle: {
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.small+2,
    textTransform: 'uppercase',
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
  },
  pageTitle: {
    ...ApplicationStyles.screen.fonts,
    ...Fonts.style.h1,
    color: Colors.gray,
    textAlign: 'center',
    marginVertical: 30,
  },
  topListItem: {
    backgroundColor: Colors.white,
    borderRadius: 10,
    elevation: 7,
    height: '100%',
    minWidth: Platform.OS !== 'web' ? 250 : 50,
    marginVertical: 10,
    marginHorizontal: 10,
    shadowColor: Colors.black,
    shadowOffset: { width: 4.5, height: 4.5 },
    shadowOpacity: 0.1,
    shadowRadius: 10,
  },
  topListItemButton: {
    flex: 1,
  },
  topListItemImage : {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    flex: 1,
    ...Platform.select({
      web: {
        minHeight: 100,
      }
    })
  },
  topListItemText: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  topListItemTextDesc: {
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.darkBlueText,
    fontSize: Fonts.size.small,
    textTransform: 'uppercase',
  },
  topListItemTextTitle: {
    ...ApplicationStyles.screen.fontsBold,
    color: Colors.darkBlueText,
    fontSize: Fonts.size.small+2,
    textTransform: 'uppercase',
  },
  topListWrapper: {
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
    paddingBottom: 10,
  },
  topTitle: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 30,
    paddingVertical: 10,
  },
  topTitleLink: {
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.blue1,
    fontSize: Fonts.size.regular+4,
    paddingVertical: 10,
  },
  topTitleText: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.grayMedium3,
    fontSize: Fonts.size.regular+2,
  },
  topWrapper: {
    flexGrow: 1,
  },
  webContainer: {
    flex: 1,
  },
  wrapper: {
    flex: 1,
  },
};

const StyleLG = {
  joinedIcon: {
    right: 20,
  },
  listItem: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 20,
  },
  listItemImage : {
    minHeight: 200,
  },
  listItemTextTitle: {
    fontSize: Fonts.size.regular+2,
  },
  listItemTextDesc: {
    fontSize: Fonts.size.regular+2,
  },
  topTitle: {
    marginTop: 50,
    marginBottom: 20,
  },
  topTitleText: {
    color: Colors.white,
    textAlign: 'center',
    fontSize: Fonts.size.h1,
    flex: 1,
  },
  topTitleLink: {
    color: Colors.white,
    position: 'absolute',
    right: 30,
    top: 0,
    bottom: 0,
  },
  topListItemImage : {
    minHeight: 150,
  },
  webContainer: {
    ...ApplicationStyles.screen.webContainer,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
