import React from "react";
import PropTypes from "prop-types";
import {
  ActivityIndicator,
  Text,
  View,
  Linking,
  SafeAreaView,
  TextInput,
  ScrollView,
  Platform,
  Image,
} from "react-native";
import Clipboard from "@react-native-clipboard/clipboard";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { PubSub } from "aws-amplify";
import { Config } from "../../../Config";

import { getResponsive } from "./HomeStyle";
import {
  RightArrow,
  CheckIcon,
  EditIcon,
  CloseIcon,
  InfoIcon,
} from "../../../Theme/Icons";
import { Colors } from "../../../Theme";
import { Footer, Modal, ModalDownload, PageHeader } from "../../Layout";
import { Button, PrimaryButton } from "../../Buttons";
import { Popup } from "../../Popup";
import AvatarUpload from "../../AvatarUpload/AvatarUpload";
import { Conditions, PrivacyPolicy } from "../../Legals";

import RuncardActions from "../../../Stores/Runcard/Actions";
import AuthActions from "../../../Stores/Auth/Actions";
import RegistrationActions from "../../../Stores/Registration/Actions";
import S3Actions from "../../../Stores/S3/Actions";
import LayoutActions from "../../../Stores/Layout/Actions";
import HelperActions from "../../../Stores/Helper/Actions";
import { isDesktop } from "../../../Services/ResponsiveHelper";
import CertificateContent from "../Certificate/CertificateContent";

const Home = () => {
  let subscribe;

  const Style = getResponsive();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const runcard = useSelector((state) => state.runcard.runcard);
  const runcardError = useSelector((state) => state.runcard.error);
  const leaderboardLevel = useSelector(
    (state) => state.helper.leaderboardLevel
  );

  const [contactPopUp, setContactPopUp] = React.useState(false);
  const [editNickname, setEditNickname] = React.useState(false);
  const [nickname, setNickname] = React.useState(user.nickname);
  const [activeComponent, setActiveComponent] = React.useState(
    isDesktop() ? "privacy" : null
  );
  const [info, setInfo] = React.useState(false);
  const [PDFOpen, setPDFOpen] = React.useState(null);

  const components = {
    certificate: (
      <>
        <Text style={Style.rightColumnTitle}>Visita medica</Text>
        <Button onPress={() => setInfo(true)} style={Style.info}>
          <InfoIcon color={Colors.blue1} />
          <Text style={Style.infoNote}>
            Il certificato medico valido è necessario per accedere alle gare
          </Text>
        </Button>
        <Text style={Style.rightColumnText}>
          Runcard ha attivato per te diverse convenzioni in collaborazione con
          la{" "}
          <Text style={Style.bold}>Federazione Italiana Medico Sportiva</Text>.
        </Text>
        <Text style={Style.rightColumnCTA}>SCOPRILE SUBITO!</Text>
        <CertificateContent openPdf={() => { }} />
      </>
    ),
    privacy: (
      <>
        <PrivacyPolicy fullHeight={true} />
      </>
    ),
    conditions: (
      <>
        <Conditions fullHeight={true} />
      </>
    ),
  };

  const updateUser = (data) => dispatch(RegistrationActions.updateUser(data));

  const deleteAvatar = () => {
    if (user.picture.indexOf(Config.AWS.Storage.AWSS3.bucket) !== -1)
      dispatch(S3Actions.S3_delete(user.picture));
    dispatch(RegistrationActions.updateUser({ picture: null }));
  };

  React.useEffect(() => {
    if (user.runcard && user.runcard !== "" && !runcard) {
      dispatch(RuncardActions.getRuncard(false));
    }
    if (leaderboardLevel === null) {
      dispatch(HelperActions.getLeaderboardLevel());
    }
    return function cleanup() {
      if (timeout) clearTimeout(timeout);
      if (subscribe) subscribe.unsubscribe();
    };
  }, []);

  const mailUs = () => {
    try {
      Linking.openURL(
        `mailto:${Config.CONTACTS.address}?subject=${Config.CONTACTS.subject}`
      ).catch(() => {
        setContactPopUp(true);
      });
    } catch (err) {
      Clipboard.setString(Config.CONTACTS.address);
    }
  };

  const calllUs = () => {
    try {
      Linking.openURL(`tel:${Config.CONTACTS.telephone}`).catch(() => {
        setContactPopUp(true);
      });
    } catch (err) {
      Clipboard.setString(Config.CONTACTS.telephone);
    }
  };

  const MenuButton = ({ children, route, action }) => {
    return isDesktop() && Object.keys(components).indexOf(route) !== -1 ? (
      <Button
        onPress={
          action
            ? action
            : () => {
              setActiveComponent(route);
              if (route === "certificate")
                dispatch(
                  HelperActions.sendCRMEvent("event_open_certificate")
                );
            }
        }
      >
        <View style={Style.menuButton}>
          <View style={Style.menuButtonLink}>{children}</View>
          <RightArrow />
        </View>
      </Button>
    ) : (
      <Button
        route={route}
        onPress={
          action
            ? action
            : () => {
              if (route === "certificate")
                dispatch(
                  HelperActions.sendCRMEvent("event_open_certificate")
                );
            }
        }
      >
        <View style={Style.menuButton}>
          <View style={Style.menuButtonLink}>{children}</View>
          <RightArrow />
        </View>
      </Button>
    );
  };

  MenuButton.propTypes = {
    children: PropTypes.node.isRequired,
    route: PropTypes.string,
    action: PropTypes.func,
  };

  const openChangePassword = () => {
    if (isDesktop()) {
      dispatch(AuthActions.startChangePassword(true));
      dispatch(LayoutActions.toggleLoginModal(true));
    } else {
      dispatch(AuthActions.startChangePassword(true));
    }
  };

  let timeout;
  const generatePdfCertificate = () => {
    if (timeout) clearTimeout(timeout);
    if (runcard) {
      // Receive messages from PDF generated

      subscribe = PubSub.subscribe(
        `runcard/${Config.STAGE}/pdf/${runcard.runcard}`
      ).subscribe({
        next: (data) => {
          if (timeout) clearTimeout(timeout);
          dispatch(LayoutActions.setLoading(false));
          if (data.value.status === "OK") {
            const url = `${Config.CDN}/${data.value.data}`;
            if (Platform.OS === "ios") {
              setPDFOpen(url);
            } else if (Platform.OS === "web") {
              window.open(url, "");
            } else {
              Linking.openURL(url);
            }
          } else {
            dispatch(
              LayoutActions.setError({
                text:
                  "Non è stato possibile generare il PDF.\nTi preghiamo di riprovare più tardi.",
              })
            );
          }
          if (subscribe) subscribe.unsubscribe();
        },
        error: (err) => console.log("PubSub subscribe error", err),
      });

      dispatch(LayoutActions.setLoading(true));
      dispatch(HelperActions.generatePdf("certificate"));

      timeout = setTimeout(() => {
        dispatch(LayoutActions.setLoading(false));
        dispatch(
          LayoutActions.setError({
            text:
              "La generazione del PDF sta impiegando troppo tempo.\nTi preghiamo di riprovare più tardi.",
          })
        );
      }, 60000); // 1 minute
    }
  };

  const updateAvatar = (picture) => {
    updateUser({ picture });
    dispatch(HelperActions.sendLeaderboardEvent("change-profile-image"));
  };

  console.log(
    "0user.runcard && runcard && !runcardError",
    user.runcard,
    runcard,
    !runcardError
  );
  console.log(
    "moment(runcard.expire_at).isAfter(moment()) && moment(runcard.expire_at).diff(moment(), days) > Config.DAYS_BEFORE_RENEW",
    runcard && runcard.expire_at !== null
      ? moment(runcard.expire_at).isAfter(moment()) &&
      moment(runcard.expire_at).diff(moment(), "days") >
      Config.DAYS_BEFORE_RENEW
      : ""
  );
  return (
    <>
      <PageHeader
        title={"Profilo"}
        desc={"La tua area personale"}
        back={false}
      />
      <ScrollView contentContainerStyle={Style.container} bounces={false}>
        <View style={Style.webContainer}>
          <View style={Style.leftColumn}>
            <View style={Style.topContainer}>
              <View style={Style.avatarContainer}>
                <AvatarUpload
                  size={100}
                  avatar={user.picture}
                  onUpload={updateAvatar}
                  onReset={() => {
                    deleteAvatar();
                  }}
                />
              </View>
              <Text style={Style.userName}>
                {user.given_name} {user.family_name}
              </Text>
              {leaderboardLevel && leaderboardLevel.level && (
                <View style={Style.userBadge}>
                  <Image
                    source={{ uri: leaderboardLevel.badge }}
                    style={Style.userBadgeImage}
                  />
                  <Text style={Style.userLevel}>{leaderboardLevel.level}</Text>
                </View>
              )}
              {editNickname ? (
                <View style={Style.nicknameContainer}>
                  <TextInput
                    style={Style.textInput}
                    textContentType={"nickname"}
                    autoCorrect={false}
                    placeholder={"Nickname"}
                    value={nickname}
                    onChangeText={setNickname}
                  />
                  <Button
                    style={Style.saveIcon}
                    onPress={() => {
                      updateUser({ nickname });
                      setEditNickname(false);
                    }}
                  >
                    <CheckIcon size={10} color={Colors.white} />
                  </Button>
                  <Button
                    style={Style.cancelIcon}
                    onPress={() => {
                      setEditNickname(false);
                      setNickname(user.nickname);
                    }}
                  >
                    <CloseIcon size={10} color={Colors.white} />
                  </Button>
                </View>
              ) : (
                <View style={Style.nicknameContainer}>
                  <Text style={Style.userNickname}>{nickname}</Text>
                  <Button
                    style={Style.editIcon}
                    onPress={() => setEditNickname(true)}
                  >
                    <EditIcon size={15} />
                  </Button>
                </View>
              )}
            </View>
            <View style={Style.menuContainer}>
              <View style={Style.menuItem}>
                <Text style={Style.menuItemText}>{user.email}</Text>
              </View>
              <View style={Style.menuItem}>
                {user.runcard ? (
                  !runcard ? (
                    !runcardError ? (
                      <ActivityIndicator
                        size={"small"}
                        color={Colors.grayMedium}
                        style={Style.loader}
                      />
                    ) : (
                      <Text style={[Style.menuItemText, Style.error]}>
                        Dati non disponibili
                      </Text>
                    )
                  ) : moment(runcard.expire_at).isAfter(moment()) &&
                    moment(runcard.expire_at).diff(moment(), "days") >
                    Config.DAYS_BEFORE_RENEW ? (
                    <View style={Style.menuItemWithIcon}>
                      <Text style={[Style.menuItemText, {}]}>
                        {Config.RUNCARD_TYPES[runcard.type]
                          ? Config.RUNCARD_TYPES[runcard.type].name
                          : "Runcard"}
                      </Text>
                      <CheckIcon size={22} />
                    </View>
                  ) : (
                    <View style={Style.menuItemWithButton}>
                      <Text style={[Style.menuItemText, {}]}>
                        {Config.RUNCARD_TYPES[runcard.type]
                          ? Config.RUNCARD_TYPES[runcard.type].name
                          : "Runcard"}
                      </Text>
                      <View style={Style.buttonContainer}>
                        <PrimaryButton size={"xsmall"} route={"renewRuncard"}>
                          Rinnova
                        </PrimaryButton>
                      </View>
                    </View>
                  )
                ) : (
                  <MenuButton route={"linkRuncard"}>
                    <Text style={Style.menuItemText}>Nessuna Runcard</Text>
                  </MenuButton>
                )}
              </View>
              {user.runcard &&
                runcard &&
                moment(runcard.expire_at).isAfter(moment()) && (
                  <View style={Style.menuItem}>
                    <MenuButton route={"certificate"}>
                      <Text
                        style={[
                          Style.menuItemText,
                          activeComponent === "certificate"
                            ? Style.menuItemTextSelected
                            : {},
                        ]}
                      >
                        Visita medica
                      </Text>
                    </MenuButton>
                  </View>
                )}
              {user.runcard &&
                runcard &&
                !runcardError &&
                moment(runcard.expire_at).isAfter(moment()) && (
                  <View style={Style.menuItem}>
                    <MenuButton action={generatePdfCertificate}>
                      <Text
                        style={[
                          Style.menuItemText,
                          activeComponent === "certificate-pdf"
                            ? Style.menuItemTextSelected
                            : {},
                        ]}
                      >
                        Richiesta per certificato medico
                      </Text>
                    </MenuButton>
                  </View>
                )}
              <View style={Style.menuItem}>
                <MenuButton action={openChangePassword}>
                  <Text style={Style.menuItemText}>Cambia password</Text>
                </MenuButton>
              </View>
            </View>
            <View style={Style.menuContainer}>
              {Platform.OS !== "web" && (
                <View style={Style.menuItem}>
                  <MenuButton route={"notifications"}>
                    <Text style={Style.menuItemText}>Notifiche</Text>
                  </MenuButton>
                </View>
              )}
              <View style={Style.menuItem}>
                <MenuButton route={"privacy"}>
                  <Text
                    style={[
                      Style.menuItemText,
                      activeComponent === "privacy"
                        ? Style.menuItemTextSelected
                        : {},
                    ]}
                  >
                    Informativa sulla privacy
                  </Text>
                </MenuButton>
              </View>
              {Platform.OS !== "web" && (
                <View style={Style.menuItem}>
                  <MenuButton route={"services"}>
                    <Text style={Style.menuItemText}>Collega le tue APP</Text>
                  </MenuButton>
                </View>
              )}
              <View style={Style.menuItem}>
                <MenuButton route={"conditions"}>
                  <Text
                    style={[
                      Style.menuItemText,
                      activeComponent === "conditions"
                        ? Style.menuItemTextSelected
                        : {},
                    ]}
                  >
                    Termini e Condizioni
                  </Text>
                </MenuButton>
              </View>
              <View style={Style.menuItem}>
                <MenuButton action={() => setContactPopUp(true)}>
                  <Text style={Style.menuItemText}>Contattaci</Text>
                </MenuButton>
              </View>
              <View style={Style.menuItem}>
                <MenuButton route={"deleteProfile"}>
                  <Text style={Style.menuItemText}>Elimina Pofilo</Text>
                </MenuButton>
              </View>
              <View style={[Style.menuItem, Style.versionItem]}>
                <Text style={[Style.menuItemText, Style.versionText]}>
                  Versione
                </Text>
                <Text style={[Style.menuItemText, Style.versionText]}>
                  {Config.APP_VERSION}
                </Text>
              </View>
            </View>
          </View>
          {isDesktop() && (
            <View style={Style.rightColumn}>{components[activeComponent]}</View>
          )}
        </View>
        <Footer />
      </ScrollView>
      {PDFOpen && (
        <ModalDownload
          title={"PDF"}
          url={PDFOpen}
          closeHandler={() => setPDFOpen(null)}
        />
      )}
      {contactPopUp && (
        <Popup
          title={"Contattaci"}
          button={false}
          showClose
          closeHandler={() => setContactPopUp(false)}
          text={`Se desideri contattarci, via email`}
        >
          {/* <PrimaryButton
            onPress={calllUs}
            size={"small"}
            style={[Style.button, Style.firstPopupButton]}
          >
            <Text>{Config.CONTACTS.telephone}</Text>
          </PrimaryButton> */}
          <View style={Style.divider} />
          <PrimaryButton onPress={mailUs} size={"small"} style={Style.button}>
            <Text>{Config.CONTACTS.address}</Text>
          </PrimaryButton>
        </Popup>
      )}
      {info && (
        <Modal
          transparent={true}
          onRequestClose={() => setInfo(false)}
          animationType={"fade"}
        >
          <SafeAreaView style={Style.infoModalBackground}>
            <View style={Style.infoModalContainer}>
              <Button
                onPress={() => setInfo(false)}
                style={Style.infoModalClose}
              >
                <CloseIcon />
              </Button>
              <View style={Style.infoModal}>
                <Text style={Style.infoModalText}>
                  La partecipazione dei possessori di Runcard, Mountain and
                  Trail Runcard, Runcard EPS e Runcard Nordic Walking è comunque
                  subordinata al possesso di un certificato medico di idoneità
                  agonistica specifica per l’atletica leggera, in corso di
                  validità, che dovrà essere obbligatoriamente consegnato, anche
                  digitalmente, alle società organizzatrici di ciascuna
                  manifestazione.
                </Text>
              </View>
            </View>
          </SafeAreaView>
        </Modal>
      )}
    </>
  );
};

export default Home;
