import React from 'react';
import { View } from 'react-native';

import { getResponsive } from '../LegalsStyle';
import { Footer, PageHeader } from '../../../Components/Layout';
import { PrivacyPolicy as PrivacyPolicyContent } from '../../../Components/Legals';

const PrivacyPolicy = () => {
  const Style = getResponsive();

  return (
    <View style={Style.wrapper}>
      <PageHeader title={'Privacy Policy'}/>
      <View style={Style.container}>
        <PrivacyPolicyContent padding={true} />
      </View>
      <Footer />
    </View>
  );
};

export default PrivacyPolicy;
