import { Platform } from 'react-native';

import Welcome from '../Containers/Welcome/Welcome';
import WelcomeUser from '../Containers/Welcome/WelcomeUser';
import Registration from '../Containers/Registration/Registration';
import Paywall from '../Containers/Paywall/Paywall';
import Login from '../Containers/Login/Login';
import Logout from '../Containers/Logout/Logout';
import SignIn from '../Containers/SignIn/SignIn';
import EnableServices from '../Containers/EnableServices/EnableServices';
import LinkRuncard from '../Containers/LinkRuncard/LinkRuncard';
import RenewRuncard from '../Containers/RenewRuncard/RenewRuncard';

import App from '../Containers/App/AppScreen';
import Homepage from '../Containers/Homepage/Homepage';
import Activities from '../Containers/Activities/Activities';
import Races from '../Containers/Races/Races';
import Runcard from '../Containers/Runcard/Runcard';
import Play from '../Containers/Play/Play';
import News from '../Containers/News/News';
import Profile from '../Containers/Profile/Profile';

import UserProfile from '../Components/UserProfile/Home/Home';
import Certificate from '../Components/UserProfile/Certificate/Certificate';
import Notifications from '../Components/UserProfile/Notifications/Notifications';
import Services from '../Components/UserProfile/Services/Services';
import FrequentQuestions from '../Containers/FrequentQuestions/FrequentQuestions';
import Advantages from '../Containers/Advantages/Advantages';
import Conditions from '../Containers/Legals/Conditions/Conditions';
import PrivacyPolicy from '../Containers/Legals/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from '../Containers/Legals/CookiePolicy/CookiePolicy';
import { News as NewsItem } from '../Components/News';
import { Race as RaceItem } from '../Components/Races';


import { Selfie, SocialWall, GenericService, Home as PlayHomepage, Cattolica } from '../Components/Play';
import { Home as ChallengesHome, Challenge, Create as CreateChallenge } from '../Components/Play/Challenges';
import ChallengesEditorialList from '../Components/Play/Challenges/Editorial/List';
import ChallengesUserList from '../Components/Play/Challenges/User/List';

import { Config } from '../Config';
import { isDesktop } from '../Services/ResponsiveHelper';
import DeleteProfile from '../Components/UserProfile/DeleteProfile/DeleteProfile';

const routes = [
  {
    name: 'welcome',
    path: '/',
    component: Welcome,
    exact: true,
    logged: false,
  },
  {
    name: 'paywall',
    path: '/paywall',
    component: Paywall,
    logged: false,
  },
  {
    name: 'registration',
    path: '/registrazione',
    component: Registration,
    logged: false,
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    logged: false,
  },
  {
    name: 'logout',
    path: '/logout',
    component: Logout,
  },
  {
    name: 'signin',
    path: '/signin',
    component: SignIn,
  },
  {
    name: 'linkRuncard',
    path: '/associa-runcard',
    component: LinkRuncard,
    logged: true,
    paywall: true,
    runcard: false,
  },
  {
    name: 'renewRuncard',
    path: '/rinnova-runcard',
    component: RenewRuncard,
    runcard: true,
  },
  {
    name: 'enableServices',
    path: '/abilita-servizi',
    component: EnableServices,
    logged: true,
  },
  {
    name: 'stravaSignin',
    path: '/strava-signin',
    component: EnableServices,
  },
  {
    name: 'welcomeUser',
    path: '/benvenuto',
    component: WelcomeUser,
    logged: true,
  },
  {
    name: 'app',
    path: '/',
    component: App,
    routes: [

      {
        name: 'races',
        path: '/gare',
        component: Races,
        logged: true,
        paywall: true,
        routes: [
          {
            name: 'raceItem',
            path: '/gare/:id',
            component: RaceItem,
            exact: true,
            runcard: true,
            paywall: true,
          },
        ]
      },
      {
        name: 'activities',
        path: '/attivita',
        component: Activities,
        runcard: true,
        paywall: true,
      },
      {
        name: 'runcard',
        path: '/runcard',
        component: Runcard,
        runcard: true,
        paywall: true,
      },
      {
        name: 'play',
        path: '/servizi',
        component: Play,
        logged: true,
        paywall: true,
        routes: [
          {
            name: 'playHomepage',
            path: '/servizi',
            component: PlayHomepage,
            exact: true,
            logged: true,
            paywall: true,
          },
          {
            name: 'selfie',
            path: '/servizi/selfie',
            component: Selfie,
            logged: true,
            paywall: true,
          },
          {
            name: 'socialwall',
            path: '/servizi/social-wall',
            component: SocialWall,
            logged: true,
            paywall: true,
          },
          {
            name: 'challenges',
            path: '/servizi/sfide',
            component: ChallengesHome,
            exact: true,
            runcard: true,
            paywall: true,
          },
          {
            name: 'challengesEditorial',
            path: '/servizi/sfide/top',
            component: ChallengesEditorialList,
            exact: true,
            runcard: true,
            paywall: true,
          },
          {
            name: 'challengesUser',
            path: '/servizi/sfide/obiettivi',
            component: ChallengesUserList,
            exact: true,
            runcard: true,
            paywall: true,
          },
          {
            name: 'challengeEditorial',
            path: '/servizi/sfide/top/:id',
            component: Challenge,
            runcard: true,
            paywall: true,
            type: 'editorial',
          },
          {
            name: 'challengeNew',
            path: '/servizi/sfide/obiettivi/crea',
            component: CreateChallenge,
            runcard: true,
            paywall: true,
          },
          {
            name: 'challengeUser',
            path: '/servizi/sfide/obiettivi/:id',
            component: Challenge,
            type: 'user',
            runcard: true,
            paywall: true,
          },
          {
            name: 'cattolica',
            path: '/servizi/tua-assicurazioni',
            component: Cattolica,
            logged: true,
            paywall: true,
          },
          {
            name: 'others',
            path: '/servizi/:service',
            component: GenericService,
            logged: true,
            paywall: true,
          },
        ]
      },
      {
        name: 'news',
        path: '/news',
        component: News,
        routes: [
          {
            name: 'newsItem',
            path: '/news/:id',
            component: NewsItem,
            exact: true,
          },
        ]
      },
      {
        name: 'faq',
        path: '/domande-frequenti',
        component: FrequentQuestions,
      },
      {
        name: 'advantages',
        path: '/vantaggi',
        component: Advantages,
      },
      {
        name: 'privacy',
        path: '/privacy',
        component: PrivacyPolicy,
      },
      {
        name: 'conditions',
        path: '/terms-and-conditions',
        component: Conditions,
      },
      {
        name: 'cookie',
        path: '/cookie-policy',
        component: CookiePolicy,
      },
      {
        name: 'profile',
        path: '/profilo',
        component: Profile,
        logged: true,
        routes: [
          {
            name: 'profileHomepage',
            path: '/profilo',
            component: UserProfile,
            exact: true,
            logged: true,
          },
          {
            name: 'certificate',
            path: '/profilo/certificato',
            component: Certificate,
            logged: true,
          },
          {
            name: 'notifications',
            path: '/profilo/notifiche',
            component: Notifications,
            logged: true,
          },
          {
            name: 'deleteProfile',
            path: '/profilo/delete_profile',
            component: DeleteProfile,
            logged: true,
          },
          {
            name: 'services',
            path: '/profilo/servizi',
            component: Services,
            logged: true,
          },
        ]
      }
    ]
  },
];

const filterRoute = (routeNames, routes) => {
  return routes.filter(route => routeNames.indexOf(route.name) === -1).map(route => route.routes ? { ...route, routes: filterRoute(routeNames, route.routes.filter(r => routeNames.indexOf(r.name) === -1)) } : route);
};

const homepage = {
  name: 'homepage',
  path: '/',
  component: Homepage,
  exact: true,
};
const linkRuncardDesktop = {
  name: 'linkRuncard',
  path: '/acquista-runcard',
  component: LinkRuncard,
  logged: true,
  runcard: false,
};
const renewRuncardDesktop = {
  name: 'renewRuncard',
  path: '/rinnova-runcard',
  component: RenewRuncard,
  logged: true,
  runcard: true,
};

let webRoutes = filterRoute(['welcome', 'selfie', 'notifications'], routes);
webRoutes = webRoutes.map(route => route.name === 'app' ? { ...route, routes: [homepage, ...route.routes] } : route); // Add homepage

let webDesktopRoutes = filterRoute(['linkRuncard', 'renewRuncard'], webRoutes);
webDesktopRoutes = webDesktopRoutes.map(route => route.name === 'app' ? { ...route, routes: [linkRuncardDesktop, renewRuncardDesktop, ...route.routes] } : route); // Add runcard pages

const appRoutes = Platform.OS === 'web' ? isDesktop() ? webDesktopRoutes : webRoutes : routes;

export default appRoutes;

// Flatten routes as a plain object { routeName: '/path', ... }
const getRoutes = (routes) => {
  if (typeof routes === 'undefined') return {};
  return routes.reduce((acc, route) => {
    return {
      ...acc,
      ...getRoutes(route.routes),
      [route.name]: route.path,
    };
  }, {});
};

const flattenedRoutes = getRoutes(appRoutes);

const getPath = (routeName) => {
  if (typeof routeName === 'object') {
    routeName = routeName.pathname;
  }
  if (routeName && routeName.indexOf('/') !== -1) return routeName; // Not a route name but an url
  if (typeof flattenedRoutes[routeName] === 'undefined') console.log('Route not found', routeName);
  return typeof flattenedRoutes[routeName] !== 'undefined' ? flattenedRoutes[routeName] : Platform.OS !== 'web' ? getPath(Config.APP_HOMEPAGE) : 'not-found';
};

export { getPath };
