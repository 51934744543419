import { INITIAL_STATE } from './InitialState'
import { createReducer } from 'reduxsauce'
import { ServicesTypes } from './Actions'

export const setGoogleFit = (state, { status }) => ({
  ...state,
  google_fit: status,
});

export const setStrava = (state, { status }) => ({
  ...state,
  strava: status,
});

export const setSalute = (state, { status }) => ({
  ...state,
  salute: status,
});

export const setStravaAuthError = (state, { error }) => ({
  ...state,
  strava_error: error,
});

export const setStravaData = (state, { access_token, refresh_token, expires_at, athlete_id }) => ({
  ...state,
  strava_data: { access_token, refresh_token, expires_at, athlete_id },
});

export const reducer = createReducer(INITIAL_STATE, {
  [ServicesTypes.SET_GOOGLE_FIT]: setGoogleFit,
  [ServicesTypes.SET_STRAVA]: setStrava,
  [ServicesTypes.SET_SALUTE]: setSalute,
  [ServicesTypes.SET_STRAVA_DATA]: setStravaData,
  [ServicesTypes.SET_STRAVA_AUTH_ERROR]: setStravaAuthError,
});
