import { put, call } from 'redux-saga/effects';

import LayoutActions from '../Stores/Layout/Actions';
import ResultsActions from '../Stores/Results/Actions';
import { fetchResults } from '../Services/ApiGatewayService';

export function* getResults({ loader = true }) {
  if (loader) yield put(LayoutActions.setLoading(true));
  try {
    const data = yield call(fetchResults);
    yield put(ResultsActions.setResults(data));
  } catch (error) {
    console.log('DEBUG: Error while fetching user\'s results', error);
    yield put(ResultsActions.setResultsError(error));
  }
  if (loader) yield put(LayoutActions.setLoading(false));
}
