import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Text, Platform, View, SafeAreaView, ScrollView, ImageBackground } from 'react-native';
import { useHistory } from 'react-router-native';
import { Config } from '../../Config';

import Style, { getResponsive } from './EnableServicesStyle';
import { Images } from '../../Theme';
import { ErrorPopup } from '../../Components/Popup';
import { Strava, GoogleFit, Salute } from '../../Components/Services';
import { BackOrCloseButton, PrimaryButton } from '../../Components/Buttons';

import { getPath } from '../../Router/Router';
import ResponsiveHelper from '../../Services/ResponsiveHelper';
import NavigationAction from '../../Stores/Navigation/Actions';

const EnableServices = () => {
  const dispatch = useDispatch();

  const logged = useSelector(state => state.auth.logged);

  let history = useHistory();
  const [ {}, setBreakpoint ] = React.useState(ResponsiveHelper.getSize());
  const [ closeModal, setCloseModal ] = React.useState(false);

  React.useEffect(() => {
    if (logged) {
      dispatch(NavigationAction.setAfterRuncardLink(null));
    }
  }, []);

  return logged && (
    Platform.OS === 'web'
      ? !closeModal && (
        <ErrorPopup closeHandler={() => { setCloseModal(true); history.push(getPath(Config.APP_HOMEPAGE)); }} title={'Errore'} text={'Questa funzionalità è disponibile solo su applicazione mobile'} />
      )
      : (
        <View onLayout={() => setBreakpoint(ResponsiveHelper.getSize())} style={Style.container}>
          <View style={getResponsive().innerContainer}>
            <ImageBackground source={Images.bg_splash} style={Style.background} resizeMode='cover'>
              <SafeAreaView>
                <View style={Style.topContainer}>
                  <View style={Style.backButtonContainer}>
                    <BackOrCloseButton style={Style.backButton} />
                  </View>
                  <Text style={Style.h1}>Abilita i servizi</Text>
                  <View style={Style.backButtonContainer} />
                </View>
              </SafeAreaView>
            </ImageBackground>
            <View style={Style.container}>
              <ScrollView bounces={false}>
                <View style={Style.centerContainer}>
                  <View style={[getResponsive().innerContainer, Style.paddingTop]}>
                    <GoogleFit />
                    <Salute />
                    <Strava />
                    <PrimaryButton route={'welcomeUser'} style={Style.button}>CONTINUA</PrimaryButton>
                  </View>
                </View>
              </ScrollView>
            </View>
          </View>
        </View>
    )
  );
};

export default EnableServices;
