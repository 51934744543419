import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const TabRuncardButton = ({ active=false, size=28 }) => {
  return (
    <Svg width={size} height={size} viewBox={'0 0 40 64'}>
      <Defs>
        <LinearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gradientBgIconRuncard">
          <Stop stopColor={active ? '#02AFF1' : '#E3E4E3'} offset="0%" />
          <Stop stopColor={active ? '#0D85F1' : '#C3C4C3'} offset="100%" />
        </LinearGradient>
      </Defs>
      <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
        <Path d="M37.3691489,7.45964211 C37.3691489,11.1390492 34.3018672,14.1215323 30.5188573,14.1215323 C26.7358474,14.1215323 23.6688573,11.1390492 23.6688573,7.45964211 C23.6688573,3.780235 26.7358474,0.797468354 30.5188573,0.797468354 C34.3018672,0.797468354 37.3691489,3.780235 37.3691489,7.45964211" fill="url(#gradientBgIconRuncard)" />
        <Path d="M14.3629037,21.6409963 L8.28868899,34.9463471 C8.28868899,34.9463471 7.57733386,36.1170547 9.82072635,36.1170547 C10.7274126,36.1170547 11.3839584,35.9520385 11.8442985,35.7549831 C12.3658617,35.5321262 12.772267,35.1136315 13.0046236,34.6078088 L17.8106438,24.142324 L26.6757613,34.8397387 C26.6757613,34.8397387 27.5512528,36.1170547 29.3573368,36.1170547 C29.7182621,36.1170547 30.0599458,36.0637505 30.3750994,35.9786906 C31.5470862,35.6619843 32.5004186,34.8309492 33.030145,33.766283 C33.2461754,33.3319105 33.5097266,32.763994 33.7898956,32.0724571 C34.5012507,30.3159705 39.0431366,20.5233094 39.0431366,20.5233094 C39.0431366,20.5233094 39.8087179,19.2417405 37.6752356,19.1926893 C35.0484694,19.1325803 34.3371143,20.7362427 34.3371143,20.7362427 L29.4902786,31.2969945 L20.8685962,20.9375511 C20.8685962,20.9375511 19.6712456,19.1926893 17.3727522,19.6185558 C15.3829987,19.9871486 14.3629037,21.6409963 14.3629037,21.6409963" fill="url(#gradientBgIconRuncard)" />
        <Path d="M14.6365131,48.357881 C14.6365131,48.357881 12.7073996,47.5861043 11.5858491,48.8103996 C10.3237769,50.1872356 1.28402756,61.0244321 1.28402756,61.0244321 C1.28402756,61.0244321 0.0388645487,62.3210282 2.22773925,63.299784 C4.41690549,64.2782562 5.83961574,62.4613771 5.83961574,62.4613771 L15.5009262,50.7069515 C15.5009262,50.7069515 16.8801971,49.2626346 14.6365131,48.357881" fill="url(#gradientBgIconRuncard)" />
        <Path d="M17.8105563,37.287904 C17.8105563,37.287904 16.716119,39.2570403 18.0292106,40.6409647 C19.3425937,42.0246055 27.8797298,50.2206925 27.8797298,50.2206925 L18.4671021,59.4811622 C18.4671021,59.4811622 17.3726648,60.4922407 18.3029657,62.3550522 C18.4481521,62.6453899 18.5956708,62.8761858 18.7414403,63.0590645 C19.2341412,63.6760322 20.1737713,63.7273517 20.7457708,63.1789989 L31.7350413,52.6378109 C31.7350413,52.6378109 32.8046979,51.8512905 32.8595072,50.5036584 C32.9143165,49.1560263 31.7102605,47.932298 31.7102605,47.932298 L20.1198366,36.6598785 C20.017798,36.560642 19.9011824,36.4758656 19.771156,36.4151896 C19.3379291,36.2124635 18.3878035,35.9646557 17.8105563,37.287904" fill="url(#gradientBgIconRuncard)" />
      </G>
    </Svg>
  )
};

TabRuncardButton.propTypes = {
  active: PropTypes.bool,
  size: PropTypes.number,
};

export default TabRuncardButton;
