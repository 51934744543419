import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop, Polygon } from 'react-native-svg';

const CalendarIcon = ({ size=25 }) => (
  <Svg width={size} height={size} viewBox={'0 0 22 22'}>
    <Defs>
      <LinearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'calendarIconGradient'}>
        <Stop stopColor={'#02AFF1'} offset={'0%'} />
        <Stop stopColor={'#0D85F1'} offset={'100%'} />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} strokeWidth={1} fill={'none'} fillRule={'evenodd'}>
      <G fill={'url(#calendarIconGradient)'}>
        <Path d={'M17.6954074,19.0234444 L2.30466667,19.0234444 C1.57244444,19.0234444 0.976518519,18.4277037 0.976518519,17.6952963 L0.976518519,6.9727037 L19.0233704,6.9727037 L19.0233704,17.6952963 C19.0233704,18.4277037 18.4276296,19.0234444 17.6954074,19.0234444 M16.4842963,1.56251852 L16.4842963,-7.40740741e-05 L15.3905926,-7.40740741e-05 L15.3905926,1.56251852 L4.6092963,1.56251852 L4.6092963,-7.40740741e-05 L3.51559259,-7.40740741e-05 L3.51559259,1.56251852 L2.30466667,1.56251852 C1.03188889,1.56251852 3.7037037e-05,2.59437037 3.7037037e-05,3.86714815 L3.7037037e-05,17.6952963 C3.7037037e-05,18.9682593 1.03188889,19.9999259 2.30466667,19.9999259 L17.6954074,19.9999259 C18.9681852,19.9999259 20.000037,18.9682593 20.000037,17.6952963 L20.000037,3.86714815 C20.000037,2.59437037 18.9681852,1.56251852 17.6954074,1.56251852 L16.4842963,1.56251852 Z M2.30466667,2.539 L3.51559259,2.539 L3.51559259,4.68751852 L4.6092963,4.68751852 L4.6092963,2.539 L15.3905926,2.539 L15.3905926,4.68751852 L16.4842963,4.68751852 L16.4842963,2.539 L17.6954074,2.539 C18.4276296,2.539 19.0233704,3.13474074 19.0233704,3.86714815 L19.0233704,5.99603704 L0.976518519,5.99603704 L0.976518519,3.86714815 C0.976518519,3.13474074 1.57244444,2.539 2.30466667,2.539'} />
          <Polygon points={'2.9687037 10.547037 4.5312963 10.547037 4.5312963 8.98444444 2.9687037 8.98444444'} />
          <Polygon points={'6.0937037 10.547037 7.6562963 10.547037 7.6562963 8.98444444 6.0937037 8.98444444'} />
          <Polygon points={'9.2187037 10.547037 10.7812963 10.547037 10.7812963 8.98444444 9.2187037 8.98444444'} />
          <Polygon points={'12.3437037 10.547037 13.9062963 10.547037 13.9062963 8.98444444 12.3437037 8.98444444'} />
          <Polygon points={'15.4687037 10.547037 17.0312963 10.547037 17.0312963 8.98444444 15.4687037 8.98444444'} />
          <Polygon points={'2.9687037 13.672037 4.5312963 13.672037 4.5312963 12.1094444 2.9687037 12.1094444'} />
          <Polygon points={'6.0937037 13.672037 7.6562963 13.672037 7.6562963 12.1094444 6.0937037 12.1094444'} />
          <Polygon points={'9.2187037 13.672037 10.7812963 13.672037 10.7812963 12.1094444 9.2187037 12.1094444'} />
          <Polygon points={'12.3437037 13.672037 13.9062963 13.672037 13.9062963 12.1094444 12.3437037 12.1094444'} />
          <Polygon points={'2.9687037 16.797037 4.5312963 16.797037 4.5312963 15.2344444 2.9687037 15.2344444'} />
          <Polygon points={'6.0937037 16.797037 7.6562963 16.797037 7.6562963 15.2344444 6.0937037 15.2344444'} />
          <Polygon points={'9.2187037 16.797037 10.7812963 16.797037 10.7812963 15.2344444 9.2187037 15.2344444'} />
          <Polygon points={'12.3437037 16.797037 13.9062963 16.797037 13.9062963 15.2344444 12.3437037 15.2344444'} />
          <Polygon points={'15.4687037 13.672037 17.0312963 13.672037 17.0312963 12.1094444 15.4687037 12.1094444'} />
      </G>
    </G>
  </Svg>
);

export default CalendarIcon;

CalendarIcon.propTypes = {
  size: PropTypes.number,
};
