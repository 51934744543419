export default class NotificationService {

  constructor(onRegister, onNotification) {
    console.log('Push notifications disabled on web');
  }

  configure(onRegister, onNotification, gcm = '') {

  }

  checkPermissions(cbk) {

  }

  cancelNotif() {

  }

  cancelAll() {

  }
}

const getUniqueId = () => 'desktop';

export {
  getUniqueId
};
