import React from 'react';
import PropTypes from 'prop-types';
import Svg, { G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';

const SpeedMeterIcon = ({ size=25 }) => (
  <Svg width={size} height={size} viewBox={'0 0 26 20'} >
    <Defs>
      <LinearGradient x1={'50%'} y1={'0%'} x2={'50%'} y2={'100%'} id={'speedMeterGradient'}>
        <Stop stopColor={'#02AFF1'} offset={'0%'} />
        <Stop stopColor={'#0D85F1'} offset={'100%'} />
      </LinearGradient>
    </Defs>
    <G stroke={'none'} strokeWidth={'1'} fill={'none'} fillRule={'evenodd'}>
      <Path d={'M17.573727,14.9505571 C18.1489282,14.9497424 18.6161813,14.4815818 18.616996,13.9073975 C18.6178061,13.3349478 18.1518742,12.8707094 17.5766731,12.871523 L12.8652786,9.41487396 L16.5341855,13.9771293 C16.5673014,14.5209086 17.0202479,14.9513389 17.573727,14.9505571'} fill={'url(#speedMeterGradient)'} transform={'translate(15.741137, 12.182716) rotate(-241.000000) translate(-15.741137, -12.182716)'} />
      <G strokeWidth={'1'} transform={'translate(0.866667, 0.681739)'} fill={'url(#speedMeterGradient)'}>
        <Path d={'M20.9813067,3.73652174 C18.6598244,1.32695652 15.5734511,0 12.2902,0 C9.00778667,0 5.92057556,1.32695652 3.59993111,3.73652174 C1.27844889,6.14608696 0,9.34956522 0,12.7573913 C0,14.9573913 0.533664444,17.0713043 1.53648444,18.9426087 L3.83367111,18.9426087 L5.51425333,17.9365217 L4.77198222,16.6017391 L2.61135333,17.8965217 C1.84813778,16.353913 1.41668222,14.606087 1.41668222,12.7573913 C1.41668222,12.4295652 1.43176222,12.106087 1.45773333,11.7852174 L4.0339,12.2565217 L4.29193556,10.7391304 L1.68560889,10.2626087 C2.15057556,8.12869565 3.20114889,6.22173913 4.65134222,4.73391304 L6.41737778,6.91826087 L7.55424222,5.92869565 L5.77480222,3.72782609 C7.40595556,2.45565217 9.39232667,1.64956522 11.5487667,1.49826087 L11.5487667,4.41913043 L13.0324711,4.41913043 L13.0324711,1.49826087 C15.1889111,1.64956522 17.1744444,2.45565217 18.8064356,3.72782609 L17.0269956,5.92869565 L18.16386,6.91826087 L19.9298956,4.73391304 C21.3800889,6.22173913 22.4298244,8.12869565 22.8956289,10.2626087 L20.2893022,10.7391304 L20.5465,12.2565217 L23.1235044,11.7843478 C23.1494756,12.106087 23.1637178,12.4295652 23.1637178,12.7573913 C23.1637178,14.606087 22.7331,16.353913 21.9698844,17.8965217 L19.8092556,16.6017391 L19.0669844,17.9365217 L20.7475667,18.9426087 L23.0447533,18.9426087 C24.0467356,17.0713043 24.5812378,14.9573913 24.5812378,12.7573913 C24.5812378,9.34956522 23.3027889,6.14608696 20.9813067,3.73652174'} />
      </G>
    </G>
  </Svg>
);

export default SpeedMeterIcon;

SpeedMeterIcon.propTypes = {
  size: PropTypes.number,
};
