import { StyleSheet } from 'react-native';
import ResponsiveHelper from '../../Services/ResponsiveHelper';

import { ApplicationStyles, Fonts, Colors } from '../../Theme';

export const BaseStyle = {
  container: {
    flex: 1,
    minHeight: 640
  },
  innerContainer: {
    flex: 1,
    height: '100%',
  },
  closeContainer: {
    marginBottom: 10,
    marginLeft: 30,
    marginTop: 30,
  },
  topContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    flex: 0.8,
  },
  bottomContainer: {
    flex: 0.45,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 30,
  },
  logo: {
    width: 257,
    height: 102,
    marginTop: 10,
    marginBottom: 40,
  },
  title: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.big,
    textAlign: 'center',
  },
  lead: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.white,
    fontSize: Fonts.size.medium,
    textAlign: 'center',
    marginBottom: 20,
  },
  buttonContainer: {
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10
  },
  laterLink: {
    paddingTop: 10,
    paddingBottom: 10,
    width: '100%',
  },
  later: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.regular-1,
    textAlign: 'center',
  },
  loginText: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsRegular,
    color: Colors.white,
    fontSize: Fonts.size.regular+1,
    marginTop: 20,
    marginBottom: 20,
  },
  login: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsBold,
    fontSize: Fonts.size.regular+1,
    color: Colors.blue3,
  },
  modal: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  modalContainer: {
    flex: 1,
    paddingBottom: 20,
  },
  conditionsContainer: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    borderTopWidth: 1,
    width: '100%',
    borderTopColor: 'rgba(255, 255, 255, 0.32)',
  },
  conditions: {
    ...ApplicationStyles.screen.fonts,
    ...ApplicationStyles.screen.fontsLight,
    color: Colors.white,
    fontSize: Fonts.size.small,
    textAlign: 'center',
  },
  underlined: {
    textDecorationLine: 'underline',
  },
};

const StyleMedium = {
  innerContainer: {
    height: '100%',
    maxWidth:500,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, MD: StyleMedium });
export default StyleSheet.create(BaseStyle);
