import ResponsiveHelper from '../../../../Services/ResponsiveHelper';
import { ApplicationStyles, Colors, Fonts } from '../../../../Theme';
import { Platform } from "react-native";

const BaseStyle = {
  img: {
    flex: 1,
    ...Platform.select({
      web: {
        width: 250,
        height: 250,
      }
    })
  },
  imgContainer: {
    flex: 1,
    alignItems: 'center',
  },
  buttonContainer: {
    flex: 0.5,
    marginTop: 20,
  },
  text: {
    ...ApplicationStyles.screen.fontsRegular,
    textAlign: 'center',
  },
  title: {
    ...ApplicationStyles.screen.fontsBold,
    textAlign: 'center',
    fontSize: Fonts.size.big,
    color: Colors.cattolicaRed,
    marginTop: 20,
  },
  subtitle: {
    ...ApplicationStyles.screen.fonts,
    textAlign: 'center',
    fontSize: Fonts.size.big - 3,
    color: Colors.black,
    marginBottom: 10,
    marginTop: -3,
  },
  backImg: {},
  backImgContainer: {},
  cta: {
    ...ApplicationStyles.screen.fonts,
    color: Colors.cattolicaRed,
    textAlign: 'center',
    fontSize: Fonts.size.regular + 2,
    marginTop: 10,
  },
  wrapper: {
    flex: 1,
    flexShrink: 0.2,
    paddingHorizontal: 30,
  },
};

const StyleLG = {
  wrapper: {
    flex: 1,
    flexShrink: 1,
    marginBottom: 50,
  },
  text: {
    ...ApplicationStyles.screen.fontsLight,
    fontSize: Fonts.size.regular - 2,
  },
  backImgContainer: {
    shadowColor: Colors.black,
    shadowOffset: { width: 0, height: 1 },
    shadowOpacity: 0.25,
    shadowRadius: 2,
  },
  backImg: {
    width: '100%',
  },
  img: {
    width: 250,
    height: 250,
    marginTop: -170,
    marginBottom: 20,
  },
};

export const getResponsive = () => ResponsiveHelper.getResponsive({ base: BaseStyle, LG: StyleLG });
